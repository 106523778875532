import { useApolloClient } from '@apollo/client';
import { CustomFieldsFragment as CustomFieldsFragmentQuery } from 'queries/getWatchlist';
const useGetCustomField = () => {
    const client = useApolloClient();
    const getCustomFieldFragmentIdFromUrn = (urn) => {
        return `CompanyListCustomField:{"urn":"${urn}"}`;
    };
    const getCustomFieldFromCache = (urn) => {
        return client.cache.readFragment({
            fragment: CustomFieldsFragmentQuery,
            fragmentName: 'CustomFields',
            id: getCustomFieldFragmentIdFromUrn(urn)
        });
    };
    return {
        getCustomFieldFromCache,
        getCustomFieldFragmentIdFromUrn
    };
};
export default useGetCustomField;
