import { useStore } from '../config/StoreProvider';
const useObservableSavedSearch = () => {
    const { rootStore } = useStore();
    return {
        loading: rootStore.savedSearchViewStore.loading,
        currentPageSavedSearch: rootStore.savedSearchViewStore.savedSearch?.savedSearchWithDraftState,
        error: rootStore.savedSearchViewStore.error
    };
};
export default useObservableSavedSearch;
