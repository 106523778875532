import PersonColumnsConfig from 'interfaces/PersonColumnsConfig';
const ConnectionsOptions = [
    {
        label: PersonColumnsConfig.TEAM_CONNECTIONS.label,
        value: PersonColumnsConfig.TEAM_CONNECTIONS.value
    },
    {
        label: PersonColumnsConfig.LAST_CONTACTED_BY.label,
        value: PersonColumnsConfig.LAST_CONTACTED_BY.value
    },
    {
        label: PersonColumnsConfig.LAST_EMAIL_AT.label,
        value: PersonColumnsConfig.LAST_EMAIL_AT.value
    },
    {
        label: PersonColumnsConfig.LAST_MEETING_WITH.label,
        value: PersonColumnsConfig.LAST_MEETING_WITH.value
    },
    {
        label: PersonColumnsConfig.LAST_MEETING_DATE.label,
        value: PersonColumnsConfig.LAST_MEETING_DATE.value
    }
];
export default ConnectionsOptions;
