import CompanyColumnsConfig from 'interfaces/CompanyColumnsConfig';
const LinksOptions = [
    {
        label: CompanyColumnsConfig.WEBSITE.label,
        value: CompanyColumnsConfig.WEBSITE.value
    },
    {
        label: CompanyColumnsConfig.LINKEDIN.label,
        value: CompanyColumnsConfig.LINKEDIN.value
    },
    {
        label: CompanyColumnsConfig.TWITTER.label,
        value: CompanyColumnsConfig.TWITTER.value
    },
    {
        label: CompanyColumnsConfig.PITCHBOOK.label,
        value: CompanyColumnsConfig.PITCHBOOK.value
    },
    {
        label: CompanyColumnsConfig.FACEBOOK.label,
        value: CompanyColumnsConfig.FACEBOOK.value
    },
    {
        label: CompanyColumnsConfig.INSTAGRAM.label,
        value: CompanyColumnsConfig.INSTAGRAM.value
    },
    {
        label: CompanyColumnsConfig.TEAM_LINKEDIN_PROFILES.label,
        value: CompanyColumnsConfig.TEAM_LINKEDIN_PROFILES.value
    },
    {
        label: CompanyColumnsConfig.YOUTUBE.label,
        value: CompanyColumnsConfig.YOUTUBE.value
    },
    {
        label: CompanyColumnsConfig.INDEED.label,
        value: CompanyColumnsConfig.INDEED.value
    },
    {
        label: CompanyColumnsConfig.MONSTER.label,
        value: CompanyColumnsConfig.MONSTER.value
    },
    {
        label: CompanyColumnsConfig.STACKOVERFLOW.label,
        value: CompanyColumnsConfig.STACKOVERFLOW.value
    }
];
export default LinksOptions;
