import { jsx as _jsx } from "react/jsx-runtime";
import PersonColumnsConfig from 'interfaces/PersonColumnsConfig';
import { BaseFieldsDropdownItems } from './BaseFieldsDropdownItems';
const PeopleSearchFieldsDropdownItems = ({ onFieldClick, filterTerm }) => {
    return (_jsx(BaseFieldsDropdownItems, { onFieldClick: onFieldClick, filterTerm: filterTerm, harmonicFields: Object.values(PersonColumnsConfig), hiddenFields: [
            PersonColumnsConfig.ADDED_TO_LIST_BY.value,
            PersonColumnsConfig.ADDED_TO_LIST_DATE.value
        ] }));
};
export default PeopleSearchFieldsDropdownItems;
