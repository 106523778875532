import { action, makeObservable, runInAction } from 'mobx';
import DefaultPeopleSearchColumns from '../../components/Dashboard/Grids/ColumnDefaultOrderings/DefaultPeopleSearchColumns';
import client from '../../config/client';
import { appStore } from '../../hooks/useAppStore';
import { INITIAL_PEOPLE_SEARCH_MODEL, INITIAL_PEOPLE_SORT_VALUE, INITIAL_SEARCH_FILTER_GROUP } from '../../interfaces/SearchModel/Search';
import { PEOPLE_SEARCH_QUERY } from '../../queries/peopleSearch';
import analytics, { CustomTrackEvent, EventLocations, SearchVersion } from '../../utils/analytics';
import { GRID_INITIAL_PAGE_SIZE } from '../../utils/constants';
import { convertPersonColumnsConfigToUrns, getPeopleSavedSearchColumnOrders } from '../../utils/namedViews';
import useFilterStore from '../filterStore';
import usePersistedZustandStore from '../persistedZustandStore';
import useTableStore from '../tableStore';
import ExploreViewStore from './ExploreViewStore';
class PeopleExploreViewStore extends ExploreViewStore {
    /**
     * Initializes the companies explore store.
     * @param rootStore - The root store instance.
     */
    constructor(rootStore) {
        super(rootStore, INITIAL_PEOPLE_SEARCH_MODEL, DefaultPeopleSearchColumns);
        this.initializeStore();
        makeObservable(this, {
            initializeStore: false,
            intializeSearchModel: false,
            initializeTableColumns: false,
            initializeTotalCount: false,
            logSearchModel: false,
            observeApolloQuery: false,
            updateSort: action,
            updateTableColumns: action,
            updateSearchModel: action
        });
    }
    /**
     * Initializes the store.
     */
    initializeStore() {
        this.intializeSearchModel();
        this.initializeTableColumns();
        this.initializeTotalCount();
    }
    /**
     * Initializes the table columns from the persisted Zustand store.
     */
    initializeTableColumns() {
        const persistedPeopleVisibleColumns = usePersistedZustandStore.getState().explorePeopleVisibleColumns;
        let columnHeaders = getPeopleSavedSearchColumnOrders(persistedPeopleVisibleColumns ?? []);
        if (persistedPeopleVisibleColumns.length === 0)
            columnHeaders = DefaultPeopleSearchColumns;
        this.tableColumns = columnHeaders;
        useTableStore.getState().editTableStoreData('tableColumns', columnHeaders);
    }
    /**
     * Initializes the search model from the persisted Zustand store.
     */
    intializeSearchModel() {
        const { peopleDashboardFilters, peopleExplorePageSort } = useFilterStore.getState();
        this.updateSearchModel({
            searchFilterGroupQuery: peopleDashboardFilters,
            pageSort: peopleExplorePageSort
        });
    }
    /**
     * Initializes the total count from the Apollo query.
     */
    initializeTotalCount() {
        const query = client.readQuery({
            query: PEOPLE_SEARCH_QUERY,
            variables: {
                query: this.transformedSearchModel,
                first: GRID_INITIAL_PAGE_SIZE,
                after: null,
                skipExtended: true
            }
        });
        const totalCount = query?.searchPeople?.totalCount;
        this.totalCount = totalCount;
        useTableStore.getState().editTableStoreData('totalCount', totalCount ?? 0);
    }
    /**
     * Logs the search model to the analytics.
     */
    logSearchModel() {
        analytics.trackCustomEvent({
            event: CustomTrackEvent.SEARCH_INITIATED,
            properties: {
                openedFromLocation: EventLocations.PEOPLE_SEARCH,
                version: SearchVersion.V2,
                searchQuery: JSON.stringify(this.searchModel)
            }
        });
    }
    /**
     * Observes the Apollo query for changes in the search results.
     * Updates the data and error states accordingly.
     */
    observeApolloQuery() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        const observableQuery = client.watchQuery({
            query: PEOPLE_SEARCH_QUERY,
            variables: {
                query: this.transformedSearchModel,
                first: GRID_INITIAL_PAGE_SIZE,
                after: null,
                skipExtended: true
            },
            fetchPolicy: 'cache-only',
            returnPartialData: true
        });
        this.subscription = observableQuery.subscribe({
            next: (result) => {
                runInAction(() => {
                    const totalCount = result.data?.searchPeople?.totalCount;
                    useTableStore
                        .getState()
                        .editTableStoreData('totalCount', totalCount ?? 0);
                    this.totalCount = totalCount;
                });
            },
            error: (err) => {
                runInAction(() => {
                    this.error = err;
                });
            }
        });
    }
    /**
     * Updates the sort field and descending state.
     * @param field - The field to sort by.
     * @param descending - The descending state.
     */
    updateSort({ field, descending }) {
        this.updateSearchModel({
            searchFilterGroupQuery: {
                filter_group: this.searchModel.filter_group,
                controlled_filter_group: this.searchModel.controlled_filter_group ??
                    INITIAL_SEARCH_FILTER_GROUP
            },
            pageSort: {
                sortField: field,
                descending
            }
        });
    }
    /**
     * Updates the table columns.
     * @param newColumnOrder - The new columns order.
     */
    updateTableColumns(tableColumns) {
        // Update the table columns
        this.tableColumns = tableColumns;
        const columnsOrder = convertPersonColumnsConfigToUrns(tableColumns);
        // Update the persisted Zustand store
        usePersistedZustandStore
            .getState()
            .editStore('explorePeopleVisibleColumns', columnsOrder);
        // Update the table store
        useTableStore.getState().editTableStoreData('tableColumns', tableColumns);
    }
    /**
     * Updates the search model.
     * @param searchFilterGroupQuery - The search filter group query.
     * @param pageSort - The page sort.
     */
    updateSearchModel({ searchFilterGroupQuery, pageSort }) {
        // Update the search model in MobX
        this.searchModel = {
            filter_group: searchFilterGroupQuery?.filter_group ?? INITIAL_SEARCH_FILTER_GROUP,
            controlled_filter_group: searchFilterGroupQuery?.controlled_filter_group ??
                INITIAL_SEARCH_FILTER_GROUP,
            sort: pageSort?.sortField
                ? [
                    {
                        sort_field: pageSort.sortField,
                        descending: pageSort.descending ?? false
                    }
                ]
                : [
                    {
                        sort_field: INITIAL_PEOPLE_SORT_VALUE,
                        descending: false
                    }
                ],
            pagination: {
                page_size: GRID_INITIAL_PAGE_SIZE,
                start: 0
            }
        };
        // Update the search model in the dashboard
        appStore.getState().dashboard.updateSearchModel(this.searchModel);
        if (this.searchModel.sort && this.searchModel.sort[0]?.sort_field) {
            appStore
                .getState()
                .dashboard.setSortField(this.searchModel.sort[0]?.sort_field);
            appStore
                .getState()
                .dashboard.setSortIsDescending(this.searchModel.sort[0]?.descending);
        }
        // Persist the search model to the filter store
        useFilterStore.getState().editFilterStore('peopleDashboardFilters', {
            filter_group: this.searchModel.filter_group,
            controlled_filter_group: this.searchModel.controlled_filter_group || INITIAL_SEARCH_FILTER_GROUP
        });
        // Persist the sort to the filter store
        useFilterStore.getState().editFilterStore('peopleExplorePageSort', {
            sortField: this.searchModel.sort?.[0]?.sort_field,
            descending: this.searchModel.sort?.[0]?.descending
        });
        this.logSearchModel();
        this.observeApolloQuery();
    }
}
export default PeopleExploreViewStore;
