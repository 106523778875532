import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
const rectContainsRect = (parent, child) => {
    return (child.top >= parent.top &&
        child.bottom <= parent.bottom &&
        child.left >= parent.left &&
        child.right <= parent.right);
};
const MAX_ITEMS_RENDER = 10;
export const TruncatedListV2 = ({ renderTruncator, children, className, style }) => {
    const containerRef = useRef(null);
    const childArray = React.Children.toArray(children);
    const prevWidthRef = useRef(null);
    const truncate = useCallback(() => {
        if (!containerRef.current) {
            return;
        }
        const childNodes = Array.from(containerRef.current.children).filter((node) => node instanceof HTMLElement);
        //
        // Put the list in its initial state.
        //
        // Change from a scrollable container to overflow: hidden during hydration
        containerRef.current.style.overflow = 'hidden';
        // Show all items, hide all truncators.
        for (let i = 0; i < childNodes.length; ++i) {
            childNodes[i].hidden = i % 2 === 0;
        }
        // If there are no items (the last truncator is always included).
        if (childNodes.length === 1) {
            return;
        }
        //
        // Test if truncation is necessary. If the last item fits, exit
        const itemEl = childNodes[childNodes.length - 2];
        if (childArray.length <= MAX_ITEMS_RENDER &&
            rectContainsRect(containerRef.current.getBoundingClientRect(), itemEl.getBoundingClientRect())) {
            return;
        }
        //
        // Truncation is necessary - binary search to find the last truncator that can fit.
        //
        const numTruncators = Math.floor((childNodes.length - 1) / 2);
        let left = 0;
        let right = numTruncators - 1;
        let truncatorIndex = null;
        while (left <= right) {
            const middle = Math.floor((left + right) / 2);
            // show all items before the truncator
            for (let i = 0; i < middle; i += 1) {
                childNodes[i * 2 + 1].hidden = false;
            }
            // hide all items after the truncator
            for (let i = middle; i < numTruncators; i += 1) {
                childNodes[i * 2 + 1].hidden = true;
            }
            const truncatorEl = childNodes[middle * 2];
            truncatorEl.hidden = false;
            // check if this truncator fits
            if (rectContainsRect(containerRef.current.getBoundingClientRect(), truncatorEl.getBoundingClientRect())) {
                truncatorIndex = middle;
                left = middle + 1;
            }
            else {
                right = middle - 1;
            }
            truncatorEl.hidden = true;
        }
        // If we didn't find a truncator that fits, everything will be hidden at this point and we can exit early
        if (truncatorIndex === null) {
            return;
        }
        //
        // Now we have found the last truncator that fits, show it.
        //
        // show all items before the truncator
        for (let i = 0; i < truncatorIndex; i += 1) {
            childNodes[i * 2 + 1].hidden = false;
        }
        // hide all items after truncator
        for (let i = truncatorIndex; i < numTruncators; i += 1) {
            childNodes[i * 2 + 1].hidden = true;
        }
        const truncatorEl = childNodes[truncatorIndex * 2];
        truncatorEl.hidden = false;
    }, [childArray]);
    const debouncedTruncate = useMemo(() => debounce(() => {
        truncate();
    }, 100), [truncate]);
    // Clean up debounce on unmount
    useEffect(() => {
        return () => {
            debouncedTruncate.cancel();
        };
    }, [debouncedTruncate]);
    // ResizeObserver implementation
    useLayoutEffect(() => {
        const current = containerRef.current;
        const resizeObserver = new ResizeObserver(() => {
            debouncedTruncate();
        });
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
            truncate(); // Initial calculation
        }
        return () => {
            if (current) {
                resizeObserver.unobserve(current);
            }
        };
    }, [truncate, debouncedTruncate]);
    // Window resize listener with special handling for expansion
    useEffect(() => {
        const handleResize = () => {
            if (!containerRef.current)
                return;
            const currentWidth = containerRef.current.getBoundingClientRect().width;
            const prevWidth = prevWidthRef.current;
            // If we're expanding, use immediate truncate
            if (prevWidth !== null && currentWidth > prevWidth) {
                truncate();
            }
            else {
                // Window shrinking or initial - using debounced truncate
                debouncedTruncate();
            }
            prevWidthRef.current = currentWidth;
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [truncate, debouncedTruncate]);
    return (_jsxs("ul", { ref: containerRef, className: classNames('box-border p-0 m-0', 'list-none', '*:box-border *:before:box-border *:after:box-border', className), style: style, children: [childArray.slice(0, MAX_ITEMS_RENDER).map((item, i) => (_jsxs(React.Fragment, { children: [_jsx("li", { hidden: true, children: renderTruncator({
                            hiddenItemsCount: i + 1 === MAX_ITEMS_RENDER
                                ? childArray.length - MAX_ITEMS_RENDER
                                : childArray.length - i
                        }) }), _jsx("li", { children: item })] }, i))), _jsx("li", { hidden: true, children: renderTruncator({ hiddenItemsCount: 0 }) })] }));
};
