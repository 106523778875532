import { jsx as _jsx } from "react/jsx-runtime";
import { StarFilledIcon, StarOutlineIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useFavoriteItem } from 'hooks/useFavoriteItem';
const Favorites = ({ isFavourited, id, type }) => {
    const onUpdateFavoriteItem = useFavoriteItem(type, id, isFavourited);
    return (_jsx("div", { className: classNames('flex-row', isFavourited ? 'flex' : 'group-hover:flex hidden'), children: _jsx(IconButton, { icon: isFavourited ? StarFilledIcon : StarOutlineIcon, onClick: (e) => {
                e.preventDefault();
                onUpdateFavoriteItem();
            }, emphasis: "low", size: "tiny", stopPropagation: true, className: "text-yellow-400", dataTestId: `${isFavourited ? 'favorited' : 'not-favorited'}` }) }));
};
export default Favorites;
