import { jsx as _jsx } from "react/jsx-runtime";
import { useStore } from 'config/StoreProvider';
import useFetchSingleWatchlist from 'hooks/useFetchSingleWatchlist';
import useGetCompanyListCustomField from 'hooks/useGetCompanyListCustomField';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import EditCustomFields from './EditCustomFields';
const EditAllCompanyCustomFields = observer(({ open = false, anchorEl, onClose, anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
}, inverted, className = '-mt-p30' }) => {
    const { companyWatchlist } = useFetchSingleWatchlist();
    const customFields = useMemo(() => {
        return companyWatchlist?.customFields || [];
    }, [companyWatchlist]);
    const { getCustomFieldFromCache } = useGetCompanyListCustomField();
    const { rootStore } = useStore();
    const handleSetCustomFieldValues = async ({ customFieldUrn, customFieldValueInput }) => {
        if (!companyWatchlist)
            return;
        rootStore.companyWatchlistViewStore.setAllCustomFieldValues({
            watchlistUrn: companyWatchlist.entityUrn,
            customFieldUrn,
            customFieldValueInput
        });
        onClose?.();
    };
    const handleResetToDefaultValues = async ({ customFieldUrn }) => {
        if (!companyWatchlist)
            return;
        await rootStore.companyWatchlistViewStore.resetAllCustomFieldValues({
            watchlistUrn: companyWatchlist.entityUrn,
            customFieldUrn
        });
    };
    const handleDeleteCustomFieldValues = async ({ customFieldUrn }) => {
        if (!companyWatchlist)
            return;
        await rootStore.companyWatchlistViewStore.deleteAllCustomFieldValues({
            watchlistUrn: companyWatchlist.entityUrn,
            customFieldUrn
        });
    };
    const handleOnClose = () => {
        onClose?.();
    };
    return (_jsx(EditCustomFields, { open: open, anchorEl: anchorEl, onClose: handleOnClose, anchorOrigin: anchorOrigin, inverted: inverted, className: className, getCustomFieldFromCache: getCustomFieldFromCache, customFields: customFields, onSetCustomFieldValues: handleSetCustomFieldValues, onResetToDefaultValues: handleResetToDefaultValues, onDeleteCustomFieldValues: handleDeleteCustomFieldValues }));
});
export default EditAllCompanyCustomFields;
