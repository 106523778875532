import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
const Shimmer = _ref => {
  let {
    className
  } = _ref;
  return _jsx("div", {
    "data-testid": "shimmer",
    className: classNames('animate-shimmer bg-gradient-to-r from-surface-default via-surface-raised to-surface-default bg-[length:200%_100%]', className)
  });
};
export default Shimmer;