import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
export function CommonTable({ columns, data }) {
    if (!data || data.length === 0) {
        return _jsx("div", {});
    }
    return (_jsx("div", { className: "overflow-x-auto max-h-[500px] overflow-y-auto  divide-gray-200 border-border border border-gray-200 border-solid rounded-lg", children: _jsxs("table", { className: "min-w-full", children: [_jsx("thead", { className: "bg-gray-50", children: _jsx("tr", { children: columns.map((column) => (_jsx("th", { className: "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider", children: column.header }, String(column.key)))) }) }), _jsx("tbody", { className: "bg-white divide-y divide-gray-200", children: data.map((item, index) => (_jsx("tr", { children: columns.map((column) => (_jsx("td", { className: "px-6 py-2 whitespace-nowrap text-sm text-gray-900 align-middle", children: column.render
                                ? column.render(item)
                                : String(item[column.key]) }, String(column.key)))) }, index))) })] }) }));
}
