import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Popover } from '@material-ui/core';
import { getIdFromUrn } from 'common/utils/urn';
import useFetchWatchlists from 'hooks/useFetchWatchlists';
import { EntityListModalType, EntityListType, EntityURNTypeToDashboardPath } from 'interfaces/SearchModel/Search';
import { toString } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ADD_COMPANY_WATCHLIST_ERROR_MESSAGE, ADD_PEOPLE_WATCHLIST_ERROR_MESSAGE, DEFAULT_DASHBOARD_ROUTE, DEFAULT_PEOPLE_DASHBOARD_ROUTE } from 'utils/constants';
import { addWatchlist, deleteWatchlist } from 'utils/midtierApi';
import { updateIdtoURN } from 'utils/urn';
import { useShallowStore } from 'stores/zustandStore';
// Icons
import { ReactComponent as UploadIcon } from 'assets/upload.svg';
import { BellIcon, DuplicateOutlineIcon, StarOutlineIcon, TrashcanIcon } from 'assets/harmonic-icons';
import StarFilledIcon from 'assets/harmonic-icons/star-filled';
import VerticalMenuIcon from 'assets/harmonic-icons/vertical-menu';
import { Tooltip } from 'common/components';
import { useStore } from 'config/StoreProvider';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import IconButton from 'harmonic-components/IconButton/IconButton';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import { useCopySavedSearch } from 'hooks/useCopySavedSearch';
import { useDeleteEntityListConfirmationModal } from 'hooks/useDeleteEntityListConfirmationModal';
import { useFavoriteItem } from 'hooks/useFavoriteItem';
import { useUpsertEntityListModal } from 'hooks/useUpsertEntityListModal';
import useDeletePersonWatchlist from 'hooks/Watchlists/useDeletePersonWatchlist';
import { observer } from 'mobx-react';
import { GET_PEOPLE_WATCHLISTS } from 'queries/getPeopleWatchlists';
import { getListsDeleteButtonVisibility, ListDeleteButtonVisibility } from 'utils/lists';
import { logger } from 'utils/logger';
import { displayToast } from 'utils/toasts';
import { hasCreator, hasOwner, isOwnerThisUser, SPECIAL_LIST_TYPES } from '../Collections/utils';
const { COMPANY_SAVED_SEARCH, PEOPLE_SAVED_SEARCH, COMPANY_WATCHLIST, PEOPLE_WATCHLIST } = EntityListType;
const deleteSavedSearchMutation = gql `
  mutation DeleteSavedSearchMutation($idOrUrn: String!) {
    deleteSavedSearch(idOrUrn: $idOrUrn) {
      urn
    }
  }
`;
const EntityListSettingsDropdown = observer(({ placement = 'navbar', setIsAnyDropdownModalOpen, entityType, entityId, notificationSubscriptions, onClose, isPinned }) => {
    const client = useApolloClient();
    const { data: peopleWatchlists } = useQuery(GET_PEOPLE_WATCHLISTS);
    const { userUrn } = useShallowStore(['userUrn']);
    const { watchlists } = useFetchWatchlists();
    const [deleteSavedSearch] = useMutation(deleteSavedSearchMutation);
    const { rootStore } = useStore();
    const [savedSearch, setSavedSearch] = useState(null);
    const userMetadata = useStore().rootStore.authStore.userMetadata;
    const location = useLocation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [entityListUpdateLoading, setEntityListUpdateLoading] = useState(false);
    const { showDeleteEntityListConfirmationModal, isDeleteEntityListConfirmationModalOpen } = useDeleteEntityListConfirmationModal();
    const { showUpsertEntityListModal, isUpsertEntityListModalOpen } = useUpsertEntityListModal();
    const open = Boolean(anchorEl);
    const { makeCopy } = useCopySavedSearch();
    useEffect(() => {
        if (setIsAnyDropdownModalOpen) {
            setIsAnyDropdownModalOpen(isUpsertEntityListModalOpen ||
                isDeleteEntityListConfirmationModalOpen ||
                entityListUpdateLoading);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isDeleteEntityListConfirmationModalOpen,
        isUpsertEntityListModalOpen,
        entityListUpdateLoading
    ]);
    useEffect(() => {
        if ((entityType === COMPANY_SAVED_SEARCH ||
            entityType === PEOPLE_SAVED_SEARCH) &&
            entityId) {
            const getSavedSearch = async () => {
                const { savedSearch } = await rootStore.savedSearchStore.get(entityId);
                setSavedSearch(savedSearch);
            };
            getSavedSearch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId, entityType]);
    const isSearchOwner = (savedSearch?.savedSearchWithDraftState?.creator?.entityUrn || '') ===
        userMetadata?.user_urn;
    const entity = useMemo(() => {
        if (entityType === EntityListType.COMPANY_WATCHLIST) {
            return watchlists?.find((watchlist) => watchlist?.id === entityId);
        }
        if (entityType === EntityListType.PEOPLE_WATCHLIST) {
            return (peopleWatchlists?.getPeopleWatchlistsForTeam ?? []).find((watchlist) => watchlist?.id === entityId);
        }
        if (entityType === EntityListType.COMPANY_SAVED_SEARCH ||
            entityType === EntityListType.PEOPLE_SAVED_SEARCH) {
            return savedSearch?.savedSearchWithDraftState;
        }
        return null;
    }, [
        entityType,
        watchlists,
        entityId,
        peopleWatchlists?.getPeopleWatchlistsForTeam,
        savedSearch
    ]);
    const isEntityOwner = useMemo(() => {
        if (entity && hasOwner(entity)) {
            return isOwnerThisUser(entity.owner, userUrn);
        }
        if (entity && hasCreator(entity)) {
            return isOwnerThisUser(entity.creator, userUrn);
        }
        return false;
    }, [entity, userUrn]);
    const id = open ? 'saved-search-settings' : undefined;
    const { deletePersonWatchlist } = useDeletePersonWatchlist();
    const handleClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleAllClose = () => {
        setAnchorEl(null);
        if (onClose)
            onClose();
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const handleMakeCopy = (e) => {
        e?.preventDefault();
        handlePopoverClose();
        showUpsertEntityListModal({
            entityName: entity?.name,
            entityType: entityType,
            modalType: EntityListModalType.DuplicateList,
            onSubmit: createEntity
        });
    };
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            event.stopPropagation();
        }
        handleAllClose();
    };
    const handleCopyWatchlistId = async (e) => {
        e?.stopPropagation();
        await navigator.clipboard.writeText(entityId);
        displayToast({
            primaryText: 'Copied to clipboard',
            mode: 'success'
        });
    };
    const handlePeopleImportHistory = (event) => {
        event?.stopPropagation();
        navigate(`/dashboard/people_watchlist/urn:harmonic:people_watchlist:${entityId}/imports`);
    };
    const onUpdateFavoriteItem = useFavoriteItem(entityType, entityId, !!isPinned);
    const deleteEntityList = async () => {
        if (!entityId)
            return;
        if (entityType === COMPANY_SAVED_SEARCH ||
            entityType === PEOPLE_SAVED_SEARCH) {
            setEntityListUpdateLoading(true);
            deleteSavedSearch({
                variables: { idOrUrn: entityId },
                update: (cache) => {
                    cache.modify({
                        fields: {
                            getSavedSearchesForTeam(existingRefs, actions) {
                                return existingRefs.filter((savedSearchRef) => {
                                    const id = actions.readField('id', savedSearchRef);
                                    return id !== Number(entityId);
                                });
                            }
                        }
                    });
                }
            })
                .then(async () => {
                displayToast({
                    primaryText: 'Successfully deleted saved search',
                    mode: 'success'
                });
                setEntityListUpdateLoading(false);
                //If we are on very page that is being deleted.
                if (location.pathname.includes(entityId)) {
                    navigate(entityType === EntityListType.PEOPLE_SAVED_SEARCH
                        ? DEFAULT_PEOPLE_DASHBOARD_ROUTE
                        : DEFAULT_DASHBOARD_ROUTE);
                }
            })
                .catch((error) => {
                displayToast({
                    primaryText: 'There was a problem deleting the saved search',
                    mode: 'error'
                });
                logger.error('Company or people saved search delete error', {
                    error
                });
                setEntityListUpdateLoading(false);
            });
        }
        if (entityType === COMPANY_WATCHLIST || entityType === PEOPLE_WATCHLIST) {
            setEntityListUpdateLoading(true);
            try {
                if (entityType === PEOPLE_WATCHLIST) {
                    await deletePersonWatchlist(entityId);
                }
                else {
                    await deleteWatchlist(client, entityId);
                }
                displayToast({
                    primaryText: 'Successfully deleted watchlist',
                    mode: 'success'
                });
                setEntityListUpdateLoading(false);
                //If we are on very page that is being deleted.
                if (location.pathname ===
                    `/dashboard/watchlist/urn:harmonic:${EntityURNTypeToDashboardPath[entityType]}:${toString(entityId)}`) {
                    navigate(DEFAULT_DASHBOARD_ROUTE);
                }
            }
            catch (error) {
                displayToast({
                    primaryText: 'There was a problem deleting watchlist',
                    mode: 'error'
                });
                logger.error('Company or people watchlist delete error', {
                    error
                });
                setEntityListUpdateLoading(false);
            }
        }
    };
    const createEntity = async (feedName) => {
        setEntityListUpdateLoading(true);
        if (entityType === COMPANY_SAVED_SEARCH ||
            entityType === PEOPLE_SAVED_SEARCH) {
            const entityUrn = updateIdtoURN(entityId, entityType);
            try {
                await makeCopy(entityUrn, feedName);
            }
            catch (error) {
                setEntityListUpdateLoading(false);
            }
            finally {
                setEntityListUpdateLoading(false);
            }
        }
        if (entityType === COMPANY_WATCHLIST || entityType === PEOPLE_WATCHLIST) {
            const payload = {
                name: feedName,
                shared_with_team: false,
                ...(entityType === COMPANY_WATCHLIST
                    ? { companies: [] }
                    : { people: [] })
            };
            try {
                const response = await addWatchlist(client, payload);
                displayToast({
                    primaryText: 'New list successfully created',
                    mode: 'success'
                });
                const toNavigateRoute = `/dashboard/watchlist/urn:harmonic:${EntityURNTypeToDashboardPath[entityType]}:${toString(getIdFromUrn(response.urn))}`;
                navigate(toNavigateRoute);
            }
            catch (error) {
                displayToast({
                    primaryText: entityType === COMPANY_WATCHLIST
                        ? ADD_COMPANY_WATCHLIST_ERROR_MESSAGE
                        : ADD_PEOPLE_WATCHLIST_ERROR_MESSAGE,
                    mode: 'error'
                });
                logger.error('Add watchlist error', { error });
            }
            finally {
                setEntityListUpdateLoading(false);
            }
        }
    };
    const isWatchlist = entityType === COMPANY_WATCHLIST || entityType === PEOPLE_WATCHLIST;
    const handleTogglePinMode = (event) => {
        event?.stopPropagation();
        handleAllClose();
        onUpdateFavoriteItem();
    };
    const handleSlackAlertButtonClick = (event) => {
        event?.stopPropagation();
        navigate('/settings?t=notifications');
    };
    const handleCompanyImportHistory = (event) => {
        event?.stopPropagation();
        navigate(`/dashboard/company_watchlist/urn:harmonic:company_watchlist:${entityId}/imports`);
    };
    const handleDelete = (event) => {
        event?.stopPropagation();
        handlePopoverClose();
        showDeleteEntityListConfirmationModal({
            onDeleteEntityList: deleteEntityList,
            entityType: entityType,
            entityName: entity?.name ?? ''
        });
    };
    const hasSetupNotifications = notificationSubscriptions && notificationSubscriptions.length > 0;
    const isCreatedByHarmonic = (entityType === EntityListType.COMPANY_WATCHLIST ||
        entityType === EntityListType.PEOPLE_WATCHLIST) &&
        SPECIAL_LIST_TYPES.includes(
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        entity
            ?.userWatchlistType);
    const { visibility: deleteButtonVisibility, tooltipText: deleteButtonTooltipText } = getListsDeleteButtonVisibility({
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        watchlistType: entity
            ?.userWatchlistType,
        watchlistOwnerUrn: 
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        entity?.owner
            ?.entityUrn ?? '',
        currentUserRole: rootStore.authStore.role
    });
    return (_jsxs(_Fragment, { children: [placement === 'navbar' ? (_jsx(IconButton, { dataTestId: `EntityListSettingsDropdown-SettingsIcon-${entityId}`, emphasis: "low", size: "tiny", icon: () => (_jsx(VerticalMenuIcon, { className: "w-4 h-4 text-content-weak" })), onClick: handleClick, stopPropagation: true })) : (_jsx(IconButton, { dataTestId: `EntityListSettingsDropdown-Header-SettingsIcon-${entityId}`, onClick: handleClick, icon: VerticalMenuIcon })), _jsx(Popover, { id: id, open: open, anchorEl: anchorEl, classes: {
                    paper: 
                    // HACK: Need to add padding to we create room for the children shadow to show
                    // Otherwise it gets cut off
                    'rounded-none bg-transparent border-transparent p-p30 -mt-p30 -ml-p30'
                }, elevation: 0, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: placement === 'navbar' ? 'left' : 'right'
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: placement === 'navbar' ? 'left' : 'right'
                }, disableAutoFocus: true, disableEnforceFocus: true, children: _jsxs(Dropdown, { children: [(entityType === COMPANY_SAVED_SEARCH ||
                            entityType === PEOPLE_SAVED_SEARCH) &&
                            entity ? (_jsx(ListItem, { testId: `EntityListSettingsDropdown-Save-as-new-search-${entityId}`, variant: ListVariant.default, label: "Make a copy", onClick: handleMakeCopy, value: "make-copy", primaryIcon: () => (_jsx(DuplicateOutlineIcon, { className: "w-4 h-4 text-content-default" })) })) : null, (entityType === COMPANY_WATCHLIST ||
                            entityType == PEOPLE_WATCHLIST) && (_jsx(ListItem, { variant: ListVariant.default, label: "Copy watchlist ID", onClick: handleCopyWatchlistId, value: "copy-watchlist-id", primaryIcon: () => (_jsx(DuplicateOutlineIcon, { className: "w-4 h-4 text-content-default" })) })), entityType == PEOPLE_WATCHLIST && (_jsx(ListItem, { variant: ListVariant.default, label: "View import history", onClick: handlePeopleImportHistory, value: "view-import-history", primaryIcon: () => (_jsx(UploadIcon, { className: "w-4 h-4 text-content-default" })) })), entityType == COMPANY_WATCHLIST && (_jsx(ListItem, { variant: ListVariant.default, label: "View import history", onClick: handleCompanyImportHistory, value: "view-import-history", primaryIcon: () => (_jsx(UploadIcon, { className: "w-4 h-4 text-content-default" })) })), _jsx(ListItem, { testId: `EntityListSettingsDropdown-Toggle-favorites-${entityId}`, variant: ListVariant.default, label: isPinned ? 'Unfavorite' : 'Favorite', onClick: handleTogglePinMode, value: "favorite-unfavorite", primaryIcon: () => isPinned ? (_jsx(StarFilledIcon, { className: "w-4 h-4 text-content-default" })) : (_jsx(StarOutlineIcon, { className: "w-4 h-4 text-content-default" })) }), isSearchOwner && entityType === COMPANY_SAVED_SEARCH ? (_jsx(ListItem, { testId: `EntityListSettingsDropdown-notification-settings-${entityId}`, variant: ListVariant.default, label: hasSetupNotifications
                                ? 'Manage Slack alert'
                                : 'Create Slack alert', onClick: handleSlackAlertButtonClick, value: "slack-alerts", primaryIcon: () => (_jsx(BellIcon, { className: "w-4 h-4 text-content-default" })) })) : null, !isWatchlist && isEntityOwner && !isCreatedByHarmonic ? (_jsx(ListItem, { testId: `EntityListSettingsDropdown-delete-${entityId}`, variant: ListVariant.default, label: "Delete", onClick: handleDelete, value: "delete", primaryIcon: () => (_jsx(TrashcanIcon, { className: "w-4 h-4 text-content-default" })) })) : null, isWatchlist &&
                            (deleteButtonVisibility === ListDeleteButtonVisibility.ENABLED ||
                                deleteButtonVisibility ===
                                    ListDeleteButtonVisibility.DISABLED_WITH_TOOLTIP) ? (_jsx(Tooltip, { fullWidth: true, title: deleteButtonTooltipText ?? '', children: _jsx(ListItem, { testId: `EntityListSettingsDropdown-delete-${entityId}`, variant: ListVariant.default, label: "Delete", onClick: handleDelete, value: "delete", primaryIcon: () => (_jsx(TrashcanIcon, { className: "w-4 h-4 text-content-default" })), disabled: entityListUpdateLoading ||
                                    deleteButtonVisibility ===
                                        ListDeleteButtonVisibility.DISABLED_WITH_TOOLTIP }) })) : null] }) })] }));
});
export default EntityListSettingsDropdown;
