import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '@material-ui/core';
import { AffinityIcon, CompanyListIcon, PlusIcon } from 'assets/harmonic-icons';
import CircleCheckIcon from 'assets/harmonic-icons/circle-check';
import CircleMinus from 'assets/harmonic-icons/circle-minus';
import classNames from 'classnames';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import ListItem, { ListType, ListVariant } from 'harmonic-components/ListItem/ListItem';
import { useAddEntityListModal } from 'hooks/useAddEntityListModal';
import useFetchWatchlists from 'hooks/useFetchWatchlists';
import { useModal } from 'hooks/useModal';
import { EntityListType, INITIAL_SEARCH_MODEL } from 'interfaces/SearchModel/Search';
import { useMemo, useState } from 'react';
import { UserWatchlistType } from 'utils/watchlist';
import { useWatchlistActions } from '../../../hooks/useWatchlistActions';
import AddCompanyToAffinityDetails from '../CompanyActions/AddCompanyToAffinityDetails';
import CRMNotSetUpYetPopup from '../CompanyActions/CRMNotSetupPopup';
import RemoveCompanyFromAffinity from '../CompanyActions/RemoveCompanyFromAffinity';
import { HarmonicLoader } from '../ResultsWrapper/LoadingOverlay';
const ToggleCompanyListsDropdown = ({ companyIds, shouldNavigate, open = false, anchorEl, onClose, anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
}, inverted, className = '-mt-p30', variant = ListVariant.checkboxes, onClick = Function.prototype, displayAffinityLists = false, displayAddToAffinityButton = false }) => {
    const { show } = useModal();
    const [loadingKeys, setLoadingKeys] = useState([]);
    const { addCompaniesToWatchlist, removeCompaniesFromWatchlist, getWatchlistContainingCompanies } = useWatchlistActions();
    const { watchlists: companyWatchlists, loading, affinityLists } = useFetchWatchlists();
    const [filterTerm, setFilterTerm] = useState('');
    const onCompanyListCreatedSuccess = async ({ entityListId }) => {
        if (variant === ListVariant.default) {
            await onClick(entityListId);
            return;
        }
        await addCompaniesToWatchlist({
            watchlistId: entityListId,
            shouldNavigate,
            companyIds: companyIds
        });
    };
    const { showAddEntityListModal: showAddCompanyListModal } = useAddEntityListModal({
        disableNavigation: variant === ListVariant.default,
        entityType: EntityListType.COMPANY_WATCHLIST,
        searchQuery: INITIAL_SEARCH_MODEL,
        onSuccess: onCompanyListCreatedSuccess,
        disableToast: variant === ListVariant.default
    });
    const selectedCompanyLists = getWatchlistContainingCompanies(companyWatchlists, companyIds);
    const selectedAffinityLists = getWatchlistContainingCompanies(affinityLists, companyIds);
    const selectedLists = useMemo(() => [...selectedCompanyLists, ...selectedAffinityLists], [selectedCompanyLists, selectedAffinityLists]);
    //User Managed watchlist appears at bottom, selected lists at the top
    const typeFilteredWatchlists = useMemo(() => companyWatchlists?.filter((watchlist) => watchlist?.userWatchlistType ===
        UserWatchlistType.USER_MANAGED_WATCHLIST ||
        watchlist?.userWatchlistType ===
            UserWatchlistType.CUSTOMER_MANAGED_WATCHLIST ||
        (displayAffinityLists &&
            watchlist?.userWatchlistType ===
                UserWatchlistType.CUSTOMER_AFFINITY_SYNCED_LIST)), [companyWatchlists, displayAffinityLists]);
    const filteredCompanyWatchlists = useMemo(() => typeFilteredWatchlists
        ?.filter((list) => list?.name.toLowerCase().includes(filterTerm.toLowerCase()))
        .sort((a, b) => {
        // First sort by selection status
        const aSelected = selectedLists?.includes(a?.id ?? '');
        const bSelected = selectedLists?.includes(b?.id ?? '');
        if (aSelected !== bSelected) {
            return aSelected ? -1 : 1;
        }
        // Then sort by list type (affinity lists come before normal watchlists)
        const aIsAffinity = a?.userWatchlistType ===
            UserWatchlistType.CUSTOMER_AFFINITY_SYNCED_LIST;
        const bIsAffinity = b?.userWatchlistType ===
            UserWatchlistType.CUSTOMER_AFFINITY_SYNCED_LIST;
        if (aIsAffinity !== bIsAffinity) {
            return aIsAffinity ? -1 : 1;
        }
        // If they're the same type, keep original order
        return 0;
    }), [typeFilteredWatchlists, filterTerm, selectedLists]);
    const onChangeAffinityList = async (listId) => {
        const isSelected = selectedLists?.includes(listId ?? '');
        if (isSelected) {
            show(_jsx(RemoveCompanyFromAffinity, { companyId: companyIds[0], affinityListId: listId }));
        }
        else {
            show(_jsx(AddCompanyToAffinityDetails, { companyId: companyIds[0], affinityListId: listId }));
        }
    };
    const handleAddToAffinityClick = () => {
        show(_jsx(CRMNotSetUpYetPopup, {}));
    };
    const onChange = async (key) => {
        if (!key)
            return;
        const affinityList = affinityLists?.find((list) => list?.id === key);
        if (affinityList && displayAffinityLists) {
            onChangeAffinityList(key);
            return;
        }
        try {
            if (!selectedLists?.includes(key)) {
                setLoadingKeys((prev) => {
                    return prev.concat(key);
                });
                await addCompaniesToWatchlist({
                    companyIds,
                    shouldNavigate,
                    watchlistId: key
                });
            }
            else {
                await removeCompaniesFromWatchlist({
                    watchlistId: key,
                    companyIds,
                    confirmBeforeRemoval: true,
                    onConfirmRemoval: async () => {
                        setLoadingKeys((prev) => {
                            return prev.concat(key);
                        });
                    }
                });
            }
        }
        finally {
            setLoadingKeys((prev) => {
                return prev.filter((k) => k !== key);
            });
        }
    };
    return (_jsx(Popover, { open: open, anchorEl: anchorEl, 
        //eslint-disable-next-line react/jsx-handler-names
        onClose: onClose, anchorOrigin: anchorOrigin, elevation: 0, className: "mt-1", classes: {
            paper: 
            // HACK: Need to add padding to we create room for the children shadow to show
            // Otherwise it gets cut off
            classNames('rounded-none bg-transparent border-transparent p-p30 -ml-p30', className)
        }, disableAutoFocus: true, disableEnforceFocus: true, children: _jsx("div", { className: classNames('w-64 overflow-hidden', !inverted && 'visible-scrollbar', inverted && 'inverted-scrollbar'), children: _jsxs(Dropdown
            //eslint-disable-next-line react/jsx-handler-names
            , { 
                //eslint-disable-next-line react/jsx-handler-names
                onFilterTermChange: (value) => setFilterTerm(value), filterTerm: filterTerm, filterPlaceholder: "Search", dataTestId: "AddCompanyToWatchlist-Dropdown", inverted: inverted, children: [_jsxs("div", { className: classNames('overflow-y-scroll py-p20 w-full', inverted && 'text-white'), children: [loading ? _jsx(HarmonicLoader, { showText: false }) : null, filteredCompanyWatchlists?.map((list) => {
                                let primaryIcon = undefined;
                                if (list?.userRelevanceScoreInfluenceType === 'DISSIMILAR') {
                                    primaryIcon = () => (_jsx(CircleMinus, { className: "w-4 h-4 text-content-weak" }));
                                }
                                if (list?.userRelevanceScoreInfluenceType === 'SIMILAR') {
                                    primaryIcon = () => (_jsx(CircleCheckIcon, { className: "w-4 h-4 text-yellow" }));
                                }
                                if (list?.userWatchlistType ===
                                    UserWatchlistType.CUSTOMER_AFFINITY_SYNCED_LIST) {
                                    primaryIcon = () => (_jsx(AffinityIcon, { className: "w-4 h-4 text-content-weak" }));
                                }
                                if (list?.userWatchlistType ===
                                    UserWatchlistType.USER_MANAGED_WATCHLIST &&
                                    displayAffinityLists) {
                                    primaryIcon = () => (_jsx(CompanyListIcon, { className: "w-4 h-4 text-content-weak" }));
                                }
                                const listItemProps = {
                                    type: inverted ? ListType.inverted : ListType.default,
                                    testId: `list-item-${list?.id}`,
                                    label: list?.name || '',
                                    value: list?.id || '',
                                    selected: selectedLists?.includes(list?.id ?? ''),
                                    primaryIcon,
                                    primaryIconTooltip: list?.userRelevanceScoreInfluenceType === 'SIMILAR'
                                        ? 'This is a Recommend list'
                                        : list?.userRelevanceScoreInfluenceType === 'DISSIMILAR'
                                            ? 'This is a Do not Recommend list'
                                            : undefined,
                                    loading: loadingKeys.includes(list?.id ?? '')
                                };
                                if (variant === ListVariant.default) {
                                    return (_createElement(ListItem, { ...listItemProps, key: list?.id, variant: ListVariant.default, 
                                        //eslint-disable-next-line react/jsx-handler-names
                                        onClick: () => onClick(list?.id) }));
                                }
                                return (_createElement(ListItem, { ...listItemProps, key: list?.id, variant: ListVariant.checkboxes, 
                                    //eslint-disable-next-line react/jsx-handler-names
                                    onChange: () => onChange(list?.id) }));
                            })] }), _jsx("div", { className: classNames('w-full h-[2px]', !inverted && 'bg-border', inverted && 'bg-border-inverted-weak') }), !!displayAddToAffinityButton && (_jsx(ListItem, { type: ListType.default, variant: ListVariant.default, primaryIcon: AffinityIcon, label: "Add to Affinity", 
                        //eslint-disable-next-line react/jsx-handler-names
                        onClick: handleAddToAffinityClick, value: "add-to-new-list" })), _jsx(ListItem, { type: inverted ? ListType.inverted : ListType.default, variant: ListVariant.default, primaryIcon: PlusIcon, label: "Add to new list", 
                        //eslint-disable-next-line react/jsx-handler-names
                        onClick: showAddCompanyListModal, value: "add-to-new-list" })] }) }) }));
};
export default ToggleCompanyListsDropdown;
