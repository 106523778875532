import { PeopleWatchlistType, TeamRole } from '__generated__/graphql';
import useStore from 'stores/zustandStore';
import { UserWatchlistType } from './watchlist';
export var ListDeleteButtonVisibility;
(function (ListDeleteButtonVisibility) {
    ListDeleteButtonVisibility["ENABLED"] = "ENABLED";
    //delete button hidden altogether
    ListDeleteButtonVisibility["HIDDEN"] = "HIDDEN";
    // Delete button disabled and tooltip is shown. But it's visible
    ListDeleteButtonVisibility["DISABLED_WITH_TOOLTIP"] = "DISABLED_WITH_TOOLTIP";
})(ListDeleteButtonVisibility || (ListDeleteButtonVisibility = {}));
const ONLY_OWNER_CAN_EDIT_TOOLTIP_TEXT = 'Only the list owner can delete this list';
const ONLY_OWNER_OR_ADMIN_CAN_EDIT_TOOLTIP_TEXT = 'Only the list owner or admin can delete this list';
export const getListsDeleteButtonVisibility = ({ watchlistType, watchlistOwnerUrn, currentUserRole = TeamRole.MEMBER }) => {
    if (watchlistType === PeopleWatchlistType.TEAM_CONNECTIONS ||
        watchlistType === PeopleWatchlistType.USER_CONNECTIONS ||
        watchlistType === UserWatchlistType.USER_HIDDEN_ENTITIES ||
        watchlistType === UserWatchlistType.USER_FAVORITED_ENTITIES) {
        return {
            visibility: ListDeleteButtonVisibility.HIDDEN
        };
    }
    const currentUserUrn = useStore.getState().userUrn;
    if (currentUserUrn !== watchlistOwnerUrn &&
        watchlistType === UserWatchlistType.USER_MANAGED_WATCHLIST) {
        return {
            visibility: ListDeleteButtonVisibility.DISABLED_WITH_TOOLTIP,
            tooltipText: ONLY_OWNER_CAN_EDIT_TOOLTIP_TEXT
        };
    }
    // Allow admins to delete affinitiy or api created lists
    if (currentUserUrn !== watchlistOwnerUrn &&
        currentUserRole !== TeamRole.ADMIN &&
        currentUserRole !== TeamRole.LIMITED_ADMIN &&
        (watchlistType === UserWatchlistType.CUSTOMER_AFFINITY_SYNCED_LIST ||
            watchlistType === UserWatchlistType.CUSTOMER_MANAGED_WATCHLIST)) {
        return {
            visibility: ListDeleteButtonVisibility.DISABLED_WITH_TOOLTIP,
            tooltipText: ONLY_OWNER_OR_ADMIN_CAN_EDIT_TOOLTIP_TEXT
        };
    }
    return {
        visibility: ListDeleteButtonVisibility.ENABLED
    };
};
