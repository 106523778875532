import { jsx as _jsx } from "react/jsx-runtime";
import { getPeopleWatchlistCustomFieldHeaders } from 'components/Dashboard/Grids/ColumnDefinitions/WatchlistCustomColumnHeaders';
import useFetchSinglePeopleWatchlist from 'hooks/useFetchSinglePeopleWatchlist';
import useFlags from 'hooks/useFlags';
import PersonColumnsConfig from 'interfaces/PersonColumnsConfig';
import { observer } from 'mobx-react';
import { SPLITS } from 'utils/constants';
import { mapCustomFieldsToColumnConfig } from 'utils/custom-fields';
import { BaseFieldsDropdownItems } from './BaseFieldsDropdownItems';
const PeopleWatchlistFieldsDropdownItems = ({ onFieldClick, onCreateNewField, filterTerm }) => {
    const { enabled: enableAddedByColumn } = useFlags(SPLITS.enableAddedByColumn);
    const { peopleWatchlist } = useFetchSinglePeopleWatchlist();
    const customFields = mapCustomFieldsToColumnConfig(getPeopleWatchlistCustomFieldHeaders(peopleWatchlist?.customFields ?? []), peopleWatchlist?.customFields ?? []);
    return (_jsx(BaseFieldsDropdownItems, { onFieldClick: onFieldClick, onCreateNewField: onCreateNewField, filterTerm: filterTerm, harmonicFields: Object.values(PersonColumnsConfig), customFields: customFields, hiddenFields: enableAddedByColumn ? [] : [PersonColumnsConfig.ADDED_TO_LIST_BY.value] }));
};
export default observer(PeopleWatchlistFieldsDropdownItems);
