import { useMutation } from '@apollo/client';
import { getIdFromUrn } from 'common/utils/urn';
import { CREATE_PEOPLE_WATCHLIST } from 'queries/createPeopleWatchlist';
import { GET_PEOPLE_WATCHLISTS } from 'queries/getPeopleWatchlists';
import { useNavigate } from 'react-router-dom';
import useStore from 'stores/zustandStore';
import { ADD_PEOPLE_WATCHLIST_ERROR_MESSAGE } from '../../utils/constants';
import { logger } from '../../utils/logger';
import { displayToast } from '../../utils/toasts';
const useCreatePeopleList = () => {
    const [createPeopleWatchlist] = useMutation(CREATE_PEOPLE_WATCHLIST);
    const navigate = useNavigate();
    const editStoreData = useStore((state) => state.editStoreData);
    const createPeopleList = async (name, disableNavigation = false, disableToast = false, onSuccess) => {
        const payload = {
            watchlistInput: {
                name,
                sharedWithTeam: false,
                people: []
            }
        };
        try {
            const { data } = await createPeopleWatchlist({
                variables: payload,
                // Add new watchlist to watchlists in cache
                update: (cache, { data }) => {
                    const res = cache.readQuery({ query: GET_PEOPLE_WATCHLISTS });
                    const existingWatchlists = res?.getPeopleWatchlistsForTeam ?? [];
                    cache.writeQuery({
                        query: GET_PEOPLE_WATCHLISTS,
                        data: {
                            getPeopleWatchlistsForTeam: [
                                ...existingWatchlists,
                                { ...data?.createPeopleWatchlist }
                            ]
                        }
                    });
                }
            });
            const watchlistUrn = data?.createPeopleWatchlist?.entityUrn;
            const notificationMessage = `${name} list successfully created`;
            if (!disableToast) {
                displayToast({
                    primaryText: notificationMessage,
                    mode: 'success'
                });
            }
            if (onSuccess) {
                await onSuccess({
                    entityListId: getIdFromUrn(watchlistUrn) ?? '',
                    entityListName: name
                });
            }
            if (disableNavigation) {
                if (!disableToast) {
                    displayToast({
                        primaryText: notificationMessage,
                        secondaryText: 'Go to list',
                        link: `/dashboard/people_watchlist/${watchlistUrn}`
                    });
                }
                return;
            }
            editStoreData('pageTitle', name);
            const toNavigateRoute = `/dashboard/people_watchlist/${watchlistUrn}`;
            navigate(toNavigateRoute);
        }
        catch (error) {
            logger.error('People watchlist create error', {
                error
            });
            displayToast({
                primaryText: ADD_PEOPLE_WATCHLIST_ERROR_MESSAGE,
                mode: 'error'
            });
        }
    };
    return {
        createPeopleList
    };
};
export default useCreatePeopleList;
