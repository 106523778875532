import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '@material-ui/core';
import { AffinityIcon, CircleCheckIcon } from 'assets/harmonic-icons';
import { Tooltip } from 'common/components/Tooltip';
import Button from 'harmonic-components/Button/Button';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import useFetchWatchlists from 'hooks/useFetchWatchlists';
import useFlags from 'hooks/useFlags';
import { useModal } from 'hooks/useModal';
import { useEffect, useState } from 'react';
import { SPLITS } from 'utils/constants';
import { getCustomerAffinityApiKeyIsSet } from 'utils/midtierApi';
import { useWatchlistActions } from '../../../hooks/useWatchlistActions';
import AddCompanyToAffinityDetails from './AddCompanyToAffinityDetails';
import AddCompanytoWatchlistButtonContentLoader from './AddCompanyToWatchlistButtonContentLoader';
import CRMNotSetUpYetPopup from './CRMNotSetupPopup';
import RemoveCompanyFromAffinity from './RemoveCompanyFromAffinity';
const AddCompanyToAffinityButton = ({ companyId, isDisabled, tooltipText }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterTerm, setFilterTerm] = useState('');
    const { affinityLists } = useFetchWatchlists();
    const { getWatchlistContainingCompanies } = useWatchlistActions();
    const { show } = useModal();
    const [isAffinityIntegrated, setIsAffinityIntegrated] = useState(false);
    const [loadingAffinityIntegrationStatus, setLoadingAffinityIntegrationStatus] = useState(false);
    const { enabled: affinityIntegrationMocked } = useFlags(SPLITS.mockAffinityFrontend);
    useEffect(() => {
        setLoadingAffinityIntegrationStatus(true);
        if (affinityIntegrationMocked) {
            setIsAffinityIntegrated(true);
            setLoadingAffinityIntegrationStatus(false);
            return;
        }
        getCustomerAffinityApiKeyIsSet()
            .then((isSet) => {
            if (isSet) {
                setIsAffinityIntegrated(isSet);
            }
        })
            .finally(() => setLoadingAffinityIntegrationStatus(false));
    }, [affinityIntegrationMocked]);
    const handleClose = (isSelected, affinityListId) => {
        setFilterTerm('');
        setAnchorEl(null);
        if (isSelected) {
            show(_jsx(RemoveCompanyFromAffinity, { companyId: companyId, affinityListId: affinityListId }));
        }
        else {
            show(_jsx(AddCompanyToAffinityDetails, { companyId: companyId, affinityListId: affinityListId }));
        }
    };
    const handleOpen = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleAddToCRMClick = () => {
        show(_jsx(CRMNotSetUpYetPopup, {}));
    };
    const open = Boolean(anchorEl);
    const affinityListsContainingCompany = getWatchlistContainingCompanies(affinityLists, [companyId]);
    const sortedAffinitiyLists = affinityLists.sort((a, b) => {
        const isSelectedA = affinityListsContainingCompany.some((affinityWatchlistIdContainingCompany) => affinityWatchlistIdContainingCompany === a.id);
        const isSelectedB = affinityListsContainingCompany.some((affinityWatchlistIdContainingCompany) => affinityWatchlistIdContainingCompany === b.id);
        // If one of them is selected and the other is not, the selected one should come first
        if (isSelectedA && !isSelectedB) {
            return -1;
        }
        else if (!isSelectedA && isSelectedB) {
            return 1;
        }
        else {
            // If both are selected or both are not selected, sort alphabetically
            return a.name.localeCompare(b.name);
        }
    });
    const filteredAffinityLists = sortedAffinitiyLists.filter((affinityList) => affinityList.name.toLowerCase().includes(filterTerm.toLocaleLowerCase()));
    if (loadingAffinityIntegrationStatus) {
        return _jsx(AddCompanytoWatchlistButtonContentLoader, {});
    }
    if (!isAffinityIntegrated) {
        return _jsx(Button, { onClick: handleAddToCRMClick, label: "Add to CRM" });
    }
    const handleFilterTermChange = (value) => {
        setFilterTerm(value);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: tooltipText, children: _jsx(Button, { dataTestId: "AddCompanyToAffinityButton", leadingIcon: affinityListsContainingCompany.length > 0
                        ? CircleCheckIcon
                        : AffinityIcon, onClick: handleOpen, label: affinityListsContainingCompany.length > 0
                        ? 'In Affinity'
                        : 'Add to Affinity', isDisabled: isDisabled, isSelected: open, size: "compact" }) }), _jsx(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }, className: "mt-p40", children: _jsx("div", { className: "w-[264px] truncate line-clamp-1", children: _jsx(Dropdown, { filterPlaceholder: "Search Affinity lists", filterTerm: filterTerm, onFilterTermChange: handleFilterTermChange, children: filteredAffinityLists.map((affinityList) => {
                            const isSelected = affinityListsContainingCompany.some((affinityWatchlistIdContainingCompany) => affinityWatchlistIdContainingCompany === affinityList.id);
                            return (_jsx(ListItem, { variant: ListVariant.checkboxes, label: affinityList.name, value: affinityList.id, onChange: () => handleClose(isSelected, affinityList.id), selected: isSelected, primaryIcon: AffinityIcon }, affinityList.id));
                        }) }) }) })] }));
};
export default AddCompanyToAffinityButton;
