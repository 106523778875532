import client from 'config/client';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import useTableStore from 'stores/tableStore';
import { GRID_MAX_SELECT_ALL_ROWS, SPLITS } from 'utils/constants';
import { logger } from 'utils/logger';
import { v4 as uuidv4 } from 'uuid';
import { createNewCustomFieldValue, mapCustomFieldValueInputToCacheValue } from './utils';
class WatchlistViewStore {
    rootStore;
    selectedEntryUrns = new Set();
    watchlist = null;
    upsertCustomFieldValueLoading;
    namedViewDraftStateByUrn = {};
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.upsertCustomFieldValueLoading = false;
        makeObservable(this, {
            syncing: computed,
            setUpsertCustomFieldValueLoading: action,
            upsertCustomFieldValueLoading: observable,
            rootStore: observable,
            namedViewDraftStateByUrn: observable,
            observeWatchlistResults: false,
            asyncEnabled: true,
            resetStore: true,
            selectedEntryUrns: observable,
            numItemsActionable: computed,
            addSelectedEntryUrns: action,
            optimisticUpdateCustomFieldValueByEntryUrns: action,
            removeSelectedEntryUrns: action,
            clearSelectedEntryUrns: action,
            setTotalCount: action,
            localSearchTermSelector: false,
            selectedNamedViewSelector: false,
            watchlist: observable
        });
        reaction(() => this.watchlist?.currentTotalCount, (totalCount) => this.setTotalCount(totalCount), {
            fireImmediately: true
        });
    }
    get numItemsActionable() {
        const count = this.watchlist?.currentTotalCount || 0;
        return count > GRID_MAX_SELECT_ALL_ROWS ? GRID_MAX_SELECT_ALL_ROWS : count;
    }
    get asyncEnabled() {
        return this.rootStore.splitStore.getFeature(SPLITS.enableAsyncBulkActions);
    }
    get syncing() {
        return this.upsertCustomFieldValueLoading;
    }
    setUpsertCustomFieldValueLoading(loading) {
        this.upsertCustomFieldValueLoading = loading;
    }
    addSelectedEntryUrns(entryUrns) {
        entryUrns.forEach((urn) => this.selectedEntryUrns.add(urn));
    }
    removeSelectedEntryUrns(entryUrns) {
        entryUrns.forEach((urn) => this.selectedEntryUrns.delete(urn));
    }
    clearSelectedEntryUrns() {
        this.selectedEntryUrns.clear();
    }
    setTotalCount(totalCount) {
        useTableStore.getState().editTableStoreData('totalCount', totalCount);
    }
    resetStore() {
        this.selectedEntryUrns = new Set();
        this.watchlist = null;
        this.setUpsertCustomFieldValueLoading(false);
        this.setTotalCount(undefined);
    }
    optimisticUpdateCustomFieldValueByEntryUrns({ entryUrns, customFieldUrn, customFieldValueInput, entryDefinition, customFieldValueDefinition, customFieldDefinition }) {
        for (const entryUrn of entryUrns) {
            const entryId = client.cache.identify({
                __typename: entryDefinition.__typename,
                entryUrn: entryUrn
            });
            const listEntry = client.cache.readFragment({
                id: entryId,
                fragment: entryDefinition.fragment,
                fragmentName: entryDefinition.fragmentName
            });
            let existingValueFound = false;
            const updatedPendingFields = [];
            listEntry?.pendingFields?.forEach((pendingField) => {
                if (pendingField) {
                    updatedPendingFields.push(pendingField);
                }
            });
            if (listEntry) {
                const pendingField = {
                    __typename: 'PendingField',
                    action: 'upsert',
                    customFieldUrn
                };
                updatedPendingFields.push(pendingField);
                //Update custom field value if it's already set
                const updatedCustomFieldValues = listEntry.customFieldValues?.map((fieldValue) => {
                    if (fieldValue.customField.urn === customFieldUrn) {
                        existingValueFound = true;
                        return {
                            ...fieldValue,
                            data: {
                                ...fieldValue.data,
                                ...mapCustomFieldValueInputToCacheValue(customFieldValueInput)
                            }
                        };
                    }
                    return fieldValue;
                }) ?? [];
                // If custom field value is not set in the first place, create a temporary one
                if (!existingValueFound) {
                    const customField = client.cache.readFragment({
                        id: client.cache.identify({
                            __typename: customFieldDefinition.__typename,
                            urn: customFieldUrn
                        }),
                        fragment: customFieldDefinition.fragment,
                        fragmentName: customFieldDefinition.fragmentName
                    });
                    if (!customField) {
                        logger.error('Custom field not found in optimistic update');
                        continue;
                    }
                    const newCustomFieldValue = createNewCustomFieldValue({
                        processingUrn: `urn:harmonic:company_list_custom_field_value:processing-urn-${uuidv4()}`,
                        variables: {
                            customFieldUrn,
                            companyWatchlistEntryUrn: entryUrn,
                            customFieldValueInput
                        },
                        customField: customField,
                        customFieldValueDefinition: customFieldValueDefinition.__typename
                    });
                    updatedCustomFieldValues.push(newCustomFieldValue);
                }
                client.cache.writeFragment({
                    id: entryId,
                    fragment: entryDefinition.fragment,
                    fragmentName: entryDefinition.fragmentName,
                    data: {
                        ...listEntry,
                        customFieldValues: updatedCustomFieldValues,
                        pendingFields: updatedPendingFields
                    }
                });
            }
        }
    }
}
export default WatchlistViewStore;
