import { getIdFromUrn } from 'common/utils/urn';
import { makeAutoObservable } from 'mobx';
import { logger } from 'utils/logger';
class CompanyDetailDrawerStore {
    rootStore;
    isOpen = false;
    company = null;
    selectedTab = 'overview';
    loading = false;
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }
    openDrawer = async (companyUrn) => {
        const companyIdString = getIdFromUrn(companyUrn);
        if (!companyIdString) {
            logger.error('Company Urn is not valid', { companyUrn });
            return;
        }
        const companyId = parseInt(companyIdString);
        this.loading = true;
        this.isOpen = true;
        try {
            const { company } = await this.rootStore.companyStore.get(companyId);
            if (company) {
                this.company = company;
                this.selectedTab = 'overview';
            }
        }
        catch (error) {
            logger.error('Failed to fetch company data', { error });
        }
        finally {
            this.loading = false;
        }
    };
    closeDrawer = () => {
        this.isOpen = false;
        this.company = null;
        this.loading = false;
    };
    setSelectedTab = (tab) => {
        this.selectedTab = tab;
    };
}
export default CompanyDetailDrawerStore;
