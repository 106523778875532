import { gql } from '@apollo/client';
export const REMOVE_COMPANIES_FROM_WATCHLIST = gql `
  mutation RemoveCompaniesFromWatchlist($id: String!, $companies: [String]!) {
    removeCompaniesFromWatchlistWithIds(id: $id, companies: $companies) {
      id
      entityUrn
      companyIds
      name
      sharedWithTeam
      userWatchlistType
    }
  }
`;
