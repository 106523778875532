import { getIdFromUrn } from 'common/utils/urn';
import { FilterArrayComparator, INITIAL_COMPANY_SORT, INITIAL_PEOPLE_SORT, INITIAL_SEARCH_MODEL } from 'interfaces/SearchModel/Search';
import { cloneDeep, get } from 'lodash';
import { GRID_INITIAL_PAGE_SIZE } from './constants';
import { getModifiedCompanySortValueAfterValidationCheck, getModifiedPeopleSortValueAfterValidationCheck } from './sort';
import { camelize } from './utilities';
export var UserWatchlistType;
(function (UserWatchlistType) {
    UserWatchlistType["USER_MANAGED_WATCHLIST"] = "USER_MANAGED_WATCHLIST";
    UserWatchlistType["USER_FAVORITED_ENTITIES"] = "USER_FAVORITED_ENTITIES";
    UserWatchlistType["USER_HIDDEN_ENTITIES"] = "USER_HIDDEN_ENTITIES";
    UserWatchlistType["CUSTOMER_AFFINITY_SYNCED_LIST"] = "CUSTOMER_AFFINITY_SYNCED_LIST";
    UserWatchlistType["CUSTOMER_MANAGED_WATCHLIST"] = "CUSTOMER_MANAGED_WATCHLIST";
})(UserWatchlistType || (UserWatchlistType = {}));
export const getSingletonWatchlist = (watchlists, userWatchlistType) => {
    const filteredWatchlists = watchlists.filter((watchlist) => watchlist.userWatchlistType == userWatchlistType);
    if (filteredWatchlists.length !== 1) {
        return null;
    }
    return filteredWatchlists[0];
};
export const sortWatchlists = (watchlists) => {
    if (!watchlists) {
        return [];
    }
    return watchlists.sort((a, b) => {
        if (!a || !b) {
            return 0;
        }
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
};
export const isUserListTypeEditable = (userWatchlistType) => {
    return (userWatchlistType === UserWatchlistType.USER_MANAGED_WATCHLIST ||
        userWatchlistType === UserWatchlistType.CUSTOMER_MANAGED_WATCHLIST ||
        userWatchlistType === UserWatchlistType.CUSTOMER_AFFINITY_SYNCED_LIST);
};
export const getWatchlistCompanySearchVariables = ({ selectedNamedViewUrn, companyWatchlist, localSearchTerm }) => {
    return getWatchlistSearchVariables({
        selectedNamedViewUrn,
        watchlist: companyWatchlist,
        localSearchTerm,
        localSearchField: 'company_name',
        initialSort: INITIAL_COMPANY_SORT,
        getSortValueAfterValidationCheck: getModifiedCompanySortValueAfterValidationCheck
    });
};
export const getPeopleWatchlistSearchVariables = ({ selectedNamedViewUrn, peopleWatchlist, localSearchTerm }) => {
    return getWatchlistSearchVariables({
        selectedNamedViewUrn,
        watchlist: peopleWatchlist,
        localSearchTerm,
        localSearchField: 'person_name',
        initialSort: INITIAL_PEOPLE_SORT,
        getSortValueAfterValidationCheck: getModifiedPeopleSortValueAfterValidationCheck
    });
};
const getWatchlistSearchVariables = ({ selectedNamedViewUrn, watchlist, localSearchTerm, localSearchField, initialSort, getSortValueAfterValidationCheck }) => {
    const selectedNamedView = watchlist?.namedViews?.find((view) => view.entityUrn === selectedNamedViewUrn) ?? watchlist?.namedViews?.[0];
    const currentNamedViewSearchQuery = selectedNamedView?.searchQuery;
    let formattedCurrentNamedViewSearchQuery = cloneDeep(camelize(INITIAL_SEARCH_MODEL));
    if (currentNamedViewSearchQuery) {
        formattedCurrentNamedViewSearchQuery = currentNamedViewSearchQuery;
    }
    if (localSearchTerm) {
        formattedCurrentNamedViewSearchQuery = {
            ...formattedCurrentNamedViewSearchQuery,
            filterGroup: {
                ...formattedCurrentNamedViewSearchQuery.filterGroup,
                filters: [
                    ...formattedCurrentNamedViewSearchQuery.filterGroup.filters,
                    {
                        field: localSearchField,
                        filterValue: [localSearchTerm],
                        comparator: FilterArrayComparator.SUBSTRING_MATCH
                    }
                ]
            }
        };
    }
    const formattedSort = [
        getSortValueAfterValidationCheck(currentNamedViewSearchQuery?.sort?.[0]?.sortField ??
            initialSort.sortField, currentNamedViewSearchQuery?.sort?.[0]?.descending ??
            initialSort.sortDescending, currentNamedViewSearchQuery?.sort?.[0]?.sortContextValues, watchlist?.customFields)
    ];
    const sortDescending = get(formattedSort, '[0].descending', initialSort.sortDescending);
    const sortCustomFieldUrn = get(formattedSort, '[0].sortContextValues.customFieldUrn', undefined);
    return {
        idOrUrn: getIdFromUrn(watchlist?.entityUrn ?? '') ?? '',
        skipExtended: true,
        sortField: formattedSort?.[0].sortField || initialSort.sortField,
        sortDescending,
        searchQuery: {
            ...formattedCurrentNamedViewSearchQuery,
            sort: formattedSort
        },
        first: GRID_INITIAL_PAGE_SIZE,
        after: null,
        sortCustomFieldUrn: sortCustomFieldUrn ?? null
    };
};
