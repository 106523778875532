import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { useShallowSidebarStore } from 'stores/sidebarStore';
export const FundingMetricCard = ({ value, label, icon }) => {
    const { isCollapsed, isFloating } = useShallowSidebarStore([
        'isCollapsed',
        'isFloating'
    ]);
    const isSideBarOpen = !isCollapsed && !isFloating;
    const Icon = icon;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "grid grid-cols-10", children: [_jsx("div", { "data-testid": `funding-metric-card-label`, className: classNames('col-span-5 flex items-start', {
                            'sm:col-span-3 lg:col-span-2': !isSideBarOpen,
                            'sm:col-span-4 lg:col-span-3': isSideBarOpen
                        }), children: _jsxs("div", { className: "flex gap-2 items-center", children: [_jsx(Icon, { className: "flex-shrink-0 w-4 h-4" }), _jsx("p", { className: classNames('text-sm text-content-default font-normal truncate'), children: label })] }) }), _jsx("div", { "data-testid": `funding-metric-card-value`, className: classNames('col-span-5 flex items-start text-sm text-content-strong pl-2', {
                            'sm:col-span-7 lg:col-span-8': !isSideBarOpen,
                            'sm:col-span-6 lg:col-span-7': isSideBarOpen
                        }), children: value })] }), _jsx("hr", { className: "border-solid border-b-border-strong" })] }));
};
