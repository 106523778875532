import { gql } from '@apollo/client';
import { SavedSearchFragmentWithoutColumns } from './savedSearchFragment';
export const getPaginatedUseFetchSavedSearchesWithUserOptions = gql `
  query GetPaginatedUseFetchSavedSearches_WithUserOptions(
    $createdBySelf: Boolean
    $visibility: VisibilityFilter
    $favoritesOnly: Boolean
    $type: SearchType
    $searchQueryText: String
    $sort: ListSearchSort
    $after: String
    $first: Int
    $page: Int
  ) {
    getPaginatedSavedSearchesForTeam(
      createdBySelf: $createdBySelf
      visibility: $visibility
      favoritesOnly: $favoritesOnly
      type: $type
      searchQueryText: $searchQueryText
      sort: $sort
      after: $after
      first: $first
      page: $page
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...SavedSearch
        }
      }
    }
  }
  ${SavedSearchFragmentWithoutColumns}
`;
