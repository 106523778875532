import { useMemo } from 'react';
import { camelize } from 'utils/utilities';
import { INITIAL_COMPANY_SORT } from 'interfaces/SearchModel/Search';
import { transformSearchModelForApiv2 } from 'utils/search';
import { isValidSortField } from 'utils/sort';
import { GRID_INITIAL_PAGE_SIZE } from '../utils/constants';
import useSearchQuery from './useSearchQuery';
import { useSearchSort } from './useSearchSort';
import useTableUrlParams from './useTableUrlParams';
/**
 * @deprecated use search variables explicit to queries instead
 */
const useSearchVariables = () => {
    const { searchStateFilter, highlightNewState } = useTableUrlParams();
    const sort = useSearchSort();
    const { searchModel } = useSearchQuery();
    const companySearchQuery = useMemo(() => {
        return camelize(transformSearchModelForApiv2(searchModel));
    }, [searchModel]);
    const formattedSort = useMemo(() => {
        if (!sort) {
            return undefined;
        }
        const validSortField = isValidSortField(sort.sortField ?? '');
        if (!validSortField) {
            return [
                {
                    sortField: INITIAL_COMPANY_SORT.sortField,
                    descending: INITIAL_COMPANY_SORT.sortDescending
                }
            ];
        }
        return [sort];
    }, [sort]);
    const searchVariables = useMemo(() => {
        return {
            query: {
                ...companySearchQuery,
                pagination: { pageSize: GRID_INITIAL_PAGE_SIZE, start: 0 },
                sort: formattedSort
            },
            first: null,
            after: null,
            state: searchStateFilter,
            highlightNew: highlightNewState,
            skipExtended: true
        };
    }, [companySearchQuery, formattedSort, searchStateFilter, highlightNewState]);
    return {
        searchVariables
    };
};
export default useSearchVariables;
