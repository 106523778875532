import { useStore } from '../config/StoreProvider';
const useFetchSingleWatchlist = () => {
    const { rootStore } = useStore();
    const { watchlist, loading, error } = rootStore.companyWatchlistViewStore;
    return {
        companyWatchlist: watchlist?.watchlistWithDraftState,
        companyWatchlistLoading: loading,
        companyWatchlistError: error
    };
};
export default useFetchSingleWatchlist;
