import { gql } from '@apollo/client';
import { GET_PEOPLE_WATCHLIST_FRAGMENT } from './getPeopleWatchlists';
export const CREATE_PEOPLE_WATCHLIST = gql `
  mutation CreatePeopleWatchlist($watchlistInput: PeopleWatchlistInput!) {
    createPeopleWatchlist(watchlistInput: $watchlistInput) {
      ...PeopleWatchlist
      people {
        id
      }
    }
  }
  ${GET_PEOPLE_WATCHLIST_FRAGMENT}
`;
