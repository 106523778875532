import { jsx as _jsx } from "react/jsx-runtime";
import Tag from '../../../../harmonic-components/Tag/Tag';
import TruncatedList from '../../../../harmonic-components/TruncatedList/TruncatedList';
import { DEFAULT_HEIGHT } from './CustomColumnCellRenderers';
import { withPendingDot } from './components/WithPendingDot';
export const BaseCustomColumnSingleSelectCellRenderer = (props) => {
    const value = props.value?.value;
    const options = props.options;
    const selectedOption = options.find((option) => option.urn && option.urn === value);
    if (props.showEmptyValue && !selectedOption) {
        return (_jsx("p", { className: "typography-paragraph text-content-weak", children: `Select ${props.colDef?.headerName}` }));
    }
    return (_jsx("div", { className: "inline-block max-w-full", children: _jsx(TruncatedList, { height: props.height ?? DEFAULT_HEIGHT, children: !!selectedOption && (_jsx(Tag, { label: selectedOption?.name ?? '', color: (selectedOption?.color ?? 'neutral'), truncate: true })) }) }));
};
export const CustomColumnSingleSelectCellRenderer = withPendingDot(BaseCustomColumnSingleSelectCellRenderer);
