import { gql } from '@apollo/client';
const GetCompanyUserConnectionsV2_User = gql `
  fragment GetCompanyUserConnectionsV2_User on User {
    email
    name
  }
`;
const GetCompanyUserConnectionsV2_targetPerson = gql `
  fragment GetCompanyUserConnectionsV2_targetPerson on Person {
    id
    fullName
    profilePictureUrl
    socials {
      linkedin {
        url
      }
    }
    highlights {
      text
      category
    }
    experience {
      title
      roleType
      company {
        id
        name
        logoUrl
      }
      isCurrentPosition
      startDate
      endDate
    }
  }
`;
const getCompanyUserConnections = gql `
  fragment CompanyUserConnectionV2 on UserConnection {
    targetPerson {
      ...GetCompanyUserConnectionsV2_targetPerson
    }
    user {
      ...GetCompanyUserConnectionsV2_User
    }
  }
  ${GetCompanyUserConnectionsV2_User}
  ${GetCompanyUserConnectionsV2_targetPerson}
  query GetCompanyUserConnectionsV2($id: Int!) {
    getCompanyById(id: $id) {
      id
      userConnections {
        ...CompanyUserConnectionV2
      }
    }
  }
`;
const UserConnectionsOverviewFragment = gql `
  fragment UserConnectionsOverview on UserConnectionOverview {
    correspondenceSummary {
      ...CorrespondenceSummary
    }
  }
`;
export const UserConnectionFragment = gql `
  fragment UserConnection on UserConnection {
    targetPersonEmailAddress
    latestCalendarEvent {
      endTime
      name
      organizer {
        emailAddress {
          emailAddress
          name
        }
        person {
          id
          entityUrn
          fullName
        }
      }
      participants {
        emailAddress {
          emailAddress
          name
        }
        person {
          id
          entityUrn
          fullName
        }
      }
      participantResponseStatuses {
        person {
          emailAddress {
            emailAddress
            name
          }
          person {
            id
            entityUrn
            fullName
          }
        }
        status
      }
      startTime
    }
    latestEmail {
      timestamp
      title
      sender {
        emailAddress {
          emailAddress
          name
        }
        person {
          id
          entityUrn
          fullName
        }
      }
      receivers {
        emailAddress {
          emailAddress
          name
        }
        person {
          id
          entityUrn
          fullName
        }
      }
    }
    user {
      ...User
    }
    connectionSources
  }
  fragment User on User {
    email
    name
  }
`;
export const CorrespondenceSummary = gql `
  fragment CorrespondenceSummary on CorrespondenceSummary {
    lastEmailAt
    lastEmailContactPersonEmail
    lastEmailContactPerson {
      fullName
      profilePictureUrl
    }
    lastMeetingAt
    lastMeetingContactPersonEmail
    lastMeetingContactPerson {
      fullName
      profilePictureUrl
    }
  }
`;
export const getCompanyUserConnectionsWithOverviewAndEvents = gql `
  query GetCompanyUserConnectionsWithOverviewAndEvents($id: Int!) {
    getCompanyById(id: $id) {
      id
      userConnectionsOverview {
        ...UserConnectionsOverview
      }
      userConnections {
        targetPerson {
          ...TargetPerson
        }
        ...UserConnection
      }
    }
  }
  ${UserConnectionsOverviewFragment}
  ${CorrespondenceSummary}
  fragment TargetPerson on Person {
    id
    fullName
    profilePictureUrl
    socials {
      linkedin {
        url
      }
    }
    contact {
      emails
    }
    emailEnrichmentStatus {
      enrichedAt
    }
    experience {
      title
      roleType
      company {
        id
        name
        logoUrl
      }
      isCurrentPosition
      startDate
      endDate
    }
  }
  ${UserConnectionFragment}
`;
export const getPersonExperienceAndHighlightsForConnectionsCard = gql `
  query GetPersonExperienceAndHighlightsForConnectionsCard($id: Int!) {
    getPersonById(id: $id) {
      id
      highlights {
        text
        category
      }
      experience {
        title
        roleType
        company {
          id
          name
          logoUrl
        }
        isCurrentPosition
        startDate
        endDate
      }
    }
  }
`;
export default getCompanyUserConnections;
