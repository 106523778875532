import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
const ExampleQueries = ({ onExampleQuestionClick, exampleQuestions, isLoadingExamples, question, selectedIndex, onMouseEnter, onMouseLeave }) => {
    const [handleExampleQuestionClick, handleMouseEnter, handleMouseLeave] = [
        onExampleQuestionClick,
        onMouseEnter,
        onMouseLeave
    ];
    return (_jsx("div", { className: "flex flex-col px-p20 pt-p20", children: _jsx("div", { className: "flex flex-col gap-g10", children: isLoadingExamples ? (
            // Shimmer placeholders for 5 question buttons
            [...Array(5)].map((_, index) => (_jsx("div", { className: "flex items-center justify-between px-p40 py-p30 rounded-md bg-white w-full", children: _jsxs("div", { className: "flex items-center gap-2 w-full", children: [_jsx("span", { className: "icon material-icons-outlined text-sm font-medium text-gray-500", children: "search" }), _jsx("div", { className: "h-4 bg-gray-200 rounded animate-pulse w-4/5" })] }) }, index)))) : question.trim() ? (
            // Show dynamic query when user is typing
            _jsxs("button", { onClick: () => handleExampleQuestionClick(question), disabled: isLoadingExamples, className: classNames('flex items-center flex-row justify-between overflow-hidden px-p40 py-p30 rounded-md disabled:opacity-50 w-full text-left', selectedIndex === 0
                    ? 'bg-int-overlay-secondary-hover'
                    : 'bg-white hover:bg-int-overlay-secondary-hover'), children: [_jsxs("div", { className: "flex items-center gap-2 overflow-hidden flex-grow-0", children: [_jsx("span", { className: "icon material-icons-outlined text-sm font-medium text-gray-500", children: "search" }), _jsxs("span", { className: "text-gray-700 text-sm text-wrap", title: `Ask agent "${question}"`, children: ["Ask agent \"", question, "\""] })] }), _jsx("span", { className: "icon material-icons-outlined text-sm font-medium text-gray-500", children: "keyboard_return" })] })) : (
            // Show example queries when user is not typing
            exampleQuestions.map((exampleQuestion, index) => (_jsxs("button", { onClick: () => handleExampleQuestionClick(exampleQuestion), onMouseEnter: () => handleMouseEnter(index), onMouseLeave: handleMouseLeave, disabled: isLoadingExamples, className: classNames('flex items-center justify-between px-p40 py-p30 rounded-md hover:bg-int-overlay-secondary-hover disabled:opacity-50 w-full text-left', selectedIndex === index
                    ? 'bg-int-overlay-secondary-hover'
                    : 'bg-white'), children: [_jsxs("div", { className: "flex items-center gap-2", children: [_jsx("span", { className: "icon material-icons-outlined text-sm font-medium text-gray-500", children: "search" }), _jsx("span", { className: "text-gray-700 text-sm truncate", title: exampleQuestion, children: exampleQuestion })] }), _jsx("span", { className: classNames('icon material-icons-outlined text-sm font-medium text-gray-500', selectedIndex === index ? 'block' : 'hidden'), children: "keyboard_return" })] }, index)))) }) }));
};
export default ExampleQueries;
