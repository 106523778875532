import { jsx as _jsx } from "react/jsx-runtime";
import CompanyColumnsConfig from 'interfaces/CompanyColumnsConfig';
import { BaseFieldsDropdownItems } from './BaseFieldsDropdownItems';
const CompanySearchFieldsDropdownItems = ({ onFieldClick, filterTerm }) => {
    return (_jsx(BaseFieldsDropdownItems, { onFieldClick: onFieldClick, filterTerm: filterTerm, harmonicFields: Object.values(CompanyColumnsConfig), hiddenFields: [
            CompanyColumnsConfig.ADDED_TO_LIST_BY.value,
            CompanyColumnsConfig.ADDED_TO_LIST_DATE.value
        ] }));
};
export default CompanySearchFieldsDropdownItems;
