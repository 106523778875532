import { SearchStateFilter } from '__generated__/graphql';
import useFilterStore from 'stores/filterStore';
import { BooleanParam, createEnumParam, useQueryParam, withDefault } from 'use-query-params';
import useDashboardLocation from './useDashboardLocation';
const useTableUrlParams = () => {
    const { urn } = useDashboardLocation();
    const searchState = useFilterStore((state) => state.searchStates);
    const [searchStateFilter, setSearchStateFilter] = useQueryParam('searchState', withDefault(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    createEnumParam(Object.values(SearchStateFilter)), 
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    ((urn && searchState[urn]) || SearchStateFilter.NONE)));
    const [highlightNewState, setHighlightNewState] = useQueryParam('highlightNew', withDefault(BooleanParam, true));
    return {
        searchStateFilter,
        setSearchStateFilter,
        highlightNewState,
        setHighlightNewState
    };
};
export default useTableUrlParams;
