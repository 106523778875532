import { jsx as _jsx } from "react/jsx-runtime";
import { getCompanyWatchlistCustomFieldHeaders } from 'components/Dashboard/Grids/ColumnDefinitions/WatchlistCustomColumnHeaders';
import useFetchSingleWatchlist from 'hooks/useFetchSingleWatchlist';
import useFlags from 'hooks/useFlags';
import CompanyColumnsConfig from 'interfaces/CompanyColumnsConfig';
import { observer } from 'mobx-react';
import { SPLITS } from 'utils/constants';
import { mapCustomFieldsToColumnConfig } from 'utils/custom-fields';
import { BaseFieldsDropdownItems } from './BaseFieldsDropdownItems';
const CompanyWatchlistFieldsDropdownItems = ({ onFieldClick, onCreateNewField, filterTerm }) => {
    const { companyWatchlist } = useFetchSingleWatchlist();
    const { enabled: enableAddedByColumn } = useFlags(SPLITS.enableAddedByColumn);
    const customFields = mapCustomFieldsToColumnConfig(getCompanyWatchlistCustomFieldHeaders(companyWatchlist?.customFields ?? []), companyWatchlist?.customFields ?? []);
    return (_jsx(BaseFieldsDropdownItems, { onFieldClick: onFieldClick, onCreateNewField: onCreateNewField, filterTerm: filterTerm, harmonicFields: Object.values(CompanyColumnsConfig), customFields: customFields, hiddenFields: enableAddedByColumn ? [] : [CompanyColumnsConfig.ADDED_TO_LIST_BY.value] }));
};
export default observer(CompanyWatchlistFieldsDropdownItems);
