import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Checkbox from '../Checkbox/Checkbox';
const CheckboxRenderer = ({ value, onChange, mode }) => {
    const [checked, setChecked] = useState(value ?? false);
    useEffect(() => {
        setChecked(value ?? false);
    }, [value]);
    const handleOnChange = (newValue) => {
        setChecked(newValue);
        onChange(newValue);
    };
    return (_jsx("div", { className: classNames(mode === 'edit' && 'p-p30'), children: _jsx(Checkbox, { checked: checked, onChange: () => handleOnChange(!checked) }) }));
};
export default CheckboxRenderer;
