import { useMemo } from 'react';
import { NOT_APPLICABLE_FIELD_OPTION } from '../BoardView';
import { useBoardColumnVariables } from './useBoardColumnVariables';
export const useNotApplicableBoardColumnVariables = ({ watchlist, watchlistSearchVariables }) => {
    const fieldUrn = useMemo(() => {
        if (!watchlist)
            return null;
        const selectedFieldUrn = watchlist?.namedViews?.[0]?.groupByField?.urn;
        if (!selectedFieldUrn) {
            const singleSelectField = 
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            watchlist.customFields?.find((customField) => customField.metadata?.__typename === 'SelectListCustomFieldMetadata');
            return singleSelectField?.urn;
        }
        return selectedFieldUrn;
    }, [watchlist]);
    const fieldType = useMemo(() => {
        if (!watchlist)
            return null;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return watchlist.customFields?.find((customField) => customField.urn === fieldUrn)?.type;
    }, [watchlist, fieldUrn]);
    const variables = useBoardColumnVariables({
        watchlistId: watchlist?.id,
        fieldUrn,
        fieldValueUrn: NOT_APPLICABLE_FIELD_OPTION.urn,
        fieldType,
        searchVariables: watchlistSearchVariables
    });
    if (!fieldUrn)
        return;
    return variables;
};
