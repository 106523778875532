import { jsx as _jsx } from "react/jsx-runtime";
import { DateListCustomFieldValueFormat } from '__generated__/graphql';
import SingleDatePicker, { InputDateFormat } from 'harmonic-components/DatePicker/SingleDatePicker';
import React from 'react';
import { formatCustomFieldDate } from 'utils/custom-fields';
const DateRenderer = ({ value, onChange, mode, format = DateListCustomFieldValueFormat.MM_DD_YYYY }) => {
    const handleChange = (date) => {
        onChange(date);
    };
    const formattedDate = formatCustomFieldDate(format, value ?? '');
    if (mode === 'read') {
        return (_jsx("p", { className: "typography-paragraph-default-default", children: formattedDate }));
    }
    return (_jsx(SingleDatePicker, { selectedDate: value, onChange: handleChange, inputType: "text", dateFormat: InputDateFormat[format], alwaysOpen: true, fullWidth: true }));
};
export default DateRenderer;
