import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WarningIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
const calculateRightPadding = (trailingIconExist, errorIconExist) => {
    let iconsCount = 0;
    if (trailingIconExist)
        iconsCount += 1;
    if (errorIconExist)
        iconsCount += 1;
    if (iconsCount === 0)
        return 'var(--spacing-p50)';
    if (iconsCount === 1)
        return 'calc(var(--spacing-p50) + 16px + var(--gap-g10))';
    return 'calc(var(--spacing-p50) + 32px + var(--gap-g10) + var(--gap-g40))';
};
const TextArea = ({ errorMessage, leadingIcon, label, placeholder, trailingIcon, dataTestId, helperText, isDisabled, value, onChange, rows = 5, fullWidth = true, autoFocus = false }) => {
    const LeadingIcon = leadingIcon;
    const TrailingIcon = trailingIcon;
    const rightPadding = calculateRightPadding(Boolean(trailingIcon), Boolean(errorMessage));
    return (_jsxs("div", { "data-testid": dataTestId, className: classNames('flex flex-col gap-g20', fullWidth && 'w-full'), children: [label && _jsx("p", { className: "text-content-weak typography-label", children: label }), _jsxs("div", { className: classNames('relative', fullWidth && 'w-full'), children: [_jsx("textarea", { autoFocus: autoFocus, "data-testid": `${dataTestId}-input`, style: {
                            paddingLeft: leadingIcon
                                ? `calc(var(--spacing-p50) + 16px + var(--gap-g10))`
                                : 'var(--spacing-p50)',
                            paddingRight: rightPadding
                        }, disabled: isDisabled, placeholder: placeholder, value: value, onChange: onChange, rows: rows, className: classNames(fullWidth && 'w-full', 'py-p40 border border-solid typography-label', 'bg-int-overlay-secondary-enabled placeholder-content-muted', 'text-content-default rounded-br20', 
                        //disabled state
                        isDisabled &&
                            'bg-int-overlay-secondary-disabled border-int-outline-secondary-disabled placeholder-content-muted', 
                        //error state
                        errorMessage && !isDisabled && 'border-int-fill-negative-enabled', 
                        //default state
                        !errorMessage &&
                            !isDisabled &&
                            classNames('active:border-int-outline-secondary-pressed hover:border-int-outline-secondary-hover', 'border-int-outline-secondary-enabled focus:border-int-outline-secondary-selected-enabled focus:shadow-int-focus-outside')) }), LeadingIcon && (_jsx("div", { className: "absolute top-0 bottom-0 left-0 pl-p50 pt-p40 gap-g40 pointer-events-none", children: LeadingIcon && (_jsx(LeadingIcon, { className: "fill-current text-content-weak w-4 h-4" })) })), (TrailingIcon || errorMessage) && (_jsxs("div", { className: "absolute top-0 bottom-0 right-0 pr-p50 pt-p40 flex gap-g40 pointer-events-none", children: [TrailingIcon && (_jsx(TrailingIcon, { className: "fill-current text-content-weak w-4 h-4" })), errorMessage && (_jsx(WarningIcon, { className: " text-content-sentiment-negative w-4 h-4" }))] }))] }), errorMessage && (_jsx("p", { className: "typography-label text-int-fill-negative-contentEnabled", children: errorMessage })), helperText && (_jsx("p", { className: "typography-label text-content-weak", children: helperText }))] }));
};
export default TextArea;
