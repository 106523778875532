import { jsx as _jsx } from "react/jsx-runtime";
import { useStore } from 'config/StoreProvider';
import useFetchSinglePeopleWatchlist from 'hooks/useFetchSinglePeopleWatchlist';
import useGetPeopleListCustomField from 'hooks/useGetPeopleListCustomField';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import EditCustomFields from './EditCustomFields';
const EditPeopleCustomFields = observer(({ open = false, anchorEl, onClose, anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
}, inverted, className = '-mt-p30' }) => {
    const { peopleWatchlist } = useFetchSinglePeopleWatchlist();
    const customFields = useMemo(() => {
        return peopleWatchlist?.customFields || [];
    }, [peopleWatchlist]);
    const { getCustomFieldFromCache } = useGetPeopleListCustomField();
    const { rootStore } = useStore();
    const handleSetCustomFieldValues = async ({ customFieldUrn, customFieldValueInput }) => {
        if (!peopleWatchlist)
            return;
        rootStore.peopleWatchlistViewStore.setCustomFieldValues({
            watchlistUrn: peopleWatchlist.entityUrn,
            entryUrns: Array.from(rootStore.peopleWatchlistViewStore.selectedEntryUrns),
            customFieldUrn,
            customFieldValueInput
        });
    };
    const handleOnClose = () => {
        onClose?.();
    };
    const handleResetToDefaultValues = async ({ customFieldUrn }) => {
        if (!peopleWatchlist)
            return;
        rootStore.peopleWatchlistViewStore.resetToDefaultValues({
            watchlistUrn: peopleWatchlist.entityUrn,
            entryUrns: Array.from(rootStore.peopleWatchlistViewStore.selectedEntryUrns),
            customFieldUrn
        });
    };
    const handleDeleteCustomFieldValues = async ({ customFieldUrn }) => {
        if (!peopleWatchlist)
            return;
        rootStore.peopleWatchlistViewStore.deleteCustomFieldValues({
            watchlistUrn: peopleWatchlist.entityUrn,
            entryUrns: Array.from(rootStore.peopleWatchlistViewStore.selectedEntryUrns),
            customFieldUrn
        });
    };
    return (_jsx(EditCustomFields, { open: open, anchorEl: anchorEl, onClose: handleOnClose, anchorOrigin: anchorOrigin, inverted: inverted, className: className, getCustomFieldFromCache: getCustomFieldFromCache, customFields: customFields, onSetCustomFieldValues: handleSetCustomFieldValues, onResetToDefaultValues: handleResetToDefaultValues, onDeleteCustomFieldValues: handleDeleteCustomFieldValues }));
});
export default EditPeopleCustomFields;
