export var ReportSourceView;
(function (ReportSourceView) {
    ReportSourceView["COMPANY"] = "COMPANY";
    ReportSourceView["PERSON"] = "PERSON";
    ReportSourceView["INVESTOR"] = "INVESTOR";
    ReportSourceView["COMPANY_SEARCH"] = "COMPANY_SEARCH";
    ReportSourceView["PERSON_SEARCH"] = "PERSON_SEARCH";
    ReportSourceView["COMPANY_LIST"] = "COMPANY_LIST";
    ReportSourceView["PERSON_LIST"] = "PERSON_LIST";
})(ReportSourceView || (ReportSourceView = {}));
export var ReportSourceSection;
(function (ReportSourceSection) {
    ReportSourceSection["FINANCING"] = "FINANCING";
    ReportSourceSection["CAP_TABLE"] = "CAP_TABLE";
    ReportSourceSection["OVERVIEW"] = "OVERVIEW";
    ReportSourceSection["TEAM"] = "TEAM";
    ReportSourceSection["SIMILAR_COMPANIES"] = "SIMILAR_COMPANIES";
    ReportSourceSection["TRACTION_METRICS"] = "TRACTION_METRICS";
    ReportSourceSection["CONTACT"] = "CONTACT";
    ReportSourceSection["NETWORK"] = "CONNECTIONS";
    ReportSourceSection["LINEAGE"] = "LINEAGE";
    ReportSourceSection["INVESTMENTS"] = "INVESTMENTS";
    ReportSourceSection["CO_INVESTORS"] = "CO_INVESTORS";
    ReportSourceSection["DETAILS"] = "DETAILS";
    ReportSourceSection["RESEARCH"] = "RESEARCH";
})(ReportSourceSection || (ReportSourceSection = {}));
/**
 * Returns the list of commonly reported issues for a given section
 * @param section - The section of the applicationto get commonly reported issues for
 * @returns The list of commonly reported issues for the given section
 */
export const getCommonlyReportedIssuesBySection = (section) => {
    if (section === ReportSourceSection.FINANCING) {
        return CommonlyReportedFundingDataIssue;
    }
    // Note: Currently this function is only called by the data issue reporting modal in the funding section.
    // If we want to show commonly reported issues in the modal for other sections in the future, we should add them here.
    return [];
};
const CommonlyReportedFundingDataIssue = [
    { label: 'Missing rounds', value: 'MISSING_ROUNDS' },
    { label: 'Duplicate rounds', value: 'DUPLICATE_ROUNDS' },
    { label: 'Incorrect round details', value: 'INCORRECT_ROUND_DETAILS' },
    { label: 'Other', value: 'OTHER' }
];
