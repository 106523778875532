import { gql } from '@apollo/client';
import { CompanySearch } from './companySearchFragment';
export const COMPANY_SEARCH_QUERY = gql `
  query SearchCompanies(
    $query: EntitySearchQuery!
    $first: Int
    $after: String
    $state: SearchStateFilter
    $skipExtended: Boolean = false
    $includesPersonRelationships: Boolean = true
    $includesPersonRelationshipsFoundersAndCeos: Boolean = true
    $includesPersonRelationshipsExecutives: Boolean = true
    $includesLeadershipPriorCompanies: Boolean = true
    $includesFunding: Boolean = true
    $includesTractionMetrics: Boolean = true
    $includesUserConnections: Boolean = true
    $includesUserConnectionsOverview: Boolean = true
    $includesAffinityLists: Boolean = true
    $includesSocials: Boolean = true
    $includesTypedTags: Boolean = true
    $includesHighlights: Boolean = true
    $includesContact: Boolean = true
    $relationshipsPageSize: Int = 5
  ) {
    searchCompanies(
      query: $query
      first: $first
      after: $after
      state: $state
    ) {
      edges {
        cursor
        node {
          ... on Company {
            ...CompanySearch
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
  ${CompanySearch}
`;
