import { useApolloClient, useMutation } from '@apollo/client';
import { getIdFromUrn } from 'common/utils/urn';
import { toast } from 'react-toastify';
import { useLocalSearchState } from './useAppState';
const useRemoveWatchlistCustomField = (config) => {
    const client = useApolloClient();
    const [removeWatchlistCustomField, { loading }] = useMutation(config.mutation);
    const { searchByKey, updateLocalSearch } = useLocalSearchState();
    const deleteFieldFromCache = (watchlistUrn, customFieldUrn) => {
        client.cache.modify({
            id: client.cache.identify({
                __typename: config.typename,
                id: getIdFromUrn(watchlistUrn)
            }),
            fields: {
                customFields(existingCustomFields) {
                    const filteredCustomFields = existingCustomFields.filter((customField) => customField.__ref !==
                        config.getCustomFieldFragmentIdFromUrn(customFieldUrn));
                    return filteredCustomFields;
                },
                namedViews(existingNamedViews, { readField }) {
                    return existingNamedViews.map((namedView) => {
                        const visibleColumns = readField('visibleColumns', namedView);
                        return {
                            ...namedView,
                            visibleColumns: visibleColumns?.filter((column) => column !== customFieldUrn)
                        };
                    });
                }
            }
        });
    };
    const deleteFieldFromLocalSearch = (watchlistUrn, customFieldUrn) => {
        const watchlist = client.cache.readFragment({
            fragment: config.fragmentDoc,
            fragmentName: config.fragmentName,
            id: client.cache.identify({
                __typename: config.typename,
                id: getIdFromUrn(watchlistUrn)
            })
        });
        for (const namedView of watchlist?.namedViews ?? []) {
            const localNamedView = searchByKey[namedView.entityUrn];
            if (localNamedView) {
                const visibleColumns = localNamedView.visibleColumns?.filter((column) => column !== customFieldUrn) ?? [];
                updateLocalSearch(namedView.entityUrn, {
                    ...localNamedView,
                    visibleColumns
                });
            }
        }
    };
    const removeCustomField = (watchlistUrn, customFieldUrn) => {
        deleteFieldFromLocalSearch(watchlistUrn, customFieldUrn);
        return removeWatchlistCustomField({
            refetchQueries: [
                {
                    query: config.getWatchlistQuery,
                    variables: {
                        idOrUrn: watchlistUrn
                    }
                }
            ],
            awaitRefetchQueries: true,
            variables: {
                watchlistUrn,
                customFieldUrn
            }
        })
            .then(() => {
            toast.success('Custom field removed successfully');
            deleteFieldFromCache(watchlistUrn, customFieldUrn);
        })
            .catch(() => {
            toast.error('Failed to remove custom field');
        });
    };
    return {
        loading,
        removeCustomField
    };
};
export default useRemoveWatchlistCustomField;
