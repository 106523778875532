import { jsx as _jsx } from "react/jsx-runtime";
import { LongTextCellRenderer } from './CellRenderers';
import { withPendingDot } from './components/WithPendingDot';
export const BaseCustomColumnTextCellRenderer = (props) => {
    const value = props.value?.value;
    if (props.showEmptyValue && !value) {
        return (_jsx("p", { className: "typography-paragraph text-content-weak", children: props.emptyValueText ?? `Add ${props.colDef?.headerName}` }));
    }
    return _jsx(LongTextCellRenderer, { ...props, value: value });
};
export const CustomColumnTextCellRenderer = withPendingDot(BaseCustomColumnTextCellRenderer);
