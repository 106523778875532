export var HotkeyScope;
(function (HotkeyScope) {
    HotkeyScope["GLOBAL_SEARCH"] = "global-search";
    HotkeyScope["FILTERS"] = "filters";
    HotkeyScope["GRID"] = "grid";
    HotkeyScope["GRID_EDITING"] = "grid-editing";
})(HotkeyScope || (HotkeyScope = {}));
export const KeyboardSearchScopeToLabelMap = {
    [HotkeyScope.GLOBAL_SEARCH]: 'Global Search',
    [HotkeyScope.FILTERS]: 'Sort and filter results',
    [HotkeyScope.GRID]: 'Navigating the grid',
    [HotkeyScope.GRID_EDITING]: 'Grid Editing'
};
export var HotkeyEnum;
(function (HotkeyEnum) {
    HotkeyEnum["OPEN_GENERIC_SEARCH_WINDOWS"] = "OPEN_GENERIC_SEARCH_WINDOWS";
    HotkeyEnum["OPEN_GENERIC_SEARCH_MAC"] = "OPEN_GENERIC_SEARCH_MAC";
    HotkeyEnum["OPEN_HELP_MODAL"] = "OPEN_HELP_MODAL";
    HotkeyEnum["MOVE_UP"] = "MOVE_UP";
    HotkeyEnum["MOVE_DOWN"] = "MOVE_DOWN";
    HotkeyEnum["MOVE_LEFT"] = "MOVE_LEFT";
    HotkeyEnum["MOVE_RIGHT"] = "MOVE_RIGHT";
    HotkeyEnum["TOGGLE_LIKE"] = "TOGGLE_LIKE";
    HotkeyEnum["TOGGLE_HIDE"] = "TOGGLE_HIDE";
    HotkeyEnum["ADD_TO_LIST"] = "ADD_TO_LIST";
    HotkeyEnum["REMOVE_FROM_LIST"] = "REMOVE_FROM_LIST";
    HotkeyEnum["TOGGLE_VIEWED"] = "TOGGLE_VIEWED";
    HotkeyEnum["OPEN_CLOSE_SORT"] = "OPEN_CLOSE_SORT";
    HotkeyEnum["OPEN_CLOSE_FILTERS"] = "OPEN_CLOSE_FILTERS";
    HotkeyEnum["FILTER_GENERAL_TAB"] = "FILTER_GENERAL_TAB";
    HotkeyEnum["FILTER_TEAM_TAB"] = "FILTER_TEAM_TAB";
    HotkeyEnum["FILTER_SECTOR_TAB"] = "FILTER_SECTOR_TAB";
    HotkeyEnum["FILTER_FUNDING_TAB"] = "FILTER_FUNDING_TAB";
    HotkeyEnum["FILTER_CUSTOM_TAB"] = "FILTER_CUSTOM_TAB";
    HotkeyEnum["FILTER_SEE_RESULTS"] = "FILTER_SEE_RESULTS";
    HotkeyEnum["FILTER_CLOSE"] = "FILTER_CLOSE";
})(HotkeyEnum || (HotkeyEnum = {}));
export const HotkeyConfig = {
    [HotkeyEnum.OPEN_GENERIC_SEARCH_WINDOWS]: {
        keys: ['ctrl+/'],
        description: 'Open quick search windows'
    },
    [HotkeyEnum.OPEN_GENERIC_SEARCH_MAC]: {
        keys: ['meta+/'],
        description: 'Open quick search mac'
    },
    [HotkeyEnum.OPEN_HELP_MODAL]: {
        keys: ['/'],
        description: 'Open keyboard glossary'
    },
    [HotkeyEnum.MOVE_UP]: {
        keys: ['↑'],
        description: 'Move up'
    },
    [HotkeyEnum.MOVE_DOWN]: {
        keys: ['↓'],
        description: 'Move down'
    },
    [HotkeyEnum.MOVE_LEFT]: {
        keys: ['←'],
        description: 'Move left'
    },
    [HotkeyEnum.MOVE_RIGHT]: {
        keys: ['→'],
        description: 'Move right'
    },
    [HotkeyEnum.TOGGLE_LIKE]: {
        keys: ['l'],
        description: 'Like/unlike'
    },
    [HotkeyEnum.TOGGLE_HIDE]: {
        keys: ['h'],
        description: 'Hide from searches'
    },
    [HotkeyEnum.ADD_TO_LIST]: {
        keys: ['a'],
        description: 'Add to list'
    },
    [HotkeyEnum.REMOVE_FROM_LIST]: {
        keys: ['backspace'],
        description: 'Remove from current list'
    },
    [HotkeyEnum.TOGGLE_VIEWED]: {
        keys: ['v'],
        description: 'Mark viewed/unviewed'
    },
    [HotkeyEnum.OPEN_CLOSE_SORT]: {
        keys: ['s'],
        description: 'Open/close sort options'
    },
    [HotkeyEnum.OPEN_CLOSE_FILTERS]: {
        keys: ['f'],
        description: 'Open/close filters'
    },
    [HotkeyEnum.FILTER_GENERAL_TAB]: {
        keys: ['g'],
        description: 'Go to general tab',
        prefixKey: 'F'
    },
    [HotkeyEnum.FILTER_FUNDING_TAB]: {
        keys: ['f'],
        description: 'Go to funding tab',
        prefixKey: 'F'
    },
    [HotkeyEnum.FILTER_TEAM_TAB]: {
        keys: ['t'],
        description: 'Go to team tab',
        prefixKey: 'F'
    },
    [HotkeyEnum.FILTER_SECTOR_TAB]: {
        keys: ['s'],
        description: 'Go to sector tab',
        prefixKey: 'F'
    },
    [HotkeyEnum.FILTER_CUSTOM_TAB]: {
        keys: ['c'],
        description: 'Go to custom tab',
        prefixKey: 'F'
    },
    [HotkeyEnum.FILTER_SEE_RESULTS]: {
        keys: ['enter'],
        description: 'See results',
        prefixKey: 'F'
    },
    [HotkeyEnum.FILTER_CLOSE]: {
        keys: ['esc'],
        description: 'Close filters',
        prefixKey: 'F'
    }
};
// This is a mapping of hotkey enum to the keys that trigger it
export const HotkeyBinding = Object.keys(HotkeyConfig).reduce((acc, key) => {
    acc[key] = HotkeyConfig[key].keys;
    return acc;
}, {});
export const HotkeyMapByScope = {
    [HotkeyScope.GLOBAL_SEARCH]: [
        HotkeyEnum.OPEN_GENERIC_SEARCH_WINDOWS,
        HotkeyEnum.OPEN_GENERIC_SEARCH_MAC,
        HotkeyEnum.OPEN_HELP_MODAL
    ],
    [HotkeyScope.GRID]: [
        HotkeyEnum.MOVE_UP,
        HotkeyEnum.MOVE_DOWN,
        HotkeyEnum.MOVE_LEFT,
        HotkeyEnum.MOVE_RIGHT,
        HotkeyEnum.TOGGLE_LIKE,
        HotkeyEnum.TOGGLE_HIDE,
        HotkeyEnum.ADD_TO_LIST,
        HotkeyEnum.REMOVE_FROM_LIST,
        HotkeyEnum.TOGGLE_VIEWED,
        HotkeyEnum.OPEN_CLOSE_SORT,
        HotkeyEnum.OPEN_CLOSE_FILTERS
    ],
    [HotkeyScope.FILTERS]: [
        HotkeyEnum.FILTER_GENERAL_TAB,
        HotkeyEnum.FILTER_FUNDING_TAB,
        HotkeyEnum.FILTER_TEAM_TAB,
        HotkeyEnum.FILTER_SECTOR_TAB,
        HotkeyEnum.FILTER_CUSTOM_TAB,
        HotkeyEnum.FILTER_SEE_RESULTS,
        HotkeyEnum.FILTER_CLOSE
    ],
    [HotkeyScope.GRID_EDITING]: [
        HotkeyEnum.ADD_TO_LIST,
        HotkeyEnum.REMOVE_FROM_LIST,
        HotkeyEnum.TOGGLE_VIEWED
    ]
};
