import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { ReactComponent as EditIcon } from 'assets/edit-custom-fields-icon.svg';
import { config } from 'config/config';
import Button from 'harmonic-components/Button/Button';
import React from 'react';
import { DASHBOARD_ROUTE, PEOPLE_WATCHLIST_ROUTE, WATCHLIST_ROUTE } from 'utils/constants';
const ListStackItemEmptyState = ({ watchlistUrn }) => {
    const handleOpenListInNewTab = () => {
        const watchlistUrl = watchlistUrn.includes('company')
            ? `${WATCHLIST_ROUTE}/${watchlistUrn}`
            : `${PEOPLE_WATCHLIST_ROUTE}/${watchlistUrn}`;
        window.open(`${config.BASE_CONSOLE_URL}/${DASHBOARD_ROUTE}${watchlistUrl}?addCustomField=true`, '_blank');
    };
    return (_jsxs("div", { "data-testid": "list-stack-item-empty-state", className: "flex flex-col items-center justify-center pt-p40 pb-p60 px-p20 gap-g60", children: [_jsx(EditIcon, {}), _jsxs("div", { className: "flex flex-col items-center justify-center gap-g20", children: [_jsx("div", { className: "typography-label-default-default text-content-title", children: "No custom fields have been created" }), _jsx("div", { className: "typography-label-default-default text-content-weak text-content-secondary text-center", children: "Create custom fields in your list" })] }), _jsx(Button, { type: "secondary", size: "compact", label: "Open list in new tab", trailingIcon: () => (_jsx(OpenInNewIcon, { className: "w-4 h-4 text-int-fill-secondary-onEnabled" })), onClick: handleOpenListInNewTab })] }));
};
export default ListStackItemEmptyState;
