import { getIdFromUrn } from 'common/utils/urn';
import client from '../../config/client';
import { GET_SAVED_SEARCH } from '../../queries/getSavedSearch';
import SavedSearchModel from './models/SavedSearchModel';
class SavedSearchStore {
    rootStore;
    constructor(rootStore) {
        this.rootStore = rootStore;
    }
    async get(idOrUrn) {
        const { data, errors } = await client.query({
            query: GET_SAVED_SEARCH,
            variables: {
                idOrUrn: getIdFromUrn(idOrUrn) ?? ''
            },
            fetchPolicy: 'cache-first'
        });
        if (!data?.getSavedSearch) {
            return {
                savedSearch: null,
                error: errors?.[0]
            };
        }
        const savedSearch = new SavedSearchModel(data.getSavedSearch);
        return {
            savedSearch,
            error: errors?.[0]
        };
    }
}
export default SavedSearchStore;
