import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowTopBottomIcon } from 'assets/harmonic-icons';
import ArrowDownwardIcon from 'assets/harmonic-icons/arrow-downward';
import ArrowUpwardIcon from 'assets/harmonic-icons/arrow-upward';
import classNames from 'classnames';
import IconButton from 'harmonic-components/IconButton/IconButton';
import useFlags from 'hooks/useFlags';
import { useSearchSort } from 'hooks/useSearchSort';
import React from 'react';
import { SPLITS } from 'utils/constants';
import { EditFieldTypeOptionIcon } from 'utils/custom-fields';
import useDashboardLocation, { DashboardLocation } from '../../../../hooks/useDashboardLocation';
import { ColumnFieldType } from '../../../../interfaces/ColumnsConfig';
import { CompanyColumnsValueToFieldTypeMap } from '../../../../interfaces/CompanyColumnsConfig';
import { getCompanyFieldIcon, getIconForCompanyColumnField, getIconForPersonColumnField, getPeopleFieldIcon } from '../EditColumnsModal/utils';
const PeopleHeaderLabel = (props) => {
    const field = props.column.getColDef().field;
    const icon = getIconForPersonColumnField(props.column.getColDef().field, 'person');
    const fieldIcon = getPeopleFieldIcon(field);
    return _jsx(CommonHeaderLabel, { ...props, icon: icon, fieldIcon: fieldIcon });
};
const CompanyHeaderLabel = (props) => {
    const field = props.column.getColDef().field;
    const icon = getIconForCompanyColumnField(props.column.getColDef().field);
    const fieldIcon = getCompanyFieldIcon(field);
    return _jsx(CommonHeaderLabel, { ...props, icon: icon, fieldIcon: fieldIcon });
};
const CommonHeaderLabel = (props) => {
    const field = props.column.getColDef().field;
    const customField = props.context?.customFields?.[props.column.getColDef().field ?? ''];
    const fieldType = CompanyColumnsValueToFieldTypeMap[field];
    const CustomFieldIcon = EditFieldTypeOptionIcon[customField?.type];
    const Icon = props.icon;
    const FieldIcon = props.fieldIcon;
    const { enabled: enableSortInHeader } = useFlags(SPLITS.enableSortInHeader);
    const changeSort = props.context.changeSort;
    const colDef = props.column.getColDef();
    const sortField = colDef.searchModelIdentifier;
    const isCustomField = colDef.isCustomField;
    const customFieldUrn = colDef?.field;
    const sortable = colDef.sortable && sortField;
    const sort = useSearchSort();
    const isSorted = sort?.sortField === sortField &&
        (!isCustomField ||
            sort?.sortContextValues?.customFieldUrn === customFieldUrn);
    return (_jsxs("div", { className: "group flex items-center w-full h-full justify-between gap-2", children: [_jsxs("div", { className: "flex items-center gap-2", children: [FieldIcon && _jsx(FieldIcon, { className: "text-content-weak" }), CustomFieldIcon && _jsx(CustomFieldIcon, { className: "text-content-weak" }), _jsx("div", { children: props.displayName }), sortable && enableSortInHeader ? (_jsx("div", { className: classNames(!isSorted && 'hidden', 'group-hover:block'), children: _jsx(IconButton, { onClick: (e) => {
                                e.stopPropagation();
                                changeSort({
                                    field: sortField,
                                    descending: isSorted ? !sort?.descending : true,
                                    sortContextValues: isCustomField
                                        ? { customFieldUrn }
                                        : undefined
                                });
                            }, icon: !isSorted
                                ? ArrowTopBottomIcon
                                : sort?.descending
                                    ? ArrowDownwardIcon
                                    : ArrowUpwardIcon, size: "tiny", isSelected: isSorted }) })) : null] }), Icon && fieldType === ColumnFieldType.SMART && _jsx(Icon, {})] }));
};
export const HeaderLabel = (props) => {
    const routeDetails = useDashboardLocation();
    const isCompany = routeDetails.location == DashboardLocation.COMPANY_SEARCH ||
        routeDetails.location == DashboardLocation.COMPANY_LIST;
    return isCompany ? (_jsx(CompanyHeaderLabel, { ...props })) : (_jsx(PeopleHeaderLabel, { ...props }));
};
