import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
import RecentHiresAndDeparturesGraph from './RecentHiresAndDeparturesGraph';
import DiagramWrapper from './common/DiagramWrapper';
import { prepareFounderOutflowData, prepareInflowData } from './utils/parseTalentFlowData';
const DEFAULT_WIDTH = 750;
const LEFT_COLUMN_RATIO = 0.125;
const RIGHT_COLUMN_RATIO = 0.85;
const VERTICAL_PADDING = 100;
const ITEM_SPACING = 50; // Spacing between items
export const TalentFlowDiagram = ({ content }) => {
    return (_jsx(TalentFlowDiagramGraph, { talentFlow: content, companyName: content.metadata.companyName, companyLogoUrl: content.metadata.companyLogoUrl, width: 600 }));
};
const TalentFlowDiagramGraph = ({ talentFlow, companyName, companyLogoUrl, width = DEFAULT_WIDTH }) => {
    const svgRef = useRef(null);
    useEffect(() => {
        if (!svgRef.current)
            return;
        // 1. Prepare data
        const inflowData = prepareInflowData(talentFlow);
        const outflowData = prepareFounderOutflowData(talentFlow);
        const FONT_SIZE = 12;
        // Calculate required height based on number of items
        const maxItems = Math.max(inflowData?.length, outflowData?.length);
        const height = maxItems * ITEM_SPACING + VERTICAL_PADDING * 2;
        const CENTER_X = width / 2;
        const CENTER_Y = height / 2;
        const LEFT_COLUMN_X = width * LEFT_COLUMN_RATIO;
        const RIGHT_COLUMN_X = width * RIGHT_COLUMN_RATIO;
        // 2. Clear any existing content in the SVG
        d3.select(svgRef.current).selectAll('*').remove();
        // 3. Create an SVG container with calculated height
        const svg = d3
            .select(svgRef.current)
            .attr('width', width)
            .attr('height', height);
        // Add column headers
        svg
            .append('text')
            .attr('x', LEFT_COLUMN_X)
            .attr('y', 40)
            .attr('text-anchor', 'middle')
            .attr('fill', '#4B5563')
            .style('font-size', `${FONT_SIZE + 2}px`)
            .style('font-weight', 'bold')
            .text('Talent Sources');
        svg
            .append('text')
            .attr('x', RIGHT_COLUMN_X)
            .attr('y', 40)
            .attr('text-anchor', 'middle')
            .attr('fill', '#4B5563')
            .style('font-size', `${FONT_SIZE + 2}px`)
            .style('font-weight', 'bold')
            .text('Alumni-founded Startups');
        // 4. Calculate maximums
        const maxInflowCount = d3.max(inflowData, (d) => d.count) || 1;
        const maxOutflowCount = d3.max(outflowData, (d) => d.count) || 1;
        // Position items on the left with proper vertical spacing
        inflowData.forEach((item, i) => {
            item['x'] = LEFT_COLUMN_X;
            item['y'] = VERTICAL_PADDING + i * ITEM_SPACING;
        });
        // Position items on the right with proper vertical spacing
        outflowData.forEach((item, i) => {
            item['x'] = RIGHT_COLUMN_X;
            item['y'] = VERTICAL_PADDING + i * ITEM_SPACING;
        });
        // First, draw all the lines
        const linesGroup = svg.append('g').attr('class', 'lines-group');
        // Draw inflow lines in the lines group
        linesGroup
            .selectAll('path.inflow-line')
            .data(inflowData)
            .enter()
            .append('path')
            .attr('class', 'inflow-line')
            .attr('fill', 'none')
            .attr('stroke', '#E5E7EB')
            .attr('stroke-width', (d) => {
            const scale = 1 + (d.count / maxInflowCount) * 8;
            return scale;
        })
            .style('transition', 'stroke 0.2s ease, opacity 0.2s ease')
            .attr('d', (d) => {
            const x1 = d.x;
            const y1 = d.y;
            const x2 = CENTER_X;
            const y2 = CENTER_Y;
            return `M ${x1},${y1} 
                C ${x1 + 100},${y1} 
                  ${x2 - 100},${y2} 
                  ${x2},${y2}`;
        })
            // Add hover interactions
            .on('mouseover', (event, d) => {
            d3.select(event.currentTarget)
                .attr('stroke', '#60A5FA')
                .style('opacity', 1)
                .raise();
            svg
                .selectAll('path.inflow-line')
                .filter(function () {
                return this !== event.currentTarget;
            })
                .style('opacity', 0.2);
            tooltip
                .style('visibility', 'visible')
                .text(`${d.count} employees`)
                .style('left', `${event.pageX + 10}px`)
                .style('top', `${event.pageY - 10}px`);
        })
            .on('mousemove', (event) => {
            tooltip
                .style('left', `${event.pageX + 10}px`)
                .style('top', `${event.pageY - 10}px`);
        })
            .on('mouseout', (event) => {
            d3.select(event.currentTarget)
                .attr('stroke', '#E5E7EB')
                .style('opacity', 1);
            svg.selectAll('path.inflow-line').style('opacity', 1);
            tooltip.style('visibility', 'hidden');
        });
        // Draw outflow lines in the lines group
        linesGroup
            .selectAll('path.outflow-line')
            .data(outflowData)
            .enter()
            .append('path')
            .attr('class', 'outflow-line')
            .attr('fill', 'none')
            .attr('stroke', '#E5E7EB')
            .attr('stroke-width', (d) => {
            const scale = 1 + (d.count / maxOutflowCount) * 8;
            return scale;
        })
            .attr('d', (d) => {
            const x1 = d.x;
            const y1 = d.y;
            const x2 = CENTER_X;
            const y2 = CENTER_Y;
            return `M ${x1},${y1} 
                C ${x1 - 100},${y1} 
                  ${x2 + 100},${y2} 
                  ${x2},${y2}`;
        });
        // Draw the nodes
        const nodesGroup = svg.append('g').attr('class', 'nodes-group');
        // Draw inflow/outflow nodes in the nodes group
        const allNodes = [...inflowData, ...outflowData];
        // If you want an image (logo) for each node
        const nodeGroup = nodesGroup
            .selectAll('.flow-node')
            .data(allNodes)
            .enter()
            .append('g')
            .attr('class', 'flow-node')
            .attr('transform', (d) => `translate(${d['x']}, ${d['y']})`);
        nodeGroup
            .append((d) => {
            // Create either an 'a' element or a 'g' element based on URL presence
            return document.createElementNS('http://www.w3.org/2000/svg', d.companyProfileUrl ? 'a' : 'g');
        })
            .attr('href', (d) => d.companyProfileUrl || null)
            .attr('target', '_blank')
            .append('image')
            .attr('href', (d) => d.logoUrl ||
            'https://static.licdn.com/aero-v1/sc/h/cs8pjfgyw96g44ln9r7tct85f')
            .attr('width', 30)
            .attr('height', 30)
            .attr('x', -15)
            .attr('y', -15)
            .attr('clip-path', 'circle(15px at 15px 15px)')
            .each(function (d) {
            // Only add hover effects if there's a profile URL
            if (d.companyProfileUrl) {
                d3.select(this)
                    .style('cursor', 'pointer')
                    .style('filter', 'drop-shadow(0 0 0 rgba(59, 130, 246, 0))')
                    .style('transition', 'all 0.2s ease')
                    .on('mouseover', function () {
                    d3.select(this)
                        .style('filter', 'drop-shadow(0 0 6px rgba(59, 130, 246, 0.8))')
                        .style('opacity', '0.9')
                        .style('transform', 'scale(1.1)');
                })
                    .on('mouseout', function () {
                    d3.select(this)
                        .style('filter', 'drop-shadow(0 0 0 rgba(59, 130, 246, 0))')
                        .style('opacity', '1')
                        .style('transform', 'scale(1)');
                });
            }
        });
        // Text label next to each node
        const textNodes = nodeGroup
            .append('text')
            .attr('x', (d) => (d.x === LEFT_COLUMN_X ? 40 : -40))
            .attr('y', 5)
            .attr('text-anchor', (d) => (d.x === LEFT_COLUMN_X ? 'start' : 'end'))
            .attr('fill', '#111827')
            .style('font-size', `${FONT_SIZE}px`)
            .text((d) => {
            const name = d.name;
            const truncatedName = name?.length > 20 ? name.substring(0, 20) + '...' : name;
            if (d.type === 'founded') {
                return `${truncatedName} (${d.displayCount})`;
            }
            // Don't show count for inflow nodes
            return d.x === LEFT_COLUMN_X
                ? truncatedName
                : `${truncatedName} (${d.count})`;
        });
        // Add title separately
        textNodes.append('title').text((d) => d.name);
        // draw the center node last so it's always on top
        const centerGroup = svg
            .append('g')
            .attr('class', 'center-node')
            .attr('transform', `translate(${CENTER_X}, ${CENTER_Y})`);
        // Add white background circle first
        centerGroup
            .append('circle')
            .attr('r', 40)
            .attr('fill', '#FFF')
            .attr('stroke', '#E5E7EB');
        // Then add the logo and text
        if (companyLogoUrl) {
            centerGroup
                .append('image')
                .attr('href', companyLogoUrl)
                .attr('width', 40)
                .attr('height', 40)
                .attr('x', -20)
                .attr('y', -20);
        }
        centerGroup
            .append('text')
            .text(companyName)
            .attr('text-anchor', 'middle')
            .attr('y', 60)
            .attr('fill', '#111827')
            .style('font-size', `${FONT_SIZE}px`);
        // Add a tooltip div to the document if it doesn't exist
        const tooltip = d3
            .select('body')
            .selectAll('.talent-flow-tooltip')
            .data([null])
            .join('div')
            .attr('class', 'talent-flow-tooltip')
            .style('position', 'absolute')
            .style('visibility', 'hidden')
            .style('background-color', 'white')
            .style('padding', '5px 10px')
            .style('border', '1px solid #E5E7EB')
            .style('border-radius', '4px')
            .style('font-size', `${FONT_SIZE}px`)
            .style('pointer-events', 'none')
            .style('z-index', '9999');
        return () => {
            tooltip.remove();
        };
    }, [talentFlow, companyName, companyLogoUrl, width]);
    return (_jsxs("div", { children: [_jsx(DiagramWrapper, { title: "Recent hires and departures", children: _jsx(RecentHiresAndDeparturesGraph
                // @ts-ignore
                , { 
                    // @ts-ignore
                    talentInflow: talentFlow, companyName: companyName, companyLogoUrl: companyLogoUrl, companyProfileUrl: 'TODO', width: 550 }) }), _jsx(DiagramWrapper, { title: "Talent sources and alumni-founded startups", children: _jsx("svg", { ref: svgRef, style: { maxWidth: '100%', height: 'auto' } }) })] }));
};
