import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from 'harmonic-components/Button/Button';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import useDashboardLocation, { DashboardLocation } from 'hooks/useDashboardLocation';
import useGetCompanyListCustomField from 'hooks/useGetCompanyListCustomField';
import useGetPeopleListCustomField from 'hooks/useGetPeopleListCustomField';
import { useModal } from 'hooks/useModal';
import useRemoveCompanyListCustomField from 'hooks/useRemoveCompanyListCustomField';
import useRemovePeopleListCustomField from 'hooks/useRemovePeopleListCustomField';
import { useMemo } from 'react';
const DeleteConfirmationModal = ({ customFieldUrn, watchlistUrn, closeForm }) => {
    const routeDetails = useDashboardLocation();
    const isPeopleList = routeDetails.location === DashboardLocation.PERSON_LIST;
    const { removeCustomField, loading } = isPeopleList
        ? useRemovePeopleListCustomField()
        : useRemoveCompanyListCustomField();
    const { getCustomFieldFromCache } = isPeopleList
        ? useGetPeopleListCustomField()
        : useGetCompanyListCustomField();
    const { close } = useModal();
    const customField = useMemo(() => getCustomFieldFromCache(customFieldUrn), [customFieldUrn]);
    const onDelete = async () => {
        await removeCustomField(watchlistUrn, customFieldUrn);
        close();
        closeForm();
    };
    return (_jsxs("div", { children: [_jsx(ModalTitle, { title: "Delete field for everyone?" }), _jsx("div", { className: "pb-p80 px-p20 pt-p20", children: _jsxs("p", { className: "p-p40", children: ["Are you sure you want to delete field ", customField.name, " for everyone?"] }) }), _jsxs(ModalFooter, { children: [_jsx(Button, { label: "Cancel", type: "secondary", emphasis: "medium", onClick: close }), _jsx(Button, { label: "Delete", type: "negative", emphasis: "high", loading: loading, onClick: onDelete, dataTestId: "Delete-Btn" })] })] }));
};
export default DeleteConfirmationModal;
