const baseUnit = 2;
module.exports = {
  br00: `${baseUnit * 0}px`,
  br10: `${baseUnit}px`,
  br20: `${baseUnit * 2}px`,
  br30: `${baseUnit * 3}px`,
  br40: `${baseUnit * 4}px`,
  br60: `${baseUnit * 6}px`,
  br80: `${baseUnit * 8}px`,
  br100: `${baseUnit * 10}px`,
  br120: `${baseUnit * 12}px`,
  brPill: `999999px` // brPill
};