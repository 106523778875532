export const insertColumn = ({ visibleColumns, newColId, newColName, mode, colId }) => {
    const index = visibleColumns.findIndex((column) => colId?.includes(column.id));
    const newColumn = {
        id: newColId,
        name: newColName,
        isVisibleOnTable: true
    };
    if (mode === 'add-to-left' && index !== -1) {
        visibleColumns.splice(index, 0, newColumn);
    }
    else if (mode === 'add-to-right' && index !== -1) {
        visibleColumns.splice(index + 1, 0, newColumn);
    }
    else {
        visibleColumns.unshift(newColumn);
    }
    return visibleColumns;
};
