import { useApolloClient } from '@apollo/client';
import { PeopleCustomFieldsFragment as PeopleCustomFieldsFragmentQuery } from 'queries/getPeopleWatchlists';
const useGetPeopleListCustomField = () => {
    const client = useApolloClient();
    const getCustomFieldFragmentIdFromUrn = (urn) => {
        return `PeopleListCustomField:{"urn":"${urn}"}`;
    };
    const getCustomFieldFromCache = (urn) => {
        return client.cache.readFragment({
            fragment: PeopleCustomFieldsFragmentQuery,
            fragmentName: 'PeopleCustomFields',
            id: getCustomFieldFragmentIdFromUrn(urn)
        });
    };
    return {
        getCustomFieldFromCache,
        getCustomFieldFragmentIdFromUrn
    };
};
export default useGetPeopleListCustomField;
