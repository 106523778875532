import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CopyIcon, EyeClosedIcon, PencilIcon } from 'assets/harmonic-icons';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import useDashboardLocation from 'hooks/useDashboardLocation';
import useFlags from 'hooks/useFlags';
import { useModal } from 'hooks/useModal';
import useUpsertCompanyWatchlistNamedViews from 'hooks/useUpsertCompanyWatchlistNamedViews';
import { observer } from 'mobx-react';
import React from 'react';
import useTableStore from 'stores/tableStore';
import { SPLITS } from 'utils/constants';
import { convertCompanyListColumnsConfigToUrns } from 'utils/namedViews';
import DeleteConfirmationModal from '../../EditField/DeleteConfirmationModal';
import CompanyWatchlistFieldsDropdownItems from '../FieldsDropdownItems/CompanyWatchlistFieldsDropdownItems';
import { useFieldsDropdown } from '../FieldsDropdownItems/FieldsDropdownItems';
import FilterByFieldDropdown from '../FilterByFieldDropdown';
import { HeaderWithDropdown } from '../HeaderWithDropdown';
import { SortDropdownItems } from '../SortDropdownItems';
const _CompanyWatchlistCustomColumnHeader = (props) => {
    const { urn: watchlistUrn } = useDashboardLocation();
    const { show } = useModal();
    const editSidebar = useTableStore((state) => state.editSidebar);
    const { enabled: enableEditColumnField } = useFlags(SPLITS.enableCustomColumns);
    const { enabled: enableFilterInHeader } = useFlags(SPLITS.enableFilterInHeader);
    const { enabled: enableInsertAdjacentToField } = useFlags(SPLITS.enableInsertAdjacentToField);
    const { enabled: enableSortInHeader } = useFlags(SPLITS.enableSortInHeader);
    const { removeColumnFromCurrentNamedView } = useUpsertCompanyWatchlistNamedViews();
    const column = props.column;
    const columnDef = props.column.getColDef();
    const customFieldUrn = column.getUserProvidedColDef()
        ?.field;
    const colId = props.column.getColId();
    const changeSort = props.context.changeSort;
    const colDef = props.column.getColDef();
    const sortField = colDef.searchModelIdentifier;
    const sortable = colDef.sortable && sortField;
    const onEditFieldClick = (e) => {
        e?.stopPropagation();
        editSidebar('customizeFieldModal', {
            open: true,
            mode: 'edit',
            colId: customFieldUrn
        });
    };
    const onDuplicateFieldClick = (e) => {
        e?.stopPropagation();
        editSidebar('customizeFieldModal', {
            open: true,
            mode: 'duplicate',
            colId: customFieldUrn
        });
    };
    const onHideFieldClick = () => {
        removeColumnFromCurrentNamedView(customFieldUrn);
    };
    const { upsertCurrentSelectedNamedView: upsertCompanyNamedView } = useUpsertCompanyWatchlistNamedViews();
    const save = (visibleColumns) => {
        upsertCompanyNamedView({
            variables: {
                visibleColumns
            }
        });
    };
    const onDeleteFieldClick = () => {
        show(_jsx(DeleteConfirmationModal, { watchlistUrn: watchlistUrn, customFieldUrn: customFieldUrn, closeForm: () => Function.prototype }));
    };
    const { onFieldClick, onCreateNewField } = useFieldsDropdown({
        colId,
        save,
        convertColumnsConfigToUrns: convertCompanyListColumnsConfigToUrns
    });
    return (_jsx(HeaderWithDropdown, { params: props, showDropdown: enableEditColumnField ||
            enableFilterInHeader ||
            enableInsertAdjacentToField, renderFields: (filterTerm, insertFieldMode, onClose) => (_jsx(CompanyWatchlistFieldsDropdownItems, { onFieldClick: (field) => {
                onFieldClick(field, insertFieldMode);
                onClose();
            }, onCreateNewField: () => {
                onCreateNewField(insertFieldMode);
                onClose();
            }, filterTerm: filterTerm })), onDeleteFieldClick: onDeleteFieldClick, children: ({ close }) => (_jsxs(_Fragment, { children: [_jsx(ListItem, { primaryIcon: PencilIcon, variant: ListVariant.default, label: "Edit field", value: "Edit field", onMouseDown: (e) => {
                        onEditFieldClick(e);
                        close();
                    } }), sortField !== 'company_custom_field_status' && (_jsx(ListItem, { primaryIcon: CopyIcon, variant: ListVariant.default, label: "Duplicate field", value: "Duplicate field", onMouseDown: (e) => {
                        onDuplicateFieldClick(e);
                        close();
                    } })), _jsx(ListItem, { primaryIcon: EyeClosedIcon, variant: ListVariant.default, label: "Hide field", value: "Hide field", onMouseDown: () => {
                        onHideFieldClick();
                        close();
                    } }), enableSortInHeader && sortable && (_jsx(SortDropdownItems, { changeSort: changeSort, field: sortField, customFieldUrn: customFieldUrn })), enableFilterInHeader && (_jsx(FilterByFieldDropdown, { onClose: close, searchField: columnDef.field }))] })) }));
};
export const CompanyWatchlistCustomColumnHeader = observer(_CompanyWatchlistCustomColumnHeader);
