import CompanyColumnsConfig from 'interfaces/CompanyColumnsConfig';
const ConnectionsOptions = [
    {
        label: CompanyColumnsConfig.TEAM_CONNECTIONS.label,
        value: CompanyColumnsConfig.TEAM_CONNECTIONS.value
    },
    {
        label: CompanyColumnsConfig.AFFINITY_LISTS.label,
        value: CompanyColumnsConfig.AFFINITY_LISTS.value
    },
    {
        label: CompanyColumnsConfig.LAST_MEETING_DATE.label,
        value: CompanyColumnsConfig.LAST_MEETING_DATE.value
    },
    {
        label: CompanyColumnsConfig.LAST_MEETING_WITH.label,
        value: CompanyColumnsConfig.LAST_MEETING_WITH.value
    },
    {
        label: CompanyColumnsConfig.LAST_CONTACTED_DATE.label,
        value: CompanyColumnsConfig.LAST_CONTACTED_DATE.value
    },
    {
        label: CompanyColumnsConfig.LAST_CONTACTED_BY.label,
        value: CompanyColumnsConfig.LAST_CONTACTED_BY.value
    }
];
export default ConnectionsOptions;
