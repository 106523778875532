import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useFlags from 'hooks/useFlags';
import React from 'react';
import { SPLITS } from 'utils/constants';
import PendingDot from './PendingDot';
export function withPendingDot(WrappedComponent) {
    return (props) => {
        const { enabled: enableCustomFieldBulkActions } = useFlags(SPLITS.enableCustomFieldBulkActions);
        return (_jsxs("div", { className: "w-full flex items-center justify-between gap-g50", children: [_jsx(WrappedComponent, { ...props }), !!props.value?.pending && !!enableCustomFieldBulkActions && (_jsx(PendingDot, {}))] }));
    };
}
