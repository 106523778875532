import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { UsersFilledIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { useStore } from 'config/StoreProvider';
import { useGroupedUserConnections } from 'hooks/useGroupedUserConnections';
import { observer } from 'mobx-react';
import { PersonUserConnnectionsTooltip } from './PersonUserConnnectionsTooltip';
const PersonUserConnections = observer(({ target, userConnections, emphasizedStyle = true, gap = 'g20', iconFill = 'default', showIcon = true }) => {
    const { rootStore } = useStore();
    const groupedUserConnections = useGroupedUserConnections({
        userConnections,
        email: rootStore.authStore.user?.email
    });
    if (!groupedUserConnections) {
        return null;
    }
    const { userConnectionsToRender, groupedUserConnectionsToRender, connectionsToShowBehindHover, connectionsToShowWithoutHover } = groupedUserConnections;
    return (_jsx("div", { "data-testid": "PersonUserConnections", className: "flex max-w-[200px]", children: _jsxs("div", { className: classNames('flex items-center text-content-weak typography-label-default-default whitespace-pre', gap === 'g20' && 'gap-g20', gap === 'g30' && 'gap-g30'), children: [showIcon ? (_jsx(UsersFilledIcon, { className: classNames(iconFill === 'default'
                        ? 'text-content-default'
                        : iconFill === 'weak'
                            ? 'text-content-weak'
                            : 'text-content-muted') })) : null, _jsx("div", { className: "lg:hidden", children: `${groupedUserConnectionsToRender[0][0].userName} ${groupedUserConnectionsToRender.length > 1
                        ? `and ${groupedUserConnectionsToRender.length - 1} others`
                        : groupedUserConnectionsToRender[0][0].userName === 'You'
                            ? 'are'
                            : 'is'} in network` }), _jsxs("div", { className: "hidden lg:flex", "data-testid": "PersonUserConnections-text", children: [connectionsToShowWithoutHover.map((group, index) => {
                            const connection = group[0];
                            return (_jsxs("span", { className: "flex ", children: [_jsx("span", { className: classNames(emphasizedStyle
                                            ? 'text-content-strong'
                                            : 'text-content-weak'), children: connection.userName }), index < groupedUserConnectionsToRender?.length - 2
                                        ? ', '
                                        : index == groupedUserConnectionsToRender?.length - 2
                                            ? ' and '
                                            : '', index == connectionsToShowWithoutHover.length - 1 &&
                                        connectionsToShowBehindHover.length > 0 && (_jsx(PersonUserConnnectionsTooltip, { userConnections: connectionsToShowBehindHover, emphasizedStyle: emphasizedStyle }))] }, `userConnection_${index}`));
                        }), _jsxs("span", { className: "text-content-weak flex", children: [userConnectionsToRender.length > 1
                                    ? ' on your team are'
                                    : userConnectionsToRender[0].userName === 'You'
                                        ? ' are'
                                        : ' on your team is', ' ', "in network", ' ', target ? (_jsxs(_Fragment, { children: ["with", ' ', _jsx("p", { className: classNames(emphasizedStyle
                                                ? 'text-content-strong'
                                                : 'text-content-weak'), children: target.name })] })) : ('here'), "."] })] })] }) }));
});
export default PersonUserConnections;
