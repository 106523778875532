import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PlusIcon } from 'assets/harmonic-icons';
import ListGroup from 'harmonic-components/ListGroup/ListGroup';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import { useMemo } from 'react';
export const BaseFieldsDropdownItems = ({ onFieldClick, onCreateNewField, filterTerm, harmonicFields, customFields, hiddenFields }) => {
    const filteredHarmonicFields = useMemo(() => {
        return harmonicFields.filter((c) => !hiddenFields?.includes(c.value) &&
            c.label.toLowerCase().includes(filterTerm.toLowerCase()));
    }, [filterTerm, harmonicFields, hiddenFields]);
    const filteredCustomFields = useMemo(() => {
        return (customFields ?? []).filter((c) => !hiddenFields?.includes(c.value) &&
            c.label.toLowerCase().includes(filterTerm.toLowerCase()));
    }, [customFields, filterTerm, hiddenFields]);
    return (_jsxs(_Fragment, { children: [customFields && (_jsxs("div", { className: "overflow-y-scroll py-p20 w-full", children: [_jsx(ListGroup, { title: "Custom fields", children: filteredCustomFields.map((c) => (_jsx(ListItem, { variant: ListVariant.default, label: c.label, value: c.value, primaryIcon: c.icon, onClick: () => onFieldClick(c) }, c.urn))) }), _jsx(ListGroup, { title: "Harmonic fields", children: filteredHarmonicFields.map((c) => (_jsx(ListItem, { variant: ListVariant.default, label: c.label, value: c.value, primaryIcon: c.icon, onClick: () => onFieldClick(c) }, c.urn))) })] })), !customFields && (_jsx(_Fragment, { children: filteredHarmonicFields.map((c) => (_jsx(ListItem, { variant: ListVariant.default, label: c.label, value: c.value, primaryIcon: c.icon, onClick: () => onFieldClick(c) }, c.urn))) })), onCreateNewField && (_jsx(ListItem, { variant: ListVariant.default, label: "Create custom field", value: "Create custom field", primaryIcon: PlusIcon, onClick: onCreateNewField }))] }));
};
