import { jsx as _jsx } from "react/jsx-runtime";
import { TextareaAutosize } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
export const NotesCellEditor = React.forwardRef((props, ref) => {
    const [value, setValue] = React.useState(props.value || '');
    const refInput = React.useRef(null);
    const width = props.api.getColumn(props.colDef)?.getActualWidth();
    React.useEffect(() => {
        if (refInput.current) {
            refInput.current.focus();
            refInput.current.setSelectionRange(refInput.current.value.length, refInput.current.value.length);
        }
    }, [refInput]);
    React.useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            }
        };
    });
    const handleChange = (e) => {
        setValue(e.target.value);
        props.onValueChange?.(e.target.value);
    };
    const handleFocus = (event) => {
        if (event.target) {
            event.target.scrollTop = event.target.scrollHeight;
        }
    };
    const handleBlur = () => {
        refInput?.current?.focus();
    };
    const handleKeyDown = (e) => {
        if (e.code === 'Enter' && !e.shiftKey) {
            props.api.stopEditing();
        }
    };
    return (_jsx(TextareaAutosize, { onBlur: handleBlur, onFocus: handleFocus, onKeyDown: handleKeyDown, autoFocus: true, className: classNames('bg-surface-default rounded-br30 border-2 border-solid border-int-outline-secondary-selected-enabled text-content-default typography-paragraph-default-default', props.size === 'small' ? 'min-h-[28px] p-p20' : 'min-h-[77px] p-p50'), ...(props.size === 'small' && { minRows: 1 }), onChange: handleChange, ref: refInput, value: value, style: { width } }));
});
