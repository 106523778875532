import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/consistent-type-assertions */
// eslint-disable-next-line no-restricted-imports
import { useQuery } from '@apollo/client';
import AvatarWithHighlightBadge from 'components/common/Highlights/AvatarWithHighlightBadge';
import { useStore } from 'config/StoreProvider';
import CompanyAttribute from 'harmonic-components/CompanyAttribute/CompanyAttribute';
import { toJS } from 'mobx';
import getPersonProfileHeader from 'queries/getPersonProfileHeader';
import { useEffect, useState } from 'react';
import { DASHBOARD_ROUTE, PERSON_ROUTE } from 'utils/constants';
import { camelize } from 'utils/utilities';
const TalentFlowContentLoader = () => {
    return (_jsxs(_Fragment, { children: [_jsxs("div", { "data-testid": "CompanyTalentFlowLoading", className: "flex flex-row justify-between gap-10 grid-cols-2 mt-5 mb-4", children: [_jsx("div", { className: "flex w-full h-20 py-px animated-box-pulse" }), _jsx("div", { className: "flex w-full" })] }), _jsx("div", { className: "mt-5 h-80 py-14 bg-slate-200 rounded-md animate-pulse" })] }));
};
const TalentFlowPersonDetail = ({ id, type, name, title, prevOrNextTitle, prevOrNextCompanyLogoUrl, prevOrNextCompanyProfileUrl, prevOrNextCompanyName }) => {
    const { data, loading } = useQuery(getPersonProfileHeader, {
        variables: {
            id: id
        },
        skip: !id
    });
    if (!!id && loading)
        return null;
    const personRoute = `${DASHBOARD_ROUTE}${PERSON_ROUTE}/${id}`;
    const profilePictureUrl = data?.getPersonById?.profilePictureUrl ?? '';
    const highlights = (data?.getPersonById?.highlights ?? [])
        .map((highlight) => highlight?.text)
        .filter(Boolean);
    return (_jsxs("div", { className: "first:pt-0 last:pb-0 py-p60 flex gap-g60 bg-surface-nested border-b border-solid border-border last:border-none", children: [!!id && (_jsx(AvatarWithHighlightBadge, { src: profilePictureUrl, highlights: highlights, personId: id, href: personRoute })), _jsxs("div", { className: "flex flex-col gap-g20", children: [_jsx("p", { className: "typography-label-small-default", children: name }), _jsx("p", { className: "typography-label-small-default", children: title }), _jsxs("div", { className: "flex gap-g30 items-center", children: [!!prevOrNextTitle && (_jsx("p", { className: "typography-label-small-default text-content-weak", children: type === 'recentHires'
                                    ? `Was: ${prevOrNextTitle}`
                                    : `Now: ${prevOrNextTitle}` })), !!prevOrNextCompanyLogoUrl &&
                                !!prevOrNextCompanyProfileUrl &&
                                !!prevOrNextCompanyName && (_jsx(CompanyAttribute, { src: prevOrNextCompanyLogoUrl, href: prevOrNextCompanyProfileUrl, name: prevOrNextCompanyName }))] })] })] }));
};
const TalentFlowCard = ({ type, talentFlowData }) => {
    const data = type === 'recentHires'
        ? talentFlowData.recentHires
        : talentFlowData.recentOutflow;
    return (_jsxs("div", { className: "w-full border-border border-solid border bg-white rounded-xl shadow-sm mb-p80", children: [_jsxs("h3", { className: "typography-label-small-default border-b border-border border-solid p-p50", children: [type === 'recentHires' ? 'Recent hires' : 'Recent departures', " (", (data?.remainingCount || 0) + (data?.highlighted?.length || 0), ")"] }), _jsxs("div", { className: "flex flex-col gap-g60 p-p60", children: [_jsx("p", { className: "typography-label-small text-content-weak", children: type === 'recentHires' ? 'Executive hires' : 'Noteable departures' }), _jsx("div", { className: "flex flex-col p-p50 border-border border-solid border bg-surface-nested rounded-br40", children: data?.highlighted?.map((person) => person.name && person.title ? (_jsx(TalentFlowPersonDetail, { type: type, id: person.id, name: person.name, title: person.title, prevOrNextTitle: type === 'recentHires'
                                ? person.previousTitle || ''
                                : person.nextTitle || '', prevOrNextCompanyLogoUrl: type === 'recentHires'
                                ? person.previousCompanyLogoUrl
                                : person.nextCompanyLogoUrl, prevOrNextCompanyProfileUrl: type === 'recentHires'
                                ? person.previousCompanyProfileUrl
                                : person.nextCompanyProfileUrl, prevOrNextCompanyName: type === 'recentHires'
                                ? person.previousCompany
                                : person.nextCompany }, person.name)) : null) })] })] }));
};
const TalentFlowContent = ({ talentFlowData }) => {
    if (!talentFlowData) {
        return (_jsx("div", { className: "flex flex-row gap-g80 mt-4", children: "No talent flow data available" }));
    }
    return (_jsxs("div", { className: "flex flex-row gap-g80 mt-4", children: [_jsx(TalentFlowCard, { type: "recentHires", talentFlowData: talentFlowData }), _jsx(TalentFlowCard, { type: "recentDepartures", talentFlowData: talentFlowData })] }));
};
const CompanyTalentFlow = () => {
    const { rootStore } = useStore();
    const { diligenceReportLoading, diligenceReport } = rootStore.companyResearchStore;
    const [talentFlowData, setTalentFlowData] = useState(undefined);
    useEffect(() => {
        async function fetchDiligenceReport() {
            await rootStore.companyResearchStore.fetchCompanyDiligenceReport();
        }
        if (!diligenceReportLoading && !diligenceReport) {
            fetchDiligenceReport();
        }
        if (!diligenceReportLoading && diligenceReport) {
            const reportSections = camelize(toJS(diligenceReport.values)?.research_report_sections || []);
            const teamSection = reportSections.find((section) => section.title === 'Team');
            if (teamSection) {
                const artifacts = teamSection.subSections?.[0]?.artifacts;
                if (artifacts) {
                    const talentFlow = artifacts.find((artifact) => artifact.type === 'talent_flow_diagram');
                    if (talentFlow) {
                        setTalentFlowData({
                            recentHires: talentFlow.content?.recentHires,
                            recentOutflow: talentFlow.content?.recentOutflow
                        });
                    }
                }
            }
        }
    }, [diligenceReport, diligenceReportLoading, rootStore.companyResearchStore]);
    return (_jsxs("div", { children: [_jsx("p", { className: "typography-title-medium py-p60 border-b border-solid border-border", children: "Talent Flow" }), diligenceReportLoading ? (_jsx(TalentFlowContentLoader, {})) : (_jsx(TalentFlowContent, { talentFlowData: talentFlowData }))] }));
};
export default CompanyTalentFlow;
