import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ToastCautionIcon, ToastNegativeIcon, ToastPositiveIcon } from 'assets/harmonic-icons';
import Button from 'harmonic-components/Button/Button';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
export const ToastComponent = ({ primaryText, secondaryText, link, cta }) => (_jsxs("div", { className: "flex-1 flex flex-row justify-between items-center gap-g40", children: [_jsxs("div", { className: "flex flex-col gap-g20 py-p20", children: [_jsx("p", { className: "typography-paragraph text-content-inverted-strong", children: primaryText }), (link || secondaryText) && (_jsx("div", { className: "typography-paragraph-small-default text-content-inverted-default", children: link ? (_jsx(Link, { to: link, children: secondaryText })) : (_jsx("p", { children: secondaryText })) }))] }), cta && (_jsx(_Fragment, { children: _jsx(Button, { type: "inverted", emphasis: "low", size: "compact", label: cta.text, onClick: cta.onClick }) }))] }));
export const displayToast = ({ primaryText, secondaryText, link, mode = 'success', cta }) => {
    const toastContent = (_jsx(ToastComponent, { primaryText: primaryText, secondaryText: secondaryText, link: link, cta: cta }));
    if (mode === 'success')
        return toast.success(toastContent, {
            icon: () => (_jsx(ToastPositiveIcon, {}))
        });
    if (mode === 'info')
        return toast.info(toastContent, {
            icon: () => (_jsx(ToastCautionIcon, {}))
        });
    if (mode === 'warn')
        return toast.warn(toastContent, {
            icon: () => (_jsx(ToastCautionIcon, {}))
        });
    if (mode === 'error')
        return toast.error(toastContent, {
            icon: () => (_jsx(ToastNegativeIcon, {}))
        });
};
