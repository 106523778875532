import { get } from 'lodash';
import { EventTypeToDescriptionMapping } from '../DataModel/Event';
export var FieldGrouping;
(function (FieldGrouping) {
    FieldGrouping["EMPLOYEE_CURRENT_EXPERIENCE"] = "EMPLOYEE_CURRENT_EXPERIENCE";
    FieldGrouping["EMPLOYEE_PAST_EXPERIENCE"] = "EMPLOYEE_PAST_EXPERIENCE";
    FieldGrouping["EMPLOYEE_EDUCATION_EXPERIENCE"] = "EMPLOYEE_EDUCATION_EXPERIENCE";
    FieldGrouping["TAG"] = "TAG";
    FieldGrouping["NO_GROUPING"] = "NO_GROUPING";
    FieldGrouping["PERSON_EDUCATION_EXPERIENCE"] = "PERSON_EDUCATION_EXPERIENCE";
    FieldGrouping["PERSON_PAST_EXPERIENCE"] = "PERSON_PAST_EXPERIENCE";
    FieldGrouping["PERSON_CURRENT_EXPERIENCE"] = "PERSON_CURRENT_EXPERIENCE";
    FieldGrouping["PERSON_LAST_EXPERIENCE"] = "PERSON_LAST_EXPERIENCE";
    FieldGrouping["PERSON_HIGHLIGHTS"] = "PERSON_HIGHLIGHTS";
    FieldGrouping["INSTAGRAM"] = "INSTAGRAM";
    FieldGrouping["HEADCOUNT"] = "HEADCOUNT";
    FieldGrouping["HEADCOUNT_ADVISOR"] = "HEADCOUNT_ADVISOR";
    FieldGrouping["HEADCOUNT_CUSTOMER_SUCCESS"] = "HEADCOUNT_CUSTOMER_SUCCESS";
    FieldGrouping["HEADCOUNT_DATA"] = "HEADCOUNT_DATA";
    FieldGrouping["HEADCOUNT_DESIGN"] = "HEADCOUNT_DESIGN";
    FieldGrouping["HEADCOUNT_ENGINEERING"] = "HEADCOUNT_ENGINEERING";
    FieldGrouping["HEADCOUNT_FINANCE"] = "HEADCOUNT_FINANCE";
    FieldGrouping["HEADCOUNT_LEGAL"] = "HEADCOUNT_LEGAL";
    FieldGrouping["HEADCOUNT_MARKETING"] = "HEADCOUNT_MARKETING";
    FieldGrouping["HEADCOUNT_OPERATIONS"] = "HEADCOUNT_OPERATIONS";
    FieldGrouping["HEADCOUNT_OTHER"] = "HEADCOUNT_OTHER";
    FieldGrouping["HEADCOUNT_PEOPLE"] = "HEADCOUNT_PEOPLE";
    FieldGrouping["HEADCOUNT_PRODUCT"] = "HEADCOUNT_PRODUCT";
    FieldGrouping["HEADCOUNT_SALES"] = "HEADCOUNT_SALES";
    FieldGrouping["HEADCOUNT_SUPPORT"] = "HEADCOUNT_SUPPORT";
    FieldGrouping["FACEBOOK"] = "FACEBOOK";
    FieldGrouping["LINKEDIN"] = "LINKEDIN";
    FieldGrouping["FUNDING"] = "FUNDING";
    FieldGrouping["TWITTER"] = "TWITTER";
    FieldGrouping["WEB_TRAFFIC"] = "WEB_TRAFFIC";
})(FieldGrouping || (FieldGrouping = {}));
export var FilterDisplayFormatType;
(function (FilterDisplayFormatType) {
    FilterDisplayFormatType["PARAGRAPH"] = "PARAGRAPH";
    FilterDisplayFormatType["DATE"] = "DATE";
    FilterDisplayFormatType["YEAR"] = "YEAR";
    FilterDisplayFormatType["MONEY"] = "MONEY";
    FilterDisplayFormatType["PERCENTAGE"] = "PERCENTAGE";
})(FilterDisplayFormatType || (FilterDisplayFormatType = {}));
export var TableFilterType;
(function (TableFilterType) {
    TableFilterType["STRING"] = "string";
    TableFilterType["DATE"] = "date";
    TableFilterType["INTEGER"] = "integer";
    TableFilterType["ARRAY"] = "array";
    TableFilterType["BOOLEAN"] = "boolean";
    TableFilterType["NULL"] = "null";
})(TableFilterType || (TableFilterType = {}));
export var FilterNumberComparator;
(function (FilterNumberComparator) {
    FilterNumberComparator["EQUALS"] = "equals";
    FilterNumberComparator["NOT_EQUALS"] = "notEquals";
    FilterNumberComparator["LESS_THAN"] = "lessThan";
    FilterNumberComparator["LESS_THAN_OR_EQUALS"] = "lessThanOrEquals";
    FilterNumberComparator["GREATER_THAN"] = "greaterThan";
    FilterNumberComparator["GREATER_THAN_OR_EQUALS"] = "greaterThanOrEquals";
    FilterNumberComparator["IN_RANGE"] = "inRange";
})(FilterNumberComparator || (FilterNumberComparator = {}));
export var FilterDateComparator;
(function (FilterDateComparator) {
    FilterDateComparator["AFTER_DATE"] = "afterDate";
    FilterDateComparator["BEFORE_DATE"] = "beforeDate";
    FilterDateComparator["EXACT_DATE"] = "exactDate";
    FilterDateComparator["IN_RANGE"] = "inRange";
    FilterDateComparator["IN_DAYS_AGO_RANGE"] = "inDaysAgoRange";
    FilterDateComparator["NOT_IN_DAYS_AGO_RANGE"] = "notInDaysAgoRange";
})(FilterDateComparator || (FilterDateComparator = {}));
export var FilterStringComparator;
(function (FilterStringComparator) {
    FilterStringComparator["EXACT_MATCH"] = "exactMatch";
    FilterStringComparator["TEXT_MATCH"] = "match";
    FilterStringComparator["NOT_TEXT_MATCH"] = "notMatch";
    FilterStringComparator["IS_EMPTY"] = "isEmpty";
    FilterStringComparator["IS_NOT_EMPTY"] = "isNotEmpty";
})(FilterStringComparator || (FilterStringComparator = {}));
export var FilterArrayComparator;
(function (FilterArrayComparator) {
    FilterArrayComparator["ANY_OF"] = "anyOf";
    FilterArrayComparator["NOT_ANY_OF"] = "notAnyOf";
    FilterArrayComparator["ANY_OF_ARRAY"] = "anyOfArray";
    FilterArrayComparator["NOT_ANY_OF_ARRAY"] = "notAnyOfArray";
    FilterArrayComparator["ALL_OF_ARRAY"] = "allOfArray";
    FilterArrayComparator["ANY_MATCH"] = "anyMatch";
    FilterArrayComparator["NONE_MATCH"] = "notAnyMatch";
    FilterArrayComparator["ALL_MATCH"] = "allMatch";
    FilterArrayComparator["SUBSTRING_MATCH"] = "substringMatch";
    FilterArrayComparator["NOT_SUBSTRING_MATCH"] = "notSubstringMatch";
    FilterArrayComparator["IS_EMPTY"] = "isEmpty";
})(FilterArrayComparator || (FilterArrayComparator = {}));
export var FilterListComparator;
(function (FilterListComparator) {
    FilterListComparator["IS_IN_LIST"] = "isInList";
    FilterListComparator["IS_NOT_IN_LIST"] = "isNotInList";
})(FilterListComparator || (FilterListComparator = {}));
export var FilterBooleanComparator;
(function (FilterBooleanComparator) {
    FilterBooleanComparator["IS"] = "is";
    FilterBooleanComparator["IS_NOT"] = "isNot";
})(FilterBooleanComparator || (FilterBooleanComparator = {}));
export var FilterPlaceholder;
(function (FilterPlaceholder) {
    FilterPlaceholder["FIELD"] = "$FIELD$";
    FilterPlaceholder["COMPARATOR"] = "$COMPARATOR$";
    FilterPlaceholder["FILTER_VALUE"] = "$FILTER_VALUE$";
})(FilterPlaceholder || (FilterPlaceholder = {}));
// The keys and values for the enum are different because
// the `type` field values used in the backend couldn't be changed without changing the
// API and as a result, would take months to make the shift (See issue DISCO-928 on Linear.app).
// So, when compiled, this enum's values are the same as the API `type` field values, but we could
// reference them with the more descriptive typescript enum keys, without having to do any manual mapping.
// Awaiting: backend migration to use & match the new enum values.
export var EntityListType;
(function (EntityListType) {
    EntityListType["COMPANY_SAVED_SEARCH"] = "COMPANIES_LIST";
    EntityListType["PEOPLE_SAVED_SEARCH"] = "PERSONS";
    EntityListType["COMPANY_WATCHLIST"] = "COMPANY_WATCHLIST";
    EntityListType["PEOPLE_WATCHLIST"] = "PEOPLE_WATCHLIST";
})(EntityListType || (EntityListType = {}));
export var SearchType;
(function (SearchType) {
    SearchType["COMPANIES_LIST"] = "COMPANIES_LIST";
    SearchType["EVENT_FEED"] = "EVENT_FEED";
    SearchType["PERSONS"] = "PERSONS";
})(SearchType || (SearchType = {}));
export var ResultsView;
(function (ResultsView) {
    ResultsView["GRID"] = "GRID";
    ResultsView["BOARD"] = "BOARD";
})(ResultsView || (ResultsView = {}));
export const EntityListTypeToDashboardPath = {
    [EntityListType.COMPANY_SAVED_SEARCH]: 'companies',
    [EntityListType.PEOPLE_SAVED_SEARCH]: 'people',
    [EntityListType.COMPANY_WATCHLIST]: 'company_watchlist',
    [EntityListType.PEOPLE_WATCHLIST]: 'people_watchlist'
};
export const EntityURNTypeToDashboardPath = {
    [EntityListType.COMPANY_SAVED_SEARCH]: 'saved_search',
    [EntityListType.PEOPLE_SAVED_SEARCH]: 'saved_search',
    [EntityListType.COMPANY_WATCHLIST]: 'company_watchlist',
    [EntityListType.PEOPLE_WATCHLIST]: 'people_watchlist'
};
export var EntityListModalType;
(function (EntityListModalType) {
    EntityListModalType[EntityListModalType["UpdateList"] = 0] = "UpdateList";
    EntityListModalType[EntityListModalType["DuplicateList"] = 1] = "DuplicateList";
    EntityListModalType[EntityListModalType["CreateList"] = 2] = "CreateList";
})(EntityListModalType || (EntityListModalType = {}));
export var FieldCategory;
(function (FieldCategory) {
    FieldCategory["COMPANY"] = "COMPANY";
    FieldCategory["PERSON"] = "PERSON";
    FieldCategory["FINANCING"] = "FINANCING";
    FieldCategory["TRACTION"] = "TRACTION";
    FieldCategory["OTHER"] = "OTHER";
    FieldCategory["EDUCATION"] = "EDUCATION";
    FieldCategory["ROLE"] = "ROLE";
    FieldCategory["SOCIAL"] = "SOCIAL";
    FieldCategory["CUSTOM"] = "CUSTOM";
})(FieldCategory || (FieldCategory = {}));
export const FieldCategoryLabel = {
    [FieldCategory.COMPANY]: 'Company',
    [FieldCategory.PERSON]: 'Team',
    [FieldCategory.FINANCING]: 'Financing',
    [FieldCategory.TRACTION]: 'Traction',
    [FieldCategory.OTHER]: 'Other',
    [FieldCategory.EDUCATION]: 'Education',
    [FieldCategory.ROLE]: 'Role',
    [FieldCategory.SOCIAL]: 'Social',
    [FieldCategory.CUSTOM]: 'Custom'
};
export var FieldEntityType;
(function (FieldEntityType) {
    FieldEntityType["COMPANY"] = "COMPANY";
    FieldEntityType["PERSON"] = "PERSON";
    FieldEntityType["SCHOOL"] = "SCHOOL";
})(FieldEntityType || (FieldEntityType = {}));
/**
 * Initial search model values
 */
export const INITIAL_COMPANY_SORT = {
    sortField: 'featured',
    sortDescending: true
};
export const INITIAL_PEOPLE_SORT = {
    sortField: 'person_id',
    sortDescending: false
};
export const INITIAL_SEARCH_FILTER = {
    field: 'company_name',
    filter_value: '',
    comparator: FilterArrayComparator.SUBSTRING_MATCH
};
export const INITIAL_SEARCH_FILTER_GROUP = {
    join_operator: 'and',
    filter_groups: [],
    filters: []
};
export const INITIAL_COMPANY_SEARCH_SORT_QUERY = [
    {
        sort_field: INITIAL_COMPANY_SORT.sortField,
        descending: INITIAL_COMPANY_SORT.sortDescending
    }
];
export const INITIAL_PEOPLE_SEARCH_SORT_QUERY = [
    {
        sort_field: INITIAL_PEOPLE_SORT.sortField,
        descending: INITIAL_PEOPLE_SORT.sortDescending
    }
];
export const INITIAL_SEARCH_CONTROLLED_FILTER_GROUP = {
    filter_group_generators: [],
    join_operator: 'and',
    filter_groups: [],
    filters: []
};
export const INITIAL_SEARCH_COMPANY_FILTER_GROUP_QUERY = {
    filter_group: INITIAL_SEARCH_FILTER_GROUP,
    controlled_filter_group: INITIAL_SEARCH_CONTROLLED_FILTER_GROUP,
    sort: INITIAL_COMPANY_SEARCH_SORT_QUERY
};
export const INITIAL_SEARCH_PEOPLE_FILTER_GROUP_QUERY = {
    filter_group: INITIAL_SEARCH_FILTER_GROUP,
    controlled_filter_group: INITIAL_SEARCH_CONTROLLED_FILTER_GROUP,
    sort: []
};
const INITIAL_SEARCH_PAGINATION = {
    start: 0,
    page_size: process.env.NODE_ENV === 'production' ? 25 : 10
};
// Used to set default sort options for the company search
export const INITIAL_PEOPLE_SORT_VALUE = 'person_id';
export const INITIAL_SEARCH_MODEL = {
    filter_group: INITIAL_SEARCH_FILTER_GROUP,
    controlled_filter_group: INITIAL_SEARCH_CONTROLLED_FILTER_GROUP,
    pagination: INITIAL_SEARCH_PAGINATION,
    sort: [
        {
            sort_field: INITIAL_COMPANY_SORT.sortField,
            descending: INITIAL_COMPANY_SORT.sortDescending
        }
    ]
};
export const INITIAL_PEOPLE_SEARCH_MODEL = {
    filter_group: INITIAL_SEARCH_FILTER_GROUP,
    pagination: INITIAL_SEARCH_PAGINATION,
    sort: [
        {
            sort_field: INITIAL_PEOPLE_SORT_VALUE,
            descending: false
        }
    ]
};
const EMPTY_FILTER_VALUE_STRING = '$EMPTY$';
const FundingTypeEnums = {
    ANGEL: 'Angel',
    CONVERTIBLE_NOTE: 'Convertible Note',
    CORPORATE_ROUND: 'Corporate Round',
    DEBT_FINANCING: 'Debt Financing',
    EQUITY_CROWDFUNDING: 'Equity Crowdfunding',
    OUT_OF_BUSINESS: 'Out of Business',
    GRANT: 'Grant',
    INITIAL_COIN_OFFERING: 'Initial Coin Offering',
    NON_EQUITY_ASSISTANCE: 'Non-equity Assistance',
    POST_IPO_DEBT: 'Post-IPO Debt',
    POST_IPO_EQUITY: 'Post-IPO Equity',
    POST_IPO_SECONDARY: 'Post-IPO Secondary',
    PRE_SEED: 'Preseed',
    PRIVATE_EQUITY: 'Private Equity',
    PRODUCT_CROWDFUNDING: 'Product Crowdfunding',
    SECONDARY_MARKET: 'Secondary Market',
    SEED: 'Seed',
    SERIES_A: 'Series A',
    SERIES_B: 'Series B',
    SERIES_C: 'Series C',
    SERIES_D: 'Series D',
    SERIES_E: 'Series E',
    SERIES_F: 'Series F',
    SERIES_G: 'Series G',
    SERIES_H: 'Series H',
    SERIES_I: 'Series I',
    SERIES_J: 'Series J',
    SERIES_K_PLUS: 'Series K+',
    SERIES_UNKNOWN: 'Unknown venture',
    UNDISCLOSED: 'Undisclosed',
    EARLY_STAGE_VC: 'Early Stage VC',
    ACCELERATOR_INC: 'Accelerator/​Inc',
    DEBT_PP: 'Debt - PPP',
    LATER_STAGE_VC: 'Later Stage VC',
    SERIES_1: 'Series 1',
    SERIES_A1: 'Series A1',
    VENTURE_DEBT: 'Venture Debt',
    EARLY_STAGE_VENTURE: 'Early Stage Venture',
    LATER_STAGE_VENTURE: 'Late Stage Venture',
    PRE__SEED: 'Pre-Seed',
    FUNDING_ROUND: 'Funding Round',
    PIPE: 'PIPE',
    M_AND_A: 'M&A',
    PE_GROWTH: 'PE Growth',
    ANGEL_INDIVIDUAL: 'Angel (individual)',
    ACCELERATOR_INCUBATOR: 'Accelerator/Incubator',
    SEED_ROUND: 'Seed Round',
    EARLY_STAGE_SERIES_A: 'Early Stage VC (Series A)',
    EARLY_STAGE_SERIES_A1: 'Early Stage VC (Series A1)',
    EARLY_STAGE_SERIES_B: 'Early Stage VC (Series B)',
    EARLY_STAGE_SERIES_B1: 'Early Stage VC (Series B1)',
    LATER_STAGE_SERIES_C: 'Later Stage VC (Series C)',
    LATER_STAGE_SERIES_C1: 'Later Stage VC (Series C1)',
    CORPORATE_SERIES_A: 'Corporate (Series A)',
    LATER_STAGE_SERIES_E: 'Later Stage VC (Series E)',
    LATER_STAGE_SERIES_B: 'Later Stage VC (Series B)',
    LATER_STAGE_SERIES_D: 'Later Stage VC (Series D)',
    IPO: 'IPO',
    BUYOUT_LBO: 'Buyout/LBO',
    SECONDARY_PRIVATE: '2ndary - Private',
    DEBT: 'Debt',
    CORPORATE: 'Corporate',
    CROWDFUNDING: 'Crowdfunding',
    LIQUIDATION: 'Liquidation',
    DEBT_REFIN: 'Debt Refin.',
    UNDERTERMINED: 'Undetermined',
    BANK_ADMIN: 'Bankr. Admin/Reorg',
    MERGER_OF_EQ: 'Merger of Eq.',
    SERIES_B1: 'Series B1',
    CONV_DEBT: 'Conv. Debt',
    JOINT_VENTURE: 'Joint Venture',
    IBO: 'IBO',
    MEZZANINE: 'Mezzanine',
    SERIES_A2: 'Series A2',
    ASSET_PURCH: 'Asset Purch.',
    SERIES_2: 'Series 2',
    PLATFORM: 'Platform',
    DEBT_REPAY: 'Debt Repay.',
    REVERSE_MERGER: 'Reverse Merger',
    SERIES_C1: 'Series C1',
    DIV_RECAP: 'Div. Recap.',
    LEV_RECAP: 'Lev. Recap.',
    SERIES_A3: 'Series A3',
    SERIES_B2: 'Series B2',
    SERIES_AA: 'Series AA',
    SECONDARY_OPEN: '2ndary - Open',
    CAPITALIZATION: 'Capitalization',
    SERIES_3: 'Series 3',
    SERIES_D1: 'Series D1',
    REPURCHASE: 'Repurchase',
    SERIES_C2: 'Series C2',
    DEBT_CONV: 'Debt Conv.',
    BRIDGE: 'Bridge',
    SERIES_B3: 'Series B3',
    RESTART_ANGEL: 'Restart - Angel',
    SERIES_BB: 'Series BB',
    SERIES_E1: 'Series E1',
    SERIES_D2: 'Series D2',
    SERIES_C3: 'Series C3',
    TWO_PO: '2PO',
    EQUITY_FOR_SVC: 'Equity for Svc.',
    SECONDARY_DISTRIB: '2ndary - Distrib.',
    DIVIDEND: 'Dividend',
    VENTURE_LEASING: 'Venture Leasing',
    RESTART_LATER: 'Restart - Later',
    SERIES_AAA: 'Series AAA',
    SERIES_CC: 'Series CC',
    SERIES_E2: 'Series E2',
    SALE_LEASE_BACK: 'Sale-Lease back',
    SPIN_OFF: 'Spin-Off',
    SERIES_D3: 'Series D3',
    RESTART_EARLY: 'Restart - Early',
    GP_STAKES: 'GP Stakes',
    SECONDARY_TRANSACTION_PRIVATE: 'Secondary Transaction - Private',
    LATER_STAGE_SERIES_G: 'Later Stage VC (Series G)',
    DEBT_GENERAL: 'Debt - General',
    LATER_STAGE_SERIES_F: 'Later Stage VC (Series F)',
    STEALTH: 'Stealth',
    VENTURE_UNKNOWN: 'Unknown venture',
    EXITED: 'Exited'
};
export const SearchValueToDisplayNameMap = {
    // Event Types
    ...EventTypeToDescriptionMapping,
    // Selectivity / Likelihood of Backing
    one: '1',
    two: '2',
    three: '3',
    four: '4',
    major_hire: 'Major Hire',
    backed: 'Backed',
    // Funding Stage
    // new mappings for FundingType ENUM
    ...FundingTypeEnums,
    // old mappings for FundingType
    angel: 'Angel',
    convertible_note: 'Convertible Note',
    corporate_round: 'Corporate Round',
    debt_financing: 'Debt Financing',
    equity_crowdfunding: 'Equity Crowdfunding',
    grant: 'Grant',
    initial_coin_offering: 'Initial Coin Offering',
    non_equity_assistance: 'Non-equity Assistance',
    post_ipo_debt: 'Post-IPO Debt',
    post_ipo_equity: 'Post-IPO Equity',
    post_ipo_secondary: 'Post-IPO Secondary',
    pre_seed: 'Preseed',
    private_equity: 'Private Equity',
    product_crowdfunding: 'Product Crowdfunding',
    secondary_market: 'Secondary Market',
    seed: 'Seed',
    SEED_EXTENSION: 'Seed',
    series_a: 'Series A',
    series_b: 'Series B',
    series_c: 'Series C',
    series_d: 'Series D',
    series_e: 'Series E',
    series_f: 'Series F',
    series_g: 'Series G',
    series_h: 'Series H',
    series_i: 'Series I',
    series_j: 'Series J',
    SERIES_A_EXTENSION: 'Series A (Ext)',
    SERIES_B_EXTENSION: 'Series B (Ext)',
    SERIES_C_EXTENSION: 'Series C (Ext)',
    SERIES_D_EXTENSION: 'Series D (Ext)',
    SERIES_E_EXTENSION: 'Series E (Ext)',
    SERIES_F_EXTENSION: 'Series F (Ext)',
    SERIES_G_EXTENSION: 'Series G (Ext)',
    STRATEGIC: 'Strategic',
    SECONDARY_TRANSACTION: 'Secondary transaction',
    VENTURE_OTHER: 'Venture (Other)',
    series_unknown: 'Unknown venture',
    undisclosed: 'Undisclosed',
    early_stage_vc: 'Early Stage VC',
    accelerator_inc: 'Accelerator/​Inc',
    debt_pp: 'Debt - PPP',
    later_stage_vc: 'Later Stage VC',
    series_1: 'Series 1',
    series_a1: 'Series A1',
    venture_debt: 'Venture Debt',
    early_stage_venture: 'Early Stage Venture',
    later_stage_venture: 'Late Stage Venture',
    pre__seed: 'Pre-Seed',
    funding_round: 'Funding Round',
    pipe: 'PIPE',
    'Venture - Series Unknown': 'Unknown venture',
    // Funding Attribute Null Status
    EXISTS_BUT_UNDISCLOSED: 'Undisclosed',
    NONE_ANNOUNCED: 'None Announced',
    // Company Type
    UNKNOWN: 'Unknown venture',
    STARTUP: 'Startup',
    // Typed Tags
    PRODUCT_HUNT: 'Product Hunt',
    YC_BATCH: 'YC Batch',
    INDUSTRY: 'Industry',
    EXTERNAL_INDUSTRY: 'External Industry',
    TECHNOLOGY: 'Technology',
    // Role Type
    FOUNDER: 'Founder',
    EXECUTIVE: 'Executive',
    OPERATOR: 'Operator',
    INTERN: 'Intern',
    ALUMNI: 'Alumni',
    ADVISER: 'Adviser',
    EMPLOYEE: 'Employee',
    // Empty
    UNKNOWN_VALUE: '—',
    [EMPTY_FILTER_VALUE_STRING]: '(Empty)'
};
export const getFundingTypeDisplayName = (value) => {
    if (!value)
        return '';
    return get(SearchValueToDisplayNameMap, value, value);
};
export var EditFundingRoundType;
(function (EditFundingRoundType) {
    EditFundingRoundType["PRE_SEED"] = "PRE_SEED";
    EditFundingRoundType["SEED"] = "SEED";
    EditFundingRoundType["SERIES_A"] = "SERIES_A";
    EditFundingRoundType["SERIES_A_EXTENSION"] = "SERIES_A_EXTENSION";
    EditFundingRoundType["SERIES_B"] = "SERIES_B";
    EditFundingRoundType["SERIES_B_EXTENSION"] = "SERIES_B_EXTENSION";
    EditFundingRoundType["SERIES_C"] = "SERIES_C";
    EditFundingRoundType["SERIES_C_EXTENSION"] = "SERIES_C_EXTENSION";
    EditFundingRoundType["SERIES_D"] = "SERIES_D";
    EditFundingRoundType["SERIES_D_EXTENSION"] = "SERIES_D_EXTENSION";
    EditFundingRoundType["SERIES_E"] = "SERIES_E";
    EditFundingRoundType["SERIES_E_EXTENSION"] = "SERIES_E_EXTENSION";
    EditFundingRoundType["SERIES_F"] = "SERIES_F";
    EditFundingRoundType["SERIES_F_EXTENSION"] = "SERIES_F_EXTENSION";
    EditFundingRoundType["SERIES_G"] = "SERIES_G";
    EditFundingRoundType["SERIES_G_EXTENSION"] = "SERIES_G_EXTENSION";
    EditFundingRoundType["SERIES_H"] = "SERIES_H";
    EditFundingRoundType["SERIES_I"] = "SERIES_I";
    EditFundingRoundType["SERIES_J"] = "SERIES_J";
    EditFundingRoundType["SERIES_K_PLUS"] = "SERIES_K_PLUS";
    EditFundingRoundType["PRIVATE_EQUITY"] = "PRIVATE_EQUITY";
    EditFundingRoundType["VENTURE_DEBT"] = "VENTURE_DEBT";
    EditFundingRoundType["VENTURE_OTHER"] = "VENTURE_OTHER";
})(EditFundingRoundType || (EditFundingRoundType = {}));
