import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from 'harmonic-components/Button/Button';
import SingleDatePicker from 'harmonic-components/DatePicker/SingleDatePicker';
import { useState } from 'react';
const EditCustomFieldsDateValue = ({ customField, onCancel, onSave, onClear }) => {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const dateFormat = customField?.metadata?.__typename === 'DateListCustomFieldMetadata'
        ? customField.metadata.dateFormat
        : null;
    const handleOnChange = (date) => {
        setValue(date);
    };
    const handleOnCancel = () => {
        onCancel();
    };
    const handleOnSave = async () => {
        setLoading(true);
        if (!value) {
            await onClear();
        }
        else {
            await onSave(value);
        }
        setLoading(false);
    };
    return (_jsxs("div", { className: "w-full", children: [_jsx(SingleDatePicker, { fullWidth: true, selectedDate: value, inputType: "text", 
                //eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                dateFormat: dateFormat, onChange: handleOnChange }), _jsxs("div", { className: "flex gap-g40 mt-p50", children: [_jsx(Button, { fullWidth: true, type: "secondary", emphasis: "medium", size: "compact", label: "Cancel", onClick: handleOnCancel, isDisabled: loading }), _jsx(Button, { fullWidth: true, type: "primary", emphasis: "high", size: "compact", label: "Save", onClick: handleOnSave, loading: loading, isDisabled: loading })] })] }));
};
export default EditCustomFieldsDateValue;
