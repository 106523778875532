import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LinkedInColorless from 'assets/harmonic-icons/linkedin-colorless';
import Avatar from 'harmonic-components/Avatar/Avatar';
import React from 'react';
const TeamProfileCard = ({ name, profilePictureUrl, title, linkedinUrl, education, experience }) => {
    return (_jsxs("div", { className: "flex flex-col w-full rounded-xl bg-surface-nested", children: [_jsxs("div", { className: "flex flex-row items-center gap-g50 p-p50 border-b border-border border-solid", children: [_jsx(Avatar, { src: profilePictureUrl, alt: name, kind: "face", size: "x-large" }), _jsxs("div", { className: "flex flex-col", children: [_jsxs("h3", { className: "typography-label-default-default text-content-title flex flex-row items-center gap-g20", children: [name, !!linkedinUrl && (_jsx("a", { href: linkedinUrl, target: "_blank", rel: "noopener noreferrer", className: "w-2", children: _jsx(LinkedInColorless, { className: "w-4 text-content-weak hover:text-blue-600" }) }))] }), _jsx("p", { className: "typography-paragraph-extraSmall-default text-content-weak", children: title })] })] }), _jsx("div", { className: "flex flex-row gap-g20 p-p60 border-b border-border border-solid", children: _jsxs("div", { className: "flex flex-col", children: [_jsx("h4", { className: "typography-paragraph-extraSmall-default text-content-weak", children: "Education" }), _jsx("p", { className: "text-sm text-gray-500", children: education[0] })] }) }), _jsx("div", { className: "flex flex-row gap-g20 p-p60 border-b border-border", children: _jsxs("div", { className: "flex flex-col", children: [_jsx("h4", { className: "typography-paragraph-extraSmall-default text-content-weak", children: "Experience" }), _jsxs("p", { className: "text-sm text-gray-500", children: [experience.slice(0, 3).map((exp) => {
                                    return (_jsxs("div", { className: "py-p40", children: [_jsx("p", { className: "typography-label-default-default text-content-title", children: exp.title }), _jsx("p", { className: "typography-label-small-default text-content-weak", children: exp.duration })] }, exp.title));
                                }), experience.length > 3 && (_jsxs("p", { className: "typography-label-small-default text-content-weak", children: ["+", experience.length - 3, " more"] }))] })] }) })] }));
};
export const TeamSummaryTable = ({ content }) => {
    return (_jsx("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-g40", children: content.rows.map((row) => (_jsx(TeamProfileCard, { ...row }, row.name))) }));
};
