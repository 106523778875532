import { gql } from '@apollo/client';
import { SavedSearchFragmentWithoutColumns } from './savedSearchFragment';
export const GET_SAVED_SEARCH_NOTIFICATION_SUBSCRIPTIONS = gql `
  fragment SavedSearchNotificationSubscription on SavedSearchNotificationSubscription {
    savedSearchUrn
    savedSearch {
      ...SavedSearch
    }
    slackIntegrationUrn
    subscriptionUrn
    fieldsToShow
    frequency
    isEnabled
    notificationCap
  }
  query GetSavedSearchNotificationSubscriptions {
    getSavedSearchNotificationSubscriptions {
      ...SavedSearchNotificationSubscription
    }
  }
  ${SavedSearchFragmentWithoutColumns}
`;
