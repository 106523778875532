import { ENVIRONMENT, FrontendEnvironments } from 'utils/constants';
import { getURLParamValue } from 'utils/utilities';
const flagDisablePrefetch = getURLParamValue('disablePrefetch') === 'true';
const local = {
    BASE_CRM_INTEGRATION_API_URL: 'http://localhost:6972',
    BASE_GRAPHQL_API_URL: 'http://localhost:4000/graphql',
    BASE_GRAPHQL_WEBSOCKET_URL: 'ws://localhost:4000/graphql',
    BASE_MIDTIER_API_URL: 'http://localhost:80/',
    BASE_CONSOLE_URL: 'http://localhost:3000',
    DISABLE_PREFETCH: true,
    INTERCOM_APP_ID: 'go7ahz14',
    SPLIT_API_KEY: '6lpmuqblee4lbttqu7lmftg4h39rieql40d9',
    EXTENSION_IDS: [
        'pmnechipnndlblhloienjjdafcloodap',
        'ikkpacjgdcmnnlkponeejjkhkbfmcplk',
        'lpeokfegmaajjedpinemghjbloboglii'
    ]
};
const stage = {
    BASE_CRM_INTEGRATION_API_URL: 'https://crm-integration-py-production.up.railway.app',
    BASE_GRAPHQL_API_URL: 'https://api.staging.harmonic.ai/graphql',
    BASE_GRAPHQL_WEBSOCKET_URL: 'ws://api.staging.harmonic.ai/graphql',
    BASE_MIDTIER_API_URL: 'https://api.staging.harmonic.ai/',
    BASE_CONSOLE_URL: 'https://console.staging.harmonic.ai',
    DISABLE_PREFETCH: flagDisablePrefetch,
    INTERCOM_APP_ID: 'go7ahz14',
    SPLIT_API_KEY: '6lpmuqblee4lbttqu7lmftg4h39rieql40d9',
    EXTENSION_IDS: [
        'pmnechipnndlblhloienjjdafcloodap',
        'ikkpacjgdcmnnlkponeejjkhkbfmcplk',
        'lpeokfegmaajjedpinemghjbloboglii' // harmonic-linkedin-web-extension
    ]
};
const canary = {
    BASE_CRM_INTEGRATION_API_URL: 'https://crm-integration-py-production.up.railway.app',
    BASE_GRAPHQL_API_URL: 'https://api.canary.harmonic.ai/graphql',
    BASE_GRAPHQL_WEBSOCKET_URL: 'wss://api.canary.harmonic.ai/graphql',
    BASE_MIDTIER_API_URL: 'https://api.canary.harmonic.ai/',
    BASE_CONSOLE_URL: 'https://console.canary.harmonic.ai',
    DISABLE_PREFETCH: flagDisablePrefetch,
    INTERCOM_APP_ID: 'zbddx3lp',
    SPLIT_API_KEY: '6lpmuqblee4lbttqu7lmftg4h39rieql40d9',
    EXTENSION_IDS: [
        'pmnechipnndlblhloienjjdafcloodap',
        'ikkpacjgdcmnnlkponeejjkhkbfmcplk',
        'lpeokfegmaajjedpinemghjbloboglii' // harmonic-linkedin-web-extension
    ]
};
const prod = {
    BASE_CRM_INTEGRATION_API_URL: 'https://crm-integration-py-production.up.railway.app',
    BASE_GRAPHQL_API_URL: 'https://api.harmonic.ai/graphql',
    BASE_GRAPHQL_WEBSOCKET_URL: 'wss://api.harmonic.ai/graphql/subscriptions',
    BASE_MIDTIER_API_URL: 'https://api.harmonic.ai/',
    BASE_CONSOLE_URL: 'https://console.harmonic.ai',
    DISABLE_PREFETCH: flagDisablePrefetch,
    INTERCOM_APP_ID: 'zbddx3lp',
    SPLIT_API_KEY: '5n2tm96qk3o5njtb72doscq2atk7q1kifd5g',
    EXTENSION_IDS: [
        'pmnechipnndlblhloienjjdafcloodap',
        'ikkpacjgdcmnnlkponeejjkhkbfmcplk',
        'lpeokfegmaajjedpinemghjbloboglii' // harmonic-linkedin-web-extension
    ]
};
export const config = {
    ...(ENVIRONMENT === FrontendEnvironments.Production
        ? prod
        : ENVIRONMENT === FrontendEnvironments.Canary
            ? canary
            : ENVIRONMENT === FrontendEnvironments.Staging
                ? stage
                : local)
};
