import { jsx as _jsx } from "react/jsx-runtime";
import { formatNumberListCustomFieldValue } from '../../../../utils/utilities';
import { withPendingDot } from './components/WithPendingDot';
export const BaseCustomColumnNumberCellRenderer = (props) => {
    const value = props.value?.value;
    const formattedNumber = formatNumberListCustomFieldValue(value, props.numberFormat, true);
    if (props.showEmptyValue && !value) {
        return (_jsx("p", { className: "typography-paragraph text-content-weak", children: `Add ${props.colDef?.headerName}` }));
    }
    return (_jsx("p", { className: "typography-paragraph text-content-default", children: formattedNumber }));
};
export const CustomColumnNumberCellRenderer = withPendingDot(BaseCustomColumnNumberCellRenderer);
