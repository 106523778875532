import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
// Function to decode HTML entities
const decodeHTMLEntities = (text) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
};
// Function to transform URLs from ([text][url]) or (text)[url] to [text](url) format
const transformUrlFormat = (text) => {
    return text
        .replace(/\(\[([^[\]]*?)\]\[([^[\]]*?)\]\)/g, '([$1]($2))') // Handle ([text][url]) format
        .replace(/\(([^()]*?)\)\[([^[\]]*?)\]/g, '[$1]($2)') // Handle (text)[url] format
        .replace(/\[`([^[\]]*?)`\]\(([^()]*?)\)/g, '([$1]($2))'); // Handle `[text](url)` format
};
export const ChatMessageText = ({ children }) => {
    const parts = [];
    let lastIndex = 0;
    // Decode HTML entities first
    const decodedText = decodeHTMLEntities(children);
    // Transform URL format
    const transformedText = transformUrlFormat(decodedText);
    // Updated regex to include markdown links
    const combinedRegex = /(@\[(.*?)\]\((.*?)\)|\*\*(.*?)\*\*|\[(.*?)\]\((.*?)\))/g;
    let match;
    while ((match = combinedRegex.exec(transformedText)) !== null) {
        // Add text before the match
        if (match.index > lastIndex) {
            parts.push(transformedText.slice(lastIndex, match.index));
        }
        if (match[0].startsWith('@')) {
            // Handle mention
            parts.push(_jsxs("span", { className: "font-semibold text-blue-600", children: ["@", match[2]] }, match.index));
        }
        else if (match[0].startsWith('*')) {
            // Handle bold text
            parts.push(_jsx("span", { className: "font-semibold", children: match[4] }, match.index));
        }
        else if (match[0].startsWith('[')) {
            // Handle markdown link
            parts.push(_jsx("a", { href: match[6], target: "_blank", rel: "noopener noreferrer", className: "text-blue-600 hover:underline", children: match[5] }, match.index));
        }
        lastIndex = match.index + match[0].length;
    }
    // Add remaining text
    if (lastIndex < transformedText.length) {
        parts.push(transformedText.slice(lastIndex));
    }
    return _jsx("div", { className: "whitespace-pre-wrap", children: parts });
};
