import { jsx as _jsx } from "react/jsx-runtime";
import Tag from '../../../../harmonic-components/Tag/Tag';
import TruncatedList from '../../../../harmonic-components/TruncatedList/TruncatedList';
import { DEFAULT_HEIGHT } from './CustomColumnCellRenderers';
import { withPendingDot } from './components/WithPendingDot';
export const BaseCustomColumnMultiSelectCellRenderer = (props) => {
    const value = props.value?.value ?? [];
    const options = props.options;
    const selectedOptions = options
        .filter((option) => option.urn && value.includes(option.urn))
        .sort((a, b) => {
        return value.indexOf(a.urn) - value.indexOf(b.urn);
    });
    if (props.showEmptyValue && !selectedOptions.length) {
        return (_jsx("p", { className: "typography-paragraph text-content-weak", children: `Select ${props.colDef?.headerName}` }));
    }
    return (_jsx("div", { className: "inline-block max-w-full", children: _jsx(TruncatedList, { height: props.height ?? DEFAULT_HEIGHT, children: !!selectedOptions &&
                !!selectedOptions.length &&
                selectedOptions.map((option) => (_jsx(Tag, { label: option.name, color: (option.color ?? 'neutral'), truncate: true }, option.urn))) }) }));
};
export const CustomColumnMultiSelectCellRenderer = withPendingDot(BaseCustomColumnMultiSelectCellRenderer);
