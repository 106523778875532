import { create } from 'zustand';
import { persist } from 'zustand/middleware';
const initialState = {
    globalEnableHotkeys: true,
    exploreCompaniesVisibleColumns: [],
    explorePeopleVisibleColumns: []
};
/**
 * @deprecated Use the mobx store instead
 */
const usePersistedZustandStore = create(persist((set, get) => ({
    editStore: (key, payload) => set(() => ({
        ...get(),
        [key]: payload
    })),
    ...initialState,
    reset: () => set(() => ({ ...get(), ...initialState }))
}), {
    name: `persisted-global-store`,
    version: 2
}));
export default usePersistedZustandStore;
