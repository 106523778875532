import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { EyeClosedIcon } from 'assets/harmonic-icons';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import useFlags from 'hooks/useFlags';
import useUpsertCompanyWatchlistNamedViews from 'hooks/useUpsertCompanyWatchlistNamedViews';
import { CompanyColumnsValueToUrnMap } from 'interfaces/CompanyColumnsConfig';
import React from 'react';
import { SPLITS } from 'utils/constants';
import { convertCompanyListColumnsConfigToUrns } from 'utils/namedViews';
import CompanyWatchlistFieldsDropdownItems from '../FieldsDropdownItems/CompanyWatchlistFieldsDropdownItems';
import { useFieldsDropdown } from '../FieldsDropdownItems/FieldsDropdownItems';
import FilterByFieldDropdown from '../FilterByFieldDropdown';
import { HeaderWithDropdown } from '../HeaderWithDropdown';
import { SortDropdownItems } from '../SortDropdownItems';
export const CompanyWatchlistHeader = (props) => {
    const { enabled: enableSortInHeader } = useFlags(SPLITS.enableSortInHeader);
    const { enabled: enableFilterInHeader } = useFlags(SPLITS.enableFilterInHeader);
    const { enabled: enableInsertAdjacentToField } = useFlags(SPLITS.enableInsertAdjacentToField);
    const columnDef = props.column.getColDef();
    const changeSort = props.context.changeSort;
    const colDef = props.column.getColDef();
    const colId = props.column.getColId();
    const sortField = colDef.searchModelIdentifier;
    const sortable = colDef.sortable && sortField;
    const showDropdown = Boolean(enableSortInHeader && sortable) ||
        Boolean(enableFilterInHeader && columnDef.searchModelIdentifier) ||
        Boolean(enableInsertAdjacentToField);
    const { upsertCurrentSelectedNamedView: upsertCompanyNamedView } = useUpsertCompanyWatchlistNamedViews();
    const { removeColumnFromCurrentNamedView } = useUpsertCompanyWatchlistNamedViews();
    const save = (visibleColumns) => {
        upsertCompanyNamedView({
            variables: {
                visibleColumns
            }
        });
    };
    const onHideFieldClick = () => {
        const columnUrn = CompanyColumnsValueToUrnMap[colId];
        removeColumnFromCurrentNamedView(columnUrn);
    };
    const { onFieldClick, onCreateNewField } = useFieldsDropdown({
        colId,
        save,
        convertColumnsConfigToUrns: convertCompanyListColumnsConfigToUrns
    });
    return (_jsx(HeaderWithDropdown, { params: props, showDropdown: showDropdown, renderFields: (filterTerm, insertFieldMode, onClose) => (_jsx(CompanyWatchlistFieldsDropdownItems, { onFieldClick: (field) => {
                onFieldClick(field, insertFieldMode);
                onClose();
            }, onCreateNewField: () => {
                onCreateNewField(insertFieldMode);
                onClose();
            }, filterTerm: filterTerm })), children: ({ close }) => (_jsxs(_Fragment, { children: [enableSortInHeader && sortable && (_jsx(SortDropdownItems, { changeSort: changeSort, field: sortField })), _jsx(ListItem, { primaryIcon: EyeClosedIcon, variant: ListVariant.default, label: "Hide field", value: "Hide field", onMouseDown: () => onHideFieldClick() }), enableFilterInHeader && (_jsx(FilterByFieldDropdown, { onClose: close, searchField: columnDef.filterModelIdentifier }))] })) }));
};
