import { SPLITS } from 'utils/constants';
import { AffinityIcon, ArrowTopRightCircleIcon, CalendarIcon, CompanyIcon, DollarsIcon, EmailIcon, LocationIcon, NumberIcon, PersonCircleIcon, PhoneIcon, RelevanceIcon, TagIcon, TextIcon, TrendingIcon, URLIcon } from '../assets/harmonic-icons';
import { ColumnFieldType } from './ColumnsConfig';
const CompanyColumnsConfig = {
    ID: {
        urn: 'urn:harmonic:company_field:company_id',
        label: 'ID',
        value: 'company.id',
        fieldType: ColumnFieldType.HARMONIC,
        icon: NumberIcon
    },
    ADDED_TO_HARMONIC_DATE: {
        urn: 'urn:harmonic:company_field:company_created_at',
        label: 'Date added to Harmonic',
        value: 'company.created_at',
        fieldType: ColumnFieldType.HARMONIC,
        icon: CalendarIcon
    },
    ADDED_TO_LIST_DATE: {
        urn: 'urn:harmonic:company_field:company_added_to_list_date',
        label: 'Date added',
        value: 'company.entry_created_at',
        fieldType: ColumnFieldType.HARMONIC,
        icon: CalendarIcon
    },
    ADDED_TO_LIST_BY: {
        urn: 'urn:harmonic:company_field:company_entry_added_by',
        label: 'Added by',
        value: 'company.added_by',
        fieldType: ColumnFieldType.HARMONIC,
        icon: PersonCircleIcon,
        splitFlagToEnable: SPLITS.enableAddedByColumn
    },
    UPDATED_DATE: {
        urn: 'urn:harmonic:company_field:company_updated_at',
        label: 'Updated date',
        value: 'company.updated_at',
        fieldType: ColumnFieldType.HARMONIC,
        icon: CalendarIcon
    },
    NAME: {
        urn: 'urn:harmonic:company_field:company_name',
        label: 'Name',
        value: 'company.name',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TextIcon
    },
    LEGAL_NAME: {
        urn: 'urn:harmonic:company_field:legal_name',
        label: 'Legal name',
        value: 'company.legal_name',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TextIcon
    },
    RELEVANCE_SCORE: {
        urn: 'urn:harmonic:company_field:relevance_score',
        label: 'Relevance score',
        value: 'company.relevance_score',
        fieldType: ColumnFieldType.SMART,
        icon: RelevanceIcon
    },
    COMPANY_HIGHLIGHTS: {
        urn: 'urn:harmonic:company_field:company_highlights',
        label: 'Company highlights',
        value: 'company.highlights',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TagIcon
    },
    DESCRIPTION: {
        urn: 'urn:harmonic:company_field:company_description',
        label: 'Description',
        value: 'company.description',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TextIcon
    },
    EXTERNAL_DESCRIPTION: {
        urn: 'urn:harmonic:company_field:company_external_description',
        label: 'External description',
        value: 'company.external_description',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TextIcon
    },
    TEAM: {
        urn: 'urn:harmonic:company_field:company_team',
        label: 'Team',
        value: 'company.person_relationships',
        fieldType: ColumnFieldType.HARMONIC,
        icon: PersonCircleIcon
    },
    FOUNDERS_AND_CEOS: {
        urn: 'urn:harmonic:company_field:company_founders_and_ceos',
        label: 'Founders & CEOs',
        value: 'company.person_relationships_founders_and_ceos',
        fieldType: ColumnFieldType.HARMONIC,
        icon: PersonCircleIcon
    },
    EXECUTIVES: {
        urn: 'urn:harmonic:company_field:company_executives',
        label: 'Executives',
        value: 'company.person_relationships_executives',
        fieldType: ColumnFieldType.HARMONIC,
        icon: PersonCircleIcon
    },
    LEADERSHIP_PRIOR_COMPANIES: {
        urn: 'urn:harmonic:company_field:company_leadership_last_companies',
        label: "Leadership's prior experiences",
        value: 'company.leadership_prior_companies',
        fieldType: ColumnFieldType.HARMONIC,
        icon: CompanyIcon
    },
    TEAM_CONNECTIONS: {
        urn: 'urn:harmonic:company_field:company_customers_connections',
        label: 'Team network',
        value: 'company.user_connections',
        fieldType: ColumnFieldType.SMART,
        icon: PersonCircleIcon
    },
    LAST_MEETING_DATE: {
        urn: 'urn:harmonic:company_field:company_customers_connections_last_meeting_at',
        label: 'Last meeting date',
        value: 'company.user_connections_overview.correspondence_summary.last_meeting_at',
        fieldType: ColumnFieldType.SMART,
        splitFlagToEnable: SPLITS.showCorrespondencesInSearch,
        icon: CalendarIcon
    },
    LAST_MEETING_WITH: {
        urn: 'urn:harmonic:company_field:company_customers_connections_last_meeting_person_email',
        label: 'Last meeting with',
        value: 'company.user_connections_overview.correspondence_summary.last_meeting_contact_person_email',
        fieldType: ColumnFieldType.SMART,
        splitFlagToEnable: SPLITS.showCorrespondencesInSearch,
        icon: PersonCircleIcon
    },
    LAST_CONTACTED_DATE: {
        urn: 'urn:harmonic:company_field:company_customers_connections_last_email_at',
        label: 'Last email date',
        value: 'company.user_connections_overview.correspondence_summary.last_email_at',
        fieldType: ColumnFieldType.SMART,
        splitFlagToEnable: SPLITS.showCorrespondencesInSearch,
        icon: CalendarIcon
    },
    LAST_CONTACTED_BY: {
        urn: 'urn:harmonic:company_field:company_customers_connections_last_email_person_email',
        label: 'Last email with',
        value: 'company.user_connections_overview.correspondence_summary.last_email_contact_person_email',
        fieldType: ColumnFieldType.SMART,
        splitFlagToEnable: SPLITS.showCorrespondencesInSearch,
        icon: PersonCircleIcon
    },
    AFFINITY_LISTS: {
        urn: 'urn:harmonic:company_field:company_customers_affinity_lists',
        label: 'Affinity lists',
        value: 'company.affinity_lists',
        fieldType: ColumnFieldType.SMART,
        icon: AffinityIcon
    },
    LISTS: {
        urn: 'urn:harmonic:company_field:company_lists',
        label: 'Lists',
        value: 'company.lists',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TagIcon
    },
    TEAM_LINKEDIN_PROFILES: {
        urn: 'urn:harmonic:company_field:company_team_linkedin_profiles',
        label: 'Team LinkedIn profiles',
        value: 'company.person_linkedin_profiles',
        fieldType: ColumnFieldType.HARMONIC,
        icon: PersonCircleIcon
    },
    HEADCOUNT: {
        urn: 'urn:harmonic:company_field:company_headcount',
        label: 'Headcount',
        value: 'company.headcount',
        fieldType: ColumnFieldType.HARMONIC,
        icon: NumberIcon,
        searchModelIdentifier: 'company_headcount'
    },
    EXTERNAL_HEADCOUNT: {
        urn: 'urn:harmonic:company_field:company_external_headcount',
        label: 'External headcount',
        value: 'company.external_headcount',
        fieldType: ColumnFieldType.HARMONIC,
        icon: NumberIcon
    },
    CITY: {
        urn: 'urn:harmonic:company_field:company_city',
        label: 'City',
        value: 'company.city',
        fieldType: ColumnFieldType.HARMONIC,
        icon: LocationIcon
    },
    STATE: {
        urn: 'urn:harmonic:company_field:company_state',
        label: 'State',
        value: 'company.state',
        fieldType: ColumnFieldType.HARMONIC,
        icon: LocationIcon
    },
    COUNTRY: {
        urn: 'urn:harmonic:company_field:company_country',
        label: 'Country',
        value: 'company.country',
        fieldType: ColumnFieldType.HARMONIC,
        icon: LocationIcon
    },
    FOUNDING_DATE: {
        urn: 'urn:harmonic:company_field:company_founding_date',
        label: 'Founding date',
        value: 'company.founding_date',
        fieldType: ColumnFieldType.HARMONIC,
        icon: CalendarIcon
    },
    TAGS: {
        urn: 'urn:harmonic:company_field:company_tag_values_v2',
        label: 'Tags',
        value: 'company.typed_tags',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TagIcon
    },
    COMPANY_TYPE: {
        urn: 'urn:harmonic:company_field:company_type',
        label: 'Company type',
        value: 'company.company_type',
        fieldType: ColumnFieldType.HARMONIC
    },
    WEBSITE: {
        urn: 'urn:harmonic:company_field:company_website_url',
        label: 'Website',
        value: 'company.website_url',
        fieldType: ColumnFieldType.HARMONIC,
        icon: URLIcon
    },
    LINKEDIN: {
        urn: 'urn:harmonic:company_field:company_linkedin_url',
        label: 'LinkedIn',
        value: 'company.linkedin_url',
        fieldType: ColumnFieldType.HARMONIC,
        icon: URLIcon
    },
    STAGE: {
        urn: 'urn:harmonic:company_field:company_stage',
        label: 'Stage',
        value: 'company.stage',
        fieldType: ColumnFieldType.HARMONIC,
        icon: ArrowTopRightCircleIcon
    },
    NUM_FUNDING_ROUNDS: {
        urn: 'urn:harmonic:company_field:company_num_funding_rounds',
        label: '# Funding rounds',
        value: 'company.num_funding_rounds',
        fieldType: ColumnFieldType.HARMONIC,
        icon: NumberIcon
    },
    LAST_FUNDING_DATE: {
        urn: 'urn:harmonic:company_field:company_last_funding_date',
        label: 'Last funding date',
        value: 'company.last_funding_at',
        fieldType: ColumnFieldType.HARMONIC,
        icon: CalendarIcon
    },
    LAST_FUNDING_TYPE: {
        urn: 'urn:harmonic:company_field:company_last_funding_type',
        label: 'Last funding type',
        value: 'company.last_funding_type',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TagIcon
    },
    LAST_FUNDING_TOTAL: {
        urn: 'urn:harmonic:company_field:company_last_funding_total',
        label: 'Last funding round total',
        value: 'company.last_funding_total',
        fieldType: ColumnFieldType.HARMONIC,
        icon: NumberIcon
    },
    FUNDING_TOTAL: {
        urn: 'urn:harmonic:company_field:company_funding_total',
        label: 'Funding total',
        value: 'company.funding_total',
        fieldType: ColumnFieldType.HARMONIC,
        icon: NumberIcon
    },
    FUNDING_PER_EMPLOYEE: {
        urn: 'urn:harmonic:company_field:funding_per_employee',
        label: 'Funding per employee',
        value: 'company.funding_per_employee',
        fieldType: ColumnFieldType.HARMONIC,
        icon: NumberIcon
    },
    INVESTORS: {
        urn: 'urn:harmonic:company_field:company_investor_names',
        label: 'Investors',
        value: 'company.investors',
        fieldType: ColumnFieldType.HARMONIC,
        icon: DollarsIcon
    },
    CUSTOMER_TYPE: {
        urn: 'urn:harmonic:company_field:company_customer_type',
        label: 'Customer type',
        value: 'company.customer_type',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TagIcon
    },
    INDUSTRY_TAGS: {
        urn: 'urn:harmonic:company_field:company_industry_tags',
        label: 'Industry tags',
        value: 'company.industry_tags',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TagIcon
    },
    TECHNOLOGY_TAGS: {
        urn: 'urn:harmonic:company_field:company_technology_tags',
        label: 'Technology tags',
        value: 'company.technology_tags',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TagIcon
    },
    ACCELERATORS: {
        urn: 'urn:harmonic:company_field:company_accelerator_tags',
        label: 'Accelerators',
        value: 'company.accelerators',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TagIcon
    },
    COMPANY_EMAILS: {
        urn: 'urn:harmonic:company_field:company_emails',
        label: 'Company emails',
        value: 'company.company_emails',
        fieldType: ColumnFieldType.HARMONIC,
        icon: EmailIcon
    },
    COMPANY_PRIMARY_EMAIL: {
        urn: 'urn:harmonic:company_field:company_primary_email',
        label: 'Company primary email',
        value: 'company.primary_email',
        fieldType: ColumnFieldType.HARMONIC,
        icon: EmailIcon
    },
    TEAM_EMAILS: {
        urn: 'urn:harmonic:company_field:company_team_emails',
        label: 'Team emails',
        value: 'company.team_emails',
        fieldType: ColumnFieldType.HARMONIC,
        icon: EmailIcon
    },
    PHONE_NUMBER: {
        urn: 'urn:harmonic:company_field:company_contact_numbers',
        label: 'Phone number',
        value: 'company.contact_numbers',
        fieldType: ColumnFieldType.HARMONIC,
        icon: PhoneIcon
    },
    TWITTER: {
        urn: 'urn:harmonic:company_field:company_twitter_url',
        label: 'Twitter',
        value: 'company.twitter_url',
        fieldType: ColumnFieldType.HARMONIC,
        icon: URLIcon
    },
    TWITTER_HANDLE: {
        urn: 'urn:harmonic:company_field:company_twitter_handle',
        label: 'Twitter handle',
        value: 'company.twitter_handle',
        fieldType: ColumnFieldType.HARMONIC,
        icon: URLIcon
    },
    CRUNCHBASE: {
        urn: 'urn:harmonic:company_field:company_crunchbase_url',
        label: 'Crunchbase',
        value: 'company.crunchbase_url',
        fieldType: ColumnFieldType.HARMONIC,
        icon: URLIcon
    },
    PITCHBOOK: {
        urn: 'urn:harmonic:company_field:company_pitchbook_url',
        label: 'Pitchbook',
        value: 'company.pitchbook_url',
        fieldType: ColumnFieldType.HARMONIC,
        icon: URLIcon
    },
    INSTAGRAM: {
        urn: 'urn:harmonic:company_field:company_instagram_url',
        label: 'Instagram',
        value: 'company.instagram_url',
        fieldType: ColumnFieldType.HARMONIC,
        icon: URLIcon
    },
    FACEBOOK: {
        urn: 'urn:harmonic:company_field:company_facebook_url',
        label: 'Facebook',
        value: 'company.facebook_url',
        fieldType: ColumnFieldType.HARMONIC,
        icon: URLIcon
    },
    ANGELLIST: {
        urn: 'urn:harmonic:company_field:company_angellist_url',
        label: 'AngelList',
        value: 'company.angellist_url',
        fieldType: ColumnFieldType.HARMONIC,
        icon: URLIcon
    },
    INDEED: {
        urn: 'urn:harmonic:company_field:company_indeed_url',
        label: 'Indeed',
        value: 'company.indeed_url',
        fieldType: ColumnFieldType.HARMONIC,
        icon: URLIcon
    },
    YOUTUBE: {
        urn: 'urn:harmonic:company_field:company_youtube_url',
        label: 'YouTube',
        value: 'company.youtube_url',
        fieldType: ColumnFieldType.HARMONIC,
        icon: URLIcon
    },
    MONSTER: {
        urn: 'urn:harmonic:company_field:company_monster_url',
        label: 'Monster',
        value: 'company.monster_url',
        fieldType: ColumnFieldType.HARMONIC,
        icon: URLIcon
    },
    STACKOVERFLOW: {
        urn: 'urn:harmonic:company_field:company_stackoverflow_url',
        label: 'StackOverflow',
        value: 'company.stackoverflow_url',
        fieldType: ColumnFieldType.HARMONIC,
        icon: URLIcon
    },
    LINKEDIN_FOLLOWERS: {
        urn: 'urn:harmonic:company_field:company_linkedin_follower_count',
        label: 'LinkedIn followers',
        value: 'company.linkedin_follower_count',
        fieldType: ColumnFieldType.HARMONIC,
        icon: NumberIcon
    },
    TWITTER_FOLLOWERS: {
        urn: 'urn:harmonic:company_field:company_twitter_follower_count',
        label: 'Twitter followers',
        value: 'company.twitter_total_followers',
        fieldType: ColumnFieldType.HARMONIC,
        icon: NumberIcon
    },
    INSTAGRAM_FOLLOWERS: {
        urn: 'urn:harmonic:company_field:company_instagram_follower_count',
        label: 'Instagram followers',
        value: 'company.instagram_num_followers',
        fieldType: ColumnFieldType.HARMONIC,
        icon: NumberIcon
    },
    HEADCOUNT_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_value_14d_ago',
        label: 'Headcount (14d)',
        value: 'company.traction_metrics.headcount.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_real_change_14d_ago',
        label: 'Headcount ∆ (14d)',
        value: 'company.traction_metrics.headcount.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_percent_change_14d_ago',
        label: 'Headcount % (14d)',
        value: 'company.traction_metrics.headcount.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_value_30d_ago',
        label: 'Headcount (30d)',
        value: 'company.traction_metrics.headcount.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_real_change_30d_ago',
        label: 'Headcount ∆ (30d)',
        value: 'company.traction_metrics.headcount.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_percent_change_30d_ago',
        label: 'Headcount % (30d)',
        value: 'company.traction_metrics.headcount.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_value_90d_ago',
        label: 'Headcount (90d)',
        value: 'company.traction_metrics.headcount.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_real_change_90d_ago',
        label: 'Headcount ∆ (90d)',
        value: 'company.traction_metrics.headcount.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_percent_change_90d_ago',
        label: 'Headcount % (90d)',
        value: 'company.traction_metrics.headcount.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_value_180d_ago',
        label: 'Headcount (180d)',
        value: 'company.traction_metrics.headcount.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_real_change_180d_ago',
        label: 'Headcount ∆ (180d)',
        value: 'company.traction_metrics.headcount.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_percent_change_180d_ago',
        label: 'Headcount % (180d)',
        value: 'company.traction_metrics.headcount.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_value_365d_ago',
        label: 'Headcount (365d)',
        value: 'company.traction_metrics.headcount.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_real_change_365d_ago',
        label: 'Headcount ∆ (365d)',
        value: 'company.traction_metrics.headcount.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_percent_change_365d_ago',
        label: 'Headcount % (365d)',
        value: 'company.traction_metrics.headcount.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    WEB_TRAFFIC: {
        urn: 'urn:harmonic:company_field:web_traffic',
        label: 'Web traffic',
        value: 'company.traction_metrics.web_traffic.latest_metric_value',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    WEB_TRAFFIC_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_web_traffic_value_30d_ago',
        label: 'Web traffic (30d)',
        value: 'company.traction_metrics.web_traffic.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    WEB_TRAFFIC_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:web_traffic_real_change_30d_ago',
        label: 'Web traffic ∆ (30d)',
        value: 'company.traction_metrics.web_traffic.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    WEB_TRAFFIC_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:web_traffic_percent_change_30d_ago',
        label: 'Web traffic % (30d)',
        value: 'company.traction_metrics.web_traffic.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    WEB_TRAFFIC_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_web_traffic_value_90d_ago',
        label: 'Web traffic (90d)',
        value: 'company.traction_metrics.web_traffic.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    WEB_TRAFFIC_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:web_traffic_real_change_90d_ago',
        label: 'Web traffic ∆ (90d)',
        value: 'company.traction_metrics.web_traffic.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    WEB_TRAFFIC_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:web_traffic_percent_change_90d_ago',
        label: 'Web traffic % (90d)',
        value: 'company.traction_metrics.web_traffic.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    WEB_TRAFFIC_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_web_traffic_value_180d_ago',
        label: 'Web traffic (180d)',
        value: 'company.traction_metrics.web_traffic.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    WEB_TRAFFIC_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:web_traffic_real_change_180d_ago',
        label: 'Web traffic ∆ (180d)',
        value: 'company.traction_metrics.web_traffic.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    WEB_TRAFFIC_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:web_traffic_percent_change_180d_ago',
        label: 'Web traffic % (180d)',
        value: 'company.traction_metrics.web_traffic.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    WEB_TRAFFIC_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_web_traffic_value_365d_ago',
        label: 'Web traffic (365d)',
        value: 'company.traction_metrics.web_traffic.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    WEB_TRAFFIC_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:web_traffic_real_change_365d_ago',
        label: 'Web traffic ∆ (365d)',
        value: 'company.traction_metrics.web_traffic.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    WEB_TRAFFIC_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:web_traffic_percent_change_365d_ago',
        label: 'Web traffic % (365d)',
        value: 'company.traction_metrics.web_traffic.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ADVISOR: {
        urn: 'urn:harmonic:company_field:company_headcount_advisor_latest_metric_value',
        label: 'Headcount (Advisor)',
        value: 'company.traction_metrics.headcount_advisor.latest_metric_value',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ADVISOR_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_advisor_value_14d_ago',
        label: 'Headcount (Advisor) (14d)',
        value: 'company.traction_metrics.headcount_advisor.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ADVISOR_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_advisor_real_change_14d_ago',
        label: 'Headcount (Advisor) ∆ (14d)',
        value: 'company.traction_metrics.headcount_advisor.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ADVISOR_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_advisor_percent_change_14d_ago',
        label: 'Headcount (Advisor) % (14d)',
        value: 'company.traction_metrics.headcount_advisor.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ADVISOR_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_advisor_value_30d_ago',
        label: 'Headcount (Advisor) (30d)',
        value: 'company.traction_metrics.headcount_advisor.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ADVISOR_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_advisor_real_change_30d_ago',
        label: 'Headcount (Advisor) ∆ (30d)',
        value: 'company.traction_metrics.headcount_advisor.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ADVISOR_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_advisor_percent_change_30d_ago',
        label: 'Headcount (Advisor) % (30d)',
        value: 'company.traction_metrics.headcount_advisor.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ADVISOR_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_advisor_value_90d_ago',
        label: 'Headcount (Advisor) (90d)',
        value: 'company.traction_metrics.headcount_advisor.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ADVISOR_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_advisor_real_change_90d_ago',
        label: 'Headcount (Advisor) ∆ (90d)',
        value: 'company.traction_metrics.headcount_advisor.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ADVISOR_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_advisor_percent_change_90d_ago',
        label: 'Headcount (Advisor) % (90d)',
        value: 'company.traction_metrics.headcount_advisor.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ADVISOR_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_advisor_value_180d_ago',
        label: 'Headcount (Advisor) (180d)',
        value: 'company.traction_metrics.headcount_advisor.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ADVISOR_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_advisor_real_change_180d_ago',
        label: 'Headcount (Advisor) ∆ (180d)',
        value: 'company.traction_metrics.headcount_advisor.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ADVISOR_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_advisor_percent_change_180d_ago',
        label: 'Headcount (Advisor) % (180d)',
        value: 'company.traction_metrics.headcount_advisor.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ADVISOR_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_advisor_value_365d_ago',
        label: 'Headcount (Advisor) (365d)',
        value: 'company.traction_metrics.headcount_advisor.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ADVISOR_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_advisor_real_change_365d_ago',
        label: 'Headcount (Advisor) ∆ (365d)',
        value: 'company.traction_metrics.headcount_advisor.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ADVISOR_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_advisor_percent_change_365d_ago',
        label: 'Headcount (Advisor) % (365d)',
        value: 'company.traction_metrics.headcount_advisor.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_CUSTOMER_SUCCESS: {
        urn: 'urn:harmonic:company_field:company_headcount_customer_success_latest_metric_value',
        label: 'Headcount (Customer success)',
        value: 'company.traction_metrics.headcount_customer_success.latest_metric_value',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_CUSTOMER_SUCCESS_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_customer_success_value_14d_ago',
        label: 'Headcount (Customer success) (14d)',
        value: 'company.traction_metrics.headcount_customer_success.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_CUSTOMER_SUCCESS_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_customer_success_real_change_14d_ago',
        label: 'Headcount (Customer success) ∆ (14d)',
        value: 'company.traction_metrics.headcount_customer_success.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_CUSTOMER_SUCCESS_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_customer_success_percent_change_14d_ago',
        label: 'Headcount (Customer success) % (14d)',
        value: 'company.traction_metrics.headcount_customer_success.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_CUSTOMER_SUCCESS_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_customer_success_value_30d_ago',
        label: 'Headcount (Customer success) (30d)',
        value: 'company.traction_metrics.headcount_customer_success.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_CUSTOMER_SUCCESS_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_customer_success_real_change_30d_ago',
        label: 'Headcount (Customer success) ∆ (30d)',
        value: 'company.traction_metrics.headcount_customer_success.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_CUSTOMER_SUCCESS_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_customer_success_percent_change_30d_ago',
        label: 'Headcount (Customer success) % (30d)',
        value: 'company.traction_metrics.headcount_customer_success.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_CUSTOMER_SUCCESS_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_customer_success_value_90d_ago',
        label: 'Headcount (Customer success) (90d)',
        value: 'company.traction_metrics.headcount_customer_success.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_CUSTOMER_SUCCESS_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_customer_success_real_change_90d_ago',
        label: 'Headcount (Customer success) ∆ (90d)',
        value: 'company.traction_metrics.headcount_customer_success.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_CUSTOMER_SUCCESS_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_customer_success_percent_change_90d_ago',
        label: 'Headcount (Customer success) % (90d)',
        value: 'company.traction_metrics.headcount_customer_success.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_CUSTOMER_SUCCESS_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_customer_success_value_180d_ago',
        label: 'Headcount (Customer success) (180d)',
        value: 'company.traction_metrics.headcount_customer_success.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_CUSTOMER_SUCCESS_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_customer_success_real_change_180d_ago',
        label: 'Headcount (Customer success) ∆ (180d)',
        value: 'company.traction_metrics.headcount_customer_success.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_CUSTOMER_SUCCESS_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_customer_success_percent_change_180d_ago',
        label: 'Headcount (Customer success) % (180d)',
        value: 'company.traction_metrics.headcount_customer_success.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_CUSTOMER_SUCCESS_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_customer_success_value_365d_ago',
        label: 'Headcount (Customer success) (365d)',
        value: 'company.traction_metrics.headcount_customer_success.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_CUSTOMER_SUCCESS_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_customer_success_real_change_365d_ago',
        label: 'Headcount (Customer success) ∆ (365d)',
        value: 'company.traction_metrics.headcount_customer_success.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_CUSTOMER_SUCCESS_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_customer_success_percent_change_365d_ago',
        label: 'Headcount (Customer success) % (365d)',
        value: 'company.traction_metrics.headcount_customer_success.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DATA: {
        urn: 'urn:harmonic:company_field:company_headcount_data_latest_metric_value',
        label: 'Headcount (Data)',
        value: 'company.traction_metrics.headcount_data.latest_metric_value',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DATA_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_data_value_14d_ago',
        label: 'Headcount (Data) (14d)',
        value: 'company.traction_metrics.headcount_data.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DATA_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_data_real_change_14d_ago',
        label: 'Headcount (Data) ∆ (14d)',
        value: 'company.traction_metrics.headcount_data.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DATA_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_data_percent_change_14d_ago',
        label: 'Headcount (Data) % (14d)',
        value: 'company.traction_metrics.headcount_data.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DATA_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_data_value_30d_ago',
        label: 'Headcount (Data) (30d)',
        value: 'company.traction_metrics.headcount_data.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DATA_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_data_real_change_30d_ago',
        label: 'Headcount (Data) ∆ (30d)',
        value: 'company.traction_metrics.headcount_data.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DATA_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_data_percent_change_30d_ago',
        label: 'Headcount (Data) % (30d)',
        value: 'company.traction_metrics.headcount_data.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DATA_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_data_value_90d_ago',
        label: 'Headcount (Data) (90d)',
        value: 'company.traction_metrics.headcount_data.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DATA_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_data_real_change_90d_ago',
        label: 'Headcount (Data) ∆ (90d)',
        value: 'company.traction_metrics.headcount_data.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DATA_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_data_percent_change_90d_ago',
        label: 'Headcount (Data) % (90d)',
        value: 'company.traction_metrics.headcount_data.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DATA_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_data_value_180d_ago',
        label: 'Headcount (Data) (180d)',
        value: 'company.traction_metrics.headcount_data.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DATA_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_data_real_change_180d_ago',
        label: 'Headcount (Data) ∆ (180d)',
        value: 'company.traction_metrics.headcount_data.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DATA_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_data_percent_change_180d_ago',
        label: 'Headcount (Data) % (180d)',
        value: 'company.traction_metrics.headcount_data.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DATA_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_data_value_365d_ago',
        label: 'Headcount (Data) (365d)',
        value: 'company.traction_metrics.headcount_data.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DATA_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_data_real_change_365d_ago',
        label: 'Headcount (Data) ∆ (365d)',
        value: 'company.traction_metrics.headcount_data.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DATA_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_data_percent_change_365d_ago',
        label: 'Headcount (Data) % (365d)',
        value: 'company.traction_metrics.headcount_data.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DESIGN: {
        urn: 'urn:harmonic:company_field:company_headcount_design_latest_metric_value',
        label: 'Headcount (Design)',
        value: 'company.traction_metrics.headcount_design.latest_metric_value',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DESIGN_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_design_value_14d_ago',
        label: 'Headcount (Design) (14d)',
        value: 'company.traction_metrics.headcount_design.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DESIGN_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_design_real_change_14d_ago',
        label: 'Headcount (Design) ∆ (14d)',
        value: 'company.traction_metrics.headcount_design.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DESIGN_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_design_percent_change_14d_ago',
        label: 'Headcount (Design) % (14d)',
        value: 'company.traction_metrics.headcount_design.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DESIGN_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_design_value_30d_ago',
        label: 'Headcount (Design) (30d)',
        value: 'company.traction_metrics.headcount_design.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DESIGN_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_design_real_change_30d_ago',
        label: 'Headcount (Design) ∆ (30d)',
        value: 'company.traction_metrics.headcount_design.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DESIGN_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_design_percent_change_30d_ago',
        label: 'Headcount (Design) % (30d)',
        value: 'company.traction_metrics.headcount_design.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DESIGN_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_design_value_90d_ago',
        label: 'Headcount (Design) (90d)',
        value: 'company.traction_metrics.headcount_design.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DESIGN_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_design_real_change_90d_ago',
        label: 'Headcount (Design) ∆ (90d)',
        value: 'company.traction_metrics.headcount_design.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DESIGN_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_design_percent_change_90d_ago',
        label: 'Headcount (Design) % (90d)',
        value: 'company.traction_metrics.headcount_design.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DESIGN_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_design_value_180d_ago',
        label: 'Headcount (Design) (180d)',
        value: 'company.traction_metrics.headcount_design.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DESIGN_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_design_real_change_180d_ago',
        label: 'Headcount (Design) ∆ (180d)',
        value: 'company.traction_metrics.headcount_design.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DESIGN_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_design_percent_change_180d_ago',
        label: 'Headcount (Design) % (180d)',
        value: 'company.traction_metrics.headcount_design.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DESIGN_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_design_value_365d_ago',
        label: 'Headcount (Design) (365d)',
        value: 'company.traction_metrics.headcount_design.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DESIGN_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_design_real_change_365d_ago',
        label: 'Headcount (Design) ∆ (365d)',
        value: 'company.traction_metrics.headcount_design.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_DESIGN_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_design_percent_change_365d_ago',
        label: 'Headcount (Design) % (365d)',
        value: 'company.traction_metrics.headcount_design.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ENGINEERING: {
        urn: 'urn:harmonic:company_field:company_headcount_engineering_latest_metric_value',
        label: 'Headcount (Engineering)',
        value: 'company.traction_metrics.headcount_engineering.latest_metric_value',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ENGINEERING_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_engineering_value_14d_ago',
        label: 'Headcount (Engineering) (14d)',
        value: 'company.traction_metrics.headcount_engineering.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ENGINEERING_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_engineering_real_change_14d_ago',
        label: 'Headcount (Engineering) ∆ (14d)',
        value: 'company.traction_metrics.headcount_engineering.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ENGINEERING_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_engineering_percent_change_14d_ago',
        label: 'Headcount (Engineering) % (14d)',
        value: 'company.traction_metrics.headcount_engineering.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ENGINEERING_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_engineering_value_30d_ago',
        label: 'Headcount (Engineering) (30d)',
        value: 'company.traction_metrics.headcount_engineering.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ENGINEERING_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_engineering_real_change_30d_ago',
        label: 'Headcount (Engineering) ∆ (30d)',
        value: 'company.traction_metrics.headcount_engineering.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ENGINEERING_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_engineering_percent_change_30d_ago',
        label: 'Headcount (Engineering) % (30d)',
        value: 'company.traction_metrics.headcount_engineering.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ENGINEERING_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_engineering_value_90d_ago',
        label: 'Headcount (Engineering) (90d)',
        value: 'company.traction_metrics.headcount_engineering.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ENGINEERING_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_engineering_real_change_90d_ago',
        label: 'Headcount (Engineering) ∆ (90d)',
        value: 'company.traction_metrics.headcount_engineering.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ENGINEERING_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_engineering_percent_change_90d_ago',
        label: 'Headcount (Engineering) % (90d)',
        value: 'company.traction_metrics.headcount_engineering.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ENGINEERING_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_engineering_value_180d_ago',
        label: 'Headcount (Engineering) (180d)',
        value: 'company.traction_metrics.headcount_engineering.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ENGINEERING_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_engineering_real_change_180d_ago',
        label: 'Headcount (Engineering) ∆ (180d)',
        value: 'company.traction_metrics.headcount_engineering.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ENGINEERING_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_engineering_percent_change_180d_ago',
        label: 'Headcount (Engineering) % (180d)',
        value: 'company.traction_metrics.headcount_engineering.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ENGINEERING_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_engineering_value_365d_ago',
        label: 'Headcount (Engineering) (365d)',
        value: 'company.traction_metrics.headcount_engineering.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ENGINEERING_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_engineering_real_change_365d_ago',
        label: 'Headcount (Engineering) ∆ (365d)',
        value: 'company.traction_metrics.headcount_engineering.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_ENGINEERING_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_engineering_percent_change_365d_ago',
        label: 'Headcount (Engineering) % (365d)',
        value: 'company.traction_metrics.headcount_engineering.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_FINANCE: {
        urn: 'urn:harmonic:company_field:company_headcount_finance_latest_metric_value',
        label: 'Headcount (Finance)',
        value: 'company.traction_metrics.headcount_finance.latest_metric_value',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_FINANCE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_finance_value_14d_ago',
        label: 'Headcount (Finance) (14d)',
        value: 'company.traction_metrics.headcount_finance.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_FINANCE_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_finance_real_change_14d_ago',
        label: 'Headcount (Finance) ∆ (14d)',
        value: 'company.traction_metrics.headcount_finance.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_FINANCE_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_finance_percent_change_14d_ago',
        label: 'Headcount (Finance) % (14d)',
        value: 'company.traction_metrics.headcount_finance.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_FINANCE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_finance_value_30d_ago',
        label: 'Headcount (Finance) (30d)',
        value: 'company.traction_metrics.headcount_finance.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_FINANCE_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_finance_real_change_30d_ago',
        label: 'Headcount (Finance) ∆ (30d)',
        value: 'company.traction_metrics.headcount_finance.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_FINANCE_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_finance_percent_change_30d_ago',
        label: 'Headcount (Finance) % (30d)',
        value: 'company.traction_metrics.headcount_finance.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_FINANCE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_finance_value_90d_ago',
        label: 'Headcount (Finance) (90d)',
        value: 'company.traction_metrics.headcount_finance.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_FINANCE_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_finance_real_change_90d_ago',
        label: 'Headcount (Finance) ∆ (90d)',
        value: 'company.traction_metrics.headcount_finance.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_FINANCE_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_finance_percent_change_90d_ago',
        label: 'Headcount (Finance) % (90d)',
        value: 'company.traction_metrics.headcount_finance.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_FINANCE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_finance_value_180d_ago',
        label: 'Headcount (Finance) (180d)',
        value: 'company.traction_metrics.headcount_finance.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_FINANCE_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_finance_real_change_180d_ago',
        label: 'Headcount (Finance) ∆ (180d)',
        value: 'company.traction_metrics.headcount_finance.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_FINANCE_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_finance_percent_change_180d_ago',
        label: 'Headcount (Finance) % (180d)',
        value: 'company.traction_metrics.headcount_finance.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_FINANCE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_finance_value_365d_ago',
        label: 'Headcount (Finance) (365d)',
        value: 'company.traction_metrics.headcount_finance.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_FINANCE_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_finance_real_change_365d_ago',
        label: 'Headcount (Finance) ∆ (365d)',
        value: 'company.traction_metrics.headcount_finance.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_FINANCE_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_finance_percent_change_365d_ago',
        label: 'Headcount (Finance) % (365d)',
        value: 'company.traction_metrics.headcount_finance.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_LEGAL: {
        urn: 'urn:harmonic:company_field:company_headcount_legal_latest_metric_value',
        label: 'Headcount (Legal)',
        value: 'company.traction_metrics.headcount_legal.latest_metric_value',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_LEGAL_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_legal_value_14d_ago',
        label: 'Headcount (Legal) (14d)',
        value: 'company.traction_metrics.headcount_legal.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_LEGAL_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_legal_real_change_14d_ago',
        label: 'Headcount (Legal) ∆ (14d)',
        value: 'company.traction_metrics.headcount_legal.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_LEGAL_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_legal_percent_change_14d_ago',
        label: 'Headcount (Legal) % (14d)',
        value: 'company.traction_metrics.headcount_legal.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_LEGAL_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_legal_value_30d_ago',
        label: 'Headcount (Legal) (30d)',
        value: 'company.traction_metrics.headcount_legal.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_LEGAL_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_legal_real_change_30d_ago',
        label: 'Headcount (Legal) ∆ (30d)',
        value: 'company.traction_metrics.headcount_legal.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_LEGAL_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_legal_percent_change_30d_ago',
        label: 'Headcount (Legal) % (30d)',
        value: 'company.traction_metrics.headcount_legal.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_LEGAL_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_legal_value_90d_ago',
        label: 'Headcount (Legal) (90d)',
        value: 'company.traction_metrics.headcount_legal.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_LEGAL_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_legal_real_change_90d_ago',
        label: 'Headcount (Legal) ∆ (90d)',
        value: 'company.traction_metrics.headcount_legal.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_LEGAL_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_legal_percent_change_90d_ago',
        label: 'Headcount (Legal) % (90d)',
        value: 'company.traction_metrics.headcount_legal.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_LEGAL_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_legal_value_180d_ago',
        label: 'Headcount (Legal) (180d)',
        value: 'company.traction_metrics.headcount_legal.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_LEGAL_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_legal_real_change_180d_ago',
        label: 'Headcount (Legal) ∆ (180d)',
        value: 'company.traction_metrics.headcount_legal.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_LEGAL_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_legal_percent_change_180d_ago',
        label: 'Headcount (Legal) % (180d)',
        value: 'company.traction_metrics.headcount_legal.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_LEGAL_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_legal_value_365d_ago',
        label: 'Headcount (Legal) (365d)',
        value: 'company.traction_metrics.headcount_legal.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_LEGAL_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_legal_real_change_365d_ago',
        label: 'Headcount (Legal) ∆ (365d)',
        value: 'company.traction_metrics.headcount_legal.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_LEGAL_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_legal_percent_change_365d_ago',
        label: 'Headcount (Legal) % (365d)',
        value: 'company.traction_metrics.headcount_legal.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_MARKETING: {
        urn: 'urn:harmonic:company_field:company_headcount_marketing_latest_metric_value',
        label: 'Headcount (Marketing)',
        value: 'company.traction_metrics.headcount_marketing.latest_metric_value',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_MARKETING_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_marketing_value_14d_ago',
        label: 'Headcount (Marketing) (14d)',
        value: 'company.traction_metrics.headcount_marketing.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_MARKETING_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_marketing_real_change_14d_ago',
        label: 'Headcount (Marketing) ∆ (14d)',
        value: 'company.traction_metrics.headcount_marketing.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_MARKETING_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_marketing_percent_change_14d_ago',
        label: 'Headcount (Marketing) % (14d)',
        value: 'company.traction_metrics.headcount_marketing.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_MARKETING_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_marketing_value_30d_ago',
        label: 'Headcount (Marketing) (30d)',
        value: 'company.traction_metrics.headcount_marketing.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_MARKETING_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_marketing_real_change_30d_ago',
        label: 'Headcount (Marketing) ∆ (30d)',
        value: 'company.traction_metrics.headcount_marketing.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_MARKETING_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_marketing_percent_change_30d_ago',
        label: 'Headcount (Marketing) % (30d)',
        value: 'company.traction_metrics.headcount_marketing.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_MARKETING_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_marketing_value_90d_ago',
        label: 'Headcount (Marketing) (90d)',
        value: 'company.traction_metrics.headcount_marketing.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_MARKETING_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_marketing_real_change_90d_ago',
        label: 'Headcount (Marketing) ∆ (90d)',
        value: 'company.traction_metrics.headcount_marketing.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_MARKETING_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_marketing_percent_change_90d_ago',
        label: 'Headcount (Marketing) % (90d)',
        value: 'company.traction_metrics.headcount_marketing.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_MARKETING_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_marketing_value_180d_ago',
        label: 'Headcount (Marketing) (180d)',
        value: 'company.traction_metrics.headcount_marketing.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_MARKETING_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_marketing_real_change_180d_ago',
        label: 'Headcount (Marketing) ∆ (180d)',
        value: 'company.traction_metrics.headcount_marketing.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_MARKETING_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_marketing_percent_change_180d_ago',
        label: 'Headcount (Marketing) % (180d)',
        value: 'company.traction_metrics.headcount_marketing.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_MARKETING_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_marketing_value_365d_ago',
        label: 'Headcount (Marketing) (365d)',
        value: 'company.traction_metrics.headcount_marketing.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_MARKETING_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_marketing_real_change_365d_ago',
        label: 'Headcount (Marketing) ∆ (365d)',
        value: 'company.traction_metrics.headcount_marketing.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_MARKETING_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_marketing_percent_change_365d_ago',
        label: 'Headcount (Marketing) % (365d)',
        value: 'company.traction_metrics.headcount_marketing.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OPERATIONS: {
        urn: 'urn:harmonic:company_field:company_headcount_operations_latest_metric_value',
        label: 'Headcount (Operations)',
        value: 'company.traction_metrics.headcount_operations.latest_metric_value',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OPERATIONS_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_operations_value_14d_ago',
        label: 'Headcount (Operations) (14d)',
        value: 'company.traction_metrics.headcount_operations.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OPERATIONS_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_operations_real_change_14d_ago',
        label: 'Headcount (Operations) ∆ (14d)',
        value: 'company.traction_metrics.headcount_operations.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OPERATIONS_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_operations_percent_change_14d_ago',
        label: 'Headcount (Operations) % (14d)',
        value: 'company.traction_metrics.headcount_operations.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OPERATIONS_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_operations_value_30d_ago',
        label: 'Headcount (Operations) (30d)',
        value: 'company.traction_metrics.headcount_operations.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OPERATIONS_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_operations_real_change_30d_ago',
        label: 'Headcount (Operations) ∆ (30d)',
        value: 'company.traction_metrics.headcount_operations.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OPERATIONS_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_operations_percent_change_30d_ago',
        label: 'Headcount (Operations) % (30d)',
        value: 'company.traction_metrics.headcount_operations.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OPERATIONS_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_operations_value_90d_ago',
        label: 'Headcount (Operations) (90d)',
        value: 'company.traction_metrics.headcount_operations.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OPERATIONS_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_operations_real_change_90d_ago',
        label: 'Headcount (Operations) ∆ (90d)',
        value: 'company.traction_metrics.headcount_operations.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OPERATIONS_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_operations_percent_change_90d_ago',
        label: 'Headcount (Operations) % (90d)',
        value: 'company.traction_metrics.headcount_operations.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OPERATIONS_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_operations_value_180d_ago',
        label: 'Headcount (Operations) (180d)',
        value: 'company.traction_metrics.headcount_operations.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OPERATIONS_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_operations_real_change_180d_ago',
        label: 'Headcount (Operations) ∆ (180d)',
        value: 'company.traction_metrics.headcount_operations.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OPERATIONS_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_operations_percent_change_180d_ago',
        label: 'Headcount (Operations) % (180d)',
        value: 'company.traction_metrics.headcount_operations.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OPERATIONS_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_operations_value_365d_ago',
        label: 'Headcount (Operations) (365d)',
        value: 'company.traction_metrics.headcount_operations.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OPERATIONS_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_operations_real_change_365d_ago',
        label: 'Headcount (Operations) ∆ (365d)',
        value: 'company.traction_metrics.headcount_operations.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OPERATIONS_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_operations_percent_change_365d_ago',
        label: 'Headcount (Operations) % (365d)',
        value: 'company.traction_metrics.headcount_operations.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OTHER: {
        urn: 'urn:harmonic:company_field:company_headcount_other_latest_metric_value',
        label: 'Headcount (Other)',
        value: 'company.traction_metrics.headcount_other.latest_metric_value',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OTHER_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_other_value_14d_ago',
        label: 'Headcount (Other) (14d)',
        value: 'company.traction_metrics.headcount_other.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OTHER_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_other_real_change_14d_ago',
        label: 'Headcount (Other) ∆ (14d)',
        value: 'company.traction_metrics.headcount_other.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OTHER_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_other_percent_change_14d_ago',
        label: 'Headcount (Other) % (14d)',
        value: 'company.traction_metrics.headcount_other.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OTHER_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_other_value_30d_ago',
        label: 'Headcount (Other) (30d)',
        value: 'company.traction_metrics.headcount_other.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OTHER_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_other_real_change_30d_ago',
        label: 'Headcount (Other) ∆ (30d)',
        value: 'company.traction_metrics.headcount_other.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OTHER_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_other_percent_change_30d_ago',
        label: 'Headcount (Other) % (30d)',
        value: 'company.traction_metrics.headcount_other.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OTHER_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_other_value_90d_ago',
        label: 'Headcount (Other) (90d)',
        value: 'company.traction_metrics.headcount_other.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OTHER_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_other_real_change_90d_ago',
        label: 'Headcount (Other) ∆ (90d)',
        value: 'company.traction_metrics.headcount_other.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OTHER_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_other_percent_change_90d_ago',
        label: 'Headcount (Other) % (90d)',
        value: 'company.traction_metrics.headcount_other.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OTHER_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_other_value_180d_ago',
        label: 'Headcount (Other) (180d)',
        value: 'company.traction_metrics.headcount_other.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OTHER_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_other_real_change_180d_ago',
        label: 'Headcount (Other) ∆ (180d)',
        value: 'company.traction_metrics.headcount_other.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OTHER_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_other_percent_change_180d_ago',
        label: 'Headcount (Other) % (180d)',
        value: 'company.traction_metrics.headcount_other.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OTHER_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_other_value_365d_ago',
        label: 'Headcount (Other) (365d)',
        value: 'company.traction_metrics.headcount_other.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OTHER_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_other_real_change_365d_ago',
        label: 'Headcount (Other) ∆ (365d)',
        value: 'company.traction_metrics.headcount_other.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_OTHER_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_other_percent_change_365d_ago',
        label: 'Headcount (Other) % (365d)',
        value: 'company.traction_metrics.headcount_other.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PEOPLE: {
        urn: 'urn:harmonic:company_field:company_headcount_people_latest_metric_value',
        label: 'Headcount (People)',
        value: 'company.traction_metrics.headcount_people.latest_metric_value',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PEOPLE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_people_value_14d_ago',
        label: 'Headcount (People) (14d)',
        value: 'company.traction_metrics.headcount_people.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PEOPLE_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_people_real_change_14d_ago',
        label: 'Headcount (People) ∆ (14d)',
        value: 'company.traction_metrics.headcount_people.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PEOPLE_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_people_percent_change_14d_ago',
        label: 'Headcount (People) % (14d)',
        value: 'company.traction_metrics.headcount_people.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PEOPLE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_people_value_30d_ago',
        label: 'Headcount (People) (30d)',
        value: 'company.traction_metrics.headcount_people.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PEOPLE_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_people_real_change_30d_ago',
        label: 'Headcount (People) ∆ (30d)',
        value: 'company.traction_metrics.headcount_people.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PEOPLE_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_people_percent_change_30d_ago',
        label: 'Headcount (People) % (30d)',
        value: 'company.traction_metrics.headcount_people.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PEOPLE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_people_value_90d_ago',
        label: 'Headcount (People) (90d)',
        value: 'company.traction_metrics.headcount_people.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PEOPLE_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_people_real_change_90d_ago',
        label: 'Headcount (People) ∆ (90d)',
        value: 'company.traction_metrics.headcount_people.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PEOPLE_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_people_percent_change_90d_ago',
        label: 'Headcount (People) % (90d)',
        value: 'company.traction_metrics.headcount_people.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PEOPLE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_people_value_180d_ago',
        label: 'Headcount (People) (180d)',
        value: 'company.traction_metrics.headcount_people.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PEOPLE_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_people_real_change_180d_ago',
        label: 'Headcount (People) ∆ (180d)',
        value: 'company.traction_metrics.headcount_people.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PEOPLE_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_people_percent_change_180d_ago',
        label: 'Headcount (People) % (180d)',
        value: 'company.traction_metrics.headcount_people.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PEOPLE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_people_value_365d_ago',
        label: 'Headcount (People) (365d)',
        value: 'company.traction_metrics.headcount_people.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PEOPLE_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_people_real_change_365d_ago',
        label: 'Headcount (People) ∆ (365d)',
        value: 'company.traction_metrics.headcount_people.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PEOPLE_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_people_percent_change_365d_ago',
        label: 'Headcount (People) % (365d)',
        value: 'company.traction_metrics.headcount_people.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PRODUCT: {
        urn: 'urn:harmonic:company_field:company_headcount_product_latest_metric_value',
        label: 'Headcount (Product)',
        value: 'company.traction_metrics.headcount_product.latest_metric_value',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PRODUCT_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_product_value_14d_ago',
        label: 'Headcount (Product) (14d)',
        value: 'company.traction_metrics.headcount_product.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PRODUCT_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_product_real_change_14d_ago',
        label: 'Headcount (Product) ∆ (14d)',
        value: 'company.traction_metrics.headcount_product.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PRODUCT_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_product_percent_change_14d_ago',
        label: 'Headcount (Product) % (14d)',
        value: 'company.traction_metrics.headcount_product.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PRODUCT_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_product_value_30d_ago',
        label: 'Headcount (Product) (30d)',
        value: 'company.traction_metrics.headcount_product.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PRODUCT_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_product_real_change_30d_ago',
        label: 'Headcount (Product) ∆ (30d)',
        value: 'company.traction_metrics.headcount_product.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PRODUCT_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_product_percent_change_30d_ago',
        label: 'Headcount (Product) % (30d)',
        value: 'company.traction_metrics.headcount_product.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PRODUCT_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_product_value_90d_ago',
        label: 'Headcount (Product) (90d)',
        value: 'company.traction_metrics.headcount_product.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PRODUCT_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_product_real_change_90d_ago',
        label: 'Headcount (Product) ∆ (90d)',
        value: 'company.traction_metrics.headcount_product.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PRODUCT_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_product_percent_change_90d_ago',
        label: 'Headcount (Product) % (90d)',
        value: 'company.traction_metrics.headcount_product.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PRODUCT_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_product_value_180d_ago',
        label: 'Headcount (Product) (180d)',
        value: 'company.traction_metrics.headcount_product.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PRODUCT_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_product_real_change_180d_ago',
        label: 'Headcount (Product) ∆ (180d)',
        value: 'company.traction_metrics.headcount_product.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PRODUCT_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_product_percent_change_180d_ago',
        label: 'Headcount (Product) % (180d)',
        value: 'company.traction_metrics.headcount_product.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PRODUCT_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_product_value_365d_ago',
        label: 'Headcount (Product) (365d)',
        value: 'company.traction_metrics.headcount_product.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PRODUCT_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_product_real_change_365d_ago',
        label: 'Headcount (Product) ∆ (365d)',
        value: 'company.traction_metrics.headcount_product.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_PRODUCT_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_product_percent_change_365d_ago',
        label: 'Headcount (Product) % (365d)',
        value: 'company.traction_metrics.headcount_product.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SALES: {
        urn: 'urn:harmonic:company_field:company_headcount_sales_latest_metric_value',
        label: 'Headcount (Sales)',
        value: 'company.traction_metrics.headcount_sales.latest_metric_value',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SALES_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_sales_value_14d_ago',
        label: 'Headcount (Sales) (14d)',
        value: 'company.traction_metrics.headcount_sales.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SALES_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_sales_real_change_14d_ago',
        label: 'Headcount (Sales) ∆ (14d)',
        value: 'company.traction_metrics.headcount_sales.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SALES_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_sales_percent_change_14d_ago',
        label: 'Headcount (Sales) % (14d)',
        value: 'company.traction_metrics.headcount_sales.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SALES_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_sales_value_30d_ago',
        label: 'Headcount (Sales) (30d)',
        value: 'company.traction_metrics.headcount_sales.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SALES_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_sales_real_change_30d_ago',
        label: 'Headcount (Sales) ∆ (30d)',
        value: 'company.traction_metrics.headcount_sales.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SALES_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_sales_percent_change_30d_ago',
        label: 'Headcount (Sales) % (30d)',
        value: 'company.traction_metrics.headcount_sales.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SALES_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_sales_value_90d_ago',
        label: 'Headcount (Sales) (90d)',
        value: 'company.traction_metrics.headcount_sales.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SALES_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_sales_real_change_90d_ago',
        label: 'Headcount (Sales) ∆ (90d)',
        value: 'company.traction_metrics.headcount_sales.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SALES_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_sales_percent_change_90d_ago',
        label: 'Headcount (Sales) % (90d)',
        value: 'company.traction_metrics.headcount_sales.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SALES_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_sales_value_180d_ago',
        label: 'Headcount (Sales) (180d)',
        value: 'company.traction_metrics.headcount_sales.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SALES_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_sales_real_change_180d_ago',
        label: 'Headcount (Sales) ∆ (180d)',
        value: 'company.traction_metrics.headcount_sales.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SALES_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_sales_percent_change_180d_ago',
        label: 'Headcount (Sales) % (180d)',
        value: 'company.traction_metrics.headcount_sales.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SALES_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_sales_value_365d_ago',
        label: 'Headcount (Sales) (365d)',
        value: 'company.traction_metrics.headcount_sales.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SALES_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_sales_real_change_365d_ago',
        label: 'Headcount (Sales) ∆ (365d)',
        value: 'company.traction_metrics.headcount_sales.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SALES_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_sales_percent_change_365d_ago',
        label: 'Headcount (Sales) % (365d)',
        value: 'company.traction_metrics.headcount_sales.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SUPPORT: {
        urn: 'urn:harmonic:company_field:company_headcount_support_latest_metric_value',
        label: 'Headcount (Support)',
        value: 'company.traction_metrics.headcount_support.latest_metric_value',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SUPPORT_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_support_value_14d_ago',
        label: 'Headcount (Support) (14d)',
        value: 'company.traction_metrics.headcount_support.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SUPPORT_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_support_real_change_14d_ago',
        label: 'Headcount (Support) ∆ (14d)',
        value: 'company.traction_metrics.headcount_support.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SUPPORT_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_support_percent_change_14d_ago',
        label: 'Headcount (Support) % (14d)',
        value: 'company.traction_metrics.headcount_support.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SUPPORT_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_support_value_30d_ago',
        label: 'Headcount (Support) (30d)',
        value: 'company.traction_metrics.headcount_support.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SUPPORT_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_support_real_change_30d_ago',
        label: 'Headcount (Support) ∆ (30d)',
        value: 'company.traction_metrics.headcount_support.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SUPPORT_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_support_percent_change_30d_ago',
        label: 'Headcount (Support) % (30d)',
        value: 'company.traction_metrics.headcount_support.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SUPPORT_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_support_value_90d_ago',
        label: 'Headcount (Support) (90d)',
        value: 'company.traction_metrics.headcount_support.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SUPPORT_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_support_real_change_90d_ago',
        label: 'Headcount (Support) ∆ (90d)',
        value: 'company.traction_metrics.headcount_support.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SUPPORT_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_support_percent_change_90d_ago',
        label: 'Headcount (Support) % (90d)',
        value: 'company.traction_metrics.headcount_support.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SUPPORT_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_support_value_180d_ago',
        label: 'Headcount (Support) (180d)',
        value: 'company.traction_metrics.headcount_support.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SUPPORT_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_support_real_change_180d_ago',
        label: 'Headcount (Support) ∆ (180d)',
        value: 'company.traction_metrics.headcount_support.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SUPPORT_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_support_percent_change_180d_ago',
        label: 'Headcount (Support) % (180d)',
        value: 'company.traction_metrics.headcount_support.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SUPPORT_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_support_value_365d_ago',
        label: 'Headcount (Support) (365d)',
        value: 'company.traction_metrics.headcount_support.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SUPPORT_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_support_real_change_365d_ago',
        label: 'Headcount (Support) ∆ (365d)',
        value: 'company.traction_metrics.headcount_support.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    HEADCOUNT_SUPPORT_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_headcount_support_percent_change_365d_ago',
        label: 'Headcount (Support) % (365d)',
        value: 'company.traction_metrics.headcount_support.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FUNDING_TOTAL_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_funding_total_value_14d_ago',
        label: 'Funding total (14d)',
        value: 'company.traction_metrics.funding_total.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FUNDING_TOTAL_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_funding_total_real_change_14d_ago',
        label: 'Funding total ∆ (14d)',
        value: 'company.traction_metrics.funding_total.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FUNDING_TOTAL_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_funding_total_percent_change_14d_ago',
        label: 'Funding total % (14d)',
        value: 'company.traction_metrics.funding_total.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FUNDING_TOTAL_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_funding_total_value_30d_ago',
        label: 'Funding total (30d)',
        value: 'company.traction_metrics.funding_total.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FUNDING_TOTAL_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_funding_total_real_change_30d_ago',
        label: 'Funding total ∆ (30d)',
        value: 'company.traction_metrics.funding_total.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FUNDING_TOTAL_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_funding_total_percent_change_30d_ago',
        label: 'Funding total % (30d)',
        value: 'company.traction_metrics.funding_total.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FUNDING_TOTAL_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_funding_total_value_90d_ago',
        label: 'Funding total (90d)',
        value: 'company.traction_metrics.funding_total.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FUNDING_TOTAL_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_funding_total_real_change_90d_ago',
        label: 'Funding total ∆ (90d)',
        value: 'company.traction_metrics.funding_total.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FUNDING_TOTAL_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_funding_total_percent_change_90d_ago',
        label: 'Funding total % (90d)',
        value: 'company.traction_metrics.funding_total.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FUNDING_TOTAL_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_funding_total_value_180d_ago',
        label: 'Funding total (180d)',
        value: 'company.traction_metrics.funding_total.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FUNDING_TOTAL_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_funding_total_real_change_180d_ago',
        label: 'Funding total ∆ (180d)',
        value: 'company.traction_metrics.funding_total.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FUNDING_TOTAL_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_funding_total_percent_change_180d_ago',
        label: 'Funding total % (180d)',
        value: 'company.traction_metrics.funding_total.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FUNDING_TOTAL_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_funding_total_value_365d_ago',
        label: 'Funding total (365d)',
        value: 'company.traction_metrics.funding_total.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FUNDING_TOTAL_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_funding_total_real_change_365d_ago',
        label: 'Funding total ∆ (365d)',
        value: 'company.traction_metrics.funding_total.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FUNDING_TOTAL_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_funding_total_percent_change_365d_ago',
        label: 'Funding total % (365d)',
        value: 'company.traction_metrics.funding_total.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    TWITTER_FOLLOWERS_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_twitter_follower_count_value_14d_ago',
        label: 'Twitter followers (14d)',
        value: 'company.traction_metrics.twitter_follower_count.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    TWITTER_FOLLOWERS_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_twitter_follower_count_real_change_14d_ago',
        label: 'Twitter followers ∆ (14d)',
        value: 'company.traction_metrics.twitter_follower_count.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    TWITTER_FOLLOWERS_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_twitter_follower_count_percent_change_14d_ago',
        label: 'Twitter followers % (14d)',
        value: 'company.traction_metrics.twitter_follower_count.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    TWITTER_FOLLOWERS_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_twitter_follower_count_value_30d_ago',
        label: 'Twitter followers (30d)',
        value: 'company.traction_metrics.twitter_follower_count.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    TWITTER_FOLLOWERS_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_twitter_follower_count_real_change_30d_ago',
        label: 'Twitter followers ∆ (30d)',
        value: 'company.traction_metrics.twitter_follower_count.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    TWITTER_FOLLOWERS_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_twitter_follower_count_percent_change_30d_ago',
        label: 'Twitter followers % (30d)',
        value: 'company.traction_metrics.twitter_follower_count.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    TWITTER_FOLLOWERS_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_twitter_follower_count_value_90d_ago',
        label: 'Twitter followers (90d)',
        value: 'company.traction_metrics.twitter_follower_count.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    TWITTER_FOLLOWERS_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_twitter_follower_count_real_change_90d_ago',
        label: 'Twitter followers ∆ (90d)',
        value: 'company.traction_metrics.twitter_follower_count.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    TWITTER_FOLLOWERS_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_twitter_follower_count_percent_change_90d_ago',
        label: 'Twitter followers % (90d)',
        value: 'company.traction_metrics.twitter_follower_count.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    TWITTER_FOLLOWERS_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_twitter_follower_count_value_180d_ago',
        label: 'Twitter followers (180d)',
        value: 'company.traction_metrics.twitter_follower_count.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    TWITTER_FOLLOWERS_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_twitter_follower_count_real_change_180d_ago',
        label: 'Twitter followers ∆ (180d)',
        value: 'company.traction_metrics.twitter_follower_count.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    TWITTER_FOLLOWERS_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_twitter_follower_count_percent_change_180d_ago',
        label: 'Twitter followers % (180d)',
        value: 'company.traction_metrics.twitter_follower_count.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    TWITTER_FOLLOWERS_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_twitter_follower_count_value_365d_ago',
        label: 'Twitter followers (365d)',
        value: 'company.traction_metrics.twitter_follower_count.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    TWITTER_FOLLOWERS_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_twitter_follower_count_real_change_365d_ago',
        label: 'Twitter followers ∆ (365d)',
        value: 'company.traction_metrics.twitter_follower_count.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    TWITTER_FOLLOWERS_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_twitter_follower_count_percent_change_365d_ago',
        label: 'Twitter followers % (365d)',
        value: 'company.traction_metrics.twitter_follower_count.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    LINKEDIN_FOLLOWERS_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_linkedin_follower_count_value_14d_ago',
        label: 'LinkedIn followers (14d)',
        value: 'company.traction_metrics.linkedin_follower_count.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    LINKEDIN_FOLLOWERS_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_linkedin_follower_count_real_change_14d_ago',
        label: 'LinkedIn followers ∆ (14d)',
        value: 'company.traction_metrics.linkedin_follower_count.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    LINKEDIN_FOLLOWERS_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_linkedin_follower_count_percent_change_14d_ago',
        label: 'LinkedIn followers % (14d)',
        value: 'company.traction_metrics.linkedin_follower_count.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    LINKEDIN_FOLLOWERS_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_linkedin_follower_count_value_30d_ago',
        label: 'LinkedIn followers (30d)',
        value: 'company.traction_metrics.linkedin_follower_count.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    LINKEDIN_FOLLOWERS_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_linkedin_follower_count_real_change_30d_ago',
        label: 'LinkedIn followers ∆ (30d)',
        value: 'company.traction_metrics.linkedin_follower_count.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    LINKEDIN_FOLLOWERS_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_linkedin_follower_count_percent_change_30d_ago',
        label: 'LinkedIn followers % (30d)',
        value: 'company.traction_metrics.linkedin_follower_count.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    LINKEDIN_FOLLOWERS_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_linkedin_follower_count_value_90d_ago',
        label: 'LinkedIn followers (90d)',
        value: 'company.traction_metrics.linkedin_follower_count.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    LINKEDIN_FOLLOWERS_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_linkedin_follower_count_real_change_90d_ago',
        label: 'LinkedIn followers ∆ (90d)',
        value: 'company.traction_metrics.linkedin_follower_count.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    LINKEDIN_FOLLOWERS_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_linkedin_follower_count_percent_change_90d_ago',
        label: 'LinkedIn followers % (90d)',
        value: 'company.traction_metrics.linkedin_follower_count.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    LINKEDIN_FOLLOWERS_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_linkedin_follower_count_value_180d_ago',
        label: 'LinkedIn followers (180d)',
        value: 'company.traction_metrics.linkedin_follower_count.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    LINKEDIN_FOLLOWERS_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_linkedin_follower_count_real_change_180d_ago',
        label: 'LinkedIn followers ∆ (180d)',
        value: 'company.traction_metrics.linkedin_follower_count.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    LINKEDIN_FOLLOWERS_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_linkedin_follower_count_percent_change_180d_ago',
        label: 'LinkedIn followers % (180d)',
        value: 'company.traction_metrics.linkedin_follower_count.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    LINKEDIN_FOLLOWERS_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_linkedin_follower_count_value_365d_ago',
        label: 'LinkedIn followers (365d)',
        value: 'company.traction_metrics.linkedin_follower_count.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    LINKEDIN_FOLLOWERS_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_linkedin_follower_count_real_change_365d_ago',
        label: 'LinkedIn followers ∆ (365d)',
        value: 'company.traction_metrics.linkedin_follower_count.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    LINKEDIN_FOLLOWERS_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_linkedin_follower_count_percent_change_365d_ago',
        label: 'LinkedIn followers % (365d)',
        value: 'company.traction_metrics.linkedin_follower_count.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FACEBOOK_LIKES: {
        urn: 'urn:harmonic:company_field:company_facebook_page_like_count_latest_metric_value',
        label: 'Facebook likes',
        value: 'company.traction_metrics.facebook_page_like_count.latest_metric_value',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FACEBOOK_LIKES_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_facebook_page_like_count_value_14d_ago',
        label: 'Facebook likes (14d)',
        value: 'company.traction_metrics.facebook_page_like_count.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FACEBOOK_LIKES_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_facebook_page_like_count_real_change_14d_ago',
        label: 'Facebook likes ∆ (14d)',
        value: 'company.traction_metrics.facebook_page_like_count.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FACEBOOK_LIKES_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_facebook_page_like_count_percent_change_14d_ago',
        label: 'Facebook likes % (14d)',
        value: 'company.traction_metrics.facebook_page_like_count.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FACEBOOK_LIKES_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_facebook_page_like_count_value_30d_ago',
        label: 'Facebook likes (30d)',
        value: 'company.traction_metrics.facebook_page_like_count.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FACEBOOK_LIKES_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_facebook_page_like_count_real_change_30d_ago',
        label: 'Facebook likes ∆ (30d)',
        value: 'company.traction_metrics.facebook_page_like_count.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FACEBOOK_LIKES_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_facebook_page_like_count_percent_change_30d_ago',
        label: 'Facebook likes % (30d)',
        value: 'company.traction_metrics.facebook_page_like_count.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FACEBOOK_LIKES_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_facebook_page_like_count_value_90d_ago',
        label: 'Facebook likes (90d)',
        value: 'company.traction_metrics.facebook_page_like_count.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FACEBOOK_LIKES_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_facebook_page_like_count_real_change_90d_ago',
        label: 'Facebook likes ∆ (90d)',
        value: 'company.traction_metrics.facebook_page_like_count.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FACEBOOK_LIKES_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_facebook_page_like_count_percent_change_90d_ago',
        label: 'Facebook likes % (90d)',
        value: 'company.traction_metrics.facebook_page_like_count.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FACEBOOK_LIKES_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_facebook_page_like_count_value_180d_ago',
        label: 'Facebook likes (180d)',
        value: 'company.traction_metrics.facebook_page_like_count.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FACEBOOK_LIKES_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_facebook_page_like_count_real_change_180d_ago',
        label: 'Facebook likes ∆ (180d)',
        value: 'company.traction_metrics.facebook_page_like_count.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FACEBOOK_LIKES_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_facebook_page_like_count_percent_change_180d_ago',
        label: 'Facebook likes % (180d)',
        value: 'company.traction_metrics.facebook_page_like_count.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FACEBOOK_LIKES_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_facebook_page_like_count_value_365d_ago',
        label: 'Facebook likes (365d)',
        value: 'company.traction_metrics.facebook_page_like_count.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FACEBOOK_LIKES_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_facebook_page_like_count_real_change_365d_ago',
        label: 'Facebook likes ∆ (365d)',
        value: 'company.traction_metrics.facebook_page_like_count.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    FACEBOOK_LIKES_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_facebook_page_like_count_percent_change_365d_ago',
        label: 'Facebook likes % (365d)',
        value: 'company.traction_metrics.facebook_page_like_count.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    INSTAGRAM_FOLLOWERS_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_instagram_follower_count_value_14d_ago',
        label: 'Instagram followers (14d)',
        value: 'company.traction_metrics.instagram_follower_count.value_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    INSTAGRAM_FOLLOWERS_REAL_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_instagram_follower_count_real_change_14d_ago',
        label: 'Instagram followers ∆ (14d)',
        value: 'company.traction_metrics.instagram_follower_count.real_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    INSTAGRAM_FOLLOWERS_PERCENT_CHANGE_14D_AGO: {
        urn: 'urn:harmonic:company_field:company_instagram_follower_count_percent_change_14d_ago',
        label: 'Instagram followers % (14d)',
        value: 'company.traction_metrics.instagram_follower_count.percent_change_14d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    INSTAGRAM_FOLLOWERS_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_instagram_follower_count_value_30d_ago',
        label: 'Instagram followers (30d)',
        value: 'company.traction_metrics.instagram_follower_count.value_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    INSTAGRAM_FOLLOWERS_REAL_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_instagram_follower_count_real_change_30d_ago',
        label: 'Instagram followers ∆ (30d)',
        value: 'company.traction_metrics.instagram_follower_count.real_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    INSTAGRAM_FOLLOWERS_PERCENT_CHANGE_30D_AGO: {
        urn: 'urn:harmonic:company_field:company_instagram_follower_count_percent_change_30d_ago',
        label: 'Instagram followers % (30d)',
        value: 'company.traction_metrics.instagram_follower_count.percent_change_30d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    INSTAGRAM_FOLLOWERS_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_instagram_follower_count_value_90d_ago',
        label: 'Instagram followers (90d)',
        value: 'company.traction_metrics.instagram_follower_count.value_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    INSTAGRAM_FOLLOWERS_REAL_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_instagram_follower_count_real_change_90d_ago',
        label: 'Instagram followers ∆ (90d)',
        value: 'company.traction_metrics.instagram_follower_count.real_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    INSTAGRAM_FOLLOWERS_PERCENT_CHANGE_90D_AGO: {
        urn: 'urn:harmonic:company_field:company_instagram_follower_count_percent_change_90d_ago',
        label: 'Instagram followers % (90d)',
        value: 'company.traction_metrics.instagram_follower_count.percent_change_90d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    INSTAGRAM_FOLLOWERS_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_instagram_follower_count_value_180d_ago',
        label: 'Instagram followers (180d)',
        value: 'company.traction_metrics.instagram_follower_count.value_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    INSTAGRAM_FOLLOWERS_REAL_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_instagram_follower_count_real_change_180d_ago',
        label: 'Instagram followers ∆ (180d)',
        value: 'company.traction_metrics.instagram_follower_count.real_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    INSTAGRAM_FOLLOWERS_PERCENT_CHANGE_180D_AGO: {
        urn: 'urn:harmonic:company_field:company_instagram_follower_count_percent_change_180d_ago',
        label: 'Instagram followers % (180d)',
        value: 'company.traction_metrics.instagram_follower_count.percent_change_180d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    INSTAGRAM_FOLLOWERS_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_instagram_follower_count_value_365d_ago',
        label: 'Instagram followers (365d)',
        value: 'company.traction_metrics.instagram_follower_count.value_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    INSTAGRAM_FOLLOWERS_REAL_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_instagram_follower_count_real_change_365d_ago',
        label: 'Instagram followers ∆ (365d)',
        value: 'company.traction_metrics.instagram_follower_count.real_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    INSTAGRAM_FOLLOWERS_PERCENT_CHANGE_365D_AGO: {
        urn: 'urn:harmonic:company_field:company_instagram_follower_count_percent_change_365d_ago',
        label: 'Instagram followers % (365d)',
        value: 'company.traction_metrics.instagram_follower_count.percent_change_365d_ago',
        fieldType: ColumnFieldType.HARMONIC,
        icon: TrendingIcon
    },
    MY_NOTES: {
        urn: 'urn:harmonic:company_field:company_user_notes',
        label: 'My notes',
        value: 'company.user_custom_fields.custom_text',
        fieldType: ColumnFieldType.CUSTOM,
        icon: TextIcon
    },
    TEAM_NOTES: {
        urn: 'urn:harmonic:company_field:company_team_notes',
        label: 'Team notes',
        value: 'company.team_custom_fields.custom_text',
        fieldType: ColumnFieldType.CUSTOM,
        icon: TextIcon
    }
};
export const CompanyColumnsValueToFieldTypeMap = Object.fromEntries(Object.entries(CompanyColumnsConfig).map(([, value]) => [
    value.value,
    value.fieldType || null
]));
export const CompanyColumnsValueToIconMap = Object.fromEntries(Object.entries(CompanyColumnsConfig).map(([, value]) => [
    value.value,
    value.icon || null
]));
export const CompanyColumnsValueToObjectMap = Object.fromEntries(Object.entries(CompanyColumnsConfig).map(([key, value]) => [
    value.value,
    CompanyColumnsConfig[key]
]));
export const CompanyColumnsUrnToValueMap = Object.fromEntries(Object.entries(CompanyColumnsConfig).map(([key, value]) => [
    value.urn,
    CompanyColumnsConfig[key]
]));
export const CompanyColumnsValueToUrnMap = Object.fromEntries(Object.entries(CompanyColumnsConfig).map(([key, value]) => [
    value.value,
    value.urn
]));
export default CompanyColumnsConfig;
