export const useNetworkConnectionInfo = () => {
    const connection = navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection ||
        null;
    const navTiming = performance.getEntriesByType('navigation')[0];
    const latency = navTiming.responseStart - navTiming.fetchStart;
    if (!connection)
        return null;
    return {
        rtt: connection.rtt,
        type: connection.type,
        saveData: connection.saveData,
        downlink: connection.downlink,
        downlinkMax: connection.downlinkMax,
        effectiveType: connection.effectiveType,
        latency
    };
};
