import dayjs from 'dayjs';
import { Parser } from 'json2csv';
import { cloneDeep, difference, omit } from 'lodash';
import { GridExportFileType } from 'interfaces/Grid';
import { ApiResourceType } from 'interfaces/DataModel/ApiResource';
import { CompanyCustomTextScope } from 'interfaces/DataModel/Company';
import { COMPANY_FIELD_HEADER_SPEC } from './constants';
import { updateCompanyCustomText } from './midtierApi';
import { displayToast } from './toasts';
export const downloadFile = (fileContents, fileType, fileName) => {
    let mimeType = '', fileExtension = '';
    switch (fileType) {
        case GridExportFileType.JSON:
            (mimeType = 'application/json'), (fileExtension = 'json');
            break;
        case GridExportFileType.JSONL:
            (mimeType = 'application/jsonl'), (fileExtension = 'jsonl');
            break;
        case GridExportFileType.CSV:
        default:
            (mimeType = 'text/csv'), (fileExtension = 'csv');
            break;
    }
    const element = document.createElement('a');
    const file = new Blob([fileContents], { type: mimeType });
    element.href = URL.createObjectURL(file);
    const date = dayjs().format('MMDDYYYY');
    const finalFileName = fileName || `harmonic_export_${date}`;
    element.download = `${finalFileName}.${fileExtension}`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
};
export const formatFieldsForCsvExport = (fields, resourceType) => {
    if (resourceType === ApiResourceType.PeopleList) {
        return fields;
    }
    // Filter out the "Company Name" field and prepend it to the fields array
    const filteredFields = fields.filter((field) => field.value !== 'urn:harmonic:company_field:company_name');
    // Company name is always the first column and visible even if it was turned off or the position was changed
    // Right now, edit columns does not show company name field and assumes it is always checked and visible.
    // For full proof, we are always adding it manually here
    const updatedFields = [COMPANY_FIELD_HEADER_SPEC, ...filteredFields];
    return updatedFields;
};
export const exportGridToCsv = (fields, exportData, fileName) => {
    const csvParser = new Parser({ fields: fields });
    const csv = csvParser.parse(exportData);
    downloadFile(csv, GridExportFileType.CSV, fileName);
};
// Editing cells: currently only exposed to admins, except for custom text fields
export const onCellValueChanged = async (client, event) => {
    const fieldPath = event.colDef.field?.split('.') || [];
    const headerName = event.colDef.headerName;
    const oldValue = event.oldValue;
    const oldValueText = oldValue === null || oldValue === undefined ? 'Empty' : oldValue;
    const newValue = event.newValue;
    const entity = fieldPath[0];
    if (newValue === oldValue)
        return;
    if (entity === 'company') {
        const companyId = event.node.data?.company?.id;
        const companyName = event.node.data?.company?.name;
        if (fieldPath[1] === 'view_record')
            return;
        const displayFailureMessage = () => {
            displayToast({
                primaryText: `Error updating ${headerName} for ${companyName}: ${oldValueText} -> ${newValue}`,
                mode: 'error'
            });
        };
        if (fieldPath[1].includes('custom_fields')) {
            const attributeName = fieldPath[1];
            const scope = attributeName === 'user_custom_fields'
                ? CompanyCustomTextScope.USER
                : CompanyCustomTextScope.TEAM;
            // New value contains a pending boolean
            updateCompanyCustomText(client, companyId, newValue, scope).catch(() => displayFailureMessage());
        }
    }
    else if (entity === 'person') {
        displayToast({
            primaryText: `Deprecated! No longer possible to update person from console`,
            mode: 'error'
        });
    }
};
export const defaultGridOptions = {
    suppressColumnVirtualisation: false,
    rowBuffer: 25,
    suppressCellFocus: true,
    suppressPropertyNamesCheck: true,
    suppressRowHoverHighlight: true,
    rowSelection: {
        headerCheckbox: false,
        checkboxes: false,
        mode: 'multiRow',
        enableClickSelection: false
    },
    enableCellTextSelection: true,
    enableAdvancedFilter: false,
    rowHeight: 65,
    // Editing Options
    singleClickEdit: true,
    enterNavigatesVertically: false,
    enterNavigatesVerticallyAfterEdit: true,
    stopEditingWhenCellsLoseFocus: true
};
export const defaultColDef = {
    cellStyle: {
        fontSize: '10pt',
        fontWeight: 300,
        lineHeight: 'unset',
        wordBreak: 'break-word',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        display: 'flex',
        alignItems: 'center'
    },
    headerClass: 'column-headers text-content-default font-normal typography-label',
    filter: false,
    floatingFilter: false,
    sortable: false,
    resizable: true,
    cellRenderer: 'LongTextCellRenderer',
    enableCellChangeFlash: false
};
// Compares the column name of default columns with custom columns.
// If any name are different, it updates them
export const updateCustomColumnsName = (savedColumns, defaultColumns) => {
    const defaultColumnsIdToNameMap = {};
    defaultColumns.forEach((column) => {
        defaultColumnsIdToNameMap[column.id] = column.name;
    });
    let diffCount = 0;
    const savedColumnsClone = cloneDeep(savedColumns);
    savedColumns.forEach((column, index) => {
        // For custom columns, defaultName will be empty string since it does not exist on config.
        // They should not be reset
        const defaultName = defaultColumnsIdToNameMap[column.id];
        if (defaultName && column.name !== defaultName) {
            diffCount = diffCount + 1;
            savedColumnsClone[index].name = defaultName;
        }
    });
    return {
        diffCount,
        updatedColumns: savedColumnsClone
    };
};
/**
 *  Determine if number of total columns/display names in saved columns
 *  are different from default ordering. If so, update display names,
 *  append new columns, and save back to datastore.
 */
export const updateSavedColumnsWithDefaultColumns = (savedColumns, defaultColumns, customColumnsKey) => {
    const defaultColumnIds = defaultColumns.map((column) => column.id);
    const savedColumnIds = savedColumns.map((column) => column.id);
    const newlyAddedColumnIds = difference(defaultColumnIds, savedColumnIds);
    const removedColumnIds = difference(savedColumnIds, defaultColumnIds);
    if (newlyAddedColumnIds.length === 0 && removedColumnIds.length === 0)
        return savedColumns;
    let newColumns = savedColumns;
    // Add new columns
    for (const newColumnId of newlyAddedColumnIds) {
        const newColumn = defaultColumns.find((column) => column.id == newColumnId);
        const newColumnOrder = newColumn?.order;
        if (isFinite(newColumnOrder)) {
            //Add new column to appropriate order if mentioned
            newColumns = [
                ...newColumns.slice(0, newColumnOrder),
                omit(newColumn, 'order'),
                ...newColumns.slice(newColumnOrder)
            ];
        }
        else {
            // Add new column to end of list
            newColumns = [...newColumns, newColumn];
        }
    }
    // Remove deleted columns
    newColumns = newColumns.filter((column) => !removedColumnIds.includes(column.id));
    return newColumns;
};
