import { HotkeyScope } from 'config/hotkeys';
import { useCallback } from 'react';
import { isHotkeyPressed } from 'react-hotkeys-hook';
import useHotkeys from './useHotkeys';
export const useGridRowSelection = ({ ref, onAddSelectedRows, onRemoveSelectedRows, onClearSelectedRows, enabled }) => {
    useHotkeys({
        keys: ['esc'],
        options: {
            scopes: [HotkeyScope.GRID],
            enableOnFormTags: true,
            description: 'Deselect rows',
            enabled
        },
        callback: () => {
            ref?.current?.api?.deselectAll();
            onClearSelectedRows();
        }
    });
    const handleRowSelected = useCallback((event) => {
        if (isHotkeyPressed('shift') && event.node.isSelected()) {
            // Get the last selected row index
            const selectedNodes = event.api.getSelectedNodes();
            const lastSelectedNode = selectedNodes.length > 1
                ? selectedNodes[selectedNodes.length - 2]
                : selectedNodes[0];
            const lastSelectedIndex = lastSelectedNode
                ? lastSelectedNode.rowIndex
                : -1;
            const currentIndex = event.node.rowIndex;
            // Select all rows between last selected and current
            if (lastSelectedIndex !== null &&
                currentIndex !== null &&
                lastSelectedIndex >= 0) {
                const start = lastSelectedIndex;
                const end = currentIndex;
                const rowNodesToSelect = [];
                // Adjust loop direction based on selection direction
                const step = start <= end ? 1 : -1;
                for (let i = start; step > 0 ? i <= end : i >= end; i += step) {
                    const rowNode = event.api.getDisplayedRowAtIndex(i);
                    if (rowNode) {
                        rowNodesToSelect.push(rowNode);
                        rowNode.setSelected(true);
                    }
                }
                onAddSelectedRows(rowNodesToSelect);
            }
        }
        else {
            if (event.node.isSelected()) {
                onAddSelectedRows([event.node]);
            }
            else {
                onRemoveSelectedRows([event.node]);
            }
        }
    }, [onAddSelectedRows, onRemoveSelectedRows]);
    return { handleRowSelected };
};
