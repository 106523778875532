import client from '../../config/client';
import getPersonProfileHeader from '../../queries/getPersonProfileHeader';
import PersonModel from './models/PersonModel';
class PersonStore {
    rootStore;
    constructor(rootStore) {
        this.rootStore = rootStore;
    }
    async get(id) {
        const { data, errors } = await client.query({
            query: getPersonProfileHeader,
            variables: {
                id
            },
            fetchPolicy: 'cache-first'
        });
        if (!data?.getPersonById) {
            return {
                person: null,
                error: errors?.[0]
            };
        }
        const person = new PersonModel(data.getPersonById);
        return {
            person,
            error: errors?.[0]
        };
    }
}
export default PersonStore;
