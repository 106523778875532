import { datadogRum } from '@datadog/browser-rum';
import { SplitFactory } from '@splitsoftware/splitio';
import { makeAutoObservable, when } from 'mobx';
import { config } from '../../config/config';
import { AuthStatus } from '../../interfaces/Auth';
import zustandStore from '../../stores/zustandStore';
import { PILOT_LIST, SPLITS } from '../../utils/constants';
class SplitStore {
    rootStore;
    features = new Map();
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.initSplit();
        when(() => this.rootStore.authStore.authStatus === AuthStatus.AUTHENTICATED, () => {
            this.initSplit();
        });
    }
    getSplitSdkConfig(key) {
        return {
            core: {
                authorizationKey: config.SPLIT_API_KEY,
                key
            },
            sync: {
                enabled: false
            },
            impressionListener: {
                logImpression(impressionData) {
                    datadogRum.addFeatureFlagEvaluation(impressionData.impression.feature, impressionData.impression.treatment);
                }
            }
        };
    }
    initSplit() {
        const factory = SplitFactory(this.getSplitSdkConfig(this.rootStore.authStore.user?.email.toLocaleLowerCase() ?? ''));
        const client = factory.client();
        client.on(client.Event.SDK_READY, () => {
            this.fetchFeatures(client);
        });
    }
    fetchFeatures(client) {
        const featureNames = Object.values(SPLITS);
        const userUrn = zustandStore.getState().userUrn;
        const planType = PILOT_LIST.includes(userUrn) ? 'pilot' : 'paid';
        const email = this.rootStore.authStore.user?.email.toLocaleLowerCase() ?? '';
        const customerUrn = this.rootStore.authStore.userMetadata?.customer_urn ?? '';
        featureNames.forEach((feature) => {
            const treatment = client.getTreatment(feature, {
                plan_type: planType,
                email,
                user_urn: userUrn,
                customer_urn: customerUrn
            });
            this.features.set(feature, treatment);
        });
    }
    getFeature(featureName) {
        return this.features.get(featureName) === 'on';
    }
}
export default SplitStore;
