import CompanyAllOptions from './company-columns/all';
import CompanyOptions from './company-columns/company';
import CompanyConnectionsOptions from './company-columns/connections';
import CompanyFundingOptions from './company-columns/funding';
import CompanyLinksOptions from './company-columns/links';
import CompanyRecommendedOptions from './company-columns/recommended';
import CompanyTractionOptions from './company-columns/traction';
import PersonAllOptions from './person-columns/all';
import PersonConnectionsOptions from './person-columns/connections';
import PersonExperienceOptions from './person-columns/experience';
import PersonLinksOptions from './person-columns/links';
import PersonOptions from './person-columns/person';
import PersonRecommendedOptions from './person-columns/recommended';
import { EditListIcon, HarmonicFieldIcon, SmartFieldIcon } from 'assets/harmonic-icons';
import { ColumnFieldType } from 'interfaces/ColumnsConfig';
import CompanyColumnsConfig, { CompanyColumnsValueToFieldTypeMap, CompanyColumnsValueToIconMap } from 'interfaces/CompanyColumnsConfig';
import PersonColumnsConfig, { PersonColumnsValueToFieldTypeMap, PersonColumnsValueToIconMap } from 'interfaces/PersonColumnsConfig';
export var ColumnTabOption;
(function (ColumnTabOption) {
    ColumnTabOption["COMPANY_ALL"] = "company_all";
    ColumnTabOption["COMPANY_RECOMMENDED"] = "company_recommended";
    ColumnTabOption["COMPANY_MAIN"] = "company_main";
    ColumnTabOption["COMPANY_FUNDING"] = "company_funding";
    ColumnTabOption["COMPANY_TRACTION"] = "company_traction";
    ColumnTabOption["COMPANY_CONNECTIONS"] = "company_connections";
    ColumnTabOption["COMPANY_LINKS"] = "company_links";
    ColumnTabOption["COMPANY_CUSTOM"] = "company_custom";
    ColumnTabOption["PERSON_ALL"] = "person_all";
    ColumnTabOption["PERSON_RECOMMENDED"] = "person_recommended";
    ColumnTabOption["PERSON_MAIN"] = "person_main";
    ColumnTabOption["PERSON_EXPERIENCE"] = "person_experience";
    ColumnTabOption["PERSON_CONNECTIONS"] = "person_connections";
    ColumnTabOption["PERSON_LINKS"] = "person_links";
    ColumnTabOption["PERSON_CUSTOM"] = "person_custom";
})(ColumnTabOption || (ColumnTabOption = {}));
export const ColumnTabOptionsToShowCustomFields = [
    ColumnTabOption.COMPANY_CUSTOM,
    ColumnTabOption.COMPANY_ALL,
    ColumnTabOption.PERSON_CUSTOM,
    ColumnTabOption.PERSON_ALL
];
export const ColumnTabOptionToListMap = {
    [ColumnTabOption.COMPANY_ALL]: CompanyAllOptions,
    [ColumnTabOption.COMPANY_RECOMMENDED]: CompanyRecommendedOptions,
    [ColumnTabOption.COMPANY_MAIN]: CompanyOptions,
    [ColumnTabOption.COMPANY_FUNDING]: CompanyFundingOptions,
    [ColumnTabOption.COMPANY_TRACTION]: CompanyTractionOptions,
    [ColumnTabOption.COMPANY_CONNECTIONS]: CompanyConnectionsOptions,
    [ColumnTabOption.COMPANY_LINKS]: CompanyLinksOptions,
    //Persons
    [ColumnTabOption.PERSON_ALL]: PersonAllOptions,
    [ColumnTabOption.PERSON_RECOMMENDED]: PersonRecommendedOptions,
    [ColumnTabOption.PERSON_MAIN]: PersonOptions,
    [ColumnTabOption.PERSON_EXPERIENCE]: PersonExperienceOptions,
    [ColumnTabOption.PERSON_CONNECTIONS]: PersonConnectionsOptions,
    [ColumnTabOption.PERSON_LINKS]: PersonLinksOptions
};
export const flattenColumnlist = (columnList) => {
    const output = [];
    for (const column of columnList) {
        if (!column.nested)
            output.push({ label: column.label, value: column.value });
        if (column.nested) {
            output.push(...flattenColumnlist(column.nested));
        }
    }
    return output;
};
export const getNestedFieldsCount = (column) => {
    if (!column.nested)
        return 1;
    let count = 0;
    for (const nestedColumn of column.nested) {
        count += getNestedFieldsCount(nestedColumn);
    }
    return count;
};
export const getIconForPersonColumnField = (fieldValue, prefix) => {
    const prefixWithDot = `${prefix}.`;
    if (prefix && fieldValue?.startsWith(prefixWithDot)) {
        fieldValue = fieldValue.substring(prefixWithDot.length);
    }
    const fieldType = PersonColumnsValueToFieldTypeMap[fieldValue];
    return fieldType === ColumnFieldType.HARMONIC
        ? HarmonicFieldIcon
        : fieldType === ColumnFieldType.SMART
            ? SmartFieldIcon
            : EditListIcon;
};
export const getIconForCompanyColumnField = (fieldValue) => {
    const fieldType = CompanyColumnsValueToFieldTypeMap[fieldValue];
    return fieldType === ColumnFieldType.HARMONIC
        ? HarmonicFieldIcon
        : fieldType === ColumnFieldType.SMART
            ? SmartFieldIcon
            : EditListIcon;
};
export const getCompanyFieldIcon = (fieldValue) => {
    return CompanyColumnsValueToIconMap[fieldValue];
};
export const getPeopleFieldIcon = (fieldValue) => {
    return PersonColumnsValueToIconMap[fieldValue];
};
export const getTooltipForColumnField = (isCompanyMode, fieldValue) => {
    const fieldType = isCompanyMode
        ? CompanyColumnsValueToFieldTypeMap[fieldValue]
        : PersonColumnsValueToFieldTypeMap[fieldValue];
    return fieldType === ColumnFieldType.HARMONIC
        ? 'This field is automatically enriched by harmonic’s startup database.'
        : fieldType === ColumnFieldType.SMART
            ? 'This field is automatically enriched by data from integrations.'
            : 'This field is created by you or your team for this list';
};
export const getUpdatedColumnsForSave = (tableColumns, isCompanyMode) => {
    let formattedColumns = [];
    if (isCompanyMode) {
        formattedColumns = [
            {
                id: CompanyColumnsConfig.NAME.value,
                name: CompanyColumnsConfig.NAME.label,
                isVisibleOnTable: true
            },
            ...tableColumns
        ];
    }
    else {
        formattedColumns = [
            {
                id: PersonColumnsConfig.FULL_NAME.value,
                name: PersonColumnsConfig.FULL_NAME.label,
                isVisibleOnTable: true
            },
            ...tableColumns
        ];
    }
    return formattedColumns;
};
