import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { compact } from 'lodash';
import React from 'react';
export const TeamMemberConnections = ({ employee }) => {
    const userConnections = compact(employee.userConnections?.map((userConnection) => userConnection?.user));
    const userConnectionNames = userConnections.map((user) => user?.name);
    const numConnectionToShow = 3;
    const firstThreeConnections = userConnectionNames.slice(0, numConnectionToShow);
    const otherConnections = userConnectionNames.length - numConnectionToShow;
    return (_jsxs("div", { className: "p-p20", children: [firstThreeConnections.length ? (_jsxs(_Fragment, { children: [firstThreeConnections.map((connection, index) => (_jsxs("span", { children: [_jsx("span", { className: "typography-label-default-strong", children: connection }), index < firstThreeConnections.length - 1 && (_jsxs("span", { className: "text-content-weak typography-label-default-default", children: [",", ' '] }))] }, index))), otherConnections > 0 && (_jsxs("span", { className: "text-content-weak typography-label-default-default", children: [", and ", otherConnections, ' ', otherConnections > 1 ? 'others' : 'other', ' '] })), ' ', _jsxs("span", { className: "text-content-weak typography-label-default-default", children: [userConnectionNames.length > 1 ? 'are' : 'is', " in network with"] })] })) : (_jsx("span", { className: "text-content-weak typography-label-default-default", children: "Not in network with" })), ' ', _jsx("span", { className: "typography-label-default-strong", children: employee.firstName ?? employee.fullName })] }));
};
