import { gql } from '@apollo/client';
import { PeopleCustomFieldsFragment } from './getPeopleWatchlists';
import { SIMPLE_PEOPLE_WATCHLIST_ENTRY_NODE_FRAGMENT } from './getWatchlistWithPeople';
export const BASE_PEOPLE_WATCHLIST_ENTRY_NODE_FRAGMENT = gql `
  fragment BasePeopleWatchlistEntryNode on PeopleWatchlistEntryNode {
    __typename
    list {
      updatedAt
      __typename
      name
      entityUrn
      id
      customFields {
        ...PeopleCustomFields
      }
    }
    ...SimplePeopleListEntryNode
  }
  ${SIMPLE_PEOPLE_WATCHLIST_ENTRY_NODE_FRAGMENT}
  ${PeopleCustomFieldsFragment}
`;
export const getListEntriesByPersonId = gql `
  query GetListEntriesByPersonId($id: Int!) {
    getPersonById(id: $id) {
      id
      __typename
      listEntries {
        ...BasePeopleWatchlistEntryNode
      }
    }
  }
  ${BASE_PEOPLE_WATCHLIST_ENTRY_NODE_FRAGMENT}
`;
