import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ExcludeInvertedIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { useStore } from 'config/StoreProvider';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { logger } from 'utils/logger';
import CompanyResearchReportOptions from './CompanyResearchReportOptions';
const CompanyResearchInput = observer(({ companyUrn, companyName, companyDomain }) => {
    const { rootStore } = useStore();
    const companyResearchStore = rootStore.companyResearchStore;
    const containerRef = useRef(null);
    const [question, setQuestion] = useState('');
    const [isDeepResearch, setIsDeepResearch] = useState(false);
    const abortControllerRef = useRef(null);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const inputRef = useRef(null);
    // Add click outside handler
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current &&
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                !containerRef.current.contains(event.target) &&
                isInputFocused) {
                setIsInputFocused(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isInputFocused]);
    const handleSetQuestion = useCallback((e) => {
        setQuestion(e.target.value);
    }, []);
    const handleClearQuestion = useCallback(() => {
        setQuestion('');
        setIsInputFocused(false);
    }, []);
    const handleAskQuestion = useCallback(async (questionToAsk) => {
        if (!questionToAsk.trim())
            return;
        // Cancel any existing request
        abortControllerRef.current?.abort();
        // Create new abort controller
        abortControllerRef.current = new AbortController();
        try {
            // Create a new thread through the store
            const thread = await companyResearchStore.createNewThread({
                question: questionToAsk,
                researchMode: isDeepResearch ? 'deep' : 'quick'
            });
            if (!thread) {
                throw new Error('Failed to create thread');
            }
        }
        catch (error) {
            if (error instanceof Error && error.name === 'AbortError') {
                return;
            }
            logger.error('Research assistant request failed', { error });
        }
        finally {
            handleClearQuestion();
        }
    }, [companyResearchStore, handleClearQuestion, isDeepResearch]);
    const handleExampleQuestionClick = async (exampleQuestion) => {
        await handleAskQuestion(exampleQuestion);
    };
    // Fetch example questions
    const handleFetchExampleQuestions = useCallback(async () => {
        if (!companyUrn || !companyName)
            return;
        await companyResearchStore.fetchExampleQuestions();
    }, [companyUrn, companyName, companyResearchStore]);
    const handleClearQuestionClick = () => {
        setIsInputFocused(true);
        if (!companyResearchStore.isLoadingExamples &&
            !companyResearchStore.exampleQuestions.length) {
            handleFetchExampleQuestions();
        }
    };
    return (_jsx("div", { className: "fixed bottom-4 z-[1000] w-[380px] right-[10px]", ref: containerRef, children: _jsxs("div", { className: classNames('flex flex-col bg-white shadow-lg border border-border rounded-lg transition-all duration-300 ease-in-out', isInputFocused ? 'h-fit' : 'h-[45px]'), children: [_jsx("div", { className: classNames('flex-1 overflow-hidden flex flex-col transition-all duration-300 ease-in-out pb-p20', isInputFocused ? 'opacity-100' : 'opacity-0'), children: _jsx(CompanyResearchReportOptions, { companyName: companyName, companyUrn: companyUrn, companyDomain: companyDomain, onExampleQuestionClick: handleExampleQuestionClick, exampleQuestions: companyResearchStore.exampleQuestions, isLoadingExamples: companyResearchStore.isLoadingExamples, onFetchExampleQueries: handleFetchExampleQuestions, question: question }) }), _jsxs("div", { className: classNames('flex flex-row items-center justify-between py-p40 px-p40', isInputFocused && 'border-t border-border border-solid'), children: [_jsxs("div", { className: "flex flex-row items-center gap-g40 w-full", children: [_jsx(ExcludeInvertedIcon, { className: "text-blue-600" }), _jsx("input", { ref: inputRef, value: question, onChange: handleSetQuestion, onFocus: handleClearQuestionClick, placeholder: `Ask Agent about ${companyName}`, className: "border-none resize-none flex-1 bg-white placeholder:text-content-muted" })] }), _jsxs("div", { className: "flex items-center gap-2", children: [_jsx("button", { 
                                    // eslint-disable-next-line react/jsx-handler-names
                                    onClick: () => setIsDeepResearch(!isDeepResearch), className: classNames('rounded-md p-p10', !isDeepResearch && 'text-gray-500 hover:text-gray-700'), title: isDeepResearch ? 'Deep Research Mode' : 'Quick Research Mode', children: _jsx("span", { className: classNames('icon material-icons-outlined text-lg', isDeepResearch && 'text-blue-500 icon-filled'), children: "science" }) }), _jsx("button", { onClick: handleClearQuestion, className: "text-gray-500 hover:text-gray-700", children: _jsx("span", { className: "icon material-icons-outlined text-lg", children: "close" }) })] })] })] }) }));
});
export default CompanyResearchInput;
