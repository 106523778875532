import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { ReactComponent as AnonymousPersonLogo } from 'assets/anonymous-person-logo.svg';
import defaultPersonLogo from 'assets/default-person-logo.svg';
import { LinkedInIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { ReactComponent as PathInIcon } from 'common/assets/network/path-in.svg';
import { FormatTextInAsterisks } from 'common/components/FormatTextInAsterisks';
import { formatConnectionStrings } from 'common/components/Network/util';
import AddPeopleToWatchlist from 'components/common/AddPeopleToWatchlist';
import ContactButton from 'components/common/ContactData/ContactButton';
import GetPersonEmailButton from 'components/common/ContactData/GetEmailButton';
import AvatarWithHighlightBadge from 'components/common/Highlights/AvatarWithHighlightBadge';
import InternalLink from 'components/common/InternalLink';
import PersonUserConnections from 'components/common/PersonUserConnections';
import { useStore } from 'config/StoreProvider';
import { dayjsExt } from 'config/dayjs';
import IconButton from 'harmonic-components/IconButton/IconButton';
import useFlags from 'hooks/useFlags';
import { compact, first, isNil, trim } from 'lodash';
import { observer } from 'mobx-react';
import { getPersonExperienceAndHighlightsForConnectionsCard } from 'queries/getCompanyUserConnections';
import { SPLITS } from 'utils/constants';
import { getDisplayNameFromParticipantList, getParticipantResponseStatusMap } from 'utils/userConnections';
import { isSafeHostedAsset } from 'utils/utilities';
import CalendarEventCard from './CalendarEventCard';
import EmailMessageCard from './EmailMessageCard';
const ConnectionCorrespondenceDetail = ({ latestEmail, latestCalendarEvent, emailCombinedParticipants, calendarEventParticipants }) => {
    const calendarParticipantResponseStatusMap = getParticipantResponseStatusMap(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    latestCalendarEvent?.participantResponseStatuses ??
        []);
    return (_jsxs("div", { className: "flex flex-col gap-g40", children: [latestEmail ? (_jsx(EmailMessageCard, { sender: latestEmail?.sender?.emailAddress.name ?? '', participants: emailCombinedParticipants, name: latestEmail?.title || '', startTime: latestEmail?.timestamp })) : null, latestCalendarEvent ? (_jsx("div", { className: "", children: _jsx(CalendarEventCard, { participants: calendarEventParticipants, name: latestCalendarEvent?.name || '', startTime: latestCalendarEvent?.startTime, participantsResponseStatusMap: calendarParticipantResponseStatusMap }) })) : null] }));
};
export const UserConnectionDetail = observer(({ userConnection }) => {
    const { rootStore } = useStore();
    const user = rootStore.authStore.user;
    const currentUserEmail = user?.email ?? '';
    const connectionString = first(formatConnectionStrings(
    // hack: typings are a bit wonky in common package at the moment, need to figure out how to get
    // properly generated types in there and refactor
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    [userConnection], currentUserEmail, userConnection.targetPerson?.fullName ?? '')?.detailedConnections);
    const emailCombinedParticipants = userConnection.latestEmail?.receivers?.map((participant) => ({
        name: participant?.emailAddress.name ?? '',
        emailAddress: participant?.emailAddress.emailAddress ?? ''
    })) ?? [];
    const calendarEventParticipants = userConnection.latestCalendarEvent?.participants?.map((participant) => ({
        name: participant?.emailAddress.name ?? '',
        emailAddress: participant?.emailAddress.emailAddress ?? ''
    })) ?? [];
    return (_jsxs("div", { className: "bg-surface-nested p-p30 rounded-md border border-solid border-border", children: [_jsxs("div", { className: "flex items-start gap-2", children: [_jsx("div", { className: "w-4 h-4 pt-p10", children: _jsx(PathInIcon, { "data-testid": "path-in-icon" }) }), _jsx(FormatTextInAsterisks, { text: connectionString ?? '', asteriskClassName: "typography-label-default-strong text-content-strong", textClassName: "typography-paragraph text-content-weak" })] }), userConnection.latestEmail || userConnection.latestCalendarEvent ? (_jsx("div", { className: "w-full mt-g40 ml-p80 pr-p80", children: _jsx(ConnectionCorrespondenceDetail, { latestEmail: userConnection.latestEmail, latestCalendarEvent: userConnection.latestCalendarEvent, emailCombinedParticipants: emailCombinedParticipants, calendarEventParticipants: calendarEventParticipants }) })) : null] }));
});
const PersonConnectionCard = ({ personWithConnections, currentCompanyId }) => {
    const person = personWithConnections.person;
    const { data: personExperienceAndHighlightsData } = useQuery(getPersonExperienceAndHighlightsForConnectionsCard, {
        variables: {
            id: person?.id ?? -1
        },
        skip: !person?.id
    });
    const { enabled: useNetworkMappingNewTooltips } = useFlags(SPLITS.networkMappingNewTooltips);
    const personExperienceAndHighlights = personExperienceAndHighlightsData?.getPersonById;
    const personEmail = personWithConnections.personEmail;
    const userConnections = personWithConnections.userConnections;
    const userConnectionsWithFullMetadata = personWithConnections.userConnectionsWithFullMetadata;
    const current = personExperienceAndHighlights?.experience?.find((exp) => {
        return exp?.company.id === currentCompanyId && exp?.isCurrentPosition;
    });
    const relevantExperience = personExperienceAndHighlights?.experience?.find((exp) => {
        return exp?.company.id === currentCompanyId;
    });
    const relevantTitle = current?.title ?? relevantExperience?.title;
    const relevantStartDate = current?.startDate || relevantExperience?.startDate
        ? dayjsExt(current?.startDate ?? relevantExperience?.startDate).format('MMM YYYY')
        : undefined;
    const relevantEndDate = current
        ? 'Current'
        : relevantExperience?.endDate
            ? dayjsExt(relevantExperience?.endDate).format('MMM YYYY')
            : undefined;
    const latestCalendarEvent = personWithConnections?.latestCalendarEvent;
    const latestEmail = personWithConnections?.latestEmail;
    const calendarEventParticipants = latestCalendarEvent?.participants?.map((participant) => ({
        name: participant?.emailAddress.name ?? '',
        emailAddress: participant?.emailAddress.emailAddress ?? ''
    })) ?? [];
    const latestEmailReceivers = latestEmail?.receivers?.map((participant) => ({
        name: participant?.emailAddress.name ?? '',
        emailAddress: participant?.emailAddress.emailAddress ?? ''
    })) ?? [];
    const emailCombinedParticipants = latestEmailReceivers;
    emailCombinedParticipants.unshift({
        name: latestEmail?.sender?.emailAddress.name ?? '',
        emailAddress: latestEmail?.sender?.emailAddress.emailAddress ?? ''
    });
    const personLogo = isSafeHostedAsset(person?.profilePictureUrl ?? '')
        ? person?.profilePictureUrl
        : defaultPersonLogo;
    const personHighlights = compact(personExperienceAndHighlights?.highlights?.map((highlight) => highlight?.category)) ?? [];
    let targetConnectionName = person?.fullName ??
        getDisplayNameFromParticipantList(personEmail ?? '', emailCombinedParticipants.concat(calendarEventParticipants));
    targetConnectionName = trim(targetConnectionName, '"');
    return (_jsxs("div", { "data-testid": "UserConnectionsList-PersonWithConnectionsCard", className: classNames('flex flex-col', 'p-p10 rounded-br40 bg-surface-background'), children: [!useNetworkMappingNewTooltips && (_jsx("div", { className: "px-p30 py-p20", children: _jsx(PersonUserConnections, { target: { name: targetConnectionName }, userConnections: userConnections, emphasizedStyle: true, showIcon: false }) })), _jsx("div", { className: classNames('flex flex-col gap-2', 'p-p50', 'rounded-br40 bg-surface-default border border-border border-solid', 'h-full'), children: _jsx("div", { className: "flex-1", children: _jsxs("div", { className: "flex flex-col gap-g50", children: [person ? (_jsx("div", { className: "flex flex-col gap-g10", children: _jsxs("div", { className: "flex justify-between", children: [_jsxs("div", { className: "flex gap-g40", children: [_jsx(AvatarWithHighlightBadge, { href: `/dashboard/person/${person.id}`, 
                                                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                                                    src: personLogo, highlights: personHighlights, personId: person.id, size: "x-large" }), _jsxs("div", { children: [_jsxs("div", { className: "flex flex-row items-center gap-g20", children: [_jsx(InternalLink, { to: { pathname: `/dashboard/person/${person.id}` }, children: _jsx("p", { className: "text-content-strong typography-label-default-strong line-clamp-1", children: targetConnectionName }) }), person.socials?.linkedin?.url ? (_jsx(IconButton, { icon: (props) => (_jsx(LinkedInIcon, { ...props, applyCurrentColor: false })), type: "secondary", emphasis: "low", size: "tiny", 
                                                                    // eslint-disable-next-line react/jsx-handler-names
                                                                    onClick: () => {
                                                                        person.socials?.linkedin?.url &&
                                                                            window.open(person.socials?.linkedin?.url, '_blank');
                                                                    } })) : null] }), relevantTitle ? (_jsxs("div", { className: "flex gap-g30 items-center", children: [_jsxs("div", { className: "line-clamp-1 text-content-weak typography-label-default-default", children: [!current && 'was', " ", relevantTitle] }), _jsx("p", { className: "text-content-lighterGray typography-label-small-default", children: "\u2022" }), _jsx("div", { children: _jsxs("p", { className: "line-clamp-1 text-content-weak typography-label-default-default", children: [relevantStartDate ? relevantStartDate : null, " -", ' ', relevantEndDate ? relevantEndDate : null] }) })] })) : null] })] }), _jsxs("div", { className: "flex gap-g20 max-h-[28px]", children: [_jsx(AddPeopleToWatchlist, { type: "icon", personIds: [person.id] }), personEmail ? (_jsx(ContactButton, { name: targetConnectionName, emailAddress: personEmail })) : (_jsx(GetPersonEmailButton, { personId: person.id, size: "compact" }))] })] }) })) : (_jsx("div", { className: "flex flex-col gap-g10", children: _jsxs("div", { className: "flex gap-g40", children: [_jsx(AnonymousPersonLogo, {}), _jsxs("div", { className: "flex justify-between w-full", children: [_jsxs("div", { children: [_jsx("p", { className: "text-content-strong typography-label-default-strong line-clamp-1", children: targetConnectionName }), _jsx("p", { className: "typography-label-default-default text-content-weak", children: "Imported from emails" })] }), _jsx("div", { className: "flex gap-g20 max-h-[28px]", children: personEmail ? (_jsx(ContactButton, { name: targetConnectionName, emailAddress: personEmail })) : null })] })] }) })), userConnectionsWithFullMetadata
                                .sort((a, b) => (isNil(b.latestCalendarEvent) ? 0 : 1) -
                                (isNil(a.latestCalendarEvent) ? 0 : 1) +
                                (isNil(b.latestEmail) ? 0 : 1) -
                                (isNil(a.latestEmail) ? 0 : 1))
                                .map((userConnection) => (_jsx(UserConnectionDetail, { userConnection: userConnection }, userConnection.userUrn + userConnection.connectionTargetUrn)))] }) }) })] }));
};
export default PersonConnectionCard;
