import { useMemo } from 'react';
import useTableStore from '../stores/tableStore';
export const EXTENDED_INCLUDES_TO_COLUMNS_MAP = {
    includesPersonRelationships: [
        'company.person_relationships',
        'company.person_linkedin_profiles'
    ],
    includesPersonRelationshipsFoundersAndCeos: [
        'company.person_relationships_founders_and_ceos'
    ],
    includesPersonRelationshipsExecutives: [
        'company.person_relationships_executives'
    ],
    includesLeadershipPriorCompanies: ['company.leadership_prior_companies'],
    includesFunding: [
        'company.num_funding_rounds',
        'company.last_funding_at',
        'company.last_funding_type',
        'company.last_funding_total',
        'company.funding_total',
        'company.funding_per_employee',
        'company.investors'
    ],
    includesUserConnections: ['company.user_connections'],
    includesAffinityLists: ['company.lists', 'company.affinity_lists'],
    includesSocials: [
        'company.linkedin_url',
        'company.twitter_url',
        'company.crunchbase_url',
        'company.pitchbook_url',
        'company.facebook_url',
        'company.instagram_url',
        'company.angellist_url',
        'company.indeed_url',
        'company.youtube_url',
        'company.monster_url',
        'company.stackoverflow_url',
        'company.linkedin_follower_count',
        'company.twitter_total_followers',
        'company.instagram_num_followers',
        'company.facebook_total_page_likes'
    ],
    includesTypedTags: [
        'company.customer_type',
        'company.industry_tags',
        'company.technology_tags',
        'company.accelerators',
        'company.typed_tags'
    ],
    includesHighlights: ['company.highlights'],
    includesContact: [
        'company.company_emails',
        'company.team_emails',
        'company.contact_numbers',
        'company.primary_email'
    ]
};
// Special cases that check for column prefix matches
export const EXTENDED_INCLUDES_TO_COLUMNS_PREFIX_MAP = {
    includesTractionMetrics: 'company.traction_metrics',
    includesUserConnectionsOverview: 'company.user_connections_overview'
};
export const useCompanyExtendedIncludes = () => {
    const tableColumns = useTableStore((state) => state.tableColumns);
    const extendedIncludes = useMemo(() => {
        const visibleFieldByTableColumnId = new Map();
        for (const column of tableColumns) {
            visibleFieldByTableColumnId.set(column.id, column.isVisibleOnTable);
        }
        const keys = Array.from(visibleFieldByTableColumnId.keys());
        // Handle prefix-based includes
        const prefixIncludes = Object.entries(EXTENDED_INCLUDES_TO_COLUMNS_PREFIX_MAP).reduce((acc, [key, prefix]) => ({
            ...acc,
            [key]: keys.some((key) => key.includes(prefix))
        }), {});
        // Handle exact match includes
        const exactIncludes = Object.entries(EXTENDED_INCLUDES_TO_COLUMNS_MAP).reduce((acc, [key, columns]) => ({
            ...acc,
            [key]: columns.some((column) => visibleFieldByTableColumnId.get(column)) ||
                false
        }), {});
        return {
            ...exactIncludes,
            ...prefixIncludes
        };
    }, [tableColumns]);
    return { extendedIncludes };
};
