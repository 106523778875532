import { jsx as _jsx } from "react/jsx-runtime";
import { useLazyQuery } from '@apollo/client';
import classNames from 'classnames';
import NetworkAttributeBadge from 'common/components/Network/NetworkAttributeBadge';
import NetworkBadge from 'common/components/Network/NetworkBadge';
import NetworkSummaryBadge from 'common/components/Network/NetworkSummaryBadge';
import NetworkTooltip from 'common/components/Network/NetworkTooltip';
import { getIdFromUrn, getUrnFragment } from 'common/utils/urn';
import { useStore } from 'config/StoreProvider';
import { compact } from 'lodash';
import { observer } from 'mobx-react';
import { getCompanyUserConnectionsWithOverviewAndEvents } from 'queries/getCompanyUserConnections';
import getPersonUserConnections from 'queries/getPersonUserConnections';
const NetworkBadgeWithTooltip = observer((props) => {
    const { rootStore } = useStore();
    const user = rootStore.authStore.user;
    const { usersInNetwork = [], includeSummaryText, variant = 'badge', targetEntityUrn, targetName, targetRelevantExperienceCompanyUrn, onClick } = props;
    const entityType = getUrnFragment(targetEntityUrn, 2);
    const entityId = getIdFromUrn(targetEntityUrn);
    if ((entityType != 'person' && entityType != 'company') || !entityId) {
        throw new Error('Invalid URN');
    }
    const connectionsQuery = entityType === 'person'
        ? getPersonUserConnections
        : getCompanyUserConnectionsWithOverviewAndEvents;
    const [loadConnections, { data: connectionsData, loading: connectionsLoading }] = useLazyQuery(connectionsQuery, {
        variables: {
            id: parseInt(entityId)
        }
    });
    const currentUserEmail = user?.email ?? '';
    const BadgeComponent = variant === 'attribute'
        ? NetworkAttributeBadge
        : includeSummaryText
            ? NetworkSummaryBadge
            : NetworkBadge;
    const userConnections = compact(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    connectionsData?.getPersonById
        ?.userConnections ||
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        connectionsData
            ?.getCompanyById?.userConnections);
    return (_jsx("div", { "data-testid": "network-badge-tooltip", className: classNames(onClick ? 'cursor-pointer' : 'cursor-default'), 
        // eslint-disable-next-line react/jsx-handler-names
        onClick: onClick, children: _jsx(NetworkTooltip, { userConnections: connectionsLoading
                ? undefined
                : // hack: typings are a bit wonky in common package at the moment, need to figure out how to get
                    // properly generated types in there and refactor
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    userConnections, loadConnections: loadConnections, targetRelevantExperienceCompanyUrn: targetRelevantExperienceCompanyUrn, currentUserEmail: currentUserEmail, targetName: targetName, children: _jsx(BadgeComponent, { usersInNetwork: usersInNetwork, currentUserEmail: currentUserEmail }) }) }));
});
export default NetworkBadgeWithTooltip;
