import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { PlusIcon } from 'assets/harmonic-icons';
import Button from 'harmonic-components/Button/Button';
import { BASE_URL } from 'utils/constants';
import { displayToast } from 'utils/toasts';
import { camelize } from 'utils/utilities';
import ResearchReportContent from './ResearchReportContent';
const ResearchReportModal = ({ onBack, onClose, reportTitle, summarySection, reportSections, thread }) => {
    const handleCopyLink = () => {
        const reportUrl = `${BASE_URL}/dashboard/research/report/${thread.thread_id}`;
        navigator.clipboard.writeText(reportUrl);
        displayToast({
            primaryText: 'Successfully copied to clipboard',
            mode: 'success'
        });
    };
    const userPrompt = thread.values?.research_report_sections_input?.user_input;
    const reportMetadata = {
        companyUrn: thread.metadata?.company_urn,
        companyName: thread.values?.company_data?.name,
        companyLogoUrl: thread.values?.company_data?.logo_url,
        companyDomain: thread.values?.company_data?.website?.domain
    };
    const handleBackClick = () => {
        onBack?.();
    };
    const handleCloseClick = () => {
        onClose?.();
    };
    return (_jsx("div", { className: "overflow-hidden absolute top-0 right-0 left-0 bottom-0", "aria-labelledby": "research-report-modal", children: _jsxs("div", { className: "bg-white flex flex-col h-full", children: [_jsxs("div", { className: "shrink-0 flex justify-between items-center border-b border-solid border-border px-p60 py-p10", children: [_jsxs("div", { className: "flex items-center gap-2", children: [_jsx("button", { onClick: handleBackClick, className: "flex items-center justify-center w-8 h-8 rounded-full transition-colors text-gray-500 hover:text-gray-700", children: _jsx("span", { className: "icon text-md", children: "arrow_back" }) }), _jsx("h2", { className: "text-md font-medium text-gray-800", children: reportTitle || '' })] }), _jsxs("div", { children: [thread.metadata?.visibility === 'customer' && (_jsx(Button, { onClick: handleCopyLink, leadingIcon: PlusIcon, label: "Copy share link", emphasis: "medium", type: "secondary", size: "compact" })), !!onClose && (_jsx("button", { onClick: handleCloseClick, className: "flex items-center justify-center w-8 h-8 rounded-full transition-colors text-gray-500 hover:text-gray-700", children: _jsx("span", { className: "icon text-md", children: "close" }) }))] })] }), _jsxs("div", { className: "flex-1 overflow-y-auto px-p60 pb-[80px]", children: [_jsx("p", { className: "text-gray-500 text-sm block text-ellipsis py-p60", title: userPrompt, children: userPrompt }), reportSections?.length > 0 && (_jsx(ResearchReportContent, { metadata: reportMetadata, sections: camelize(reportSections), summarySection: summarySection, isTopLevel: true, reportType: thread.metadata?.report_type }))] })] }) }));
};
export default ResearchReportModal;
