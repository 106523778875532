import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from 'harmonic-components/Button/Button';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import { useModal } from 'hooks/useModal';
import { useNavigate } from 'react-router';
const CRMNotSetUpYetPopup = () => {
    const { close } = useModal();
    const navigate = useNavigate();
    const handleOnSubmit = () => {
        navigate('/settings?t=integrations');
        close();
    };
    const handleOnCancel = () => {
        close();
    };
    return (_jsxs("div", { children: [_jsx(ModalTitle, { title: "No CRM integrated yet" }), _jsx("div", { className: "flex flex-col items-start self-stretch p-p60 max-w-[360px]", children: _jsx("p", { className: "text-content-weak typography-label-default-default", children: "Please integrate with your CRM first. Please visit the setup page to go through the setup." }) }), _jsxs(ModalFooter, { children: [_jsx(Button, { label: "Cancel", onClick: handleOnCancel }), _jsx(Button, { type: "primary", label: "Set up CRM Integration", onClick: handleOnSubmit })] })] }));
};
export default CRMNotSetUpYetPopup;
