import defaultCompanyLogo from 'assets/company-profile/default-logo.svg';
import stealthLogo from 'assets/company-profile/stealth-logo.svg';
import client from 'config/client';
import { makeAutoObservable, runInAction } from 'mobx';
import { getListEntriesByCompanyId } from 'queries/getListEntriesByCompanyId';
import { isSafeHostedAsset } from '../../../utils/utilities';
import CompanyListEntryModel from './CompanyListEntryModel';
class CompanyModel {
    data;
    listEntries = [];
    listEntriesLoading = false;
    constructor(data) {
        makeAutoObservable(this);
        this.data = data;
        this.fetchListEntries();
    }
    get isStealthCompany() {
        return this.data?.name?.startsWith('Stealth Company') ?? false;
    }
    get logoUrl() {
        return this.isStealthCompany
            ? stealthLogo
            : isSafeHostedAsset(this.data?.logoUrl ?? '')
                ? this.data?.logoUrl
                : defaultCompanyLogo;
    }
    fetchListEntries() {
        if (!this.data?.id) {
            return;
        }
        client.query({
            query: getListEntriesByCompanyId,
            variables: {
                id: this.data?.id
            }
        });
        this.observeListEntriesQuery();
    }
    observeListEntriesQuery() {
        const observableFragment = client.watchQuery({
            query: getListEntriesByCompanyId,
            variables: {
                id: this.data?.id
            }
        });
        runInAction(() => {
            this.listEntriesLoading = true;
        });
        observableFragment.subscribe({
            next: (result) => {
                runInAction(() => {
                    this.listEntries =
                        result.data.getCompanyById?.listEntries?.map((entry) => new CompanyListEntryModel(entry)) ?? [];
                    this.listEntriesLoading = false;
                });
            },
            error: () => {
                runInAction(() => {
                    this.listEntriesLoading = false;
                });
            }
        });
    }
}
export default CompanyModel;
