import { gql } from '@apollo/client';
import { CorrespondenceSummary, UserConnectionFragment } from './getCompanyUserConnections';
const getPersonUserConnections = gql `
  query GetPersonUserConnections($id: Int!) {
    getPersonById(id: $id) {
      id
      correspondenceSummary {
        ...CorrespondenceSummary
      }
      userConnections {
        ...UserConnection
        targetPerson {
          id
          fullName
          profilePictureUrl
          experience {
            title
            roleType
            company {
              id
              name
              logoUrl
            }
            isCurrentPosition
            startDate
            endDate
          }
        }
      }
    }
  }
  ${CorrespondenceSummary}
  ${UserConnectionFragment}
`;
export default getPersonUserConnections;
