import { jsx as _jsx } from "react/jsx-runtime";
import { NumberListCustomFieldValueFormat } from '__generated__/graphql';
import classNames from 'classnames';
import TextInput from 'harmonic-components/TextInput/TextInput';
import React, { useState } from 'react';
import { formatNumberListCustomFieldValue, parseNumberListCustomFieldValue } from 'utils/utilities';
const NumberRenderer = ({ value, onChange, mode, numberFormat, placeholder }) => {
    const [inputValue, setInputValue] = useState(value !== null && value !== undefined ? value : undefined);
    const [hasChanged, setHasChanged] = useState(false);
    const formattedNumber = formatNumberListCustomFieldValue(inputValue, numberFormat, mode === 'read');
    if (mode === 'read') {
        if (inputValue === null || inputValue === undefined) {
            return _jsx("p", { className: "text-input-placeholder-default", children: placeholder });
        }
        return _jsx("p", { children: formattedNumber });
    }
    const handleInputChange = (e) => {
        const parsedValue = parseNumberListCustomFieldValue(e.target.value);
        setInputValue(Number(parsedValue));
        setHasChanged(true);
    };
    const handleSave = () => {
        if (!hasChanged) {
            return;
        }
        if (inputValue === undefined) {
            onChange(null);
        }
        else {
            onChange(Number(inputValue.toString().replace(/,/g, '')));
        }
        setHasChanged(false);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSave();
        }
    };
    return (_jsx(TextInput, { dataTestId: "NumberRenderer-Input", fullWidth: true, prefix: numberFormat === NumberListCustomFieldValueFormat.US_DOLLAR
            ? '$'
            : undefined, suffix: numberFormat === NumberListCustomFieldValueFormat.PERCENT
            ? '%'
            : undefined, type: "decimal", placeholder: placeholder, onBlur: handleSave, onKeyDown: handleKeyDown, value: formatNumberListCustomFieldValue(inputValue, numberFormat, false), onChange: handleInputChange, autoFocus: true, paddingOverrideClasses: classNames('py-p20', numberFormat === NumberListCustomFieldValueFormat.NUMBER && 'pl-p30') }));
};
export default NumberRenderer;
