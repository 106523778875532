/** Convert funding stage to a color. You can tweak as desired. */
export function getStageColor(stage = '') {
    const stageColors = {
        STEALTH: '#64748b',
        PRE_SEED: '#9ca3af',
        SEED: '#10b981',
        SERIES_A: '#3b82f6',
        SERIES_B: '#8b5cf6',
        SERIES_C: '#ec4899',
        SERIES_D: '#f97316',
        SERIES_E: '#eab308',
        SERIES_F: '#14b8a6',
        UNKNOWN: '#6b7280'
    };
    const s = stage.toUpperCase().trim();
    return stageColors[s] || stageColors.UNKNOWN;
}
/** Convert numeric funding to a short label, e.g. $1.2M, $3.4B, etc. */
export function formatMoney(num) {
    if (!num)
        return 'Unknown';
    if (num >= 1e9)
        return `$${(num / 1e9).toFixed(1)}B`;
    if (num >= 1e6)
        return `$${(num / 1e6).toFixed(1)}M`;
    if (num >= 1e3)
        return `$${(num / 1e3).toFixed(1)}K`;
    return `$${num.toFixed(0)}`;
}
/**
 * Process raw quadrant data (one quadrant's companies) into a form we can feed to D3.
 * Returns an array of processed items, plus some min/max for x, y, r.
 */
export function processQuadrantData(companies, currentDate = new Date('2024-12-01')) {
    const parsed = companies
        .map((c) => {
        const stage = c.fundingDetails?.stage ?? c.lastRound ?? 'UNKNOWN';
        const founding = c.details?.foundingDate
            ? new Date(c.details.foundingDate)
            : c.foundingDate
                ? new Date(c.foundingDate)
                : null;
        const yearsSince = founding
            ? (currentDate.getTime() - founding.getTime()) /
                (365.25 * 24 * 3600 * 1000)
            : 0;
        return {
            name: c.name,
            x: Math.max(0, yearsSince),
            y: c.headcount ?? 0,
            totalFunding: c.fundingDetails?.totalRaised
                ? cleanFundingAmount(c.fundingDetails.totalRaised)
                : c.fundingTotal
                    ? cleanFundingAmount(c.fundingTotal)
                    : 0,
            r: 0, // We'll fill in later from scale
            color: getStageColor(stage),
            stage: stage.toUpperCase(),
            stageDisplayName: stage.split('_').join(' ').toUpperCase(),
            logoUrl: c.logoUrl ?? 'https://via.placeholder.com/40'
        };
    })
        .filter((c) => c.x >= 0 && c.y >= 0); // Filter out invalid entries if needed
    if (!parsed.length) {
        return {
            processed: [],
            xExtent: [0, 1],
            yExtent: [0, 1],
            rExtent: [0, 1]
        };
    }
    const xValues = parsed.map((d) => d.x);
    const yValues = parsed.map((d) => d.y);
    const rValues = parsed.map((d) => d.totalFunding);
    const xExtent = [
        Math.min(...xValues),
        Math.max(...xValues)
    ];
    const yExtent = [
        Math.min(...yValues),
        Math.max(...yValues)
    ];
    const rExtent = [
        Math.min(...rValues),
        Math.max(...rValues)
    ];
    return { processed: parsed, xExtent, yExtent, rExtent };
}
function cleanFundingAmount(amount) {
    return parseFloat(amount
        .replace('$', '')
        .replace(',', '')
        .replace('M', '000000')
        .replace('B', '000000000'));
}
