import { datadogLogs } from '@datadog/browser-logs';
import { addDatadogEventProperties, addDatadogRUMGlobalContext, identifyDatadogUser, initializeDatadog, sendDatadogPerformanceEvent, sendDatadogTiming } from './datadog';
import { initializeSignoz } from './signoz';
const MAX_ERROR_MESSAGE_LENGTH = 225280;
export var LoggerEvent;
(function (LoggerEvent) {
    // Auth logging events
    LoggerEvent["TOKEN_LOADING_STARTED"] = "token_loading_started";
    LoggerEvent["TOKEN_LOADING_COMPLETED"] = "token_loading_completed";
    LoggerEvent["USER_LOADING_STARTED"] = "user_loading_started";
    LoggerEvent["USER_LOADING_COMPLETED"] = "user_loading_completed";
    // New generic logging events using view name to differentiate
    LoggerEvent["GRID_READY"] = "grid_ready";
    LoggerEvent["GRID_FIRST_DATA_RENDERED"] = "grid_first_data_rendered";
    LoggerEvent["GRID_FIRST_EXTENDED_DATA_RENDERED"] = "grid_first_extended_data_rendered";
    LoggerEvent["GRID_DATA_LOADING_STARTED"] = "grid_data_loading_started";
    LoggerEvent["GRID_DATA_LOADING_COMPLETED"] = "grid_data_loading_completed";
    LoggerEvent["GRID_DATA_EXTENDED_LOADING_STARTED"] = "grid_data_extended_loading_started";
    LoggerEvent["GRID_DATA_EXTENDED_LOADING_COMPLETED"] = "grid_data_extended_loading_completed";
    LoggerEvent["GRID_NEXT_DATA_LOADING_STARTED"] = "grid_data_next_data_loading_started";
    LoggerEvent["GRID_NEXT_DATA_LOADING_COMPLETED"] = "grid_data_next_data_loading_completed";
    LoggerEvent["GRID_NEXT_EXTENDED_DATA_LOADING_STARTED"] = "grid_data_next_extended_data_loading_started";
    LoggerEvent["GRID_NEXT_EXTENDED_DATA_LOADING_COMPLETED"] = "grid_data_next_extended_data_loading_completed";
    // Logging to debug grid rendering logging with user tab change
    LoggerEvent["USER_CHANGED_TAB"] = "user_changed_tab";
    LoggerEvent["COMPANY_PROFILE_RENDER"] = "company_profile_render";
    LoggerEvent["PERSON_PROFILE_RENDER"] = "person_profile_render";
    // Performance logging for sidebar
    LoggerEvent["SIDEBAR_SEARCH_LOADING_STARTED"] = "sidebar_search_loading_started";
    LoggerEvent["SIDEBAR_SEARCH_LOADED"] = "sidebar_search_loaded";
    LoggerEvent["SIDEBAR_LISTS_LOADING_STARTED"] = "sidebar_lists_loading_started";
    LoggerEvent["SIDEBAR_LISTS_LOADED"] = "sidebar_lists_loaded";
    // Performance logging for board
    LoggerEvent["BOARD_FIRST_COLUMN_RENDERED"] = "board_first_column_rendered";
    LoggerEvent["BOARD_FIRST_CARD_RENDERED"] = "board_first_card_rendered";
    LoggerEvent["RENDER_COMPONENT"] = "render_component";
    LoggerEvent["MODULE_PRELOAD"] = "module_preload";
    LoggerEvent["MODULE_LOADING_STARTED"] = "module_loading_started";
    LoggerEvent["MODULE_LOADING_COMPLETED"] = "module_loading_completed";
})(LoggerEvent || (LoggerEvent = {}));
const initialize = async ({ enableDatadogProxy }) => {
    const { traceId } = initializeDatadog({
        enableDatadogProxy
    });
    initializeSignoz({ traceId });
};
const identifyUser = async ({ name, email, entityUrn }) => {
    identifyDatadogUser({ name, email, entityUrn });
};
export const logger = {
    initialize,
    identifyUser,
    addGlobalContext: addDatadogRUMGlobalContext,
    addEventProperties: addDatadogEventProperties,
    error: (message, context) => {
        datadogLogs.logger.error(message.slice(0, MAX_ERROR_MESSAGE_LENGTH), context);
        // eslint-disable-next-line no-console
        console.error(message, context);
    },
    warning: (message, context) => {
        datadogLogs.logger.warn(message, context);
    },
    info: (message, context) => {
        datadogLogs.logger.log(message, context);
    },
    sendTiming: sendDatadogTiming,
    sendPerformanceEvent: sendDatadogPerformanceEvent
};
