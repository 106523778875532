import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AddColumnLeftIcon from 'assets/harmonic-icons/add-column-left';
import AddColumnRightIcon from 'assets/harmonic-icons/add-column-right';
import TrashcanIcon from 'assets/harmonic-icons/trashcan';
import classNames from 'classnames';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import useFlags from 'hooks/useFlags';
import React, { useState } from 'react';
import { SPLITS } from 'utils/constants';
import { CommonHeaderLayout } from './CommonHeaderLayout';
import { HeaderLabel } from './HeaderLabel';
const HeaderDropdown = ({ open, anchorEl, onClose, popoverWidth, children, filterTerm, onFilterTermChange, filterPlaceholder }) => {
    return (_jsx(Popover, { open: open, anchorEl: anchorEl, onClose: onClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right'
        }, classes: {
            paper: 'bg-transparent p-p20 ml-p20 shadow-none'
        }, PaperProps: {
            style: {
                width: `calc(${popoverWidth}px + var(--spacing-p20) + 4px)`
            }
        }, children: _jsx("div", { className: "w-full", children: _jsx(Dropdown, { filterTerm: filterTerm, onFilterTermChange: onFilterTermChange, filterPlaceholder: filterPlaceholder, children: children }) }) }));
};
export const HeaderWithDropdown = ({ params, showDropdown, children, renderFields, onDeleteFieldClick, customHeaderLabelContent, hideInsertLeftField = false }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverWidth, setPopoverWidth] = useState(0);
    const onHeaderClick = (event) => {
        if (!showDropdown)
            return;
        setPopoverWidth(event.currentTarget.clientWidth);
        setAnchorEl(event.currentTarget);
    };
    const popoverOpen = Boolean(anchorEl);
    const { customTooltip } = params.column.getColDef();
    const tooltipContent = customTooltip && (_jsx("div", { className: "ml-1.5 -mt-0.5", children: _jsx(Tooltip, { title: customTooltip, children: _jsx(InfoOutlinedIcon, { className: "w-4 h-4 text-content-muted", fontSize: "small" }) }) }));
    const closeDropdown = () => {
        setAnchorEl(null);
        setFilterTerm(undefined);
        setTimeout(() => setInsertFieldMode(undefined), 500);
    };
    const { enabled: enableInsertAdjacentToField } = useFlags(SPLITS.enableInsertAdjacentToField);
    const [insertFieldMode, setInsertFieldMode] = useState();
    const [filterTerm, setFilterTerm] = useState();
    return (_jsxs(_Fragment, { children: [_jsx(CommonHeaderLayout, { className: classNames('flex ag-header-cell-text flex-row justify-between w-full h-full items-center', showDropdown && 'cursor-pointer hover:bg-int-overlay-secondary-hover', popoverOpen && 'bg-int-overlay-secondary'), onClick: onHeaderClick, children: customHeaderLabelContent ? (customHeaderLabelContent) : (_jsxs(_Fragment, { children: [_jsx(HeaderLabel, { ...params }), tooltipContent] })) }), (showDropdown || enableInsertAdjacentToField) && (_jsx(HeaderDropdown, { open: popoverOpen, anchorEl: anchorEl, onClose: () => {
                    setAnchorEl(null);
                    setFilterTerm(undefined);
                    setInsertFieldMode(undefined);
                }, popoverWidth: popoverWidth, filterTerm: insertFieldMode ? filterTerm : undefined, onFilterTermChange: insertFieldMode ? setFilterTerm : undefined, filterPlaceholder: insertFieldMode ? 'Search fields' : undefined, children: insertFieldMode ? (renderFields?.(filterTerm ?? '', insertFieldMode, closeDropdown)) : (_jsxs(_Fragment, { children: [children({ close: closeDropdown }), enableInsertAdjacentToField && (_jsxs(_Fragment, { children: [!hideInsertLeftField && (_jsx(ListItem, { primaryIcon: AddColumnLeftIcon, variant: ListVariant.default, label: "Insert left", value: "Insert left", onClick: () => setInsertFieldMode('add-to-left') })), _jsx(ListItem, { primaryIcon: AddColumnRightIcon, variant: ListVariant.default, label: "Insert right", value: "Insert right", onClick: () => {
                                        setInsertFieldMode('add-to-right');
                                    } }), onDeleteFieldClick && (_jsx(ListItem, { primaryIcon: TrashcanIcon, variant: ListVariant.default, label: "Delete field", value: "Delete field", destructive: true, onMouseDown: () => {
                                        onDeleteFieldClick();
                                        closeDropdown();
                                    } }))] }))] })) }))] }));
};
