export var CompanyFilterTabs;
(function (CompanyFilterTabs) {
    CompanyFilterTabs["GENERAL"] = "company_general";
    CompanyFilterTabs["FUNDING"] = "company_funding";
    CompanyFilterTabs["TEAM"] = "company_team";
    CompanyFilterTabs["PRODUCT"] = "company_product";
    CompanyFilterTabs["CUSTOM"] = "company_custom";
})(CompanyFilterTabs || (CompanyFilterTabs = {}));
export var PeopleFilterTabs;
(function (PeopleFilterTabs) {
    PeopleFilterTabs["FILTER"] = "filter";
    PeopleFilterTabs["GENERAL"] = "people_general";
    PeopleFilterTabs["CURRENT_EXP"] = "people_current_exp";
    PeopleFilterTabs["PAST_EXP"] = "people_past_exp";
    PeopleFilterTabs["EDUCATION"] = "people_education";
    PeopleFilterTabs["CUSTOM"] = "people_custom";
})(PeopleFilterTabs || (PeopleFilterTabs = {}));
export var SearchStateFilter;
(function (SearchStateFilter) {
    SearchStateFilter["VIEWED"] = "VIEWED";
    SearchStateFilter["UNVIEWED"] = "UNVIEWED";
    SearchStateFilter["NONE"] = "NONE";
})(SearchStateFilter || (SearchStateFilter = {}));
export var SectorTagsRowTypes;
(function (SectorTagsRowTypes) {
    SectorTagsRowTypes["INDUSTRY"] = "industry";
    SectorTagsRowTypes["PRODUCT"] = "product";
    SectorTagsRowTypes["CUSTOMER"] = "customer";
    SectorTagsRowTypes["TECHNOLOGY_TYPE"] = "technology_type";
    SectorTagsRowTypes["MARKET_VERTICAL"] = "market_vertical";
})(SectorTagsRowTypes || (SectorTagsRowTypes = {}));
export var FilterGroupGeneratorId;
(function (FilterGroupGeneratorId) {
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_FUNDING_STAGE"] = "search_v2_company_funding_stage";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_FUNDING_TOTAL_RANGE"] = "search_v2_company_funding_total_range";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_FUNDING_LAST_ROUND_TOTAL_RANGE"] = "search_v2_company_funding_last_round_total_range";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_FUNDING_INCLUDE_INVESTORS"] = "search_v2_company_funding_include_investors";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_FUNDING_EXCLUDE_INVESTORS"] = "search_v2_company_funding_exclude_investors";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_FUNDING_LAST_ROUND_DATE"] = "search_v2_company_funding_last_round_date";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_FUNDING_FOUNDATION_DATE"] = "search_v2_company_funding_foundation_date";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_TEAM_HEADCOUNT_RANGE"] = "search_v2_company_team_headcount_range";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_TEAM_HEADCOUNT_PERCENT_GROWTH_RANGE"] = "search_v2_company_team_headcount_percent_growth_range";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_TEAM_HIGHLIGHTS"] = "search_v2_company_team_highlights";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_SECTOR_INCLUDE_BUSINESS_TAGS"] = "search_v2_company_sector_include_business_tags";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_SECTOR_EXCLUDE_BUSINESS_TAGS"] = "search_v2_company_sector_exclude_business_tags";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_SECTOR_INCLUDE_INDUSTRY_TAGS"] = "search_v2_company_sector_include_industry_tags";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_SECTOR_EXCLUDE_INDUSTRY_TAGS"] = "search_v2_company_sector_exclude_industry_tags";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_SECTOR_INCLUDE_TECHNOLOGY_TAGS"] = "search_v2_company_sector_include_technology_tags";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_SECTOR_EXCLUDE_TECHNOLOGY_TAGS"] = "search_v2_company_sector_exclude_technology_tags";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_SECTOR_PRESET"] = "search_v2_company_sector_preset";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_SECTOR_INCLUDE_TAGS"] = "search_v2_company_sector_include_tags";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_SECTOR_EXCLUDE_TAGS"] = "search_v2_company_sector_exclude_tags";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_SECTOR_INCLUDE_KEYWORDS"] = "search_v2_company_sector_include_keywords";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_SECTOR_EXCLUDE_KEYWORDS"] = "search_v2_company_sector_exclude_keywords";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_SECTOR_MATCH_KEYWORDS"] = "search_v2_company_sector_match_keywords";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_SECTOR_PRODUCT_CATEGORIES"] = "search_v2_company_sector_product_categories";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE"] = "search_v2_company_team_founding_experience";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE"] = "search_v2_company_team_member_experience";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_LIST_AND_MORE_INCLUDE_FROM_WATCHLIST"] = "search_v2_company_list_and_more_include_from_watchlist";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_LIST_AND_MORE_EXCLUDE_FROM_WATCHLIST"] = "search_v2_company_list_and_more_exclude_from_watchlist";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_LIST_AND_MORE_INITIALIZED_DATE"] = "search_v2_company_list_and_more_initialized_date";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_LIST_AND_MORE_UPDATED_DATE"] = "search_v2_company_list_and_more_updated_date";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_LIST_AND_MORE_ACCELERATORS"] = "search_v2_company_list_and_more_accelerators";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_LIST_AND_MORE_LOCATION"] = "search_v2_company_list_and_more_location";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_LIST_AND_MORE_INCLUDE_FROM_PEOPLE_LIST"] = "search_v2_company_list_and_more_include_from_people_list";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_LIST_AND_MORE_EXCLUDE_FROM_PEOPLE_LIST"] = "search_v2_company_list_and_more_exclude_from_people_list";
    FilterGroupGeneratorId["SEARCH_V2_COMPANY_TEAM_CORRESPONDENCES"] = "search_v2_company_team_correspondences";
    //People searches
    FilterGroupGeneratorId["SEARCH_V2_PEOPLE_CURRENT_EXPERIENCE"] = "search_v2_people_current_experience";
    FilterGroupGeneratorId["SEARCH_V2_PEOPLE_EDUCATION"] = "search_v2_people_education_experience";
    FilterGroupGeneratorId["SEARCH_V2_PEOPLE_PAST_EXPERIENCE"] = "search_v2_people_past_experience";
    FilterGroupGeneratorId["SEARCH_V2_PEOPLE_GENERAL_HIGHLIGHTS"] = "search_v2_people_general_highlights";
    FilterGroupGeneratorId["SEARCH_V2_PEOPLE_GENERAL_LOCATION"] = "search_v2_people_general_location";
    FilterGroupGeneratorId["SEARCH_V2_PEOPLE_GENERAL_CONNECTIONS"] = "search_v2_people_general_connections";
    FilterGroupGeneratorId["SEARCH_V2_PEOPLE_GENERAL_CORRESPONDENCES"] = "search_v2_people_general_correspondences";
    FilterGroupGeneratorId["SEARCH_V2_PEOPLE_GENERAL_PEOPLE_LISTS"] = "search_v2_people_general_people_lists";
    FilterGroupGeneratorId["SEARCH_V2_PEOPLE_GENERAL_LINKEDIN_HEADLINES"] = "search_v2_people_general_linkedin_headlines";
    // Accelerators
    FilterGroupGeneratorId["SEARCH_V2_ACCELERATOR"] = "search_v2_accelerator";
    // Semantic search
    FilterGroupGeneratorId["SEARCH_V2_SEMANTIC_SEARCH"] = "search_v2_semantic_search_filter";
    // Similar companies search
    FilterGroupGeneratorId["SEARCH_V2_SIMILAR_COMPANY_SEARCH"] = "search_v2_similar_company_search_filter";
})(FilterGroupGeneratorId || (FilterGroupGeneratorId = {}));
export const FilterTabToFilterGroupGeneratorId = {
    [CompanyFilterTabs.GENERAL]: [
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_LOCATION,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_FOUNDATION_DATE,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INITIALIZED_DATE,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INCLUDE_FROM_WATCHLIST,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_EXCLUDE_FROM_WATCHLIST,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_UPDATED_DATE
    ],
    [CompanyFilterTabs.FUNDING]: [
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_STAGE,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_TOTAL_RANGE,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_LAST_ROUND_TOTAL_RANGE,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_INCLUDE_INVESTORS,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_EXCLUDE_INVESTORS,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_FUNDING_LAST_ROUND_DATE,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_ACCELERATORS,
        FilterGroupGeneratorId.SEARCH_V2_ACCELERATOR
    ],
    [CompanyFilterTabs.TEAM]: [
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_HEADCOUNT_RANGE,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_HEADCOUNT_PERCENT_GROWTH_RANGE,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_HIGHLIGHTS,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_INCLUDE_FROM_PEOPLE_LIST,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_LIST_AND_MORE_EXCLUDE_FROM_PEOPLE_LIST,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_TEAM_CORRESPONDENCES
    ],
    [CompanyFilterTabs.PRODUCT]: [
        FilterGroupGeneratorId.SEARCH_V2_SEMANTIC_SEARCH,
        FilterGroupGeneratorId.SEARCH_V2_SIMILAR_COMPANY_SEARCH,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_KEYWORDS,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_KEYWORDS,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_MATCH_KEYWORDS,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_PRESET,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_INDUSTRY_TAGS,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_INDUSTRY_TAGS,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_BUSINESS_TAGS,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_BUSINESS_TAGS,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_TECHNOLOGY_TAGS,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_TECHNOLOGY_TAGS,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_INCLUDE_TAGS,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_EXCLUDE_TAGS,
        FilterGroupGeneratorId.SEARCH_V2_COMPANY_SECTOR_PRODUCT_CATEGORIES
    ],
    [CompanyFilterTabs.CUSTOM]: [],
    [PeopleFilterTabs.FILTER]: [],
    [PeopleFilterTabs.CURRENT_EXP]: [
        FilterGroupGeneratorId.SEARCH_V2_PEOPLE_CURRENT_EXPERIENCE
    ],
    [PeopleFilterTabs.PAST_EXP]: [
        FilterGroupGeneratorId.SEARCH_V2_PEOPLE_PAST_EXPERIENCE
    ],
    [PeopleFilterTabs.EDUCATION]: [
        FilterGroupGeneratorId.SEARCH_V2_PEOPLE_EDUCATION
    ],
    [PeopleFilterTabs.GENERAL]: [
        FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_HIGHLIGHTS,
        FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_LOCATION,
        FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CONNECTIONS,
        FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_CORRESPONDENCES,
        FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_LINKEDIN_HEADLINES,
        FilterGroupGeneratorId.SEARCH_V2_PEOPLE_GENERAL_PEOPLE_LISTS
    ],
    [PeopleFilterTabs.CUSTOM]: []
};
export var FundingLastRoundDateOptions;
(function (FundingLastRoundDateOptions) {
    FundingLastRoundDateOptions["PAST_FOUR_TO_TWELVE_MONTHS"] = "past_four_to_twelve_months";
    FundingLastRoundDateOptions["PAST_TWELVE_TO_TWENTY_FOUR_MONTHS"] = "past_twelve_to_twenty_four_months";
    FundingLastRoundDateOptions["CUSTOM"] = "custom_rolling";
})(FundingLastRoundDateOptions || (FundingLastRoundDateOptions = {}));
export var CompanyInitializedOrUpdatedDateOptions;
(function (CompanyInitializedOrUpdatedDateOptions) {
    CompanyInitializedOrUpdatedDateOptions["PAST_SIX_MONTHS"] = "past_six_months";
    CompanyInitializedOrUpdatedDateOptions["PAST_THIRTY_DAYS"] = "past_thirty_days";
    CompanyInitializedOrUpdatedDateOptions["CUSTOM"] = "custom_rolling";
})(CompanyInitializedOrUpdatedDateOptions || (CompanyInitializedOrUpdatedDateOptions = {}));
export var CompanyFoundationDateOptions;
(function (CompanyFoundationDateOptions) {
    CompanyFoundationDateOptions["PAST_YEAR"] = "past_year";
    CompanyFoundationDateOptions["PAST_TWO_YEARS"] = "past_two_years";
    CompanyFoundationDateOptions["CUSTOM"] = "custom_rolling";
})(CompanyFoundationDateOptions || (CompanyFoundationDateOptions = {}));
export var HeadcountPercentGrowthTimeline;
(function (HeadcountPercentGrowthTimeline) {
    HeadcountPercentGrowthTimeline["THREE_MONTHS"] = "three_months";
    HeadcountPercentGrowthTimeline["SIX_MONTHS"] = "six_months";
    HeadcountPercentGrowthTimeline["ONE_YEAR"] = "one_year";
})(HeadcountPercentGrowthTimeline || (HeadcountPercentGrowthTimeline = {}));
// Default value for headcount data and funding date
// Note: If value equals the default value, those filters are ignored altogether.
// Lots of companies don't have headcount or funding data. having a default headcount range filter effects results. So, default are treated as none
export const companyTeamHeadcountRangeDefaultValue = {
    range: [0, Number.POSITIVE_INFINITY]
};
export const companyTeamHeadcountPercentGrowthDefaultValue = {
    timeline: HeadcountPercentGrowthTimeline.THREE_MONTHS,
    range: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY]
};
export const companyFundingTotalDefaultValue = {
    range: [0, Number.POSITIVE_INFINITY],
    include_undisclosed: false
};
const isSelectedTypeaheadResultType = (typeaheadResult) => {
    return typeaheadResult.name !== undefined;
};
// TODO(APPS-2812):
// Utility function to maintain backwards compatibility with searches created prior to searching against the id. These searches only store the company name(s) as arguments.
// We can remove this once we have backfilled all existing searches that use the SEARCH_V2_COMPANY_TEAM_MEMBER_EXPERIENCE and SEARCH_V2_COMPANY_TEAM_FOUNDING_EXPERIENCE generators.
export const convertStringArrayToSelectedTypeaheadResultArray = (input) => {
    return (input?.map((item) => isSelectedTypeaheadResultType(item) ? item : { name: item }) ?? []);
};
export const fundingSliderStepRanges = [
    0,
    25000,
    50000,
    75000,
    100000,
    250000,
    500000,
    750000,
    //1 million
    1000000,
    2000000,
    3000000,
    5000000,
    7500000,
    //10 million
    10000000,
    15000000,
    25000000,
    50000000,
    75000000,
    100000000,
    1000000000,
    Number.POSITIVE_INFINITY
];
export const headcountStepRanges = [
    0,
    1,
    5,
    10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    100,
    250,
    500,
    750,
    1000,
    5000,
    10000,
    50_000,
    100_000,
    500_000,
    Number.POSITIVE_INFINITY
];
export const headcountGrowthStepRanges = [
    Number.NEGATIVE_INFINITY,
    -100,
    -50,
    -25,
    0,
    25,
    50,
    100,
    200,
    300,
    500,
    1000,
    Number.POSITIVE_INFINITY
];
export var BackendCustomRangeModeType;
(function (BackendCustomRangeModeType) {
    BackendCustomRangeModeType["CUSTOM"] = "custom";
    BackendCustomRangeModeType["CUSTOM_ROLLING"] = "custom_rolling";
})(BackendCustomRangeModeType || (BackendCustomRangeModeType = {}));
export var ConnectionScope;
(function (ConnectionScope) {
    ConnectionScope["ANY"] = "ANY";
    ConnectionScope["MY_CONNECTIONS"] = "MY_CONNECTIONS";
    ConnectionScope["TEAM_CONNECTIONS"] = "TEAM_CONNECTIONS";
    ConnectionScope["NO_CONNECTIONS"] = "NO_CONNECTIONS";
})(ConnectionScope || (ConnectionScope = {}));
export var TimeUnit;
(function (TimeUnit) {
    TimeUnit["TODAY"] = "TODAY";
    TimeUnit["DAYS"] = "DAYS";
    TimeUnit["WEEKS"] = "WEEKS";
    TimeUnit["MONTHS"] = "MONTHS";
    TimeUnit["YEARS"] = "YEARS";
    TimeUnit["INFINITY"] = "INFINITY";
})(TimeUnit || (TimeUnit = {}));
export var DateRangeShortHand;
(function (DateRangeShortHand) {
    DateRangeShortHand["PAST_WEEK"] = "past_week";
    DateRangeShortHand["PAST_TWO_WEEKS"] = "past_two_weeks";
    DateRangeShortHand["PAST_ONE_MONTH"] = "past_one_month";
    DateRangeShortHand["PAST_THREE_MONTHS"] = "past_three_months";
    DateRangeShortHand["PAST_FOUR_MONTHS"] = "past_four_months";
    DateRangeShortHand["PAST_SIX_MONTHS"] = "past_six_months";
    DateRangeShortHand["PAST_THIRTY_DAYS"] = "past_thirty_days";
    DateRangeShortHand["PAST_YEAR"] = "past_year";
    DateRangeShortHand["PAST_TWO_YEARS"] = "past_two_years";
    DateRangeShortHand["PAST_FOUR_TO_TWELVE_MONTHS"] = "past_four_to_twelve_months";
    DateRangeShortHand["PAST_TWELVE_TO_TWENTY_FOUR_MONTHS"] = "past_twelve_to_twenty_four_months";
    DateRangeShortHand["CUSTOM"] = "custom";
    DateRangeShortHand["CUSTOM_ROLLING"] = "custom_rolling";
})(DateRangeShortHand || (DateRangeShortHand = {}));
export const DEFAULT_DATE_SELECTOR_OPTIONS = [
    { value: TimeUnit.TODAY, label: 'Today' },
    { value: TimeUnit.DAYS, label: 'Days ago' },
    { value: TimeUnit.WEEKS, label: 'Weeks ago' },
    { value: TimeUnit.MONTHS, label: 'Months ago' },
    { value: TimeUnit.YEARS, label: 'Years ago' }
];
export const YEAR_ONLY_DATE_SELECTOR_OPTIONS = [
    { value: TimeUnit.TODAY, label: 'This year' },
    { value: TimeUnit.YEARS, label: 'Years ago' }
];
