import { CircleCheckIcon, EyeClosedIcon, EyeOpenIcon, HeartIcon, PencilIcon, PlusIcon, TrashcanIcon } from 'assets/harmonic-icons';
import CircleBanSign from 'assets/harmonic-icons/circle-ban-sign';
import HeartFilledIcon from 'assets/harmonic-icons/heart-filled';
export var EntityActionType;
(function (EntityActionType) {
    EntityActionType["TOGGLE_LIKE"] = "toggleLike";
    EntityActionType["TOGGLE_HIDE"] = "toggleHide";
    EntityActionType["TOGGLE_VIEW"] = "toggleView";
    EntityActionType["ADD_TO_WATCHLIST"] = "addToWatchlist";
    EntityActionType["REMOVE_FROM_CURRENT_LIST"] = "removeFromCurrentList";
    EntityActionType["EDIT_CUSTOM_FIELD_VALUE"] = "editCustomFieldValue";
})(EntityActionType || (EntityActionType = {}));
const ENTITY_ACTIONS_ICON_MAP = {
    [EntityActionType.ADD_TO_WATCHLIST]: {
        performed: PlusIcon,
        notPerformed: PlusIcon
    },
    [EntityActionType.REMOVE_FROM_CURRENT_LIST]: {
        performed: TrashcanIcon,
        notPerformed: TrashcanIcon
    },
    [EntityActionType.TOGGLE_HIDE]: {
        performed: CircleCheckIcon,
        notPerformed: CircleBanSign
    },
    [EntityActionType.TOGGLE_LIKE]: {
        performed: HeartFilledIcon,
        notPerformed: HeartIcon
    },
    [EntityActionType.TOGGLE_VIEW]: {
        performed: EyeClosedIcon,
        notPerformed: EyeOpenIcon
    },
    [EntityActionType.EDIT_CUSTOM_FIELD_VALUE]: {
        performed: PencilIcon,
        notPerformed: PencilIcon
    }
};
export const getIconForAction = (actionType, isPerformed) => {
    return isPerformed
        ? ENTITY_ACTIONS_ICON_MAP[actionType].performed
        : ENTITY_ACTIONS_ICON_MAP[actionType].notPerformed;
};
