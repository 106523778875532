import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from 'common';
import Avatar from 'harmonic-components/Avatar/Avatar';
import { getFundingTypeDisplayName, SearchValueToDisplayNameMap } from 'interfaces/SearchModel/Search';
import React from 'react';
import { getFormattedFundingTotal } from 'utils/funding';
import DiagramWrapper from './common/DiagramWrapper';
import { MarketMapDiagram } from './MarketMapDiagram';
export const MarketMapCompanyCard = ({ company }) => {
    return (_jsxs("div", { className: "bg-surface-nested rounded-xl", children: [_jsxs("div", { className: "p-p60 flex flex-row border-b border-solid border-border items-center", children: [_jsx(Avatar, { src: company.logoUrl, alt: 'company logo', kind: "logo", size: "x-large" }), _jsxs("div", { className: "flex flex-col pl-p50", children: [_jsx("span", { className: "typography-label-default-default text-content-title", children: company.name }), _jsxs("span", { className: "text-content-weak typography-label-small-default", children: [!!company.lastRound &&
                                        getFundingTypeDisplayName(company.lastRound), !!company.lastRound && !!company.location && ' • ', company.location, getFormattedFundingTotal(company.fundingTotal) !==
                                        SearchValueToDisplayNameMap.UNKNOWN_VALUE &&
                                        ' • ' +
                                            getFormattedFundingTotal(company.fundingTotal) +
                                            ' total funding'] })] })] }), _jsxs("h4", { className: "typography-paragraph-small-default text-content-sentiment-neutral p-p60", children: [_jsx("p", { className: "pb-p50", children: company.description }), _jsxs("div", { className: "grid grid-cols-2 gap-g20", children: [company.headcount > 0 && (_jsxs("div", { className: "flex flex-col gap-g30 rounded-lg bg-surface-default p-p40", children: [_jsx("span", { className: "typography-paragraph-extraSmall-default text-content-weak", children: "Headcount" }), _jsx("span", { className: "typography-label-small-default text-content-title", children: company.headcount })] })), Boolean(company.primaryFocus) && (_jsxs("div", { className: "flex flex-col gap-g30 rounded-lg bg-surface-default p-p40", children: [_jsx("span", { className: "typography-paragraph-extraSmall-default text-content-weak", children: "Primary focus" }), _jsx("span", { className: "typography-label-small-default text-content-title", children: company.primaryFocus })] })), Boolean(company.keyDifferentiators) && (_jsxs("div", { className: "flex flex-col gap-g30 rounded-lg bg-surface-default p-p40", children: [_jsx("span", { className: "typography-paragraph-extraSmall-default text-content-weak", children: "Key differentiators" }), _jsx("span", { className: "typography-label-small-default text-content-title", children: company.keyDifferentiators })] })), company.marketSegments?.length > 0 && (_jsxs("div", { className: "flex flex-col gap-g30 rounded-lg bg-surface-default p-p40", children: [_jsx("span", { className: "typography-paragraph-extraSmall-default text-content-weak", children: "Market segments" }), _jsx("span", { className: "typography-label-small-default text-content-title", children: company.marketSegments?.map((segment) => (_jsx(Badge, { label: segment }, segment))) })] }))] })] })] }));
};
const MarketMapSegment = ({ category }) => {
    return (_jsx("div", { children: _jsx("div", { className: "grid grid-cols-2 gap-2 ", children: category.companies.map((company) => (_jsx(MarketMapCompanyCard, { company: company }, company.name))) }) }));
};
export const MarketMapSegments = ({ content }) => {
    const { marketMap } = content;
    return (_jsx("div", { className: "flex flex-col gap-g80", children: marketMap.map((category, index) => (_jsx("div", { children: _jsx(DiagramWrapper, { title: category.name, children: _jsxs("div", { className: "flex flex-col gap-g80 p-p50", children: [_jsx(MarketMapDiagram, { content: {
                                quadrantName: category.name,
                                companies: category.companies
                            } }), _jsx(MarketMapSegment, { category: category })] }) }) }, index))) }));
};
