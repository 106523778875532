import { gql } from '@apollo/client';
export const UPSERT_USER_VIEW_RECORD_FOR_COMPANY = gql `
  mutation UpsertUserViewRecordsForCompanyUrns($urns: [CompanyUrn]!) {
    upsertUserViewRecordsForCompanyUrns(urns: $urns) {
      viewRecords {
        entityUrn
        viewRecord {
          createdAt
          updatedAt
        }
      }
    }
  }
`;
