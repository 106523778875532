import gql from 'graphql-tag';
import { DATE_LIST_CUSTOM_FIELD_METADATA_FRAGMENT, NUMBER_LIST_CUSTOM_FIELD_METADATA_FRAGMENT, PERSON_LIST_CUSTOM_FIELD_METADATA_FRAGMENT, SELECT_LIST_CUSTOM_FIELD_METADATA_FRAGMENT } from './getWatchlist';
const PERSON_LIST_NAMED_VIEW_FRAGMENT = gql `
  fragment PersonListNamedView on PersonListNamedView {
    id
    entityUrn
    name
    visibleColumns
    searchQuery
    displayType
    groupByField {
      urn
    }
    hideEmptyColumns
  }
`;
export const PeopleCustomFieldsFragment = gql `
  fragment PeopleCustomFields on PeopleListCustomField {
    __typename
    urn
    name
    type
    createdAt
    defaultValue {
      ... on NumberListCustomFieldValue {
        numberDefaultValue: value
      }
      ... on TextCustomFieldValue {
        textDefaultValue: value
      }
      ... on SingleSelectCustomFieldValue {
        singleSelectDefaultValue: value
      }
      ... on MultiSelectCustomFieldValue {
        multiSelectDefaultValue: value
      }
      ... on PersonSelectCustomFieldDefaultValue {
        personDefaultValue: value
        personDefaultValueMode: mode
      }
      ... on DateListCustomFieldDefaultValue {
        dateDefaultValue: value
        dateDefaultValueMode: mode
      }
      ... on WebsiteListCustomFieldValue {
        websiteDefaultValue: value
      }
      ... on CheckboxListCustomFieldValue {
        checkboxDefaultValue: value
      }
      ... on StatusListCustomFieldValue {
        statusDefaultValue: value
      }
    }
    metadata {
      __typename
      ... on SelectListCustomFieldMetadata {
        ...SelectListCustomFieldMetadata
      }
      ... on NumberListCustomFieldMetadata {
        ...NumberListCustomFieldMetadata
      }
      ... on DateListCustomFieldMetadata {
        ...DateListCustomFieldMetadata
      }
      ... on PersonListCustomFieldMetadata {
        ...PersonListCustomFieldMetadata
      }
    }
  }
  ${PERSON_LIST_CUSTOM_FIELD_METADATA_FRAGMENT}
  ${SELECT_LIST_CUSTOM_FIELD_METADATA_FRAGMENT}
  ${NUMBER_LIST_CUSTOM_FIELD_METADATA_FRAGMENT}
  ${DATE_LIST_CUSTOM_FIELD_METADATA_FRAGMENT}
`;
export const GET_PEOPLE_WATCHLIST_FRAGMENT = gql `
  fragment PeopleWatchlist on PeopleWatchlist {
    owner {
      ... on User {
        entityUrn
        email
      }
      ... on Customer {
        identifier
        name
      }
    }
    id
    entityUrn
    name
    sharedWithTeam
    userWatchlistType
    namedViewsOrder
    customFields {
      ...PeopleCustomFields
    }
    namedViews {
      ...PersonListNamedView
    }
  }
  ${PeopleCustomFieldsFragment}
  ${PERSON_LIST_NAMED_VIEW_FRAGMENT}
`;
export const GET_PEOPLE_WATCHLISTS = gql `
  query GetPeopleWatchlists {
    getPeopleWatchlistsForTeam {
      ...PeopleWatchlist
    }
  }
  ${GET_PEOPLE_WATCHLIST_FRAGMENT}
`;
