import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PersonListCustomFieldValueMode } from '__generated__/graphql';
import { colorOptions } from 'components/Dashboard/Grids/EditField/SelectOptions/EditOptionItem';
import Button from 'harmonic-components/Button/Button';
import ListItem, { ListType, ListVariant } from 'harmonic-components/ListItem/ListItem';
import SearchInput from 'harmonic-components/SearchInput/SearchInput';
import useTeamMembers from 'hooks/useTeamMembers';
import { useMemo, useState } from 'react';
import { getNthColor } from 'utils/colors';
const EditCustomFieldsPersonValue = ({ customField, onSave, onCancel, onClear, recalculatePopoverPosition }) => {
    const [filterTerm, setFilterTerm] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleOnFilterTermChange = (value) => {
        setFilterTerm(value);
        recalculatePopoverPosition();
    };
    const { activeTeamMembers } = useTeamMembers({ fetchPolicy: 'cache-only' });
    const options = useMemo(() => {
        return (activeTeamMembers?.map((member, i) => ({
            urn: member?.user?.entityUrn,
            name: member?.user?.name,
            color: getNthColor(colorOptions, i)
        })) ?? []);
    }, [activeTeamMembers]);
    const filteredOptions = useMemo(() => {
        return options
            .filter((option) => option.name?.toLowerCase().includes(filterTerm.toLowerCase()))
            .filter((option) => Boolean(option.name) && Boolean(option.urn));
    }, [options, filterTerm]);
    const isSingleSelect = useMemo(() => {
        return (customField.metadata?.__typename === 'PersonListCustomFieldMetadata' &&
            customField.metadata?.mode === PersonListCustomFieldValueMode.SINGLE);
    }, [customField]);
    const handleOnChange = async (value) => {
        if (isSingleSelect) {
            setSelectedOptions([value]);
            return savePersonValue([value]);
        }
        if (selectedOptions.includes(value)) {
            setSelectedOptions((prev) => prev.filter((option) => option !== value));
        }
        else {
            setSelectedOptions((prev) => [...prev, value]);
        }
    };
    const handleOnCancel = () => {
        setSelectedOptions([]);
        onCancel();
    };
    const handleOnSave = async () => {
        return savePersonValue(selectedOptions);
    };
    const handleOnClear = async () => {
        setLoading(true);
        await onClear();
        setLoading(false);
    };
    const savePersonValue = async (values) => {
        setLoading(true);
        await onSave(values);
        setSelectedOptions([]);
        setLoading(false);
    };
    return (_jsxs("div", { className: "w-full flex flex-col max-h-96", children: [_jsx(SearchInput, { fullWidth: true, placeholder: "Search", value: filterTerm, onChange: handleOnFilterTermChange }), _jsxs("div", { className: "mt-p30 flex-1 overflow-y-auto min-h-0", children: [filteredOptions
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((option) => (_jsx("div", { className: "w-full", children: _jsx(ListItem, { type: ListType.default, variant: isSingleSelect ? ListVariant.tag : ListVariant.tagCheckbox, label: option.name, value: option.name, onChange: () => handleOnChange(option.urn), onClick: () => handleOnChange(option.urn), 
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            color: option.color, selected: selectedOptions.includes(option.urn), disabled: loading, loading: loading && selectedOptions.includes(option.urn) ? true : false }, option.urn) }, option.urn))), !!isSingleSelect && (_jsx(ListItem, { variant: ListVariant.default, label: "None", value: "None", onClick: handleOnClear, loading: loading, disabled: loading }))] }), !isSingleSelect && (_jsxs("div", { className: "flex gap-g40 mt-p50", children: [_jsx(Button, { fullWidth: true, type: "secondary", emphasis: "medium", size: "compact", label: "Cancel", onClick: handleOnCancel, isDisabled: loading }), _jsx(Button, { fullWidth: true, type: "primary", emphasis: "high", size: "compact", label: "Save", onClick: handleOnSave, loading: loading, isDisabled: loading, dataTestId: "EditCustomFieldsPersonValue-SaveButton" })] }))] }));
};
export default EditCustomFieldsPersonValue;
