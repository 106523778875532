import { isEqual, isNil } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import { SearchType } from '../../../__generated__/graphql';
import client from '../../../config/client';
import { appStore } from '../../../hooks/useAppStore';
import { SAVED_SEARCH_FRAGMENT } from '../../../queries/getSavedSearch';
import { getCompanySavedSearchColumnOrders, getPeopleSavedSearchColumnOrders } from '../../../utils/namedViews';
class SavedSearchModel {
    savedSearch = null;
    localSavedSearch = null;
    error = undefined;
    apolloSubscription = null;
    zustandSubscription = null;
    constructor(data) {
        this.savedSearch = data;
        this.observeZustandStore();
        this.observeApolloFragment();
        makeAutoObservable(this);
    }
    /**
     * Returns the computed tableColumns of the current saved search
     */
    get tableColumns() {
        let columnHeaders = [];
        const visibleColumns = this.savedSearchWithDraftState?.visibleColumns ?? [];
        // TODO: refactor into separate stores for each type
        if (this.savedSearchWithDraftState?.search_type === SearchType.COMPANIES_LIST) {
            columnHeaders = getCompanySavedSearchColumnOrders(visibleColumns ?? []);
        }
        else if (this.savedSearchWithDraftState?.search_type === SearchType.PERSONS) {
            columnHeaders = getPeopleSavedSearchColumnOrders(visibleColumns ?? []);
        }
        return columnHeaders;
    }
    get hasChanges() {
        return ['name', 'searchQuery', 'visibleColumns'].some((field) => {
            const localValue = 
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            this.localSavedSearch?.[field];
            const currentValue = 
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            this.savedSearch?.[field];
            return !isNil(localValue) && !isEqual(localValue, currentValue);
        });
    }
    deleteDraftState(entityUrn) {
        this.localSavedSearch = null;
        appStore.getState().localSearch.removeLocalSearch(entityUrn);
    }
    observeZustandStore() {
        if (this.zustandSubscription) {
            this.zustandSubscription();
        }
        if (!this.savedSearch)
            return;
        const entityUrn = `urn:harmonic:saved_search:${this.savedSearch.id}`;
        // Check if the saved search has a draft state in the local search
        const localSavedSearch = appStore.getState().localSearch.searchByKey[entityUrn];
        // Set local saved search state
        if (localSavedSearch && 'type' in localSavedSearch) {
            this.localSavedSearch = localSavedSearch;
        }
        else {
            this.localSavedSearch = null;
        }
        this.zustandSubscription = appStore.subscribe((state) => state.localSearch.searchByKey[entityUrn], (localSavedSearch) => {
            // Assert that the state is a LocalSavedSearch
            if (localSavedSearch && 'type' in localSavedSearch) {
                runInAction(() => {
                    this.localSavedSearch = localSavedSearch;
                });
            }
            else {
                runInAction(() => {
                    this.localSavedSearch = null;
                });
            }
        });
    }
    observeApolloFragment() {
        if (this.apolloSubscription) {
            this.apolloSubscription.unsubscribe();
        }
        const from = client.cache.identify({
            __typename: 'SavedSearch',
            id: this.savedSearch?.id
        });
        if (!from) {
            throw new Error(`Saved search with id ${this.savedSearch?.id} not found`);
        }
        const observableFragment = client.watchFragment({
            fragment: SAVED_SEARCH_FRAGMENT,
            fragmentName: 'SavedSearchMetadata',
            variables: {
                id: this.savedSearch?.id
            },
            from
        });
        this.apolloSubscription = observableFragment.subscribe({
            next: (result) => {
                runInAction(() => {
                    if (result.complete) {
                        this.savedSearch = result.data;
                    }
                });
            },
            error: (err) => {
                runInAction(() => {
                    this.error = err;
                });
            }
        });
    }
    /**
     * Returns the computed saved search with the draft state
     */
    get savedSearchWithDraftState() {
        return this.formatSavedSearch(this.savedSearch, this.localSavedSearch);
    }
    formatSavedSearch(savedSearch, localSavedSearch) {
        if (savedSearch && localSavedSearch) {
            return {
                ...savedSearch,
                name: localSavedSearch.name ?? savedSearch?.name,
                searchQuery: localSavedSearch.searchQuery ?? savedSearch?.searchQuery,
                visibleColumns: localSavedSearch.visibleColumns ?? savedSearch?.visibleColumns
            };
        }
        return savedSearch;
    }
}
export default SavedSearchModel;
