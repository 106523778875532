// parseRecentMovesData.ts
export function prepareRecentMovesData(talentFlow) {
    const hiresRaw = talentFlow.recentHires?.highlighted ?? [];
    const hiresCount = (talentFlow.recentHires?.remainingCount ?? 0) + hiresRaw.length;
    const hires = hiresRaw.map((h) => ({
        name: h.name ?? 'Unknown',
        title: h.title ?? 'Unknown Title',
        previousTitle: h.previousTitle ?? 'Unknown Title',
        previousCompany: h.previousCompany ?? 'Unknown Company',
        previousCompanyProfileUrl: h.previousCompanyProfileUrl,
        logoUrl: h.previousCompanyLogoUrl,
        displayTitle: `${h.previousTitle ?? 'Unknown Title'}\nhired as: ${h.title ?? 'Unknown Title'}`,
        hoverText: `Previously at ${h.previousCompany ?? 'Unknown Company'}`
    }));
    const departuresRaw = talentFlow.recentOutflow?.highlighted ?? [];
    const departuresCount = (talentFlow.recentOutflow?.remainingCount ?? 0) + departuresRaw.length;
    const departures = departuresRaw.map((d) => ({
        name: d.name ?? 'Unknown',
        title: d.title ?? 'Unknown Title',
        nextTitle: d.nextTitle ?? 'Unknown Title',
        nextCompany: d.nextCompany ?? 'Unknown Company',
        nextCompanyProfileUrl: d.nextCompanyProfileUrl,
        logoUrl: d.nextCompanyLogoUrl,
        displayTitle: `${d.title ?? 'Unknown Title'}\nwas: ${d.nextTitle ?? 'Unknown Title'}`,
        hoverText: `Moved to ${d.nextCompany ?? 'Unknown Company'}`
    }));
    return {
        hires,
        hiresCount,
        departures,
        departuresCount
    };
}
