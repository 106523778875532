import { jsx as _jsx } from "react/jsx-runtime";
import { useStore } from 'config/StoreProvider';
import useFetchSinglePeopleWatchlist from 'hooks/useFetchSinglePeopleWatchlist';
import useGetPeopleListCustomField from 'hooks/useGetPeopleListCustomField';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import EditCustomFields from './EditCustomFields';
const EditAllPeopleCustomFields = observer(({ open = false, anchorEl, onClose, anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
}, inverted, className = '-mt-p30' }) => {
    const { peopleWatchlist } = useFetchSinglePeopleWatchlist();
    const customFields = useMemo(() => {
        return peopleWatchlist?.customFields || [];
    }, [peopleWatchlist]);
    const { getCustomFieldFromCache } = useGetPeopleListCustomField();
    const { rootStore } = useStore();
    const handleSetCustomFieldValues = async ({ customFieldUrn, customFieldValueInput }) => {
        if (!peopleWatchlist)
            return;
        rootStore.peopleWatchlistViewStore.setAllCustomFieldValues({
            watchlistUrn: peopleWatchlist.entityUrn,
            customFieldUrn,
            customFieldValueInput
        });
        onClose?.();
    };
    const handleOnClose = () => {
        onClose?.();
    };
    const handleResetToDefaultValues = async ({ customFieldUrn }) => {
        if (!peopleWatchlist)
            return;
        rootStore.peopleWatchlistViewStore.resetAllCustomFieldValues({
            watchlistUrn: peopleWatchlist.entityUrn,
            customFieldUrn
        });
        onClose?.();
    };
    const handleDeleteCustomFieldValues = async ({ customFieldUrn }) => {
        if (!peopleWatchlist)
            return;
        rootStore.peopleWatchlistViewStore.deleteAllCustomFieldValues({
            watchlistUrn: peopleWatchlist.entityUrn,
            customFieldUrn
        });
        onClose?.();
    };
    return (_jsx(EditCustomFields, { open: open, anchorEl: anchorEl, onClose: handleOnClose, anchorOrigin: anchorOrigin, inverted: inverted, className: className, getCustomFieldFromCache: getCustomFieldFromCache, customFields: customFields, onSetCustomFieldValues: handleSetCustomFieldValues, onResetToDefaultValues: handleResetToDefaultValues, onDeleteCustomFieldValues: handleDeleteCustomFieldValues }));
});
export default EditAllPeopleCustomFields;
