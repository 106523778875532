import { useCallback } from 'react';
import { useStore } from '../../config/StoreProvider';
export const usePeopleSearchSort = () => {
    const { rootStore } = useStore();
    const sort = rootStore.peopleExploreViewStore.sort;
    const changeSort = useCallback(({ field, descending }) => {
        rootStore.peopleExploreViewStore.updateSort({ field, descending });
    }, [rootStore.peopleExploreViewStore]);
    return {
        changeSort,
        sort
    };
};
