import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { useStore } from 'config/StoreProvider';
import { autorun } from 'mobx';
import { useEffect, useMemo, useState } from 'react';
import useZustandStore from 'stores/zustandStore';
import { PILOT_LIST } from 'utils/constants';
export const useFlagsToFetch = (flagNames) => {
    const userUrn = useZustandStore((state) => state.userUrn);
    const planType = useMemo(() => (PILOT_LIST.includes(userUrn) ? 'pilot' : 'paid'), [userUrn]);
    const { rootStore } = useStore();
    const [email, setEmail] = useState('');
    const [customerUrn, setCustomerUrn] = useState('');
    useEffect(() => {
        autorun(() => {
            setEmail(rootStore.authStore.user?.email ?? '');
        });
    }, [rootStore.authStore.user?.email]);
    useEffect(() => {
        autorun(() => {
            setCustomerUrn(rootStore.authStore.userMetadata?.customer_urn ?? '');
        });
    }, [rootStore.authStore.userMetadata?.customer_urn]);
    /* `useTreatments` returns the evaluated treatments of the given list of split names.
     * While the SDK is not ready, treatments values are `control`.
     * If we send an empty list of flag names then nothing will be fetched but the rules of hooks will be followed. */
    const { treatments, isReady } = useSplitTreatments({
        names: flagNames,
        attributes: {
            plan_type: planType,
            email,
            user_urn: userUrn,
            customer_urn: customerUrn
        }
    });
    return { treatments, isReady };
};
export const useMultipleFlags = (flagNames) => {
    const { treatments, isReady } = useFlagsToFetch(flagNames);
    return flagNames.reduce((results, flagName) => ({
        ...results,
        [flagName]: {
            enabled: treatments[flagName]?.treatment === 'on',
            loading: !isReady
        }
    }), 
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {});
};
const useFlags = (flagName) => {
    const result = useMultipleFlags([flagName]);
    return result[flagName];
};
export default useFlags;
