import { EXTENDED_INCLUDES_TO_COLUMNS_MAP, EXTENDED_INCLUDES_TO_COLUMNS_PREFIX_MAP } from '../hooks/useCompanyExtendedIncludes';
/**
 * Process the extended URNs for the given ids and translates the extended includes
 * into the fields and prefix fields that are either loaded or not.
 *
 * @param ids - The ids to process.
 * @param currentLoadedUrns - The current loaded URNs.
 * @param extendedIncludes - The extended includes to process.
 * @returns The processed URNs.
 */
export function processExtendedUrns(ids, currentLoadedUrns, extendedIncludes) {
    return ids.reduce((acc, urn) => {
        const existingIncludes = currentLoadedUrns[urn];
        if (extendedIncludes) {
            const loadedFields = {};
            const loadedPrefixFields = {};
            for (const include of Object.keys(extendedIncludes)) {
                if (EXTENDED_INCLUDES_TO_COLUMNS_MAP[include]) {
                    const fields = EXTENDED_INCLUDES_TO_COLUMNS_MAP[include];
                    for (const field of fields) {
                        loadedFields[field] = extendedIncludes[include];
                    }
                }
                if (EXTENDED_INCLUDES_TO_COLUMNS_PREFIX_MAP[include]) {
                    const prefixField = EXTENDED_INCLUDES_TO_COLUMNS_PREFIX_MAP[include];
                    loadedPrefixFields[prefixField] = extendedIncludes[include];
                }
            }
            return {
                ...acc,
                [urn]: {
                    fields: {
                        ...(typeof existingIncludes === 'object'
                            ? existingIncludes.fields
                            : loadedFields),
                        ...Object.fromEntries(Object.entries(loadedFields).filter(([, value]) => value))
                    },
                    prefixFields: {
                        ...(typeof existingIncludes === 'object'
                            ? existingIncludes.prefixFields
                            : loadedPrefixFields),
                        ...Object.fromEntries(Object.entries(loadedPrefixFields).filter(([, value]) => value))
                    }
                }
            };
        }
        return {
            ...acc,
            [urn]: true
        };
    }, currentLoadedUrns);
}
