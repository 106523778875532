import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useNotApplicableBoardColumnVariables } from '../components/common/Board/hooks/useNotApplicableBoardColumnVariables';
import { useShallowTableStore } from '../stores/tableStore';
import { GRID_INITIAL_PAGE_SIZE } from '../utils/constants';
export const useRefetchWatchlistResults = ({ query, extendedQuery, variables, extendedVariables, getDataPath, getIdsFromData, getUrnsFromExtendedData, watchlist }) => {
    const client = useApolloClient();
    const { addToLoadedExtendedUrns } = useShallowTableStore([
        'addToLoadedExtendedUrns'
    ]);
    const [fetchCachedData] = useLazyQuery(query, {
        fetchPolicy: 'cache-only'
    });
    const [fetch] = useLazyQuery(query, {
        errorPolicy: 'ignore',
        fetchPolicy: 'network-only'
    });
    const notApplicableVariables = useNotApplicableBoardColumnVariables({
        watchlist,
        watchlistSearchVariables: variables
    });
    const handleCompleted = (data) => {
        const entries = getDataPath(data);
        const ids = getIdsFromData(data);
        client
            .query({
            query: extendedQuery,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            variables: {
                ids,
                skipExtended: false,
                ...extendedVariables
            },
            fetchPolicy: 'network-only'
        })
            .then(({ data }) => {
            const urns = getUrnsFromExtendedData(data);
            addToLoadedExtendedUrns(urns);
        });
        return entries;
    };
    const refetchResults = async (idOrUrn, variables) => {
        let cursor = null;
        const { data: cachedData } = await fetchCachedData({
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            variables: {
                ...variables,
                idOrUrn
            }
        });
        const numCachedEdges = getDataPath(cachedData)?.edges?.length || 0;
        let numPages = Math.floor(numCachedEdges / GRID_INITIAL_PAGE_SIZE) + 1;
        while (numPages > 0) {
            const { data, error } = await fetch({
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                variables: {
                    ...variables,
                    idOrUrn,
                    first: GRID_INITIAL_PAGE_SIZE,
                    after: cursor,
                    skipExtended: true
                }
            });
            if (!data || error)
                break;
            const entries = handleCompleted(data);
            const pageInfo = entries?.pageInfo;
            if (!pageInfo?.hasNextPage)
                break;
            cursor = pageInfo.endCursor ?? null;
            numPages--;
        }
    };
    const refetchResultsWithNotApplicable = async (idOrUrn) => {
        // Refetching results for grid
        await refetchResults(idOrUrn, variables);
        // Refetching results for N/A column of selected groupBy field
        await refetchResults(idOrUrn, {
            ...variables,
            ...notApplicableVariables
        });
    };
    return {
        refetchResults: refetchResultsWithNotApplicable
    };
};
