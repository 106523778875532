import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CompanyEmployees from './CompanyEmployees';
import CompanyExecutives from './CompanyExecutives';
import CompanyFoundersCeo from './CompanyFoundersCeo';
import CompanyTalentFlow from './CompanyTalentFlow';
import CompanyTeamGrowth from './CompanyTeamGrowth';
const CompanyTeam = ({ companyId }) => {
    return (_jsxs("div", { className: "min-h-screen", children: [_jsx(CompanyFoundersCeo, { companyId: companyId }), _jsx("div", { className: "py-16", children: _jsx(CompanyTeamGrowth, { companyId: companyId }) }), _jsx(CompanyTalentFlow, {}), _jsxs("div", { className: "gap-y-6", children: [_jsx(CompanyExecutives, { companyId: companyId }), _jsx(CompanyEmployees, { companyId: companyId })] })] }, companyId));
};
export default CompanyTeam;
