import CompanyColumnsConfig from 'interfaces/CompanyColumnsConfig';
const CompanyOptions = [
    {
        label: CompanyColumnsConfig.ADDED_TO_HARMONIC_DATE.label,
        value: CompanyColumnsConfig.ADDED_TO_HARMONIC_DATE.value
    },
    {
        label: CompanyColumnsConfig.COMPANY_HIGHLIGHTS.label,
        value: CompanyColumnsConfig.COMPANY_HIGHLIGHTS.value
    },
    {
        label: CompanyColumnsConfig.DESCRIPTION.label,
        value: CompanyColumnsConfig.DESCRIPTION.value
    },
    {
        label: CompanyColumnsConfig.EXTERNAL_DESCRIPTION.label,
        value: CompanyColumnsConfig.EXTERNAL_DESCRIPTION.value
    },
    {
        label: CompanyColumnsConfig.TAGS.label,
        value: CompanyColumnsConfig.TAGS.value
    },
    {
        label: CompanyColumnsConfig.CUSTOMER_TYPE.label,
        value: CompanyColumnsConfig.CUSTOMER_TYPE.value
    },
    {
        label: CompanyColumnsConfig.INDUSTRY_TAGS.label,
        value: CompanyColumnsConfig.INDUSTRY_TAGS.value
    },
    {
        label: CompanyColumnsConfig.TECHNOLOGY_TAGS.label,
        value: CompanyColumnsConfig.TECHNOLOGY_TAGS.value
    },
    {
        label: CompanyColumnsConfig.COMPANY_TYPE.label,
        value: CompanyColumnsConfig.COMPANY_TYPE.value
    },
    {
        label: CompanyColumnsConfig.FOUNDING_DATE.label,
        value: CompanyColumnsConfig.FOUNDING_DATE.value
    },
    {
        label: CompanyColumnsConfig.RELEVANCE_SCORE.label,
        value: CompanyColumnsConfig.RELEVANCE_SCORE.value
    },
    {
        label: CompanyColumnsConfig.CITY.label,
        value: CompanyColumnsConfig.CITY.value
    },
    {
        label: CompanyColumnsConfig.STATE.label,
        value: CompanyColumnsConfig.STATE.value
    },
    {
        label: CompanyColumnsConfig.COUNTRY.label,
        value: CompanyColumnsConfig.COUNTRY.value
    },
    {
        label: CompanyColumnsConfig.COMPANY_EMAILS.label,
        value: CompanyColumnsConfig.COMPANY_EMAILS.value
    },
    {
        label: CompanyColumnsConfig.COMPANY_PRIMARY_EMAIL.label,
        value: CompanyColumnsConfig.COMPANY_PRIMARY_EMAIL.value
    },
    {
        label: CompanyColumnsConfig.TEAM.label,
        value: CompanyColumnsConfig.TEAM.value
    },
    {
        label: CompanyColumnsConfig.FOUNDERS_AND_CEOS.label,
        value: CompanyColumnsConfig.FOUNDERS_AND_CEOS.value
    },
    {
        label: CompanyColumnsConfig.EXECUTIVES.label,
        value: CompanyColumnsConfig.EXECUTIVES.value
    },
    {
        label: CompanyColumnsConfig.LEADERSHIP_PRIOR_COMPANIES.label,
        value: CompanyColumnsConfig.LEADERSHIP_PRIOR_COMPANIES.value
    },
    {
        label: CompanyColumnsConfig.TEAM_EMAILS.label,
        value: CompanyColumnsConfig.TEAM_EMAILS.value
    },
    {
        label: CompanyColumnsConfig.PHONE_NUMBER.label,
        value: CompanyColumnsConfig.PHONE_NUMBER.value
    },
    {
        label: CompanyColumnsConfig.ACCELERATORS.label,
        value: CompanyColumnsConfig.ACCELERATORS.value
    },
    {
        label: CompanyColumnsConfig.ID.label,
        value: CompanyColumnsConfig.ID.value
    },
    {
        label: CompanyColumnsConfig.LEGAL_NAME.label,
        value: CompanyColumnsConfig.LEGAL_NAME.value
    },
    {
        label: CompanyColumnsConfig.UPDATED_DATE.label,
        value: CompanyColumnsConfig.UPDATED_DATE.value
    },
    {
        label: CompanyColumnsConfig.MY_NOTES.label,
        value: CompanyColumnsConfig.MY_NOTES.value
    },
    {
        label: CompanyColumnsConfig.TEAM_NOTES.label,
        value: CompanyColumnsConfig.TEAM_NOTES.value
    }
];
export default CompanyOptions;
