import client from 'config/client';
import { EntityAction } from 'interfaces/Grid';
import { INITIAL_COMPANY_SORT } from 'interfaces/SearchModel/Search';
import { action, makeObservable, override, runInAction } from 'mobx';
import { CustomFieldsFragment } from 'queries/getWatchlist';
import { SIMPLE_COMPANY_WATCHLIST_ENTRY_NODE_FRAGMENT, WATCHLIST_WITH_COMPANIES } from 'queries/getWatchlistWithCompanies';
import upsertCompanyWatchlistCustomFieldValuesMutation from 'queries/upsertCompanyWatchlistCustomFieldValuesMutation';
import { performEntityActionFromWatchlist } from 'utils/midtierApi';
import { displayToast } from 'utils/toasts';
import { getWatchlistCompanySearchVariables } from 'utils/watchlist';
import WatchlistViewStore from './WatchlistViewStore';
import { formatCustomFieldValueInputForBackend, generateCustomFieldValueDeleteInput, generateCustomFieldValueInput } from './utils';
class CompanyWatchlistViewStore extends WatchlistViewStore {
    loading = false;
    error = undefined;
    /**
     * Initializes the company watchlist store.
     * @param rootStore - The root store instance.
     */
    constructor(rootStore) {
        super(rootStore);
        /*
         * https://mobx.js.org/observable-state.html#limitations
         * By default TypeScript will not allow you to annotate private fields.
         * This can be overcome by explicitly passing the relevant private fields as generic argument,
         * like this: makeObservable<MyStore, "privateField" | "privateField2">(this, { privateField: observable, privateField2: observable })
         */
        makeObservable(this, {
            resetAllCustomFieldValues: action,
            deleteAllCustomFieldValues: action,
            setAllCustomFieldValues: action,
            sort: true,
            observeLocalSearchTerm: false,
            observeWatchlistResults: false,
            apolloResultsSubscription: false,
            rootStore: false,
            observeSelectedNamedView: false,
            optimisticUpdateCompanyCustomFieldValueByEntryUrns: true,
            resetStore: override,
            setWatchlist: false,
            setCustomFieldValues: action,
            deleteCustomFieldValues: action,
            resetToDefaultValues: action,
            localSearchTermSelector: false,
            selectedNamedViewSelector: false,
            fetchAndSetWatchlist: action,
            loading: true,
            error: true
        });
    }
    get sort() {
        const selectedNamedView = this.watchlist?.currentNamedViewWithDraft;
        const sort = selectedNamedView?.searchQuery?.sort?.[0];
        return {
            sortField: sort?.sortField ?? INITIAL_COMPANY_SORT.sortField,
            descending: sort?.descending ?? INITIAL_COMPANY_SORT.sortDescending,
            sortContextValues: sort?.sortContextValues
                ? { ...sort.sortContextValues }
                : null
        };
    }
    setWatchlist(watchlistUrn) {
        this.resetStore();
        this.fetchAndSetWatchlist(watchlistUrn);
    }
    async fetchAndSetWatchlist(watchlistUrn) {
        this.loading = true;
        const { watchlist, error } = await this.rootStore.companyWatchlistStore.get(watchlistUrn);
        this.loading = false;
        runInAction(() => {
            this.watchlist = watchlist;
            this.error = error;
        });
    }
    optimisticUpdateCompanyCustomFieldValueByEntryUrns({ entryUrns, customFieldUrn, customFieldValueInput }) {
        return this.optimisticUpdateCustomFieldValueByEntryUrns({
            entryUrns,
            customFieldUrn,
            customFieldValueInput,
            entryDefinition: {
                __typename: 'CompanyWatchlistEntryNode',
                fragment: SIMPLE_COMPANY_WATCHLIST_ENTRY_NODE_FRAGMENT,
                fragmentName: 'SimpleCompanyListEntryNode'
            },
            customFieldDefinition: {
                __typename: 'CompanyListCustomField',
                fragment: CustomFieldsFragment,
                fragmentName: 'CustomFields'
            },
            customFieldValueDefinition: {
                __typename: 'CompanyListCustomFieldValue'
            }
        });
    }
    /**
     * Upserts custom field values for a company watchlist.
     * @param customFieldUrn - The urn of the custom field to set the value for.
     * @param watchlistUrn - The urn of the watchlist.
     * @param entryUrns - The urns of the entries to set the value for.
     * @param customFieldValueInput - The custom field value to set.
     */
    async setCustomFieldValues({ customFieldUrn, watchlistUrn, entryUrns, customFieldValueInput }) {
        try {
            this.optimisticUpdateCompanyCustomFieldValueByEntryUrns({
                entryUrns,
                customFieldUrn,
                customFieldValueInput
            });
            this.setUpsertCustomFieldValueLoading(true);
            await client.mutate({
                mutation: upsertCompanyWatchlistCustomFieldValuesMutation,
                variables: {
                    customFieldUrn,
                    companyWatchlistUrn: watchlistUrn,
                    companyWatchlistEntryUrns: entryUrns,
                    customFieldValueInput,
                    asyncEnabled: this.asyncEnabled
                }
            });
        }
        catch (error) {
            displayToast({
                mode: 'error',
                primaryText: 'Failed to update custom field values'
            });
        }
        finally {
            runInAction(() => {
                this.setUpsertCustomFieldValueLoading(false);
            });
        }
    }
    /**
     * Deletes custom field values for a company watchlist.
     * @param customFieldUrn - The urn of the custom field to delete the value for.
     * @param watchlistUrn - The urn of the watchlist.
     * @param entryUrns - The urns of the entries to delete the value for.
     */
    async deleteCustomFieldValues({ customFieldUrn, watchlistUrn, entryUrns }) {
        try {
            const id = client.cache.identify({
                __typename: 'CompanyListCustomField',
                urn: customFieldUrn
            });
            const customField = client.cache.readFragment({
                id,
                fragment: CustomFieldsFragment,
                fragmentName: 'CustomFields',
                returnPartialData: true
            });
            if (!customField) {
                throw new Error('Custom field not found');
            }
            this.setUpsertCustomFieldValueLoading(true);
            await client.mutate({
                mutation: upsertCompanyWatchlistCustomFieldValuesMutation,
                variables: {
                    customFieldUrn,
                    companyWatchlistUrn: watchlistUrn,
                    companyWatchlistEntryUrns: entryUrns,
                    customFieldValueInput: generateCustomFieldValueDeleteInput(customField.type),
                    asyncEnabled: this.asyncEnabled
                }
            });
        }
        catch (error) {
            displayToast({
                mode: 'error',
                primaryText: 'Failed to delete custom field values'
            });
        }
        finally {
            runInAction(() => {
                this.setUpsertCustomFieldValueLoading(false);
            });
        }
    }
    /**
     * Resets custom field values to their default values for a company watchlist.
     * @param customFieldUrn - The urn of the custom field to reset the value for.
     * @param watchlistUrn - The urn of the watchlist.
     * @param entryUrns - The urns of the entries to reset the value for.
     */
    async resetToDefaultValues({ customFieldUrn, watchlistUrn, entryUrns }) {
        try {
            const id = client.cache.identify({
                __typename: 'CompanyListCustomField',
                urn: customFieldUrn
            });
            const customField = client.cache.readFragment({
                id,
                fragment: CustomFieldsFragment,
                fragmentName: 'CustomFields',
                returnPartialData: true
            });
            if (!customField) {
                throw new Error('Custom field not found');
            }
            const defaultValue = customField.defaultValue;
            if (!defaultValue) {
                await this.deleteCustomFieldValues({
                    customFieldUrn,
                    watchlistUrn,
                    entryUrns
                });
                return;
            }
            this.setUpsertCustomFieldValueLoading(true);
            await client.mutate({
                mutation: upsertCompanyWatchlistCustomFieldValuesMutation,
                variables: {
                    customFieldUrn,
                    companyWatchlistUrn: watchlistUrn,
                    companyWatchlistEntryUrns: entryUrns,
                    customFieldValueInput: generateCustomFieldValueInput(defaultValue),
                    asyncEnabled: this.asyncEnabled
                }
            });
        }
        catch (error) {
            displayToast({
                mode: 'error',
                primaryText: 'Failed to reset custom field values'
            });
        }
        finally {
            runInAction(() => {
                this.setUpsertCustomFieldValueLoading(false);
            });
        }
    }
    /**
     * Sets all custom field values for a company watchlist.
     * @param customFieldUrn - The urn of the custom field to set the value for.
     * @param watchlistUrn - The urn of the watchlist.
     * @param customFieldValueInput - The custom field value to set.
     */
    async setAllCustomFieldValues({ customFieldUrn, watchlistUrn, customFieldValueInput }) {
        const watchlistVariables = getWatchlistCompanySearchVariables({
            selectedNamedViewUrn: this.watchlist?.selectedNamedViewUrn ?? null,
            companyWatchlist: this.watchlist?.watchlistWithDraftState ?? null,
            localSearchTerm: this.watchlist?.localSearchTerm ?? null
        });
        const currentQuery = client.readQuery({
            query: WATCHLIST_WITH_COMPANIES,
            variables: watchlistVariables
        });
        const currentCompanyEntries = currentQuery?.getCompanyWatchlistByIdOrUrn?.companyEntries;
        this.optimisticUpdateCompanyCustomFieldValueByEntryUrns({
            entryUrns: currentCompanyEntries?.edges.map((entry) => entry.node.entryUrn) ?? [],
            customFieldUrn,
            customFieldValueInput
        });
        this.setUpsertCustomFieldValueLoading(true);
        try {
            await performEntityActionFromWatchlist({
                entityAction: EntityAction.UPSERT_CUSTOM_FIELD_VALUE,
                targetEntityType: 'COMPANY_WATCHLIST_ENTRY',
                targetWatchlistUrn: watchlistUrn,
                customFieldUrn,
                customFieldValue: formatCustomFieldValueInputForBackend(customFieldValueInput)
            }, watchlistUrn, this.sort, this.numItemsActionable, this.asyncEnabled, this.watchlist?.currentNamedViewWithDraft?.searchQuery);
        }
        catch (error) {
            displayToast({
                mode: 'error',
                primaryText: 'Failed to update custom field values'
            });
        }
        finally {
            runInAction(() => {
                this.setUpsertCustomFieldValueLoading(false);
            });
        }
    }
    /**
     * Deletes all custom field values for a company watchlist.
     * @param customFieldUrn - The urn of the custom field to delete the value for.
     * @param watchlistUrn - The urn of the watchlist.
     */
    async deleteAllCustomFieldValues({ customFieldUrn, watchlistUrn }) {
        try {
            const id = client.cache.identify({
                __typename: 'CompanyListCustomField',
                urn: customFieldUrn
            });
            const customField = client.cache.readFragment({
                id,
                fragment: CustomFieldsFragment,
                fragmentName: 'CustomFields',
                returnPartialData: true
            });
            if (!customField) {
                throw new Error('Custom field not found');
            }
            this.setUpsertCustomFieldValueLoading(true);
            await performEntityActionFromWatchlist({
                entityAction: EntityAction.UPSERT_CUSTOM_FIELD_VALUE,
                targetEntityType: 'COMPANY_WATCHLIST_ENTRY',
                targetWatchlistUrn: watchlistUrn,
                customFieldUrn,
                customFieldValue: formatCustomFieldValueInputForBackend(generateCustomFieldValueDeleteInput(customField.type))
            }, watchlistUrn, this.sort, this.numItemsActionable, this.asyncEnabled, this.watchlist?.currentNamedViewWithDraft?.searchQuery);
        }
        catch (error) {
            displayToast({
                mode: 'error',
                primaryText: 'Failed to delete custom field values'
            });
        }
        finally {
            runInAction(() => {
                this.setUpsertCustomFieldValueLoading(false);
            });
        }
    }
    /**
     * Resets all custom field values for a company watchlist.
     * @param customFieldUrn - The urn of the custom field to reset the value for.
     * @param watchlistUrn - The urn of the watchlist.
     */
    async resetAllCustomFieldValues({ customFieldUrn, watchlistUrn }) {
        try {
            const id = client.cache.identify({
                __typename: 'CompanyListCustomField',
                urn: customFieldUrn
            });
            const customField = client.cache.readFragment({
                id,
                fragment: CustomFieldsFragment,
                fragmentName: 'CustomFields',
                returnPartialData: true
            });
            if (!customField) {
                throw new Error('Custom field not found');
            }
            const defaultValue = customField.defaultValue;
            if (!defaultValue) {
                await this.deleteAllCustomFieldValues({
                    customFieldUrn,
                    watchlistUrn
                });
                return;
            }
            this.setUpsertCustomFieldValueLoading(true);
            await performEntityActionFromWatchlist({
                entityAction: EntityAction.UPSERT_CUSTOM_FIELD_VALUE,
                targetEntityType: 'COMPANY_WATCHLIST_ENTRY',
                targetWatchlistUrn: watchlistUrn,
                customFieldUrn,
                customFieldValue: formatCustomFieldValueInputForBackend(generateCustomFieldValueInput(defaultValue))
            }, watchlistUrn, this.sort, this.numItemsActionable, this.asyncEnabled, this.watchlist?.currentNamedViewWithDraft?.searchQuery);
        }
        catch (error) {
            displayToast({
                mode: 'error',
                primaryText: 'Failed to reset custom field values'
            });
        }
        finally {
            runInAction(() => {
                this.setUpsertCustomFieldValueLoading(false);
            });
        }
    }
}
export default CompanyWatchlistViewStore;
