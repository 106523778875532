import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// eslint-disable-next-line no-restricted-imports
import { useQuery } from '@apollo/client';
import { EmployeeGroupType } from '__generated__/graphql';
import { getIdFromUrn } from 'common/utils/urn';
import dayjs from 'dayjs';
import { getFundingTypeDisplayName } from 'interfaces/SearchModel/Search';
import { getCompanyFinancing } from 'queries/getCompanyFinancingV2';
import { getCompanyMetrics } from 'queries/getCompanyMetrics';
import { getCompanyProfileHeader } from 'queries/getCompanyProfileHeader';
import { formatFundingAmount } from 'utils/investor';
import { getCompanyFounders } from '../CompanyTeam/CompanyFoundersCeo';
import CompanyOverviewCard from './CompanyOverviewCard';
export const CompanyOverviewArtifact = ({ companyUrn }) => {
    const { data: companyData } = useQuery(getCompanyProfileHeader, {
        variables: {
            id: Number(getIdFromUrn(companyUrn))
        }
    });
    const { data: metricsData } = useQuery(getCompanyMetrics, {
        variables: {
            id: Number(getIdFromUrn(companyUrn))
        }
    });
    const { data: fundingData } = useQuery(getCompanyFinancing, {
        variables: {
            id: Number(getIdFromUrn(companyUrn))
        }
    });
    const { data: foundersData } = useQuery(getCompanyFounders, {
        variables: {
            companyUrn: companyUrn,
            page: 0,
            size: 5,
            employeeGroupType: EmployeeGroupType.FOUNDERS_AND_CEO
        }
    });
    const company = companyData?.getCompanyById;
    if (!company)
        return null;
    const foundedYear = dayjs(company.foundingDate?.date).year();
    const yearsInOperation = foundedYear
        ? dayjs().diff(dayjs(company.foundingDate?.date), 'year')
        : 8;
    const stage = company.funding?.lastFundingType;
    const leadInvestorsForMostRecentStage = fundingData?.getCompanyById?.funding?.fundingRounds
        ?.find((round) => round?.fundingRoundType === stage)
        ?.investors?.filter((investor) => investor?.isLead);
    const totalFunding = fundingData?.getCompanyById?.funding?.fundingTotal;
    const fundingPerEmployee = fundingData?.getCompanyById?.fundingPerEmployee;
    const headcount = metricsData?.getCompanyById?.headcount;
    const headcountChange = metricsData?.getCompanyById?.tractionMetrics?.headcount?.ago90d
        ?.percentChange;
    const founders = foundersData?.getEmployeesByCompanyId?.edges.map((edge) => edge?.node);
    const foundersNames = founders
        ?.filter((founder) => founder?.__typename === 'Person')
        .map((founder) => founder.fullName);
    const overviewCards = [
        [
            {
                icon: 'today',
                title: 'Founded',
                primaryContent: foundedYear,
                secondaryContent: `${yearsInOperation} years in operation`
            },
            {
                icon: 'flag',
                title: 'Stage',
                primaryContent: getFundingTypeDisplayName(stage),
                secondaryContent: Number(leadInvestorsForMostRecentStage?.length) > 0
                    ? `Led by ${leadInvestorsForMostRecentStage
                        ?.map((investor) => investor?.investorName)
                        ?.join(', ')}`
                    : ''
            },
            {
                icon: 'paid',
                title: 'Total funding',
                primaryContent: formatFundingAmount(totalFunding),
                secondaryContent: `${formatFundingAmount(fundingPerEmployee?.toString())} per employee`
            }
        ],
        [
            {
                icon: 'group',
                title: 'Headcount',
                primaryContent: headcount?.toString() ?? '-',
                secondaryContent: headcountChange
                    ? `${headcountChange}% in the last 90 days`
                    : ''
            },
            // {
            //   icon: 'leaderboard',
            //   title: 'Key competitors',
            //   primaryContent: 'Ramp, Divvy, Airbase, Mercury, Stripe',
            //   additionalContent: '+5 more'
            // },
            {
                icon: 'group',
                title: 'Founders',
                primaryContent: foundersNames?.slice(0, 2).join(', ') || '',
                additionalContent: Number(foundersNames?.length) > 2
                    ? `+${Number(foundersNames?.length) - 2} more`
                    : ''
            }
        ]
    ];
    return (_jsx("div", { className: "space-y-4 my-6", children: overviewCards.map((row, rowIndex) => (_jsx("div", { className: "grid grid-cols-3 gap-4", children: row.map((card, cardIndex) => (_jsxs(CompanyOverviewCard, { icon: card.icon, title: card.title, children: [_jsx("div", { className: "typography-label-default-default text-content-strong", children: card.primaryContent }), !!card.secondaryContent && (_jsx("div", { className: "typography-paragraph-extraSmall-default text-content-weak", children: card.secondaryContent })), !!card.additionalContent && (_jsxs("span", { className: "typography-paragraph-extraSmall-default text-content-weak", children: [' ', card.additionalContent] }))] }, cardIndex))) }, rowIndex))) }));
};
