import useNetNewCounts from 'components/Dashboard/Collections/Searches/NetNew/useNetNewCounts';
import { useStore } from 'config/StoreProvider';
import { toast } from 'react-toastify';
import { performEntityActionFromSearchQuery, performEntityActionFromWatchlist } from 'utils/midtierApi';
import { camelize } from 'utils/utilities';
import useDashboardLocation, { DashboardLocation } from './useDashboardLocation';
import { useSearchSort } from './useSearchSort';
import useSearchVariables from './useSearchVariables';
const useBulkActions = () => {
    const dashboardLocation = useDashboardLocation();
    const sort = useSearchSort();
    const { onClearNetNewForSearch } = useNetNewCounts();
    const { searchVariables } = useSearchVariables();
    const urn = dashboardLocation.urn;
    const { rootStore } = useStore();
    const performBulkAction = async (options) => {
        const { actionToPerform, targetWatchlistUrn, onComplete, onError, actionText, operationCount, isProcessedAsync } = options;
        const toastId = !isProcessedAsync
            ? toast(`${actionText ? actionText : 'Action in progress'}. Results may take some time to process; feel free to navigate away from this page.`, {
                autoClose: false,
                closeOnClick: false,
                isLoading: true,
                closeButton: false
            })
            : undefined;
        try {
            if (isProcessedAsync && onComplete) {
                onComplete(operationCount);
            }
            if (dashboardLocation.location === DashboardLocation.COMPANY_SEARCH) {
                urn && onClearNetNewForSearch(urn);
                await performEntityActionFromSearchQuery({
                    entityAction: actionToPerform,
                    targetEntityType: 'COMPANY',
                    targetWatchlistUrn
                }, camelize(searchVariables.query), operationCount);
                onComplete && !isProcessedAsync && onComplete(operationCount);
            }
            else if (dashboardLocation.location === DashboardLocation.PEOPLE_SEARCH) {
                await performEntityActionFromSearchQuery({
                    entityAction: actionToPerform,
                    targetEntityType: 'PERSON',
                    targetWatchlistUrn
                }, camelize(searchVariables.query), operationCount);
                onComplete && !isProcessedAsync && onComplete(operationCount);
            }
            else if (dashboardLocation.location === DashboardLocation.COMPANY_LIST &&
                urn &&
                sort) {
                await performEntityActionFromWatchlist({
                    entityAction: actionToPerform,
                    targetEntityType: 'COMPANY',
                    targetWatchlistUrn
                }, urn, sort, operationCount, false, rootStore.companyWatchlistViewStore.watchlist
                    ?.currentNamedViewWithDraft?.searchQuery);
                onComplete && !isProcessedAsync && onComplete(operationCount);
            }
            else if (dashboardLocation.location === DashboardLocation.PERSON_LIST &&
                urn &&
                sort) {
                await performEntityActionFromWatchlist({
                    entityAction: actionToPerform,
                    targetEntityType: 'PERSON',
                    targetWatchlistUrn
                }, urn, sort, operationCount, false, rootStore.peopleWatchlistViewStore.watchlist
                    ?.currentNamedViewWithDraft?.searchQuery);
            }
        }
        catch (e) {
            onError && onError(0, e.message);
            toast.error('An error occurred while performing this action. Please try again later.');
            if (toastId) {
                toast.dismiss(toastId);
            }
            return;
        }
        if (toastId) {
            toast.dismiss(toastId);
        }
    };
    return {
        performBulkAction
    };
};
export default useBulkActions;
