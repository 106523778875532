import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
import { prepareRecentMovesData } from './utils/recentMoves';
const DEFAULT_WIDTH = 400;
const LEFT_COLUMN_RATIO = 0.15;
const RIGHT_COLUMN_RATIO = 0.85;
const VERTICAL_PADDING = 80;
const MIN_HEIGHT = 400;
const TEXT_TRUNCATE_LENGTH = 30;
export default function RecentMovesDiagram({ talentInflow: talentFlow, companyName, companyLogoUrl, width = DEFAULT_WIDTH, onLoad }) {
    const baseFontSize = 10;
    const svgRef = useRef(null);
    // Call onLoad immediately after first render
    useEffect(() => {
        onLoad?.();
    }, [onLoad]);
    useEffect(() => {
        if (!svgRef.current)
            return;
        // 1. Parse data
        const { hires, hiresCount, departures, departuresCount } = prepareRecentMovesData(talentFlow);
        // 2. Clear existing SVG content
        d3.select(svgRef.current).selectAll('*').remove();
        // Calculate dimensions
        const LEFT_COLUMN_X = width * LEFT_COLUMN_RATIO;
        const RIGHT_COLUMN_X = width * RIGHT_COLUMN_RATIO;
        const CENTER_X = width / 2;
        // Calculate dynamic spacing based on text content
        const calculateNodeHeight = () => {
            const nameHeight = 20;
            const titleHeight = 20;
            const padding = 80;
            return nameHeight + titleHeight * 2 + padding;
        };
        // Position nodes with dynamic spacing
        let currentY = VERTICAL_PADDING;
        hires.forEach((item) => {
            item.x = LEFT_COLUMN_X;
            item.y = currentY;
            currentY += calculateNodeHeight();
        });
        // Reset currentY for departures
        currentY = VERTICAL_PADDING;
        departures.forEach((item) => {
            item.x = RIGHT_COLUMN_X;
            item.y = currentY;
            currentY += calculateNodeHeight();
        });
        // Calculate required height based on content
        const maxY = Math.max(hires.length
            ? hires[hires.length - 1].y + calculateNodeHeight()
            : 0, departures.length
            ? departures[departures.length - 1].y +
                calculateNodeHeight()
            : 0);
        const height = Math.max(MIN_HEIGHT, maxY + VERTICAL_PADDING);
        const CENTER_Y = height / 2;
        // 3. Set up the SVG with calculated dimensions
        const svg = d3
            .select(svgRef.current)
            .attr('width', width)
            .attr('height', height);
        // 4. Draw lines from hires -> center
        svg
            .selectAll('path.hire-line')
            .data(hires)
            .enter()
            .append('path')
            .attr('class', 'hire-line')
            .attr('fill', 'none')
            .attr('stroke', '#E5E7EB')
            .attr('stroke-width', 2)
            .attr('d', (d) => {
            const x1 = d.x;
            const y1 = d.y;
            return generateCurvePath(x1, y1, CENTER_X, CENTER_Y, x1 + 100, y1, CENTER_X - 100, CENTER_Y);
        });
        // lines from center -> departures
        svg
            .selectAll('path.departure-line')
            .data(departures)
            .enter()
            .append('path')
            .attr('class', 'departure-line')
            .attr('fill', 'none')
            .attr('stroke', '#E5E7EB')
            .attr('stroke-width', 2)
            .attr('d', (d) => {
            const x2 = d.x;
            const y2 = d.y;
            return generateCurvePath(CENTER_X, CENTER_Y, x2, y2, CENTER_X + 100, CENTER_Y, x2 - 100, y2);
        });
        // 6. Draw hire nodes on the left
        const hiresGroup = svg
            .selectAll('g.hire-node')
            .data(hires)
            .enter()
            .append('g')
            .attr('class', 'hire-node')
            .attr('transform', (d) => `translate(${d.x}, ${d.y})`);
        hiresGroup
            .append('circle')
            .attr('r', 25)
            .attr('fill', '#fff')
            .attr('stroke', '#E5E7EB');
        hiresGroup
            .append((d) => {
            // Create either an 'a' element or a 'g' element based on URL presence
            return document.createElementNS('http://www.w3.org/2000/svg', d.previousCompanyProfileUrl ? 'a' : 'g');
        })
            .attr('href', (d) => d.previousCompanyProfileUrl || null)
            .attr('target', '_blank')
            .append('image')
            .attr('href', (d) => d.logoUrl || fallbackLogo())
            .attr('x', -15)
            .attr('y', -15)
            .attr('width', 30)
            .attr('height', 30)
            .each(function (d) {
            // Only add hover effects if there's a profile URL
            if (d.previousCompanyProfileUrl) {
                d3.select(this)
                    .style('cursor', 'pointer')
                    .style('filter', 'drop-shadow(0 0 0 rgba(59, 130, 246, 0))')
                    .style('transition', 'all 0.2s ease')
                    .on('mouseover', function (event) {
                    d3.select(this)
                        .style('filter', 'drop-shadow(0 0 6px rgba(59, 130, 246, 0.8))')
                        .style('opacity', '0.9')
                        .style('transform', 'scale(1.1)');
                    tooltip
                        .style('visibility', 'visible')
                        .html(`<div>${d.previousCompany}</div>`)
                        .style('left', `${event.pageX + 10}px`)
                        .style('top', `${event.pageY - 10}px`);
                })
                    .on('mousemove', function (event) {
                    tooltip
                        .style('left', `${event.pageX + 10}px`)
                        .style('top', `${event.pageY - 10}px`);
                })
                    .on('mouseout', function () {
                    d3.select(this)
                        .style('filter', 'drop-shadow(0 0 0 rgba(59, 130, 246, 0))')
                        .style('opacity', '1')
                        .style('transform', 'scale(1)');
                    tooltip.style('visibility', 'hidden');
                });
            }
        });
        hiresGroup
            .append('text')
            .text((d) => truncateText(d.name))
            .style('font-size', `${baseFontSize}px`)
            .attr('x', 0)
            .attr('y', 45)
            .attr('text-anchor', 'middle')
            .attr('fill', '#111827');
        hiresGroup
            .append('text')
            .attr('class', 'subtitle-text')
            .text((d) => truncateText(`hired as: ${d.title}`))
            .attr('x', 0)
            .attr('y', 65)
            .attr('text-anchor', 'middle')
            .attr('fill', '#111827')
            .style('font-size', `${baseFontSize}px`)
            .on('mouseover', function (event, d) {
            tooltip
                .style('visibility', 'visible')
                .html(`
            <div>
              <strong>${d.name}</strong><br/>
              hired as: ${d.title}<br/>
              was: ${d.previousTitle} at ${d.previousCompany}
            </div>
          `)
                .style('left', `${event.pageX + 10}px`)
                .style('top', `${event.pageY - 10}px`);
        })
            .on('mousemove', function (event) {
            tooltip
                .style('left', `${event.pageX + 10}px`)
                .style('top', `${event.pageY - 10}px`);
        })
            .on('mouseout', function () {
            tooltip.style('visibility', 'hidden');
        });
        hiresGroup
            .append('text')
            .attr('class', 'title-text')
            .text((d) => truncateText(`was: ${d.previousTitle} at ${d.previousCompany}`))
            .attr('x', 0)
            .attr('y', 80)
            .attr('text-anchor', 'middle')
            .attr('fill', '#6B7280')
            .style('font-size', `${baseFontSize}px`)
            .on('mouseover', function (event, d) {
            tooltip
                .style('visibility', 'visible')
                .html(`
            <div>
              <strong>${d.name}</strong><br/>
              hired as: ${d.title}<br/>
              was: ${d.previousTitle} at ${d.previousCompany}
            </div>
          `)
                .style('left', `${event.pageX + 10}px`)
                .style('top', `${event.pageY - 10}px`);
        })
            .on('mousemove', function (event) {
            tooltip
                .style('left', `${event.pageX + 10}px`)
                .style('top', `${event.pageY - 10}px`);
        })
            .on('mouseout', function () {
            tooltip.style('visibility', 'hidden');
        });
        // 7. Draw departure nodes on the right
        const departuresGroup = svg
            .selectAll('g.departure-node')
            .data(departures)
            .enter()
            .append('g')
            .attr('class', 'departure-node')
            .attr('transform', (d) => `translate(${d.x}, ${d.y})`);
        departuresGroup
            .append('circle')
            .attr('r', 25)
            .attr('fill', '#fff')
            .attr('stroke', '#E5E7EB');
        departuresGroup
            .append((d) => {
            // Create either an 'a' element or a 'g' element based on URL presence
            return document.createElementNS('http://www.w3.org/2000/svg', d.nextCompanyProfileUrl ? 'a' : 'g');
        })
            .attr('href', (d) => d.nextCompanyProfileUrl || null)
            .attr('target', '_blank')
            .append('image')
            .attr('href', (d) => d.logoUrl || fallbackLogo())
            .attr('x', -15)
            .attr('y', -15)
            .attr('width', 30)
            .attr('height', 30)
            .each(function (d) {
            // Only add hover effects if there's a profile URL
            if (d.nextCompanyProfileUrl) {
                d3.select(this)
                    .style('cursor', 'pointer')
                    .style('filter', 'drop-shadow(0 0 0 rgba(59, 130, 246, 0))')
                    .style('transition', 'all 0.2s ease')
                    .on('mouseover', function (event) {
                    d3.select(this)
                        .style('filter', 'drop-shadow(0 0 6px rgba(59, 130, 246, 0.8))')
                        .style('opacity', '0.9')
                        .style('transform', 'scale(1.1)');
                    tooltip
                        .style('visibility', 'visible')
                        .html(`<div>${d.nextCompany}</div>`)
                        .style('left', `${event.pageX + 10}px`)
                        .style('top', `${event.pageY - 10}px`);
                })
                    .on('mousemove', function (event) {
                    tooltip
                        .style('left', `${event.pageX + 10}px`)
                        .style('top', `${event.pageY - 10}px`);
                })
                    .on('mouseout', function () {
                    d3.select(this)
                        .style('filter', 'drop-shadow(0 0 0 rgba(59, 130, 246, 0))')
                        .style('opacity', '1')
                        .style('transform', 'scale(1)');
                    tooltip.style('visibility', 'hidden');
                });
            }
        });
        departuresGroup
            .append('text')
            .text((d) => truncateText(d.name))
            .style('font-size', `${baseFontSize + 2}px`)
            .attr('x', 0)
            .attr('y', 45)
            .attr('text-anchor', 'middle')
            .attr('fill', '#111827');
        departuresGroup
            .append('text')
            .attr('class', 'title-text')
            .text((d) => truncateText(d.nextTitle))
            .attr('x', 0)
            .attr('y', 65)
            .attr('text-anchor', 'middle')
            .attr('fill', '#6B7280')
            .style('font-size', `${baseFontSize}px`)
            .on('mouseover', function (event, d) {
            tooltip
                .style('visibility', 'visible')
                .html(`
            <div>
              <strong>${d.name}</strong><br/>
              was: ${d.title}<br/>
              moved to: ${d.nextTitle} at ${d.nextCompany}
            </div>
          `)
                .style('left', `${event.pageX + 10}px`)
                .style('top', `${event.pageY - 10}px`);
        })
            .on('mousemove', function (event) {
            tooltip
                .style('left', `${event.pageX + 10}px`)
                .style('top', `${event.pageY - 10}px`);
        })
            .on('mouseout', function () {
            tooltip.style('visibility', 'hidden');
        });
        departuresGroup
            .append('text')
            .attr('class', 'subtitle-text')
            .text((d) => truncateText(`was: ${d.title}`))
            .attr('x', 0)
            .attr('y', 80)
            .attr('text-anchor', 'middle')
            .attr('fill', '#111827')
            .style('font-size', `${baseFontSize}px`)
            .on('mouseover', function (event, d) {
            tooltip
                .style('visibility', 'visible')
                .html(`
            <div>
              <strong>${d.name}</strong><br/>
              was: ${d.title}<br/>
              moved to: ${d.nextTitle} at ${d.nextCompany}
            </div>
          `)
                .style('left', `${event.pageX + 10}px`)
                .style('top', `${event.pageY - 10}px`);
        })
            .on('mousemove', function (event) {
            tooltip
                .style('left', `${event.pageX + 10}px`)
                .style('top', `${event.pageY - 10}px`);
        })
            .on('mouseout', function () {
            tooltip.style('visibility', 'hidden');
        });
        // 8. Draw center node for your main company
        const centerGroup = svg
            .append('g')
            .attr('class', 'center-node')
            .attr('transform', `translate(${CENTER_X}, ${CENTER_Y})`);
        centerGroup
            .append('circle')
            .attr('r', 40)
            .attr('fill', '#FFF')
            .attr('stroke', '#E5E7EB');
        if (companyLogoUrl) {
            centerGroup
                .append('image')
                .attr('href', companyLogoUrl)
                .attr('x', -20)
                .attr('y', -20)
                .attr('width', 40)
                .attr('height', 40);
        }
        centerGroup
            .append('text')
            .text(companyName)
            .attr('text-anchor', 'middle')
            .attr('y', 60)
            .attr('fill', '#111827')
            .style('font-size', `${baseFontSize + 2}px`);
        // 9. Optionally display total counts
        svg
            .append('text')
            .attr('x', LEFT_COLUMN_X)
            .attr('y', 40)
            .attr('text-anchor', 'middle')
            .attr('fill', '#4B5563')
            .style('font-size', `${baseFontSize + 4}px`)
            .style('font-weight', 'bold')
            .text(`Recent Hires (${hiresCount})`);
        svg
            .append('text')
            .attr('x', RIGHT_COLUMN_X)
            .attr('y', 40)
            .attr('text-anchor', 'middle')
            .attr('fill', '#4B5563')
            .style('font-size', `${baseFontSize + 4}px`)
            .style('font-weight', 'bold')
            .text(`Recent Departures (${departuresCount})`);
        // Cubic Bezier path generator for lines
        function generateCurvePath(x1, y1, x2, y2, cx1, cy1, cx2, cy2) {
            return `M ${x1},${y1} C ${cx1},${cy1} ${cx2},${cy2} ${x2},${y2}`;
        }
        function fallbackLogo() {
            return 'https://static.licdn.com/aero-v1/sc/h/cs8pjfgyw96g44ln9r7tct85f';
        }
        const tooltip = d3
            .select('body')
            .append('div')
            .attr('class', 'tooltip')
            .style('position', 'absolute')
            .style('visibility', 'hidden')
            .style('background-color', 'white')
            .style('padding', '8px')
            .style('border-radius', '4px')
            .style('border', '1px solid #E5E7EB')
            .style('font-size', '12px')
            .style('pointer-events', 'none')
            .style('z-index', '100')
            .style('max-width', '300px')
            .style('box-shadow', '0 2px 4px rgba(0,0,0,0.1)');
        // Clean up tooltip on unmount
        return () => {
            tooltip.remove();
        };
    }, [talentFlow, companyName, companyLogoUrl, width]);
    return (_jsx("div", { className: "relative mx-auto flex", children: _jsx("svg", { ref: svgRef, style: { maxWidth: '100%', height: 'auto' } }) }));
}
function truncateText(text, maxLength = TEXT_TRUNCATE_LENGTH) {
    if (!text)
        return '';
    return text?.length > maxLength ? text.slice(0, maxLength) + '...' : text;
}
