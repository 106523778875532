import { useQuery } from '@apollo/client';
import { TeamStatus } from '__generated__/graphql';
import { GET_ALL_TEAM_MEMBERS } from 'queries/getAllTeamMembers';
import { useMemo } from 'react';
import useStore from 'stores/zustandStore';
const useTeamMembers = ({ skip, fetchPolicy = 'cache-first' } = {}) => {
    const customerUrn = useStore((state) => state.customerUrn);
    const { data, loading, error } = useQuery(GET_ALL_TEAM_MEMBERS, {
        variables: {
            urn: customerUrn
        },
        fetchPolicy,
        skip: !customerUrn || skip
    });
    const activeTeamMembers = useMemo(() => (data?.getAllTeamMembersByCustomer || []).filter((member) => member?.status !== TeamStatus.PAUSED &&
        member?.status !== TeamStatus.PENDING_PILOT_AGREEMENT_ACCEPTANCE), [data]);
    const allTeamMembers = useMemo(() => data?.getAllTeamMembersByCustomer || [], [data]);
    return {
        activeTeamMembers,
        allTeamMembers,
        loading,
        error
    };
};
export default useTeamMembers;
