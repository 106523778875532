import CompanyColumnsConfig from 'interfaces/CompanyColumnsConfig';
import PersonColumnsConfig from 'interfaces/PersonColumnsConfig';
import { useShallowTableStore } from 'stores/tableStore';
import { insertColumn } from 'utils/columnUtils';
export const useFieldsDropdown = ({ colId, save, convertColumnsConfigToUrns }) => {
    const { editSidebar, tableColumns } = useShallowTableStore([
        'editSidebar',
        'tableColumns'
    ]);
    const onCreateNewField = (insertFieldMode) => {
        editSidebar('customizeFieldModal', {
            open: true,
            mode: insertFieldMode,
            colId
        });
    };
    const onFieldClick = (field, insertFieldMode) => {
        const visibleColumnsExcludingFixedColumns = tableColumns.filter((column) => column.isVisibleOnTable &&
            column.id !== field.value &&
            column.id !== CompanyColumnsConfig.NAME.value &&
            column.id !== PersonColumnsConfig.FULL_NAME.value);
        insertColumn({
            visibleColumns: visibleColumnsExcludingFixedColumns,
            newColId: field.value,
            newColName: field.label,
            mode: insertFieldMode,
            colId
        });
        const filteredColumns = visibleColumnsExcludingFixedColumns.filter((col) => col?.id);
        const columnUrns = convertColumnsConfigToUrns(filteredColumns);
        save(columnUrns, filteredColumns);
    };
    return {
        onFieldClick,
        onCreateNewField
    };
};
