import PersonColumnsConfig from 'interfaces/PersonColumnsConfig';
const AllOptions = [
    {
        label: PersonColumnsConfig.TEAM_CONNECTIONS.label,
        value: PersonColumnsConfig.TEAM_CONNECTIONS.value
    },
    {
        label: PersonColumnsConfig.EMAILS.label,
        value: PersonColumnsConfig.EMAILS.value
    },
    {
        label: PersonColumnsConfig.LINKEDIN.label,
        value: PersonColumnsConfig.LINKEDIN.value
    },
    {
        label: PersonColumnsConfig.LINKEDIN_HEADLINE.label,
        value: PersonColumnsConfig.LINKEDIN_HEADLINE.value
    },
    {
        label: PersonColumnsConfig.PERSON_HIGHLIGHTS.label,
        value: PersonColumnsConfig.PERSON_HIGHLIGHTS.value
    },
    {
        label: PersonColumnsConfig.CURRENT_TITLE.label,
        value: PersonColumnsConfig.CURRENT_TITLE.value
    },
    {
        label: PersonColumnsConfig.CURRENT_COMPANY.label,
        value: PersonColumnsConfig.CURRENT_COMPANY.value
    },
    {
        label: PersonColumnsConfig.CURRENT_COMPANY_TAGS.label,
        value: PersonColumnsConfig.CURRENT_COMPANY_TAGS.value
    },
    {
        label: PersonColumnsConfig.PREVIOUS_COMPANY.label,
        value: PersonColumnsConfig.PREVIOUS_COMPANY.value
    },
    {
        label: PersonColumnsConfig.CURRENT_LOCATION.label,
        value: PersonColumnsConfig.CURRENT_LOCATION.value
    },
    {
        label: PersonColumnsConfig.EDUCATION.label,
        value: PersonColumnsConfig.EDUCATION.value
    }
];
export default AllOptions;
