import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ListItem, { ListType, ListVariant } from 'harmonic-components/ListItem/ListItem';
import SearchInput from 'harmonic-components/SearchInput/SearchInput';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { ToastComponent, displayToast } from 'utils/toasts';
const EditCustomFieldsSelectValue = ({ customField, onSave, onClear, recalculatePopoverPosition }) => {
    const [filterTerm, setFilterTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const handleOnFilterTermChange = (value) => {
        setFilterTerm(value);
        recalculatePopoverPosition();
    };
    const options = useMemo(() => {
        return customField?.metadata?.__typename === 'SelectListCustomFieldMetadata'
            ? customField.metadata.options
            : [];
    }, [customField]);
    const filteredOptions = useMemo(() => {
        return options.filter((option) => option.name.toLowerCase().includes(filterTerm.toLowerCase()));
    }, [options, filterTerm]);
    const handleOnChange = async (value) => {
        setLoading(true);
        await onSave(value);
        setLoading(false);
    };
    const handleOnClear = async () => {
        const toastId = toast.loading(_jsx(ToastComponent, { primaryText: "Saving..." }));
        await onClear();
        toast.dismiss(toastId);
        displayToast({
            mode: 'success',
            primaryText: 'Saved'
        });
    };
    return (_jsxs("div", { className: "w-full flex flex-col max-h-96", children: [_jsx(SearchInput, { fullWidth: true, placeholder: "Search", value: filterTerm, onChange: handleOnFilterTermChange }), _jsxs("div", { className: "mt-p20 flex-1 overflow-y-auto min-h-0", children: [filteredOptions.map((option) => (_jsx("div", { className: "w-full", children: _jsx(ListItem, { type: ListType.default, variant: ListVariant.tag, label: option.name, value: option.name, onClick: () => handleOnChange(option.urn), disabled: loading, 
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            color: option.color }, option.urn) }, option.urn))), _jsx(ListItem, { type: ListType.default, variant: ListVariant.default, label: "None", value: "None", onClick: handleOnClear })] })] }));
};
export default EditCustomFieldsSelectValue;
