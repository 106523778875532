import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import ArrowDownwardIcon from 'assets/harmonic-icons/arrow-downward';
import ArrowUpwardIcon from 'assets/harmonic-icons/arrow-upward';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import { useSearchSort } from 'hooks/useSearchSort';
import React from 'react';
export const SortDropdownItems = ({ field, changeSort, customFieldUrn }) => {
    const sort = useSearchSort();
    const isCurrentSort = sort?.sortField === field;
    return (_jsxs(_Fragment, { children: [_jsx(ListItem, { primaryIcon: ArrowUpwardIcon, variant: ListVariant.default, label: "Sort ascending", value: "Sort ascending", selected: Boolean(isCurrentSort && !sort?.descending), onClick: () => changeSort({
                    field,
                    descending: false,
                    sortContextValues: customFieldUrn ? { customFieldUrn } : undefined
                }) }), _jsx(ListItem, { primaryIcon: ArrowDownwardIcon, variant: ListVariant.default, label: "Sort descending", value: "Sort descending", selected: Boolean(isCurrentSort && sort?.descending), onClick: () => changeSort({
                    field,
                    descending: true,
                    sortContextValues: customFieldUrn ? { customFieldUrn } : undefined
                }) })] }));
};
