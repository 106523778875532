import React, { useEffect, useLayoutEffect } from 'react';
import { useRef } from 'react';
import { logger, LoggerEvent } from '../utils/logger';
const useRenderPerformance = (componentName, children) => {
    const renderCount = useRef(0);
    const renderStartTime = useRef(0);
    // Capture start time before render
    useLayoutEffect(() => {
        renderStartTime.current = performance.now();
    });
    // Measure after render is complete
    useEffect(() => {
        const renderDuration = performance.now() - renderStartTime.current;
        renderCount.current += 1;
        logger.sendPerformanceEvent(LoggerEvent.RENDER_COMPONENT, {
            componentName,
            renderCount: renderCount.current,
            renderDuration: renderDuration.toFixed(2),
            numChildren: React.Children.count(children)
        });
    });
};
export default useRenderPerformance;
