import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// eslint-disable-next-line no-restricted-imports
import { useQuery } from '@apollo/client';
import { getIdFromUrn } from 'common/utils/urn';
import { ChatMessageText } from 'components/Dashboard/Semantic/Chat/ChatMessageText';
import { getCompanyProfileHeader } from 'queries/getCompanyProfileHeader';
import React from 'react';
import { CompanyOverviewArtifact } from './CompanyOverviewArtifact';
import { InvestmentHistoryCard } from './InvestmentHistoryCard';
const ExecutiveSummarySection = ({ content, companyUrn }) => {
    const { data: companyProfileData } = useQuery(getCompanyProfileHeader, {
        variables: {
            id: Number(getIdFromUrn(companyUrn))
        }
    });
    const getSocials = () => {
        const socialLinks = [];
        // add domain as well
        if (companyProfileData?.getCompanyById?.website?.domain) {
            socialLinks.push({
                name: 'Website',
                url: `https://${companyProfileData?.getCompanyById?.website?.domain}`
            });
        }
        if (companyProfileData?.getCompanyById?.socials?.linkedin?.url) {
            socialLinks.push({
                name: 'LinkedIn',
                url: companyProfileData?.getCompanyById?.socials?.linkedin?.url
            });
        }
        if (companyProfileData?.getCompanyById?.socials?.twitter?.url) {
            socialLinks.push({
                name: 'Twitter',
                url: companyProfileData?.getCompanyById?.socials?.twitter?.url
            });
        }
        if (companyProfileData?.getCompanyById?.socials?.crunchbase?.url) {
            socialLinks.push({
                name: 'Crunchbase',
                url: companyProfileData?.getCompanyById?.socials?.crunchbase?.url
            });
        }
        if (companyProfileData?.getCompanyById?.socials?.instagram?.url) {
            socialLinks.push({
                name: 'Instagram',
                url: companyProfileData?.getCompanyById?.socials?.instagram?.url
            });
        }
        if (companyProfileData?.getCompanyById?.socials?.pitchbook?.url) {
            socialLinks.push({
                name: 'Pitchbook',
                url: companyProfileData?.getCompanyById?.socials?.pitchbook?.url
            });
        }
        return socialLinks;
    };
    const socialLinks = getSocials();
    return (_jsx(_Fragment, { children: _jsxs("div", { children: [_jsx("h4", { className: "font-medium text-gray-800 text-lg mb-4", children: "Executive Summary" }), _jsx("p", { className: "text-gray-600", children: _jsx(ChatMessageText, { children: content }) }), _jsx("div", { className: "py-p100", children: socialLinks.map((link, index) => (_jsxs(React.Fragment, { children: [_jsx("a", { href: link.url, target: "_blank", rel: "noopener noreferrer", className: "text-content-weak underline hover:text-content-default", children: link.name }), _jsx("span", { className: "text-content-weak text-xxs", children: index < socialLinks.length - 1 && ' • ' })] }, link.name))) }), _jsx(CompanyOverviewArtifact, { companyUrn: companyUrn }), _jsx(InvestmentHistoryCard, { companyUrn: companyUrn })] }) }));
};
export default ExecutiveSummarySection;
