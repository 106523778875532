import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import useFlags from 'hooks/useFlags';
import React from 'react';
import { SPLITS } from 'utils/constants';
const CompanyIconButtonActionLoader = () => {
    const { enabled: enableCustomFieldsInCompanyPage } = useFlags(SPLITS.enableCustomFieldsInCompanyPage);
    return (_jsx("div", { className: classNames('rounded-br20 bg-slate-200 animate-pulse', {
            'w-7 h-7': enableCustomFieldsInCompanyPage,
            'w-9 h-9': !enableCustomFieldsInCompanyPage
        }), "data-testid": "CompanyIconButtonActionLoader" }));
};
export default CompanyIconButtonActionLoader;
