import { computed, makeObservable, observable } from 'mobx';
import { transformSearchModelForApiv2 } from '../../utils/search';
import { camelize } from '../../utils/utilities';
class ExploreViewStore {
    rootStore;
    totalCount = undefined;
    searchModel;
    tableColumns;
    error = null;
    subscription = null;
    constructor(rootStore, searchModel, tableColumns) {
        this.rootStore = rootStore;
        this.searchModel = searchModel;
        this.tableColumns = tableColumns;
        makeObservable(this, {
            rootStore: false,
            totalCount: observable,
            sort: computed,
            searchModel: observable,
            tableColumns: observable,
            transformedSearchModel: computed,
            error: observable,
            subscription: false
        });
    }
    /**
     * Returns the sort field and descending state.
     * @returns The sort field and descending state.
     */
    get sort() {
        return {
            sortField: this.searchModel.sort?.[0]?.sort_field,
            descending: this.searchModel.sort?.[0]?.descending
        };
    }
    /**
     * Returns the camelized and API v2 transformed search model.
     */
    get transformedSearchModel() {
        return camelize(transformSearchModelForApiv2(this.searchModel));
    }
}
export default ExploreViewStore;
