import removeCompanyWatchlistCustomFieldMutation from 'queries/removeCompanyWatchlistCustomField';
import { GET_WATCHLIST, GET_WATCHLIST_FRAGMENT_WITH_COMPANY_IDS } from '../queries/getWatchlist';
import useGetCompanyListCustomField from './useGetCompanyListCustomField';
import useRemoveWatchlistCustomField from './useRemoveWatchlistCustomField';
const useRemoveCompanyListCustomField = () => {
    const { getCustomFieldFragmentIdFromUrn } = useGetCompanyListCustomField();
    return useRemoveWatchlistCustomField({
        typename: 'CompanyWatchlist',
        mutation: removeCompanyWatchlistCustomFieldMutation,
        getWatchlistQuery: GET_WATCHLIST,
        fragmentDoc: GET_WATCHLIST_FRAGMENT_WITH_COMPANY_IDS,
        fragmentName: 'WatchlistWithCompanyId',
        getCustomFieldFragmentIdFromUrn
    });
};
export default useRemoveCompanyListCustomField;
