import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '@headlessui/react';
import { ExcludeInvertedIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { initializeResearchAgent } from 'common/utils/api';
import { Form } from 'components/common/Form';
import { last } from 'lodash';
import { observer } from 'mobx-react';
import { useCallback, useRef, useState } from 'react';
import { assistantStreamResponse } from 'utils/agentGraphApi';
import { logger } from 'utils/logger';
import { useStore } from '../../../../config/StoreProvider';
import { ChatMessageText } from '../../Semantic/Chat/ChatMessageText';
import { INITIAL_LOADING_MESSAGE } from '../../Semantic/Chat/types';
import CompanyResearchReportOptions from './CompanyResearchReportOptions';
// Separate component for input box
const InputBox = ({ question, onSetQuestion, onClearQuestion, onAskQuestion, isLoading, companyName }) => {
    const [handleSetQuestion, handleClearQuestion, handleAskQuestion] = [
        onSetQuestion,
        onClearQuestion,
        onAskQuestion
    ];
    return (_jsx(Form, { enableSubmitOnEnter: true, onSubmit: handleAskQuestion, children: _jsxs("div", { className: "flex flex-row items-center justify-between py-p20 px-p40 border-b border-border border-solid", children: [_jsxs("div", { className: "flex flex-row items-center gap-g40 w-full", children: [_jsx(ExcludeInvertedIcon, { className: "text-blue-600" }), _jsx("input", { value: question, onChange: handleSetQuestion, placeholder: `Ask Agent about ${companyName}`, disabled: isLoading, autoFocus: true, className: "border-none resize-none flex-1 bg-white placeholder:text-content-muted" })] }), _jsx("button", { onClick: handleClearQuestion, className: "text-gray-500 hover:text-gray-700", children: _jsx("span", { className: "icon material-icons-outlined text-lg", children: "close" }) })] }) }));
};
const CompanyResearchAssistant = ({ companyUrn, companyName, companyDomain }) => {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const abortControllerRef = useRef(null);
    const [currentProcessingMessages, setCurrentProcessingMessages] = useState([
        INITIAL_LOADING_MESSAGE
    ]);
    const [isGeneratingReport, setIsGeneratingReport] = useState(false);
    const [exampleQuestions, setExampleQuestions] = useState([]);
    const [isLoadingExamples, setIsLoadingExamples] = useState(false);
    const [exampleQueriesFetched, setExampleQueriesFetched] = useState(false);
    const [threadId, setThreadId] = useState();
    const [isCopied, setIsCopied] = useState(false);
    const { rootStore } = useStore();
    const { apiKey, user } = rootStore.authStore;
    // Get the current message to display
    const currentProcessingMessage = last(currentProcessingMessages) || 'Processing';
    const handleSetQuestion = useCallback((e) => {
        setQuestion(e.target.value);
    }, []);
    const handleClearQuestion = useCallback(() => {
        setQuestion('');
        setAnswer('');
    }, []);
    const handleClickPanel = (e) => {
        e.stopPropagation();
    };
    const handleCopyAnswer = useCallback(() => {
        navigator.clipboard.writeText(answer);
        setIsCopied(true);
        // Reset the copied state after 2 seconds
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    }, [answer]);
    const handleAskQuestionWithText = useCallback(async (questionText) => {
        if (!questionText.trim())
            return;
        // Cancel any existing request
        abortControllerRef.current?.abort();
        // Create new abort controller
        abortControllerRef.current = new AbortController();
        setIsLoading(true);
        setAnswer('');
        try {
            let responseText = '';
            await assistantStreamResponse({
                assistantId: 'company_research_assistant',
                keep: true,
                graphInput: {
                    company_identifiers: [
                        {
                            urn: companyUrn
                        }
                    ]
                },
                prompt: !threadId
                    ? `About ${companyName}: ${questionText}`
                    : questionText,
                onChunkReceived: (chunk) => {
                    if (chunk.error) {
                        setAnswer('Sorry, an error occurred. Please try again.');
                        return;
                    }
                    if (chunk.event === 'on_chain_start' &&
                        chunk.metadata.graph_id === 'company_research_assistant') {
                        setThreadId(chunk.metadata.thread_id);
                    }
                    if (chunk.event === 'on_custom_event') {
                        if (chunk.name === 'step_start') {
                            setCurrentProcessingMessages((prev) => [
                                ...prev,
                                chunk.data.display_message
                            ]);
                        }
                        if (chunk.name === 'step_end') {
                            // Only remove the message if it's a subtask (generally parallelized)
                            if (chunk.data.step_type === 'subtask') {
                                setCurrentProcessingMessages((prev) => prev.filter((message) => message !== chunk.data.display_message));
                            }
                        }
                    }
                    if (chunk.event === 'on_chat_model_stream' &&
                        chunk.metadata.langgraph_node === 'final_answer' &&
                        chunk.data.chunk.content) {
                        // Handle text content
                        if (chunk.data.chunk.content) {
                            responseText += chunk.data.chunk.content;
                            setAnswer(responseText);
                        }
                    }
                },
                onComplete: () => {
                    setIsLoading(false);
                },
                threadId: threadId,
                abortSignal: abortControllerRef.current?.signal
            });
        }
        catch (error) {
            if (error instanceof Error && error.name === 'AbortError') {
                logger.info('Research assistant request aborted');
                return;
            }
            logger.error('Research assistant request failed', { error });
            setAnswer('Sorry, an error occurred. Please try again.');
            setIsLoading(false);
        }
    }, [companyUrn, companyName, threadId]);
    const handleAskQuestion = useCallback(async () => {
        await handleAskQuestionWithText(question);
    }, [question, handleAskQuestionWithText]);
    const handleGenerateDiligenceReport = useCallback(async () => {
        if (!companyUrn || !user?.email || !apiKey) {
            setAnswer('Unable to generate report. Missing required information.');
            return;
        }
        try {
            if (!companyDomain) {
                setAnswer('Unable to generate report. Missing required information.');
                return;
            }
            const response = await initializeResearchAgent(companyDomain, user.email, apiKey, 'company_research_assistant');
            if (response.status === 'success') {
                setIsGeneratingReport(true);
            }
        }
        catch (error) {
            logger.error('Failed to initialize research agent', { error });
        }
    }, [companyUrn, companyDomain, setAnswer]);
    const handleExampleQuestionClick = async (exampleQuestion) => {
        setQuestion(exampleQuestion);
        await handleAskQuestionWithText(exampleQuestion);
    };
    // Fetch example questions
    const handleFetchExampleQuestions = useCallback(async () => {
        if (!companyUrn || !companyName || exampleQueriesFetched)
            return;
        setIsLoadingExamples(true);
        try {
            let suggestions = [];
            await assistantStreamResponse({
                assistantId: 'company_research_assistant',
                keep: false,
                graphInput: {
                    company_identifiers: [
                        {
                            urn: companyUrn
                        }
                    ],
                    include_research: false,
                    include_suggestions: true
                },
                prompt: `Generate example questions about ${companyName}`,
                onChunkReceived: (chunk) => {
                    if (chunk.error) {
                        return;
                    }
                    if (chunk.event === 'on_chain_end' &&
                        chunk.metadata.langgraph_node === 'question_suggestions') {
                        if (Array.isArray(chunk.data.output.research_question_suggestions)) {
                            suggestions = chunk.data.output.research_question_suggestions;
                        }
                    }
                },
                onComplete: () => {
                    if (suggestions.length > 0) {
                        setExampleQuestions(suggestions);
                    }
                    setIsLoadingExamples(false);
                    setExampleQueriesFetched(true);
                }
            });
        }
        catch (error) {
            logger.error('Failed to fetch example questions', { error });
            setIsLoadingExamples(false);
        }
    }, [companyUrn, companyName, exampleQueriesFetched]);
    return (_jsx(Popover.Panel, { className: "absolute -right-6 z-10 mt-2 w-[400px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none", children: _jsxs("div", { onClick: handleClickPanel, className: "cursor-default py-p20 typography-label-default-default", children: [_jsx(InputBox, { question: question, onSetQuestion: handleSetQuestion, onClearQuestion: handleClearQuestion, onAskQuestion: handleAskQuestion, isLoading: isLoading, companyName: companyName }), !answer && !isLoading && (_jsx(CompanyResearchReportOptions, { companyName: companyName, companyUrn: companyUrn, companyDomain: companyDomain, onExampleQuestionClick: handleExampleQuestionClick, isLoading: isLoading, exampleQuestions: exampleQuestions, isLoadingExamples: isLoadingExamples, onFetchExampleQueries: handleFetchExampleQuestions, question: question, onGenerateDiligenceReport: handleGenerateDiligenceReport, isGeneratingReport: isGeneratingReport })), !!answer && (_jsxs("div", { className: "rounded-md pl-p20", children: [_jsx("div", { className: "text-sm text-gray-700 whitespace-pre-wrap break-words max-h-64 overflow-y-auto overflow-x-hidden inverted-scrollbar p-p40", children: _jsx(ChatMessageText, { children: answer }) }), !isLoading && (_jsx("div", { className: "flex justify-start", children: _jsx("button", { onClick: handleCopyAnswer, children: _jsx("span", { className: classNames('icon material-icons-outlined icon-20 text-gray-400 hover:text-gray-500 pl-p40', { 'text-gray-500': isCopied }), children: isCopied ? 'check' : 'content_copy' }) }) }))] })), !!isLoading && !answer && (_jsxs("div", { className: "flex justify-start items-center gap-g40 px-p40 py-p40", children: [_jsx(ExcludeInvertedIcon, { className: "text-purple-500" }), _jsxs("div", { className: "chatbot-loading-text font-medium text-sm", children: [currentProcessingMessage, "..."] })] }))] }) }));
};
export default observer(CompanyResearchAssistant);
