import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BagIcon, BankIcon, CelebrateIcon, DollarIcon, GlassesIcon, GraduationCapIcon, ImageAvatarSparklingIcon, PeopleCopyIcon, ToolboxIcon } from 'assets/harmonic-icons';
import { Badge, Tooltip } from 'common/components';
import { useState } from 'react';
import { useOutsideClick } from 'utils/hooks';
const HighlightTextToIconMap = {
    'Elite Industry Experience': BagIcon,
    'Major Tech Company Experience': BagIcon,
    'Top Company Alum': BagIcon,
    'Deep Technical Background': BagIcon,
    'Legacy Tech Company Experience': BagIcon,
    'Major Research Institution Experience': GraduationCapIcon,
    'Top University': GraduationCapIcon,
    'Top 10 MBAs': GraduationCapIcon,
    'HBCU Alum': GraduationCapIcon,
    'Prior VC Backed Founder': ImageAvatarSparklingIcon,
    'Prior VC Backed Executive': ImageAvatarSparklingIcon,
    'Prior Exit': ImageAvatarSparklingIcon,
    'Seasoned Operator': ImageAvatarSparklingIcon,
    'Seasoned Adviser': ImageAvatarSparklingIcon,
    'Seasoned Founder': ImageAvatarSparklingIcon,
    'Seasoned Executive': ImageAvatarSparklingIcon,
    'Strong Team Growth': ImageAvatarSparklingIcon,
    'Stealth Founder': GlassesIcon,
    'Founder Turned Operator': PeopleCopyIcon,
    'Jack of All Trades': ToolboxIcon,
    'Free Agent': CelebrateIcon,
    'Venture Background': BankIcon,
    'Venture Backed': BankIcon,
    'YC Backed Founder': ImageAvatarSparklingIcon
};
export const SingleHighlight = ({ highlight, tooltipText, icon }) => {
    const HighlightIcon = icon;
    const chipComponent = (_jsx(Badge, { label: highlight, leadingIcon: HighlightIcon, color: "expressive-32", size: "large", dataTestId: "SingleHighlight-Badge" }, highlight));
    if (!tooltipText)
        return chipComponent;
    return (_jsx(Tooltip, { title: tooltipText ?? '', interactive: true, children: chipComponent }));
};
export const getHighlightIcon = (highlight) => {
    let HighlightIcon = HighlightTextToIconMap?.[highlight];
    if (highlight.startsWith('$')) {
        HighlightIcon = DollarIcon;
    }
    return HighlightIcon;
};
const HighlightsChip = ({ highlights, limit = 3 }) => {
    const [isMoreHighlightsDropdownOpen, setIsMoreHighlightsDropdownOpen] = useState(false);
    const totalHighlights = highlights.length;
    const highlightsDropdownRef = useOutsideClick(() => setIsMoreHighlightsDropdownOpen(false));
    return (_jsxs("div", { className: "flex gap-3 flex-wrap items-start md:items-center", children: [highlights.slice(0, limit).map((highlight, index) => {
                const highlightCategory = highlight.category;
                const HighlightIcon = getHighlightIcon(highlightCategory);
                return (_jsx(Badge, { size: "large", color: "expressive-32", leadingIcon: HighlightIcon, label: highlightCategory }, index));
            }), totalHighlights > limit && (_jsxs("div", { className: "relative z-20", children: [_jsxs("button", { onClick: () => setIsMoreHighlightsDropdownOpen(true), className: "text-content-headerGray typography-label", children: ["+", totalHighlights - limit] }), isMoreHighlightsDropdownOpen && (_jsx("div", { ref: highlightsDropdownRef, "data-testid": "MoreHighlights-Dropdown", className: "absolute bg-white flex-nowrap p-2 min-w-48 border border-solid border-modal-mainStroke shadow-search rounded-md flex flex-col gap-1.5", children: highlights.slice(limit).map((highlight, index) => {
                            const highlightCategory = highlight?.category;
                            const HighlightIcon = getHighlightIcon(highlightCategory);
                            return (_jsx(Badge, { size: "large", color: "expressive-32", leadingIcon: HighlightIcon, label: highlightCategory, intensity: "highlight" }, index));
                        }) }))] }))] }));
};
export default HighlightsChip;
