import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar as OldAvatar } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import SecurityIcon from '@material-ui/icons/Security';
import { get } from 'lodash';
import { useState } from 'react';
import { isSafeHostedAsset } from '../../../../utils/utilities';
import { GRID_ROW_HEIGHT } from '../../../common/ResultsWrapper/GridResultsView';
import { useStyles } from './CellRenderers';
export const LogoCellRenderer = (props) => {
    const gridRowHeight = props.api?.getGridOption('rowHeight') || GRID_ROW_HEIGHT;
    const logoUrl = get(props, 'data.company.logo_url');
    const isStealthCompany = get(props, 'node.data.company.name')?.startsWith('Stealth Company');
    const [imageError, setImageError] = useState(false);
    const classes = useStyles();
    const logoClassName = `${gridRowHeight > 65 ? classes.logoLarge : classes.logo}`;
    return logoUrl && isSafeHostedAsset(logoUrl) && !imageError ? (_jsx("div", { children: _jsx("img", { "data-testid": "safe-logo-url-image", onError: () => setImageError(true), className: logoClassName, src: logoUrl }) })) : (_jsx("div", { children: 
        // Do not render until data is loaded
        props.node.data && (_jsx(OldAvatar, { className: logoClassName, variant: "rounded", children: isStealthCompany ? _jsx(SecurityIcon, {}) : _jsx(BusinessIcon, {}) })) }));
};
