import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useHotkeysContext } from 'react-hotkeys-hook';
import { HotkeyScope } from 'config/hotkeys';
import { getCompaniesViewRecords } from '../queries/getCompanyViewRecords';
import useHotkeys from './useHotkeys';
export const useGridCellEditing = ({ ref, context }) => {
    const client = useApolloClient();
    const { enableScope, disableScope } = useHotkeysContext();
    const refocusGrid = useCallback(() => {
        // This block is required to ensure we don't lose focus of the grid when we delete a row
        const focusedCell = ref.current?.api?.getFocusedCell();
        if (focusedCell) {
            ref.current?.api.setFocusedCell(focusedCell.rowIndex, focusedCell.column.getColId());
        }
    }, [ref]);
    useHotkeys({
        keys: ['esc'],
        options: {
            scopes: [HotkeyScope.GRID_EDITING],
            enableOnFormTags: true,
            description: 'Cancels editing of a cell and re-focuses it',
            enabled: ref.current?.api?.getEditingCells() &&
                ref.current?.api?.getEditingCells().length > 0
        },
        callback: refocusGrid
    });
    const markCompanyAsViewed = useCallback(async (companyId) => {
        const viewRecordCache = await client.query({
            query: getCompaniesViewRecords,
            variables: { ids: [companyId] },
            fetchPolicy: 'cache-only'
        });
        const companyViewRecord = viewRecordCache.data?.getCompaniesByIds?.[0]?.viewRecord;
        if (!companyViewRecord) {
            context?.setViewed([
                companyId
            ]);
        }
    }, [client, context]);
    const handleCellEditingStarted = useCallback(() => {
        disableScope(HotkeyScope.GRID);
        enableScope(HotkeyScope.GRID_EDITING);
        ref.current?.api.updateGridOptions({ suppressClickEdit: true });
    }, [disableScope, enableScope, ref]);
    const handlCellEditingStopped = useCallback(async (cell) => {
        disableScope(HotkeyScope.GRID_EDITING);
        enableScope(HotkeyScope.GRID);
        ref.current?.api.updateGridOptions({ suppressClickEdit: false });
        const companyId = cell.data?.company?.id;
        if (companyId) {
            markCompanyAsViewed(companyId);
        }
        refocusGrid();
    }, [disableScope, enableScope, markCompanyAsViewed, refocusGrid, ref]);
    return {
        handleCellEditingStarted,
        handlCellEditingStopped,
        refocusGrid
    };
};
