import { gql } from '@apollo/client';
export const UPDATE_WATCHLIST = gql `
  mutation UpdateWatchlist(
    $idOrUrn: String!
    $watchlistInput: CompanyWatchlistUpdateInput!
  ) {
    updateCompanyWatchlist(idOrUrn: $idOrUrn, watchlistInput: $watchlistInput) {
      id
      entityUrn
      companyIds
      name
      sharedWithTeam
      userWatchlistType
      namedViewsOrder
    }
  }
`;
