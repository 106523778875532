import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import Button from 'harmonic-components/Button/Button';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import { WarningIcon } from 'assets/harmonic-icons';
import Avatar from 'harmonic-components/Avatar/Avatar';
import useFetchWatchlists from 'hooks/useFetchWatchlists';
import { EntityListType } from 'interfaces/SearchModel/Search';
import { compact } from 'lodash';
import { getCompaniesNames } from 'queries/getCompaniesNames';
import { GET_WATCHLIST_WITH_CUSTOM_FIELDS } from 'queries/getWatchlistsCustomFields';
import { EditFieldTypeOptionIcon } from 'utils/custom-fields';
const ConfirmRemoveFromListModal = (props) => {
    const { onConfirmRemoval, listType, listId, entityIds } = props;
    const { watchlists } = useFetchWatchlists();
    const { data: listCustomFieldsData } = useQuery(GET_WATCHLIST_WITH_CUSTOM_FIELDS, {
        variables: {
            idOrUrn: listId
        },
        fetchPolicy: 'cache-first'
    });
    const watchlist = listCustomFieldsData?.getCompanyWatchlistByIdOrUrn;
    const listName = watchlists?.find((watchlist) => {
        return watchlist?.id === listId;
    })?.name ?? 'list';
    const customFields = watchlist?.customFields;
    const { data: companyNameData } = useQuery(getCompaniesNames, {
        variables: {
            ids: compact(entityIds)
        },
        fetchPolicy: 'cache-first',
        skip: entityIds.length !== 1
    });
    const entityTitle = entityIds.length === 1
        ? companyNameData?.getCompaniesByIds?.[0]?.name
        : `${entityIds.length} companies`;
    if (listType === EntityListType.PEOPLE_WATCHLIST) {
        throw new Error('ConfirmRemoveFromListModal: People watchlist is not supported yet.');
    }
    const ListFields = () => {
        return (_jsxs("div", { className: "p-p10 bg-surface-sentiment-neutral rounded-br40 mb-p60", children: [_jsxs("div", { className: "flex px-p30 pt-p20 pb-p40 gap-g30", children: [_jsx(Avatar, { size: "medium", kind: "logo", src: '', alt: "company-profile-picture" }), _jsx("p", { className: "text-content-strong typography-label-default-default line-clamp-1", children: entityTitle })] }), _jsx("div", { className: "px-p40 py-p20 rounded-br40 bg-white", children: customFields?.map((field) => {
                        const FieldTypeIcon = EditFieldTypeOptionIcon[field.type];
                        return (_jsxs("li", { className: "flex gap-g30", children: [_jsx(FieldTypeIcon, {}), field.name] }, field.urn));
                    }) })] }));
    };
    return (_jsxs("div", { children: [_jsx(ModalTitle, { titleIcon: WarningIcon, sentiment: "negative", title: `Remove ${entityTitle} from ${listName}` }), _jsxs("div", { className: "px-p50 pt-p50 flex flex-col max-h-[400px] overflow-y-auto", children: [_jsx("p", { className: "typography-paragraph text-content-default mb-p50", children: "You will lose any values entered for custom fields associated with this list." }), customFields && customFields.length > 0 && _jsx(ListFields, {})] }), _jsx(ModalFooter, { children: _jsx(Button, { onClick: onConfirmRemoval, type: "negative", emphasis: "high", label: "Remove from list" }) })] }));
};
export default ConfirmRemoveFromListModal;
