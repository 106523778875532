import { urnFrom } from 'common/utils/urn';
import { EntityType } from 'hooks/useDashboardLocation';
import { makeAutoObservable, runInAction } from 'mobx';
import { AssistantId, assistantCreateThread, assistantDeleteThread, assistantSearchThreads, assistantStreamResponse } from 'utils/agentGraphApi';
import { AGENT_GRAPH_RESEARCH_API_URL } from 'utils/constants';
import { logger } from 'utils/logger';
import { createCompanyDiligenceReport, getCompanyDiligenceReport, getReportByThreadId } from 'utils/midtierApi';
class CompanyResearchStore {
    rootStore;
    companyResearch = null;
    loading = false;
    sidebarOpen = false;
    error = undefined;
    diligenceReport = null;
    diligenceReportLoading = false;
    exampleQuestions = [];
    isLoadingExamples = false;
    exampleQueriesFetched = false;
    threadProcessingMessages = {};
    companyUrn = null;
    pollingInterval = null;
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }
    startPolling() {
        if (this.pollingInterval)
            return;
        this.pollingInterval = setInterval(async () => {
            try {
                // Poll diligence report if it's busy
                if (this.diligenceReport?.status === 'busy') {
                    try {
                        await this.fetchAndUpdateThread(this.diligenceReport.thread_id);
                    }
                    catch (error) {
                        runInAction(() => {
                            if (this.diligenceReport) {
                                this.diligenceReport.status = 'error';
                            }
                        });
                    }
                }
                // Poll busy threads in company research
                if (this.companyResearch) {
                    const busyThreads = this.companyResearch.threads.filter((thread) => thread.status === 'busy');
                    await Promise.all(busyThreads.map(async (thread) => {
                        try {
                            await this.fetchAndUpdateThread(thread.thread_id);
                        }
                        catch (error) {
                            runInAction(() => {
                                const threadIndex = this.companyResearch.threads.findIndex((t) => t.thread_id === thread.thread_id);
                                if (threadIndex !== -1) {
                                    this.companyResearch.threads[threadIndex].status = 'error';
                                }
                            });
                        }
                    }));
                }
            }
            catch (error) {
                logger.error('Failed to poll thread updates', { error });
            }
        }, 3000);
    }
    stopPolling() {
        if (this.pollingInterval) {
            clearInterval(this.pollingInterval);
            this.pollingInterval = null;
        }
    }
    setCompany(id) {
        this.companyUrn = urnFrom(EntityType.COMPANY, id);
        this.resetCompany();
        this.startPolling();
    }
    handleToggleSidebar = () => {
        this.sidebarOpen = !this.sidebarOpen;
    };
    resetCompany() {
        this.companyResearch = null;
        this.loading = false;
        this.error = undefined;
        this.diligenceReport = null;
        this.diligenceReportLoading = false;
        this.exampleQuestions = [];
        this.isLoadingExamples = false;
        this.exampleQueriesFetched = false;
        this.threadProcessingMessages = {};
        this.stopPolling();
    }
    async fetchThreads() {
        if (!this.companyUrn) {
            logger.error('Cannot fetch threads: no company URN set');
            return;
        }
        if (!this.companyResearch) {
            this.companyResearch = {
                threads: [],
                loading: false,
                error: undefined,
                hasMore: true,
                currentPage: 0,
                pageSize: 10
            };
        }
        runInAction(() => {
            this.companyResearch.loading = true;
            this.companyResearch.error = undefined;
        });
        try {
            const threadData = await assistantSearchThreads({
                limit: this.companyResearch.pageSize,
                offset: 0,
                metadata: {
                    company_urn: this.companyUrn,
                    assistant_id: AssistantId.COMPANY_RESEARCH_REPORT_BUILDER
                }
            }, AGENT_GRAPH_RESEARCH_API_URL);
            runInAction(() => {
                this.companyResearch.threads = threadData;
                this.companyResearch.currentPage = 0;
                this.companyResearch.hasMore =
                    threadData.length === this.companyResearch.pageSize;
                this.companyResearch.loading = false;
            });
        }
        catch (error) {
            runInAction(() => {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                this.companyResearch.error = error;
                this.companyResearch.loading = false;
            });
            logger.error(`Failed to fetch company research threads: ${error}`);
        }
    }
    async loadNextPage() {
        if (!this.companyUrn) {
            logger.error('Cannot load next page: no company URN set');
            return;
        }
        if (!this.companyResearch ||
            !this.companyResearch.hasMore ||
            this.companyResearch.loading) {
            return;
        }
        runInAction(() => {
            this.companyResearch.loading = true;
            this.companyResearch.error = undefined;
        });
        try {
            const nextPage = this.companyResearch.currentPage + 1;
            const threadData = await assistantSearchThreads({
                limit: this.companyResearch.pageSize,
                offset: nextPage * this.companyResearch.pageSize,
                metadata: {
                    company_urn: this.companyUrn,
                    assistant_id: AssistantId.COMPANY_RESEARCH_REPORT_BUILDER
                }
            }, AGENT_GRAPH_RESEARCH_API_URL);
            runInAction(() => {
                this.companyResearch.threads = [
                    ...this.companyResearch.threads,
                    ...threadData
                ];
                this.companyResearch.currentPage = nextPage;
                this.companyResearch.hasMore =
                    threadData.length === this.companyResearch.pageSize;
                this.companyResearch.loading = false;
            });
        }
        catch (error) {
            runInAction(() => {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                this.companyResearch.error = error;
                this.companyResearch.loading = false;
            });
            logger.error(`Failed to load next page of research threads: ${error}`);
        }
    }
    async createNewThread(params) {
        if (!this.companyUrn) {
            logger.error('Cannot create new thread: no company URN set');
            return null;
        }
        if (!this.companyResearch) {
            this.companyResearch = {
                threads: [],
                loading: false,
                error: undefined,
                hasMore: true,
                currentPage: 0,
                pageSize: 10
            };
        }
        try {
            const threadMetadata = {
                company_urn: this.companyUrn,
                assistant_id: AssistantId.COMPANY_RESEARCH_REPORT_BUILDER,
                report_type: 'custom_report',
                research_mode: params.researchMode ?? 'quick'
            };
            // Create the thread first
            const thread = await assistantCreateThread(AGENT_GRAPH_RESEARCH_API_URL, threadMetadata);
            // Add the thread to the list with initial metadata
            const initialThread = {
                ...thread,
                status: 'busy',
                values: {
                    research_report_sections_input: {
                        user_input: params.question,
                        research_mode: params.researchMode
                    }
                },
                metadata: threadMetadata
            };
            runInAction(() => {
                this.companyResearch.threads = [
                    initialThread,
                    ...this.companyResearch.threads
                ];
            });
            // Start streaming the response
            await assistantStreamResponse({
                assistantId: AssistantId.COMPANY_RESEARCH_REPORT_BUILDER,
                keep: true,
                baseApiUrl: AGENT_GRAPH_RESEARCH_API_URL,
                threadId: thread.thread_id,
                metadata: threadMetadata,
                graphInput: {
                    company_urn: this.companyUrn,
                    research_report_sections_input: {
                        user_input: params.question
                    },
                    research_mode: params.researchMode
                },
                onChunkReceived: (chunk) => {
                    if (chunk.error) {
                        throw new Error(chunk.error);
                    }
                    if (chunk.event) {
                        // Update the thread with real data
                        runInAction(() => {
                            const threadIndex = this.companyResearch.threads.findIndex((t) => t.thread_id === thread.thread_id);
                            if (threadIndex !== -1) {
                                this.companyResearch.threads[threadIndex] = {
                                    ...this.companyResearch.threads[threadIndex],
                                    updated_at: new Date().toISOString(),
                                    metadata: chunk.metadata
                                };
                            }
                        });
                    }
                    if (chunk.event === 'on_custom_event') {
                        if (chunk.name === 'step_start') {
                            runInAction(() => {
                                if (!this.threadProcessingMessages[thread.thread_id]) {
                                    this.threadProcessingMessages[thread.thread_id] = [];
                                }
                                this.threadProcessingMessages[thread.thread_id].push(chunk.data.display_message);
                            });
                        }
                        if (chunk.name === 'step_end') {
                            // Only remove the message if it's a subtask (generally parallelized)
                            if (chunk.data.step_type === 'subtask') {
                                runInAction(() => {
                                    if (this.threadProcessingMessages[thread.thread_id]) {
                                        this.threadProcessingMessages[thread.thread_id] =
                                            this.threadProcessingMessages[thread.thread_id].filter((message) => message !== chunk.data.display_message);
                                    }
                                });
                            }
                        }
                    }
                },
                onComplete: () => {
                    // No need to update threads array since we already have the thread
                    // The thread's status and content will be updated through the streaming updates
                }
            });
            return initialThread;
        }
        catch (error) {
            logger.error('Failed to create new research thread', { error });
            return null;
        }
    }
    async deleteThread(threadId) {
        if (!this.companyResearch)
            return;
        try {
            await assistantDeleteThread(threadId, AGENT_GRAPH_RESEARCH_API_URL);
            runInAction(() => {
                this.companyResearch.threads = this.companyResearch.threads.filter((thread) => thread.thread_id !== threadId);
            });
        }
        catch (error) {
            logger.error('Failed to delete thread', { error });
            throw error;
        }
    }
    async fetchCompanyDiligenceReport() {
        if (!this.companyUrn) {
            logger.error('Cannot fetch diligence report: no company URN set');
            return;
        }
        runInAction(() => {
            this.diligenceReportLoading = true;
        });
        try {
            const report = await getCompanyDiligenceReport(this.companyUrn);
            runInAction(() => {
                this.diligenceReport = report;
                this.diligenceReportLoading = false;
            });
        }
        catch (error) {
            logger.error('Failed to fetch company diligence report', { error });
            runInAction(() => {
                this.diligenceReport = null;
                this.diligenceReportLoading = false;
            });
            throw error;
        }
    }
    async createCompanyDiligenceReport() {
        if (!this.companyUrn) {
            logger.error('Cannot create diligence report: no company URN set');
            return;
        }
        runInAction(() => {
            this.diligenceReportLoading = true;
        });
        try {
            const report = await createCompanyDiligenceReport(this.companyUrn);
            runInAction(() => {
                this.diligenceReport = { ...report, status: 'busy' };
                this.diligenceReportLoading = false;
            });
        }
        catch (error) {
            logger.error('Failed to create company diligence report', { error });
            runInAction(() => {
                this.diligenceReport = null;
                this.diligenceReportLoading = false;
            });
            throw error;
        }
    }
    async fetchAndUpdateThread(threadId) {
        try {
            const thread = await getReportByThreadId(threadId);
            runInAction(() => {
                // If this is a diligence report, update the diligence report state
                if (thread.metadata?.report_type === 'company_diligence_report') {
                    this.diligenceReport = thread;
                    return;
                }
                // Update the thread in the history stack
                if (this.companyResearch) {
                    const threadIndex = this.companyResearch.threads.findIndex((t) => t.thread_id === threadId);
                    if (threadIndex !== -1) {
                        this.companyResearch.threads[threadIndex] = thread;
                    }
                }
            });
        }
        catch (error) {
            logger.error('Failed to fetch and update thread', { error, threadId });
            throw error;
        }
    }
    async fetchExampleQuestions() {
        if (!this.companyUrn || this.exampleQuestions.length > 0)
            return;
        runInAction(() => {
            this.isLoadingExamples = true;
        });
        try {
            let suggestions = [];
            await assistantStreamResponse({
                assistantId: 'company_research_assistant',
                keep: false,
                graphInput: {
                    company_identifiers: [
                        {
                            urn: this.companyUrn
                        }
                    ],
                    include_research: false,
                    include_suggestions: true
                },
                prompt: `Generate example questions`,
                onChunkReceived: (chunk) => {
                    if (chunk.error) {
                        return;
                    }
                    if (chunk.event === 'on_chain_end' &&
                        chunk.metadata.langgraph_node === 'question_suggestions') {
                        if (Array.isArray(chunk.data.output.research_question_suggestions)) {
                            suggestions = chunk.data.output.research_question_suggestions;
                        }
                    }
                },
                onComplete: () => {
                    runInAction(() => {
                        if (suggestions.length > 0) {
                            this.exampleQuestions = suggestions;
                        }
                        this.isLoadingExamples = false;
                        this.exampleQueriesFetched = true;
                    });
                }
            });
        }
        catch (error) {
            logger.error('Failed to fetch example questions', { error });
            runInAction(() => {
                this.isLoadingExamples = false;
            });
        }
    }
}
export default CompanyResearchStore;
