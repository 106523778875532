import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { GRID_INITIAL_PAGE_SIZE } from '../../../../utils/constants';
import { camelize } from '../../../../utils/utilities';
import { getFieldSearchQuery } from '../utils';
export const useBoardColumnVariables = ({ watchlistId, fieldUrn, fieldValueUrn, fieldType, searchVariables }) => {
    const searchQuery = getFieldSearchQuery({
        watchlistId,
        fieldUrn,
        fieldValueUrn,
        fieldType
    });
    return useMemo(() => {
        if (!searchQuery)
            return;
        const camelizedSearchQuery = cloneDeep(camelize(searchQuery));
        return {
            ...searchVariables,
            first: GRID_INITIAL_PAGE_SIZE,
            page: null,
            size: null,
            skipExtended: false,
            searchQuery: {
                ...camelizedSearchQuery,
                filterGroup: {
                    ...camelizedSearchQuery.filterGroup,
                    filters: [
                        ...(searchVariables.searchQuery?.filterGroup?.filters ?? []),
                        ...camelizedSearchQuery.filterGroup.filters
                    ]
                }
            }
        };
    }, [searchVariables, searchQuery]);
};
