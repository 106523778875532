import { appStore } from './useAppStore';
export const useAppState = (selector) => {
    return appStore(selector);
};
export const useDashboardState = () => {
    const dashboardState = useAppState((state) => state.dashboard);
    return dashboardState;
};
export const useLocalSearchState = () => {
    const localSearchState = useAppState((state) => state.localSearch);
    return localSearchState;
};
