import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CircleQuestionmarkIcon from 'assets/harmonic-icons/circle-questionmark';
import classNames from 'classnames';
import { Tooltip } from 'common/components';
import Button from 'harmonic-components/Button/Button';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import { clone } from 'lodash';
import React, { useState } from 'react';
const Checkboxes = ({ options, onValueChange, selectedValues = [], excludedValues = [], maxOptionsLimit = 6, enableExcludeMode = false, showAllIfSelected: showAllIfSelected = false, disabled, onCheckChange, dataTestId, singleColumn = false }) => {
    const [showAllOptions, setShowAllOptions] = useState(!showAllIfSelected ? false : selectedValues.length > 0);
    const onChange = (checked, fieldName) => {
        let newSelectedValues = clone(selectedValues);
        let newExcludedValues = clone(excludedValues);
        if (checked) {
            newSelectedValues.push(fieldName);
        }
        else {
            newSelectedValues = newSelectedValues.filter((value) => value !== fieldName);
        }
        newExcludedValues = newExcludedValues.filter((value) => value !== fieldName);
        onValueChange(newSelectedValues.length > 0 ? newSelectedValues : undefined, newExcludedValues.length > 0 ? newExcludedValues : undefined);
        onCheckChange && onCheckChange(checked, fieldName);
    };
    const onExclude = (fieldName) => {
        let newSelectedValues = clone(selectedValues);
        let newExcludedValues = clone(excludedValues);
        const alreadyExcluded = excludedValues.includes(fieldName);
        newSelectedValues = newSelectedValues.filter((value) => value !== fieldName);
        if (!alreadyExcluded) {
            newExcludedValues.push(fieldName);
        }
        else {
            newExcludedValues = newExcludedValues.filter((value) => value !== fieldName);
        }
        onValueChange(newSelectedValues.length > 0 ? newSelectedValues : undefined, newExcludedValues.length > 0 ? newExcludedValues : undefined);
    };
    React.useEffect(() => {
        if (showAllIfSelected && selectedValues.length > 0) {
            setShowAllOptions(selectedValues.length > 0);
        }
    }, [selectedValues, showAllIfSelected]);
    const showToggleButton = options.length > maxOptionsLimit;
    return (_jsxs("div", { "data-testid": dataTestId, className: "w-full flex flex-col gap-g50", children: [_jsx("div", { className: classNames('grid', singleColumn ? 'grid-cols-1' : 'grid-cols-2', 'gap-y-2 gap-x-10'), children: options
                    ?.slice(0, showAllOptions ? options.length : maxOptionsLimit)
                    .map((option) => (_jsxs("div", { className: "flex flex-row gap-g20", children: [_jsx(Checkbox, { checked: selectedValues.includes(option.value) ||
                                excludedValues.includes(option.value), variant: selectedValues.includes(option.value)
                                ? 'default'
                                : excludedValues.includes(option.value)
                                    ? 'exclude'
                                    : 'default', onChange: (e) => {
                                const checked = e.target.checked;
                                onChange(checked, option.value);
                            }, disabled: disabled, showExcludeButton: enableExcludeMode, onExclude: () => onExclude(option.value), label: option.label, secondaryLabel: option.secondaryLabel, fullWidth: true, id: option.label, dataTestId: `${dataTestId}-${option.value}` }), option.tooltipLabel && (_jsx(Tooltip, { body: option.tooltipLabel, children: _jsx(CircleQuestionmarkIcon, {}) }))] }, `${option.value}`))) }), showToggleButton && (_jsx("div", { className: "flex-shrink", children: _jsx(Button, { emphasis: "high", label: !showAllOptions ? 'Show more' : 'Show less', onClick: () => setShowAllOptions(!showAllOptions) }) }))] }));
};
export default Checkboxes;
