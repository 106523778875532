import { gql } from '@apollo/client';
const RemoveCompanyWatchlistCustomField = gql `
  mutation RemoveCompanyWatchlistCustomField(
    $watchlistUrn: CompanyWatchlistUrn!
    $customFieldUrn: CompanyListCustomFieldUrn!
  ) {
    removeCompanyWatchlistCustomField(
      watchlistUrn: $watchlistUrn
      customFieldUrn: $customFieldUrn
    ) {
      urn
    }
  }
`;
export default RemoveCompanyWatchlistCustomField;
