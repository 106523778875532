import { jsx as _jsx } from "react/jsx-runtime";
import { formatCustomFieldDate } from '../../../../utils/custom-fields';
import { withPendingDot } from './components/WithPendingDot';
export const BaseCustomColumnDateCellRenderer = (props) => {
    const value = props.value?.value;
    const dateFormat = props.dateFormat;
    const headerName = props.colDef?.headerName;
    const formattedValue = formatCustomFieldDate(dateFormat, value ?? '');
    if (props.showEmptyValue && !formattedValue) {
        return (_jsx("p", { className: "typography-paragraph text-content-weak", children: `Add ${headerName}` }));
    }
    return (_jsx("div", { className: "inline-block", children: _jsx("p", { className: "typography-paragraph text-content-weak", children: formattedValue }) }));
};
export const CustomColumnDateCellRenderer = withPendingDot(BaseCustomColumnDateCellRenderer);
