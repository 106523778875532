import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import DiligenceReportButton from './DiligenceReportButton';
import ExampleQueries from './ExampleQueries';
const CompanyResearchReportOptions = ({ onExampleQuestionClick, isLoading, exampleQuestions, isLoadingExamples, onFetchExampleQueries, question, onGenerateDiligenceReport, isGeneratingReport, companyDomain }) => {
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const showDiligenceReport = !!companyDomain;
    // Calculate total number of selectable items (example questions + diligence report if available)
    const totalSelectableItems = question.trim()
        ? 1 // Just the dynamic query when typing
        : exampleQuestions.length + (showDiligenceReport ? 1 : 0);
    const handleClickQuestion = useCallback(async (question) => {
        await onExampleQuestionClick(question);
    }, [onExampleQuestionClick]);
    const handleGenerateDiligenceReport = onGenerateDiligenceReport;
    // Fetch example queries on first render
    useEffect(() => {
        onFetchExampleQueries();
    }, [onFetchExampleQueries]);
    // Handle keyboard navigation
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (isLoading || isLoadingExamples)
                return;
            // If user is typing, only allow selection of the dynamic query
            if (question.trim()) {
                if (e.key === 'Enter' && selectedIndex === 0) {
                    e.preventDefault();
                    handleClickQuestion(question);
                }
                return;
            }
            // Only handle keyboard navigation when no query is being typed
            if (totalSelectableItems === 0)
                return;
            switch (e.key) {
                case 'ArrowDown':
                    e.preventDefault();
                    setSelectedIndex((prev) => prev < totalSelectableItems - 1 ? prev + 1 : prev);
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
                    break;
                case 'Enter':
                    e.preventDefault();
                    if (selectedIndex >= 0 && selectedIndex < exampleQuestions.length) {
                        handleClickQuestion(exampleQuestions[selectedIndex]);
                    }
                    else if (selectedIndex === exampleQuestions.length &&
                        showDiligenceReport) {
                        onGenerateDiligenceReport();
                    }
                    break;
                default:
                    break;
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [
        selectedIndex,
        exampleQuestions,
        isLoading,
        isLoadingExamples,
        handleClickQuestion,
        question,
        totalSelectableItems,
        onGenerateDiligenceReport,
        showDiligenceReport
    ]);
    // Set selectedIndex to 0 when user starts typing
    useEffect(() => {
        if (question.trim()) {
            setSelectedIndex(0);
        }
        else {
            setSelectedIndex(-1);
        }
    }, [question]);
    const handleOnMouseEnter = (index) => {
        setSelectedIndex(index);
    };
    const handleOnMouseLeave = () => {
        setSelectedIndex(-1);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ExampleQueries, { onExampleQuestionClick: handleClickQuestion, isLoading: isLoading, exampleQuestions: exampleQuestions, isLoadingExamples: isLoadingExamples, question: question, selectedIndex: selectedIndex, onMouseEnter: handleOnMouseEnter, onMouseLeave: handleOnMouseLeave }), !!showDiligenceReport && !question.trim() && (_jsx(DiligenceReportButton, { onGenerateDiligenceReport: handleGenerateDiligenceReport, isLoading: isLoading, isGeneratingReport: isGeneratingReport, isSelected: selectedIndex === exampleQuestions.length, onMouseEnter: () => handleOnMouseEnter(exampleQuestions.length), onMouseLeave: handleOnMouseLeave }))] }));
};
export default CompanyResearchReportOptions;
