import { isElementInViewport } from 'components/Dashboard/Grids/CellRenderers/utils';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LOADING_ROW_ID } from 'utils/constants';
export const useDebouncedRowLoader = (rows, fetchMore) => {
    const [gridFetchMoreLoading, setGridFetchMoreLoading] = useState(false);
    const debouncedFetchMore = useMemo(() => debounce(async (e) => {
        if (e.direction === 'vertical') {
            const agRowLast = document.querySelector('.ag-row-last');
            if (agRowLast && isElementInViewport(agRowLast)) {
                setGridFetchMoreLoading(true);
                await fetchMore?.();
                setGridFetchMoreLoading(false);
            }
        }
    }, 250), [fetchMore]);
    const [applyShadow, setApplyShadow] = useState(false);
    const onScroll = useCallback((e) => {
        if (e.left > 0) {
            setApplyShadow(true);
        }
        else {
            setApplyShadow(false);
        }
        debouncedFetchMore(e);
    }, [debouncedFetchMore]);
    useEffect(() => {
        const pinnedHeader = document.querySelector('.ag-pinned-left-header');
        const pinnedColumnsContainer = document.querySelector('.ag-pinned-left-cols-container');
        if (applyShadow) {
            pinnedHeader?.classList.add('pinnedHeaderShadowEffect');
            pinnedColumnsContainer?.classList.add('pinnedColumnsShadowEffect');
        }
        else {
            pinnedHeader?.classList.remove('pinnedHeaderShadowEffect');
            pinnedColumnsContainer?.classList.remove('pinnedColumnsShadowEffect');
        }
    }, [applyShadow]);
    const finalRows = useMemo(() => gridFetchMoreLoading
        ? [...(rows ?? []), { id: LOADING_ROW_ID }]
        : rows ?? [], [gridFetchMoreLoading, rows]);
    return { finalRows, onScroll };
};
