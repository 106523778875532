import { GET_WATCHLIST_FRAGMENT_WITH_COMPANY_IDS } from 'queries/getWatchlist';
import upsertCompanyWatchlistNamedViewMutation from 'queries/upsertCompanyWatchlistNamedViewMutation';
import useStore from 'stores/zustandStore';
import { COMPANY_WATCHLIST_GRID_VIEW_DEFAULT_COLUMNS, COMPANY_WATCHLIST_KANBAN_VIEW_DEFAULT_COLUMNS, getSelectedCompanyNamedViewIndex } from 'utils/namedViews';
import useFetchSingleWatchlist from './useFetchSingleWatchlist';
import useUpsertWatchlistNamedViews from './useUpsertWatchlistNamedViews';
const useUpsertCompanyWatchlistNamedViews = () => {
    const companyListSelectedNamedView = useStore((state) => state.companyListSelectedNamedView);
    const { companyWatchlist } = useFetchSingleWatchlist();
    return useUpsertWatchlistNamedViews({
        upsertMutation: upsertCompanyWatchlistNamedViewMutation,
        watchlistFragment: GET_WATCHLIST_FRAGMENT_WITH_COMPANY_IDS,
        namedViewCacheIdName: 'CompanyListNamedView',
        watchlistCacheIdName: 'CompanyWatchlist',
        watchlistFragmentName: 'WatchlistWithCompanyId',
        backendResponseKey: 'upsertCompanyListNamedView',
        getSelectedNamedViewIndex: getSelectedCompanyNamedViewIndex,
        customFieldCacheIdName: 'CompanyListCustomField',
        watchlist: companyWatchlist,
        selectedNamedView: companyListSelectedNamedView,
        gridViewTypeDefaultColumns: COMPANY_WATCHLIST_GRID_VIEW_DEFAULT_COLUMNS,
        kanbanViewTypeDefaultColumns: COMPANY_WATCHLIST_KANBAN_VIEW_DEFAULT_COLUMNS
    });
};
export default useUpsertCompanyWatchlistNamedViews;
