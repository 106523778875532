import { useStore } from '../config/StoreProvider';
const useFetchSinglePeopleWatchlist = () => {
    const { rootStore } = useStore();
    return {
        peopleWatchlist: rootStore.peopleWatchlistViewStore.watchlist?.watchlistWithDraftState,
        peopleWatchlistLoading: rootStore.peopleWatchlistViewStore.loading,
        peopleWatchlistError: rootStore.peopleWatchlistViewStore.error
    };
};
export default useFetchSinglePeopleWatchlist;
