export var CustomFieldType;
(function (CustomFieldType) {
    CustomFieldType["CHECKBOX"] = "CHECKBOX";
    CustomFieldType["DATE"] = "DATE";
    CustomFieldType["MULTI_SELECT"] = "MULTI_SELECT";
    CustomFieldType["NUMBER"] = "NUMBER";
    CustomFieldType["PERSON"] = "PERSON";
    CustomFieldType["SINGLE_SELECT"] = "SINGLE_SELECT";
    CustomFieldType["STATUS"] = "STATUS";
    CustomFieldType["TEXT"] = "TEXT";
    CustomFieldType["WEBSITE"] = "WEBSITE";
})(CustomFieldType || (CustomFieldType = {}));
