import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from 'harmonic-components/Button/Button';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import { useModal } from 'hooks/useModal';
const GenericDeleteConfirmationModal = ({ title, description, onConfirm }) => {
    const { close } = useModal();
    const handleOnConfirm = () => {
        onConfirm();
        close();
    };
    const handleClose = close;
    return (_jsxs("div", { children: [_jsx(ModalTitle, { title: title }), _jsx("div", { className: "pb-p80 px-p20 pt-p20", children: _jsx("p", { className: "p-p40", children: description }) }), _jsxs(ModalFooter, { children: [_jsx(Button, { label: "Cancel", type: "secondary", emphasis: "medium", onClick: handleClose }), _jsx(Button, { label: "Delete", type: "negative", emphasis: "high", onClick: handleOnConfirm, dataTestId: "Delete-Btn" })] })] }));
};
export default GenericDeleteConfirmationModal;
