import { jsx as _jsx } from "react/jsx-runtime";
import useDashboardLocation from 'hooks/useDashboardLocation';
import useFlags from 'hooks/useFlags';
import useObservableSavedSearch from 'hooks/useObservableSavedSearch';
import { observer } from 'mobx-react';
import React from 'react';
import { useShallowStore } from 'stores/zustandStore';
import { SPLITS } from 'utils/constants';
import { convertPeopleListColumnsConfigToUrns } from 'utils/namedViews';
import { useStore } from '../../../../../config/StoreProvider';
import { getUpdatedColumnsForSave } from '../../EditColumnsModal/utils';
import { useFieldsDropdown } from '../FieldsDropdownItems/FieldsDropdownItems';
import PeopleSearchFieldsDropdownItems from '../FieldsDropdownItems/PeopleSearchFieldsDropdownItems';
import { HeaderWithDropdown } from '../HeaderWithDropdown';
import { SortDropdownItems } from '../SortDropdownItems';
export const PeopleSearchHeader = observer((props) => {
    const { enabled: enableSortInHeader } = useFlags(SPLITS.enableSortInHeader);
    const { enabled: enableInsertAdjacentToField } = useFlags(SPLITS.enableInsertAdjacentToField);
    const { rootStore } = useStore();
    const { urn } = useDashboardLocation();
    const { userUrn } = useShallowStore(['userUrn']);
    const { currentPageSavedSearch } = useObservableSavedSearch();
    const isSearchOwner = currentPageSavedSearch?.creator?.entityUrn === userUrn;
    const changeSort = props.context.changeSort;
    const colDef = props.column.getColDef();
    const colId = props.column.getColId();
    const sortField = colDef.searchModelIdentifier;
    const sortable = colDef.sortable && sortField;
    const showDropdown = Boolean(enableSortInHeader && sortable) ||
        Boolean(enableInsertAdjacentToField && (!urn || isSearchOwner));
    const save = (visibleColumns, cols) => {
        const updatedColumns = getUpdatedColumnsForSave(cols, false);
        rootStore.peopleExploreViewStore.updateTableColumns(updatedColumns);
    };
    const { onFieldClick } = useFieldsDropdown({
        colId,
        save,
        convertColumnsConfigToUrns: convertPeopleListColumnsConfigToUrns
    });
    return (_jsx(HeaderWithDropdown, { params: props, showDropdown: showDropdown, renderFields: (filterTerm, insertFieldMode, onClose) => (_jsx(PeopleSearchFieldsDropdownItems, { onFieldClick: (field) => {
                onFieldClick(field, insertFieldMode);
                onClose();
            }, filterTerm: filterTerm })), children: () => enableSortInHeader && sortable ? (_jsx(SortDropdownItems, { changeSort: changeSort, field: sortField })) : null }));
});
