import { LoggerEvent, logger } from './logger';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function lazyRetry(componentImport, chunkIdentifier) {
    const sessionStorageKey = `retry-lazy-refreshed-${chunkIdentifier}`;
    return new Promise((resolve, reject) => {
        const hasRefreshed = JSON.parse(window.sessionStorage.getItem(sessionStorageKey) || 'false');
        const startTime = performance.now();
        logger.sendPerformanceEvent(LoggerEvent.MODULE_LOADING_STARTED, {
            module: chunkIdentifier
        });
        componentImport()
            .then((component) => {
            window.sessionStorage.setItem(sessionStorageKey, 'false');
            resolve(component);
        })
            .catch((error) => {
            if (!hasRefreshed) {
                window.sessionStorage.setItem(sessionStorageKey, 'true');
                window.location.reload();
            }
            reject(error);
        })
            .finally(() => {
            logger.sendPerformanceEvent(LoggerEvent.MODULE_LOADING_COMPLETED, {
                module: chunkIdentifier,
                duration: performance.now() - startTime
            });
        });
    });
}
