import { EntityActionType } from 'components/common/EntityActions/EntityActionType';
import useDashboardLocation, { DashboardLocation } from './useDashboardLocation';
import { getUrnFragment } from 'common/utils/urn';
import useFlags from 'hooks/useFlags';
import { SPLITS } from 'utils/constants';
import useFetchWatchlists from './useFetchWatchlists';
const useEntityActionsAvailableByLocation = () => {
    const { likedCompaniesWatchlist, hiddenCompaniesWatchlist, affinityLists } = useFetchWatchlists();
    const { enabled: enableCustomFieldBulkActions } = useFlags(SPLITS.enableCustomFieldBulkActions);
    const { urn, location } = useDashboardLocation();
    const watchlistId = getUrnFragment(urn, 3) || '';
    const isCompanyWatchlistPage = location === DashboardLocation.COMPANY_LIST;
    const isPersonSavedSearchPage = location === DashboardLocation.PEOPLE_SEARCH;
    const isPersonWatchlistPage = location === DashboardLocation.PERSON_LIST;
    const isLikedCompaniesWatchlistPage = isCompanyWatchlistPage && watchlistId === likedCompaniesWatchlist?.id;
    const isHiddenCompaniesWatchlistPage = isCompanyWatchlistPage && watchlistId === hiddenCompaniesWatchlist?.id;
    const isNonSingletonCompanyWatchlistPage = isCompanyWatchlistPage &&
        !isLikedCompaniesWatchlistPage &&
        !isHiddenCompaniesWatchlistPage;
    const isAffinityListPage = isCompanyWatchlistPage &&
        affinityLists?.some((list) => list?.id === watchlistId);
    let actionsToShow = [];
    if (isPersonSavedSearchPage) {
        actionsToShow = [EntityActionType.ADD_TO_WATCHLIST];
    }
    else if (isPersonWatchlistPage) {
        actionsToShow = [
            EntityActionType.ADD_TO_WATCHLIST,
            EntityActionType.REMOVE_FROM_CURRENT_LIST,
            ...(enableCustomFieldBulkActions
                ? [EntityActionType.EDIT_CUSTOM_FIELD_VALUE]
                : [])
        ];
    }
    else if (isAffinityListPage) {
        actionsToShow = [
            EntityActionType.TOGGLE_LIKE,
            EntityActionType.ADD_TO_WATCHLIST
        ];
    }
    else if (isNonSingletonCompanyWatchlistPage) {
        actionsToShow = [
            EntityActionType.TOGGLE_LIKE,
            EntityActionType.ADD_TO_WATCHLIST,
            EntityActionType.REMOVE_FROM_CURRENT_LIST,
            ...(enableCustomFieldBulkActions
                ? [EntityActionType.EDIT_CUSTOM_FIELD_VALUE]
                : [])
        ];
    }
    else {
        actionsToShow = [
            EntityActionType.TOGGLE_LIKE,
            EntityActionType.TOGGLE_VIEW,
            EntityActionType.ADD_TO_WATCHLIST,
            EntityActionType.TOGGLE_HIDE
        ];
    }
    return { entityActionsAvailable: actionsToShow };
};
export default useEntityActionsAvailableByLocation;
