import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NumberListCustomFieldValueFormat } from '__generated__/graphql';
import Button from 'harmonic-components/Button/Button';
import TextInput from 'harmonic-components/TextInput/TextInput';
import { useState } from 'react';
import { formatNumberListCustomFieldValue, parseNumberListCustomFieldValue } from 'utils/utilities';
import { Form } from '../Form';
const EditCustomFieldsNumberValue = ({ customField, onCancel, onSave, onClear }) => {
    const [value, setValue] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const numberFormat = customField?.metadata?.__typename === 'NumberListCustomFieldMetadata'
        ? customField.metadata.numberFormat
        : null;
    const handleOnChange = (e) => {
        setValue(parseNumberListCustomFieldValue(e.target.value));
    };
    const formattedNumber = formatNumberListCustomFieldValue(value, numberFormat, false);
    const handleOnCancel = () => {
        onCancel();
    };
    const handleOnSave = async () => {
        setLoading(true);
        if (value === undefined) {
            await onClear();
        }
        else {
            await onSave(Number(value.toString().replace(/,/g, '')));
        }
        setLoading(false);
    };
    return (_jsx(Form, { onSubmit: handleOnSave, children: _jsxs("div", { className: "w-full", children: [_jsx(TextInput, { dataTestId: "EditCustomFieldsNumberValue", fullWidth: true, prefix: numberFormat === NumberListCustomFieldValueFormat.US_DOLLAR
                        ? '$'
                        : undefined, suffix: numberFormat === NumberListCustomFieldValueFormat.PERCENT
                        ? '%'
                        : undefined, type: "decimal", placeholder: "Number value", value: formattedNumber, onChange: handleOnChange, autoFocus: true, isDisabled: loading }), _jsxs("div", { className: "flex gap-g40 mt-p50", children: [_jsx(Button, { fullWidth: true, type: "secondary", emphasis: "medium", size: "compact", label: "Cancel", onClick: handleOnCancel, isDisabled: loading }), _jsx(Button, { dataTestId: "EditCustomFieldsNumberValue-SaveButton", fullWidth: true, type: "primary", emphasis: "high", size: "compact", label: "Save", onClick: handleOnSave, loading: loading, isDisabled: loading })] })] }) }));
};
export default EditCustomFieldsNumberValue;
