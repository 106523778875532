import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ExcludeInvertedIcon, ListIcon } from 'assets/harmonic-icons';
import { useStore } from 'config/StoreProvider';
import IconButton from 'harmonic-components/IconButton/IconButton';
import ListStack from 'harmonic-components/ListStack/ListStack';
import useFlags from 'hooks/useFlags';
import { observer } from 'mobx-react-lite';
import { SPLITS } from 'utils/constants';
import CompanyResearchDrawer from '../CompanyResearch/CompanyResearchDrawer';
const CompanySidepanel = observer(() => {
    const { rootStore } = useStore();
    const { company, selectedTab, setSelectedTab } = rootStore.companyViewStore;
    const { enabled: enableResearchReportBuilderUi } = useFlags(SPLITS.agentEnableResearchReportBuilderUi);
    const handleSetCustomFieldValues = (input) => {
        rootStore.companyWatchlistViewStore.setCustomFieldValues(input);
    };
    if (!company?.data) {
        return null;
    }
    const handleSelectedTabChange = (tab) => {
        setSelectedTab(tab);
    };
    if (!enableResearchReportBuilderUi) {
        return (_jsx("div", { className: "w-[400px] flex flex-col top-[100px] bottom-0 bg-white fixed z-[10000000000] right-0 border-l border-solid border-border", children: _jsx("div", { className: "p-p40 overflow-y-auto flex-1", children: _jsx(ListStack, { listEntries: rootStore.companyViewStore.company?.listEntries || [], onSetCustomFieldValues: handleSetCustomFieldValues }) }) }));
    }
    return (_jsxs("div", { className: "w-[400px] flex flex-col top-[100px] bottom-0 bg-white fixed z-[10000000000] right-0 border-l border-solid border-border", children: [_jsxs("div", { className: "flex justify-center gap-4 py-1 border-b border-solid border-border shrink-0 h-[46px]", children: [_jsx(IconButton, { icon: ListIcon, onClick: () => handleSelectedTabChange('list'), isSelected: selectedTab === 'list', type: "secondary", emphasis: "low", size: "default" }), _jsx(IconButton, { icon: ExcludeInvertedIcon, onClick: () => handleSelectedTabChange('research'), isSelected: selectedTab === 'research', type: "secondary", emphasis: "low", size: "default" })] }), _jsx("div", { className: "p-p40 overflow-y-auto flex-1", children: selectedTab === 'list' ? (_jsx(ListStack, { listEntries: rootStore.companyViewStore.company?.listEntries || [], loading: rootStore.companyViewStore.company?.listEntriesLoading, onSetCustomFieldValues: handleSetCustomFieldValues })) : (_jsx(CompanyResearchDrawer, { companyId: company.data.id, companyName: company.data.name ?? '', companyDomain: company.data.website?.domain ?? undefined })) })] }));
});
export default CompanySidepanel;
