import { FacebookLikesMetricCombined, HeadcountMetricCombined, InstagramFollowersMetricCombined, LinkedInFollowerMetricCombined, TwitterFollowerMetricCombined, WebTrafficMetricCombined } from './common';
const TractionOptions = [
    HeadcountMetricCombined,
    WebTrafficMetricCombined,
    LinkedInFollowerMetricCombined,
    FacebookLikesMetricCombined,
    TwitterFollowerMetricCombined,
    InstagramFollowersMetricCombined
];
export default TractionOptions;
