import PersonColumnsConfig from 'interfaces/PersonColumnsConfig';
const AllOptions = [
    {
        label: PersonColumnsConfig.PERSON_HIGHLIGHTS.label,
        value: PersonColumnsConfig.PERSON_HIGHLIGHTS.value
    },
    {
        label: PersonColumnsConfig.EMAILS.label,
        value: PersonColumnsConfig.EMAILS.value
    },
    {
        label: PersonColumnsConfig.PRIMARY_EMAIL.label,
        value: PersonColumnsConfig.PRIMARY_EMAIL.value
    },
    {
        label: PersonColumnsConfig.EDUCATION.label,
        value: PersonColumnsConfig.EDUCATION.value
    },
    {
        label: PersonColumnsConfig.FIRST_NAME.label,
        value: PersonColumnsConfig.FIRST_NAME.value
    },
    {
        label: PersonColumnsConfig.CURRENT_LOCATION.label,
        value: PersonColumnsConfig.CURRENT_LOCATION.value
    },
    {
        label: PersonColumnsConfig.LAST_NAME.label,
        value: PersonColumnsConfig.LAST_NAME.value
    },
    {
        label: PersonColumnsConfig.LINKEDIN_HEADLINE.label,
        value: PersonColumnsConfig.LINKEDIN_HEADLINE.value
    },
    {
        label: PersonColumnsConfig.LINKEDIN.label,
        value: PersonColumnsConfig.LINKEDIN.value
    },
    {
        label: PersonColumnsConfig.ID.label,
        value: PersonColumnsConfig.ID.value
    }
];
export default AllOptions;
