import { getIdFromUrn } from 'common/utils/urn';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { SearchType } from '../../__generated__/graphql';
import client from '../../config/client';
import { appStore } from '../../hooks/useAppStore';
import { GET_SAVED_SEARCH } from '../../queries/getSavedSearch';
import { convertCompanyColumnsConfigToUrns, convertPeopleListColumnsConfigToUrns } from '../../utils/namedViews';
import { INITIAL_SEARCH_CONTROLLED_FILTER_GROUP, INITIAL_SEARCH_MODEL } from '../../interfaces/SearchModel/Search';
import { UPDATE_SAVED_SEARCH } from '../../queries/updateSavedSearch';
import analytics, { CustomTrackEvent, EventLocations, SearchVersion } from '../../utils/analytics';
import { GRID_INITIAL_PAGE_SIZE, PageTitle } from '../../utils/constants';
import { removeArtifactsFromSearchQuery, transformSearchModelFromApiv2ForDashboard } from '../../utils/search';
import { camelize } from '../../utils/utilities';
import useTableStore from '../tableStore';
class SavedSearchViewStore {
    rootStore;
    savedSearch = null;
    error = undefined;
    loading = false;
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        reaction(() => this.savedSearch, () => {
            this.initializeTableColumns();
            this.initializeSearchModel();
        });
    }
    async setSavedSearch(idOrUrn) {
        this.resetSavedSearch();
        this.fetchAndSetSavedSearch(idOrUrn);
    }
    /**
     * Resets the saved search and local saved search
     * to prevent interference from previous saved searches
     */
    resetSavedSearch() {
        this.savedSearch = null;
        this.error = undefined;
        this.loading = false;
    }
    async fetchAndSetSavedSearch(idOrUrn) {
        this.loading = true;
        const { savedSearch, error } = await this.rootStore.savedSearchStore.get(idOrUrn);
        this.loading = false;
        runInAction(() => {
            this.savedSearch = savedSearch;
            this.error = error;
        });
        this.rootStore.setTitle({
            documentTitle: this.savedSearch?.savedSearchWithDraftState?.name ||
                PageTitle.SAVED_SEARCH_PAGE_TITLE,
            pageTitle: this.savedSearch?.savedSearchWithDraftState?.name ||
                PageTitle.SAVED_SEARCH_PAGE_TITLE
        });
    }
    /**
     * Returns the computed tableColumns of the current saved search
     */
    get tableColumns() {
        return this.savedSearch?.tableColumns ?? [];
    }
    /**
     * Initialize tableColumns on Zustand for components
     * relying on it, e.g. export, bulk actions
     */
    initializeTableColumns() {
        useTableStore
            .getState()
            .editTableStoreData('tableColumns', this.tableColumns);
    }
    /**
     * Initialize searchModel on Zustand for hooks
     * relying on searchModel of dashboard, e.g. peopleSearch when
     * previewing draft state of people saved search
     */
    initializeSearchModel() {
        const searchModel = transformSearchModelFromApiv2ForDashboard(this.savedSearch?.savedSearchWithDraftState?.searchQuery);
        appStore.getState().dashboard.updateSearchModel({
            ...searchModel,
            controlled_filter_group: searchModel.controlled_filter_group ??
                INITIAL_SEARCH_CONTROLLED_FILTER_GROUP,
            pagination: {
                start: 0,
                page_size: GRID_INITIAL_PAGE_SIZE
            }
        });
        this.logSavedSearch();
        this.cleanUpSearchParams();
    }
    /**
     * Logs the saved search to analytics
     */
    logSavedSearch() {
        analytics.trackCustomEvent({
            event: CustomTrackEvent.SEARCH_INITIATED,
            properties: {
                openedFromLocation: this.savedSearch?.savedSearchWithDraftState?.type ===
                    SearchType.PERSONS
                    ? EventLocations.PEOPLE_SEARCH
                    : EventLocations.COMPANY_SEARCH,
                version: SearchVersion.V1,
                searchQuery: JSON.stringify(this.savedSearch?.savedSearchWithDraftState?.searchQuery),
                savedSearchId: this.savedSearch?.savedSearchWithDraftState?.id
            }
        });
    }
    /**
     * Cleans up the search params from the url
     * and tracks a custom event for the search page load
     */
    cleanUpSearchParams() {
        const searchParams = new URLSearchParams(window.location.search);
        const utmSource = searchParams.get('utm_source');
        const utmContent = searchParams.get('utm_content');
        // If the page was loaded from another harmonic application and
        // we have some utm content, use it to track a custom event for
        // the search page load
        const searchId = this.savedSearch?.savedSearchWithDraftState?.id;
        if (utmSource === 'harmonic' && utmContent && searchId) {
            analytics.trackCustomEvent({
                event: CustomTrackEvent.SAVED_SEARCH_PAGE_LOAD,
                properties: {
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    openedFromLocation: utmContent,
                    searchId
                }
            });
        }
        // Remove the utm params so we do not attribute the click when
        // a user shares the link via copy/paste
        const cleanParams = new URLSearchParams(searchParams);
        cleanParams.delete('utm_source');
        cleanParams.delete('utm_content');
        const cleanParamsString = cleanParams.toString();
        window.history.replaceState(null, '', cleanParamsString ? `?${cleanParamsString}` : window.location.pathname);
    }
    /**
     * Updates the search model of the current saved search
     */
    updateSavedSearchModel(searchQuery) {
        appStore.getState().dashboard.updateSearchModel({
            ...INITIAL_SEARCH_MODEL,
            filter_group: searchQuery.filter_group,
            controlled_filter_group: searchQuery.controlled_filter_group
        });
        const payload = {
            query: {
                ...INITIAL_SEARCH_MODEL,
                controlled_filter_group: searchQuery.controlled_filter_group,
                filter_group: searchQuery.filter_group,
                sort: this.savedSearch?.savedSearchWithDraftState?.searchQuery?.sort
            },
            clear_net_new: true
        };
        return this.updateSavedSearch({
            idOrUrn: this.savedSearch?.savedSearchWithDraftState?.entityUrn ?? '',
            input: payload,
            clearResultsCache: true
        });
    }
    /**
     * Updates the current save search
     */
    updateSavedSearch({ idOrUrn, input, clearResultsCache, skipUpsertLocalSearch }) {
        const updateSavedSearchInput = camelize(input);
        if (input.query) {
            updateSavedSearchInput.query = removeArtifactsFromSearchQuery(input.query);
        }
        if (!skipUpsertLocalSearch && this.savedSearch?.savedSearchWithDraftState) {
            const urn = idOrUrn.startsWith('urn')
                ? idOrUrn
                : `urn:harmonic:saved_search:${idOrUrn}`;
            appStore.getState().localSearch.updateLocalSearch(urn, {
                ...this.savedSearch?.savedSearchWithDraftState,
                name: input.name ?? this.savedSearch?.savedSearchWithDraftState?.name ?? '',
                searchQuery: input.query ??
                    this.savedSearch?.savedSearchWithDraftState?.searchQuery ??
                    '',
                visibleColumns: input.visibleColumns ??
                    this.savedSearch?.savedSearchWithDraftState?.visibleColumns ??
                    []
            });
            client.cache.evict({
                id: client.cache.identify({
                    __typename: 'SavedSearch',
                    id: this.savedSearch?.savedSearchWithDraftState?.id
                }),
                fieldName: 'results'
            });
            return;
        }
        return client.mutate({
            mutation: UPDATE_SAVED_SEARCH,
            variables: {
                idOrUrn,
                updateSavedSearchInput
            },
            ...(this.savedSearch?.savedSearchWithDraftState && {
                optimisticResponse: {
                    updateSavedSearch: {
                        ...this.savedSearch?.savedSearchWithDraftState,
                        ...updateSavedSearchInput
                    }
                }
            }),
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GET_SAVED_SEARCH,
                    variables: { idOrUrn: getIdFromUrn(idOrUrn) ?? 'NA' }
                }
            ],
            update: (cache, { data }) => {
                cache.writeQuery({
                    query: GET_SAVED_SEARCH,
                    variables: { id: data?.updateSavedSearch.id },
                    data: {
                        getSavedSearch: data?.updateSavedSearch
                    }
                });
                // We want to clear the results cache for the saved search if the query has changed
                if (clearResultsCache) {
                    cache.evict({
                        id: cache.identify({
                            __typename: 'SavedSearch',
                            id: data?.updateSavedSearch.id
                        }),
                        fieldName: 'results'
                    });
                }
            }
        });
    }
    /**
     * Updates the tableColumns fo the current saved search
     */
    updateTableColumns(columnHeaders) {
        if (!this.savedSearch?.savedSearchWithDraftState)
            return;
        // TODO: refactor into separate stores for each type
        const visibleColumns = this.savedSearch?.savedSearchWithDraftState?.search_type ===
            SearchType.COMPANIES_LIST
            ? convertCompanyColumnsConfigToUrns(columnHeaders)
            : convertPeopleListColumnsConfigToUrns(columnHeaders);
        appStore
            .getState()
            .localSearch.updateLocalSearch(this.savedSearch?.savedSearchWithDraftState?.entityUrn ?? '', {
            ...this.savedSearch?.savedSearchWithDraftState,
            visibleColumns
        });
        useTableStore.getState().editTableStoreData('tableColumns', columnHeaders);
    }
}
export default SavedSearchViewStore;
