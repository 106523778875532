import { AuthStatus } from 'interfaces/Auth';
import { makeAutoObservable, runInAction, when } from 'mobx';
import { logger } from 'utils/logger';
import { getCustomerAffinityApiKeyIsSet } from 'utils/midtierApi';
class AffinityStore {
    rootStore;
    isIntegrated = false;
    isIntegrationStatusLoading = false;
    /**
     * Initializes the affinity store.
     * @param rootStore - The root store instance.
     */
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        when(() => this.rootStore.authStore.authStatus === AuthStatus.AUTHENTICATED, () => {
            this.fetchAffinityIntegrationStatus();
        });
    }
    /**
     * Fetches and updates the Affinity integration status.
     * @returns A promise that resolves to the integration status (isSet value).
     */
    async fetchAffinityIntegrationStatus() {
        try {
            runInAction(() => {
                this.isIntegrationStatusLoading = true;
            });
            const isIntegrated = await getCustomerAffinityApiKeyIsSet();
            runInAction(() => {
                this.isIntegrated = isIntegrated;
            });
            return isIntegrated;
        }
        catch (error) {
            logger.error(`Error checking Affinity integration: ${
            //eslint-disable-next-line
            error && error.message}`, {
                error,
                code_area: 'affinity_integration'
            });
            runInAction(() => {
                this.isIntegrated = false;
            });
            return false;
        }
        finally {
            runInAction(() => {
                this.isIntegrationStatusLoading = false;
            });
        }
    }
}
export default AffinityStore;
