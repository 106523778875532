import { useState } from 'react';
import { displayToast } from 'utils/toasts';
import { useStore } from '../config/StoreProvider';
import { logger } from '../utils/logger';
const useUpdateSavedSearch = () => {
    const { rootStore } = useStore();
    const [loading, setLoading] = useState(false);
    const updateSavedSearch = async ({ idOrUrn, input, clearResultsCache, skipUpsertLocalSearch, toast, logging }) => {
        setLoading(true);
        const res = await rootStore.savedSearchViewStore.updateSavedSearch({
            idOrUrn,
            input,
            clearResultsCache,
            skipUpsertLocalSearch
        });
        setLoading(false);
        if (skipUpsertLocalSearch) {
            if (res?.data?.updateSavedSearch) {
                displayToast({
                    primaryText: toast?.successMessage ?? 'Successfully updated saved search',
                    mode: 'success'
                });
            }
            else {
                displayToast({
                    primaryText: 'There was a problem updating the saved search',
                    mode: 'error'
                });
                logger.error('Company or people saved search update error', {
                    error: res?.errors,
                    code_area: logging?.codeArea
                });
            }
        }
    };
    return {
        loading,
        updateSavedSearch
    };
};
export default useUpdateSavedSearch;
