import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LeadInvestorIcon, PlusIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { getIdFromUrn } from 'common/utils/urn';
import InternalLink from 'components/common/InternalLink';
import Avatar from 'harmonic-components/Avatar/Avatar';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { getFundingTypeDisplayName } from 'interfaces/SearchModel/Search';
import { ReportSourceSection, ReportSourceView } from 'interfaces/UserReportedDataIssue';
import React, { useState } from 'react';
import { DASHBOARD_ROUTE, INVESTOR_ROUTE } from 'utils/constants';
import { getFundingRoundStyle } from 'utils/funding';
import { SuggestFundingRoundForInvestorModal } from './SuggestFundingRoundForInvestorModal';
export const InvestorPill = ({ investorName, investorLogoUrl, investorUrn, small = false, isLead = false, roundTypes, investorModalOptions }) => {
    const id = getIdFromUrn(investorUrn);
    const shouldRenderModal = investorModalOptions?.allowModal && (!roundTypes || roundTypes?.size === 0);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const handleModalClick = () => {
        setTooltipOpen(false);
        investorModalOptions?.setModalOpen(true);
    };
    const handleTooltipHover = (open) => {
        setTooltipOpen(open);
    };
    const renderPill = () => {
        return (_jsxs("div", { title: investorName, "data-testId": 'CompanyFinancing-Rbr-Investor', className: classNames(`h-5 max-w-50 rounded-md px-0.5 flex gap-1 items-center`, {
                'bg-investors-overlay-default hover:bg-investors-overlay-hover active:bg-investors-overlay-pressed': isLead && investorUrn,
                'bg-int-fill-secondary-enabled hover:bg-int-fill-secondary-hover active:bg-int-fill-secondary-pressed': !isLead && investorUrn,
                'bg-investors-overlay-disabled cursor-default': isLead && !investorUrn,
                'bg-int-fill-secondary-disabled cursor-default': !isLead && !investorUrn
            }), children: [_jsx("div", { "data-testid": "investor-avatar-container", "data-visual-test": "transparent", children: _jsx(Avatar, { size: "small", src: investorLogoUrl ?? '', alt: investorName }) }), _jsx("div", { className: classNames('font-normal truncate', {
                        'text-xs': small,
                        'text-sm': !small
                    }), "data-testid": `CompanyFinancing-Rbr-InvestorPill-Name`, children: investorName }), _jsxs("div", { className: "flex gap-0.5", children: [!!isLead && (_jsx("div", { "data-testid": "lead-investor-icon", className: "flex items-center justify-center bg-expressive-264-midOverlay rounded size-4", children: _jsx(LeadInvestorIcon, {}) })), !!roundTypes &&
                            roundTypes.size > 0 &&
                            Array.from(roundTypes).map((roundType) => {
                                if (!roundType)
                                    return null;
                                const fundingRoundStyle = getFundingRoundStyle(roundType);
                                const roundAbbr = fundingRoundStyle.pillString;
                                const displayName = getFundingTypeDisplayName(roundType);
                                return (_jsx(Tooltip, { title: displayName, children: _jsx("div", { className: classNames(`flex items-center justify-center ${fundingRoundStyle.pillBg} ${fundingRoundStyle.pillTextColor} text-xs font-normal rounded p-p10 
                    h-4 shadow-lg`, {
                                            'min-w-4': roundAbbr.length === 1,
                                            'min-w-6': roundAbbr.length !== 1
                                        }), children: roundAbbr }) }, roundType));
                            }), shouldRenderModal ? (_jsx(Tooltip, { title: "Suggest a funding round", body: "Listed as an investor on the investor's website, but not linked to a known funding event.", open: tooltipOpen, children: _jsxs("div", { className: classNames(`flex items-center justify-center rounded p-p10 
                    h-4 min-w-4`), onMouseEnter: () => handleTooltipHover(true), onMouseLeave: () => handleTooltipHover(false), children: [_jsx(IconButton, { icon: PlusIcon, onClick: handleModalClick, type: "secondary", dataTestId: "InvestorPill-SuggestFundingRound", size: "tiny" }), _jsx(SuggestFundingRoundForInvestorModal, { investorName: investorName, fundingRounds: investorModalOptions?.fundingRounds ?? [], reportParams: {
                                            companyUrn: 'urn:company:harmonic:' + investorModalOptions?.companyId,
                                            reportSourceView: ReportSourceView.COMPANY,
                                            reportSourceSection: ReportSourceSection.FINANCING
                                        }, modalOpen: investorModalOptions?.modalOpen ?? false, setModalOpen: investorModalOptions?.setModalOpen })] }) })) : null] })] }));
    };
    if (!investorUrn) {
        return renderPill();
    }
    return (_jsx(InternalLink, { to: { pathname: `${DASHBOARD_ROUTE}${INVESTOR_ROUTE}/${id}` }, children: renderPill() }));
};
