import { jsx as _jsx } from "react/jsx-runtime";
import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
import { logger } from 'utils/logger';
import { formatMoney, getStageColor, processQuadrantData } from './utils/parseMarketMap';
const DEFAULT_WIDTH = 1000;
const DEFAULT_HEIGHT = 600;
export const MarketMapDiagram = ({ content }) => {
    const { quadrantName, companies } = content;
    return (_jsx(QuadrantChart, { quadrantName: quadrantName, companies: companies, width: 700, height: 480 }));
};
/**
 * A single-break scatter plot:
 * - X = Years since founding
 * - Y = Headcount
 * - R = Circle size (total funding)
 * - Color = Funding stage
 */
const QuadrantChart = ({ quadrantName, companies, width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT }) => {
    const svgRef = useRef(null);
    useEffect(() => {
        if (!svgRef.current)
            return;
        // -------------------------------------------------------------------------
        // 1. Create a unique tooltip ID for this chart instance
        // -------------------------------------------------------------------------
        const tooltipId = `tooltip-${quadrantName.replace(/\s+/g, '-')}`;
        try {
            // 2. Remove only this quadrant's tooltip (not all .tooltip)
            d3.select('body').selectAll(`#${tooltipId}`).remove();
        }
        catch (error) {
            logger.error('Failed to remove tooltip:', { error });
        }
        // 3. Early-exit if no companies
        if (!companies?.length) {
            const svgEl = d3.select(svgRef.current);
            svgEl.selectAll('*').remove();
            svgEl
                .append('text')
                .attr('x', 20)
                .attr('y', 40)
                .text(`${quadrantName}: No data`);
            return;
        }
        // -------------------------------------------------------------------------
        // 4. Clear previous contents from the SVG
        // -------------------------------------------------------------------------
        const svgEl = d3.select(svgRef.current);
        svgEl.selectAll('*').remove();
        // -------------------------------------------------------------------------
        // 5. Process raw data
        // -------------------------------------------------------------------------
        const { processed, xExtent, yExtent, rExtent } = processQuadrantData(companies);
        if (!processed.length) {
            svgEl
                .append('text')
                .attr('x', 20)
                .attr('y', 40)
                .text(`${quadrantName}: No valid data`);
            return;
        }
        // -------------------------------------------------------------------------
        // 6. Dimensions & margins
        // -------------------------------------------------------------------------
        const maxR = 40; // Maximum circle radius
        const margin = {
            top: maxR + 20,
            right: maxR * 2 + 30, // Double the radius (for full circle) plus safety margin
            bottom: 100,
            left: 80
        };
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;
        // -------------------------------------------------------------------------
        // 7. X & Y extents
        // -------------------------------------------------------------------------
        const xMin = 0;
        const xMax = xExtent[1];
        const yMin = 0;
        const yMax = yExtent[1];
        // Avoid dividing by zero
        if (xMax <= 0)
            processed.forEach((p) => (p.x = 0));
        if (yMax <= 0)
            processed.forEach((p) => (p.y = 0));
        // -------------------------------------------------------------------------
        // 8. Identify the largest gap on each axis (single-break approach)
        // -------------------------------------------------------------------------
        function findLargestGap(values) {
            let largestGap = 0;
            let breakStart = 0;
            let breakEnd = 0;
            for (let i = 1; i < values.length; i++) {
                const gap = values[i] - values[i - 1];
                if (gap > largestGap) {
                    largestGap = gap;
                    breakStart = values[i - 1];
                    breakEnd = values[i];
                }
            }
            return { gap: largestGap, start: breakStart, end: breakEnd };
        }
        // Sort values for x & y
        const xValues = processed.map((d) => d.x).sort((a, b) => a - b);
        const yValues = processed.map((d) => d.y).sort((a, b) => a - b);
        const largestXGap = findLargestGap(xValues);
        const largestYGap = findLargestGap(yValues);
        // Decide thresholds for "meaningful" break
        function averageGap(values) {
            if (values.length < 2)
                return 0;
            let sum = 0;
            for (let i = 1; i < values.length; i++) {
                sum += values[i] - values[i - 1];
            }
            return sum / (values.length - 1);
        }
        const xAvgGap = averageGap(xValues);
        const yAvgGap = averageGap(yValues);
        const xNeedsBreak = largestXGap.gap > xAvgGap * 3 && largestXGap.gap > 0.1 * xMax;
        const yNeedsBreak = largestYGap.gap > yAvgGap * 3 && largestYGap.gap > 0.1 * yMax;
        // We'll compress the break to a small fraction of the axis length
        const compressedFraction = 0.08; // 8% of axis allocated to the break
        // -------------------------------------------------------------------------
        // 9. Build xScale / yScale (single-break logic)
        // -------------------------------------------------------------------------
        function makeXScale(val) {
            if (!xNeedsBreak || xMax <= xMin) {
                // No break => normal scale
                return margin.left + ((val - xMin) / (xMax - xMin)) * innerWidth;
            }
            const { start, end } = largestXGap;
            const normalRange = end - start;
            const compressedPixels = compressedFraction * innerWidth;
            const totalNormalPixels = innerWidth - compressedPixels;
            const belowLength = start - xMin;
            const aboveLength = xMax - end;
            const normalDomainLength = xMax - xMin - normalRange;
            const belowPixels = (belowLength / normalDomainLength) * totalNormalPixels;
            const abovePixels = (aboveLength / normalDomainLength) * totalNormalPixels;
            if (val <= start) {
                const ratio = (val - xMin) / (belowLength || 1);
                return margin.left + ratio * belowPixels;
            }
            else if (val >= end) {
                const ratio = (val - end) / (aboveLength || 1);
                return (margin.left + belowPixels + compressedPixels + ratio * abovePixels);
            }
            else {
                // within the break
                const ratio = (val - start) / normalRange;
                return margin.left + belowPixels + ratio * compressedPixels;
            }
        }
        function makeYScale(val) {
            if (!yNeedsBreak || yMax <= yMin) {
                // No break => normal scale
                const ratio = (val - yMin) / (yMax - yMin);
                return height - margin.bottom - ratio * innerHeight;
            }
            const { start, end } = largestYGap;
            const normalRange = end - start;
            const compressedPixels = compressedFraction * innerHeight;
            const totalNormalPixels = innerHeight - compressedPixels;
            const belowLength = start - yMin;
            const aboveLength = yMax - end;
            const normalDomainLength = yMax - yMin - normalRange;
            const belowPixels = (belowLength / normalDomainLength) * totalNormalPixels;
            const abovePixels = (aboveLength / normalDomainLength) * totalNormalPixels;
            if (val <= start) {
                const ratio = (val - yMin) / (belowLength || 1);
                return height - margin.bottom - ratio * belowPixels;
            }
            else if (val >= end) {
                const ratio = (val - end) / (aboveLength || 1);
                return (height -
                    margin.bottom -
                    belowPixels -
                    compressedPixels -
                    ratio * abovePixels);
            }
            else {
                // in the break
                const ratio = (val - start) / normalRange;
                return height - margin.bottom - belowPixels - ratio * compressedPixels;
            }
        }
        // -------------------------------------------------------------------------
        // 10. Radius scale for funding
        // -------------------------------------------------------------------------
        const [rMin, rMax] = rExtent;
        const minR = 8;
        const rRange = rMax - rMin || 1;
        function rScale(val) {
            const norm = (val - rMin) / rRange;
            return minR + norm * (maxR - minR);
        }
        // -------------------------------------------------------------------------
        // 11. Create main SVG
        // -------------------------------------------------------------------------
        const svg = svgEl
            .attr('width', width)
            .attr('height', height)
            .style('pointer-events', 'all');
        // -------------------------------------------------------------------------
        // 12. Create a unique tooltip for this chart
        // -------------------------------------------------------------------------
        const tooltip = d3
            .select('body')
            .append('div')
            .attr('id', tooltipId)
            .attr('class', 'tooltip quadrant-tooltip')
            .style('position', 'absolute')
            .style('visibility', 'hidden')
            .style('background-color', 'white')
            .style('border', '1px solid #ddd')
            .style('border-radius', '8px')
            .style('padding', '12px')
            .style('box-shadow', '0 4px 6px rgba(0,0,0,0.1)')
            .style('font-size', '14px')
            .style('pointer-events', 'none')
            .style('max-width', '300px')
            .style('z-index', '9999');
        // -------------------------------------------------------------------------
        // 13. Create ticks, filtering out break region
        // -------------------------------------------------------------------------
        const xTickCount = 8;
        const yTickCount = 8;
        const xLinear = d3
            .scaleLinear()
            .domain([xMin, xMax])
            .range([0, innerWidth]);
        const yLinear = d3
            .scaleLinear()
            .domain([yMin, yMax])
            .range([innerHeight, 0]);
        const xTicks = xLinear.ticks(xTickCount);
        const yTicks = yLinear.ticks(yTickCount);
        const xBreakStart = xNeedsBreak ? largestXGap.start : -1;
        const xBreakEnd = xNeedsBreak ? largestXGap.end : -1;
        const validXTicks = xNeedsBreak
            ? xTicks.filter((t) => t <= xBreakStart || t >= xBreakEnd)
            : xTicks;
        const yBreakStart = yNeedsBreak ? largestYGap.start : -1;
        const yBreakEnd = yNeedsBreak ? largestYGap.end : -1;
        const validYTicks = yNeedsBreak
            ? yTicks.filter((t) => t <= yBreakStart || t >= yBreakEnd)
            : yTicks;
        // -------------------------------------------------------------------------
        // 14. Draw grid lines and axes
        // -------------------------------------------------------------------------
        // X grid lines
        svg
            .selectAll('line.x-grid')
            .data(validXTicks)
            .enter()
            .append('line')
            .attr('class', 'x-grid')
            .attr('x1', (d) => makeXScale(d))
            .attr('x2', (d) => makeXScale(d))
            .attr('y1', margin.top)
            .attr('y2', height - margin.bottom)
            .attr('stroke', '#e5e7eb')
            .attr('stroke-dasharray', '2,2');
        // X axis base line
        svg
            .append('line')
            .attr('x1', margin.left)
            .attr('x2', width - margin.right)
            .attr('y1', height - margin.bottom)
            .attr('y2', height - margin.bottom)
            .attr('stroke', '#000');
        // X axis labels
        svg
            .selectAll('text.x-tick')
            .data(validXTicks)
            .enter()
            .append('text')
            .attr('class', 'x-tick')
            .attr('x', (d) => makeXScale(d))
            .attr('y', height - margin.bottom + 25)
            .attr('text-anchor', 'middle')
            .style('font-size', '12px')
            .style('fill', '#666')
            .text((d) => d.toFixed(1));
        // Y grid lines
        svg
            .selectAll('line.y-grid')
            .data(validYTicks)
            .enter()
            .append('line')
            .attr('class', 'y-grid')
            .attr('x1', margin.left)
            .attr('x2', width - margin.right)
            .attr('y1', (d) => makeYScale(d))
            .attr('y2', (d) => makeYScale(d))
            .attr('stroke', '#e5e7eb')
            .attr('stroke-dasharray', '2,2');
        // Y axis base line
        svg
            .append('line')
            .attr('x1', margin.left)
            .attr('x2', margin.left)
            .attr('y1', margin.top)
            .attr('y2', height - margin.bottom)
            .attr('stroke', '#000');
        // Y axis labels
        svg
            .selectAll('text.y-tick')
            .data(validYTicks)
            .enter()
            .append('text')
            .attr('class', 'y-tick')
            .attr('x', margin.left - 10)
            .attr('y', (d) => makeYScale(d))
            .attr('text-anchor', 'end')
            .attr('dominant-baseline', 'middle')
            .style('font-size', '12px')
            .style('fill', '#666')
            .text((d) => Math.round(d));
        // -------------------------------------------------------------------------
        // 15. Plot circles (nodes)
        // -------------------------------------------------------------------------
        const nodes = svg
            .selectAll('.node')
            .data(processed)
            .enter()
            .append('g')
            .attr('class', 'node')
            .attr('transform', (d) => `translate(${makeXScale(d.x)}, ${makeYScale(d.y)})`);
        nodes
            .append('circle')
            .attr('r', (d) => rScale(d.totalFunding))
            .attr('fill', (d) => d.color)
            .attr('fill-opacity', 0.15)
            .attr('stroke', (d) => d.color)
            .attr('stroke-width', 2)
            .on('mouseover', function (event, d) {
            d3.select(this).attr('fill-opacity', 0.3).attr('stroke-width', 3);
            // NOTE: Round or floor 'd.x' to get a proper integer year.
            const foundedYear = new Date().getFullYear() - Math.round(d.x);
            tooltip
                .style('visibility', 'visible')
                .style('left', `${event.pageX + 10}px`)
                .style('top', `${event.pageY + 10}px`).html(`
            <div style="margin-bottom: 8px;">
              <div style="font-weight: bold; font-size: 16px; margin-bottom: 4px;">${d.name}</div>
              <div style="color: ${d.color}; font-weight: 600;">${d.stageDisplayName}</div>
            </div>
            <div style="display: grid; gap: 4px;">
              <div><strong>Founded:</strong> ${foundedYear}</div>
              <div><strong>Headcount:</strong> ${Math.round(d.y).toLocaleString()}</div>
              <div><strong>Total Funding:</strong> ${formatMoney(d.totalFunding)}</div>
              <div><strong>Years Active:</strong> ${d.x.toFixed(1)}</div>
            </div>
          `);
        })
            .on('mousemove', function (event) {
            tooltip
                .style('left', `${event.pageX + 10}px`)
                .style('top', `${event.pageY + 10}px`);
        })
            .on('mouseout', function () {
            d3.select(this).attr('fill-opacity', 0.15).attr('stroke-width', 2);
            tooltip.style('visibility', 'hidden');
        });
        // Add logo images, clipped to circles
        nodes
            .append('image')
            .attr('href', (d) => d.logoUrl)
            .attr('width', (d) => rScale(d.totalFunding))
            .attr('height', (d) => rScale(d.totalFunding))
            .attr('x', (d) => -rScale(d.totalFunding) / 2)
            .attr('y', (d) => -rScale(d.totalFunding) / 2)
            .attr('clip-path', 'circle(50% at 50% 50%)')
            .on('mouseover', function (event, d) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            d3.select(this.parentNode)
                .select('circle')
                .attr('fill-opacity', 0.3)
                .attr('stroke-width', 3);
            const foundedYear = new Date().getFullYear() - Math.round(d.x);
            tooltip
                .style('visibility', 'visible')
                .style('left', `${event.pageX + 10}px`)
                .style('top', `${event.pageY + 10}px`).html(`
            <div style="margin-bottom: 8px;">
              <div style="font-weight: bold; font-size: 16px; margin-bottom: 4px;">${d.name}</div>
              <div style="color: ${d.color}; font-weight: 600;">${d.stageDisplayName}</div>
            </div>
            <div style="display: grid; gap: 4px;">
              <div><strong>Founded:</strong> ${foundedYear}</div>
              <div><strong>Headcount:</strong> ${Math.round(d.y).toLocaleString()}</div>
              <div><strong>Total Funding:</strong> ${formatMoney(d.totalFunding)}</div>
              <div><strong>Years Active:</strong> ${d.x.toFixed(1)}</div>
            </div>
          `);
        })
            .on('mousemove', function (event) {
            tooltip
                .style('left', `${event.pageX + 10}px`)
                .style('top', `${event.pageY + 10}px`);
        })
            .on('mouseout', function () {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            d3.select(this.parentNode)
                .select('circle')
                .attr('fill-opacity', 0.15)
                .attr('stroke-width', 2);
            tooltip.style('visibility', 'hidden');
        });
        // -------------------------------------------------------------------------
        // 16. Axis labels
        // -------------------------------------------------------------------------
        svg
            .append('text')
            .attr('x', margin.left + innerWidth / 2)
            .attr('y', height - margin.bottom + 60)
            .attr('text-anchor', 'middle')
            .style('font-size', '14px')
            .text('Years Since Founding');
        svg
            .append('text')
            .attr('x', margin.left - 50)
            .attr('y', margin.top + innerHeight / 2)
            .attr('text-anchor', 'middle')
            .style('font-size', '14px')
            .attr('transform', `rotate(-90, ${margin.left - 50}, ${margin.top + innerHeight / 2})`)
            .text('Headcount');
        // -------------------------------------------------------------------------
        // 17. Create floating legend with info icon trigger
        // -------------------------------------------------------------------------
        const presentStages = [...new Set(processed.map((d) => d.stage))].sort();
        // Create info icon to the left of the y-axis
        const infoGroup = svg
            .append('g')
            .attr('class', 'info-trigger')
            .attr('transform', `translate(${margin.left - 30}, ${margin.top + 10})`);
        // Add circular background
        infoGroup
            .append('circle')
            .attr('r', 12)
            .attr('fill', '#f3f4f6')
            .attr('stroke', '#d1d5db')
            .attr('stroke-width', 1);
        // Add "i" icon
        infoGroup
            .append('text')
            .attr('x', 0)
            .attr('y', 1)
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'middle')
            .style('font-size', '14px')
            .style('font-weight', 'bold')
            .style('font-family', 'serif')
            .style('fill', '#6b7280')
            .text('i');
        // Add subtle pulse animation to help discovery
        infoGroup
            .append('circle')
            .attr('r', 12)
            .attr('fill', 'none')
            .attr('stroke', '#d1d5db')
            .attr('stroke-width', 1)
            .style('opacity', 0.5)
            .append('animate')
            .attr('attributeName', 'r')
            .attr('values', '12;14;12')
            .attr('dur', '2s')
            .attr('repeatCount', '3'); // Show animation 3 times when chart loads
        // Create floating legend (initially hidden)
        const floatingLegend = d3
            .select('body')
            .append('div')
            .attr('id', `legend-${tooltipId}`)
            .attr('class', 'quadrant-tooltip legend-tooltip')
            .style('position', 'absolute')
            .style('visibility', 'hidden')
            .style('background-color', 'white')
            .style('border', '1px solid #ddd')
            .style('border-radius', '8px')
            .style('padding', '16px')
            .style('box-shadow', '0 4px 6px rgba(0,0,0,0.1)')
            .style('font-size', '14px')
            .style('pointer-events', 'none')
            .style('z-index', '9999')
            .style('max-width', '250px');
        // Add legend content
        floatingLegend
            .append('div')
            .style('font-weight', 'bold')
            .style('margin-bottom', '12px')
            .text('Chart Legend');
        // Add funding stages section
        const stagesSection = floatingLegend
            .append('div')
            .style('margin-bottom', '16px');
        stagesSection
            .append('div')
            .style('font-weight', '600')
            .style('margin-bottom', '8px')
            .text('Funding Stages');
        presentStages.forEach((stage) => {
            const stageRow = stagesSection
                .append('div')
                .style('display', 'flex')
                .style('align-items', 'center')
                .style('margin-bottom', '6px');
            stageRow
                .append('div')
                .style('width', '12px')
                .style('height', '12px')
                .style('border-radius', '50%')
                .style('margin-right', '8px')
                .style('background-color', getStageColor(stage))
                .style('opacity', '0.15')
                .style('border', `2px solid ${getStageColor(stage)}`);
            stageRow.append('div').text(stage.replace('_', ' '));
        });
        // Add circle size explanation
        floatingLegend
            .append('div')
            .style('font-weight', '600')
            .text('Circle Size');
        floatingLegend
            .append('div')
            .style('margin-top', '4px')
            .text('Represents total funding amount');
        // Handle info icon interactions
        infoGroup
            .style('cursor', 'pointer')
            .on('mouseover', function (event) {
            d3.select(this).select('circle').attr('fill', '#e5e7eb');
            floatingLegend
                .style('visibility', 'visible')
                .style('left', `${event.pageX + 15}px`)
                .style('top', `${event.pageY + 15}px`);
        })
            .on('mousemove', function (event) {
            floatingLegend
                .style('left', `${event.pageX + 15}px`)
                .style('top', `${event.pageY + 15}px`);
        })
            .on('mouseout', function () {
            d3.select(this).select('circle').attr('fill', '#f3f4f6');
            floatingLegend.style('visibility', 'hidden');
        });
        // -------------------------------------------------------------------------
        // 18. Draw the break markers (using double-slash style)
        // -------------------------------------------------------------------------
        function drawXBreakMarker(breakPos) {
            const slashSize = 6;
            const gap = 2;
            const x = breakPos;
            const y = height - margin.bottom;
            svg
                .append('path')
                .attr('d', `
          M ${x - gap} ${y} l ${-slashSize} ${-slashSize}
          M ${x + gap} ${y} l ${slashSize} ${-slashSize}
        `)
                .attr('stroke', '#000')
                .attr('fill', 'none')
                .attr('stroke-width', 1);
        }
        function drawYBreakMarker(breakPos) {
            const slashSize = 6;
            const gap = 2;
            const x = margin.left;
            const y = breakPos;
            svg
                .append('path')
                .attr('d', `
          M ${x} ${y + gap} l ${-slashSize} ${slashSize}
          M ${x} ${y - gap} l ${-slashSize} ${-slashSize}
        `)
                .attr('stroke', '#000')
                .attr('fill', 'none')
                .attr('stroke-width', 1);
        }
        if (xNeedsBreak) {
            const { start, end } = largestXGap;
            drawXBreakMarker(makeXScale(start));
            drawXBreakMarker(makeXScale(end));
        }
        if (yNeedsBreak) {
            const { start, end } = largestYGap;
            drawYBreakMarker(makeYScale(start));
            drawYBreakMarker(makeYScale(end));
        }
        // -------------------------------------------------------------------------
        // 19. Cleanup on unmount
        // -------------------------------------------------------------------------
        return () => {
            tooltip.remove();
            floatingLegend.remove();
        };
    }, [companies, quadrantName, width, height]);
    return _jsx("svg", { ref: svgRef, style: { maxWidth: '100%', height: 'auto' } });
};
