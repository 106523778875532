import { jsx as _jsx } from "react/jsx-runtime";
import { TextareaAutosize } from '@material-ui/core';
import classNames from 'classnames';
import React, { useState } from 'react';
const TextFieldRenderer = ({ value, onChange, mode, placeholder }) => {
    const [textValue, setTextValue] = useState(value);
    const handleOnChange = (e) => {
        setTextValue(e.target.value);
    };
    const handleBlur = () => {
        onChange(textValue || null);
    };
    const handleOnKeyDown = (e) => {
        e.stopPropagation();
        if (e.code === 'Enter' && !e.shiftKey) {
            handleBlur();
        }
    };
    if (mode === 'read') {
        if (textValue === null) {
            return _jsx("p", { className: "text-input-placeholder-default", children: placeholder });
        }
        return (_jsx("p", { className: "typography-paragraph-default-default text-content-default", children: textValue }));
    }
    return (_jsx(TextareaAutosize, { autoFocus: true, onKeyDown: handleOnKeyDown, onBlur: handleBlur, value: textValue ?? '', onChange: handleOnChange, className: classNames('overflow-scroll py-p20 px-p30 bg-surface-default w-full rounded-br30 border border-solid border-int-outline-secondary-selected-enabled text-content-default typography-paragraph-default-default min-h-[29px]'), placeholder: placeholder, maxRows: 5, minRows: 1 }));
};
export default TextFieldRenderer;
