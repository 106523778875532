import { gql } from '@apollo/client';
export const PEOPLE_WATCHLIST_DIGEST_CONFIG_FRAGMENT = gql `
  fragment PeopleWatchlistDigestConfig on PeopleWatchlistDigestConfig {
    deliveryCadence
    deliveryChannel
    entityUrn
    lastSentAt
    peopleWatchlists
    userUrn
    isEnabled
  }
`;
export const GET_PEOPLE_WATCHLIST_DIGEST_CONFIG = gql `
  query GetPeopleWatchlistDigestConfig {
    getPeopleWatchlistDigestConfig {
      ...PeopleWatchlistDigestConfig
    }
  }
  ${PEOPLE_WATCHLIST_DIGEST_CONFIG_FRAGMENT}
`;
