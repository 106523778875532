import { gql } from '@apollo/client';
import { GET_PEOPLE_WATCHLIST_FRAGMENT } from './getPeopleWatchlists';
export const ADD_PEOPLE_TO_WATCHLIST = gql `
  mutation AddPeopleToWatchlistWithIds(
    $watchlist: String!
    $people: [String]!
  ) {
    addPeopleToWatchlistWithIds(id: $watchlist, people: $people) {
      ...PeopleWatchlist
    }
  }
  ${GET_PEOPLE_WATCHLIST_FRAGMENT}
`;
