import { gql } from '@apollo/client';
const RemovePeopleWatchlistCustomField = gql `
  mutation RemovePeopleWatchlistCustomField(
    $watchlistUrn: PeopleWatchlistUrn!
    $customFieldUrn: PeopleListCustomFieldUrn!
  ) {
    removePeopleWatchlistCustomField(
      watchlistUrn: $watchlistUrn
      customFieldUrn: $customFieldUrn
    ) {
      urn
    }
  }
`;
export default RemovePeopleWatchlistCustomField;
