import { gql } from '@apollo/client';
import { FullPeopleSearch } from './peopleSearchFragment';
const getPersonsByIds = gql `
  query GetPersonsByIds(
    $ids: [Int!]!
    $extended: Boolean!
    $skipExtended: Boolean!
  ) {
    getPersonsByIds(ids: $ids, extended: $extended) {
      ...PeopleSearch
    }
  }
  ${FullPeopleSearch}
`;
export default getPersonsByIds;
