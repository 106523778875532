import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useShallowStoreGeneric } from './util';
/**
 * @deprecated Use the mobx store instead
 */
export const useSidebarStore = create(persist((set) => ({
    isAnimating: false,
    isFloating: false,
    isCollapsed: false,
    editSidebar: (key, payload) => set((state) => ({
        ...state,
        [key]: payload
    }))
}), {
    name: 'persisted-sidebar-store',
    merge: (persistedState, currentState) => {
        return {
            ...currentState,
            ...persistedState,
            // Prevent persisting collapsed state
            isCollapsed: persistedState.isFloating
                ? true
                : currentState.isCollapsed
        };
    }
}));
export const useShallowSidebarStore = (keys) => useShallowStoreGeneric(useSidebarStore, keys);
