import CompanyColumnsConfig from 'interfaces/CompanyColumnsConfig';
import { FacebookLikesMetricCombined, FundingTotalCombined, HeadcountMetricCombined, InstagramFollowersMetricCombined, LinkedInFollowerMetricCombined, TwitterFollowerMetricCombined, WebTrafficMetricCombined } from './common';
const AllOptions = [
    {
        label: CompanyColumnsConfig.ID.label,
        value: CompanyColumnsConfig.ID.value
    },
    {
        label: CompanyColumnsConfig.ADDED_TO_HARMONIC_DATE.label,
        value: CompanyColumnsConfig.ADDED_TO_HARMONIC_DATE.value
    },
    {
        label: CompanyColumnsConfig.ADDED_TO_LIST_DATE.label,
        value: CompanyColumnsConfig.ADDED_TO_LIST_DATE.value
    },
    {
        label: CompanyColumnsConfig.UPDATED_DATE.label,
        value: CompanyColumnsConfig.UPDATED_DATE.value
    },
    {
        label: CompanyColumnsConfig.LEGAL_NAME.label,
        value: CompanyColumnsConfig.LEGAL_NAME.value
    },
    {
        label: CompanyColumnsConfig.RELEVANCE_SCORE.label,
        value: CompanyColumnsConfig.RELEVANCE_SCORE.value
    },
    {
        label: CompanyColumnsConfig.COMPANY_HIGHLIGHTS.label,
        value: CompanyColumnsConfig.COMPANY_HIGHLIGHTS.value
    },
    {
        label: CompanyColumnsConfig.DESCRIPTION.label,
        value: CompanyColumnsConfig.DESCRIPTION.value
    },
    {
        label: CompanyColumnsConfig.EXTERNAL_DESCRIPTION.label,
        value: CompanyColumnsConfig.EXTERNAL_DESCRIPTION.value
    },
    {
        label: CompanyColumnsConfig.TEAM.label,
        value: CompanyColumnsConfig.TEAM.value
    },
    {
        label: CompanyColumnsConfig.FOUNDERS_AND_CEOS.label,
        value: CompanyColumnsConfig.FOUNDERS_AND_CEOS.value
    },
    {
        label: CompanyColumnsConfig.EXECUTIVES.label,
        value: CompanyColumnsConfig.EXECUTIVES.value
    },
    {
        label: CompanyColumnsConfig.LEADERSHIP_PRIOR_COMPANIES.label,
        value: CompanyColumnsConfig.LEADERSHIP_PRIOR_COMPANIES.value
    },
    {
        label: CompanyColumnsConfig.TEAM_CONNECTIONS.label,
        value: CompanyColumnsConfig.TEAM_CONNECTIONS.value
    },
    {
        label: CompanyColumnsConfig.LAST_MEETING_DATE.label,
        value: CompanyColumnsConfig.LAST_MEETING_DATE.value
    },
    {
        label: CompanyColumnsConfig.LAST_MEETING_WITH.label,
        value: CompanyColumnsConfig.LAST_MEETING_WITH.value
    },
    {
        label: CompanyColumnsConfig.LAST_CONTACTED_DATE.label,
        value: CompanyColumnsConfig.LAST_CONTACTED_DATE.value
    },
    {
        label: CompanyColumnsConfig.LAST_CONTACTED_BY.label,
        value: CompanyColumnsConfig.LAST_CONTACTED_BY.value
    },
    {
        label: CompanyColumnsConfig.AFFINITY_LISTS.label,
        value: CompanyColumnsConfig.AFFINITY_LISTS.value
    },
    {
        label: CompanyColumnsConfig.LISTS.label,
        value: CompanyColumnsConfig.LISTS.value
    },
    {
        label: CompanyColumnsConfig.TEAM_LINKEDIN_PROFILES.label,
        value: CompanyColumnsConfig.TEAM_LINKEDIN_PROFILES.value
    },
    {
        label: CompanyColumnsConfig.STATE.label,
        value: CompanyColumnsConfig.STATE.value
    },
    {
        label: CompanyColumnsConfig.COUNTRY.label,
        value: CompanyColumnsConfig.COUNTRY.value
    },
    {
        label: CompanyColumnsConfig.FOUNDING_DATE.label,
        value: CompanyColumnsConfig.FOUNDING_DATE.value
    },
    {
        label: CompanyColumnsConfig.TAGS.label,
        value: CompanyColumnsConfig.TAGS.value
    },
    {
        label: CompanyColumnsConfig.COMPANY_TYPE.label,
        value: CompanyColumnsConfig.COMPANY_TYPE.value
    },
    {
        label: CompanyColumnsConfig.CITY.label,
        value: CompanyColumnsConfig.CITY.value
    },
    {
        label: CompanyColumnsConfig.WEBSITE.label,
        value: CompanyColumnsConfig.WEBSITE.value
    },
    {
        label: CompanyColumnsConfig.LINKEDIN.label,
        value: CompanyColumnsConfig.LINKEDIN.value
    },
    {
        label: CompanyColumnsConfig.STAGE.label,
        value: CompanyColumnsConfig.STAGE.value
    },
    {
        label: CompanyColumnsConfig.NUM_FUNDING_ROUNDS.label,
        value: CompanyColumnsConfig.NUM_FUNDING_ROUNDS.value
    },
    {
        label: CompanyColumnsConfig.LAST_FUNDING_TYPE.label,
        value: CompanyColumnsConfig.LAST_FUNDING_TYPE.value
    },
    {
        label: CompanyColumnsConfig.LAST_FUNDING_DATE.label,
        value: CompanyColumnsConfig.LAST_FUNDING_DATE.value
    },
    {
        label: CompanyColumnsConfig.LAST_FUNDING_TOTAL.label,
        value: CompanyColumnsConfig.LAST_FUNDING_TOTAL.value
    },
    {
        label: CompanyColumnsConfig.FUNDING_PER_EMPLOYEE.label,
        value: CompanyColumnsConfig.FUNDING_PER_EMPLOYEE.value
    },
    {
        label: CompanyColumnsConfig.INVESTORS.label,
        value: CompanyColumnsConfig.INVESTORS.value
    },
    {
        label: CompanyColumnsConfig.CUSTOMER_TYPE.label,
        value: CompanyColumnsConfig.CUSTOMER_TYPE.value
    },
    {
        label: CompanyColumnsConfig.INDUSTRY_TAGS.label,
        value: CompanyColumnsConfig.INDUSTRY_TAGS.value
    },
    {
        label: CompanyColumnsConfig.TECHNOLOGY_TAGS.label,
        value: CompanyColumnsConfig.TECHNOLOGY_TAGS.value
    },
    {
        label: CompanyColumnsConfig.ACCELERATORS.label,
        value: CompanyColumnsConfig.ACCELERATORS.value
    },
    {
        label: CompanyColumnsConfig.COMPANY_EMAILS.label,
        value: CompanyColumnsConfig.COMPANY_EMAILS.value
    },
    {
        label: CompanyColumnsConfig.COMPANY_PRIMARY_EMAIL.label,
        value: CompanyColumnsConfig.COMPANY_PRIMARY_EMAIL.value
    },
    {
        label: CompanyColumnsConfig.TEAM_EMAILS.label,
        value: CompanyColumnsConfig.TEAM_EMAILS.value
    },
    {
        label: CompanyColumnsConfig.PHONE_NUMBER.label,
        value: CompanyColumnsConfig.PHONE_NUMBER.value
    },
    {
        label: CompanyColumnsConfig.TWITTER.label,
        value: CompanyColumnsConfig.TWITTER.value
    },
    {
        label: CompanyColumnsConfig.TWITTER_HANDLE.label,
        value: CompanyColumnsConfig.TWITTER_HANDLE.value
    },
    {
        label: CompanyColumnsConfig.CRUNCHBASE.label,
        value: CompanyColumnsConfig.CRUNCHBASE.value
    },
    {
        label: CompanyColumnsConfig.PITCHBOOK.label,
        value: CompanyColumnsConfig.PITCHBOOK.value
    },
    {
        label: CompanyColumnsConfig.INSTAGRAM.label,
        value: CompanyColumnsConfig.INSTAGRAM.value
    },
    {
        label: CompanyColumnsConfig.FACEBOOK.label,
        value: CompanyColumnsConfig.FACEBOOK.value
    },
    {
        label: CompanyColumnsConfig.ANGELLIST.label,
        value: CompanyColumnsConfig.ANGELLIST.value
    },
    {
        label: CompanyColumnsConfig.INDEED.label,
        value: CompanyColumnsConfig.INDEED.value
    },
    {
        label: CompanyColumnsConfig.YOUTUBE.label,
        value: CompanyColumnsConfig.YOUTUBE.value
    },
    {
        label: CompanyColumnsConfig.MONSTER.label,
        value: CompanyColumnsConfig.MONSTER.value
    },
    {
        label: CompanyColumnsConfig.STACKOVERFLOW.label,
        value: CompanyColumnsConfig.STACKOVERFLOW.value
    },
    WebTrafficMetricCombined,
    HeadcountMetricCombined,
    FundingTotalCombined,
    TwitterFollowerMetricCombined,
    LinkedInFollowerMetricCombined,
    FacebookLikesMetricCombined,
    InstagramFollowersMetricCombined,
    {
        label: CompanyColumnsConfig.MY_NOTES.label,
        value: CompanyColumnsConfig.MY_NOTES.value
    },
    {
        label: CompanyColumnsConfig.TEAM_NOTES.label,
        value: CompanyColumnsConfig.TEAM_NOTES.value
    },
    {
        label: CompanyColumnsConfig.ADDED_TO_LIST_BY.label,
        value: CompanyColumnsConfig.ADDED_TO_LIST_BY.value
    }
];
export default AllOptions;
