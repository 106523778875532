import { jsx as _jsx } from "react/jsx-runtime";
import { colorOptions } from 'components/Dashboard/Grids/EditField/SelectOptions/EditOptionItem';
import Tag from 'harmonic-components/Tag/Tag';
import useTeamMembers from 'hooks/useTeamMembers';
import React from 'react';
import SelectTruncateWrapper from './SelectTruncateWrapper';
const PersonRenderer = ({ value, onChange, mode, placeholder }) => {
    const handleOnChange = (e) => {
        onChange([e.target.value]);
    };
    const { allTeamMembers } = useTeamMembers({ fetchPolicy: 'cache-first' });
    const formattedTeamMembers = allTeamMembers?.map((member) => ({
        urn: member?.user?.entityUrn ?? '',
        name: member?.user?.name ?? ''
    })) ?? [];
    const selectedOptions = formattedTeamMembers
        .filter((option) => option.urn && value?.includes(option.urn))
        .sort((a, b) => {
        if (!value)
            return 0;
        return value.indexOf(a.urn) - value.indexOf(b.urn);
    });
    if (mode === 'read') {
        return (_jsx(SelectTruncateWrapper, { children: selectedOptions?.map((option) => {
                const color = colorOptions[formattedTeamMembers.indexOf(option) % colorOptions.length].color;
                return (_jsx(Tag, { label: option?.name ?? '', color: color, truncate: true, size: "compact" }, option.urn));
            }) }));
    }
    return (_jsx("input", { type: "text", value: value ?? '', onChange: handleOnChange, placeholder: placeholder }));
};
export default PersonRenderer;
