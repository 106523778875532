import { makeAutoObservable, when } from 'mobx';
import { config } from '../../config/config';
import { AuthStatus } from '../../interfaces/Auth';
import { midtierAxios } from '../../utils/midtierApi';
class FieldSpecStore {
    rootStore;
    companiesFieldSpec = [];
    peopleFieldSpec = [];
    companiesLoading = false;
    peopleLoading = false;
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        // Fetch field specs when the user is authenticated
        when(() => this.rootStore.authStore.authStatus === AuthStatus.AUTHENTICATED, () => {
            this.fetchPeopleFieldSpec();
            this.fetchCompaniesFieldSpec();
        });
    }
    async fetchPeopleFieldSpec() {
        this.peopleLoading = true;
        const apiUrl = `${config.BASE_MIDTIER_API_URL}search/searchFieldSpec?search_type=PERSONS`;
        const response = await midtierAxios.get(apiUrl);
        if (typeof response.data === 'string') {
            const data = JSON.parse(response.data);
            this.peopleFieldSpec = data;
        }
        else {
            this.peopleFieldSpec = response.data;
        }
        this.peopleLoading = false;
    }
    async fetchCompaniesFieldSpec() {
        this.companiesLoading = true;
        const apiUrl = `${config.BASE_MIDTIER_API_URL}search/searchFieldSpec?search_type=COMPANIES_LIST`;
        const response = await midtierAxios.get(apiUrl);
        if (typeof response.data === 'string') {
            const data = JSON.parse(response.data);
            this.companiesFieldSpec = data;
        }
        else {
            this.companiesFieldSpec = response.data;
        }
        this.companiesLoading = false;
    }
}
export default FieldSpecStore;
