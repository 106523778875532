import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip } from '@material-ui/core';
import { getIconForAction } from '../EntityActions/EntityActionType';
import IconButton from 'harmonic-components/IconButton/IconButton';
import useFetchSinglePeopleWatchlist from 'hooks/useFetchSinglePeopleWatchlist';
import useFetchSingleWatchlist from 'hooks/useFetchSingleWatchlist';
import { observer } from 'mobx-react';
import { EntityActionType } from '../EntityActions/EntityActionType';
const ActionBarEditCustomFieldValueBtn = ({ buttonRef, onEditCustomFieldsOpen }) => {
    const { companyWatchlist } = useFetchSingleWatchlist();
    const { peopleWatchlist } = useFetchSinglePeopleWatchlist();
    const companyCustomFields = companyWatchlist?.customFields;
    const peopleCustomFields = peopleWatchlist?.customFields;
    const hasNoCustomFields = !companyCustomFields?.length && !peopleCustomFields?.length;
    const handleOnClick = onEditCustomFieldsOpen;
    return (_jsx(Tooltip, { title: hasNoCustomFields ? 'No custom fields to edit' : 'Set values', children: _jsx("div", { ref: buttonRef, children: _jsx(IconButton, { dataTestId: "EntityActions-actionBar-RemoveFromCurrentList", icon: getIconForAction(EntityActionType.EDIT_CUSTOM_FIELD_VALUE, false), type: "secondary", emphasis: "high", isDisabled: hasNoCustomFields, onClick: handleOnClick, size: "default" }) }) }));
};
export default observer(ActionBarEditCustomFieldValueBtn);
