import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BellFilledIcon, CompanyListIcon, CompanySavedSearchIcon, PeopleListIcon, PeopleSavedSearchIcon } from 'assets/harmonic-icons';
import { Badge } from 'common/components';
import { LoadingCellRenderer } from 'components/Dashboard/Grids/CellRenderers/CellRenderers';
import usePeopleWatchlistEnabledDigests from 'hooks/digests/usePeopleWatchlistEnabledDigests';
import useSavedSearchEnabledSubscriptions from 'hooks/useSavedSearchEnabledSubscriptions';
import { EntityListType } from 'interfaces/SearchModel/Search';
import { Link } from 'react-router-dom';
import { LOADING_ROW_ID } from 'utils/constants';
import useNetNewCounts from '../Searches/NetNew/useNetNewCounts';
import Favorites from './Favorites';
const EntityLeadingIcon = ({ entityType }) => {
    const iconMap = {
        [EntityListType.COMPANY_SAVED_SEARCH]: CompanySavedSearchIcon,
        [EntityListType.PEOPLE_SAVED_SEARCH]: PeopleSavedSearchIcon,
        [EntityListType.COMPANY_WATCHLIST]: CompanyListIcon,
        [EntityListType.PEOPLE_WATCHLIST]: PeopleListIcon
    };
    const Icon = iconMap[entityType];
    return (_jsx("div", { className: "w-5 h-5 rounded-br20 flex items-center justify-center", children: _jsx(Icon, { className: "text-content-default w-4 h-4" }) }));
};
const NetNewRowBadge = ({ savedSearchUrn }) => {
    const { hasNetNew } = useNetNewCounts();
    if (!hasNetNew(savedSearchUrn))
        return null;
    return (_jsx(Badge, { label: "New results!", intensity: "subtle", color: "expressive-37" }));
};
const NameCellComponent = (rowData) => {
    const { hasSavedSearchSubscription } = useSavedSearchEnabledSubscriptions();
    const { hasEnabledPeopleWatchlistDigest } = usePeopleWatchlistEnabledDigests();
    if (rowData.data.id == LOADING_ROW_ID) {
        return (_jsx("div", { className: "flex items-center justify-center p-p40 w-full", children: _jsx(LoadingCellRenderer, {}) }));
    }
    return (_jsx("div", { className: "typography-label-default-default text-content-strong p-p60", children: _jsxs(Link, { to: rowData.data.url, className: "flex flex-row gap-g30", children: [_jsx(EntityLeadingIcon, { entityType: rowData.data.entityType }), _jsx("span", { className: "truncate min-w-[1ch]", children: rowData.data.name }), (rowData.data.entityType === EntityListType.COMPANY_SAVED_SEARCH ||
                    rowData.data.entityType === EntityListType.PEOPLE_SAVED_SEARCH) && (_jsx(NetNewRowBadge, { savedSearchUrn: rowData.data.entityUrn })), (hasSavedSearchSubscription(rowData?.data?.entityUrn) ||
                    hasEnabledPeopleWatchlistDigest(rowData?.data?.entityUrn)) && (_jsx(BellFilledIcon, { className: "self-center text-int-fill-net-new-selected-enabled cursor-default min-w-4" })), _jsx(Favorites, { isFavourited: rowData.data?.userOptions?.isPinned, id: rowData.data.id, type: rowData.data.entityType })] }) }));
};
export default NameCellComponent;
