import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Tooltip } from '@material-ui/core';
import { ReactComponent as PathInIcon } from 'common/assets/network/path-in.svg';
import { isNil, once } from 'lodash';
import { FormatTextInAsterisks } from '../FormatTextInAsterisks';
import { formatConnectionStrings } from './util';
import Shimmer from '../Shimmer';
const Loader = () => {
  return _jsxs("div", {
    className: "flex flex-col gap-2 items-center w-[200px] h-[150px]",
    children: [_jsx(Shimmer, {
      className: "h-4 w-full"
    }), _jsx(Shimmer, {
      className: "h-4 w-full"
    }), _jsx(Shimmer, {
      className: "h-4 w-full"
    })]
  });
};
const NetworkTooltip = props => {
  const {
    userConnections,
    children,
    loadConnections,
    currentUserEmail,
    targetName,
    targetRelevantExperienceCompanyUrn
  } = props;
  const connectionStrings = userConnections?.length ? formatConnectionStrings(userConnections, currentUserEmail, targetName, targetRelevantExperienceCompanyUrn) : {
    detailedConnections: [],
    summaryString: null
  };
  return _jsx("div", {
    "data-testid": "network-tooltip",
    children: _jsx(Tooltip, {
      title: _jsx("div", {
        className: "overflow-y-auto",
        children: _jsx("div", {
          className: "text-sm text-black w-inherit flex flex-wrap py-p30",
          children: _jsxs("div", {
            className: "flex flex-col gap-g30",
            children: [_jsx("div", {
              className: "px-p50",
              children: isNil(userConnections) ? _jsx(Loader, {}) : !userConnections.length ? _jsx("div", {
                className: "typography-label-normal text-content-weak",
                children: _jsx("p", {
                  children: "Not in network"
                })
              }) : _jsx("div", {
                className: "flex flex-col gap-g20",
                children: connectionStrings.detailedConnections.map((text, index) => _jsxs("div", {
                  className: "flex items-start gap-2",
                  children: [_jsx("div", {
                    className: "w-4 h-4 pt-p20",
                    children: _jsx(PathInIcon, {})
                  }), _jsx("p", {
                    className: "typography-label-strong text-content-default",
                    children: _jsx(FormatTextInAsterisks, {
                      text: text,
                      asteriskClassName: "font-medium text-content-strong",
                      textClassName: "typography-label-default-default"
                    })
                  })]
                }, index))
              })
            }), connectionStrings.summaryString && _jsxs(_Fragment, {
              children: [_jsx("div", {
                className: "w-full h-px bg-border"
              }), _jsx("div", {
                className: "px-p50 typography-label-normal text-content-weak",
                children: _jsx(FormatTextInAsterisks, {
                  text: connectionStrings.summaryString,
                  asteriskClassName: "font-medium text-content-strong",
                  textClassName: "typography-label-default-default"
                })
              })]
            })]
          })
        })
      }),
      classes: {
        tooltip: 'bg-white rounded-md shadow-static-elevation-lifted p-0'
      },
      placement: "bottom",
      children: _jsx("div", {
        onMouseOver: once(loadConnections),
        children: children
      })
    })
  });
};
export default NetworkTooltip;