import { gql } from '@apollo/client';
const getPersonProfileHeader = gql `
  fragment ProfileHeaderHighlight on PersonHighlight {
    category
    text
    sourceCompany {
      id
      name
    }
  }

  query GetPersonProfileHeader($id: Int!) {
    getPersonById(id: $id) {
      id
      fullName
      profilePictureUrl
      linkedinHeadline
      linkedinProfileVisibilityType
      location {
        location
      }
      highlights {
        ...ProfileHeaderHighlight
      }
      socials {
        linkedin {
          status
          url
          followerCount
        }
        crunchbase {
          status
          url
          followerCount
        }
        pitchbook {
          status
          url
          followerCount
        }
        angellist {
          status
          url
          followerCount
        }
        twitter {
          status
          url
          followerCount
        }
        facebook {
          status
          url
          likeCount
        }
        instagram {
          url
          followerCount
          status
        }
        indeed {
          url
          followerCount
          status
        }
        youtube {
          url
          followerCount
          status
        }
        monster {
          url
          followerCount
          status
        }
        stackoverflow {
          url
          followerCount
          status
        }
      }
      contact {
        emails
      }
      emailEnrichmentStatus {
        enrichedAt
      }
      investorUrn
    }
  }
`;
export default getPersonProfileHeader;
