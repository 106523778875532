/* eslint-disable testing-library/render-result-naming-convention */
import { ListCustomFieldType } from '__generated__/graphql';
import { isCheckboxCustomFieldValue, isDateCustomFieldValue, isMultiSelectCustomFieldValue, isNumberCustomFieldValue, isPersonCustomFieldValue, isSingleSelectCustomFieldValue, isStatusCustomFieldValue, isTextCustomFieldValue, isWebsiteCustomFieldValue } from 'utils/custom-fields';
import { logger } from '../../../../utils/logger';
import { CustomColumnDateCellEditor, CustomColumnMultiSelectCellEditor, CustomColumnNumberCellEditor, CustomColumnPersonSelectCellEditor, CustomColumnSingleSelectCellEditor, CustomColumnTextCellEditor } from '../CellRenderers/CustomColumnCellRenderers';
import { CustomColumnCheckboxRenderer } from '../CellRenderers/CustomColumnCheckboxRenderer';
import { CustomColumnDateCellRenderer } from '../CellRenderers/CustomColumnDateCellRenderer';
import { CustomColumnMultiSelectCellRenderer } from '../CellRenderers/CustomColumnMultiSelectCellRenderer';
import { CustomColumnNumberCellRenderer } from '../CellRenderers/CustomColumnNumberCellRenderer';
import { CustomColumnPersonSelectCellRenderer } from '../CellRenderers/CustomColumnPersonSelectCellRenderer';
import { CustomColumnSingleSelectCellRenderer } from '../CellRenderers/CustomColumnSingleSelectCellRenderer';
import { CustomColumnTextCellRenderer } from '../CellRenderers/CustomColumnTextCellRenderer';
import { CustomColumnWebsiteCellRenderer } from '../CellRenderers/CustomColumnWebsiteCellRenderer';
import { SortContextByCompanyField } from './CompanyHeaders';
import { SortContextByPersonField } from './PeopleHeaders';
import { suppressCellExitOnShiftEnter } from './utils';
export const getCustomFieldValueInputFromValue = (currentCustomFieldValue) => {
    let customFieldValueInput = undefined;
    switch (currentCustomFieldValue.value.customField.type) {
        case ListCustomFieldType.SINGLE_SELECT:
            if (!isSingleSelectCustomFieldValue(currentCustomFieldValue.value)) {
                throw new Error('Custom field value input not implemented for this type');
            }
            customFieldValueInput = {
                selectData: {
                    value: currentCustomFieldValue.value.data.singleSelectValue
                }
            };
            break;
        case ListCustomFieldType.MULTI_SELECT:
            if (!isMultiSelectCustomFieldValue(currentCustomFieldValue.value)) {
                throw new Error('Custom field value input not implemented for this type');
            }
            customFieldValueInput = {
                multiSelectData: {
                    value: currentCustomFieldValue.value.data.multiSelectValue
                }
            };
            break;
        case ListCustomFieldType.TEXT:
            if (!isTextCustomFieldValue(currentCustomFieldValue.value)) {
                throw new Error('Custom field value input not implemented for this type');
            }
            customFieldValueInput = {
                textData: {
                    value: currentCustomFieldValue.value.data.textValue ?? ''
                }
            };
            break;
        case ListCustomFieldType.DATE:
            if (!isDateCustomFieldValue(currentCustomFieldValue.value)) {
                throw new Error('Custom field value input not implemented for this type');
            }
            customFieldValueInput = {
                dateData: {
                    value: currentCustomFieldValue.value.data.dateValue ?? ''
                }
            };
            break;
        case ListCustomFieldType.NUMBER:
            if (!isNumberCustomFieldValue(currentCustomFieldValue.value)) {
                throw new Error('Custom field value input not implemented for this type');
            }
            //eslint-disable-next-line
            let numberValue = currentCustomFieldValue.value.data.numberValue ?? undefined;
            if (numberValue?.toString() === '-')
                numberValue = undefined;
            customFieldValueInput = {
                numberData: {
                    value: numberValue
                }
            };
            break;
        case ListCustomFieldType.PERSON:
            if (!isPersonCustomFieldValue(currentCustomFieldValue.value)) {
                throw new Error('Custom field value input not implemented for this type');
            }
            customFieldValueInput = {
                personData: {
                    value: currentCustomFieldValue.value.data.personSelectValue ?? []
                }
            };
            break;
        case ListCustomFieldType.WEBSITE:
            if (!isWebsiteCustomFieldValue(currentCustomFieldValue.value)) {
                throw new Error('Custom field value input not implemented for this type');
            }
            customFieldValueInput = {
                websiteData: {
                    value: currentCustomFieldValue.value.data.websiteValue ?? ''
                }
            };
            break;
        case ListCustomFieldType.STATUS:
            if (!isStatusCustomFieldValue(currentCustomFieldValue.value)) {
                throw new Error('Custom field value input not implemented for this type');
            }
            customFieldValueInput = {
                selectData: {
                    value: currentCustomFieldValue.value.data.statusValue
                }
            };
            break;
        case ListCustomFieldType.CHECKBOX:
            if (!isCheckboxCustomFieldValue(currentCustomFieldValue.value)) {
                throw new Error('Custom field value input not implemented for this type');
            }
            customFieldValueInput = {
                checkboxData: {
                    value: currentCustomFieldValue.value.data.checkboxValue ?? false
                }
            };
            break;
        default:
            throw new Error('Custom field value input not implemented for this type');
    }
    return customFieldValueInput;
};
const fieldEditable = (customField) => {
    if (customField.type == ListCustomFieldType.CHECKBOX) {
        return false;
    }
    return true;
};
export const getCustomFieldValueDataFromInput = (input, customField) => {
    if (input.textData) {
        return {
            __typename: 'TextCustomFieldValue',
            textValue: input.textData.value
        };
    }
    else if (input.numberData) {
        return {
            __typename: 'NumberListCustomFieldValue',
            numberValue: input.numberData.value ?? null
        };
    }
    else if (input.dateData) {
        return {
            __typename: 'DateListCustomFieldValue',
            dateValue: input.dateData.value ?? null
        };
    }
    else if (input.selectData) {
        if (customField.type === ListCustomFieldType.STATUS) {
            return {
                __typename: 'StatusListCustomFieldValue',
                statusValue: input.selectData?.value
            };
        }
        else {
            return {
                __typename: 'SingleSelectCustomFieldValue',
                singleSelectValue: input.selectData?.value
            };
        }
    }
    else if (input.multiSelectData) {
        return {
            __typename: 'MultiSelectCustomFieldValue',
            multiSelectValue: input.multiSelectData?.value
        };
    }
    else if (input.personData) {
        return {
            __typename: 'PersonSelectCustomFieldValue',
            personSelectValue: input.personData?.value
        };
    }
    else if (input.websiteData) {
        return {
            __typename: 'WebsiteListCustomFieldValue',
            websiteValue: input.websiteData?.value ?? null
        };
        // Note, checkboxData.value can be false, so we need to explicitly check if it exists
    }
    else if (input.checkboxData) {
        return {
            __typename: 'CheckboxListCustomFieldValue',
            checkboxValue: input.checkboxData?.value ?? null
        };
    }
    else {
        throw new Error('Custom field input not implemented for this type');
    }
};
export const getCustomFieldValueDataFromValueSetterParams = (params, customFieldType) => {
    let data;
    switch (customFieldType) {
        case ListCustomFieldType.TEXT: {
            const textValue = params.newValue?.value ?? null;
            if (typeof textValue !== 'string' && textValue !== null) {
                throw new Error('Text custom field value must be a string');
            }
            data = {
                __typename: 'TextCustomFieldValue',
                textValue
            };
            break;
        }
        case ListCustomFieldType.NUMBER: {
            data = {
                __typename: 'NumberListCustomFieldValue',
                numberValue: Number(params.newValue?.value?.toString().replace(/,/g, ''))
            };
            break;
        }
        case ListCustomFieldType.DATE: {
            const dateValue = params.newValue?.value ?? null;
            if (typeof dateValue !== 'string' && dateValue !== null) {
                throw new Error('Date custom field value must be a string');
            }
            data = {
                __typename: 'DateListCustomFieldValue',
                dateValue
            };
            break;
        }
        case ListCustomFieldType.SINGLE_SELECT:
            data = {
                __typename: 'SingleSelectCustomFieldValue',
                singleSelectValue: params.newValue?.value
            };
            break;
        case ListCustomFieldType.MULTI_SELECT: {
            const multiSelectValue = params.newValue?.value ?? [];
            if (!Array.isArray(multiSelectValue)) {
                throw new Error('Multi-select custom field value must be an array');
            }
            data = {
                __typename: 'MultiSelectCustomFieldValue',
                multiSelectValue
            };
            break;
        }
        case ListCustomFieldType.PERSON: {
            const personSelectValue = params.newValue?.value ?? [];
            if (!Array.isArray(personSelectValue)) {
                throw new Error('Person custom field value must be an array');
            }
            data = {
                __typename: 'PersonSelectCustomFieldValue',
                personSelectValue
            };
            break;
        }
        case ListCustomFieldType.WEBSITE: {
            const websiteValue = params.newValue?.value ?? null;
            if (typeof websiteValue !== 'string' && websiteValue !== null) {
                throw new Error('Website custom field value must be a string');
            }
            data = {
                __typename: 'WebsiteListCustomFieldValue',
                websiteValue
            };
            break;
        }
        case ListCustomFieldType.CHECKBOX: {
            const checkboxValue = params.newValue?.value ?? null;
            if (typeof checkboxValue !== 'boolean' && checkboxValue !== null) {
                throw new Error('Checkbox custom field value must be a boolean');
            }
            data = {
                __typename: 'CheckboxListCustomFieldValue',
                checkboxValue
            };
            break;
        }
        case ListCustomFieldType.STATUS: {
            const statusValue = params.newValue?.value ?? null;
            if (typeof statusValue !== 'string' && statusValue !== null) {
                throw new Error('Status custom field value must be a string');
            }
            data = {
                __typename: 'StatusListCustomFieldValue',
                statusValue
            };
            break;
        }
        default:
            throw new Error('Custom field value setter not implemented for this type');
    }
    return data;
};
const getCustomFieldValueCellRendererParamsMap = (metadata) => ({
    [ListCustomFieldType.TEXT]: {
        component: CustomColumnTextCellRenderer,
        params: {
            hidePlaceholder: true
        }
    },
    [ListCustomFieldType.NUMBER]: {
        component: CustomColumnNumberCellRenderer,
        params: {
            numberFormat: metadata
                ?.numberFormat
        }
    },
    [ListCustomFieldType.DATE]: {
        component: CustomColumnDateCellRenderer,
        params: {
            dateFormat: metadata?.dateFormat
        }
    },
    [ListCustomFieldType.SINGLE_SELECT]: {
        component: CustomColumnSingleSelectCellRenderer,
        params: {
            options: metadata
                ?.options
        }
    },
    [ListCustomFieldType.MULTI_SELECT]: {
        component: CustomColumnMultiSelectCellRenderer,
        params: {
            options: metadata
                ?.options
        }
    },
    [ListCustomFieldType.PERSON]: {
        component: CustomColumnPersonSelectCellRenderer,
        params: {
            mode: metadata?.mode
        }
    },
    [ListCustomFieldType.WEBSITE]: {
        component: CustomColumnWebsiteCellRenderer
    },
    [ListCustomFieldType.CHECKBOX]: {
        component: CustomColumnCheckboxRenderer
    },
    [ListCustomFieldType.STATUS]: {
        component: CustomColumnSingleSelectCellRenderer,
        params: {
            options: metadata
                ?.options
        }
    }
});
const getCustomFieldValueCellEditorParams = (metadata) => ({
    [ListCustomFieldType.TEXT]: {
        component: CustomColumnTextCellEditor
    },
    [ListCustomFieldType.NUMBER]: {
        component: CustomColumnNumberCellEditor,
        params: {
            numberFormat: metadata
                ?.numberFormat
        }
    },
    [ListCustomFieldType.DATE]: {
        component: CustomColumnDateCellEditor,
        params: {
            dateFormat: metadata?.dateFormat
        }
    },
    [ListCustomFieldType.SINGLE_SELECT]: {
        component: CustomColumnSingleSelectCellEditor,
        params: {
            options: metadata
                ?.options
        }
    },
    [ListCustomFieldType.MULTI_SELECT]: {
        component: CustomColumnMultiSelectCellEditor,
        params: {
            options: metadata
                ?.options
        }
    },
    [ListCustomFieldType.PERSON]: {
        component: CustomColumnPersonSelectCellEditor,
        params: {
            mode: metadata?.mode
        }
    },
    [ListCustomFieldType.WEBSITE]: {
        component: CustomColumnTextCellEditor
    },
    [ListCustomFieldType.CHECKBOX]: {
    // This is empty, because we don't use the cell editor for checkbox
    // The Cell Renderer has a "on change" after save
    },
    [ListCustomFieldType.STATUS]: {
        component: CustomColumnSingleSelectCellEditor,
        params: {
            options: metadata
                ?.options
        }
    }
});
const getCustomFieldValueGetterMap = (pending, value) => ({
    [ListCustomFieldType.TEXT]: {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        value: value?.textValue,
        pending
    },
    [ListCustomFieldType.NUMBER]: {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        value: value
            ?.numberValue,
        pending
    },
    [ListCustomFieldType.DATE]: {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        value: value?.dateValue,
        pending
    },
    [ListCustomFieldType.SINGLE_SELECT]: {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        value: value
            ?.singleSelectValue,
        pending
    },
    [ListCustomFieldType.MULTI_SELECT]: {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        value: value
            ?.multiSelectValue,
        pending
    },
    [ListCustomFieldType.PERSON]: {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        value: value
            ?.personSelectValue,
        pending
    },
    [ListCustomFieldType.WEBSITE]: {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        value: value
            ?.websiteValue,
        pending
    },
    [ListCustomFieldType.CHECKBOX]: {
        value: 
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        value
            ?.checkboxValue || false,
        pending
    },
    [ListCustomFieldType.STATUS]: {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        value: value
            ?.statusValue,
        pending
    }
});
export const customFieldCellRendererSelector = (props) => {
    const customFieldUrn = props.colDef?.field;
    if (!customFieldUrn) {
        throw new Error('Invalid custom field urn');
    }
    const gridContext = props.context;
    const customFields = gridContext?.customFields;
    const customField = customFields?.[customFieldUrn];
    if (!customField) {
        logger.error(`Custom field not found for urn ${customFieldUrn}`);
        return;
    }
    if (!customField?.type) {
        throw new Error('Invalid custom field type');
    }
    return getCustomFieldValueCellRendererParamsMap(customField?.metadata)[customField.type];
};
export const customFieldCellEditorSelector = (props) => {
    const customFieldUrn = props.colDef.field;
    const gridContext = props.context;
    const customFields = gridContext?.customFields;
    if (!customFieldUrn) {
        throw new Error('Invalid custom field urn');
    }
    const customField = customFields?.[customFieldUrn];
    if (!customField) {
        throw new Error(`Custom field not found for urn ${customFieldUrn}`);
    }
    if (!customField.type) {
        throw new Error('Invalid custom field type');
    }
    const editorParams = getCustomFieldValueCellEditorParams(customField.metadata)[customField.type];
    return editorParams;
};
export const customFieldValueGetter = (props) => {
    const customFieldUrn = props.colDef.field;
    if (!customFieldUrn) {
        throw new Error('Custom field urn not found, does this column have a field?');
    }
    const pending = (props?.data?.pendingFields ?? []).some((field) => field?.customFieldUrn === customFieldUrn);
    const gridContext = props.context;
    if (!gridContext) {
        throw new Error('Grid context not found');
    }
    const customField = gridContext?.customFields[customFieldUrn];
    if (!customField) {
        throw new Error(`Custom field not found for urn ${customFieldUrn}`);
    }
    if (!customField.type) {
        throw new Error('Invalid custom field type');
    }
    const customFieldValues = props.node?.data?.customFieldValues;
    const matchedCustomFieldValue = customFieldValues?.find((customFieldValue) => customFieldValue.customField.urn === customFieldUrn);
    const customFieldValueGetterMap = getCustomFieldValueGetterMap(pending, matchedCustomFieldValue?.data);
    return customFieldValueGetterMap[customField.type];
};
const peopleCustomFieldValueSetter = (params) => {
    if (!params?.node?.data) {
        throw new Error('Node data not found');
    }
    if (params.newValue?.value === params.oldValue?.value) {
        return false;
    }
    const customFieldUrn = params.colDef.field;
    if (!customFieldUrn) {
        throw new Error('Custom field urn not found, does this column have a field?');
    }
    const gridContext = params.context;
    if (!gridContext) {
        throw new Error('Grid context not found');
    }
    const customField = gridContext?.customFields[customFieldUrn];
    if (!customField) {
        throw new Error(`Custom field not found for urn ${customFieldUrn}`);
    }
    if (!customField.type) {
        throw new Error('Invalid custom field type');
    }
    const data = getCustomFieldValueDataFromValueSetterParams(params, customField.type);
    if (!data) {
        return false;
    }
    const customFieldValues = params.node.data.customFieldValues;
    const index = customFieldValues.findIndex((cf) => cf.customField.urn === customFieldUrn);
    // The value is not found which means that we are setting this value for the first time
    if (index === -1) {
        params.node.data.customFieldValues = [
            ...params.node.data.customFieldValues,
            {
                urn: '',
                __typename: 'PeopleListCustomFieldValue',
                customField: customField,
                data,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            }
        ];
        return true;
    }
    // The value is found and we are updating it with the new value
    const newItemAtIndex = {
        ...customFieldValues[index],
        data
    };
    params.node.data.customFieldValues = [
        ...customFieldValues.slice(0, index),
        newItemAtIndex,
        ...customFieldValues.slice(index + 1)
    ];
    return true;
};
export const genericCustomFieldValueSetterWithParams = (params, customFieldValueTypeName) => {
    if (!params?.node?.data) {
        throw new Error('Node data not found');
    }
    if (params.newValue?.value === params.oldValue?.value) {
        return { hasChanged: false, params };
    }
    const customFieldUrn = params.colDef.field;
    if (!customFieldUrn) {
        throw new Error('Custom field urn not found, does this column have a field?');
    }
    const gridContext = params.context;
    if (!gridContext) {
        throw new Error('Grid context not found');
    }
    const customField = gridContext?.customFields[customFieldUrn];
    if (!customField) {
        throw new Error(`Custom field not found for urn ${customFieldUrn}`);
    }
    if (!customField.type) {
        throw new Error('Invalid custom field type');
    }
    const data = getCustomFieldValueDataFromValueSetterParams(params, customField.type);
    if (!data) {
        return { hasChanged: false, params };
    }
    const customFieldValues = params.node.data.customFieldValues;
    const index = customFieldValues.findIndex((cf) => cf.customField.urn === customFieldUrn);
    // The value is not found which means that we are setting this value for the first time
    if (index === -1) {
        //eslint-disable-next-line
        //@ts-ignore
        params.node.data.customFieldValues = [
            ...params.node.data.customFieldValues,
            {
                urn: '',
                __typename: customFieldValueTypeName,
                customField: customField,
                data,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            }
        ];
        return { hasChanged: true, params };
    }
    // The value is found and we are updating it with the new value
    const newItemAtIndex = {
        ...customFieldValues[index],
        data
    };
    //eslint-disable-next-line
    //@ts-ignore
    params.node.data.customFieldValues = [
        ...customFieldValues.slice(0, index),
        newItemAtIndex,
        ...customFieldValues.slice(index + 1)
    ];
    return { hasChanged: true, params };
};
const companyCustomFieldValueSetter = (params) => {
    const { hasChanged } = genericCustomFieldValueSetterWithParams(params, 'CompanyListCustomFieldValue');
    return hasChanged;
};
const companyCustomFieldOnCellValueChanged = (props) => {
    const gridContext = props.context;
    if (!gridContext) {
        throw new Error('Grid context not found');
    }
    const customFieldUrn = props.colDef.field;
    if (!customFieldUrn) {
        throw new Error('Custom field urn not found');
    }
    if (!props.node?.data) {
        throw new Error('Node not found');
    }
    const watchlistEntryUrn = props.node.data.entryUrn;
    if (!watchlistEntryUrn) {
        throw new Error('Entry urn not found');
    }
    const currentCustomFieldValue = props.node.data.customFieldValues.find((cf) => cf.customField.urn === customFieldUrn);
    if (!currentCustomFieldValue?.data) {
        throw new Error('Custom field value not found');
    }
    const customFieldValueInput = getCustomFieldValueInputFromValue({
        value: currentCustomFieldValue
    });
    gridContext.upsertCustomFieldValue({
        variables: {
            customFieldUrn,
            companyWatchlistEntryUrn: watchlistEntryUrn,
            customFieldValueInput
        },
        watchlistEntryUrn
    });
};
const peopleCustomFieldOnCellValueChanged = (props) => {
    const gridContext = props.context;
    if (!gridContext) {
        throw new Error('Grid context not found');
    }
    const customFieldUrn = props.colDef.field;
    if (!customFieldUrn) {
        throw new Error('Custom field urn not found');
    }
    if (!props.node?.data) {
        throw new Error('Node not found');
    }
    const watchlistEntryUrn = props.node.data.entryUrn;
    if (!watchlistEntryUrn) {
        throw new Error('Entry urn not found');
    }
    const currentCustomFieldValue = props.node.data.customFieldValues.find((cf) => cf.customField.urn === customFieldUrn);
    if (!currentCustomFieldValue?.data) {
        throw new Error('Custom field value not found');
    }
    const customFieldValueInput = getCustomFieldValueInputFromValue({
        value: currentCustomFieldValue
    });
    gridContext.upsertCustomFieldValue({
        variables: {
            customFieldUrn,
            peopleWatchlistEntryUrn: watchlistEntryUrn,
            customFieldValueInput
        },
        watchlistEntryUrn
    });
};
const personCustomFieldSearchModelIdentifierByType = {
    [ListCustomFieldType.TEXT]: 'person_custom_field_text',
    [ListCustomFieldType.NUMBER]: 'person_custom_field_number',
    [ListCustomFieldType.DATE]: 'person_custom_field_date',
    [ListCustomFieldType.SINGLE_SELECT]: 'person_custom_field_single_select',
    [ListCustomFieldType.MULTI_SELECT]: 'person_custom_field_multi_select',
    [ListCustomFieldType.PERSON]: 'person_custom_field_person',
    [ListCustomFieldType.STATUS]: 'person_custom_field_status',
    [ListCustomFieldType.CHECKBOX]: 'person_custom_field_checkbox',
    [ListCustomFieldType.WEBSITE]: 'person_custom_field_url'
};
const companyCustomFieldSearchModelIdentifierByType = {
    [ListCustomFieldType.TEXT]: 'company_custom_field_text',
    [ListCustomFieldType.NUMBER]: 'company_custom_field_number',
    [ListCustomFieldType.DATE]: 'company_custom_field_date',
    [ListCustomFieldType.SINGLE_SELECT]: 'company_custom_field_single_select',
    [ListCustomFieldType.MULTI_SELECT]: 'company_custom_field_multi_select',
    [ListCustomFieldType.PERSON]: 'company_custom_field_person',
    [ListCustomFieldType.STATUS]: 'company_custom_field_status',
    [ListCustomFieldType.CHECKBOX]: 'company_custom_field_checkbox',
    [ListCustomFieldType.WEBSITE]: 'company_custom_field_url'
};
export const getCompanyWatchlistCustomFieldHeaders = (customFields) => {
    return customFields.map((customField) => {
        return {
            field: customField.urn,
            headerName: customField.name,
            initialWidth: 230,
            headerComponent: 'CompanyWatchlistCustomColumnHeader',
            editable: fieldEditable(customField),
            cellRendererSelector: customFieldCellRendererSelector,
            cellEditorSelector: customFieldCellEditorSelector,
            valueGetter: customFieldValueGetter,
            valueSetter: companyCustomFieldValueSetter,
            onCellValueChanged: companyCustomFieldOnCellValueChanged,
            cellEditorPopup: true,
            enableCellChangeFlash: false,
            suppressKeyboardEvent: suppressCellExitOnShiftEnter,
            cellClass: 'cursor-pointer',
            sortable: true,
            searchModelIdentifier: companyCustomFieldSearchModelIdentifierByType[customField.type],
            sortContext: SortContextByCompanyField[companyCustomFieldSearchModelIdentifierByType[customField.type]],
            isCustomField: true
        };
    });
};
export const getPeopleWatchlistCustomFieldHeaders = (customFields) => {
    return customFields.map((customField) => {
        return {
            field: customField.urn,
            headerName: customField.name,
            initialWidth: 230,
            headerComponent: 'PeopleWatchlistCustomColumnHeader',
            editable: fieldEditable(customField),
            cellRendererSelector: customFieldCellRendererSelector,
            cellEditorSelector: customFieldCellEditorSelector,
            valueGetter: customFieldValueGetter,
            valueSetter: peopleCustomFieldValueSetter,
            onCellValueChanged: peopleCustomFieldOnCellValueChanged,
            cellEditorPopup: true,
            enableCellChangeFlash: false,
            suppressKeyboardEvent: suppressCellExitOnShiftEnter,
            cellClass: 'cursor-pointer',
            sortable: true,
            searchModelIdentifier: personCustomFieldSearchModelIdentifierByType[customField.type],
            sortContext: SortContextByPersonField[personCustomFieldSearchModelIdentifierByType[customField.type]],
            isCustomField: true
        };
    });
};
