import { jsx as _jsx } from "react/jsx-runtime";
import { isValidUrl } from '../../../../utils/utilities';
import { withPendingDot } from './components/WithPendingDot';
export const BaseCustomColumnWebsiteCellRenderer = (props) => {
    const value = props.value?.value || '';
    let url = value;
    if (!value.startsWith('http') && !value.startsWith('https')) {
        url = 'https://' + value;
    }
    if (props.showEmptyValue && !props.value?.value) {
        return (_jsx("p", { className: "typography-paragraph text-content-weak", children: `Add ${props.colDef?.headerName}` }));
    }
    if (isValidUrl(url)) {
        return (_jsx("p", { className: "typography-paragraph text-content line-clamp-2", children: _jsx("a", { className: "underline", href: url, rel: "noopener noreferrer", target: "_blank", onClick: (e) => e.stopPropagation(), children: value }) }));
    }
    else {
        return (_jsx("p", { className: "typography-paragraph text-content-default line-clamp-2", children: value }));
    }
};
export const CustomColumnWebsiteCellRenderer = withPendingDot(BaseCustomColumnWebsiteCellRenderer);
