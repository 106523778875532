import { jsx as _jsx } from "react/jsx-runtime";
export const Form = ({ children, onSubmit, className, enableSubmitOnCmdEnter = true, enableSubmitOnEnter = false }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    };
    const handleKeyDown = (e) => {
        // Check for Cmd+Enter (Mac) or Ctrl+Enter (Windows)
        if ((e.metaKey || e.ctrlKey) &&
            e.key === 'Enter' &&
            enableSubmitOnCmdEnter) {
            e.preventDefault();
            onSubmit();
            return;
        }
        if (enableSubmitOnEnter) {
            // allow shift for textareas new lines
            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                onSubmit();
                return;
            }
        }
    };
    return (_jsx("form", { onKeyDown: handleKeyDown, onSubmit: handleSubmit, className: className, role: "form", children: children }));
};
