import { gql } from '@apollo/client';
import { SavedSearchFragment } from './savedSearchFragment';
export const CREATE_SAVED_SEARCH = gql `
  mutation CreateSavedSearch($savedSearchInput: SavedSearchInput!) {
    createSavedSearch(savedSearchInput: $savedSearchInput) {
      ...FullSavedSearch
    }
  }
  ${SavedSearchFragment}
`;
export const CREATE_SEARCH_AGENT_SAVED_SEARCH = gql `
  mutation CreateSearchAgentSavedSearch(
    $savedSearchInput: SearchAgentSavedSearchInput!
  ) {
    createSearchAgentSavedSearch(savedSearchInput: $savedSearchInput) {
      ...FullSavedSearch
      __typename
    }
  }
  ${SavedSearchFragment}
`;
