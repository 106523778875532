import { jsx as _jsx } from "react/jsx-runtime";
import { datadogRum } from '@datadog/browser-rum';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import { observer } from 'mobx-react';
import { useStore } from './StoreProvider';
import { config } from './config';
const getSplitSdkConfig = (key) => ({
    core: {
        authorizationKey: config.SPLIT_API_KEY,
        key
    },
    sync: {
        enabled: false
    },
    impressionListener: {
        logImpression(impressionData) {
            datadogRum.addFeatureFlagEvaluation(impressionData.impression.feature, impressionData.impression.treatment);
        }
    }
});
export const SplitWrapper = observer(({ children }) => {
    const { rootStore } = useStore();
    const user = rootStore.authStore.user;
    return (_jsx(SplitFactoryProvider, { config: user && user.email
            ? getSplitSdkConfig(user.email.toLocaleLowerCase())
            : undefined, children: children }));
});
