import { jsx as _jsx } from "react/jsx-runtime";
import { useStore } from 'config/StoreProvider';
import useDashboardLocation, { DashboardLocation } from 'hooks/useDashboardLocation';
import useUpsertPeopleWatchlistNamedViews from 'hooks/useUpsertPeopleWatchlistNamedViews';
import * as React from 'react';
import { useCallback } from 'react';
import { useShallowTableStore } from 'stores/tableStore';
import { convertPeopleListColumnsConfigToUrns } from 'utils/namedViews';
import { getUpdatedColumnsForSave } from '../../EditColumnsModal/utils';
import BulkSelectHeaderWithDropdown from '../BulkSelectHeaderWithDropdown';
import { useFieldsDropdown } from '../FieldsDropdownItems/FieldsDropdownItems';
import PeopleWatchlistFieldsDropdownItems from '../FieldsDropdownItems/PeopleWatchlistFieldsDropdownItems';
export const PeopleBulkSelectCheckboxRenderer = (props) => {
    const routeDetails = useDashboardLocation();
    const { rootStore } = useStore();
    const { editTableStoreData, allRowsSelected } = useShallowTableStore([
        'editTableStoreData',
        'editSidebar',
        'allRowsSelected',
        'selectAllStateEnabled',
        'customizeFieldModal'
    ]);
    const isWatchlist = routeDetails.location === DashboardLocation.PERSON_LIST;
    const { upsertCurrentSelectedNamedView: upsertPeopleNamedView } = useUpsertPeopleWatchlistNamedViews();
    const resetSelectionState = useCallback(() => {
        editTableStoreData('selectedRowIds', []);
        editTableStoreData('selectAllStateEnabled', false);
        props.api?.deselectAll();
        rootStore.peopleWatchlistViewStore.clearSelectedEntryUrns();
    }, [editTableStoreData, props.api, rootStore.peopleWatchlistViewStore]);
    const colId = props.column.getColId();
    React.useEffect(() => {
        resetSelectionState();
    }, [resetSelectionState]);
    const onCheckBoxChange = async (e) => {
        e.stopPropagation();
        const selectedNetNewIds = [];
        if (allRowsSelected || allRowsSelected === null) {
            resetSelectionState();
            return;
        }
        const selectedPeopleIds = [];
        const selectedEntryUrns = [];
        props?.api?.forEachNode((node) => {
            if (node?.data?.person?.id !== undefined) {
                selectedPeopleIds.push(node?.data?.person?.id);
            }
            if (node?.data?.netNewId)
                selectedNetNewIds.push(node?.data?.netNewId);
            if (routeDetails.location === DashboardLocation.PERSON_LIST &&
                node.data.entryUrn) {
                selectedEntryUrns.push(node?.data?.entryUrn);
            }
        });
        editTableStoreData('selectedRowIds', selectedPeopleIds);
        editTableStoreData('selectedNetNewIds', selectedNetNewIds);
        rootStore.peopleWatchlistViewStore.addSelectedEntryUrns(selectedEntryUrns);
    };
    const save = (visibleColumns, cols) => {
        if (!isWatchlist) {
            const updatedColumns = getUpdatedColumnsForSave(cols, false);
            rootStore.peopleExploreViewStore.updateTableColumns(updatedColumns);
            return;
        }
        upsertPeopleNamedView({
            variables: {
                visibleColumns
            }
        });
    };
    const { onFieldClick, onCreateNewField } = useFieldsDropdown({
        colId,
        save,
        convertColumnsConfigToUrns: convertPeopleListColumnsConfigToUrns
    });
    return (_jsx(BulkSelectHeaderWithDropdown, { ...props, isWatchlist: isWatchlist, onCheckBoxChange: onCheckBoxChange, renderFields: (filterTerm, insertFieldMode, onClose) => (_jsx(PeopleWatchlistFieldsDropdownItems, { onFieldClick: (field) => {
                onFieldClick(field, insertFieldMode);
                onClose();
            }, onCreateNewField: () => {
                onCreateNewField(insertFieldMode);
                onClose();
            }, filterTerm: filterTerm })) }));
};
