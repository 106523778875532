import client from '../../config/client';
import { getCompanyProfileHeader } from '../../queries/getCompanyProfileHeader';
import CompanyModel from './models/CompanyModel';
class CompanyStore {
    rootStore;
    constructor(rootStore) {
        this.rootStore = rootStore;
    }
    async get(id) {
        const { data, errors } = await client.query({
            query: getCompanyProfileHeader,
            variables: {
                id
            },
            fetchPolicy: 'cache-first'
        });
        if (!data?.getCompanyById) {
            return {
                company: null,
                error: errors?.[0]
            };
        }
        const company = new CompanyModel(data.getCompanyById);
        return {
            company,
            error: errors?.[0]
        };
    }
}
export default CompanyStore;
