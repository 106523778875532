import gql from 'graphql-tag';
const COMPANY_LIST_NAMED_VIEW_FRAGMENT = gql `
  fragment CompanyListNamedView on CompanyListNamedView {
    id
    entityUrn
    name
    visibleColumns
    searchQuery
    displayType
    groupByField {
      urn
    }
    hideEmptyColumns
  }
`;
export const COMPANY_LIST_NAMED_VIEW_SIDEBAR_FRAGMENT = gql `
  fragment CompanyListNamedViewSidebar on CompanyListNamedView {
    entityUrn
  }
`;
export const SELECT_LIST_CUSTOM_FIELD_METADATA_FRAGMENT = gql `
  fragment SelectListCustomFieldMetadata on SelectListCustomFieldMetadata {
    options {
      color
      name
      urn
    }
  }
`;
export const NUMBER_LIST_CUSTOM_FIELD_METADATA_FRAGMENT = gql `
  fragment NumberListCustomFieldMetadata on NumberListCustomFieldMetadata {
    numberFormat: format
  }
`;
export const DATE_LIST_CUSTOM_FIELD_METADATA_FRAGMENT = gql `
  fragment DateListCustomFieldMetadata on DateListCustomFieldMetadata {
    dateFormat: format
  }
`;
export const PERSON_LIST_CUSTOM_FIELD_METADATA_FRAGMENT = gql `
  fragment PersonListCustomFieldMetadata on PersonListCustomFieldMetadata {
    mode
  }
`;
export const CustomFieldsFragment = gql `
  fragment CustomFields on CompanyListCustomField {
    __typename
    urn
    name
    type
    createdAt
    defaultValue {
      ... on NumberListCustomFieldValue {
        numberDefaultValue: value
      }
      ... on TextCustomFieldValue {
        textDefaultValue: value
      }
      ... on SingleSelectCustomFieldValue {
        singleSelectDefaultValue: value
      }
      ... on MultiSelectCustomFieldValue {
        multiSelectDefaultValue: value
      }
      ... on PersonSelectCustomFieldDefaultValue {
        personDefaultValue: value
        personDefaultValueMode: mode
      }
      ... on DateListCustomFieldDefaultValue {
        dateDefaultValue: value
        dateDefaultValueMode: mode
      }
      ... on WebsiteListCustomFieldValue {
        websiteDefaultValue: value
      }
      ... on CheckboxListCustomFieldValue {
        checkboxDefaultValue: value
      }
      ... on StatusListCustomFieldValue {
        statusDefaultValue: value
      }
    }
    metadata {
      __typename
      ... on SelectListCustomFieldMetadata {
        ...SelectListCustomFieldMetadata
      }
      ... on NumberListCustomFieldMetadata {
        ...NumberListCustomFieldMetadata
      }
      ... on DateListCustomFieldMetadata {
        ...DateListCustomFieldMetadata
      }
      ... on PersonListCustomFieldMetadata {
        ...PersonListCustomFieldMetadata
      }
    }
  }
  ${SELECT_LIST_CUSTOM_FIELD_METADATA_FRAGMENT}
  ${NUMBER_LIST_CUSTOM_FIELD_METADATA_FRAGMENT}
  ${DATE_LIST_CUSTOM_FIELD_METADATA_FRAGMENT}
  ${PERSON_LIST_CUSTOM_FIELD_METADATA_FRAGMENT}
`;
export const GET_WATCHLIST_FRAGMENT_WITH_COMPANY_IDS = gql `
  fragment WatchlistWithCompanyId on CompanyWatchlist {
    owner {
      ... on User {
        entityUrn
        email
      }
      ... on Customer {
        identifier
        name
      }
    }
    id
    entityUrn
    name
    userWatchlistType
    sharedWithTeam
    companyIds
    customFields {
      ...CustomFields
    }
    namedViews {
      ...CompanyListNamedView
    }
    namedViewsOrder
  }
  ${CustomFieldsFragment}
  ${COMPANY_LIST_NAMED_VIEW_FRAGMENT}
`;
export const GET_WATCHLIST = gql `
  query GetCompanyWatchlist($idOrUrn: String!) {
    getCompanyWatchlistByIdOrUrn(idOrUrn: $idOrUrn) {
      ...WatchlistWithCompanyId
    }
  }
  ${GET_WATCHLIST_FRAGMENT_WITH_COMPANY_IDS}
`;
