import { gql } from '@apollo/client';
import { CompanySearch } from './companySearchFragment';
import { FullPeopleSearch } from './peopleSearchFragment';
export const GET_COMPANY_SAVED_SEARCH_RESULTS = gql `
  query GetCompanySavedSearchResults(
    $idOrUrn: String!
    $first: Int!
    $after: String
    $state: SavedSearchStateFilter
    $skipExtended: Boolean = false
    $highlightNew: Boolean = false
    $sortField: String
    $sortDescending: Boolean
    $includesPersonRelationships: Boolean = true
    $includesPersonRelationshipsFoundersAndCeos: Boolean = true
    $includesPersonRelationshipsExecutives: Boolean = true
    $includesLeadershipPriorCompanies: Boolean = true
    $includesFunding: Boolean = true
    $includesTractionMetrics: Boolean = true
    $includesUserConnections: Boolean = true
    $includesUserConnectionsOverview: Boolean = true
    $includesAffinityLists: Boolean = true
    $includesSocials: Boolean = true
    $includesTypedTags: Boolean = true
    $includesHighlights: Boolean = true
    $includesContact: Boolean = true
    $relationshipsPageSize: Int = 5
  ) {
    getSavedSearch(idOrUrn: $idOrUrn) {
      id
      results(
        first: $first
        after: $after
        state: $state
        highlightNew: $highlightNew
        sortField: $sortField
        sortDescending: $sortDescending
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          startCursor
        }
        edges {
          cursor
          node {
            netNewId
            entity {
              ... on Company {
                ...CompanySearch
              }
            }
          }
        }
      }
    }
  }
  ${CompanySearch}
`;
export const GET_PERSON_SAVED_SEARCH_RESULTS = gql `
  query GetPersonSavedSearchResults(
    $idOrUrn: String!
    $first: Int!
    $after: String
    $state: SavedSearchStateFilter
    $skipExtended: Boolean = false
    $highlightNew: Boolean = false
    $sortField: String
    $sortDescending: Boolean
  ) {
    getSavedSearch(idOrUrn: $idOrUrn) {
      id
      results(
        first: $first
        after: $after
        state: $state
        highlightNew: $highlightNew
        sortField: $sortField
        sortDescending: $sortDescending
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          startCursor
        }
        edges {
          cursor
          node {
            netNewId
            entity {
              ... on Person {
                ...PeopleSearch
              }
            }
          }
        }
      }
    }
  }
  ${FullPeopleSearch}
`;
export const GET_NET_NEW_SAVED_SEARCH_COUNT = gql `
  query GetNetNewSavedSearchCount($idOrUrn: String!) {
    getSavedSearch(idOrUrn: $idOrUrn) {
      id
      results(first: 1, state: NET_NEW) {
        totalCount
      }
    }
  }
`;
const SAVED_SEARCH_BASE_FRAGMENT = gql `
  fragment SavedSearchBaseMetadata on SavedSearch {
    id
    entityUrn
    isPrivate
    name
    searchQuery
    type
    visibleColumns
    search_type: type
  }
`;
export const SAVED_SEARCH_FRAGMENT = gql `
  fragment SavedSearchMetadata on SavedSearch {
    ...SavedSearchBaseMetadata
    creator {
      email
      name
      entityUrn
    }
    userOptions {
      isPinned
      lastViewedAt
    }
  }
  ${SAVED_SEARCH_BASE_FRAGMENT}
`;
export const GET_SAVED_SEARCH = gql `
  query GetSavedSearch($idOrUrn: String!) {
    getSavedSearch(idOrUrn: $idOrUrn) {
      ...SavedSearchMetadata
    }
  }
  ${SAVED_SEARCH_FRAGMENT}
`;
