import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import ExampleQueries from './ExampleQueries';
const CompanyResearchReportOptions = ({ onExampleQuestionClick, exampleQuestions, isLoadingExamples, question, companyDomain }) => {
    const showDiligenceReport = !!companyDomain;
    // Calculate total number of selectable items (example questions + diligence report if available)
    const totalSelectableItems = question.trim()
        ? 1 // Just the dynamic query when typing
        : exampleQuestions.length + (showDiligenceReport ? 1 : 0);
    const [selectedIndex, setSelectedIndex] = useState(totalSelectableItems - 1);
    const handleClickQuestion = useCallback(async (question) => {
        await onExampleQuestionClick(question);
    }, [onExampleQuestionClick]);
    // Handle keyboard navigation
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (isLoadingExamples)
                return;
            // If user is typing, only allow selection of the dynamic query
            if (question.trim()) {
                if (e.key === 'Enter' && selectedIndex === 0) {
                    e.preventDefault();
                    handleClickQuestion(question);
                }
                return;
            }
            // Only handle keyboard navigation when no query is being typed
            if (totalSelectableItems === 0)
                return;
            switch (e.key) {
                case 'ArrowDown':
                    e.preventDefault();
                    setSelectedIndex((prev) => prev < totalSelectableItems - 1 ? prev + 1 : 0);
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    setSelectedIndex((prev) => prev > 0 ? prev - 1 : totalSelectableItems - 1);
                    break;
                case 'Enter':
                    e.preventDefault();
                    if (selectedIndex >= 0 && selectedIndex < exampleQuestions.length) {
                        handleClickQuestion(exampleQuestions[selectedIndex]);
                    }
                    break;
                default:
                    break;
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [
        selectedIndex,
        exampleQuestions,
        isLoadingExamples,
        handleClickQuestion,
        question,
        totalSelectableItems
    ]);
    // Set selectedIndex to last item when user starts typing
    useEffect(() => {
        if (question.trim()) {
            setSelectedIndex(0);
        }
        else {
            setSelectedIndex(totalSelectableItems - 1);
        }
    }, [question, totalSelectableItems]);
    const handleOnMouseEnter = (index) => {
        setSelectedIndex(index);
    };
    const handleOnMouseLeave = () => {
        setSelectedIndex(-1);
    };
    return (_jsx(ExampleQueries, { onExampleQuestionClick: handleClickQuestion, exampleQuestions: exampleQuestions, isLoadingExamples: isLoadingExamples, question: question, selectedIndex: selectedIndex, onMouseEnter: handleOnMouseEnter, onMouseLeave: handleOnMouseLeave }));
};
export default CompanyResearchReportOptions;
