import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '@material-ui/core';
import { ListCustomFieldType, PersonListCustomFieldDefaultValueMode } from '__generated__/graphql';
import { ArrowLeftIcon, ResetFieldsIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import IconButton from 'harmonic-components/IconButton/IconButton';
import ListItem, { ListType, ListVariant } from 'harmonic-components/ListItem/ListItem';
import { useMemo, useRef, useState } from 'react';
import { ListCustomFieldTypeToIconMap } from 'utils/custom-fields';
import EditCustomFieldsCheckboxValue from './EditCustomFieldsCheckboxValue';
import EditCustomFieldsDateValue from './EditCustomFieldsDateValue';
import EditCustomFieldsMultiSelectValue from './EditCustomFieldsMultiSelectValue';
import EditCustomFieldsNumberValue from './EditCustomFieldsNumberValue';
import EditCustomFieldsPersonValue from './EditCustomFieldsPersonValue';
import EditCustomFieldsSelectValue from './EditCustomFieldsSelectValue';
import EditCustomFieldsTextValue from './EditCustomFieldsTextValue';
import EditCustomFieldsUrlValue from './EditCustomFieldsUrlValue';
const EditCustomFields = ({ open = false, anchorEl, onClose, customFields, getCustomFieldFromCache, anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
}, inverted, className = '-mt-p30', onSetCustomFieldValues, onResetToDefaultValues, onDeleteCustomFieldValues }) => {
    const popoverActionsRef = useRef(null);
    const [selectedCustomFieldUrn, setSelectedCustomFieldUrn] = useState(null);
    const [filterTerm, setFilterTerm] = useState('');
    const handleOnFilterTermChange = (value) => {
        setFilterTerm(value);
    };
    const recalculatePopoverPosition = () => {
        setTimeout(() => {
            popoverActionsRef.current?.updatePosition();
        }, 100);
    };
    const filteredCustomFields = useMemo(() => {
        const customFieldsWithFixedPersonRemoved = customFields.filter((field) => {
            const defaultValue = field.defaultValue;
            return !(defaultValue &&
                defaultValue?.__typename !== 'PersonSelectCustomFieldDefaultValue' &&
                'personDefaultValueMode' in defaultValue &&
                defaultValue?.personDefaultValueMode ===
                    PersonListCustomFieldDefaultValueMode.FIXED_URN);
        });
        return customFieldsWithFixedPersonRemoved.filter((field) => field.name?.toLowerCase().includes(filterTerm.toLowerCase()));
    }, [customFields, filterTerm]);
    const handleCustomFieldClick = (customFieldUrn) => {
        setSelectedCustomFieldUrn(customFieldUrn);
    };
    const handleResetSelectedCustomField = () => {
        setSelectedCustomFieldUrn(null);
    };
    const selectedCustomFieldFromCache = useMemo(() => {
        if (!selectedCustomFieldUrn) {
            return null;
        }
        return getCustomFieldFromCache(selectedCustomFieldUrn);
    }, [getCustomFieldFromCache, selectedCustomFieldUrn]);
    const handleOnSaveText = async (newValue) => {
        onSetCustomFieldValues({
            customFieldUrn: selectedCustomFieldFromCache?.urn,
            customFieldValueInput: { textData: { value: newValue } }
        });
        handleOnClose();
    };
    const handleOnSaveSingleSelect = async (newValue) => {
        onSetCustomFieldValues({
            customFieldUrn: selectedCustomFieldFromCache?.urn,
            customFieldValueInput: { selectData: { value: newValue } }
        });
        handleOnClose();
    };
    const handleOnSaveMultiSelect = async (newValue) => {
        onSetCustomFieldValues({
            customFieldUrn: selectedCustomFieldFromCache?.urn,
            customFieldValueInput: { multiSelectData: { value: newValue } }
        });
        handleOnClose();
    };
    const handleOnSavePerson = async (newValue) => {
        onSetCustomFieldValues({
            customFieldUrn: selectedCustomFieldFromCache?.urn,
            customFieldValueInput: { personData: { value: newValue } }
        });
        handleOnClose();
    };
    const handleOnSaveNumber = async (newValue) => {
        onSetCustomFieldValues({
            customFieldUrn: selectedCustomFieldFromCache?.urn,
            customFieldValueInput: { numberData: { value: newValue } }
        });
        handleOnClose();
    };
    const handleOnSaveCheckbox = async (newValue) => {
        onSetCustomFieldValues({
            customFieldUrn: selectedCustomFieldFromCache?.urn,
            customFieldValueInput: { checkboxData: { value: newValue } }
        });
        handleOnClose();
    };
    const handleOnSaveDate = async (newValue) => {
        onSetCustomFieldValues({
            customFieldUrn: selectedCustomFieldFromCache?.urn,
            customFieldValueInput: { dateData: { value: newValue } }
        });
        handleOnClose();
    };
    const handleOnSaveUrl = async (newValue) => {
        onSetCustomFieldValues({
            customFieldUrn: selectedCustomFieldFromCache?.urn,
            customFieldValueInput: { websiteData: { value: newValue } }
        });
        handleOnClose();
    };
    const handleOnClose = () => {
        onClose?.();
        setTimeout(() => {
            setSelectedCustomFieldUrn(null);
        }, 500);
    };
    const handleOnResetToDefaultValues = async () => {
        onResetToDefaultValues({
            customFieldUrn: selectedCustomFieldFromCache?.urn
        });
        handleResetSelectedCustomField();
    };
    const handleOnDeleteCustomFieldValues = async () => {
        onDeleteCustomFieldValues({
            customFieldUrn: selectedCustomFieldFromCache?.urn
        });
        handleResetSelectedCustomField();
    };
    const HeaderLabelIcon = useMemo(() => {
        if (!selectedCustomFieldFromCache) {
            return null;
        }
        return ListCustomFieldTypeToIconMap[selectedCustomFieldFromCache?.type];
    }, [selectedCustomFieldFromCache]);
    return (_jsx(Popover, { open: open, action: popoverActionsRef, anchorEl: anchorEl, onClose: handleOnClose, anchorOrigin: anchorOrigin, elevation: 0, className: "mt-[-5px]", classes: {
            paper: classNames('bg-transparent border-transparent rounded-br40 -ml-p30 shadow-static-elevation-strong', className)
        }, disableAutoFocus: true, disableEnforceFocus: true, children: _jsxs("div", { className: classNames('w-64 overflow-hidden bg-transparent rounded-br40', !inverted && 'visible-scrollbar', inverted && 'inverted-scrollbar'), children: [!selectedCustomFieldFromCache && (_jsx(Dropdown, { onFilterTermChange: handleOnFilterTermChange, filterTerm: filterTerm, filterPlaceholder: "Search", inverted: false, children: filteredCustomFields.map((c) => (_jsx(ListItem, { type: ListType.default, variant: ListVariant.default, primaryIcon: ListCustomFieldTypeToIconMap[c.type], label: c.name, value: c.name, onClick: () => handleCustomFieldClick(c.urn) }, c.urn))) })), selectedCustomFieldFromCache ? (_jsxs("div", { className: "bg-surface-default border-border border border-solid rounded-br40", children: [_jsxs("div", { className: classNames('flex items-center text-content-default typography-label-small-strong gap-g20 bg-surface-raised', 'pr-p40 pl-[11px] py-p40 border-b border-solid border-border'), children: [_jsx(IconButton, { icon: ArrowLeftIcon, type: "secondary", emphasis: "high", size: "tiny", onClick: handleResetSelectedCustomField }), _jsxs("div", { className: "text-content-weak typography-label-small-strong flex items-center truncate line-clamp-1", children: ["Set value for", ' ', HeaderLabelIcon ? (_jsx("span", { className: "mx-g20", children: _jsx(HeaderLabelIcon, {}) })) : null, ' ', _jsx("span", {}), selectedCustomFieldFromCache?.name] })] }), _jsxs("div", { className: "p-p50", children: [(selectedCustomFieldFromCache.type ===
                                    ListCustomFieldType.SINGLE_SELECT ||
                                    selectedCustomFieldFromCache.type ===
                                        ListCustomFieldType.STATUS) && (_jsx(EditCustomFieldsSelectValue, { customField: selectedCustomFieldFromCache, onSave: handleOnSaveSingleSelect, onClear: handleOnDeleteCustomFieldValues, recalculatePopoverPosition: recalculatePopoverPosition })), selectedCustomFieldFromCache.type ===
                                    ListCustomFieldType.MULTI_SELECT && (_jsx(EditCustomFieldsMultiSelectValue, { customField: selectedCustomFieldFromCache, onSave: handleOnSaveMultiSelect, onCancel: handleResetSelectedCustomField, recalculatePopoverPosition: recalculatePopoverPosition })), selectedCustomFieldFromCache.type ===
                                    ListCustomFieldType.PERSON && (_jsx(EditCustomFieldsPersonValue, { customField: selectedCustomFieldFromCache, onSave: handleOnSavePerson, onCancel: handleResetSelectedCustomField, onClear: handleOnDeleteCustomFieldValues, recalculatePopoverPosition: recalculatePopoverPosition })), selectedCustomFieldFromCache?.type ===
                                    ListCustomFieldType.TEXT && (_jsx(EditCustomFieldsTextValue, { customField: selectedCustomFieldFromCache, onCancel: handleResetSelectedCustomField, onSave: handleOnSaveText })), selectedCustomFieldFromCache?.type ===
                                    ListCustomFieldType.NUMBER && (_jsx(EditCustomFieldsNumberValue, { customField: selectedCustomFieldFromCache, onCancel: handleResetSelectedCustomField, onSave: handleOnSaveNumber, onClear: handleOnDeleteCustomFieldValues })), selectedCustomFieldFromCache?.type ===
                                    ListCustomFieldType.DATE && (_jsx(EditCustomFieldsDateValue, { customField: selectedCustomFieldFromCache, onCancel: handleResetSelectedCustomField, onSave: handleOnSaveDate, onClear: handleOnDeleteCustomFieldValues })), selectedCustomFieldFromCache?.type ===
                                    ListCustomFieldType.CHECKBOX && (_jsx(EditCustomFieldsCheckboxValue, { customField: selectedCustomFieldFromCache, onSave: handleOnSaveCheckbox })), selectedCustomFieldFromCache?.type ===
                                    ListCustomFieldType.WEBSITE && (_jsx(EditCustomFieldsUrlValue, { customField: selectedCustomFieldFromCache, onCancel: handleResetSelectedCustomField, onSave: handleOnSaveUrl }))] }), _jsx("div", { className: "border-t border-solid border-border", children: _jsx(ListItem, { primaryIcon: ResetFieldsIcon, label: "Reset to default", value: "Reset to default", type: ListType.default, variant: ListVariant.default, onClick: handleOnResetToDefaultValues, destructive: true }) })] })) : null] }) }));
};
export default EditCustomFields;
