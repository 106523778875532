import CompanyColumnsConfig from 'interfaces/CompanyColumnsConfig';
const generateTractionMetricCombinedColumn = (label, fieldValue, addLatestMetricValue = false) => {
    let latestMetricField = [];
    if (addLatestMetricValue)
        latestMetricField = [
            {
                label: CompanyColumnsConfig[`${fieldValue}`].label,
                value: CompanyColumnsConfig[`${fieldValue}`].value
            }
        ];
    return {
        label: `${label}`,
        nested: [
            ...latestMetricField,
            {
                label: `Past`,
                nested: [
                    {
                        label: CompanyColumnsConfig[`${fieldValue}_14D_AGO`].label,
                        value: CompanyColumnsConfig[`${fieldValue}_14D_AGO`].value
                    },
                    {
                        label: CompanyColumnsConfig[`${fieldValue}_30D_AGO`].label,
                        value: CompanyColumnsConfig[`${fieldValue}_30D_AGO`].value
                    },
                    {
                        label: CompanyColumnsConfig[`${fieldValue}_90D_AGO`].label,
                        value: CompanyColumnsConfig[`${fieldValue}_90D_AGO`].value
                    },
                    {
                        label: CompanyColumnsConfig[`${fieldValue}_180D_AGO`].label,
                        value: CompanyColumnsConfig[`${fieldValue}_180D_AGO`].value
                    },
                    {
                        label: CompanyColumnsConfig[`${fieldValue}_365D_AGO`].label,
                        value: CompanyColumnsConfig[`${fieldValue}_365D_AGO`].value
                    }
                ]
            },
            {
                label: `△`,
                nested: [
                    {
                        label: CompanyColumnsConfig[`${fieldValue}_REAL_CHANGE_14D_AGO`].label,
                        value: CompanyColumnsConfig[`${fieldValue}_REAL_CHANGE_14D_AGO`].value
                    },
                    {
                        label: CompanyColumnsConfig[`${fieldValue}_REAL_CHANGE_30D_AGO`].label,
                        value: CompanyColumnsConfig[`${fieldValue}_REAL_CHANGE_30D_AGO`].value
                    },
                    {
                        label: CompanyColumnsConfig[`${fieldValue}_REAL_CHANGE_90D_AGO`].label,
                        value: CompanyColumnsConfig[`${fieldValue}_REAL_CHANGE_90D_AGO`].value
                    },
                    {
                        label: CompanyColumnsConfig[`${fieldValue}_REAL_CHANGE_180D_AGO`].label,
                        value: CompanyColumnsConfig[`${fieldValue}_REAL_CHANGE_180D_AGO`].value
                    },
                    {
                        label: CompanyColumnsConfig[`${fieldValue}_REAL_CHANGE_365D_AGO`].label,
                        value: CompanyColumnsConfig[`${fieldValue}_REAL_CHANGE_365D_AGO`].value
                    }
                ]
            },
            {
                label: `%`,
                nested: [
                    {
                        label: CompanyColumnsConfig[`${fieldValue}_PERCENT_CHANGE_14D_AGO`]
                            .label,
                        value: CompanyColumnsConfig[`${fieldValue}_PERCENT_CHANGE_14D_AGO`].value
                    },
                    {
                        label: CompanyColumnsConfig[`${fieldValue}_PERCENT_CHANGE_30D_AGO`]
                            .label,
                        value: CompanyColumnsConfig[`${fieldValue}_PERCENT_CHANGE_30D_AGO`].value
                    },
                    {
                        label: CompanyColumnsConfig[`${fieldValue}_PERCENT_CHANGE_90D_AGO`]
                            .label,
                        value: CompanyColumnsConfig[`${fieldValue}_PERCENT_CHANGE_90D_AGO`].value
                    },
                    {
                        label: CompanyColumnsConfig[`${fieldValue}_PERCENT_CHANGE_180D_AGO`]
                            .label,
                        value: CompanyColumnsConfig[`${fieldValue}_PERCENT_CHANGE_180D_AGO`]
                            .value
                    },
                    {
                        label: CompanyColumnsConfig[`${fieldValue}_PERCENT_CHANGE_365D_AGO`]
                            .label,
                        value: CompanyColumnsConfig[`${fieldValue}_PERCENT_CHANGE_365D_AGO`]
                            .value
                    }
                ]
            }
        ]
    };
};
export const WebTrafficMetricCombined = {
    label: 'Web traffic',
    nested: [
        {
            label: CompanyColumnsConfig.WEB_TRAFFIC.label,
            value: CompanyColumnsConfig.WEB_TRAFFIC.value
        },
        {
            label: 'Past',
            nested: [
                {
                    label: CompanyColumnsConfig.WEB_TRAFFIC_30D_AGO.label,
                    value: CompanyColumnsConfig.WEB_TRAFFIC_30D_AGO.value
                },
                {
                    label: CompanyColumnsConfig.WEB_TRAFFIC_90D_AGO.label,
                    value: CompanyColumnsConfig.WEB_TRAFFIC_90D_AGO.value
                },
                {
                    label: CompanyColumnsConfig.WEB_TRAFFIC_180D_AGO.label,
                    value: CompanyColumnsConfig.WEB_TRAFFIC_180D_AGO.value
                },
                {
                    label: CompanyColumnsConfig.WEB_TRAFFIC_365D_AGO.label,
                    value: CompanyColumnsConfig.WEB_TRAFFIC_365D_AGO.value
                }
            ]
        },
        {
            label: '△',
            nested: [
                {
                    label: CompanyColumnsConfig.WEB_TRAFFIC_REAL_CHANGE_30D_AGO.label,
                    value: CompanyColumnsConfig.WEB_TRAFFIC_REAL_CHANGE_30D_AGO.value
                },
                {
                    label: CompanyColumnsConfig.WEB_TRAFFIC_REAL_CHANGE_90D_AGO.label,
                    value: CompanyColumnsConfig.WEB_TRAFFIC_REAL_CHANGE_90D_AGO.value
                },
                {
                    label: CompanyColumnsConfig.WEB_TRAFFIC_REAL_CHANGE_180D_AGO.label,
                    value: CompanyColumnsConfig.WEB_TRAFFIC_REAL_CHANGE_180D_AGO.value
                },
                {
                    label: CompanyColumnsConfig.WEB_TRAFFIC_REAL_CHANGE_365D_AGO.label,
                    value: CompanyColumnsConfig.WEB_TRAFFIC_REAL_CHANGE_365D_AGO.value
                }
            ]
        },
        {
            label: '%',
            nested: [
                {
                    label: CompanyColumnsConfig.WEB_TRAFFIC_PERCENT_CHANGE_30D_AGO.label,
                    value: CompanyColumnsConfig.WEB_TRAFFIC_PERCENT_CHANGE_30D_AGO.value
                },
                {
                    label: CompanyColumnsConfig.WEB_TRAFFIC_PERCENT_CHANGE_90D_AGO.label,
                    value: CompanyColumnsConfig.WEB_TRAFFIC_PERCENT_CHANGE_90D_AGO.value
                },
                {
                    label: CompanyColumnsConfig.WEB_TRAFFIC_PERCENT_CHANGE_180D_AGO.label,
                    value: CompanyColumnsConfig.WEB_TRAFFIC_PERCENT_CHANGE_180D_AGO.value
                },
                {
                    label: CompanyColumnsConfig.WEB_TRAFFIC_PERCENT_CHANGE_365D_AGO.label,
                    value: CompanyColumnsConfig.WEB_TRAFFIC_PERCENT_CHANGE_365D_AGO.value
                }
            ]
        }
    ]
};
export const FundingTotalCombined = generateTractionMetricCombinedColumn('Funding total', 'FUNDING_TOTAL');
FundingTotalCombined.nested = [
    {
        label: CompanyColumnsConfig.FUNDING_TOTAL.label,
        value: CompanyColumnsConfig.FUNDING_TOTAL.value
    },
    ...(FundingTotalCombined.nested ?? [])
];
const HeadcountAllDepartmentMetricsCombined = generateTractionMetricCombinedColumn('All departments', 'HEADCOUNT');
HeadcountAllDepartmentMetricsCombined.nested = [
    {
        label: CompanyColumnsConfig.HEADCOUNT.label,
        value: CompanyColumnsConfig.HEADCOUNT.value
    },
    {
        label: CompanyColumnsConfig.EXTERNAL_HEADCOUNT.label,
        value: CompanyColumnsConfig.EXTERNAL_HEADCOUNT.value
    },
    ...(HeadcountAllDepartmentMetricsCombined.nested ?? [])
];
const HeadcountAdvisorMetricCombined = generateTractionMetricCombinedColumn('Advisor', 'HEADCOUNT_ADVISOR', true);
const HeadcountCustomerSuccessMetricCombined = generateTractionMetricCombinedColumn('Customer Success', 'HEADCOUNT_CUSTOMER_SUCCESS', true);
const HeadcountDataMetricCombined = generateTractionMetricCombinedColumn('Data', 'HEADCOUNT_DATA', true);
const HeadcountDesignMetricCombined = generateTractionMetricCombinedColumn('Design', 'HEADCOUNT_DESIGN', true);
const HeadcountEngineeringMetricCombined = generateTractionMetricCombinedColumn('Engineering', 'HEADCOUNT_ENGINEERING', true);
const HeadcountFinanceMetricCombined = generateTractionMetricCombinedColumn('Finance', 'HEADCOUNT_FINANCE', true);
const HeadcountLegalMetricCombined = generateTractionMetricCombinedColumn('Legal', 'HEADCOUNT_LEGAL', true);
const HeadcountMarketingMetricCombined = generateTractionMetricCombinedColumn('Marketing', 'HEADCOUNT_MARKETING', true);
const HeadcountOperationsMetricCombined = generateTractionMetricCombinedColumn('Operations', 'HEADCOUNT_OPERATIONS', true);
const HeadcountOtherMetricCombined = generateTractionMetricCombinedColumn('Other', 'HEADCOUNT_OTHER', true);
const HeadcountPeopleMetricCombined = generateTractionMetricCombinedColumn('People', 'HEADCOUNT_PEOPLE', true);
const HeadcountProductMetricCombined = generateTractionMetricCombinedColumn('Product', 'HEADCOUNT_PRODUCT', true);
const HeadcountSalesMetricCombined = generateTractionMetricCombinedColumn('Sales', 'HEADCOUNT_SALES', true);
const HeadcountSupportMetricCombined = generateTractionMetricCombinedColumn('Support', 'HEADCOUNT_SUPPORT', true);
export const TwitterFollowerMetricCombined = generateTractionMetricCombinedColumn('Twitter followers', 'TWITTER_FOLLOWERS', true);
export const LinkedInFollowerMetricCombined = generateTractionMetricCombinedColumn('LinkedIn followers', 'LINKEDIN_FOLLOWERS', true);
export const FacebookLikesMetricCombined = generateTractionMetricCombinedColumn('Facebook likes', 'FACEBOOK_LIKES', true);
export const InstagramFollowersMetricCombined = generateTractionMetricCombinedColumn('Instagram followers', 'INSTAGRAM_FOLLOWERS', true);
export const HeadcountMetricCombined = {
    label: 'Headcount',
    nested: [
        HeadcountAllDepartmentMetricsCombined,
        HeadcountAdvisorMetricCombined,
        HeadcountCustomerSuccessMetricCombined,
        HeadcountDataMetricCombined,
        HeadcountDesignMetricCombined,
        HeadcountEngineeringMetricCombined,
        HeadcountFinanceMetricCombined,
        HeadcountLegalMetricCombined,
        HeadcountMarketingMetricCombined,
        HeadcountOperationsMetricCombined,
        HeadcountOtherMetricCombined,
        HeadcountPeopleMetricCombined,
        HeadcountProductMetricCombined,
        HeadcountSalesMetricCombined,
        HeadcountSupportMetricCombined
    ]
};
