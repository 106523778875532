import { jsx as _jsx } from "react/jsx-runtime";
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import { useState } from 'react';
const EditCustomFieldsCheckbox = ({ onSave }) => {
    const [selected, setSelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleCheckboxChange = async (e) => {
        try {
            setLoading(true);
            await onSave(e.target.checked);
            setSelected(e.target.checked);
        }
        finally {
            setLoading(false);
        }
    };
    return (_jsx("div", { "data-testid": "EditCustomFieldsCheckbox-Popup", className: "w-full", children: _jsx(ListItem, { label: "", value: "", selected: selected, variant: ListVariant.checkboxes, onChange: handleCheckboxChange, loading: loading }) }));
};
export default EditCustomFieldsCheckbox;
