import { getNthColor } from '../../../../utils/colors';
import { colorOptions } from '../EditField/SelectOptions/EditOptionItem';
export const formatSelectListCustomFieldMetadata = ({ newOption, options }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const newOptions = options.map(({ __typename, ...rest }) => rest);
    const color = getNthColor(colorOptions, newOptions.length);
    if (newOption) {
        newOptions.push({ name: newOption, color: color, urn: undefined });
    }
    return newOptions;
};
/**
 * https://coreui.io/blog/how-to-check-if-an-element-is-visible-in-javascript/#:~:text=A%20common%20method%20to%20check,position%20relative%20to%20the%20viewport.
 * check if element is in viewport, we usually use this to start loading more rows in our grid when the user scrolls to the bottom
 * @param el element to check
 * @param offset default to 300 to preload a bit earlier
 * @returns boolean
 */
export function isElementInViewport(el, offset = 300) {
    const rect = el.getBoundingClientRect();
    return (rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom - offset <=
            (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth));
}
