import { gql } from '@apollo/client';
import { CHECKBOX_LIST_CUSTOM_FIELD_VALUE_FRAGMENT, DATE_LIST_CUSTOM_FIELD_VALUE_FRAGMENT, MULTI_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT, NUMBER_LIST_CUSTOM_FIELD_VALUE_FRAGMENT, PERSON_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT, SINGLE_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT, STATUS_LIST_CUSTOM_FIELD_VALUE_FRAGMENT, TEXT_CUSTOM_FIELD_VALUE_FRAGMENT, WEBSITE_LIST_CUSTOM_FIELD_VALUE_FRAGMENT } from './getWatchlistWithCompanies';
import { FullPeopleSearch } from './peopleSearchFragment';
export const PEOPLE_LIST_CUSTOM_FIELD_VALUE_FRAGMENT = gql `
  fragment PeopleListCustomFieldValue on PeopleListCustomFieldValue {
    customField {
      urn
      name
      type
      __typename
    }
    data {
      __typename
      ... on NumberListCustomFieldValue {
        ...NumberListCustomFieldValue
      }
      ... on TextCustomFieldValue {
        ...TextCustomFieldValue
      }
      ... on SingleSelectCustomFieldValue {
        ...SingleSelectCustomFieldValue
      }
      ... on MultiSelectCustomFieldValue {
        ...MultiSelectCustomFieldValue
      }
      ... on PersonSelectCustomFieldValue {
        ...PersonSelectCustomFieldValue
      }
      ... on DateListCustomFieldValue {
        ...DateListCustomFieldValue
      }
      ... on WebsiteListCustomFieldValue {
        ...WebsiteListCustomFieldValue
      }
      ... on CheckboxListCustomFieldValue {
        ...CheckboxListCustomFieldValue
      }
      ... on StatusListCustomFieldValue {
        ...StatusListCustomFieldValue
      }
    }
    updatedAt
    urn
    createdAt
    __typename
  }
  ${NUMBER_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${TEXT_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${SINGLE_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${MULTI_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${PERSON_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${DATE_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${WEBSITE_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${CHECKBOX_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${STATUS_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
`;
export const SIMPLE_PEOPLE_WATCHLIST_ENTRY_NODE_FRAGMENT = gql `
  fragment SimplePeopleListEntryNode on PeopleWatchlistEntryNode {
    __typename
    entryUrn
    customFieldValues {
      ...PeopleListCustomFieldValue
    }
    pendingFields {
      __typename
      customFieldUrn
      action
    }
  }
  ${PEOPLE_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
`;
const PEOPLE_WATCHLIST_ENTRY_NODE_FRAGMENT = gql `
  fragment PeopleListEntryNode on PeopleWatchlistEntryNode {
    __typename
    ...SimplePeopleListEntryNode
    entryCreatedAt
    person {
      ...PeopleSearch
    }
    addedBy {
      __typename
      name
      entityUrn
      email
    }
  }
  ${FullPeopleSearch}
  ${SIMPLE_PEOPLE_WATCHLIST_ENTRY_NODE_FRAGMENT}
`;
const PEOPLE_WATCHLIST_ENTRIES_FRAGMENT = gql `
  fragment PeopleWatchlistEntries on PeopleWatchlistEntriesConnection {
    edges {
      cursor
      node {
        ...PeopleListEntryNode
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      startCursor
      endCursor
    }
  }
  ${PEOPLE_WATCHLIST_ENTRY_NODE_FRAGMENT}
`;
export const WATCHLIST_WITH_PEOPLE = gql `
  query GetWatchlistWithPeople(
    $idOrUrn: String!
    $skipExtended: Boolean = false
    $first: Int
    $after: String
    $sortField: String
    $sortDescending: Boolean
    $searchQuery: JSON
    $sortCustomFieldUrn: PeopleListCustomFieldUrn
  ) {
    getPeopleWatchlistByIdOrUrn(idOrUrn: $idOrUrn) {
      id
      personEntries(
        first: $first
        after: $after
        sortField: $sortField
        sortDescending: $sortDescending
        searchQuery: $searchQuery
        sortCustomFieldUrn: $sortCustomFieldUrn
      ) {
        __typename
        ...PeopleWatchlistEntries
      }
    }
  }
  ${PEOPLE_WATCHLIST_ENTRIES_FRAGMENT}
`;
