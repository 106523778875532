import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { BagIcon, GraduationCapIcon, LinkedInIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { urnFrom } from 'common/utils/urn';
import { TeamMemberConnections } from 'components/Dashboard/Company/TeamMemberConnections';
import AddPeopleToWatchlist from 'components/common/AddPeopleToWatchlist';
import GetPersonEmailButton from 'components/common/ContactData/GetEmailButton';
import AvatarWithHighlightBadge from 'components/common/Highlights/AvatarWithHighlightBadge';
import InternalLink from 'components/common/InternalLink';
import NetworkBadgeWithTooltip from 'components/common/Network/NetworkBadgeWithTooltip';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { EntityType } from 'hooks/useDashboardLocation';
import useFlags from 'hooks/useFlags';
import { compact } from 'lodash';
import uniq from 'lodash/uniq';
import getPersonContactInformation from 'queries/getPersonContactInformation';
import { useNavigate } from 'react-router-dom';
import { SPLITS } from 'utils/constants';
import { CompanyNavbarOptions } from '../CompanyHeader/CompanyHeader';
const Employee = ({ employee, currentCompanyId }) => {
    const id = employee.id;
    const currentExperience = employee.experience?.find((exp) => {
        return exp?.isCurrentPosition && exp.company.id === currentCompanyId;
    });
    const currentTitle = currentExperience?.title;
    const pastExperience = employee.experience?.find((exp) => {
        return exp?.isCurrentPosition === false;
    });
    const pastTitle = pastExperience?.title;
    const pastCompany = pastExperience?.company.name;
    const pastCompanyId = pastExperience?.company.id;
    const schoolName = employee.education?.[0]?.school?.name;
    const userConnections = employee.userConnections;
    const { enabled: useNetworkMappingNewTooltips } = useFlags(SPLITS.networkMappingNewTooltips);
    const navigate = useNavigate();
    const personRoute = `/dashboard/person/${id}`;
    const highlights = uniq(compact(employee.highlights?.map((highlight) => highlight?.category))) ?? [];
    // Query to get person contact information to display email from cache
    useQuery(getPersonContactInformation, {
        variables: {
            id
        }
    });
    return (_jsxs("div", { "data-testid": "EmployeesList-Employee", className: classNames('flex flex-col', 'p-p10 rounded-br40 bg-surface-background'), children: [!useNetworkMappingNewTooltips && (_jsx(TeamMemberConnections, { employee: employee })), _jsxs("div", { className: classNames('flex flex-col gap-2', 'p-p50', 'rounded-br40 bg-surface-default border border-border border-solid', 'h-full'), children: [_jsxs("div", { className: "flex flex-row items-center gap-x-g60", children: [_jsx("div", { className: "flex-shrink-0", children: _jsx(AvatarWithHighlightBadge, { size: "x-large", highlights: highlights, src: employee.profilePictureUrl ?? '', personId: id, href: personRoute }) }), _jsxs("div", { className: "gap-y-1 w-full", children: [_jsxs("div", { className: classNames('flex flex-row gap-g20', currentTitle && 'items-center'), children: [_jsx(InternalLink, { to: { pathname: personRoute }, children: _jsx("p", { className: "typography-label-default-strong text-content-strong line-clamp-1", children: employee.fullName }) }), _jsxs("div", { className: "hidden lg:flex gap-g20", children: [useNetworkMappingNewTooltips &&
                                                        userConnections &&
                                                        userConnections?.length > 0 && (_jsx(NetworkBadgeWithTooltip, { usersInNetwork: userConnections?.map((user) => ({
                                                            name: user?.user?.name ?? '',
                                                            email: user?.user?.email ?? ''
                                                        })) ?? [], targetEntityUrn: urnFrom(EntityType.PERSON, id), targetName: employee.fullName ?? 'Unknown', onClick: () => navigate(personRoute +
                                                            `?selectedTab=${CompanyNavbarOptions.NETWORK}`) })), employee.socials?.linkedin?.url && (_jsx(IconButton, { icon: (props) => (_jsx(LinkedInIcon, { ...props, applyCurrentColor: false })), type: "secondary", emphasis: "low", size: "tiny", dataTestId: "LinkedinOutlink", onClick: () => window.open(employee.socials?.linkedin?.url) }))] })] }), _jsx("p", { className: "line-clamp-1 typography-label-xs text-content-weak", children: currentTitle })] }), _jsxs("div", { className: "flex flex-shrink-0 flex-row justify-start gap-2", children: [_jsx(AddPeopleToWatchlist, { personIds: [id] }), _jsx(GetPersonEmailButton, { personId: id, size: "compact" })] })] }), _jsxs("div", { className: classNames('w-full', 'pt-p40', 'flex flex-col flex-wrap items-start content-start gap-g50', 'border-t border-solid border-border border-b-0 border-l-0 border-r-0', 'typography-label-default-default text-content-weak', 'min-h-15'), children: [_jsxs("span", { "data-testid": "EmployeesList-Employee-PriorExperience", className: classNames(!schoolName && 'w-full', 'flex gap-g30 items-center', 'typography-label-default-default text-content-weak'), children: [_jsx(BagIcon, { className: "flex-shrink-0 w-4 h-4" }), ' ', _jsxs("p", { className: "line-clamp-1", children: ["Prior:", ' ', pastTitle && pastCompany ? (_jsxs(_Fragment, { children: [_jsxs(_Fragment, { children: [pastTitle, " at "] }), _jsx("span", { className: "text-content-strong", children: pastCompanyId && pastCompanyId > 0 ? (_jsx(InternalLink, { to: { pathname: `/dashboard/company/${pastCompanyId}` }, children: pastCompany })) : (pastCompany) })] })) : ('N/A')] })] }), _jsxs("span", { className: classNames('flex gap-g30 items-center'), children: [_jsx(GraduationCapIcon, { className: "flex-shrink-0 w-4 h-4" }), ' ', _jsx("p", { className: "line-clamp-1 text-content-strong", children: schoolName ? schoolName : 'N/A' })] })] })] })] }, `employee-${employee.id}`));
};
export default Employee;
