import { gql } from '@apollo/client';
import { CompanySearch } from './companySearchFragment';
export const getCompaniesByIds = gql `
  query GetCompaniesByIds(
    $ids: [Int!]!
    $skipExtended: Boolean = false
    $includesPersonRelationships: Boolean = true
    $includesPersonRelationshipsFoundersAndCeos: Boolean = true
    $includesPersonRelationshipsExecutives: Boolean = true
    $includesLeadershipPriorCompanies: Boolean = true
    $includesFunding: Boolean = true
    $includesTractionMetrics: Boolean = true
    $includesUserConnections: Boolean = true
    $includesUserConnectionsOverview: Boolean = true
    $includesAffinityLists: Boolean = true
    $includesSocials: Boolean = true
    $includesTypedTags: Boolean = true
    $includesHighlights: Boolean = true
    $includesContact: Boolean = true
    $relationshipsPageSize: Int = 5
  ) {
    getCompaniesByIds(ids: $ids) {
      ...CompanySearch
    }
  }
  ${CompanySearch}
`;
