import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import fetchingDataTextLoadingAnimationData from 'assets/harmonic-fetching-data-text-animation-lottie.json';
import harmonicLoadingAnimationData from 'assets/harmonic-logo-animation-lottie.json';
import loadingIcon from 'assets/loading-icon.gif';
import Lottie from 'lottie-light-react';
import { LoadingOverlayContainer, LoadingOverlayMessageContainer } from './resultsWrapperStyles';
const LoadingOverlay = ({ message }) => {
    return (_jsxs(LoadingOverlayContainer, { "data-testid": "loading-container", children: [_jsx("img", { src: loadingIcon }), message && (_jsx(LoadingOverlayMessageContainer, { children: message }))] }));
};
export const HarmonicLoader = ({ showText = true }) => {
    return (_jsxs("div", { className: "flex flex-col justify-center items-center", children: [_jsx(Lottie, { animationData: harmonicLoadingAnimationData, loop: true }), showText && (_jsx("div", { className: "pt-p60 flex flex-col justify-center items-center", children: _jsx(Lottie, { animationData: fetchingDataTextLoadingAnimationData, loop: true }) }))] }));
};
export const GridLoader = () => {
    return (_jsx("div", { "data-testid": "grid-loader", className: "w-full h-full flex justify-center items-center", children: _jsx(HarmonicLoader, { showText: true }) }));
};
export default LoadingOverlay;
