import { gql } from '@apollo/client';
import { GET_WATCHLIST_FRAGMENT } from './getWatchlists';
export const CREATE_WATCHLIST = gql `
  mutation CreateWatchlist($watchlistInput: CompanyWatchlistInput!) {
    createCompanyWatchlist(watchlistInput: $watchlistInput) {
      ...Watchlist
    }
  }
  ${GET_WATCHLIST_FRAGMENT}
`;
