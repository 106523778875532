import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useStore } from 'config/StoreProvider';
import dayjs from 'dayjs';
import Button from 'harmonic-components/Button/Button';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { logger } from 'utils/logger';
const DiligenceReportCard = observer(({ thread, onGenerateReport, onViewReport }) => {
    const { rootStore } = useStore();
    const { diligenceReportLoading } = rootStore.companyResearchStore;
    const [isHovered, setIsHovered] = useState(false);
    const handleButtonClick = () => {
        if (thread && thread?.status != 'error')
            onViewReport?.(thread);
        else
            onGenerateReport?.();
    };
    const handleRerunDiligenceReport = async () => {
        try {
            await rootStore.companyResearchStore.createCompanyDiligenceReport();
        }
        catch (error) {
            logger.error('Failed to rerun diligence report', { error });
        }
    };
    const getButtonLabel = () => {
        if (diligenceReportLoading)
            return 'Loading';
        if (!thread || !thread?.status)
            return 'Generate report';
        if (thread?.status === 'error')
            return 'Regenerate report';
        return 'View report';
    };
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "flex flex-col justify-between p-4 rounded-lg border border-gray-200 border-solid bg-gradient-to-br from-violet-200/5 from-30% to-blue-700/10", children: [_jsxs("div", { className: "flex items-center justify-end gap-1 mt-p8", children: [!!thread && thread?.status !== 'busy' && (_jsxs("button", { className: "inline-flex cursor-pointer items-center px-2 py-0.5 rounded-full bg-purple-300/50 text-purple-800 text-xs font-medium gap-0.5 hover:bg-purple-300/70", onClick: handleRerunDiligenceReport, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, children: [_jsx("span", { className: "icon text-sm  text-purple-600", children: "history" }), isHovered
                                    ? 'Refresh report'
                                    : dayjs(thread.updated_at).fromNow()] })), _jsxs("span", { className: "inline-flex items-center px-2 py-0.5 rounded-full bg-cyan-100/50 text-cyan-800 text-xs font-medium gap-1", children: [_jsx("span", { className: "icon icon-filled text-sm text-cyan-600", children: "people" }), "Firm-wide"] })] }), _jsxs("div", { className: "flex flex-col gap-2 mt-auto", children: [_jsx("h2", { className: "text-2xl font-medium text-gray-900 pt-3", children: "Research brief" }), _jsx("p", { className: "text-gray-600 text-xs", children: "Access comprehensive analysis covering market position, founding team, firm connections, and competitive landscape in one centralized report." }), _jsxs("div", { className: "mt-2 flex flex-row items-center gap-4", children: [_jsx("div", { className: "min-w-[100px]", children: _jsx(Button, { fullWidth: true, type: "primary", label: getButtonLabel(), onClick: handleButtonClick, size: "compact", loading: thread?.status === 'busy', isDisabled: diligenceReportLoading || thread?.status === 'busy' }) }), thread?.status === 'error' && (_jsx("div", { className: "text-red-500 text-xs", children: "An error occurred, try again" }))] })] })] }) }));
});
export default DiligenceReportCard;
