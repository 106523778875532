import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusIcon } from 'assets/harmonic-icons';
import { useStore } from 'config/StoreProvider';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useAddEntityListModal } from 'hooks/useAddEntityListModal';
import useFetchWatchlists from 'hooks/useFetchWatchlists';
import { EntityListType, INITIAL_SEARCH_MODEL } from 'interfaces/SearchModel/Search';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useWatchlistActions } from '../../../hooks/useWatchlistActions';
import ToggleCompanyListsDropdown from '../EntityActions/ToggleCompanyListsDropdown';
import AddCompanytoWatchlistButtonContentLoader from './AddCompanyToWatchlistButtonContentLoader';
const AddCompanyToWatchlistButton = observer(({ companyId, type = 'button', customButtonRenderer, onPopoverOpen, onPopoverClose, enableAffinityLists = false }) => {
    const { rootStore } = useStore();
    const isAffinityIntegrated = rootStore.affinityStore.isIntegrated;
    const affinityIntegrationLoading = rootStore.affinityStore.isIntegrationStatusLoading;
    const [watchlistActionsPopoverAnchorEl, setWatchlistActionsPopoverAnchorEl] = useState(null);
    const WatchlistActionsPopoverOpen = Boolean(watchlistActionsPopoverAnchorEl);
    const handleWatchlistActionsPopoverClose = () => {
        setWatchlistActionsPopoverAnchorEl(null);
        onPopoverClose?.();
    };
    const handleWatchlistActionPopoverOpen = (event) => {
        event.stopPropagation();
        setWatchlistActionsPopoverAnchorEl(event.currentTarget);
        onPopoverOpen?.();
    };
    const { loading: companyWatchlistLoading } = useFetchWatchlists();
    const { addCompaniesToWatchlist } = useWatchlistActions();
    const onCreateWatchlistSuccess = async ({ entityListId }) => {
        await addCompaniesToWatchlist({
            watchlistId: entityListId,
            shouldNavigate: true,
            companyIds: [companyId]
        });
        handleWatchlistActionsPopoverClose();
    };
    useAddEntityListModal({
        entityType: EntityListType.COMPANY_WATCHLIST,
        searchQuery: INITIAL_SEARCH_MODEL,
        onSuccess: onCreateWatchlistSuccess
    });
    if (companyWatchlistLoading)
        return _jsx(AddCompanytoWatchlistButtonContentLoader, {});
    return (_jsxs(_Fragment, { children: [customButtonRenderer ? (customButtonRenderer({
                onClick: handleWatchlistActionPopoverOpen
            })) : (_jsxs(_Fragment, { children: [type === 'button' && (_jsx(Button, { leadingIcon: PlusIcon, onClick: handleWatchlistActionPopoverOpen, type: "primary", emphasis: "high", label: "Add to...", dataTestId: "AddCompanyToWatchlistButton-Plus", size: "compact" })), type === 'icon' && (_jsx(IconButton, { icon: PlusIcon, onClick: handleWatchlistActionPopoverOpen, type: "secondary", emphasis: "high", dataTestId: "AddCompanyToWatchlistButton-Plus" })), type === 'inline' && (_jsx(IconButton, { icon: PlusIcon, onClick: handleWatchlistActionPopoverOpen, type: "secondary", emphasis: "low", dataTestId: "AddCompanyToWatchlistButton-Plus" })), type === 'inline-small' && (_jsx(IconButton, { icon: PlusIcon, onClick: handleWatchlistActionPopoverOpen, type: "secondary", emphasis: "low", dataTestId: "AddCompanyToWatchlistButton-Plus" }))] })), !!WatchlistActionsPopoverOpen && (_jsx(ToggleCompanyListsDropdown, { open: WatchlistActionsPopoverOpen, anchorEl: watchlistActionsPopoverAnchorEl, onClose: handleWatchlistActionsPopoverClose, companyIds: [companyId], displayAffinityLists: enableAffinityLists, displayAddToAffinityButton: !isAffinityIntegrated &&
                    !affinityIntegrationLoading &&
                    enableAffinityLists }))] }));
});
export default AddCompanyToWatchlistButton;
