import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { CompanyStage } from '__generated__/graphql';
import { FundingTotalIcon, LastFundingDateIcon } from 'assets/harmonic-icons';
import InvestorIconGrey from 'assets/harmonic-icons/funding/InvestorsIconGrey';
import HarmonicNoneIcon from 'assets/harmonic-icons/harmonic-none';
import QuestionMarkIcon from 'assets/harmonic-icons/question-mark';
import { getArticle } from 'common/utils/text';
import { CompanySingleRbrV2 } from 'components/Dashboard/Company/CompanyFinancing/CompanySingleRbrV2';
import { FundingMetricCard } from 'components/common/CompanyCard/FundingMetricCard';
import InvestorCard from 'components/common/CompanyCard/InvestorCard';
import { InvestorPill } from 'components/common/Investors/InvestorPill';
import ReportDataIssue from 'components/common/ReportDataIssue';
import dayjs from 'dayjs';
import Button from 'harmonic-components/Button/Button';
import SearchInput from 'harmonic-components/SearchInput/SearchInput';
import useFlags from 'hooks/useFlags';
import { ReportSourceSection, ReportSourceView, getCommonlyReportedIssuesBySection } from 'interfaces/UserReportedDataIssue';
import { debounce } from 'lodash';
import getCompanyCapData from 'mocks/mock-data/get_company_cap_table';
import { getCompanyFinancing } from 'queries/getCompanyFinancingV2';
import { getCompanyProfileHeader } from 'queries/getCompanyProfileHeader';
import { useEffect, useMemo, useState } from 'react';
import { SPLITS, UNKNOWN_VALUE } from 'utils/constants';
import { getFormattedFundingTotal } from 'utils/funding';
import { getCapTableForCompanyId } from 'utils/midtierApi';
import CapTable from './CapTable';
import CapTableWaitlistForm from './CapTableWaitlistForm';
import CompanyFinancingContentLoader from './CompanyFinancingContentLoader';
const MAX_INVESTORS_SHOWN = 5;
const getFundingRoundKey = (fr) => `${fr?.fundingRoundType ?? 'unknown'}_${fr?.announcementDate ?? 'unknown'}_${fr?.fundingAmount ?? 'unknown'}_${fr?.sourceUrl ?? 'unknown'}`;
export const getInvestorKey = (entityUrn, name) => `${entityUrn}_${name}`;
const CompanyFinancing = ({ companyId }) => {
    const { data: companyProfileData, loading: companyProfileLoading } = useQuery(getCompanyProfileHeader, {
        variables: { id: companyId },
        fetchPolicy: 'cache-only'
    });
    const { data: companyFinancingData, loading: companyFinancingLoading } = useQuery(getCompanyFinancing, {
        variables: { id: companyId },
        fetchPolicy: 'cache-only'
    });
    const { enabled: showCapTable } = useFlags(SPLITS.showCapTable);
    const { enabled: showCapTableMock } = useFlags(SPLITS.showCapTableMock);
    const { enabled: showCapTableWaitlist } = useFlags(SPLITS.showCapTableWaitlist);
    const { enabled: enableInvestorPortco } = useFlags(SPLITS.enabledInvestorPortcoInvestments);
    const loading = companyProfileLoading || companyFinancingLoading;
    const companyFunding = companyFinancingData?.getCompanyById?.funding;
    const fundingTotal = getFormattedFundingTotal(companyFunding?.fundingTotal);
    const [inputValue, setInputValue] = useState('');
    const [investorSearchValue, setInvestorSearchValue] = useState('');
    let lastFundingDate = undefined;
    companyFunding?.fundingRounds?.forEach((fr) => {
        if (!lastFundingDate || fr?.announcementDate > lastFundingDate) {
            lastFundingDate = fr?.announcementDate;
        }
    });
    const investorMap = useMemo(() => {
        const investorMap = {};
        companyFunding?.investors?.forEach((i) => {
            if (!i?.name || !i?.logoUrl)
                return;
            const entityUrn = i.__typename === 'Company' ? i.companyUrn : i.personUrn;
            if (!entityUrn)
                return;
            const investorKey = getInvestorKey(entityUrn, i.name);
            investorMap[investorKey] = {
                name: i.name,
                logoUrl: i.logoUrl,
                entityUrn
            };
        });
        companyFunding?.fundingRounds?.forEach((fr) => {
            if (!fr?.investors)
                return;
            fr.investors.forEach((i) => {
                if (!i?.investor || !i.entityUrn || !i.investorName)
                    return;
                const investorKey = getInvestorKey(i.entityUrn, i.investorName);
                if (!investorMap[investorKey]) {
                    investorMap[investorKey] = {
                        name: i.investorName,
                        entityUrn: i.entityUrn
                    };
                }
                investorMap[investorKey] = {
                    ...investorMap[investorKey],
                    investorUrn: i.investorUrn
                };
            });
        });
        return investorMap;
    }, [companyFunding]);
    let fundingRoundToInvestorsMap = useMemo(() => {
        const map = {};
        return map;
    }, []);
    fundingRoundToInvestorsMap = useMemo(() => {
        return (companyFunding?.fundingRounds?.reduce((acc, fr) => {
            if (!fr?.investors)
                return acc;
            const frId = getFundingRoundKey(fr);
            const investorList = fr.investors
                .filter((i) => i?.investor)
                .map((i) => {
                const entityUrn = i.entityUrn;
                const investorName = i.investorName;
                const investorKey = getInvestorKey(entityUrn, investorName);
                const fundingRoundInvestor = {
                    id: frId,
                    roundType: fr.fundingRoundType,
                    isLead: i.isLead,
                    ...investorMap[investorKey]
                };
                return fundingRoundInvestor;
            });
            investorList.sort((a, b) => {
                // First sort by isLead (true comes before false)
                if (a.isLead !== b.isLead) {
                    return b.isLead ? 1 : -1; // true values first
                }
                // Then sort by name
                const investorA = investorMap[getInvestorKey(a.entityUrn, a.name)];
                const investorB = investorMap[getInvestorKey(b.entityUrn, b.name)];
                return investorA.name.localeCompare(investorB.name);
            });
            acc[frId] = investorList;
            return acc;
        }, fundingRoundToInvestorsMap) ?? {});
    }, [companyFunding?.fundingRounds, investorMap, fundingRoundToInvestorsMap]);
    const investorsNotInFundingRounds = useMemo(() => {
        const investorsInFundingRounds = new Set(Object.values(fundingRoundToInvestorsMap).flatMap((investors) => investors.map((investor) => investor.entityUrn)));
        const investorsNotInFundingRoundsSet = new Set(Object.keys(investorMap).filter((investorKey) => {
            const investor = investorMap[investorKey];
            return (!investorsInFundingRounds.has(investor.entityUrn) &&
                (!investorSearchValue ||
                    investor.name
                        .toLowerCase()
                        .includes(investorSearchValue.toLowerCase())));
        }));
        return investorsNotInFundingRoundsSet;
    }, [fundingRoundToInvestorsMap, investorMap, investorSearchValue]);
    let investorToFundingRoundTypesMap = {};
    investorToFundingRoundTypesMap = useMemo(() => {
        return (companyFunding?.fundingRounds?.reduce((acc, fr) => {
            if (!fr?.investors || !fr.fundingRoundType)
                return acc;
            const frId = getFundingRoundKey(fr);
            fr.investors.forEach((i) => {
                if (!i?.entityUrn || !i.investorName)
                    return;
                const investorKey = getInvestorKey(i.entityUrn, i.investorName);
                if (!acc[investorKey])
                    acc[investorKey] = [];
                acc[investorKey].push({
                    id: frId,
                    fundingRoundType: fr.fundingRoundType,
                    isLead: i.isLead ?? false
                });
            });
            return acc;
        }, investorToFundingRoundTypesMap) ?? {});
    }, [companyFunding?.fundingRounds]);
    const companyName = companyFinancingData?.getCompanyById?.name;
    const investorsWithName = useMemo(() => {
        const investors = companyFunding?.investors || [];
        return investors.filter((investor) => investor?.name !== '' &&
            (investorSearchValue === '' ||
                investor?.name
                    ?.toLowerCase()
                    .includes(investorSearchValue.toLowerCase())));
    }, [companyFunding, investorSearchValue]);
    const fundingRounds = useMemo(() => companyFunding?.fundingRounds ?? [], [companyFunding]);
    const filteredFundingRounds = useMemo(() => {
        if (investorSearchValue === '') {
            return fundingRounds;
        }
        return fundingRounds.filter((fr) => fr?.investors?.some((i) => i?.investorName
            ?.toLowerCase()
            .includes(investorSearchValue.toLowerCase())));
    }, [fundingRounds, investorSearchValue]);
    const rbrExist = fundingRounds.length > 0;
    const filteredRoundsExist = filteredFundingRounds.length > 0;
    const noRoundsOrInvestorsExist = fundingRounds?.length === 0 && investorsWithName?.length === 0;
    const [capTableData, setCapTableData] = useState();
    const [containerRef, setContainerRef] = useState(null);
    const [visibleCount, setVisibleCount] = useState(MAX_INVESTORS_SHOWN);
    const [modalOpen, setModalOpen] = useState(false);
    useEffect(() => {
        if (!containerRef)
            return;
        const calculateVisiblePills = () => {
            const containerWidth = containerRef.offsetWidth;
            const pillWidth = 220; // Approximate width of each pill including gap
            const plusButtonWidth = 30; // Width of the +N button including gap
            const maxPillsInRow = Math.floor((containerWidth - plusButtonWidth) / pillWidth);
            setVisibleCount(maxPillsInRow);
        };
        const handleClickOutside = (event) => {
            if (containerRef && !containerRef.contains(event.target)) {
                calculateVisiblePills();
            }
        };
        if (!modalOpen) {
            calculateVisiblePills();
            window.addEventListener('resize', calculateVisiblePills);
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            window.removeEventListener('resize', calculateVisiblePills);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef, modalOpen]);
    useEffect(() => {
        if (!showCapTable)
            return;
        getCapTableForCompanyId(companyId).then((resp) => {
            setCapTableData(resp);
        });
    }, [companyId, showCapTable]);
    const renderAllInvestorPills = () => {
        if ((!fundingRoundToInvestorsMap ||
            Object.keys(fundingRoundToInvestorsMap).length === 0) &&
            investorsNotInFundingRounds.size === 0)
            return UNKNOWN_VALUE;
        const leadInvestorSet = new Set();
        const allInvestorSet = new Set();
        Object.keys(fundingRoundToInvestorsMap).forEach((frId) => {
            const investors = fundingRoundToInvestorsMap[frId];
            investors.forEach((i) => {
                if (!i.entityUrn ||
                    (investorSearchValue &&
                        !i.name.toLowerCase().includes(investorSearchValue.toLowerCase())))
                    return;
                const investorKey = getInvestorKey(i.entityUrn, i.name);
                if (i.isLead)
                    leadInvestorSet.add(investorKey);
                allInvestorSet.add(investorKey);
            });
        });
        investorsNotInFundingRounds.forEach((investorKey) => {
            allInvestorSet.add(investorKey);
        });
        const investorList = Array.from(allInvestorSet).sort((a, b) => {
            if (leadInvestorSet.has(a) && !leadInvestorSet.has(b))
                return -1;
            if (!leadInvestorSet.has(a) && leadInvestorSet.has(b))
                return 1;
            const investorA = investorMap[a];
            const investorB = investorMap[b];
            return investorA.name.localeCompare(investorB.name);
        });
        const handleShowAllInvestors = () => {
            setVisibleCount(allInvestorSet.size);
        };
        return (_jsx("div", { ref: setContainerRef, className: "flex flex-wrap gap-1 items-center w-full min-h-[24px]", children: investorList.map((investorEntityUrn, index) => {
                const investorData = investorMap[investorEntityUrn];
                const investorKey = getInvestorKey(investorData.entityUrn, investorData.name);
                const roundTypes = investorToFundingRoundTypesMap[investorKey]?.map((r) => r.fundingRoundType);
                if (index === visibleCount && visibleCount < allInvestorSet.size) {
                    return (_jsx("div", { className: "w-9 h-6 bg-surface-sentiment-neutral hover:bg-surface-sentiment-hover-neutral active:bg-surface-sentiment-pressed-neutral rounded flex items-center justify-center flex-wrap text-sm transition-colors cursor-pointer", onClick: handleShowAllInvestors, children: `+${allInvestorSet.size - visibleCount}` }, investorData.name));
                }
                if (index >= visibleCount)
                    return;
                return (_jsx(InvestorPill, { investorName: investorData.name, investorLogoUrl: investorData.logoUrl ?? '', investorUrn: investorData.investorUrn, roundTypes: new Set(roundTypes?.filter((r) => r !== null) ?? []), isLead: leadInvestorSet.has(investorKey), small: false, investorModalOptions: {
                        allowModal: true,
                        companyId: companyId,
                        fundingRounds: fundingRounds,
                        modalOpen: modalOpen,
                        setModalOpen: setModalOpen
                    } }, investorData.name));
            }) }));
    };
    const renderNoRoundsOrInvestorsFound = () => {
        const lastFundingType = companyProfileData?.getCompanyById?.funding?.lastFundingType;
        return (_jsxs("div", { className: "mt-20 flex items-center justify-center flex-col gap-4 pt-p50", children: [_jsx(HarmonicNoneIcon, { className: "opacity-50" }), _jsx("p", { className: "text-content-default font-medium text-content-title w-1/2 text-center", children: lastFundingType && lastFundingType !== CompanyStage.PRE_SEED ? (_jsxs(_Fragment, { children: ["Last known funding event was ", getArticle(lastFundingType), ' ', _jsx("span", { className: "font-black", children: lastFundingType.replaceAll('_', ' ') }), ' ', "round. We are actively working on sourcing more information for this company."] })) : (_jsx(_Fragment, { children: "No funding events" })) })] }));
    };
    const renderInvestorsWithoutRbr = () => {
        return (_jsxs("div", { className: "pt-p50", "data-testid": "CompanyFinancing-Investors-Without-Rbr", children: [_jsx("div", { children: _jsx("p", { className: "text-clickables-buttonHover typography-title-small", children: "Investors" }) }), _jsx("div", { className: "mt-6 grid sm:grid-cols-2 gap-4", children: investorsWithName.map((investor) => {
                        const entityUrn = investor?.__typename === 'Company'
                            ? investor?.companyUrn
                            : investor?.personUrn;
                        const investorName = investor?.name;
                        const logoUrl = investorMap[entityUrn]?.logoUrl;
                        return (_jsx(InvestorCard, { investorName: investorName, logoUrl: logoUrl, entityUrn: entityUrn, dataTestId: "CompanyFinancing-NoRbr-Investor" }, investorName));
                    }) })] }));
    };
    const renderInvestorsWithRbr = () => {
        if (!rbrExist)
            return null;
        return (_jsxs("div", { "data-testid": "CompanyFinacing-Round-By-Round", className: "flex flex-col gap-3 pt-p50", children: [filteredFundingRounds.map((fr, index) => {
                    if (!fr)
                        return null;
                    const frId = getFundingRoundKey(fr);
                    const investors = fundingRoundToInvestorsMap[frId];
                    return (_jsx(CompanySingleRbrV2, { fundingRoundId: frId, fundingRound: fr, investorMap: investorMap, investors: investors, investorToFundingRoundTypesMap: investorToFundingRoundTypesMap, companyId: companyId }, index));
                }), investorSearchValue && investorsNotInFundingRounds.size > 0
                    ? renderNoInvestorsFoundForSearchRbr(true)
                    : null] }));
    };
    const renderNoInvestorsFoundForSearchRbr = (hasOther = false) => {
        if (!rbrExist)
            return null;
        const handleClearFilter = () => {
            setInputValue('');
            setInvestorSearchValue('');
        };
        const numUnassociatedInvestors = investorsNotInFundingRounds.size;
        return (_jsxs("div", { className: "flex flex-col justify-center items-center pb-p70 pt-20", children: [_jsx(QuestionMarkIcon, { className: "opacity-50 mb-4" }), numUnassociatedInvestors > 0 ? (_jsxs(_Fragment, { children: [_jsx("span", { className: "typography-title-small text-content-title mt-4", children: `${numUnassociatedInvestors} ${hasOther ? 'other' : ''} matching investor${numUnassociatedInvestors === 1 ? '' : 's'} found but ${numUnassociatedInvestors === 1 ? 'is' : 'are'} not associated with any funding events` }), _jsx("span", { className: "typography-body-medium text-content-default mt-1 mb-4", children: "Click the \"+\" icon next to the investor name in the Funding section above to add to a funding event" }), _jsx("div", { children: _jsx(Button, { type: "secondary", emphasis: "low", size: "compact", onClick: handleClearFilter, label: "Clear filter" }) })] })) : (_jsx("span", { className: "typography-title-medium text-content-title py-p20", children: `${investorSearchValue} could not be found in any funding events` }))] }));
    };
    const renderCapTable = () => {
        return (_jsxs("div", { className: "py-p80", children: [_jsxs("div", { className: "flex justify-between py-p60 border-b mb-g80 border-solid border-border", children: [_jsx("div", { className: "text-content-title typography-title-small", children: "Cap table" }), _jsx(ReportDataIssue, { reportParams: {
                                companyUrn: 'urn:company:harmonic:' + companyId,
                                reportSourceView: ReportSourceView.COMPANY,
                                reportSourceSection: ReportSourceSection.CAP_TABLE
                            }, placeholderText: "This company's captable is missing an entry - Series A-1 ..." })] }), capTableData && (_jsx(CapTable, { companyId: companyId, capTableData: capTableData })), showCapTableWaitlist && !capTableData && (_jsx(CapTableWaitlistForm, { companyId: companyId, companyName: companyName })), showCapTableMock && !capTableData && (_jsx(CapTable, { companyId: companyId, capTableData: getCompanyCapData.capTable }))] }));
    };
    const debouncedSetInvestorSearchValue = useMemo(() => debounce((value) => setInvestorSearchValue(value), 300), [setInvestorSearchValue]);
    const handleInputChange = (value) => {
        setInputValue(value);
        debouncedSetInvestorSearchValue(value);
    };
    useEffect(() => {
        return () => {
            debouncedSetInvestorSearchValue.cancel();
        };
    }, [debouncedSetInvestorSearchValue]);
    const renderSearchInput = () => {
        if (enableInvestorPortco) {
            return (_jsx("div", { className: "", children: _jsx(SearchInput, { value: inputValue, onChange: handleInputChange, placeholder: "Search investor", autoFocus: false }) }));
        }
        return null;
    };
    const renderSectionHeader = (title, reportDataIssue, search) => {
        return (_jsxs("div", { className: "flex justify-between items-center pb-p70 border-b border-solid border-border", children: [_jsx("p", { className: "typography-title-medium text-content-title w-full", children: title }), reportDataIssue && (_jsx(ReportDataIssue, { reportParams: {
                        companyUrn: 'urn:company:harmonic:' + companyId,
                        reportSourceView: ReportSourceView.COMPANY,
                        reportSourceSection: ReportSourceSection.FINANCING
                    }, commonlyReportedIssues: getCommonlyReportedIssuesBySection(ReportSourceSection.FINANCING) })), search ? renderSearchInput() : null] }));
    };
    if (loading)
        return _jsx(CompanyFinancingContentLoader, {});
    const renderFundingEvents = () => {
        if (noRoundsOrInvestorsExist)
            return renderNoRoundsOrInvestorsFound();
        if (!rbrExist)
            return renderInvestorsWithoutRbr();
        if (filteredRoundsExist)
            return renderInvestorsWithRbr();
        if (!filteredRoundsExist)
            return renderNoInvestorsFoundForSearchRbr();
    };
    return (_jsx("div", { className: "min-h-screen", children: _jsxs("div", { className: "w-full", children: [renderSectionHeader('Funding summary', false, true), _jsxs("div", { className: "flex flex-col gap-2 min-w-100 pt-p50", children: [_jsx(FundingMetricCard, { icon: FundingTotalIcon, value: fundingTotal, label: "Funding total" }), _jsx(FundingMetricCard, { icon: LastFundingDateIcon, value: lastFundingDate
                                ? dayjs(lastFundingDate).utc().format('MM/DD/YYYY')
                                : UNKNOWN_VALUE, label: "Latest funding date" }), _jsx(FundingMetricCard, { icon: InvestorIconGrey, value: renderAllInvestorPills(), label: "Investors" })] }), showCapTable && !!capTableData && renderCapTable(), _jsx("div", { className: "pt-10" }), renderSectionHeader('Funding events', true, false), renderFundingEvents()] }) }));
};
export default CompanyFinancing;
