import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from 'harmonic-components/Button/Button';
import Tag from 'harmonic-components/Tag/Tag';
import React, { useEffect, useRef, useState } from 'react';
const RIGHT_BUFFER = 73;
const SelectTruncateWrapper = ({ children }) => {
    const containerRef = useRef(null);
    const [hiddenCount, setHiddenCount] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const childrenArray = React.Children.toArray(children);
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const handleClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsExpanded(!isExpanded);
    };
    useEffect(() => {
        const handleResize = () => {
            const container = containerRef.current;
            if (container) {
                const childElements = container.children;
                let hiddenCount = 0;
                const parentBoundingRect = container.getBoundingClientRect();
                for (let i = 0; i < childElements.length; i++) {
                    const childBoundingRect = childElements[i].getBoundingClientRect();
                    if (childBoundingRect.top > parentBoundingRect.top ||
                        childBoundingRect.right > parentBoundingRect.right - RIGHT_BUFFER) {
                        hiddenCount++;
                    }
                }
                setHiddenCount(hiddenCount);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [children, containerRef]);
    const lastElementOfFirstRow = childrenArray.length - hiddenCount - 1;
    return (_jsxs("div", { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, className: "relative pt-p30", style: { height: !isExpanded ? '32px' : 'auto' }, children: [_jsx("div", { style: { height: !isExpanded ? '21px' : 'auto' }, ref: containerRef, className: "flex gap-g20 flex-wrap relative overflow-hidden", children: childrenArray.map((child, index) => {
                    return (_jsx("div", { style: index === lastElementOfFirstRow
                            ? { marginRight: `${RIGHT_BUFFER}px` }
                            : {}, children: child }, index));
                }) }), hiddenCount > 0 ? (_jsxs("div", { className: `absolute right-0 pr-p30 top-0 z-50 ${isHovered ? 'mt-p20' : 'mt-p30'}`, children: [isExpanded ? (_jsx(Button, { size: "tiny", type: "secondary", emphasis: "low", onClick: handleClick, label: "show less" })) : null, !isExpanded && !isHovered ? (_jsx(Tag, { size: "compact", label: `+${hiddenCount}` })) : null, !isExpanded && isHovered ? (_jsx(Button, { size: "tiny", type: "secondary", emphasis: "low", onClick: handleClick, label: "show all" })) : null] })) : null] }));
};
export default SelectTruncateWrapper;
