import { getIdFromUrn } from 'common/utils/urn';
import useDashboardLocation from 'hooks/useDashboardLocation';
import { INITIAL_SEARCH_MODEL } from 'interfaces/SearchModel/Search';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import useStore from 'stores/zustandStore';
import { getModifiedCompanySortValueAfterValidationCheck } from 'utils/sort';
import { transformSearchModelFromApiv2ForDashboard } from '../../utils/search';
import useObservableSavedSearch from '../useObservableSavedSearch';
import useUpdateSavedSearch from '../useUpdateSavedSearch';
export const useCompanySavedSearchSort = () => {
    const { urn } = useDashboardLocation();
    const userUrn = useStore((state) => state.userUrn);
    const { currentPageSavedSearch } = useObservableSavedSearch();
    const { updateSavedSearch } = useUpdateSavedSearch();
    const savedSearchQuery = useMemo(() => {
        if (!currentPageSavedSearch)
            return null;
        return transformSearchModelFromApiv2ForDashboard(currentPageSavedSearch?.searchQuery);
    }, [currentPageSavedSearch]);
    const isSearchOwner = currentPageSavedSearch?.creator?.entityUrn === userUrn;
    const entityId = urn && getIdFromUrn(urn);
    const updateSortDataOnBackend = useCallback(async (sortField, isDescending) => {
        const clonedSearchQuery = cloneDeep(savedSearchQuery);
        const queryInfo = {
            filter_group: clonedSearchQuery?.filter_group ?? INITIAL_SEARCH_MODEL.filter_group,
            controlled_filter_group: clonedSearchQuery?.controlled_filter_group ??
                INITIAL_SEARCH_MODEL.controlled_filter_group,
            sort: [
                {
                    sortField,
                    descending: isDescending
                }
            ]
        };
        const mainPayload = {
            query: {
                ...INITIAL_SEARCH_MODEL,
                ...queryInfo
            }
        };
        if (!isSearchOwner)
            return;
        await updateSavedSearch({
            idOrUrn: entityId ?? '',
            input: mainPayload
        });
    }, [savedSearchQuery, isSearchOwner, entityId, updateSavedSearch]);
    const sort = useMemo(() => {
        if (currentPageSavedSearch?.searchQuery?.sort) {
            const { sortField, descending } = getModifiedCompanySortValueAfterValidationCheck(currentPageSavedSearch?.searchQuery?.sort?.[0]?.sortField, currentPageSavedSearch?.searchQuery?.sort?.[0]?.descending);
            return {
                sortField,
                descending
            };
        }
    }, [currentPageSavedSearch]);
    const changeSort = useCallback(({ field, descending }) => {
        updateSortDataOnBackend(field, descending);
    }, [updateSortDataOnBackend]);
    return {
        changeSort,
        sort
    };
};
