import { jsx as _jsx } from "react/jsx-runtime";
import EmployeeAttribute from 'harmonic-components/PersonAttribute/EmployeeAttribute';
import PersonAttribute from 'harmonic-components/PersonAttribute/PersonAttribute';
import useFlags from 'hooks/useFlags';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { SPLITS } from 'utils/constants';
import TruncatedList from '../../../../../harmonic-components/TruncatedList/TruncatedList';
import { EventLocations } from '../../../../../utils/analytics';
import { NoDataSubRenderer } from '../CellRenderers';
export const PeopleList = (props) => {
    const headerName = props.column?.getDefinition()?.headerName ?? '';
    const headerId = props.column?.getColId();
    const shouldLinkToLinkedin = headerName.includes('LinkedIn');
    const userConnections = props.node?.data?.company?.user_connections;
    const { enabled: useNetworkMappingNewTooltips } = useFlags(SPLITS.networkMappingNewTooltips);
    if (!headerId) {
        return null;
    }
    const company = get(props, 'node.data.company');
    const relationshipFieldName = headerId.split('.')[1];
    const relevantPersonRelationships = (get(company, relationshipFieldName) ?? []).filter((person) => person != null);
    const personRelationships = useMemo(() => {
        return relevantPersonRelationships.map((personRelationship) => {
            if (personRelationship) {
                return {
                    ...personRelationship,
                    person: {
                        id: personRelationship.id,
                        full_name: personRelationship.fullName,
                        linkedin_url: personRelationship.socials?.linkedin?.url,
                        personal_emails: personRelationship.contact?.emails
                    },
                    person_company_emails: personRelationship?.contact?.emails
                };
            }
        });
    }, [relevantPersonRelationships]);
    const team = useMemo(() => {
        const peopleInNetwork = userConnections?.flatMap((userConnection) => 'urn:harmonic:person:' + userConnection.target_person?.id) ?? [];
        const teamMembers = personRelationships?.filter((relationship) => relationship?.person?.full_name) || [];
        return teamMembers
            .map((person) => {
            const teamMemberHarmonicProfileLink = get(person, 'person.id', null)
                ? `/dashboard/person/${get(person, 'person.id')}`
                : undefined;
            const useLinkToLinkedIn = shouldLinkToLinkedin && Boolean(get(person, 'person.linkedin_url'));
            return {
                text: person?.person?.full_name ?? '',
                entityUrn: get(person, 'entityUrn'),
                href: useLinkToLinkedIn
                    ? get(person, 'person.linkedin_url')
                    : teamMemberHarmonicProfileLink,
                openedFromLocation: !useLinkToLinkedIn
                    ? EventLocations.GRID_TABLE_TEAM
                    : undefined,
                profilePicSrc: get(person, 'profilePictureUrl'),
                isInNetwork: peopleInNetwork.includes(get(person, 'entityUrn'))
            };
        })
            .filter((listItem) => Boolean(listItem.text))
            .sort((a, b) => {
            // Sort by network status (in network first)
            if (a.isInNetwork && !b.isInNetwork)
                return -1;
            if (!a.isInNetwork && b.isInNetwork)
                return 1;
            // If network status is the same, maintain original order
            return 0;
        });
    }, [personRelationships, shouldLinkToLinkedin, userConnections]);
    if (team.length === 0) {
        return _jsx(NoDataSubRenderer, {});
    }
    if (useNetworkMappingNewTooltips) {
        return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 54, children: team.map((person, index) => (_jsx(EmployeeAttribute, { src: person.profilePicSrc ?? '', href: person.href ?? '', name: person.text, isInNetwork: person.isInNetwork, entityUrn: person.entityUrn, companyUrn: company?.entity_urn ?? '' }, index))) }) }));
    }
    return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { wrapperClassName: "-ml-p30", height: 54, children: team.map((person, index) => (_jsx(PersonAttribute, { src: person.profilePicSrc ?? '', href: person.href ?? '', name: person.text ?? '' }, index))) }) }));
};
