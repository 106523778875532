import { jsx as _jsx } from "react/jsx-runtime";
import useDashboardLocation, { DashboardLocation } from 'hooks/useDashboardLocation';
import useUpsertCompanyWatchlistNamedViews from 'hooks/useUpsertCompanyWatchlistNamedViews';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useCallback } from 'react';
import { useShallowTableStore } from 'stores/tableStore';
import { convertCompanyListColumnsConfigToUrns } from 'utils/namedViews';
import { useStore } from '../../../../../config/StoreProvider';
import { getUpdatedColumnsForSave } from '../../EditColumnsModal/utils';
import BulkSelectHeaderWithDropdown from '../BulkSelectHeaderWithDropdown';
import CompanySearchFieldsDropdownItems from '../FieldsDropdownItems/CompanySearchFieldsDropdownItems';
import { useFieldsDropdown } from '../FieldsDropdownItems/FieldsDropdownItems';
const _CompanyBulkSelectCheckboxRenderer = (props) => {
    const { rootStore } = useStore();
    const routeDetails = useDashboardLocation();
    const { editTableStoreData, allRowsSelected } = useShallowTableStore([
        'editTableStoreData',
        'editSidebar',
        'allRowsSelected',
        'selectAllStateEnabled',
        'customizeFieldModal'
    ]);
    const { upsertCurrentSelectedNamedView: upsertCompanyNamedView } = useUpsertCompanyWatchlistNamedViews();
    const isWatchlist = routeDetails.location === DashboardLocation.COMPANY_LIST;
    const resetSelectionState = useCallback(() => {
        editTableStoreData('selectedRowIds', []);
        editTableStoreData('selectAllStateEnabled', false);
        rootStore.companyWatchlistViewStore.clearSelectedEntryUrns();
        props.api?.deselectAll();
    }, [editTableStoreData, props.api, rootStore.companyWatchlistViewStore]);
    React.useEffect(() => {
        resetSelectionState();
    }, [resetSelectionState]);
    const onCheckBoxChange = async (e) => {
        e.stopPropagation();
        const selectedNetNewIds = [];
        if (allRowsSelected || allRowsSelected === null) {
            resetSelectionState();
            return;
        }
        const selectedCompanyIds = [];
        const selectedEntryUrns = [];
        props?.api?.forEachNode((node) => {
            if (node?.data?.company?.id !== undefined) {
                selectedCompanyIds.push(node?.data?.company?.id);
            }
            if (node?.data?.netNewId)
                selectedNetNewIds.push(node?.data?.netNewId);
            if (routeDetails.location === DashboardLocation.COMPANY_LIST &&
                node.data.entryUrn) {
                selectedEntryUrns.push(node?.data?.entryUrn);
            }
        });
        editTableStoreData('selectedRowIds', selectedCompanyIds);
        editTableStoreData('selectedNetNewIds', selectedNetNewIds);
        rootStore.companyWatchlistViewStore.addSelectedEntryUrns(selectedEntryUrns);
    };
    const save = (visibleColumns, cols) => {
        if (!isWatchlist) {
            const updatedColumns = getUpdatedColumnsForSave(cols, false);
            rootStore.companiesExploreViewStore.updateTableColumns(updatedColumns);
            return;
        }
        upsertCompanyNamedView({
            variables: {
                visibleColumns
            }
        });
    };
    const { onFieldClick } = useFieldsDropdown({
        colId: props.column.getColId(),
        save,
        convertColumnsConfigToUrns: convertCompanyListColumnsConfigToUrns
    });
    return (_jsx(BulkSelectHeaderWithDropdown, { ...props, isWatchlist: isWatchlist, onCheckBoxChange: onCheckBoxChange, renderFields: (filterTerm, insertFieldMode, onClose) => (_jsx(CompanySearchFieldsDropdownItems, { onFieldClick: (field) => {
                onFieldClick(field, insertFieldMode);
                onClose();
            }, filterTerm: filterTerm })) }));
};
export const CompanyBulkSelectCheckboxRenderer = observer(_CompanyBulkSelectCheckboxRenderer);
