import { makeAutoObservable, reaction, runInAction } from 'mobx';
import client from '../../../config/client';
import { appStore, isLocalNamedView } from '../../../hooks/useAppStore';
import { GET_PEOPLE_WATCHLIST_FRAGMENT } from '../../../queries/getPeopleWatchlists';
import { WATCHLIST_WITH_PEOPLE } from '../../../queries/getWatchlistWithPeople';
import { getPeopleWatchlistSearchVariables } from '../../../utils/watchlist';
import useTableStore from '../../tableStore';
import useStore from '../../zustandStore';
class PeopleWatchlistModel {
    watchlist = null;
    selectedNamedViewUrn = null;
    namedViewDraftStateByUrn = {};
    localSearchTerm = null;
    error = undefined;
    currentTotalCount = undefined;
    apolloSubscription = null;
    apolloResultsSubscription = null;
    zustandSubscription = null;
    localSearchTermSubscription = null;
    constructor(watchlist) {
        this.watchlist = watchlist;
        makeAutoObservable(this);
        this.observeSelectedNamedView();
        this.observeLocalSearchTerm();
        this.observeSelectedNamedViewUrn();
        this.observeApolloFragment();
        this.observeWatchlistResults();
        reaction(() => this.namedViewIndex, () => this.observeSelectedNamedViewUrn());
        reaction(() => ({
            localSearchTerm: this.localSearchTerm,
            selectedNamedViewUrn: this.selectedNamedViewUrn,
            watchlistWithDraftState: this.watchlistWithDraftState
        }), () => {
            // Start/restart observing watchlist results when metadata changes
            this.observeWatchlistResults();
        }, {
            fireImmediately: true
        });
    }
    get watchlistWithDraftState() {
        if (!this.watchlist) {
            return null;
        }
        return {
            ...this.watchlist,
            namedViews: this.watchlist.namedViews.map((namedView) => {
                const namedViewDraftState = this.namedViewDraftStateByUrn[namedView.entityUrn];
                return {
                    ...namedView,
                    name: namedViewDraftState?.name ?? namedView.name,
                    searchQuery: namedViewDraftState?.searchQuery ?? namedView.searchQuery,
                    visibleColumns: namedViewDraftState?.visibleColumns ?? namedView.visibleColumns,
                    displayType: namedViewDraftState?.displayType ?? namedView.displayType,
                    hideEmptyColumns: namedViewDraftState?.hideEmptyColumns ?? namedView.hideEmptyColumns,
                    groupByField: namedViewDraftState?.groupByField?.__typename ===
                        'PeopleListCustomField'
                        ? namedViewDraftState?.groupByField
                        : namedView.groupByField
                };
            })
        };
    }
    get namedViewIndex() {
        return this.selectedNamedViewUrn
            ? this.watchlist?.namedViews?.findIndex((view) => view?.entityUrn === this.selectedNamedViewUrn) ?? 0
            : 0;
    }
    get currentNamedView() {
        return this.watchlist?.namedViews?.[this.namedViewIndex];
    }
    get currentNamedViewWithDraft() {
        return this.watchlistWithDraftState?.namedViews?.[this.namedViewIndex];
    }
    /**
     * Keeps the selectedNamedViewUrn in sync with the zustand store
     */
    observeSelectedNamedView() {
        const selectedNamedViewUrn = useStore.getState().personListSelectedNamedView[this.watchlist?.entityUrn];
        this.selectedNamedViewUrn = selectedNamedViewUrn ?? null;
        useStore.subscribe((state) => state.personListSelectedNamedView[this.watchlist?.entityUrn], (selectedNamedViewUrn) => {
            runInAction(() => {
                this.selectedNamedViewUrn = selectedNamedViewUrn;
            });
        });
    }
    /**
     * Keeps the localSearchTerm in sync with the zustand store
     */
    observeLocalSearchTerm() {
        if (this.localSearchTermSubscription) {
            this.localSearchTermSubscription();
        }
        const localSearchTerm = useTableStore.getState().personListLocalSearchTerm[this.watchlist?.entityUrn];
        this.localSearchTerm = localSearchTerm;
        this.localSearchTermSubscription = useTableStore.subscribe((state) => state.personListLocalSearchTerm[this.watchlist?.entityUrn], (localSearchTerm) => {
            runInAction(() => {
                this.localSearchTerm = localSearchTerm;
            });
        });
    }
    /**
     * Keeps the namedViewDraftStateByUrn in sync with the localSearch.searchByKey
     */
    observeSelectedNamedViewUrn() {
        if (this.zustandSubscription) {
            this.zustandSubscription();
        }
        if (!this.currentNamedView) {
            return;
        }
        const entityUrn = this.currentNamedView?.entityUrn;
        const selectedNamedViewDraft = appStore.getState().localSearch.searchByKey[entityUrn];
        if (isLocalNamedView(selectedNamedViewDraft)) {
            this.namedViewDraftStateByUrn[entityUrn] = selectedNamedViewDraft;
        }
        else {
            this.namedViewDraftStateByUrn[entityUrn] = undefined;
        }
        this.zustandSubscription = appStore.subscribe((state) => state.localSearch.searchByKey[entityUrn], (selectedNamedViewDraft) => {
            runInAction(() => {
                if (isLocalNamedView(selectedNamedViewDraft)) {
                    this.namedViewDraftStateByUrn[entityUrn] = selectedNamedViewDraft;
                }
                else {
                    this.namedViewDraftStateByUrn[entityUrn] = undefined;
                }
            });
        });
    }
    observeApolloFragment() {
        if (this.apolloSubscription) {
            this.apolloSubscription.unsubscribe();
        }
        const from = client.cache.identify({
            __typename: 'PeopleWatchlist',
            id: this.watchlist?.id
        });
        if (!from) {
            throw new Error(`Watchlist with id ${this.watchlist?.id} not found`);
        }
        const observableFragment = client.watchFragment({
            fragment: GET_PEOPLE_WATCHLIST_FRAGMENT,
            fragmentName: 'PeopleWatchlist',
            variables: {
                id: this.watchlist?.id
            },
            from
        });
        this.apolloSubscription = observableFragment.subscribe({
            next: (result) => {
                runInAction(() => {
                    if (result.complete) {
                        this.watchlist = result.data;
                    }
                });
            },
            error: (err) => {
                runInAction(() => {
                    this.error = err;
                });
            }
        });
    }
    observeWatchlistResults() {
        if (this.apolloResultsSubscription) {
            this.apolloResultsSubscription.unsubscribe();
        }
        const variables = getPeopleWatchlistSearchVariables({
            selectedNamedViewUrn: this.selectedNamedViewUrn ?? null,
            peopleWatchlist: this.watchlistWithDraftState ?? null,
            localSearchTerm: this.localSearchTerm ?? null
        });
        const currentQuery = client.readQuery({
            query: WATCHLIST_WITH_PEOPLE,
            variables
        });
        this.currentTotalCount =
            currentQuery?.getPeopleWatchlistByIdOrUrn?.personEntries.totalCount;
        const observableResults = client.watchQuery({
            query: WATCHLIST_WITH_PEOPLE,
            variables
        });
        this.apolloResultsSubscription = observableResults.subscribe({
            next: (result) => {
                runInAction(() => {
                    this.currentTotalCount =
                        result.data.getPeopleWatchlistByIdOrUrn?.personEntries.totalCount;
                });
            }
        });
    }
}
export default PeopleWatchlistModel;
