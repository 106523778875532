import { gql } from '@apollo/client';
import { SIMPLE_PEOPLE_WATCHLIST_ENTRY_NODE_FRAGMENT } from './getWatchlistWithPeople';
const upsertPeopleWatchlistCustomFieldValuesMutation = gql `
  mutation UpsertPeopleWatchlistCustomFieldValues(
    $customFieldUrn: PeopleListCustomFieldUrn!
    $peopleWatchlistUrn: PeopleWatchlistUrn!
    $peopleWatchlistEntryUrns: [PeopleWatchlistEntryUrn!]!
    $customFieldValueInput: PeopleListCustomFieldValueUpsertInput!
    $asyncEnabled: Boolean = false
  ) {
    upsertPeopleWatchlistCustomFieldValues(
      customFieldUrn: $customFieldUrn
      peopleWatchlistUrn: $peopleWatchlistUrn
      peopleWatchlistEntryUrns: $peopleWatchlistEntryUrns
      customFieldValueInput: $customFieldValueInput
      asyncEnabled: $asyncEnabled
    ) {
      ...SimplePeopleListEntryNode
    }
  }
  ${SIMPLE_PEOPLE_WATCHLIST_ENTRY_NODE_FRAGMENT}
`;
export default upsertPeopleWatchlistCustomFieldValuesMutation;
