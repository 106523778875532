import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as CheckMark } from 'assets/harmonic-icons/checkmark.svg';
import classNames from 'classnames';
import { Tooltip } from 'common/components';
import Avatar from 'harmonic-components/Avatar/Avatar';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import Tag from 'harmonic-components/Tag/Tag';
import { useState } from 'react';
export var ListVariant;
(function (ListVariant) {
    ListVariant["default"] = "default";
    ListVariant["nested"] = "nested";
    ListVariant["wrap"] = "wrap";
    ListVariant["checkboxes"] = "checkboxes";
    ListVariant["entity"] = "entity";
    ListVariant["twoLined"] = "twoLined";
    ListVariant["addCustom"] = "addCustom";
    ListVariant["tag"] = "tag";
    ListVariant["tagCheckbox"] = "tagCheckbox";
})(ListVariant || (ListVariant = {}));
export var ListType;
(function (ListType) {
    ListType["default"] = "default";
    ListType["inverted"] = "inverted";
})(ListType || (ListType = {}));
const ListItem = ({ type = ListType.default, showSecondaryIcon = (isHovering) => isHovering, ...props }) => {
    const [isHovering, setIsHovering] = useState(false);
    const handleMouseEnter = () => {
        setIsHovering(true);
        props.onHover && props.onHover();
    };
    const handleMouseLeave = () => {
        setIsHovering(false);
        props.onHover && props.onHover();
    };
    const primaryClasses = classNames('flex items-center justify-between py-p40 px-p50 gap-g-40 bg-int-overlay-secondary-enabled cursor-pointer rounded-br20', 'disabled:bg-int-overlay-secondary-disabled disabled:cursor-default', 
    //label uses aria-disabled. Hence, repeating it
    'aria-disabled:bg-int-overlay-secondary-disabled aria-disabled:cursor-default', props.variant !== 'wrap' && 'w-full', type === 'inverted'
        ? 'hover:bg-int-overlay-inverted-hover'
        : 'hover:bg-int-overlay-secondary-hover', type === 'inverted'
        ? 'data-[focused=true]:bg-int-overlay-inverted-hover'
        : 'data-[focused=true]:bg-int-overlay-secondary-hover', 'active:bg-int-overlay-secondary-pressed', 'focus-visible:bg-int-overlay-secondary-enabled focus-visible:shadow-int-focus-outside', 'text-content-weak');
    if (props.variant === 'checkboxes') {
        return (_jsx("label", { "data-testid": props.testId || 'list-item', "data-focused": props.focused, "aria-disabled": props.disabled, className: primaryClasses, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, id: props.id, children: _jsx(MultiSelectListItem, { ...props, onChange: props.onChange, type: type }) }));
    }
    if (props.variant === 'tagCheckbox') {
        return (_jsx("label", { "data-testid": props.testId || 'list-item', "data-focused": props.focused, "aria-disabled": props.disabled, className: primaryClasses, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, id: props.id, children: _jsx(TagCheckboxListItem, { ...props, type: type }) }));
    }
    // addCustom variant is used for freeSolo list items
    if (props.variant === 'addCustom') {
        return (_jsxs("div", { "data-testid": props.testId || 'list-item', "data-focused": props.focused, "aria-selected": props.selected, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, id: props.id, className: primaryClasses + ' w-full', children: [_jsx("button", { "aria-disabled": props.disabled, "data-testid": "default-list-item", className: "flex text-left items-center p-p00 gap-g40 text-content-default typography-label-default-default aria-disabled:text-content-weak w-full", onClick: props.onClick, children: _jsx("p", { children: props.label }) }), props.onExclude && (_jsx("button", { className: classNames('typography-label rounded-br20 px-p20 hover:bg-int-overlay-secondary-hover', 'text-int-overlay-secondary-onEnabled active:bg-int-overlay-secondary-pressed', 'data-[excluded=true]:text-content-sentiment-negative flex ml-auto z-100'), onClick: () => {
                        props?.onExclude?.();
                    }, children: "Exclude" }))] }));
    }
    return (_jsxs("button", { "data-testid": props.testId || 'list-item', "data-focused": props.focused, disabled: props.disabled, "aria-selected": props.selected, onClick: props.onClick, onMouseDown: props.onMouseDown, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, id: props.id, className: primaryClasses, children: [(props.variant === 'default' || props.variant === 'wrap') && (_jsx(DefaultListItem, { ...props, type: type })), props.variant === 'nested' && _jsx(DefaultNestedListItem, { ...props }), props.variant === 'entity' && _jsx(EntityListItem, { ...props, type: type }), props.variant === 'twoLined' && (_jsx(TwoLinedListItem, { ...props, type: type })), props.variant === 'tag' && _jsx(TagListItem, { ...props, type: type }), props.selected && _jsx(CheckMark, { className: "w-3 h-3 flex-shrink-0" }), props.secondaryIcon && (_jsx("div", { className: classNames(showSecondaryIcon(isHovering || props.focused || false)
                    ? 'flex'
                    : 'hidden'), children: props.secondaryIcon }))] }));
};
const DefaultListItem = (props) => {
    const Icon = props.primaryIcon;
    const destructive = props.destructive;
    const loading = props.loading;
    return (_jsxs("div", { "aria-disabled": props.disabled, "data-testid": "default-list-item", className: classNames('flex text-left flex-grow items-center p-p00 gap-g40 typography-label-default-default truncate relative', {
            'text-content-inverted-strong': props.type === 'inverted',
            'text-content-default aria-disabled:text-content-weak': !destructive,
            'text-content-sentiment-negative': destructive
        }), children: [loading ? (_jsx("div", { className: "absolute inset-0 flex justify-center items-center", children: _jsx(CircularProgress, { classes: {
                        root: 'text-inherit'
                    }, size: 16 }) })) : null, Icon && !props.primaryIconTooltip ? (_jsx(Icon, { className: classNames('w-4 h-4 flex-shrink-0', {
                    'text-white': props.type === 'inverted',
                    'text-content-weak': !destructive,
                    'text-transparent': loading,
                    'text-content-sentiment-negative': destructive
                }) })) : null, Icon && props.primaryIconTooltip && (_jsx(Tooltip, { title: props.primaryIconTooltip, children: _jsx(Icon, { className: "w-4 h-4 fill-current flex-shrink-0" }) })), _jsx("p", { className: classNames('max-w-full truncate', {
                    'text-transparent': loading
                }), children: props.label })] }));
};
const TagListItem = (props) => {
    return (_jsx("div", { "aria-disabled": props.disabled, "data-testid": "default-list-item", className: classNames('flex text-left items-center p-p00 gap-g40 typography-label-default-default min-w-0'), children: _jsx(Tag, { color: props.color, label: props.label, truncate: true }) }));
};
const TagCheckboxListItem = (props) => {
    return (_jsx("div", { "data-testid": "tag-checkbox-list-item", className: "flex items-center justify-between w-full p-p00 gap-g40", children: _jsxs("div", { className: "flex items-center gap-g40 flex-grow truncate", children: [_jsx(Checkbox, { loading: props.loading, onChange: props.onChange, checked: props.selected, disabled: props.disabled, onExclude: props.onExclude, showExcludeButton: Boolean(props.onExclude) && !(props.selected && !props.excludeMode), variant: props.excludeMode ? 'exclude' : 'default', fullWidth: false, inverted: props.type === 'inverted' }), _jsx(Tag, { color: props.color, label: props.label, truncate: true, shrink: false })] }) }));
};
const DefaultNestedListItem = (props) => {
    const Icon = props.primaryIcon;
    const NestedPrimaryIcon = props.nestedPrimaryIcon;
    const NestedSecondaryIcon = props.nestedSecondaryIcon;
    const NestedElement = props.nestedElement;
    return (_jsxs("div", { "aria-disabled": props.disabled, "data-testid": "default-list-item", className: classNames('flex text-left w-full items-center justify-between p-p00 gap-g40 typography-label-default-default text-content-default aria-disabled:text-content-weak', {
            'text-content-inverted-strong': props.type === 'inverted'
        }), children: [_jsxs("div", { className: "flex gap-g40 items-center", children: [Icon && !props.primaryIconTooltip && (_jsx(Icon, { className: classNames('w-4 h-4 text-content-weak', {
                            'text-white': props.type === 'inverted'
                        }) })), Icon && props.primaryIconTooltip && (_jsx(Tooltip, { title: props.primaryIconTooltip, children: _jsx(Icon, { className: "w-4 h-4 fill-current" }) })), _jsx("p", { children: props.label })] }), _jsxs("div", { className: "flex gap-g40 items-center", children: [NestedPrimaryIcon && (_jsx(NestedPrimaryIcon, { className: classNames('w-4 h-4 text-content-weak', {
                            'text-white': props.type === 'inverted'
                        }) })), _jsx("p", { children: props.nestedLabel }), NestedSecondaryIcon && (_jsx(NestedSecondaryIcon, { className: classNames('w-4 h-4 text-content-weak', {
                            'text-white': props.type === 'inverted'
                        }) })), NestedElement] })] }));
};
const MultiSelectListItem = (props) => {
    const Icon = props.primaryIcon;
    return (_jsxs("div", { "data-testid": "multi-select-list-item", className: "flex items-center justify-between w-full p-p00 gap-g40", children: [_jsx(Checkbox, { loading: props.loading, onChange: props.onChange, checked: props.selected, disabled: props.disabled, renderLabelAsDiv: true, label: props.label, onExclude: props.onExclude, showExcludeButton: 
                // Do not show exclude button if item is currently selected (unless it's excluded)
                Boolean(props.onExclude) && !(props.selected && !props.excludeMode), variant: props.excludeMode ? 'exclude' : 'default', fullWidth: true, inverted: props.type === 'inverted', icon: props.primaryInnerIcon }), Icon && !props.primaryIconTooltip && (_jsx(Icon, { className: "w-4 h-4 fill-current" })), Icon && props.primaryIconTooltip && (_jsx(Tooltip, { title: props.primaryIconTooltip, children: _jsx(Icon, { className: "w-4 h-4 fill-current" }) }))] }));
};
const EntityListItem = (props) => {
    return (_jsxs("div", { "data-testid": "entity-list-item", className: `flex items-center w-11/12 pl-p10 py-p10 gap-g50`, children: [_jsx(Avatar, { alt: "entity-list-item-image", src: props.src, size: "large", kind: props.logo }), _jsxs("div", { className: "flex flex-col justify-center items-start gap-g00 text-start", children: [_jsx("p", { className: classNames(`typography-label-default-default line-clamp-1`, props.type === 'inverted' &&
                            classNames('text-content-inverted-strong'), props.type === 'default' && 'text-content-weak'), children: props.label }), props.subtitle1 || props.subtitle2 || props.subtitle3 ? (_jsxs("div", { className: "flex gap-g20 items-center", children: [_jsx("p", { className: classNames(`typography-label-small-default h-4 whitespace-nowrap`, props.type === 'inverted' && 'text-content-inverted-strong', props.type === 'default' && classNames('text-content-weak')), children: props.subtitle1 }), _jsx("span", { className: "text-content-weak typography-label-small-default", children: props.subtitle1 && props.subtitle2 && '•' }), props.subtitle2 ? (_jsx("p", { className: classNames(`typography-label-small-default line-clamp-1`, props.type === 'inverted' &&
                                    classNames('text-content-inverted-strong'), props.type === 'default' && 'text-content-weak'), children: props.subtitle2 })) : null, props.subtitle3 ? (_jsxs(_Fragment, { children: [_jsx("span", { className: "text-content-weak", children: "\u2022" }), _jsx("p", { className: "typography-label-small-default text-content-weak", children: props.subtitle3 })] })) : null] })) : null] })] }));
};
const TwoLinedListItem = (props) => {
    return (_jsxs("div", { "data-testid": "two-lined-list-item", className: "flex flex-col justify-center items-start gap-g00", children: [_jsx("p", { className: "typography-label-default-default text-content-default", children: props.label }), _jsx("p", { className: "typography-label-small-default text-content-weak", children: props.subtitle })] }));
};
export default ListItem;
