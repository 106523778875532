import { LoggerEvent } from './utils/logger';
import { logger } from './utils/logger';
import { lazy } from 'react';
import { lazyRetry } from './utils/lazyRetry';
export const AppPages = () => {
    const PAGES = {
        'dashboard/*': {
            Component: lazy(() => lazyRetry(() => import('screens/Dashboard'), 'dashboard')),
            preload: () => lazyRetry(() => import('screens/Dashboard'), 'dashboard')
        },
        'settings/*': {
            Component: lazy(() => lazyRetry(() => import('components/Dashboard/UserSettings'), 'user-settings')),
            preload: () => lazyRetry(() => import('components/Dashboard/UserSettings'), 'user-settings')
        },
        'docs/*': {
            Component: lazy(() => lazyRetry(() => import('screens/Docs'), 'docs')),
            preload: () => lazyRetry(() => import('screens/Docs'), 'docs')
        },
        onboarding: {
            Component: lazy(() => lazyRetry(() => import('components/Dashboard/Onboarding/Onboarding'), 'onboarding')),
            preload: () => lazyRetry(() => import('components/Dashboard/Onboarding/Onboarding'), 'onboarding')
        },
        login: {
            Component: lazy(() => lazyRetry(() => import('screens/Auth/Login/LoginV2'), 'login')),
            preload: () => lazyRetry(() => import('screens/Auth/Login/LoginV2'), 'login')
        },
        'pw-login': {
            Component: lazy(() => lazyRetry(() => import('screens/Auth/Login/Login'), 'pw-login')),
            preload: () => lazyRetry(() => import('screens/Auth/Login/Login'), 'pw-login')
        },
        'forgot-password': {
            Component: lazy(() => lazyRetry(() => import('screens/Auth/ForgotPassword'), 'forgot-password')),
            preload: () => lazyRetry(() => import('screens/Auth/ForgotPassword'), 'forgot-password')
        },
        'user-status': {
            Component: lazy(() => lazyRetry(() => import('screens/Auth/UserStatus'), 'user-status')),
            preload: () => lazyRetry(() => import('screens/Auth/UserStatus'), 'user-status')
        },
        'email-verification': {
            Component: lazy(() => lazyRetry(() => import('screens/Auth/EmailVerification'), 'email-verification')),
            preload: () => lazyRetry(() => import('screens/Auth/EmailVerification'), 'email-verification')
        },
        'integration-auth': {
            Component: lazy(() => lazyRetry(() => import('screens/Auth/IntegrationRedirect'), 'integration-auth')),
            preload: () => lazyRetry(() => import('screens/Auth/IntegrationRedirect'), 'integration-auth')
        }
    };
    logger.sendPerformanceEvent(LoggerEvent.MODULE_PRELOAD, {
        module: 'app-layout'
    });
    if (process.env.REACT_APP_PRELOAD_MODULES === 'true') {
        Object.entries(PAGES).forEach(([, { preload }]) => {
            preload();
        });
    }
    return PAGES;
};
