import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
//eslint-disable-next-line
import { useShallowTableStore } from 'stores/tableStore';
import { EntityActionType, getIconForAction } from '../EntityActions/EntityActionType';
import ToggleCompanyListsDropdown from '../EntityActions/ToggleCompanyListsDropdown';
//eslint-disable-next-line
import { useQuery } from '@apollo/client';
import { Popover, Tooltip } from '@material-ui/core';
import VerticalMenuIcon from 'assets/harmonic-icons/vertical-menu';
import { getIdFromUrn, getUrnFragment, urnFrom } from 'common/utils/urn';
import Button from 'harmonic-components/Button/Button';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import IconButton from 'harmonic-components/IconButton/IconButton';
import ListItem, { ListType, ListVariant } from 'harmonic-components/ListItem/ListItem';
import useRemovePeopleFromList from 'hooks/Watchlists/useRemovePeopleFromWatchlist';
import useBulkActions from 'hooks/useBulkActions';
import { useCompanyEntityActions } from 'hooks/useCompanyEntityActions';
import useDashboardLocation, { DashboardLocation, EntityType } from 'hooks/useDashboardLocation';
import useFetchWatchlists from 'hooks/useFetchWatchlists';
import { useModal } from 'hooks/useModal';
import { EntityAction } from 'interfaces/Grid';
import { every } from 'lodash';
import { observer } from 'mobx-react';
import { getCompaniesAreLiked } from 'queries/getCompaniesAreLiked';
import { getCompaniesAreHiddenFromSearches } from 'queries/getCompanyIsHiddenFromSearches';
import { getCompaniesViewRecords } from 'queries/getCompanyViewRecords';
import { displayToast } from 'utils/toasts';
import { numberSuffixFormatter, truncateValue } from 'utils/utilities';
import { HotkeyScope } from '../../../config/hotkeys';
import useFlags from '../../../hooks/useFlags';
import useHotkeysWrapper from '../../../hooks/useHotkeys';
import { useRefetchCompanyWatchlistResults } from '../../../hooks/useRefetchCompanyWatchlistResults';
import { useRefetchPeopleWatchlistResults } from '../../../hooks/useRefetchPeopleWatchlistResults';
import { useWatchlistActions } from '../../../hooks/useWatchlistActions';
import { GRID_MAX_SELECT_ALL_ROWS, GRID_MAX_SELECT_ALL_ROWS_HIGHER_LIMIT, SPLITS } from '../../../utils/constants';
import EditAllCompanyCustomFields from '../EntityActions/EditAllCompanyCustomFields';
import EditAllPeopleCustomFields from '../EntityActions/EditAllPeopleCustomFields';
import EditCompanyCustomFields from '../EntityActions/EditCompanyCustomFields';
import EditPeopleCustomFields from '../EntityActions/EditPeopleCustomFields';
import TogglePersonListsDropdown from '../EntityActions/TogglePersonListDropdown';
import GenericDeleteConfirmationModal from '../GenericDeleteConfirmationModal/GenericDeleteConfirmationModal';
import ActionBarEditCustomFieldValueBtn from './ActionBarEditCustomFieldValueBtn';
export const SelectedItemsActionsBarV2 = (props) => {
    const { actionsToShow, dashboardLocation, totalCount } = props;
    const { enabled: enableSelectAll } = useFlags(SPLITS.enableSelectAll);
    const { enabled: selectAllUseHigherLimit } = useFlags(SPLITS.selectAllUseHigherLimit);
    const selectAllLimit = selectAllUseHigherLimit
        ? GRID_MAX_SELECT_ALL_ROWS_HIGHER_LIMIT
        : GRID_MAX_SELECT_ALL_ROWS;
    const { selectedRowIds, editTableStoreData: editTableStore, actionsBarOpen, removeSelectedRowIds, selectAllStateEnabled } = useShallowTableStore([
        'selectedRowIds',
        'editTableStoreData',
        'actionsBarOpen',
        'removeSelectedRowIds',
        'selectAllStateEnabled'
    ]);
    const clearSelectedRowIds = () => {
        removeSelectedRowIds(selectedRowIds);
        editTableStore('selectAllStateEnabled', false);
        editTableStore('actionsBarOpen', false);
    };
    useHotkeysWrapper({
        keys: ['esc'],
        options: {
            scopes: [HotkeyScope.GRID],
            enableOnFormTags: true,
            description: 'Close the actions bar',
            enabled: selectedRowIds.length > 0
        },
        callback: clearSelectedRowIds
    });
    // Close the add to list popup when the actions bar is closed
    useEffect(() => {
        if (!actionsBarOpen) {
            editTableStore('addToListOpenInActionsBar', false);
        }
    }, [actionsBarOpen, editTableStore]);
    if (!actionsBarOpen) {
        return null;
    }
    const isAboveSelectAllLimit = totalCount > selectAllLimit;
    const areAllPossibleRowsSelected = selectedRowIds.length === totalCount;
    const getNumRowsSelectedLabel = () => {
        if (!selectAllStateEnabled) {
            return truncateValue(selectedRowIds.length);
        }
        if (isAboveSelectAllLimit) {
            return truncateValue(selectAllLimit);
        }
        return truncateValue(totalCount);
    };
    const handleSelectAllClick = () => {
        if (selectAllStateEnabled || areAllPossibleRowsSelected) {
            return clearSelectedRowIds();
        }
        editTableStore('selectAllStateEnabled', !selectAllStateEnabled);
    };
    let selectAllButtonLabel;
    if (selectAllStateEnabled || areAllPossibleRowsSelected) {
        selectAllButtonLabel = `Unselect all`;
    }
    else if (isAboveSelectAllLimit) {
        selectAllButtonLabel = `Select first ${numberSuffixFormatter(selectAllLimit)}`;
    }
    else {
        selectAllButtonLabel = `Select all`;
    }
    return (_jsx("div", { className: classNames(`flex mx-auto fixed bottom-5 inset-x-0 pointer-events-none`, 'transition-all ease-in-out duration-300'), "data-testid": "SelectedItemsActionsBar", children: _jsxs("div", { className: "shadow-static-elevation-strong  bg-surface-raised mx-auto rounded-br60 m-g40 border-border border border-solid flex flex-row items-center h-[48px] pointer-events-auto overflow-hidden", children: [_jsxs("div", { className: "px-p60 text-content-default h-full flex items-center", children: [getNumRowsSelectedLabel(), _jsx("span", { className: "ml-g20", children: "Selected" })] }), _jsxs("div", { className: "bg-surface-default border-l border-solid border-border px-p30 py-p30 rounded-l-br60 flex items-center gap-g30", children: [enableSelectAll && (_jsx(Button, { dataTestId: "Action-Bar-Select-All", label: selectAllButtonLabel, type: "secondary", emphasis: "high", size: "default", onClick: handleSelectAllClick })), selectAllStateEnabled ? (_jsx(SelectAllActions, { actionsToShow: actionsToShow, dashboardLocation: dashboardLocation, selectAllLimit: selectAllLimit, 
                            // eslint-disable-next-line
                            onClose: clearSelectedRowIds, totalCount: totalCount })) : (_jsx(SelectedItemsActions, { actionsToShow: actionsToShow, dashboardLocation: dashboardLocation, totalCount: totalCount }))] })] }) }));
};
const SelectedItemsActions = ({ actionsToShow, dashboardLocation }) => {
    const { likeCompanies, unlikeCompanies, hideCompanies, unhideCompanies, setViewed, setUnviewed } = useCompanyEntityActions();
    const { urn } = useDashboardLocation();
    const { removeCompaniesFromWatchlist } = useWatchlistActions();
    const { removePeopleFromList } = useRemovePeopleFromList();
    const { show } = useModal();
    const { location } = useDashboardLocation();
    const isCompanyWatchlistPage = location == DashboardLocation.COMPANY_LIST;
    const isCompanySearchPage = location == DashboardLocation.COMPANY_SEARCH;
    const { selectedRowIds, editTableStoreData: editTableStore, addToListOpenInActionsBar } = useShallowTableStore([
        'selectedRowIds',
        'editTableStoreData',
        'addToListOpenInActionsBar'
    ]);
    const addWatchlistPopupOpen = addToListOpenInActionsBar;
    const editCustomFieldsButtonRef = useRef(null);
    const [editCustomFieldsPopupOpen, setEditCustomFieldsPopupOpen] = useState(false);
    const handleEditCustomFieldsOpen = () => {
        setEditCustomFieldsPopupOpen(true);
    };
    const handleOnEditCustomFieldsClose = () => {
        setEditCustomFieldsPopupOpen(false);
    };
    const setAddWatchlistPopupOpen = (open) => {
        editTableStore('addToListOpenInActionsBar', open);
    };
    const addWatchlistButtonRef = useRef(null);
    const isLiked = every(useQuery(getCompaniesAreLiked, {
        variables: { ids: selectedRowIds },
        fetchPolicy: 'cache-only',
        skip: !(isCompanySearchPage || isCompanyWatchlistPage)
    })?.data?.getCompaniesByIds?.map((company) => company?.isLikedByCurrentUser === true));
    const isViewed = every(useQuery(getCompaniesViewRecords, {
        variables: { ids: selectedRowIds },
        fetchPolicy: 'cache-only',
        skip: !(isCompanySearchPage || isCompanyWatchlistPage)
    })?.data?.getCompaniesByIds?.map((company) => company?.viewRecord !== null));
    const isHiddenFromSearches = every(useQuery(getCompaniesAreHiddenFromSearches, {
        variables: { ids: selectedRowIds },
        fetchPolicy: 'cache-only',
        skip: !(isCompanySearchPage || isCompanyWatchlistPage)
    })?.data?.getCompaniesByIds?.map((company) => company?.isHiddenFromSearchesByCurrentUser === true));
    const handleOpenDeleteConfirmationModal = () => {
        const entityText = dashboardLocation.entityType === EntityType.COMPANY
            ? 'companies'
            : 'people';
        show(_jsx(GenericDeleteConfirmationModal, { title: "Remove from list", description: `Are you sure you want to remove ${selectedRowIds.length} ${entityText} from the list?`, onConfirm: () => handleRemoveFromCurrentList() }));
    };
    const handleRemoveFromCurrentList = () => {
        if (!dashboardLocation.urn) {
            throw new Error('Cannot remove from current list - no current list urn found');
        }
        if (dashboardLocation.entityType == EntityType.COMPANY &&
            dashboardLocation.urn) {
            const listId = getIdFromUrn(dashboardLocation.urn);
            if (!listId) {
                throw new Error('Cannot remove from current list - no list id found');
            }
            removeCompaniesFromWatchlist({
                watchlistId: listId,
                companyIds: selectedRowIds,
                fromHotkey: false,
                confirmBeforeRemoval: true
            });
        }
        else if (dashboardLocation.entityType == EntityType.PERSON) {
            removePeopleFromList(dashboardLocation.urn, selectedRowIds);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { ref: addWatchlistButtonRef, className: "flex gap-g30", children: [actionsToShow.includes(EntityActionType.ADD_TO_WATCHLIST) && (_jsx(Tooltip, { title: "Add to list", children: _jsx("div", { children: _jsx(IconButton, { dataTestId: "EntityActions-actionBar-AddToWatchlist", icon: getIconForAction(EntityActionType.ADD_TO_WATCHLIST, false), type: "secondary", emphasis: "high", 
                                // eslint-disable-next-line
                                onClick: () => setAddWatchlistPopupOpen(true), size: "default" }) }) })), actionsToShow.includes(EntityActionType.TOGGLE_LIKE) && (_jsx(Tooltip, { title: isLiked ? 'Unlike' : 'Like', children: _jsx("div", { children: _jsx(IconButton, { dataTestId: "EntityActions-actionBar-Like", icon: getIconForAction(EntityActionType.TOGGLE_LIKE, isLiked), type: "secondary", emphasis: "high", 
                                // eslint-disable-next-line
                                onClick: () => isLiked
                                    ? unlikeCompanies(selectedRowIds)
                                    : likeCompanies(selectedRowIds), size: "default" }) }) })), actionsToShow.includes(EntityActionType.TOGGLE_HIDE) && (_jsx(Tooltip, { title: "Hide", children: _jsx("div", { children: _jsx(IconButton, { dataTestId: "EntityActions-actionBar-Hide", icon: getIconForAction(EntityActionType.TOGGLE_HIDE, isHiddenFromSearches), type: "secondary", emphasis: "high", 
                                // eslint-disable-next-line
                                onClick: () => isHiddenFromSearches
                                    ? unhideCompanies(selectedRowIds, getIdFromUrn(urn))
                                    : hideCompanies(selectedRowIds, getIdFromUrn(urn)), size: "default" }) }) })), actionsToShow.includes(EntityActionType.TOGGLE_VIEW) && (_jsx(Tooltip, { title: "Mark as viewed", children: _jsx("div", { children: _jsx(IconButton, { dataTestId: "EntityActions-actionBar-View", icon: getIconForAction(EntityActionType.TOGGLE_VIEW, isViewed), type: "secondary", emphasis: "high", 
                                // eslint-disable-next-line
                                onClick: () => isViewed
                                    ? setUnviewed(selectedRowIds)
                                    : setViewed(selectedRowIds), size: "default" }) }) })), actionsToShow.includes(EntityActionType.EDIT_CUSTOM_FIELD_VALUE) && (_jsx(ActionBarEditCustomFieldValueBtn, { buttonRef: editCustomFieldsButtonRef, onEditCustomFieldsOpen: handleEditCustomFieldsOpen }))] }), dashboardLocation.entityType == 'company' && (_jsx(EditCompanyCustomFields, { open: editCustomFieldsPopupOpen, 
                // eslint-disable-next-line
                anchorEl: editCustomFieldsButtonRef.current, onClose: handleOnEditCustomFieldsClose, className: "-mt-[58px]", inverted: true })), dashboardLocation.entityType == 'person' && (_jsx(EditPeopleCustomFields, { open: editCustomFieldsPopupOpen, 
                // eslint-disable-next-line
                anchorEl: editCustomFieldsButtonRef.current, onClose: handleOnEditCustomFieldsClose, className: "-mt-[58px]", inverted: true })), actionsToShow.includes(EntityActionType.REMOVE_FROM_CURRENT_LIST) && (_jsx(Tooltip, { title: "Remove from list", children: _jsx("div", { children: _jsx(IconButton, { dataTestId: "EntityActions-actionBar-RemoveFromCurrentList", icon: getIconForAction(EntityActionType.REMOVE_FROM_CURRENT_LIST, false), type: "secondary", emphasis: "high", onClick: handleOpenDeleteConfirmationModal, size: "default" }) }) })), dashboardLocation.entityType == 'company' && (_jsx(ToggleCompanyListsDropdown, { open: addWatchlistPopupOpen, 
                // eslint-disable-next-line
                anchorEl: addWatchlistButtonRef.current, 
                // eslint-disable-next-line
                onClose: () => setAddWatchlistPopupOpen(false), companyIds: selectedRowIds, className: "-mt-[58px]" })), dashboardLocation.entityType == 'person' && (_jsx(TogglePersonListsDropdown, { open: addWatchlistPopupOpen, 
                // eslint-disable-next-line
                anchorEl: addWatchlistButtonRef.current, 
                // eslint-disable-next-line
                onClose: () => setAddWatchlistPopupOpen(false), personIds: selectedRowIds, className: "-mt-[58px]" }))] }));
};
const _SelectAllActions = ({ actionsToShow, dashboardLocation, selectAllLimit, onClose, totalCount }) => {
    const { urn, location } = dashboardLocation;
    const { likedCompaniesWatchlist, hiddenCompaniesWatchlist } = useFetchWatchlists();
    const { show } = useModal();
    const { editTableStoreData: editTableStore, addToListOpenInActionsBar } = useShallowTableStore(['editTableStoreData', 'addToListOpenInActionsBar']);
    const watchlistId = getUrnFragment(urn, 3) || '';
    const isCompanyWatchlistPage = location === DashboardLocation.COMPANY_LIST;
    const isLikedCompaniesWatchlistPage = isCompanyWatchlistPage && watchlistId === likedCompaniesWatchlist?.id;
    const isHiddenCompaniesWatchlistPage = isCompanyWatchlistPage && watchlistId === hiddenCompaniesWatchlist?.id;
    const addWatchlistPopupOpen = addToListOpenInActionsBar;
    const setAddWatchlistPopupOpen = (open) => {
        editTableStore('addToListOpenInActionsBar', open);
    };
    const editCustomFieldsButtonRef = useRef(null);
    const [editCustomFieldsPopupOpen, setEditCustomFieldsPopupOpen] = useState(false);
    const handleEditCustomFieldsOpen = () => {
        setEditCustomFieldsPopupOpen(true);
    };
    const handleOnEditCustomFieldsClose = () => {
        setEditCustomFieldsPopupOpen(false);
    };
    const addWatchlistButtonRef = useRef(null);
    const actionsMenuRef = useRef(null);
    const [actionsMenuOpen, setActionsMenuOpen] = useState(false);
    const numItemsActionable = totalCount > selectAllLimit ? selectAllLimit : totalCount;
    const handleOpenActionsMenu = () => {
        setActionsMenuOpen(true);
    };
    const handleCloseActionsMenu = () => {
        setActionsMenuOpen(false);
    };
    const showActionsMenu = actionsToShow.includes(EntityActionType.TOGGLE_LIKE) ||
        actionsToShow.includes(EntityActionType.TOGGLE_VIEW) ||
        actionsToShow.includes(EntityActionType.TOGGLE_HIDE);
    const { performBulkAction } = useBulkActions();
    const { enabled: enableAsyncBulkActions } = useFlags(SPLITS.enableAsyncBulkActions);
    const { refetchResults: refetchCompanyWatchlistResults } = useRefetchCompanyWatchlistResults();
    const { refetchResults: refetchPeopleWatchlistResults } = useRefetchPeopleWatchlistResults();
    const handleOpenDeleteConfirmationModal = () => {
        const entityText = dashboardLocation.entityType === EntityType.COMPANY
            ? 'companies'
            : 'people';
        show(_jsx(GenericDeleteConfirmationModal, { title: "Remove from list", description: `Are you sure you want to remove ${numItemsActionable} ${entityText} from the list?`, onConfirm: () => handleRemoveFromCurrentList() }));
    };
    const handleRemoveFromCurrentList = () => {
        if (!dashboardLocation.urn) {
            throw new Error('Cannot remove from current list - no current list urn found');
        }
        if (dashboardLocation.entityType == EntityType.COMPANY &&
            dashboardLocation.urn) {
            const listId = getIdFromUrn(dashboardLocation.urn);
            if (!listId) {
                throw new Error('Cannot remove from current list - no list id found');
            }
            performBulkAction({
                actionToPerform: EntityAction.REMOVE_FROM_WATCHLIST,
                operationCount: numItemsActionable,
                targetWatchlistUrn: dashboardLocation.urn,
                onComplete: (numCompleted) => {
                    displayToast({
                        primaryText: `Removed ${truncateValue(numCompleted)} companies from list`
                    });
                    if (dashboardLocation.urn) {
                        refetchCompanyWatchlistResults(dashboardLocation.urn);
                    }
                },
                actionText: `Removing ${truncateValue(numItemsActionable)} companies from list`,
                onError: (numCompleted) => `Failed to remove ${numItemsActionable - numCompleted} companies from the list`
            });
        }
        else if (dashboardLocation.entityType == EntityType.PERSON) {
            performBulkAction({
                actionToPerform: EntityAction.REMOVE_FROM_WATCHLIST,
                operationCount: numItemsActionable,
                targetWatchlistUrn: dashboardLocation.urn,
                onComplete: (numCompleted) => {
                    displayToast({
                        primaryText: `Removed ${truncateValue(numCompleted)} people from list`
                    });
                    if (dashboardLocation.urn) {
                        refetchPeopleWatchlistResults(dashboardLocation.urn);
                    }
                },
                actionText: `Removing ${truncateValue(numItemsActionable)} people from list`,
                onError: (numCompleted) => `Failed to remove ${numItemsActionable - numCompleted} people from the list`
            });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { ref: addWatchlistButtonRef, className: "flex gap-g30", children: [actionsToShow.includes(EntityActionType.ADD_TO_WATCHLIST) && (_jsx(Tooltip, { title: "Add to list", children: _jsx("div", { children: _jsx(IconButton, { dataTestId: "EntityActions-actionBar-AddToWatchlist", icon: getIconForAction(EntityActionType.ADD_TO_WATCHLIST, false), type: "secondary", emphasis: "high", 
                                // eslint-disable-next-line
                                onClick: () => setAddWatchlistPopupOpen(true), size: "default" }) }) })), actionsToShow.includes(EntityActionType.TOGGLE_LIKE) &&
                        !isLikedCompaniesWatchlistPage && (_jsx(Tooltip, { title: "Favorite", children: _jsx("div", { children: _jsx(IconButton, { "data-testid": "EntityActions-actionBar-Like", icon: getIconForAction(EntityActionType.TOGGLE_LIKE, false), type: "secondary", emphasis: "high", 
                                // eslint-disable-next-line
                                onClick: async () => performBulkAction({
                                    actionToPerform: EntityAction.LIKE,
                                    operationCount: numItemsActionable,
                                    onComplete: (numCompleted) => {
                                        displayToast({
                                            primaryText: `Liked ${truncateValue(numCompleted)} companies`
                                        });
                                    },
                                    actionText: `Liking ${truncateValue(numItemsActionable)} companies`,
                                    onError: (numCompleted) => `Failed to like ${numItemsActionable - numCompleted} companies`
                                }), size: "default" }) }) })), actionsToShow.includes(EntityActionType.TOGGLE_LIKE) &&
                        isLikedCompaniesWatchlistPage && (_jsx(Tooltip, { title: "Unlike", children: _jsx(IconButton, { "data-testid": "EntityActions-actionBar-Unlike", icon: getIconForAction(EntityActionType.TOGGLE_LIKE, true), type: "secondary", emphasis: "high", 
                            // eslint-disable-next-line
                            onClick: () => performBulkAction({
                                actionToPerform: EntityAction.UNLIKE,
                                operationCount: numItemsActionable,
                                onComplete: (numCompleted) => displayToast({
                                    primaryText: `Unliked ${truncateValue(numCompleted)} companies`
                                }),
                                actionText: `Unliking ${truncateValue(numItemsActionable)} companies`,
                                onError: (numCompleted) => `Failed to unlike ${numItemsActionable - numCompleted} companies`
                            }), size: "default" }) })), actionsToShow.includes(EntityActionType.TOGGLE_HIDE) &&
                        isHiddenCompaniesWatchlistPage && (_jsx(Tooltip, { title: "Unhide", children: _jsx(IconButton, { "data-testid": "EntityActions-actionBar-Unhide", icon: getIconForAction(EntityActionType.TOGGLE_HIDE, true), type: "secondary", emphasis: "high", 
                            // eslint-disable-next-line
                            onClick: () => performBulkAction({
                                actionToPerform: EntityAction.UNHIDE,
                                operationCount: numItemsActionable,
                                onComplete: (numCompleted) => displayToast({
                                    primaryText: `Unhid ${truncateValue(numCompleted)} companies`
                                }),
                                actionText: `Unhiding ${truncateValue(numItemsActionable)} companies`,
                                onError: (numCompleted) => `Failed to unhide ${numItemsActionable - numCompleted} companies`
                            }), size: "default" }) })), actionsToShow.includes(EntityActionType.TOGGLE_HIDE) &&
                        !isHiddenCompaniesWatchlistPage && (_jsx(Tooltip, { title: "Hide", children: _jsx(IconButton, { "data-testid": "EntityActions-actionBar-Hide", icon: getIconForAction(EntityActionType.TOGGLE_HIDE, false), type: "secondary", emphasis: "high", 
                            // eslint-disable-next-line
                            onClick: () => performBulkAction({
                                actionToPerform: EntityAction.HIDE,
                                operationCount: numItemsActionable,
                                onComplete: (numCompleted) => displayToast({
                                    primaryText: `${truncateValue(numCompleted)} companies added to Hidden companies`
                                }),
                                actionText: `Hiding ${truncateValue(numItemsActionable)} companies`,
                                onError: (numCompleted) => `Failed to hide ${numItemsActionable - numCompleted} companies`
                            }), size: "default" }) })), actionsToShow.includes(EntityActionType.TOGGLE_VIEW) && (_jsx(Tooltip, { title: "Mark as viewed", children: _jsx(IconButton, { "data-testid": "EntityActions-actionBar-View", icon: getIconForAction(EntityActionType.TOGGLE_VIEW, false), type: "secondary", emphasis: "high", 
                            // eslint-disable-next-line
                            onClick: () => performBulkAction({
                                actionToPerform: EntityAction.VIEW,
                                operationCount: numItemsActionable,
                                onComplete: (numCompleted) => displayToast({
                                    primaryText: `Marked ${truncateValue(numCompleted)} companies as viewed`
                                }),
                                actionText: `Marking ${truncateValue(numItemsActionable)} companies as viewed`,
                                onError: (numCompleted) => `Failed to mark ${numItemsActionable - numCompleted} companies as viewed`
                            }), size: "compact" }) })), actionsToShow.includes(EntityActionType.EDIT_CUSTOM_FIELD_VALUE) && (_jsx(ActionBarEditCustomFieldValueBtn, { buttonRef: editCustomFieldsButtonRef, onEditCustomFieldsOpen: handleEditCustomFieldsOpen })), actionsToShow.includes(EntityActionType.REMOVE_FROM_CURRENT_LIST) && (_jsx(Tooltip, { title: "Remove from list", children: _jsx("div", { children: _jsx(IconButton, { dataTestId: "EntityActions-actionBar-RemoveFromCurrentList", icon: getIconForAction(EntityActionType.REMOVE_FROM_CURRENT_LIST, false), type: "secondary", emphasis: "high", onClick: handleOpenDeleteConfirmationModal, size: "default" }) }) })), showActionsMenu && (_jsx("div", { ref: actionsMenuRef, children: _jsx(IconButton, { type: "secondary", emphasis: "high", size: "default", onClick: handleOpenActionsMenu, icon: () => _jsx(VerticalMenuIcon, { className: "w-4 h-4" }) }) }))] }), dashboardLocation.entityType == 'company' && (_jsx(EditAllCompanyCustomFields, { open: editCustomFieldsPopupOpen, 
                //eslint-disable-next-line
                anchorEl: editCustomFieldsButtonRef.current, onClose: handleOnEditCustomFieldsClose, className: "-mt-[58px]", inverted: true })), dashboardLocation.entityType == 'person' && (_jsx(EditAllPeopleCustomFields, { open: editCustomFieldsPopupOpen, 
                //eslint-disable-next-line
                anchorEl: editCustomFieldsButtonRef.current, onClose: handleOnEditCustomFieldsClose, className: "-mt-[58px]", inverted: true })), dashboardLocation.entityType == 'company' && (_jsx("div", { "data-testid": "toggle-company-list-all-selected", children: _jsx(ToggleCompanyListsDropdown, { open: addWatchlistPopupOpen, 
                    //eslint-disable-next-line
                    anchorEl: addWatchlistButtonRef.current, 
                    //eslint-disable-next-line
                    onClose: () => setAddWatchlistPopupOpen(false), 
                    //eslint-disable-next-line
                    onClick: (watchlistId) => {
                        performBulkAction({
                            actionToPerform: EntityAction.ADD_TO_WATCHLIST,
                            operationCount: numItemsActionable,
                            targetWatchlistUrn: urnFrom('company_watchlist', watchlistId),
                            onComplete: (numCompleted) => {
                                if (enableAsyncBulkActions) {
                                    displayToast({
                                        primaryText: `Adding ${truncateValue(numItemsActionable)} companies to your list. This may take a moment.`,
                                        secondaryText: 'Go to list',
                                        link: `/dashboard/watchlist/urn:harmonic:company_watchlist:${watchlistId}`
                                    });
                                    setAddWatchlistPopupOpen(false);
                                    onClose();
                                    return;
                                }
                                displayToast({
                                    primaryText: `Added ${truncateValue(numCompleted)} companies to list`,
                                    secondaryText: 'Go to list',
                                    link: `/dashboard/watchlist/urn:harmonic:company_watchlist:${watchlistId}`
                                });
                            },
                            isProcessedAsync: enableAsyncBulkActions,
                            actionText: `Adding ${truncateValue(numItemsActionable)} companies to list`,
                            onError: (numCompleted) => `Failed to add ${numItemsActionable - numCompleted} companies to the list`
                        });
                    }, variant: ListVariant.default, companyIds: [], className: "-mt-[58px]" }) })), dashboardLocation.entityType == 'person' && (_jsx("div", { "data-testid": "toggle-person-list-all-selected", children: _jsx(TogglePersonListsDropdown, { open: addWatchlistPopupOpen, 
                    //eslint-disable-next-line
                    anchorEl: addWatchlistButtonRef.current, 
                    //eslint-disable-next-line
                    onClose: () => setAddWatchlistPopupOpen(false), 
                    //eslint-disable-next-line
                    onClick: (watchlistId) => {
                        performBulkAction({
                            actionToPerform: EntityAction.ADD_TO_WATCHLIST,
                            operationCount: numItemsActionable,
                            targetWatchlistUrn: urnFrom('people_watchlist', watchlistId),
                            onComplete: (numCompleted) => {
                                if (enableAsyncBulkActions) {
                                    displayToast({
                                        primaryText: `Adding ${truncateValue(numItemsActionable)} people to your list. This may take a moment.`,
                                        secondaryText: 'Go to list',
                                        link: `/dashboard/people_watchlist/urn:harmonic:people_watchlist:${watchlistId}`
                                    });
                                    setAddWatchlistPopupOpen(false);
                                    onClose();
                                    return;
                                }
                                displayToast({
                                    primaryText: `Added ${truncateValue(numCompleted)} people to list`,
                                    secondaryText: 'Go to list',
                                    link: `/dashboard/people_watchlist/urn:harmonic:people_watchlist:${watchlistId}`
                                });
                            },
                            isProcessedAsync: enableAsyncBulkActions,
                            actionText: `Adding ${truncateValue(numItemsActionable)} people to list`,
                            onError: (numCompleted) => `Failed to add ${numItemsActionable - numCompleted} people to the list`
                        });
                    }, variant: ListVariant.default, personIds: [], className: "-mt-[58px]" }) })), _jsx(Popover, { open: actionsMenuOpen, 
                //eslint-disable-next-line
                anchorEl: actionsMenuRef.current, onClose: handleCloseActionsMenu, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                }, elevation: 0, className: "mt-1", classes: {
                    paper: 
                    // HACK: Need to add padding to we create room for the children shadow to show
                    // Otherwise it gets cut off
                    classNames('rounded-none bg-transparent border-transparent p-p30 -ml-p30', '-mt-[58px]')
                }, disableAutoFocus: true, disableEnforceFocus: true, children: _jsxs(Dropdown, { children: [actionsToShow.includes(EntityActionType.TOGGLE_LIKE) &&
                            !isLikedCompaniesWatchlistPage && (_jsx(ListItem, { type: ListType.default, variant: ListVariant.default, label: "Unlike", 
                            //eslint-disable-next-line
                            onClick: () => performBulkAction({
                                actionToPerform: EntityAction.UNLIKE,
                                operationCount: numItemsActionable,
                                onComplete: (numCompleted) => displayToast({
                                    primaryText: `Unliked ${truncateValue(numCompleted)} companies`
                                }),
                                actionText: `Unliking ${truncateValue(numItemsActionable)} companies`,
                                onError: (numCompleted) => `Failed to unlike ${numItemsActionable - numCompleted} companies`
                            }), value: "unlike", primaryIcon: getIconForAction(EntityActionType.TOGGLE_LIKE, true) })), actionsToShow.includes(EntityActionType.TOGGLE_VIEW) && (_jsx(ListItem, { type: ListType.default, variant: ListVariant.default, label: "Unview", 
                            //eslint-disable-next-line
                            onClick: () => performBulkAction({
                                actionToPerform: EntityAction.UNVIEW,
                                operationCount: numItemsActionable,
                                onComplete: (numCompleted) => displayToast({
                                    primaryText: `Marked ${truncateValue(numCompleted)} companies as unviewed`
                                }),
                                actionText: `Marking ${truncateValue(numItemsActionable)} companies as unviewed`,
                                onError: (numCompleted) => `Failed to mark ${numItemsActionable - numCompleted} companies as unviewed`
                            }), value: "unview", primaryIcon: getIconForAction(EntityActionType.TOGGLE_VIEW, true) })), actionsToShow.includes(EntityActionType.TOGGLE_HIDE) &&
                            !isHiddenCompaniesWatchlistPage && (_jsx(ListItem, { type: ListType.default, variant: ListVariant.default, label: "Unhide", 
                            //eslint-disable-next-line
                            onClick: () => performBulkAction({
                                actionToPerform: EntityAction.UNHIDE,
                                operationCount: numItemsActionable,
                                onComplete: (numCompleted) => displayToast({
                                    primaryText: `Unhid ${truncateValue(numCompleted)} companies`
                                }),
                                actionText: `Unhiding ${truncateValue(numItemsActionable)} companies`,
                                onError: (numCompleted) => `Failed to unhide ${numItemsActionable - numCompleted} companies`
                            }), value: "unhide", primaryIcon: getIconForAction(EntityActionType.TOGGLE_HIDE, true) }))] }) })] }));
};
const SelectAllActions = observer(_SelectAllActions);
