import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ResearchReportCard from './common/ResearchReportCard';
export const HiringTable = ({ content }) => {
    // Group rows by department
    const mergedRows = content.rows.reduce((acc, row) => {
        // Convert old format to new format if needed
        const position = {
            title: row.title || '',
            url: row.url || ''
        };
        const existingRow = acc.find((r) => r.department === row.department);
        if (existingRow) {
            existingRow.positions.push(position);
        }
        else {
            if (row.positions.length > 0) {
                // If row already has positions array, use it, otherwise create new array
                const positions = Array.isArray(row.positions)
                    ? row.positions
                    : [position];
                acc.push({
                    department: row.department,
                    positions
                });
            }
        }
        return acc;
    }, []);
    return (_jsx("div", { className: "gap-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3", children: mergedRows.map((row) => (_jsxs(ResearchReportCard, { title: row.department, subtitle: `${row.positions.length} ${row.positions.length === 1 ? 'open role' : 'open roles'}`, children: [_jsx("div", { className: "flex flex-col gap-1", children: row.positions.slice(0, 3).map((position, index) => (_jsx("a", { href: position.url, target: "_blank", rel: "noopener noreferrer", className: "hover:underline", children: position.title }, index))) }), row.positions.length > 3 && (_jsxs("p", { className: "typography-paragraph-small-default text-content-weak", children: ["+", row.positions.length - 3, " more"] }))] }, row.department))) }));
};
