import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { HeartIcon } from 'assets/harmonic-icons';
import HeartFilledIcon from 'assets/harmonic-icons/heart-filled';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useCompanyEntityActions } from 'hooks/useCompanyEntityActions';
import useFlags from 'hooks/useFlags';
import { getCompaniesAllEntityActions } from 'queries/getCompaniesAllEntityActions';
import React, { useMemo } from 'react';
import { SPLITS } from 'utils/constants';
import CompanyIconButtonActionLoader from './CompanyIconButtonActionLoader';
const LikeCompanyButton = ({ companyId }) => {
    const { enabled: enableCustomFieldsInCompanyPage } = useFlags(SPLITS.enableCustomFieldsInCompanyPage);
    const { likeCompanies, unlikeCompanies } = useCompanyEntityActions();
    const { data } = useQuery(getCompaniesAllEntityActions, {
        variables: { ids: [companyId] },
        fetchPolicy: 'cache-only'
    });
    const isLiked = useMemo(() => data?.getCompaniesByIds?.map((company) => company?.isLikedByCurrentUser === true)?.[0], [data?.getCompaniesByIds]);
    const handleClick = () => {
        if (isLiked) {
            unlikeCompanies([companyId]);
            return;
        }
        likeCompanies([companyId]);
    };
    if (isLiked === undefined)
        return _jsx(CompanyIconButtonActionLoader, {});
    return (_jsx(IconButton, { icon: isLiked ? HeartFilledIcon : HeartIcon, onClick: handleClick, type: "secondary", emphasis: "high", size: "compact", dataTestId: "LikeCompanyButton" }));
};
export default LikeCompanyButton;
