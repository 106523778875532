import gql from 'graphql-tag';
import { GET_PEOPLE_WATCHLIST_FRAGMENT } from './getPeopleWatchlists';
export const GET_PEOPLE_WATCHLIST = gql `
  query GetPeopleWatchlist($idOrUrn: String!) {
    getPeopleWatchlistByIdOrUrn(idOrUrn: $idOrUrn) {
      ...PeopleWatchlist
    }
  }
  ${GET_PEOPLE_WATCHLIST_FRAGMENT}
`;
