import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Checkboxes from 'components/QueryBuilder/Common/Checkboxes';
import dayjs from 'dayjs';
import Button from 'harmonic-components/Button/Button';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import Modal from 'harmonic-components/Modal/Modal';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import TextArea from 'harmonic-components/TextArea/TextArea';
import { getFundingTypeDisplayName } from 'interfaces/SearchModel/Search';
import { useMemo, useState } from 'react';
import { getFormattedFundingTotal } from 'utils/funding';
import { submitDataIssue } from 'utils/hasuraApi';
import { displayToast } from 'utils/toasts';
import { Form } from '../Form';
export const SuggestFundingRoundForInvestorModal = (props) => {
    const { investorName, fundingRounds, reportParams, modalOpen, setModalOpen } = props;
    const [loading, setLoading] = useState(false);
    const [textInput, setTextInput] = useState('');
    const [otherCheckboxChecked, handleOtherCheckboxChecked] = useState(false);
    const [selectedRounds, setSelectedRounds] = useState([]);
    const handleClose = () => {
        setSelectedRounds([]);
        setTextInput('');
        setLoading(false);
        setModalOpen?.(false);
    };
    const handleSubmit = async () => {
        setLoading(true);
        const roundsData = selectedRounds.length > 0
            ? `Funding round(s): [${selectedRounds.join(', ')}]`
            : '';
        const otherData = otherCheckboxChecked && textInput ? `Other: ${textInput}` : '';
        const reportText = [roundsData, otherData].join(' | ');
        try {
            submitDataIssue({ ...reportParams, reportText });
            displayToast({
                primaryText: 'Funding round suggestion submitted successfully',
                mode: 'success'
            });
        }
        catch (e) {
            displayToast({
                primaryText: 'There was an error submitting the funding round suggestion',
                mode: 'error'
            });
        }
        finally {
            setLoading(false);
            handleClose();
        }
    };
    const fundingRoundCheckboxes = useMemo(() => fundingRounds
        .filter((round) => round !== null &&
        (round.fundingAmount ||
            round.announcementDate ||
            round.fundingRoundType))
        .map((round) => {
        const roundType = getFundingTypeDisplayName(round?.fundingRoundType) ?? 'Unknown';
        const roundDate = round?.announcementDate
            ? dayjs(round.announcementDate).format('MM/DD/YYYY')
            : null;
        const amount = getFormattedFundingTotal(round?.fundingAmount, null);
        const dateAndAmount = roundDate && round?.fundingAmount
            ? amount + ' • ' + roundDate
            : roundDate ?? amount ?? 'Unknown';
        return {
            label: roundType,
            secondaryLabel: dateAndAmount,
            value: 'Type: ' +
                roundType +
                ' • ' +
                'Date: ' +
                roundDate +
                ' • ' +
                'Amount: ' +
                amount
        };
    }), [fundingRounds]);
    const handleValueChange = (newValues) => {
        setSelectedRounds(newValues ?? []);
    };
    const handleTextInputChange = (newText) => {
        setTextInput(newText);
    };
    return (_jsx(Form, { onSubmit: handleSubmit, children: _jsxs(Modal, { isOpen: modalOpen, onClose: handleClose, children: [_jsx(ModalTitle, { title: `Suggest one or more funding events for ${investorName}` }), _jsx("div", { "data-testid": "SuggestFundingRoundForInvestorModal", className: "p-p50", children: _jsxs("div", { className: "flex flex-col gap-g20 pb-p30", children: [fundingRounds.length > 0 ? (_jsx(_Fragment, { children: _jsx(Checkboxes, { dataTestId: "SuggestFundingRoundForInvestorModal-FundingRounds-Checkboxes", options: fundingRoundCheckboxes, selectedValues: selectedRounds, onValueChange: (newValues) => handleValueChange(newValues), singleColumn: true }) })) : null, _jsxs("div", { className: "pt-p20", children: [_jsx(Checkbox, { label: "Other", checked: otherCheckboxChecked, onChange: (e) => handleOtherCheckboxChecked(e.target.checked) }), _jsx("div", { className: "ml-6 pt-p20", children: _jsx(TextArea, { value: textInput, onChange: (e) => handleTextInputChange(e.target.value), placeholder: "e.g. Funding event, Amount raised, and Date", rows: 2 }) })] })] }) }), _jsx(ModalFooter, { children: _jsx(Button, { dataTestId: "SuggestFundingRoundForInvestorModal-Submit-Button", type: "primary", label: "Submit", isDisabled: loading, loading: loading, onClick: handleSubmit }) })] }) }));
};
