import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { urnFrom } from 'common/utils/urn';
import { useStore } from 'config/StoreProvider';
import { EntityType } from 'hooks/useDashboardLocation';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import CompanyResearchCard from '../../Company/CompanyResearchCard';
import DiligenceReportCard from '../../Company/DiligenceReportCard';
import CompanyResearchInput from '../CompanyResearchInput/CompanyResearchInput';
import ResearchReportModal from '../ResearchReportModal';
const CompanyResearchDrawer = observer(({ companyId, companyName, companyDomain }) => {
    const { rootStore } = useStore();
    const companyResearchStore = rootStore.companyResearchStore;
    const [selectedThread, setSelectedThread] = useState(null);
    const [isDrillInOpen, setDrillInOpen] = useState(false);
    const companyUrn = urnFrom(EntityType.COMPANY, companyId);
    useEffect(() => {
        if (companyUrn) {
            companyResearchStore.setCompany(companyId);
            companyResearchStore.fetchThreads();
            companyResearchStore.fetchCompanyDiligenceReport();
        }
    }, [companyUrn, companyId, companyResearchStore]);
    const handleGenerateHarmonicReportClick = async () => {
        if (!companyUrn)
            return;
        await companyResearchStore.createCompanyDiligenceReport();
    };
    const handleViewThread = (thread) => {
        setSelectedThread(thread);
        setDrillInOpen(true);
    };
    const handleDrawerClose = () => {
        setDrillInOpen(false);
        setSelectedThread(null);
    };
    const threads = companyResearchStore.companyResearch?.threads.filter((thread) => thread.metadata?.report_type !== 'diligence_report');
    return (_jsx("div", { className: "flex flex-col max-h-full overflow-y-auto inverted-scrollbar thin-scrollbar pb-[60px] overflow-x-hidden", children: !!companyName && !!companyUrn && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "grid grid-cols-1 gap-3 w-full flex-grow", children: [_jsx(DiligenceReportCard, { thread: companyResearchStore.diligenceReport, onGenerateReport: handleGenerateHarmonicReportClick, onViewReport: handleViewThread }), companyResearchStore.companyResearch?.loading ? (_jsxs("div", { className: "flex justify-center items-center py-8", children: [_jsx(CircularProgress, { size: 24 }), _jsx("span", { className: "ml-2", children: "Loading research..." })] })) : (_jsxs(_Fragment, { children: [threads?.map((thread) => (_jsx(CompanyResearchCard, { thread: thread, onClick: () => handleViewThread(thread) }, thread.thread_id))), !threads?.length && (_jsxs("div", { className: "text-center py-12 bg-gray-50 rounded-lg border border-gray-200", children: [_jsx("div", { className: "text-gray-500 mb-4", children: _jsx("span", { className: "icon text-4xl", children: "search" }) }), _jsx("h3", { className: "text-lg font-medium text-gray-700 mb-2", children: "No research history found" }), _jsx("p", { className: "text-gray-500", children: "Use the search box above to ask questions about this company." })] }))] }))] }), _jsx("div", { className: "w-[400px]", children: _jsx(CompanyResearchInput, { companyName: companyName, companyUrn: urnFrom(EntityType.COMPANY, companyId), companyDomain: companyDomain }) }), _jsx("div", { className: classNames('absolute top-0 right-0 bottom-0 z-100 bg-white', 'transition-transform duration-300 ease-in-out', 'border-l border-solid border-border', selectedThread?.thread_id ===
                        companyResearchStore.diligenceReport?.thread_id
                        ? 'w-[800px]'
                        : 'w-[400px]', isDrillInOpen ? 'translate-x-0' : 'translate-x-full'), children: _jsx(_Fragment, { children: !!selectedThread && (_jsx(ResearchReportModal, { reportTitle: selectedThread.values?.research_report_title, onBack: handleDrawerClose, summarySection: selectedThread.values?.research_report_summary, reportSections: selectedThread.values?.research_report_sections, thread: selectedThread })) }) })] })) }));
});
export default CompanyResearchDrawer;
