import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from 'harmonic-components/Button/Button';
import TextArea from 'harmonic-components/TextArea/TextArea';
import { useState } from 'react';
import { Form } from '../Form';
const EditCustomFieldsTextValue = ({ onCancel, onSave }) => {
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const handleOnChange = (e) => {
        setValue(e.target.value);
    };
    const handleOnCancel = () => {
        onCancel();
    };
    const handleOnSave = async () => {
        setLoading(true);
        await onSave(value);
        setLoading(false);
    };
    return (_jsx(Form, { onSubmit: handleOnSave, children: _jsxs("div", { "data-testid": "EditCustomFieldsTextValue-Popup", className: "w-full", children: [_jsx(TextArea, { fullWidth: true, placeholder: "This will overwrite all existing values", value: value, onChange: handleOnChange, dataTestId: "EditCustomFieldsTextValue-TextArea", autoFocus: true, isDisabled: loading }), _jsxs("div", { className: "flex gap-g40 mt-p50", children: [_jsx(Button, { fullWidth: true, type: "secondary", emphasis: "medium", size: "compact", label: "Cancel", onClick: handleOnCancel, isDisabled: loading }), _jsx(Button, { fullWidth: true, type: "primary", emphasis: "high", size: "compact", label: "Save", onClick: handleOnSave, loading: loading, isDisabled: loading, dataTestId: "EditCustomFieldsTextValue-SaveButton" })] })] }) }));
};
export default EditCustomFieldsTextValue;
