import PersonColumnsConfig from 'interfaces/PersonColumnsConfig';
const ExperienceOptions = [
    {
        label: PersonColumnsConfig.CURRENT_COMPANY.label,
        value: PersonColumnsConfig.CURRENT_COMPANY.value
    },
    {
        label: PersonColumnsConfig.CURRENT_TITLE.label,
        value: PersonColumnsConfig.CURRENT_TITLE.value
    },
    {
        label: PersonColumnsConfig.CURRENT_DEPARTMENT.label,
        value: PersonColumnsConfig.CURRENT_DEPARTMENT.value
    },
    {
        label: PersonColumnsConfig.CURRENT_COMPANY_TAGS.label,
        value: PersonColumnsConfig.CURRENT_COMPANY_TAGS.value
    },
    {
        label: PersonColumnsConfig.PREVIOUS_COMPANY.label,
        value: PersonColumnsConfig.PREVIOUS_COMPANY.value
    },
    {
        label: PersonColumnsConfig.PREVIOUS_COMPANY_TAGS.label,
        value: PersonColumnsConfig.PREVIOUS_COMPANY_TAGS.value
    }
];
export default ExperienceOptions;
