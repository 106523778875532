import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
import { logger } from 'utils/logger';
import { ChatMessageText } from '../Semantic/Chat/ChatMessageText';
import { CompetitorsTable, HiringTable, List, MarketMapSegments, TalentFlowDiagram, TeamConnectionsTable, TeamSummaryTable } from './ResearchReportArtifacts';
import ExecutiveSummarySection from './ResearchReportArtifacts/ExecutiveSummarySection';
const ARTIFACT_COMPONENTS = {
    market_map_segments: MarketMapSegments,
    competitors_table: CompetitorsTable,
    team_summary_table: TeamSummaryTable,
    talent_flow_diagram: TalentFlowDiagram,
    team_connections_table: TeamConnectionsTable,
    hiring_open_positions_table: HiringTable,
    list: List
};
const ResearchReportContent = ({ sections, level = 0, isTopLevel = false, summarySection, reportType, metadata }) => {
    let sectionNumber = 1;
    const orderedSections = sections.sort((a, b) => {
        return (a.order ?? 0) - (b.order ?? 0);
    });
    return (_jsxs("div", { className: "flex flex-col gap-g80", children: [reportType === 'company_diligence_report' ? (_jsx(ExecutiveSummarySection, { content: summarySection?.content || '', companyUrn: metadata?.companyUrn })) : (_jsx(_Fragment, { children: !!summarySection && (_jsxs("div", { className: "bg-blue-50 px-4 py-2 rounded-lg", children: [_jsx("h4", { className: "font-medium text-gray-800 text-lg mb-4", children: reportType === 'company_diligence_report'
                                ? 'Executive Summary'
                                : summarySection.title }), !!summarySection.content && summarySection.content.length > 0 && (_jsx("p", { className: "text-gray-600", children: _jsx(ChatMessageText, { children: summarySection.content }) }))] })) })), orderedSections.map((section) => {
                // Skip rendering if section has no content, artifacts, or subsections
                if (!section.content &&
                    (!section.artifacts || section.artifacts.length === 0) &&
                    (!section.subSections || section.subSections.length === 0)) {
                    return null;
                }
                return (_jsxs(_Fragment, { children: [level === 0 && (_jsx("div", { className: "w-full flex border-b border-solid border-border my-p100" })), _jsxs("div", { className: classNames('flex flex-col gap-g20', level === 1 && 'pt-p40', level === 2 && 'pt-p20'), children: [_jsx("h4", { className: classNames('font-medium text-gray-800', level === 0 && 'text-lg', level === 1 && 'text-base', level === 2 && 'text-sm'), children: !!section.title &&
                                        sections.length > 1 &&
                                        (isTopLevel && section.title !== 'Executive Summary'
                                            ? `${sectionNumber++}. ${section.title}`
                                            : section.title) }), !!section.content && section.content.length > 0 && (_jsx("p", { className: "text-gray-600", children: _jsx(ChatMessageText, { children: section.content }) })), section.artifacts.map((artifact) => {
                                    const Component = ARTIFACT_COMPONENTS[
                                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                                    artifact.type];
                                    if (!Component) {
                                        logger.error(`No component found for artifact type: ${artifact.type}`);
                                        return null;
                                    }
                                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
                                    const content = artifact.content;
                                    content['metadata'] = metadata;
                                    return (_jsx("div", { className: "mt-4", children: _jsx(Component, { content: content }) }, artifact.type));
                                }), !!section.subSections && section.subSections.length > 0 && (_jsx(ResearchReportContent, { sections: section.subSections, level: level + 1, isTopLevel: false, metadata: metadata }))] }, section.title)] }));
            })] }));
};
export default ResearchReportContent;
