import { gql } from '@apollo/client';
export const getCompaniesAllEntityActions = gql `
  query GetCompaniesAllEntityActions($ids: [Int!]!) {
    getCompaniesByIds(ids: $ids) {
      id
      isLikedByCurrentUser
      isHiddenFromSearchesByCurrentUser
      viewRecord {
        createdAt
        updatedAt
      }
      watchlists {
        id
      }
    }
  }
`;
