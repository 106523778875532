import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Tag from '../Tag/Tag';
const SingleSelectRenderer = ({ value, onChange, mode, placeholder, options }) => {
    const handleOnChange = (e) => {
        onChange(e.target.value);
    };
    const selectedOption = options.find((option) => option.urn === value);
    if (mode === 'read') {
        return (_jsx(Tag, { size: "compact", label: selectedOption?.name ?? '', 
            //eslint-disable-next-line
            color: (selectedOption?.color ?? 'neutral'), truncate: true }));
    }
    return (_jsx("select", { value: value ?? '', onChange: handleOnChange, children: _jsx("option", { value: "", disabled: true, children: placeholder }) }));
};
export default SingleSelectRenderer;
