import { gql } from '@apollo/client';
export const getCompanyFinancing = gql `
  fragment Investor on InvestorUnion {
    __typename
    ... on Company {
      companyUrn: entityUrn
      name
      id
      logoUrl
    }
    ... on Person {
      personUrn: entityUrn
      name: fullName
      id
      logoUrl: profilePictureUrl
    }
  }

  fragment FundingRound on FundingRound {
    announcementDate
    fundingAmount
    fundingCurrency
    fundingRoundType
    sourceUrl
    additionalSources
    postMoneyValuation
    investors {
      entityUrn
      investor {
        ... on Company {
          id
          name
          companyUrn: entityUrn
        }
        ... on Person {
          id
          name: fullName
          personUrn: entityUrn
        }
      }
      investorName
      isLead
      investorUrn
    }
  }

  query GetCompanyFinancingV2($id: Int!) {
    getCompanyById(id: $id) {
      id
      name
      fundingPerEmployee
      funding {
        fundingTotal
        numFundingRounds
        investors {
          ...Investor
        }
        fundingRounds {
          ...FundingRound
        }
      }
    }
  }
`;
