import CompanyColumnsConfig from 'interfaces/CompanyColumnsConfig';
const RecommendedOptions = [
    {
        label: CompanyColumnsConfig.COMPANY_HIGHLIGHTS.label,
        value: CompanyColumnsConfig.COMPANY_HIGHLIGHTS.value
    },
    {
        label: CompanyColumnsConfig.DESCRIPTION.label,
        value: CompanyColumnsConfig.DESCRIPTION.value
    },
    {
        label: CompanyColumnsConfig.FOUNDERS_AND_CEOS.label,
        value: CompanyColumnsConfig.FOUNDERS_AND_CEOS.value
    },
    {
        label: CompanyColumnsConfig.EXECUTIVES.label,
        value: CompanyColumnsConfig.EXECUTIVES.value
    },
    {
        label: CompanyColumnsConfig.TEAM_LINKEDIN_PROFILES.label,
        value: CompanyColumnsConfig.TEAM_LINKEDIN_PROFILES.value
    },
    {
        label: CompanyColumnsConfig.TEAM_EMAILS.label,
        value: CompanyColumnsConfig.TEAM_EMAILS.value
    },
    {
        label: CompanyColumnsConfig.RELEVANCE_SCORE.label,
        value: CompanyColumnsConfig.RELEVANCE_SCORE.value
    },
    {
        label: CompanyColumnsConfig.TEAM_CONNECTIONS.label,
        value: CompanyColumnsConfig.TEAM_CONNECTIONS.value
    },
    {
        label: CompanyColumnsConfig.LINKEDIN.label,
        value: CompanyColumnsConfig.LINKEDIN.value
    },
    {
        label: CompanyColumnsConfig.WEBSITE.label,
        value: CompanyColumnsConfig.WEBSITE.value
    },
    {
        label: CompanyColumnsConfig.ADDED_TO_HARMONIC_DATE.label,
        value: CompanyColumnsConfig.ADDED_TO_HARMONIC_DATE.value
    },
    {
        label: CompanyColumnsConfig.FUNDING_TOTAL.label,
        value: CompanyColumnsConfig.FUNDING_TOTAL.value
    },
    {
        label: CompanyColumnsConfig.LAST_FUNDING_TYPE.label,
        value: CompanyColumnsConfig.LAST_FUNDING_TYPE.value
    },
    {
        label: CompanyColumnsConfig.LAST_FUNDING_DATE.label,
        value: CompanyColumnsConfig.LAST_FUNDING_DATE.value
    },
    {
        label: CompanyColumnsConfig.INVESTORS.label,
        value: CompanyColumnsConfig.INVESTORS.value
    },
    {
        label: CompanyColumnsConfig.HEADCOUNT.label,
        value: CompanyColumnsConfig.HEADCOUNT.value
    },
    {
        label: CompanyColumnsConfig.HEADCOUNT_PERCENT_CHANGE_90D_AGO.label,
        value: CompanyColumnsConfig.HEADCOUNT_PERCENT_CHANGE_90D_AGO.value
    },
    {
        label: CompanyColumnsConfig.TAGS.label,
        value: CompanyColumnsConfig.TAGS.value
    }
];
export default RecommendedOptions;
