import { useQuery } from '@apollo/client';
import { HotkeyBinding, HotkeyConfig, HotkeyScope } from 'config/hotkeys';
import { every } from 'lodash';
import { getCompaniesAreLiked } from 'queries/getCompaniesAreLiked';
import { getCompaniesViewRecords } from 'queries/getCompanyViewRecords';
import { getIdFromUrn, getUrnFragment } from 'common/utils/urn';
import useHotkeys from 'hooks/useHotkeys';
import React, { useState } from 'react';
import useTableStore, { useShallowTableStore } from 'stores/tableStore';
import { useCompanyEntityActions } from './useCompanyEntityActions';
import useDashboardLocation, { DashboardLocation } from './useDashboardLocation';
import useFetchWatchlists from './useFetchWatchlists';
import { useWatchlistActions } from './useWatchlistActions';
export const useInlineActionHotkeys = ({ companyIds, onDelete }) => {
    const { likeCompanies, unlikeCompanies, hideCompanies, unhideCompanies, setViewed, setUnviewed } = useCompanyEntityActions();
    const { deleteCompaniesFromCurrentList } = useWatchlistActions();
    const { urn, location } = useDashboardLocation();
    const watchlistId = getUrnFragment(urn, 3) || '';
    const { hiddenCompaniesWatchlist } = useFetchWatchlists();
    const isCompanyWatchlistPage = location == DashboardLocation.COMPANY_LIST;
    const isCompanySearchPage = location == DashboardLocation.COMPANY_SEARCH;
    const isHiddenCompaniesWatchlistPage = React.useMemo(() => isCompanyWatchlistPage && watchlistId === hiddenCompaniesWatchlist?.id, [hiddenCompaniesWatchlist?.id, isCompanyWatchlistPage, watchlistId]);
    const { setAddToListHoverActionOpenForCompanyId, editTableStoreData } = useShallowTableStore([
        'setAddToListHoverActionOpenForCompanyId',
        'editTableStoreData'
    ]);
    // Store a list of removed rows to prevent multiple hides/removes from being
    // toggled on the same company and throwing an error if user hits the key
    // in quick succession
    const [removedRowEntityIds, setRemovedRowEntityIds] = useState([]);
    const addIdsToRemovedRowEntityIds = (ids) => setRemovedRowEntityIds([...removedRowEntityIds, ...ids]);
    const companyIdsAreAllInRemovedRowEntityIds = companyIds.every((id) => removedRowEntityIds.includes(id));
    // Use fetch policy cache-only to ensure that we re-render on updates to the cache,
    // but that we don't send off a network request for every instance of this on the grid
    const isLiked = every(useQuery(getCompaniesAreLiked, {
        variables: { ids: companyIds },
        fetchPolicy: 'cache-only',
        skip: !(isCompanySearchPage || isCompanyWatchlistPage)
    })?.data?.getCompaniesByIds?.map((company) => company?.isLikedByCurrentUser === true));
    const isViewed = every(useQuery(getCompaniesViewRecords, {
        variables: { ids: companyIds },
        fetchPolicy: 'cache-only',
        skip: !(isCompanySearchPage || isCompanyWatchlistPage)
    })?.data?.getCompaniesByIds?.map((company) => company?.viewRecord !== null));
    useHotkeys({
        keys: HotkeyBinding.TOGGLE_LIKE,
        callback: () => {
            if (isLiked) {
                unlikeCompanies(companyIds, true);
                onDelete();
            }
            else {
                likeCompanies(companyIds, true);
            }
        },
        options: {
            enabled: companyIds.length > 0,
            scopes: [HotkeyScope.GRID],
            description: HotkeyConfig.TOGGLE_LIKE.description
        }
    });
    useHotkeys({
        keys: HotkeyBinding.TOGGLE_HIDE,
        callback: () => {
            if (isCompanySearchPage)
                hideCompanies(companyIds, getIdFromUrn(urn), true);
            if (isHiddenCompaniesWatchlistPage)
                unhideCompanies(companyIds, getIdFromUrn(urn), true);
            addIdsToRemovedRowEntityIds(companyIds);
            onDelete();
        },
        options: {
            enabled: companyIds.length > 0 &&
                (isCompanySearchPage || isHiddenCompaniesWatchlistPage) &&
                !companyIdsAreAllInRemovedRowEntityIds,
            scopes: [HotkeyScope.GRID],
            description: HotkeyConfig.TOGGLE_HIDE.description
        }
    });
    useHotkeys({
        keys: HotkeyBinding.ADD_TO_LIST,
        callback: (e) => {
            e.preventDefault();
            setAddToListHoverActionOpenForCompanyId(companyIds[0]); // @deprecated
            editTableStoreData('addToListOpenInActionsBar', true);
        },
        options: {
            enabled: companyIds.length > 0,
            scopes: [HotkeyScope.GRID],
            description: HotkeyConfig.ADD_TO_LIST.description
        }
    });
    useHotkeys({
        keys: HotkeyBinding.REMOVE_FROM_LIST,
        callback: () => {
            const selectedRowIds = useTableStore.getState().selectedRowIds;
            if (selectedRowIds.length === 0)
                return;
            deleteCompaniesFromCurrentList(companyIds, true);
            addIdsToRemovedRowEntityIds(companyIds);
            onDelete();
        },
        options: {
            enabled: companyIds.length > 0 &&
                isCompanyWatchlistPage &&
                !companyIdsAreAllInRemovedRowEntityIds,
            scopes: [HotkeyScope.GRID],
            description: HotkeyConfig.REMOVE_FROM_LIST.description
        }
    });
    useHotkeys({
        keys: HotkeyBinding.TOGGLE_VIEWED,
        callback: () => {
            isViewed ? setUnviewed(companyIds, true) : setViewed(companyIds, true);
        },
        options: {
            enabled: companyIds.length > 0,
            scopes: [HotkeyScope.GRID],
            description: HotkeyConfig.TOGGLE_VIEWED.description
        }
    });
};
