import { jsx as _jsx } from "react/jsx-runtime";
import Tag from 'harmonic-components/Tag/Tag';
import React from 'react';
import SelectTruncateWrapper from './SelectTruncateWrapper';
const MultiSelectRenderer = ({ value, onChange, mode, placeholder, options }) => {
    const handleOnChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        onChange(selectedOptions);
    };
    if (mode === 'read') {
        return (_jsx(SelectTruncateWrapper, { children: value?.map((option) => {
                const selectedOption = options.find((opt) => opt.urn === option);
                return (_jsx(Tag, { size: "compact", label: selectedOption?.name ?? '', 
                    //eslint-disable-next-line
                    color: (selectedOption?.color ?? 'neutral'), truncate: true }, option));
            }) }));
    }
    return (_jsx("select", { multiple: true, value: value ?? [], onChange: handleOnChange, children: _jsx("option", { value: "", disabled: true, children: placeholder }) }));
};
export default MultiSelectRenderer;
