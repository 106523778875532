import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Avatar from 'harmonic-components/Avatar/Avatar';
import React from 'react';
import { CommonTable } from './CommonTable';
export const TeamConnectionsTable = ({ content }) => {
    const columns = [
        {
            key: 'linkedinUrl',
            header: 'Name',
            render: (item) => (_jsxs("div", { className: "flex flex-row items-center gap-g20", children: [_jsx(Avatar, { src: item.profilePictureUrl, alt: item.name, kind: "face", size: "medium" }), _jsx("a", { href: item.linkedinUrl, target: "_blank", rel: "noopener noreferrer", className: "text-blue-600 hover:underline", children: item.name })] }))
        },
        {
            key: 'connectedThrough',
            header: 'Connected Through'
        },
        {
            key: 'connectedVia',
            header: 'Connected Via'
        }
    ];
    return _jsx(CommonTable, { columns: columns, data: content.rows });
};
