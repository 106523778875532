import { useCallback, useEffect, useRef } from 'react';
import { useShallowTableStore } from 'stores/tableStore';
import useStore from 'stores/zustandStore';
import analytics, { CustomTrackEvent, PerformanceMeasureType } from 'utils/analytics';
import { useHasChangedRoute } from './useHasChangedRoute';
export const useGridLoadingTime = ({ rows }) => {
    const hasChangedRoute = useHasChangedRoute({
        excludeSearchParams: false
    });
    const hasMeasuredRef = useRef(false);
    const didChangeRoute = useStore((state) => state.didChangeRoute);
    const measurementStartRef = useRef(0);
    const { editTableStoreData } = useShallowTableStore(['editTableStoreData']);
    const resetMeasurements = useCallback(() => {
        measurementStartRef.current = window.performance.now();
        editTableStoreData('lastGridLoadingTime', null);
        hasMeasuredRef.current = false;
    }, [editTableStoreData]);
    // Reset when route changed before, e.g. user navigates to another page
    useEffect(() => {
        if (didChangeRoute) {
            resetMeasurements();
        }
    }, [didChangeRoute, resetMeasurements]);
    // Reset when route changes, e.g. user paginates in current grid
    useEffect(() => {
        if (hasChangedRoute) {
            resetMeasurements();
        }
    }, [hasChangedRoute, resetMeasurements]);
    const updateLastGridLoadingTime = () => {
        // Prevent updating the last grid loading time more than once
        if (hasMeasuredRef.current)
            return;
        const measurement = performance.measure(PerformanceMeasureType.TIME_TO_DATA_ON_GRID_ON_FIRST_LOAD, {
            start: measurementStartRef.current,
            end: window.performance.now()
        });
        analytics.trackCustomEvent({
            event: CustomTrackEvent.PERFORMANCE_METRIC,
            properties: {
                name: PerformanceMeasureType.TIME_TO_DATA_ON_GRID_ON_FIRST_LOAD,
                durationMs: measurement.duration
            }
        });
        editTableStoreData('lastGridLoadingTime', measurement.duration);
        hasMeasuredRef.current = true;
    };
    return { updateLastGridLoadingTime, resetMeasurements };
};
