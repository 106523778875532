import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
export const CommonHeaderLayout = (props) => {
    const { children, className } = props;
    return (_jsx("div", { className: classNames('flex ag-header-cell-text flex-row justify-between w-full', className), ...props, onContextMenu: (e) => {
            e.preventDefault();
            e.stopPropagation();
            props.onClick?.(e);
        }, children: children }));
};
