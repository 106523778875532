import { ListCustomFieldType } from '__generated__/graphql';
import { getCustomFieldValueDataFromInput } from 'components/Dashboard/Grids/ColumnDefinitions/WatchlistCustomColumnHeaders';
import { isNil } from 'lodash';
/**
 * Generates a custom field value delete input for a given custom field type.
 * @param customFieldType - The type of the custom field.
 * @returns The custom field value delete input.
 */
export function generateCustomFieldValueDeleteInput(customFieldType) {
    switch (customFieldType) {
        case ListCustomFieldType.CHECKBOX:
            return {
                checkboxData: {
                    value: null
                }
            };
        case ListCustomFieldType.DATE:
            return {
                dateData: {
                    value: null
                }
            };
        case ListCustomFieldType.MULTI_SELECT:
            return {
                multiSelectData: {
                    value: []
                }
            };
        case ListCustomFieldType.NUMBER:
            return {
                numberData: {
                    value: null
                }
            };
        case ListCustomFieldType.PERSON:
            return {
                personData: {
                    value: []
                }
            };
        case ListCustomFieldType.SINGLE_SELECT:
            return {
                selectData: {
                    value: null
                }
            };
        case ListCustomFieldType.TEXT:
            return {
                textData: {
                    value: ''
                }
            };
        case ListCustomFieldType.WEBSITE:
            return {
                websiteData: {
                    value: null
                }
            };
        case ListCustomFieldType.STATUS:
            return {
                selectData: {
                    value: null
                }
            };
        default:
            return {};
    }
}
export function mapCustomFieldValueInputToCacheValue(customFieldValueInput) {
    if (customFieldValueInput.textData) {
        return { textValue: customFieldValueInput.textData.value };
    }
    if (customFieldValueInput.numberData) {
        return { numberValue: customFieldValueInput.numberData.value };
    }
    if (customFieldValueInput.dateData) {
        return { dateValue: customFieldValueInput.dateData.value };
    }
    if (customFieldValueInput.selectData) {
        return {
            // Both status and select use customFieldValueInput.selectData, but the cached values are stored differently
            // We can just set both of the values to the same value
            singleSelectValue: customFieldValueInput.selectData.value,
            statusValue: customFieldValueInput.selectData.value
        };
    }
    if (customFieldValueInput.multiSelectData) {
        return { multiSelectValue: customFieldValueInput.multiSelectData.value };
    }
    if (customFieldValueInput.personData) {
        return { personSelectValue: customFieldValueInput.personData.value };
    }
    if (customFieldValueInput.checkboxData) {
        return { checkboxValue: customFieldValueInput.checkboxData.value };
    }
    if (customFieldValueInput.websiteData) {
        return { websiteValue: customFieldValueInput.websiteData.value };
    }
    return {};
}
export const createNewCustomFieldValue = ({ processingUrn, variables, customField, customFieldValueDefinition }) => {
    //eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return {
        __typename: customFieldValueDefinition,
        urn: processingUrn,
        data: getCustomFieldValueDataFromInput(variables.customFieldValueInput, customField),
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        customField
    };
};
export const getCustomFieldValueFromData = (data) => {
    if (!data) {
        throw new Error('Custom field data is null');
    }
    if (data.__typename === 'CheckboxListCustomFieldValue') {
        return data.checkboxValue;
    }
    if (data.__typename === 'DateListCustomFieldValue') {
        return data.dateValue;
    }
    if (data.__typename === 'MultiSelectCustomFieldValue') {
        return data.multiSelectValue;
    }
    if (data.__typename === 'NumberListCustomFieldValue') {
        return data.numberValue;
    }
    if (data.__typename === 'PersonSelectCustomFieldValue') {
        return data.personSelectValue;
    }
    if (data.__typename === 'SingleSelectCustomFieldValue') {
        return data.singleSelectValue;
    }
    if (data.__typename === 'StatusListCustomFieldValue') {
        return data.statusValue;
    }
    if (data.__typename === 'TextCustomFieldValue') {
        return data.textValue;
    }
    if (data.__typename === 'WebsiteListCustomFieldValue') {
        return data.websiteValue;
    }
    return data;
};
export function generateCustomFieldValueInput(value) {
    switch (value?.__typename) {
        case 'CheckboxListCustomFieldValue':
            return {
                checkboxData: {
                    value: value.checkboxDefaultValue
                }
            };
        case 'DateListCustomFieldDefaultValue':
            return {
                dateData: {
                    value: value.dateDefaultValue
                }
            };
        case 'MultiSelectCustomFieldValue':
            return {
                multiSelectData: {
                    value: value.multiSelectDefaultValue
                }
            };
        case 'NumberListCustomFieldValue':
            return {
                numberData: {
                    value: value.numberDefaultValue
                }
            };
        case 'PersonSelectCustomFieldDefaultValue':
            return {
                personData: {
                    value: value.personDefaultValue
                }
            };
        case 'StatusListCustomFieldValue':
            return {
                selectData: {
                    value: value.statusDefaultValue
                }
            };
        case 'SingleSelectCustomFieldValue':
            return {
                selectData: {
                    value: value.singleSelectDefaultValue
                }
            };
        case 'TextCustomFieldValue':
            return {
                textData: {
                    value: value.textDefaultValue ?? ''
                }
            };
        case 'WebsiteListCustomFieldValue':
            return {
                websiteData: {
                    value: value.websiteDefaultValue
                }
            };
        default:
            return {};
    }
}
export const formatCustomFieldValueInputForBackend = (customFieldValueInput) => {
    // Object.keys returns a string array, but its safe to cast to a keyof CustomFieldValueInput array
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const keys = Object.keys(customFieldValueInput);
    const nonEmptyFields = keys.filter((key) => !isNil(customFieldValueInput[key]));
    if (nonEmptyFields.length > 1) {
        throw new Error('Please provide only one data type to update');
    }
    let data = customFieldValueInput.textData ||
        customFieldValueInput.numberData ||
        customFieldValueInput.dateData ||
        customFieldValueInput.selectData ||
        customFieldValueInput.multiSelectData ||
        customFieldValueInput.personData ||
        customFieldValueInput.checkboxData ||
        customFieldValueInput.websiteData;
    if (!data) {
        throw new Error('Please provide data to update');
    }
    if (customFieldValueInput.dateData &&
        customFieldValueInput.dateData.value === '') {
        data = {
            value: null
        };
    }
    return {
        data
    };
};
