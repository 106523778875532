import { toNumber } from 'lodash';
import { numberSuffixFormatter } from 'utils/utilities';
import { UNKNOWN_VALUE } from './constants';
export const formatFundingAmount = (amount) => {
    if (amount && Number(amount) > 0) {
        return '$' + numberSuffixFormatter(toNumber(amount));
    }
    else {
        return UNKNOWN_VALUE;
    }
};
