export function prepareInflowData(talentFlow) {
    const { talentInflow, schoolInflow } = talentFlow;
    const companies = talentInflow?.talentSources ?? [];
    const schools = schoolInflow?.schoolSources ?? [];
    // Sort & slice top 5
    const topCompanies = companies
        .sort((a, b) => b.employeeCount - a.employeeCount)
        .slice(0, 5)
        .map((c) => ({
        name: c.company,
        count: c.employeeCount,
        logoUrl: c.companyLogoUrl,
        type: 'company',
        companyProfileUrl: c.companyProfileUrl,
        displayCount: `${c.employeeCount} employees`
    }));
    const topSchools = schools
        .sort((a, b) => b.studentCount - a.studentCount)
        .slice(0, 5)
        .map((s) => ({
        name: s.school,
        count: s.studentCount,
        logoUrl: s.schoolLogoUrl,
        type: 'school',
        displayCount: `${s.studentCount} alumni`
    }));
    // Combine & sort overall
    const combined = [...topCompanies, ...topSchools].sort((a, b) => b.count - a.count);
    return combined;
}
export function prepareFounderOutflowData(talentFlow) {
    const outflow = talentFlow.founderOutflow?.alumniFoundedCompanies ?? [];
    const formatCurrency = (num) => {
        if (!num)
            return '$0';
        if (num >= 1e9)
            return `$${(num / 1e9).toFixed(1)}B`;
        if (num >= 1e6)
            return `$${(num / 1e6).toFixed(1)}M`;
        if (num >= 1e3)
            return `$${(num / 1e3).toFixed(1)}K`;
        return `$${num.toLocaleString()}`;
    };
    return outflow.map((item) => {
        const raise = item.dollarsRaised ?? 0;
        return {
            name: item.foundedCompany ?? 'Unknown',
            count: raise,
            logoUrl: item.companyLogoUrl,
            type: 'founded',
            companyProfileUrl: item.companyProfileUrl,
            displayCount: formatCurrency(raise)
        };
    });
}
