import { compact } from 'lodash';
import { WATCHLIST_WITH_COMPANIES } from 'queries/getWatchlistWithCompanies';
import { getCompaniesByIds } from '../queries/getCompaniesByIds';
import useFetchSingleWatchlist from './useFetchSingleWatchlist';
import { useRefetchWatchlistResults } from './useRefetchWatchlistResults';
import { useWatchlistCompanySearchVariables } from './useWatchlistCompanySearchVariables';
export const useRefetchCompanyWatchlistResults = () => {
    const variables = useWatchlistCompanySearchVariables();
    const { companyWatchlist } = useFetchSingleWatchlist();
    return useRefetchWatchlistResults({
        query: WATCHLIST_WITH_COMPANIES,
        extendedQuery: getCompaniesByIds,
        variables,
        getDataPath: (data) => ({
            edges: data?.getCompanyWatchlistByIdOrUrn?.companyEntries?.edges?.map((edge) => ({
                node: { company: { id: edge.node.company.id } }
            })) || [],
            pageInfo: {
                hasNextPage: data?.getCompanyWatchlistByIdOrUrn?.companyEntries?.pageInfo
                    ?.hasNextPage,
                endCursor: data?.getCompanyWatchlistByIdOrUrn?.companyEntries?.pageInfo
                    ?.endCursor
            }
        }),
        getIdsFromData: (data) => compact(data.getCompanyWatchlistByIdOrUrn?.companyEntries.edges.map((edge) => edge.node.company.id)),
        getUrnsFromExtendedData: (data) => data?.getCompaniesByIds?.map((company) => `urn:harmonic:company:${company?.id}`) ?? [],
        watchlist: companyWatchlist
    });
};
