import { getIdFromUrn } from 'common/utils/urn';
import client from '../../config/client';
import { GET_PEOPLE_WATCHLIST } from '../../queries/getPeopleWatchlist';
import PeopleWatchlistModel from './models/PeopleWatchlistModel';
class PeopleWatchlistStore {
    rootStore;
    constructor(rootStore) {
        this.rootStore = rootStore;
    }
    async get(idOrUrn) {
        const id = getIdFromUrn(idOrUrn ?? null) || '';
        const { data, errors } = await client.query({
            query: GET_PEOPLE_WATCHLIST,
            variables: { idOrUrn: id }
        });
        if (!data?.getPeopleWatchlistByIdOrUrn) {
            return {
                watchlist: null,
                error: errors?.[0]
            };
        }
        const watchlist = new PeopleWatchlistModel(data.getPeopleWatchlistByIdOrUrn);
        return {
            watchlist,
            error: errors?.[0]
        };
    }
}
export default PeopleWatchlistStore;
