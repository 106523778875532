import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import Button from 'harmonic-components/Button/Button';
import { useState } from 'react';
import ListStackEmptyState from './ListStackEmptyState';
import ListStackItem from './ListStackItem';
import ListStackLoadingState from './ListStackLoadingState';
const LIST_STACK_MAX_ENTRIES = 5;
const ListStack = ({ listEntries, loading, onSetCustomFieldValues }) => {
    const [showingMore, setShowingMore] = useState(false);
    const handleSetCustomFieldValues = (input) => {
        onSetCustomFieldValues(input);
    };
    const handleShowMore = () => {
        setShowingMore(!showingMore);
    };
    const handleShowLess = () => {
        setShowingMore(false);
    };
    const listEntriesToShow = showingMore
        ? listEntries
        : listEntries.slice(0, LIST_STACK_MAX_ENTRIES);
    if (loading) {
        return _jsx(ListStackLoadingState, {});
    }
    return (_jsxs("div", { className: "bg-surface-background p-p20 rounded-br60", children: [_jsx("div", { className: "pt-p10 pb-p40 pl-p40 pr-p20 flex justify-between items-center", children: _jsxs("div", { className: "flex items-center gap-g30", children: [_jsx("p", { className: "typography-label-medium-strong", children: "Lists" }), listEntries.length > 0 && (_jsx("span", { className: "px-p20 h-4 bg-surface-sentiment-neutral text-content-weak typography-label-small rounded-br20", children: listEntries.length }))] }) }), !listEntries.length && _jsx(ListStackEmptyState, {}), !!listEntries.length && (_jsx("div", { className: "flex flex-col gap-g10", children: listEntriesToShow.map((listEntry) => (_jsx(ListStackItem, { listEntry: listEntry, onSetCustomFieldValues: handleSetCustomFieldValues }, listEntry.list.urn))) })), _jsxs("div", { className: classNames('flex justify-center items-center', {
                    'pt-p20': listEntries.length > LIST_STACK_MAX_ENTRIES
                }), children: [listEntries.length > LIST_STACK_MAX_ENTRIES && !showingMore && (_jsx(Button, { onClick: handleShowMore, dataTestId: "list-stack-show-more-button", label: `+ ${listEntries.length - LIST_STACK_MAX_ENTRIES} more`, type: "secondary", size: "tiny", emphasis: "low", fullWidth: true })), !!showingMore && (_jsx(Button, { onClick: handleShowLess, label: "Show less", type: "secondary", size: "tiny", emphasis: "low", fullWidth: true }))] })] }));
};
export default ListStack;
