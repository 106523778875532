import client from 'config/client';
import { makeAutoObservable, runInAction } from 'mobx';
import { BASE_COMPANY_WATCHLIST_ENTRY_NODE_FRAGMENT } from 'queries/getListEntriesByCompanyId';
import { formatGraphqlCustomFieldToMobxCustomField } from 'utils/custom-fields';
import { CustomFieldType } from '../interfaces';
import { getCustomFieldValueFromData } from '../utils';
class CompanyListEntryModel {
    listEntry = null;
    apolloSubscription = null;
    error = undefined;
    constructor(listEntry) {
        makeAutoObservable(this);
        this.listEntry = listEntry;
        this.observeApolloFragment();
    }
    get urn() {
        if (!this.listEntry?.entryUrn) {
            throw new Error('List entry is null');
        }
        return this.listEntry.entryUrn;
    }
    get list() {
        if (!this.listEntry?.list) {
            throw new Error('List entry is null');
        }
        return {
            urn: this.listEntry?.list.entityUrn,
            name: this.listEntry?.list.name,
            href: `/dashboard/watchlist/${this.listEntry?.list.entityUrn}`,
            updatedAt: this.listEntry?.list.updatedAt,
            customFields: this.listEntry?.list.customFields.map((customField) => {
                return formatGraphqlCustomFieldToMobxCustomField(customField);
            }) ?? []
        };
    }
    get customFieldValues() {
        return (this.listEntry?.customFieldValues.map((customField) => {
            return {
                urn: customField.urn,
                customField: {
                    urn: customField.customField.urn,
                    name: customField.customField.name,
                    type: CustomFieldType[customField.customField.type]
                },
                value: getCustomFieldValueFromData(customField.data)
            };
        }) ?? []);
    }
    observeApolloFragment() {
        if (this.apolloSubscription) {
            this.apolloSubscription.unsubscribe();
        }
        const from = client.cache.identify({
            __typename: 'CompanyWatchlistEntryNode',
            entryUrn: this.listEntry?.entryUrn
        });
        if (!from) {
            throw new Error(`CompanyWatchlistEntryNode with id ${this.listEntry?.entryUrn} not found`);
        }
        const observableFragment = client.watchFragment({
            fragment: BASE_COMPANY_WATCHLIST_ENTRY_NODE_FRAGMENT,
            fragmentName: 'BaseCompanyWatchlistEntryNode',
            variables: {
                entryUrn: this.listEntry?.entryUrn
            },
            from
        });
        this.apolloSubscription = observableFragment.subscribe({
            next: (result) => {
                runInAction(() => {
                    if (result.complete) {
                        this.listEntry = result.data;
                    }
                });
            },
            error: (err) => {
                runInAction(() => {
                    this.error = err;
                });
            }
        });
    }
}
export default CompanyListEntryModel;
