import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import EmailIcon from 'assets/harmonic-icons/email-icon';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useModal } from 'hooks/useModal';
import getPersonContactInformation from 'queries/getPersonContactInformation';
import React from 'react';
import PersonEmailUnlockModal from './PersonEmailUnlockModal';
const GetPersonEmailButtonContentLoader = () => {
    return _jsx("div", { className: "w-8 h-8 rounded-full animated-box-pulse" });
};
const GetPersonEmailButton = ({ personId, size = 'compact' }) => {
    const { data: personContactInformation } = useQuery(getPersonContactInformation, {
        variables: {
            id: personId
        },
        fetchPolicy: 'cache-only'
    });
    const isUnlocked = Boolean(personContactInformation?.getPersonById?.contact?.emails?.length ||
        personContactInformation?.getPersonById?.emailEnrichmentStatus
            ?.enrichedAt !== null);
    const { show } = useModal();
    const handleOnUnlock = () => {
        show(_jsx(PersonEmailUnlockModal, { personId: personId }));
    };
    if (!personContactInformation) {
        return _jsx(GetPersonEmailButtonContentLoader, {});
    }
    return (_jsx(IconButton, { icon: EmailIcon, onClick: handleOnUnlock, type: "secondary", emphasis: "high", size: size, dataTestId: "GetEmailTextIcon" }));
};
export default GetPersonEmailButton;
