import opentelemetry, { metrics } from '@opentelemetry/api';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { OTLPMetricExporter } from '@opentelemetry/exporter-metrics-otlp-http';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { B3Propagator } from '@opentelemetry/propagator-b3';
import { resourceFromAttributes } from '@opentelemetry/resources';
import { MeterProvider, PeriodicExportingMetricReader } from '@opentelemetry/sdk-metrics';
import { SimpleSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals';
import { ENVIRONMENT, FrontendEnvironments } from './constants';
export const reportWebVitals = () => {
    const meter = metrics.getMeter('web-vitals');
    const lcp = meter.createObservableGauge('lcp');
    const cls = meter.createObservableGauge('cls');
    const fid = meter.createObservableGauge('fid');
    const ttfb = meter.createObservableGauge('ttfb');
    const fcp = meter.createObservableGauge('fcp');
    function sendToAnalytics(metric) {
        switch (metric.name) {
            case 'LCP': {
                lcp.addCallback((result) => {
                    result.observe(metric.value);
                });
                break;
            }
            case 'CLS': {
                cls.addCallback((result) => {
                    result.observe(metric.value);
                });
                break;
            }
            case 'FID': {
                fid.addCallback((result) => {
                    result.observe(metric.value);
                });
                break;
            }
            case 'TTFB': {
                ttfb.addCallback((result) => {
                    result.observe(metric.value);
                });
                break;
            }
            case 'FCP': {
                fcp.addCallback((result) => {
                    result.observe(metric.value);
                });
                break;
            }
        }
    }
    onCLS(sendToAnalytics);
    onLCP(sendToAnalytics);
    onTTFB(sendToAnalytics);
    onFCP(sendToAnalytics);
    onINP(sendToAnalytics);
    onFID(sendToAnalytics);
};
export const initializeSignoz = ({ traceId }) => {
    const url = 'https://ingest.us.signoz.cloud:443';
    const exporter = new OTLPTraceExporter({
        url: `${url}/v1/traces`,
        headers: {
            'signoz-ingestion-key': process.env.REACT_APP_SIGN_OZ_INGESTION_KEY ?? ''
        }
    });
    // Define your resource, e.g., service name, environment.
    const resource = resourceFromAttributes({
        'service.name': ENVIRONMENT === FrontendEnvironments.Production
            ? 'console-prod'
            : ENVIRONMENT === FrontendEnvironments.Canary
                ? 'console-canary'
                : `console-dev`,
        environment: ENVIRONMENT
    });
    const provider = new WebTracerProvider({
        resource,
        spanProcessors: [new SimpleSpanProcessor(exporter)]
    });
    provider.register({
        contextManager: new ZoneContextManager(),
        propagator: new B3Propagator()
    });
    // Create a metric reader with OTLP exporter configured to send metrics to a local collector.
    const metricReader = new PeriodicExportingMetricReader({
        exporter: new OTLPMetricExporter({
            url: `${url}/v1/metrics`,
            headers: {
                'signoz-ingestion-key': process.env.REACT_APP_SIGN_OZ_INGESTION_KEY ?? ''
            }
        }),
        exportIntervalMillis: 10000 // Export metrics every 10 seconds.
    });
    // Initialize a MeterProvider with the above configurations.
    const myServiceMeterProvider = new MeterProvider({
        resource,
        readers: [metricReader]
    });
    registerInstrumentations({
        instrumentations: [
            getWebAutoInstrumentations({
                '@opentelemetry/instrumentation-xml-http-request': {
                    clearTimingResources: true,
                    applyCustomAttributesOnSpan: (span) => {
                        span.setAttribute('trace-id', traceId);
                    }
                },
                '@opentelemetry/instrumentation-fetch': {
                    applyCustomAttributesOnSpan: (span) => {
                        span.setAttribute('trace-id', traceId);
                    }
                },
                '@opentelemetry/instrumentation-document-load': {
                    applyCustomAttributesOnSpan: {
                        documentLoad: (span) => {
                            span.setAttribute('trace-id', traceId);
                        },
                        documentFetch: (span) => {
                            span.setAttribute('trace-id', traceId);
                        },
                        resourceFetch: (span) => {
                            span.setAttribute('trace-id', traceId);
                        }
                    }
                }
            })
        ]
    });
    // Set the initialized MeterProvider as global to enable metric collection across the app.
    opentelemetry.metrics.setGlobalMeterProvider(myServiceMeterProvider);
};
