import { gql } from '@apollo/client';
export const ADD_COMPANIES_TO_WATCHLIST = gql `
  mutation AddCompaniesToWatchlist($id: String!, $companies: [String]!) {
    addCompaniesToWatchlistWithIds(id: $id, companies: $companies) {
      id
      entityUrn
      companyIds
      name
      sharedWithTeam
      userWatchlistType
    }
  }
`;
