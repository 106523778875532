import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useApolloClient } from '@apollo/client';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { defaultColDef, defaultGridOptions, onCellValueChanged } from 'utils/grid';
import { HarmonicLoader } from './LoadingOverlay';
import { useDebouncedRowLoader } from 'components/Dashboard/Collections/Grid/useDebouncedRowLoader';
import * as CellRenderers from 'components/Dashboard/Grids/CellRenderers/CellRenderers';
import * as HeaderRenderers from 'components/Dashboard/Grids/HeaderRenderers';
import { AgGridWrapper } from 'components/Dashboard/Grids/styles';
import { HotkeyScope } from 'config/hotkeys';
import useDashboardLocation from 'hooks/useDashboardLocation';
import useEntityActionsAvailableByLocation from 'hooks/useEntityActionsAvailableByLocation';
import useFlags from 'hooks/useFlags';
import { useShallowTableStore } from 'stores/tableStore';
import 'styles/HoverActions.css';
import 'styles/ag-theme-harmonic.css';
import { SPLITS } from 'utils/constants';
import { useGridCellEditing } from '../../../hooks/useGridCellEditing';
import { useGridLoadingTime } from '../../../hooks/useGridLoadingTime';
import { useGridRowSelection } from '../../../hooks/useGridRowSelection';
import useHotkeys from '../../../hooks/useHotkeys';
import { useInlineActionHotkeys } from '../../../hooks/useInlineActionHotkeys';
import { usePerformanceLogging } from '../../../hooks/usePerformanceLogging';
import useRenderPerformance from '../../../hooks/useRenderPerformance';
import { AgGridReactWrapper } from '../AgGridReactWrapper';
import { SelectedItemsActionsBar } from './SelectedItemsActionsBar';
import { SelectedItemsActionsBarV2 } from './SelectedItemsActionsBarV2';
export const GRID_ROW_HEIGHT = 78;
const GridResultsView = React.forwardRef((props, forwardedRef) => {
    useRenderPerformance('GridResultsView');
    const { enabled: enableCustomFieldBulkActions } = useFlags(SPLITS.enableCustomFieldBulkActions);
    const client = useApolloClient();
    const { rows, gridViewOptions, totalCount } = props;
    const { columnDefs, onDragStopped, noRowsOverlayComponent } = gridViewOptions;
    const ref = React.useRef(null);
    React.useImperativeHandle(forwardedRef, () => ref.current);
    const dashboardLocation = useDashboardLocation();
    const { entityActionsAvailable } = useEntityActionsAvailableByLocation();
    const { selectedRowIds } = useShallowTableStore(['selectedRowIds']);
    const { handleRowSelected } = useGridRowSelection({
        ref,
        onAddSelectedRows: props.onAddSelectedRows,
        onRemoveSelectedRows: props.onRemoveSelectedRows,
        onClearSelectedRows: props.onClearSelectedRows,
        enabled: selectedRowIds.length > 0
    });
    const { handleCellEditingStarted, handlCellEditingStopped, refocusGrid } = useGridCellEditing({ ref, context: props.context });
    // Grid loading time display
    const { updateLastGridLoadingTime } = useGridLoadingTime({ rows });
    // Performance logging
    const { logGridReady } = usePerformanceLogging();
    const [focusedEntityId, setFocusedEntityId] = useState(null);
    useInlineActionHotkeys({
        companyIds: selectedRowIds.length > 0
            ? selectedRowIds
            : focusedEntityId
                ? [focusedEntityId]
                : [],
        onDelete: refocusGrid
    });
    const focusOnFirstCell = () => {
        if (!ref?.current?.api?.getFocusedCell()) {
            ref?.current?.api?.setFocusedCell(0, ref?.current?.api?.getAllDisplayedColumns()[0]?.getColId());
        }
    };
    useHotkeys({
        keys: ['up'],
        options: {
            scopes: [HotkeyScope.GRID],
            description: 'Move up'
        },
        callback: focusOnFirstCell
    });
    useHotkeys({
        keys: ['down'],
        options: {
            scopes: [HotkeyScope.GRID],
            description: 'Move down'
        },
        callback: focusOnFirstCell
    });
    useHotkeys({
        keys: ['left'],
        options: {
            scopes: [HotkeyScope.GRID],
            description: 'Move left'
        },
        callback: focusOnFirstCell
    });
    useHotkeys({
        keys: ['right'],
        options: {
            scopes: [HotkeyScope.GRID],
            description: 'Move right'
        },
        callback: focusOnFirstCell
    });
    const gridOptions = React.useMemo(() => {
        return {
            ...defaultGridOptions,
            suppressCellFocus: false,
            rowHeight: GRID_ROW_HEIGHT,
            loadingOverlayComponent: HarmonicLoader,
            noRowsOverlayComponent
        };
    }, [noRowsOverlayComponent]);
    const modifiedDefaultColDef = React.useMemo(() => {
        return {
            ...defaultColDef,
            headerComponent: props.headerRenderer
        };
    }, [props.headerRenderer]);
    const formattedColumnDefs = React.useMemo(() => {
        return columnDefs.map((col) => ({
            ...col,
            lockPinned: !col.pinned
        }));
    }, [columnDefs]);
    const components = React.useMemo(() => {
        return {
            ...CellRenderers,
            ...HeaderRenderers
        };
    }, []);
    const handleCellValueChanged = async (event) => {
        onCellValueChanged(client, event);
    };
    // Handle row data updates
    const handleRowDataUpdated = useCallback(() => {
        updateLastGridLoadingTime();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleGridReady = useCallback(() => {
        logGridReady();
    }, [logGridReady]);
    const handleCellFocused = useCallback(({ api }) => {
        const focusedCell = api.getFocusedCell();
        if (focusedCell) {
            setFocusedEntityId(api.getDisplayedRowAtIndex(focusedCell.rowIndex)?.data?.company?.id ??
                null);
        }
    }, []);
    const { finalRows, onScroll } = useDebouncedRowLoader(rows, gridViewOptions.fetchMore);
    return (_jsxs(_Fragment, { children: [_jsx(AgGridWrapper, { className: "ag-theme-alpine content-grid", children: _jsx(AgGridReactWrapper, { context: props.context, ref: ref, animateRows: true, columnDefs: formattedColumnDefs, getRowId: props.getRowId, defaultColDef: modifiedDefaultColDef, components: components, gridOptions: gridOptions, rowData: finalRows, onGridReady: handleGridReady, suppressScrollWhenPopupsAreOpen: true, onBodyScroll: onScroll, onRowSelected: handleRowSelected, onCellFocused: handleCellFocused, onCellEditingStarted: handleCellEditingStarted, onCellEditingStopped: handlCellEditingStopped, onCellValueChanged: handleCellValueChanged, onDragStopped: onDragStopped, onRowDataUpdated: handleRowDataUpdated }) }), enableCustomFieldBulkActions ? (_jsx(SelectedItemsActionsBarV2, { actionsToShow: entityActionsAvailable, dashboardLocation: dashboardLocation, totalCount: totalCount })) : (_jsx(SelectedItemsActionsBar, { actionsToShow: entityActionsAvailable, dashboardLocation: dashboardLocation, totalCount: totalCount }))] }));
});
export default GridResultsView;
