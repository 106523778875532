import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// eslint-disable-next-line no-restricted-imports
import { useQuery } from '@apollo/client';
import { getIdFromUrn } from 'common/utils/urn';
import { InvestorPill } from 'components/common/Investors/InvestorPill';
import { getFundingTypeDisplayName } from 'interfaces/SearchModel/Search';
import { getCompanyFinancing } from 'queries/getCompanyFinancingV2';
import React, { useMemo } from 'react';
import DiagramWrapper from './common/DiagramWrapper';
export const InvestmentHistoryCard = ({ companyUrn }) => {
    const { data: financingData } = useQuery(getCompanyFinancing, {
        variables: {
            id: Number(getIdFromUrn(companyUrn))
        }
    });
    const fundingRounds = useMemo(() => financingData?.getCompanyById?.funding?.fundingRounds?.filter((round) => round !== null) || [], [financingData?.getCompanyById?.funding?.fundingRounds]);
    // Create investor map to store all investor data
    const investorMap = useMemo(() => {
        const map = {};
        // First map all investors from the company's investor list
        financingData?.getCompanyById?.funding?.investors?.forEach((i) => {
            if (!i?.name)
                return;
            const entityUrn = i.__typename === 'Company' ? i.companyUrn : i.personUrn;
            if (!entityUrn)
                return;
            const investorKey = `${entityUrn}_${i.name}`;
            map[investorKey] = {
                name: i.name,
                logoUrl: i.logoUrl || undefined,
                entityUrn
            };
        });
        // Then map all investors from funding rounds
        fundingRounds.forEach((fr) => {
            if (!fr?.investors)
                return;
            fr.investors.forEach((i) => {
                if (!i?.investor || !i.entityUrn || !i.investorName)
                    return;
                const investorKey = `${i.entityUrn}_${i.investorName}`;
                if (!map[investorKey]) {
                    map[investorKey] = {
                        name: i.investorName,
                        entityUrn: i.entityUrn
                    };
                }
                map[investorKey] = {
                    ...map[investorKey],
                    investorUrn: i.investorUrn
                };
            });
        });
        return map;
    }, [financingData?.getCompanyById?.funding?.investors, fundingRounds]);
    return (_jsx(DiagramWrapper, { title: "Investment history", children: _jsx("div", { className: "relative mr-auto", children: fundingRounds.map((round, index) => {
                if (!round)
                    return null;
                const isLast = index === fundingRounds.length - 1;
                return (_jsxs("div", { className: "relative pl-p60", children: [!isLast && (_jsx("div", { className: "absolute left-[26px] w-[0.5px] bg-gray-200", style: {
                                top: '24px',
                                bottom: '-16px'
                            } })), _jsxs("div", { className: "relative pl-6 mb-6", children: [_jsx("div", { className: "absolute left-[8.5px] top-2 w-1 h-1 bg-gray-400 rounded-full ring-4 ring-white" }), _jsxs("div", { className: "pb-1", children: [_jsxs("div", { className: "flex items-center gap-2 typography-label-default-default", children: [_jsxs("span", { className: "font-medium", children: [getFundingTypeDisplayName(round.fundingRoundType), " \u00B7 $", (round.fundingAmount / 1000000).toFixed(0), "M"] }), _jsxs("span", { className: "text-gray-500", children: ["(", new Date(round.announcementDate).toLocaleDateString('en-US', {
                                                            month: 'long',
                                                            year: 'numeric'
                                                        }), ")"] })] }), _jsx("div", { className: "mt-2 flex flex-wrap gap-2", children: round.investors
                                                ?.filter((investor) => investor !== null)
                                                .sort((a, b) => (a?.isLead ? -1 : b?.isLead ? 1 : 0))
                                                .map((investor, idx) => {
                                                if (!investor)
                                                    return null;
                                                const investorKey = `${investor.entityUrn || ''}_${investor.investorName || ''}`;
                                                const investorData = investorMap[investorKey];
                                                return (_jsx(InvestorPill, { investorName: investor.investor?.name ||
                                                        investor.investorName ||
                                                        '', investorUrn: investor.investorUrn || undefined, investorLogoUrl: investorData?.logoUrl, isLead: investor.isLead || false, small: true }, idx));
                                            }) })] })] })] }, index));
            }) }) }));
};
