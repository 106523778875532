import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SearchType } from '__generated__/graphql';
import { Badge, Tooltip } from 'common/components';
import useNetNewCounts from 'components/Dashboard/Collections/Searches/NetNew/useNetNewCounts';
import { useStore as useMobxStore } from 'config/StoreProvider';
import Avatar from 'harmonic-components/Avatar/Avatar';
import useDashboardLocation, { DashboardLocation } from 'hooks/useDashboardLocation';
import { compact, get } from 'lodash';
import * as React from 'react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useShallowTableStore } from 'stores/tableStore';
import useStore from 'stores/zustandStore';
import analytics, { CustomTrackEvent, EventLocations } from 'utils/analytics';
import { CheckboxSubRenderer } from './Checkbox';
import UnviewedNewDot from './UnviewedNewDot';
export const PersonNameWithCheckboxCellRenderer = (props) => {
    const pageTitle = useStore((state) => state.pageTitle);
    const editStoreData = useStore((state) => state.editStoreData);
    const personId = get(props, 'data.person.id');
    const personUpdates = compact(get(props, 'data.person.updates') || []);
    const personName = get(props, 'data.person.fullName');
    const { urn, location } = useDashboardLocation();
    const { rootStore } = useMobxStore();
    const { addSelectedRowIds, selectedRowIds, selectAllStateEnabled, removeSelectedRowIds } = useShallowTableStore([
        'addSelectedRowIds',
        'selectedRowIds',
        'selectAllStateEnabled',
        'removeSelectedRowIds'
    ]);
    const isCheckboxChecked = selectedRowIds.includes(personId) || selectAllStateEnabled;
    const isSearchPage = location == DashboardLocation.PEOPLE_SEARCH;
    const isExplorePage = isSearchPage && (urn === '' || !urn);
    // net new stuff
    const { hasNetNew, onClearNetNewByIds } = useNetNewCounts();
    const countExists = hasNetNew(urn);
    const netNewId = get(props.data, 'netNewId');
    const isNetNew = useMemo(() => {
        return !!(countExists && netNewId);
    }, [countExists, netNewId]);
    const showPersonUpdateChip = location === DashboardLocation.PERSON_LIST && personUpdates.length > 0;
    const jobUpdatedChip = (_jsx(Badge, { color: "informative", label: "Profile recently updated", size: "large" }));
    const tooltipText = (_jsx(_Fragment, { children: personUpdates.map((update, index) => (_jsxs(_Fragment, { children: [_jsx("span", { className: "typography-label-small-default", children: update.helperText ?? '' }, index), _jsx("br", {})] }))) }));
    const onCheckboxClick = (event) => {
        props.node.setSelected(event.target.checked);
    };
    const logoUrl = get(props, 'data.person.profile_picture_url');
    const logoUrlGql = get(props, 'data.person.profilePictureUrl');
    const profilePictureUrl = logoUrl || logoUrlGql;
    return (_jsx("div", { children: _jsxs("div", { className: "flex justify-start items-start gap-g50 w-full", children: [_jsx(CheckboxSubRenderer, { isChecked: isCheckboxChecked, onCheckboxClick: onCheckboxClick }), _jsxs("div", { className: "flex flex-col gap-g30 w-full", children: [_jsxs("div", { className: "flex flex-row justify-between w-full items-center", children: [personId ? (_jsx(Link, { onClick: () => {
                                        editStoreData('previousPageTitle', isExplorePage ? 'Explore' : pageTitle);
                                        if (isNetNew && urn && netNewId)
                                            onClearNetNewByIds({
                                                ids: [netNewId],
                                                savedSearchUrn: urn,
                                                type: SearchType.PERSONS
                                            });
                                        analytics.trackCustomEvent({
                                            event: CustomTrackEvent.PERSON_PROFILE_CLICK,
                                            properties: {
                                                personId,
                                                openedFromLocation: EventLocations.GRID_TABLE
                                            }
                                        });
                                    }, to: `/dashboard/person/${personId}`, children: _jsxs("div", { className: "flex items-start", children: [_jsx(Avatar, { kind: "face", size: "medium", src: profilePictureUrl, alt: "person-profile-picture" }), _jsx("span", { className: "typography-label text-content-strong line-clamp-1 pl-p30", children: personName })] }) })) : (_jsx("span", { className: "typography-label", children: personName })), _jsx(UnviewedNewDot, { netNewId: isNetNew ? netNewId : undefined })] }), _jsx("div", { children: showPersonUpdateChip && (_jsx(Tooltip, { body: tooltipText, alignment: "right", children: _jsx("div", { className: "inline-block", children: jobUpdatedChip }) })) })] })] }) }));
};
