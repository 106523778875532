import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CalendarDatePickerIcon, CrossIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { isNil } from 'lodash';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { InputDateFormat } from './SingleDatePicker';
const DatePickerInput = forwardRef(({ value, onChange, autoFocus, inputType = 'date', inputPlaceholder, isClearable = true, dateFormat = InputDateFormat['YYYY-MM-DD'], inverted, disabled, ...props }, ref) => {
    const [localValue, setLocalValue] = useState(value);
    const [isFocused, setIsFocused] = useState(Boolean(props.initialFocus));
    const paddingX = `calc(var(--spacing-p40) + 16px + var(--gap-g10))`;
    const inputRef = useRef(null);
    const handleOnClick = () => {
        //eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        handleOnChange({
            target: {
                value: ''
            }
        });
    };
    const handleOnChange = (e) => {
        const newDateVal = e.target.value;
        setLocalValue(newDateVal);
        // Here, when input type is text, onChange function is immediately called for a single character input.
        // Date is not valid at that point, it causes issues like wrong date being set and edit mode turning off
        // We are waiting for the date to be valid and then calling onChange function of parent which calls further network calls
        if (inputType !== 'text' ||
            dayjs(newDateVal, dateFormat).isValid() ||
            isNil(newDateVal) ||
            newDateVal === '') {
            onChange(e);
        }
    };
    useEffect(() => {
        if (value !== localValue) {
            setLocalValue(value);
        }
        // Lint expects localValue to be part of dependency array as well.
        // Here, we are doing one way sync from parent to child. In cases where parent value changes, child localValue is updated.
        // localValue is always going to be different from parent value. It will cause unnecessary calls if we add it to dependency array.
        // react-hooks/exhaustive-deps
        // eslint-disable-next-line
    }, [value]);
    useEffect(() => {
        if (autoFocus)
            inputRef?.current?.focus();
    }, [autoFocus]);
    return (_jsxs("div", { ref: ref, ...props, className: classNames(props.className, 'relative'), children: [_jsx("div", { className: "bg-surface-default", children: _jsxs("div", { "data-open": isFocused, style: {
                        minHeight: props.minHeight,
                        paddingLeft: paddingX,
                        paddingRight: paddingX
                    }, className: classNames('py-p40 relative border border-solid typography-label w-full', 'bg-int-overlay-secondary-enabled placeholder-content-muted', 'text-content-default rounded-br20', inverted &&
                        'bg-surface-inverted-raised placeholder-content-inverted-muted text-content-inverted-strong', !props.borderOverrideClasses &&
                        classNames('border-int-outline-secondary-enabled', 
                        // Hover state
                        'hover:border-int-outline-secondary-hover hover:bg-int-overlay-secondary-hover', 
                        // Active state
                        'active:border-int-outline-secondary-pressed', 
                        // Focus state
                        'focus-visible:outline-none focus-visible:border-int-outline-secondary-selected-enabled', 'data-[open=true]:outline-none data-[open=true]:border-int-outline-secondary-selected-enabled', 
                        // Disabled state
                        'disabled:border-int-outline-secondary-disabled'), 
                    // Hover state
                    'hover:text-input-value-hover', 
                    // Active state
                    !inverted &&
                        'active:border-int-outline-secondary-pressed active:bg-int-overlay-secondary-pressed active:text-input-value-pressed', 
                    // Focus state
                    'focus-visible:bg-int-overlay-secondary-enabled', 'data-[open=true]:bg-int-overlay-secondary-enabled', 
                    // Disabled state
                    'disabled:bg-int-overlay-secondary-disabled disabled:text-input-value-disabled', props.borderOverrideClasses), children: [_jsx("div", { className: classNames('absolute top-0 left-0 bottom-0 pl-p40', {
                                'flex items-center': !props.minHeight,
                                'pt-p40 mt-p10': props.minHeight
                            }), children: _jsx(CalendarDatePickerIcon, { className: "text-content-weak w-4 h-4" }) }), _jsx("input", { onFocus: () => setIsFocused(true), onBlur: () => setIsFocused(false), type: inputType, ref: inputRef, placeholder: inputPlaceholder, value: localValue, onChange: handleOnChange, autoFocus: autoFocus, className: "bg-int-overlay-secondary-enabled custom-text-input", "data-testid": "DatePickerInput", disabled: disabled })] }) }), isClearable ? (_jsx("div", { className: "absolute right-0 top-[10px] pr-p40 ", children: _jsx(IconButton, { onClick: handleOnClick, icon: CrossIcon, type: "secondary", emphasis: "low", size: "tiny" }) })) : null] }));
});
export default DatePickerInput;
