import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from 'harmonic-components/Button/Button';
import ListItem, { ListType, ListVariant } from 'harmonic-components/ListItem/ListItem';
import SearchInput from 'harmonic-components/SearchInput/SearchInput';
import { useMemo, useState } from 'react';
const EditCustomFieldsMultiSelectValue = ({ customField, onSave, onCancel, recalculatePopoverPosition }) => {
    const [filterTerm, setFilterTerm] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleOnFilterTermChange = (value) => {
        setFilterTerm(value);
        recalculatePopoverPosition();
    };
    const options = useMemo(() => {
        return customField?.metadata?.__typename === 'SelectListCustomFieldMetadata'
            ? customField.metadata.options
            : [];
    }, [customField]);
    const filteredOptions = useMemo(() => {
        return options.filter((option) => option.name.toLowerCase().includes(filterTerm.toLowerCase()));
    }, [options, filterTerm]);
    const handleOnChange = async (value) => {
        if (selectedOptions.includes(value)) {
            setSelectedOptions((prev) => prev.filter((option) => option !== value));
        }
        else {
            setSelectedOptions((prev) => [...prev, value]);
        }
    };
    const handleOnCancel = () => {
        setSelectedOptions([]);
        onCancel();
    };
    const handleOnSave = async () => {
        setLoading(true);
        await onSave(selectedOptions);
        setSelectedOptions([]);
        setLoading(false);
    };
    return (_jsxs("div", { className: "w-full flex flex-col max-h-96", children: [_jsx(SearchInput, { fullWidth: true, placeholder: "Search", value: filterTerm, onChange: handleOnFilterTermChange }), _jsx("div", { className: "mt-p30 flex-1 overflow-y-auto min-h-0", children: filteredOptions.map((option) => (_jsx("div", { className: "w-full", children: _jsx(ListItem, { type: ListType.default, variant: ListVariant.tagCheckbox, label: option.name, value: option.name, onChange: () => handleOnChange(option.urn), 
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        color: option.color, selected: selectedOptions.includes(option.urn), disabled: loading }, option.urn) }, option.urn))) }), _jsxs("div", { className: "flex gap-g40 mt-p50", children: [_jsx(Button, { fullWidth: true, type: "secondary", emphasis: "medium", size: "compact", label: "Cancel", onClick: handleOnCancel, isDisabled: loading }), _jsx(Button, { fullWidth: true, type: "primary", emphasis: "high", size: "compact", label: "Save", onClick: handleOnSave, loading: loading, isDisabled: loading, dataTestId: "EditCustomFieldsMultiSelectValue-SaveButton" })] })] }));
};
export default EditCustomFieldsMultiSelectValue;
