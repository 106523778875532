import { gql } from '@apollo/client';
import { SIMPLE_COMPANY_WATCHLIST_ENTRY_NODE_FRAGMENT } from './getWatchlistWithCompanies';
const upsertCompanyWatchlistCustomFieldValuesMutation = gql `
  mutation UpsertCompanyWatchlistCustomFieldValues(
    $customFieldUrn: CompanyListCustomFieldUrn!
    $companyWatchlistUrn: CompanyWatchlistUrn!
    $companyWatchlistEntryUrns: [CompanyWatchlistEntryUrn!]!
    $customFieldValueInput: CompanyListCustomFieldValueUpsertInput!
    $asyncEnabled: Boolean = false
  ) {
    upsertCompanyWatchlistCustomFieldValues(
      customFieldUrn: $customFieldUrn
      companyWatchlistUrn: $companyWatchlistUrn
      companyWatchlistEntryUrns: $companyWatchlistEntryUrns
      customFieldValueInput: $customFieldValueInput
      asyncEnabled: $asyncEnabled
    ) {
      ...SimpleCompanyListEntryNode
    }
  }
  ${SIMPLE_COMPANY_WATCHLIST_ENTRY_NODE_FRAGMENT}
`;
export default upsertCompanyWatchlistCustomFieldValuesMutation;
