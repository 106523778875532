import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
const DiligenceReportButton = ({ onGenerateDiligenceReport, isLoading, isGeneratingReport, isSelected, onMouseEnter, onMouseLeave }) => {
    const [handleGenerateDiligenceReport, handleMouseEnter, handleMouseLeave] = [
        onGenerateDiligenceReport,
        onMouseEnter,
        onMouseLeave
    ];
    return (_jsx("div", { className: "flex px-p20 pt-p20 mt-p20 border-t border-border border-solid", children: _jsxs("button", { onClick: handleGenerateDiligenceReport, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, disabled: isLoading || isGeneratingReport, className: classNames('flex items-center px-p40 py-p30 gap-2 rounded-md disabled:opacity-50 disabled:bg-white transition-colors w-full', isSelected
                ? 'bg-int-overlay-secondary-hover'
                : 'hover:bg-int-overlay-secondary-hover'), children: [_jsx("span", { className: classNames('icon material-icons-outlined text-sm font-medium', !isGeneratingReport ? 'agent-gradient-text' : 'text-gray-500'), children: isGeneratingReport ? 'published_with_changes' : 'insights' }), _jsx("span", { className: classNames(!isGeneratingReport ? 'agent-gradient-text' : 'text-gray-500'), children: isGeneratingReport
                        ? 'Diligence report requested'
                        : 'Generate diligence report' }), !!isSelected && !isGeneratingReport && (_jsx("span", { className: "icon material-icons-outlined text-sm font-medium text-gray-500 ml-auto", children: "keyboard_return" }))] }) }));
};
export default DiligenceReportButton;
