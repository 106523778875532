import { logger } from 'utils/logger';
import { displayToast } from 'utils/toasts';
import { RESEARCH_AGENT_URL } from './constants';
export const initializeResearchAgent = async (domain, email, apiKey, requestedThrough) => {
  const url = `${RESEARCH_AGENT_URL}?domain=${domain}&email=${email}&apiKey=${apiKey}&requested_through=${requestedThrough}`;
  try {
    const response = await fetch(url);
    displayToast({
      primaryText: `The report for ${domain} should land in your inbox in the next 10-15 minutes`,
      mode: 'success'
    });
    return response.json();
  } catch (error) {
    logger.error(error);
    displayToast({
      primaryText: 'Something went wrong. Please try again later.',
      mode: 'error'
    });
  }
};