import { jsx as _jsx } from "react/jsx-runtime";
export const FormatTextInAsterisks = props => {
  const {
    text,
    asteriskClassName,
    textClassName
  } = props;
  // Regex to match text wrapped in asterisks
  const boldRegex = /\*(.*?)\*/g;
  // Replace matches with <strong> tags
  const processedText = text.split(boldRegex).map((part, index) => {
    if (index % 2 === 1) {
      // Odd index means it's between asterisks
      return _jsx("span", {
        className: asteriskClassName,
        children: part
      });
    }
    return _jsx("span", {
      className: textClassName,
      children: part
    }); // Non-matching text
  });
  return _jsx("span", {
    children: processedText
  });
};