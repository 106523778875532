import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WarningIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
const TextInput = ({ errorMessage, leadingIcon, label, prefix, placeholder, trailingIcon, dataTestId, suffix, helperText, isDisabled, value, autoFocus, onChange, inverted, onBlur, onKeyDown, onClick, type, inputRef, maxLength, borderOverrideClasses, paddingOverrideClasses }) => {
    //Prefix and suffix width depends on length of text. It's dynamically calculated
    const [prefixWidth, setPrefixWidth] = useState(0);
    const prefixRef = useRef(null);
    const [suffixWidth, setSuffixWidth] = useState(0);
    const suffixRef = useRef(null);
    useEffect(() => {
        if (prefixRef.current) {
            const prefixWidth = prefixRef.current.offsetWidth;
            setPrefixWidth(prefixWidth);
        }
        else {
            setPrefixWidth(0);
        }
    }, [prefix, prefixRef?.current?.offsetWidth, leadingIcon]);
    useEffect(() => {
        if (suffixRef.current) {
            const suffixWidth = suffixRef.current.offsetWidth;
            setSuffixWidth(suffixWidth);
        }
    }, [suffix, suffixRef?.current?.offsetWidth, trailingIcon, errorMessage]);
    const LeadingIcon = leadingIcon;
    const TrailingIcon = trailingIcon;
    return (_jsxs("div", { "data-testid": dataTestId, className: classNames('flex flex-col gap-g20 w-full', !inverted && 'bg-surface-default'), children: [label && _jsx("p", { className: "text-content-weak typography-label", children: label }), _jsxs("div", { className: "relative", children: [_jsx("input", { "data-testid": `${dataTestId}-input`, style: {
                            paddingLeft: prefixWidth > 0
                                ? `calc(${prefixWidth}px + var(--gap-g10))`
                                : 'var(--spacing-p50)',
                            paddingRight: suffixWidth > 0
                                ? `calc(${suffixWidth}px + var(--gap-g10))`
                                : 'var(--spacing-p50)'
                        }, onClick: onClick, ref: inputRef, disabled: isDisabled, placeholder: placeholder, value: value, onChange: onChange, onBlur: onBlur, onKeyDown: onKeyDown, type: type, className: classNames(paddingOverrideClasses || 'py-p40', 'border border-solid typography-label w-full', 'bg-int-overlay-secondary-enabled placeholder-content-muted', 'text-content-default rounded-br20 custom-text-input', 
                        // Inverted
                        inverted &&
                            'bg-surface-inverted-raised placeholder-content-inverted-muted text-content-inverted-strong', 
                        //disabled state
                        isDisabled &&
                            'bg-int-overlay-secondary-disabled border-int-outline-secondary-disabled placeholder-content-muted', 
                        //error state
                        errorMessage &&
                            !isDisabled &&
                            !borderOverrideClasses &&
                            'border-int-fill-negative-enabled border-[1.5px]', 
                        //default state
                        !errorMessage &&
                            !isDisabled &&
                            !inverted &&
                            classNames(
                            // Hover state
                            'hover:bg-int-overlay-secondary-hover hover:text-input-value-hover', 
                            // Active state
                            'active:bg-int-overlay-secondary-pressed active:text-input-value-pressed', 
                            // Focus state
                            'focus-visible:bg-int-overlay-secondary-enabled', 'data-[open=true]:bg-int-overlay-secondary-enabled'), !errorMessage &&
                            !isDisabled &&
                            !borderOverrideClasses &&
                            !inverted &&
                            classNames(
                            // Default state
                            'border-int-outline-secondary-enabled', 
                            // Hover state
                            'hover:border-int-outline-secondary-hover', 
                            // Active state
                            'active:border-int-outline-secondary-pressed', 
                            // Focus state
                            'focus-visible:outline-none focus-visible:border-int-outline-secondary-selected-enabled', 'data-[open=true]:outline-none data-[open=true]:border-int-outline-secondary-selected-enabled'), borderOverrideClasses), maxLength: maxLength, autoFocus: autoFocus }), (LeadingIcon || prefix) && (_jsxs("div", { ref: prefixRef, className: "absolute top-0 bottom-0 left-0 pl-p50 flex items-center gap-g40 pointer-events-none", children: [LeadingIcon && (_jsx("span", { children: _jsx(LeadingIcon, { className: "text-content-weak w-4 h-4" }) })), prefix && (_jsx("span", { className: "text-content-weak typography-label", children: prefix }))] })), (TrailingIcon || suffix || errorMessage) && (_jsxs("div", { ref: suffixRef, className: classNames('absolute top-0 bottom-0 right-0 pr-p50 flex items-center gap-g40', { 'pointer-events-none': typeof suffix === 'string' }), children: [suffix && (_jsx("span", { className: "text-content-weak typography-label", children: suffix })), TrailingIcon && (_jsx(TrailingIcon, { className: "fill-current text-content-weak w-4 h-4" })), errorMessage && (_jsx(WarningIcon, { className: " text-content-sentiment-negative w-4 h-4" }))] }))] }), errorMessage && (_jsx("p", { className: "typography-label text-int-fill-negative-enabled", children: errorMessage })), helperText && (_jsx("p", { className: "typography-label text-content-weak", children: helperText }))] }));
};
export default TextInput;
