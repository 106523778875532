import { jsx as _jsx } from "react/jsx-runtime";
import FilterIcon from 'assets/harmonic-icons/filter';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import useDashboardLocation, { DashboardLocation } from 'hooks/useDashboardLocation';
import React from 'react';
import useFilterStore from 'stores/filterStore';
const FilterByFieldDropdown = ({ searchField, onClose }) => {
    const { location } = useDashboardLocation();
    const editFilterStore = useFilterStore((state) => state.editFilterStore);
    const onFilterByFieldDropdownClick = () => {
        if (!searchField)
            return;
        // It triggers the useEffect in ListFilter component
        // That component handles the rest
        editFilterStore('searchFieldForListFilter', searchField);
        onClose();
    };
    if (!searchField)
        return null;
    if (location !== DashboardLocation.COMPANY_LIST &&
        location !== DashboardLocation.PERSON_LIST)
        return null;
    return (_jsx(ListItem, { label: "Filter by this field", value: "Filter by this field", primaryIcon: FilterIcon, variant: ListVariant.default, onClick: onFilterByFieldDropdownClick }));
};
export default FilterByFieldDropdown;
