import { useApolloClient } from '@apollo/client';
import removePeopleWatchlistCustomFieldMutation from 'queries/removePeopleWatchlistCustomField';
import { GET_PEOPLE_WATCHLIST } from '../queries/getPeopleWatchlist';
import { GET_PEOPLE_WATCHLIST_FRAGMENT } from '../queries/getPeopleWatchlists';
import useGetPeopleListCustomField from './useGetPeopleListCustomField';
import useRemoveWatchlistCustomField from './useRemoveWatchlistCustomField';
const useRemovePeopleListCustomField = () => {
    const client = useApolloClient();
    const { getCustomFieldFragmentIdFromUrn } = useGetPeopleListCustomField();
    return useRemoveWatchlistCustomField({
        typename: 'PeopleWatchlist',
        mutation: removePeopleWatchlistCustomFieldMutation,
        getWatchlistQuery: GET_PEOPLE_WATCHLIST,
        fragmentDoc: GET_PEOPLE_WATCHLIST_FRAGMENT,
        fragmentName: 'PeopleWatchlist',
        getCustomFieldFragmentIdFromUrn
    });
};
export default useRemovePeopleListCustomField;
