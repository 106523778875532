import { ListCustomFieldType } from '__generated__/graphql';
import { FilterArrayComparator } from '../../../interfaces/SearchModel/Search';
import { GRID_INITIAL_PAGE_SIZE } from '../../../utils/constants';
import { NOT_APPLICABLE_FIELD_OPTION } from './BoardView';
export const getFieldSearchQuery = ({ watchlistId, fieldUrn, fieldValueUrn, fieldType }) => {
    if (!watchlistId)
        return;
    const isCompanyCustomField = Boolean(fieldUrn?.includes('company_list_custom_field'));
    let fieldName = fieldType === ListCustomFieldType.PERSON
        ? 'company_custom_field_person'
        : 'company_custom_field_single_select';
    if (!isCompanyCustomField) {
        fieldName =
            fieldType === ListCustomFieldType.PERSON
                ? 'person_custom_field_person'
                : 'person_custom_field_single_select';
    }
    const fieldOptionSearchQuery = {
        filter_group: {
            join_operator: 'and',
            filters: [
                {
                    field: fieldName,
                    field_urn: fieldUrn,
                    comparator: FilterArrayComparator.ANY_OF_ARRAY,
                    filter_value: [fieldValueUrn]
                }
            ],
            filter_groups: []
        },
        pagination: { start: 0, page_size: GRID_INITIAL_PAGE_SIZE }
    };
    const notApplicableFieldSearchQuery = {
        filter_group: {
            join_operator: 'and',
            filters: [
                {
                    field: fieldName,
                    field_urn: fieldUrn,
                    comparator: FilterArrayComparator.IS_EMPTY,
                    filter_value: null
                }
            ],
            filter_groups: []
        },
        pagination: { start: 0, page_size: GRID_INITIAL_PAGE_SIZE }
    };
    return fieldValueUrn === NOT_APPLICABLE_FIELD_OPTION.urn
        ? notApplicableFieldSearchQuery
        : fieldOptionSearchQuery;
};
export const getCustomFieldValue = (urn) => {
    if (urn === NOT_APPLICABLE_FIELD_OPTION.urn) {
        return null;
    }
    return urn;
};
