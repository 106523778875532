import { jsx as _jsx } from "react/jsx-runtime";
import { CellApiModule, CellStyleModule, CheckboxEditorModule, ClientSideRowModelApiModule, ClientSideRowModelModule, ColumnApiModule, ColumnAutoSizeModule, ColumnHoverModule, CustomEditorModule, CustomFilterModule, DateEditorModule, DateFilterModule, DragAndDropModule, EventApiModule, ExternalFilterModule, GridStateModule, HighlightChangesModule, LargeTextEditorModule, LocaleModule, ModuleRegistry, NumberEditorModule, NumberFilterModule, PaginationModule, PinnedRowModule, QuickFilterModule, RenderApiModule, RowApiModule, RowAutoHeightModule, RowDragModule, RowSelectionModule, RowStyleModule, ScrollApiModule, SelectEditorModule, TextEditorModule, TextFilterModule, TooltipModule, UndoRedoEditModule, ValueCacheModule, provideGlobalGridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { forwardRef } from 'react';
// Register all community features
ModuleRegistry.registerModules([
    ColumnAutoSizeModule,
    ColumnHoverModule,
    PinnedRowModule,
    RowAutoHeightModule,
    RowStyleModule,
    PaginationModule,
    RowDragModule,
    CellStyleModule,
    HighlightChangesModule,
    TooltipModule,
    TextFilterModule,
    NumberFilterModule,
    DateFilterModule,
    CustomFilterModule,
    ExternalFilterModule,
    QuickFilterModule,
    RowSelectionModule,
    TextEditorModule,
    LargeTextEditorModule,
    SelectEditorModule,
    NumberEditorModule,
    DateEditorModule,
    CheckboxEditorModule,
    CustomEditorModule,
    UndoRedoEditModule,
    LocaleModule,
    DragAndDropModule,
    ValueCacheModule,
    GridStateModule,
    ColumnApiModule,
    RowApiModule,
    CellApiModule,
    ScrollApiModule,
    RenderApiModule,
    EventApiModule,
    ClientSideRowModelApiModule,
    ClientSideRowModelModule
]);
// Mark all grids as using legacy themes
provideGlobalGridOptions({ theme: 'legacy' });
export const AgGridReactWrapper = forwardRef((props, ref) => {
    return _jsx(AgGridReact, { ...props, ref: ref });
});
