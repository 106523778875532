import { useApolloClient } from '@apollo/client';
import { EntityListTypeToDashboardPath } from 'interfaces/SearchModel/Search';
import { useNavigate } from 'react-router-dom';
import useStore from 'stores/zustandStore';
import { SearchVersion } from '../utils/analytics';
import { ADD_SAVED_SEARCH_CONFLICT_ERROR_MESSAGE, ADD_SAVED_SEARCH_ERROR_MESSAGE } from '../utils/constants';
import { logger } from '../utils/logger';
import { createSavedSearch } from '../utils/midtierApi';
import { displayToast } from '../utils/toasts';
export const useCreateSavedSearchList = () => {
    const client = useApolloClient();
    const navigate = useNavigate();
    const editStoreData = useStore((state) => state.editStoreData);
    const createSavedSearchList = async (name, type, query, disableNavigation = false) => {
        const payload = {
            name,
            is_private: true,
            type,
            keywords: '',
            query
        };
        try {
            const response = await createSavedSearch(client, payload, SearchVersion.V2);
            editStoreData('pageTitle', name);
            displayToast({
                primaryText: 'Successfully created new saved search',
                mode: 'success'
            });
            if (!disableNavigation) {
                const toNavigateRoute = `/dashboard/${EntityListTypeToDashboardPath[type]}/${response?.entityUrn}`;
                navigate(toNavigateRoute);
            }
        }
        catch (error) {
            if (error == 409) {
                displayToast({
                    primaryText: ADD_SAVED_SEARCH_CONFLICT_ERROR_MESSAGE,
                    mode: 'error'
                });
            }
            else {
                displayToast({
                    primaryText: ADD_SAVED_SEARCH_ERROR_MESSAGE,
                    mode: 'error'
                });
            }
            logger.error('Create people or company saved search error', { error });
        }
    };
    return { createSavedSearchList };
};
