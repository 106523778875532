import client from 'config/client';
import { makeAutoObservable, runInAction } from 'mobx';
import { getListEntriesByPersonId } from 'queries/getListEntriesByPersonId';
import PeopleListEntryModel from './PeopleListEntryModel';
class PersonModel {
    data;
    listEntries = [];
    listEntriesLoading = false;
    constructor(data) {
        makeAutoObservable(this);
        this.data = data;
        this.fetchListEntries();
    }
    fetchListEntries() {
        if (!this.data?.id) {
            return;
        }
        client.query({
            query: getListEntriesByPersonId,
            variables: {
                id: this.data?.id
            }
        });
        this.observeListEntriesQuery();
    }
    observeListEntriesQuery() {
        const observableFragment = client.watchQuery({
            query: getListEntriesByPersonId,
            variables: {
                id: this.data?.id
            }
        });
        runInAction(() => {
            this.listEntriesLoading = true;
        });
        observableFragment.subscribe({
            next: (result) => {
                runInAction(() => {
                    this.listEntries =
                        result.data.getPersonById?.listEntries?.map((entry) => new PeopleListEntryModel(entry)) ?? [];
                    this.listEntriesLoading = false;
                });
            },
            error: () => {
                runInAction(() => {
                    this.listEntriesLoading = false;
                });
            }
        });
    }
}
export default PersonModel;
