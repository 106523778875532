import { jsx as _jsx } from "react/jsx-runtime";
import { debounce } from 'lodash';
import React, { useCallback } from 'react';
import Checkbox from '../../../../harmonic-components/Checkbox/Checkbox';
import { withPendingDot } from './components/WithPendingDot';
export const BaseCustomColumnCheckboxRenderer = (props) => {
    const [value, setValue] = React.useState(props.value?.value);
    const gridContext = props.context;
    const customFieldUrn = props.colDef?.field;
    const watchlistEntryUrn = props.node?.data?.entryUrn;
    const isPeopleList = customFieldUrn?.includes('person_list');
    // Debounce the upsertCustomFieldValue function to prevent multiple calls in quick succession
    // which can lead to race consitions. This is especially important for the checkbox cell renderer
    // as it is a toggle and can be clicked multiple times in quick succession.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedUpsertCustomFieldValue = useCallback(debounce(gridContext.upsertCustomFieldValue, 250), [gridContext.upsertCustomFieldValue]);
    const handleOnChange = async (newValue) => {
        setValue(newValue);
        let customFieldValueInput;
        if (isPeopleList) {
            customFieldValueInput = {
                checkboxData: {
                    value: newValue
                }
            };
        }
        else {
            customFieldValueInput = {
                checkboxData: {
                    value: newValue
                }
            };
        }
        if (customFieldUrn && watchlistEntryUrn) {
            debouncedUpsertCustomFieldValue({
                variables: {
                    customFieldUrn,
                    companyWatchlistEntryUrn: watchlistEntryUrn,
                    peopleWatchlistEntryUrn: watchlistEntryUrn,
                    customFieldValueInput
                },
                watchlistEntryUrn
            });
        }
    };
    return (_jsx("div", { children: _jsx(Checkbox, { checked: !!value, onChange: () => handleOnChange(!value) }) }));
};
export const CustomColumnCheckboxRenderer = withPendingDot(BaseCustomColumnCheckboxRenderer);
