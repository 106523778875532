import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//eslint-disable-next-line
import { useApolloClient, useQuery } from '@apollo/client';
import Button from 'harmonic-components/Button/Button';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import useFetchWatchlists from 'hooks/useFetchWatchlists';
import useFlags from 'hooks/useFlags';
import { useModal } from 'hooks/useModal';
import { getCompanyProfileHeader } from 'queries/getCompanyProfileHeader';
import { useState } from 'react';
import { SPLITS } from 'utils/constants';
import { removeFromAffinity } from 'utils/midtierApi';
import { displayToast } from 'utils/toasts';
const RemoveCompanyFromAffinity = ({ companyId, affinityListId }) => {
    const client = useApolloClient();
    const { data: companyProfileData } = useQuery(getCompanyProfileHeader, {
        variables: {
            id: companyId
        },
        fetchPolicy: 'cache-only'
    });
    const { affinityLists } = useFetchWatchlists();
    const { close } = useModal();
    const selectedAffinityList = affinityLists.find((affinityList) => affinityList.id === affinityListId);
    const [loading, setLoading] = useState(false);
    const companyName = companyProfileData?.getCompanyById?.name ?? '';
    const affinityListName = selectedAffinityList?.name ?? '';
    const { enabled: affinityIntegrationMocked } = useFlags(SPLITS.mockAffinityFrontend);
    const removeCompanyFromAffinityListCache = (affinityListId, companyId) => {
        client.cache.modify({
            id: client.cache.identify({
                __typename: 'Company',
                id: companyId
            }), // The id of the company in the cache.
            fields: {
                watchlists: (existingWatchlists) => {
                    return existingWatchlists.filter((watchlistRef) => client.cache.identify({
                        __typename: 'CompanyWatchlist',
                        id: affinityListId
                    }) !== watchlistRef.__ref);
                }
            }
        });
    };
    const handleOnRemove = async () => {
        setLoading(true);
        if (affinityIntegrationMocked) {
            removeCompanyFromAffinityListCache(affinityListId, companyId);
            displayToast({
                primaryText: 'Successfully removed',
                mode: 'success'
            });
            setLoading(false);
            close();
            return;
        }
        await removeFromAffinity(affinityListId, companyId);
        removeCompanyFromAffinityListCache(affinityListId, companyId);
        displayToast({
            primaryText: 'Successfully removed',
            mode: 'success'
        });
        setLoading(false);
        close();
    };
    const handleOnCancel = () => {
        setLoading(false);
        close();
    };
    return (_jsxs("div", { className: "", children: [_jsx(ModalTitle, { title: `Remove from Affinity list` }), _jsx("div", { className: "pb-p80 pt-p20 px-p20 max-w-[360px]", children: _jsxs("p", { className: "text-content-default typography-paragraph-default-default p-p40", children: ["Are you sure you want to remove ", companyName, " from ", affinityListName, "?"] }) }), _jsxs(ModalFooter, { children: [_jsx(Button, { label: "Cancel", onClick: handleOnCancel }), _jsx(Button, { type: "negative", label: "Remove from Affinity list", loading: loading, onClick: handleOnRemove })] })] }));
};
export default RemoveCompanyFromAffinity;
