import CompanyColumnsConfig from 'interfaces/CompanyColumnsConfig';
import { FundingTotalCombined } from './common';
const FundingOptions = [
    FundingTotalCombined,
    {
        label: CompanyColumnsConfig.FUNDING_PER_EMPLOYEE.label,
        value: CompanyColumnsConfig.FUNDING_PER_EMPLOYEE.value
    },
    {
        label: CompanyColumnsConfig.LAST_FUNDING_DATE.label,
        value: CompanyColumnsConfig.LAST_FUNDING_DATE.value
    },
    {
        label: CompanyColumnsConfig.LAST_FUNDING_TYPE.label,
        value: CompanyColumnsConfig.LAST_FUNDING_TYPE.value
    },
    {
        label: CompanyColumnsConfig.LAST_FUNDING_TOTAL.label,
        value: CompanyColumnsConfig.LAST_FUNDING_TOTAL.value
    },
    {
        label: CompanyColumnsConfig.STAGE.label,
        value: CompanyColumnsConfig.STAGE.value
    },
    {
        label: CompanyColumnsConfig.NUM_FUNDING_ROUNDS.label,
        value: CompanyColumnsConfig.NUM_FUNDING_ROUNDS.value
    },
    {
        label: CompanyColumnsConfig.INVESTORS.label,
        value: CompanyColumnsConfig.INVESTORS.value
    }
];
export default FundingOptions;
