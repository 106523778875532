import PersonColumnsConfig from 'interfaces/PersonColumnsConfig';
const AllOptions = [
    {
        label: PersonColumnsConfig.ID.label,
        value: PersonColumnsConfig.ID.value
    },
    {
        label: PersonColumnsConfig.ADDED_TO_LIST_DATE.label,
        value: PersonColumnsConfig.ADDED_TO_LIST_DATE.value
    },
    {
        label: PersonColumnsConfig.FIRST_NAME.label,
        value: PersonColumnsConfig.FIRST_NAME.value
    },
    {
        label: PersonColumnsConfig.LAST_NAME.label,
        value: PersonColumnsConfig.LAST_NAME.value
    },
    {
        label: PersonColumnsConfig.CURRENT_COMPANY.label,
        value: PersonColumnsConfig.CURRENT_COMPANY.value
    },
    {
        label: PersonColumnsConfig.PERSON_HIGHLIGHTS.label,
        value: PersonColumnsConfig.PERSON_HIGHLIGHTS.value
    },
    {
        label: PersonColumnsConfig.LISTS.label,
        value: PersonColumnsConfig.LISTS.value
    },
    {
        label: PersonColumnsConfig.CURRENT_TITLE.label,
        value: PersonColumnsConfig.CURRENT_TITLE.value
    },
    {
        label: PersonColumnsConfig.CURRENT_DEPARTMENT.label,
        value: PersonColumnsConfig.CURRENT_DEPARTMENT.value
    },
    {
        label: PersonColumnsConfig.CURRENT_LOCATION.label,
        value: PersonColumnsConfig.CURRENT_LOCATION.value
    },
    {
        label: PersonColumnsConfig.LINKEDIN_HEADLINE.label,
        value: PersonColumnsConfig.LINKEDIN_HEADLINE.value
    },
    {
        label: PersonColumnsConfig.CURRENT_COMPANY_TAGS.label,
        value: PersonColumnsConfig.CURRENT_COMPANY_TAGS.value
    },
    {
        label: PersonColumnsConfig.PREVIOUS_COMPANY.label,
        value: PersonColumnsConfig.PREVIOUS_COMPANY.value
    },
    {
        label: PersonColumnsConfig.PREVIOUS_COMPANY_TAGS.label,
        value: PersonColumnsConfig.PREVIOUS_COMPANY_TAGS.value
    },
    {
        label: PersonColumnsConfig.EDUCATION.label,
        value: PersonColumnsConfig.EDUCATION.value
    },
    {
        label: PersonColumnsConfig.PREVIOUS_COMPANIES.label,
        value: PersonColumnsConfig.PREVIOUS_COMPANIES.value
    },
    {
        label: PersonColumnsConfig.LINKEDIN.label,
        value: PersonColumnsConfig.LINKEDIN.value
    },
    {
        label: PersonColumnsConfig.EMAILS.label,
        value: PersonColumnsConfig.EMAILS.value
    },
    {
        label: PersonColumnsConfig.PRIMARY_EMAIL.label,
        value: PersonColumnsConfig.PRIMARY_EMAIL.value
    },
    {
        label: PersonColumnsConfig.TEAM_CONNECTIONS.label,
        value: PersonColumnsConfig.TEAM_CONNECTIONS.value
    },
    {
        label: PersonColumnsConfig.LAST_MEETING_DATE.label,
        value: PersonColumnsConfig.LAST_MEETING_DATE.value
    },
    {
        label: PersonColumnsConfig.LAST_MEETING_WITH.label,
        value: PersonColumnsConfig.LAST_MEETING_WITH.value
    },
    {
        label: PersonColumnsConfig.LAST_EMAIL_AT.label,
        value: PersonColumnsConfig.LAST_EMAIL_AT.value
    },
    {
        label: PersonColumnsConfig.LAST_CONTACTED_BY.label,
        value: PersonColumnsConfig.LAST_CONTACTED_BY.value
    },
    {
        label: PersonColumnsConfig.ADDED_TO_LIST_BY.label,
        value: PersonColumnsConfig.ADDED_TO_LIST_BY.value
    }
];
export default AllOptions;
