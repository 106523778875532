import { jsx as _jsx } from "react/jsx-runtime";
import { scan } from 'react-scan';
if (process.env.NODE_ENV === 'development' &&
    window.location.search.includes('scan=true')) {
    scan({
        enabled: true
    });
}
import { configure } from 'mobx';
if (process.env.NODE_ENV === 'development' &&
    window.location.search.includes('mobx-debug=true')) {
    configure({
        enforceActions: 'always',
        reactionRequiresObservable: true, // Helps catch missing observables in reactions
        observableRequiresReaction: true // Warns if observables are used outside reactions
    });
}
import { ThemeProvider } from '@material-ui/core';
import AppLayout from 'AppLayout';
import { useStore } from 'config/StoreProvider';
import { useMultipleFlags } from 'hooks/useFlags';
import { LogoutReason } from 'interfaces/Auth';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { HotkeysProvider } from 'react-hotkeys-hook';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useIntercom } from 'react-use-intercom';
import { theme } from 'styles/materialUiTheme';
import analytics from 'utils/analytics';
import { IS_PROD_LIKE_ENV, LOCAL_AGAINST_PROD, SPLITS } from 'utils/constants';
import { logger } from 'utils/logger';
import { authenticate, getUserApiKey } from 'utils/midtierApi';
import { ReactScanMonitoring } from './components/common/ReactScanMonitoring';
import { useMemoryUsage } from './hooks/useMemoryUsage';
import { useNetworkConnectionInfo } from './hooks/useNetworkConnectionInfo';
import './styles/App.css';
const App = observer(() => {
    const featureFlags = useMultipleFlags(Object.values(SPLITS));
    const intercom = useIntercom();
    const networkConnectionInfo = useNetworkConnectionInfo();
    const memoryUsage = useMemoryUsage();
    const { rootStore } = useStore();
    const user = rootStore.authStore.user;
    const userMetadata = rootStore.authStore.userMetadata;
    const displayName = rootStore.authStore.displayName;
    const apiKey = rootStore.authStore.apiKey;
    const getIntercomUserArguments = (userMetadata) => {
        let company = undefined;
        if (userMetadata) {
            company = {
                companyId: userMetadata.customer_urn,
                name: userMetadata.customer_name
            };
        }
        return {
            userId: user?.user_id,
            name: displayName,
            email: user?.email,
            company
        };
    };
    const shutdownIntercom = () => intercom.shutdown();
    const bootIntercom = () => {
        intercom.boot(getIntercomUserArguments(userMetadata));
    };
    useEffect(() => {
        // Enable logging only in production
        if (IS_PROD_LIKE_ENV && !LOCAL_AGAINST_PROD) {
            logger.initialize({
                enableDatadogProxy: rootStore.splitStore.getFeature(SPLITS.enableDatadogProxy)
            });
        }
    }, [rootStore.splitStore]);
    useEffect(() => {
        analytics.loadHeap();
    }, []);
    useEffect(() => {
        if (user) {
            // Intercom is not supported in CI
            if (IS_PROD_LIKE_ENV && !process.env.CI) {
                bootIntercom();
            }
            intercom.update(getIntercomUserArguments(userMetadata));
            // Required in case the user is paused during their session
            authenticate()
                .then((user) => {
                rootStore.authStore.setUserStatus(user.status);
                rootStore.authStore.setUserRole(user.role);
            })
                .catch((err) => {
                rootStore.authStore.logout(LogoutReason.SessionExpired, err);
            });
        }
        if (apiKey || !user)
            return;
        getUserApiKey()
            .then((apiKey) => {
            rootStore.authStore.setUserApiKey(apiKey);
        })
            .catch((err) => {
            if (IS_PROD_LIKE_ENV && !process.env.CI) {
                shutdownIntercom();
            }
            rootStore.authStore.logout(LogoutReason.SessionExpired, err);
            logger.error(`Auth state changed error: ${err}`, {
                code_area: 'app',
                err
            });
        });
        // Above hook should be only called when user changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
    useEffect(() => {
        logger.addGlobalContext({
            networkType: networkConnectionInfo?.type,
            networkDownlink: networkConnectionInfo?.downlink,
            networkDownlinkMax: networkConnectionInfo?.downlinkMax,
            networkHighlatency: networkConnectionInfo?.latency
        });
        // Heap only supports scalar values for event properties, so we need
        // to transform the list into a comma separated string.
        const enabledFlagNames = Object.entries(featureFlags)
            .filter(([, value]) => value.enabled)
            .map(([key]) => key);
        analytics.addEventProperties({ featureFlags: enabledFlagNames.join(',') });
        if (memoryUsage) {
            logger.addGlobalContext(memoryUsage);
        }
    }, [featureFlags, networkConnectionInfo, memoryUsage]);
    return (_jsx(ThemeProvider, { theme: theme, children: _jsx(BrowserRouter, { children: _jsx(HotkeysProvider, { children: _jsx(ReactScanMonitoring, { children: _jsx(Routes, { children: _jsx(Route, { path: "*", element: _jsx(AppLayout, {}) }) }) }) }) }) }));
});
export default App;
