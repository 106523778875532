import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ApolloProvider, setLogVerbosity } from '@apollo/client';
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist';
import { AuthStatus } from 'interfaces/Auth';
import localforage from 'localforage';
import { observer } from 'mobx-react';
import { LOCAL_AGAINST_PROD, SPLITS } from 'utils/constants';
import useFlags from '../hooks/useFlags';
import { useStore } from './StoreProvider';
import client, { createApolloCache } from './client';
const ApolloWrapper = observer(({ children }) => {
    const { rootStore } = useStore();
    const authStatus = rootStore.authStore.authStatus;
    const [localClient, setClient] = useState();
    const { enabled: enableApolloCachePersist } = useFlags(SPLITS.enableApolloCachePersist);
    const cache = useMemo(() => createApolloCache(), []);
    const initApolloClient = useCallback(async (options) => {
        if (enableApolloCachePersist) {
            const newPersistor = new CachePersistor({
                cache,
                storage: new LocalForageWrapper(localforage),
                debug: true,
                trigger: 'write',
                maxSize: false
            });
            await newPersistor.restore();
        }
        // Update existing client's default options if provided
        if (options?.defaultOptions) {
            client.defaultOptions = {
                ...client.defaultOptions,
                ...options.defaultOptions
            };
        }
        setClient(client);
        if (LOCAL_AGAINST_PROD) {
            setLogVerbosity('debug');
        }
    }, [enableApolloCachePersist, cache]);
    useEffect(() => {
        if (authStatus === AuthStatus.UNAUTHENTICATED)
            return;
        // If the cache persist is enabled, we need to re-initialize the Apollo client
        if (enableApolloCachePersist) {
            initApolloClient({
                defaultOptions: {
                    watchQuery: {
                        fetchPolicy: 'cache-and-network',
                        errorPolicy: 'all'
                    }
                }
            });
        }
    }, [enableApolloCachePersist, initApolloClient, authStatus]);
    useEffect(() => {
        // We should only create one instance of ApolloClient per session.
        // https://github.com/apollographql/apollo-client-devtools/issues/822#issuecomment-1059166308
        if (localClient) {
            return;
        }
        if (authStatus === AuthStatus.AUTHENTICATED) {
            initApolloClient();
        }
    }, [authStatus, initApolloClient, localClient]);
    // If we do not have a Firebase token and the user is not authenticated, it means the user is logged out
    // none of the children should expect a ApolloClient in this case. We do not render ApolloProvider and
    // we render the children as is
    if (authStatus === AuthStatus.UNAUTHENTICATED) {
        return _jsx(_Fragment, { children: children });
    }
    // If the user is authenticated, but the Firebase token is not yet available, we can't instantiate the
    // Apollo client and we can't render the children, as they may expect an Apollo client.
    // This is a temporal state while the Firebase token is retrieved.
    if (!localClient) {
        return _jsx(_Fragment, {});
    }
    // At this point we have an ApolloClient, render the ApolloProvider
    return _jsx(ApolloProvider, { client: localClient, children: children });
});
export default ApolloWrapper;
