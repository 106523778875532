import { jsx as _jsx } from "react/jsx-runtime";
import Tag from '../../../../harmonic-components/Tag/Tag';
import TruncatedList from '../../../../harmonic-components/TruncatedList/TruncatedList';
import useTeamMembers from '../../../../hooks/useTeamMembers';
import { colorOptions } from '../EditField/SelectOptions/EditOptionItem';
import { LoadingCellRenderer } from './CellRenderers';
import { withPendingDot } from './components/WithPendingDot';
import { DEFAULT_HEIGHT } from './CustomColumnCellRenderers';
export const BaseCustomColumnPersonSelectCellRenderer = (props) => {
    const { allTeamMembers } = useTeamMembers({ fetchPolicy: 'cache-only' });
    const formattedTeamMembers = allTeamMembers?.map((member) => ({
        urn: member?.user?.entityUrn,
        name: member?.user?.name
    })) ?? [];
    const value = props.value?.value ?? [];
    const selectedOptions = formattedTeamMembers
        .filter((option) => option.urn && value.includes(option.urn))
        .sort((a, b) => {
        return value.indexOf(a.urn) - value.indexOf(b.urn);
    });
    if (!allTeamMembers || allTeamMembers.length === 0) {
        return _jsx(LoadingCellRenderer, {});
    }
    if (props.showEmptyValue && !selectedOptions.length) {
        return (_jsx("p", { className: "typography-paragraph text-content-weak", children: `Select ${props.colDef?.headerName}` }));
    }
    return (_jsx("div", { className: "inline-block max-w-full", children: _jsx(TruncatedList, { height: props.height ?? DEFAULT_HEIGHT, children: !!selectedOptions &&
                !!selectedOptions.length &&
                selectedOptions.map((option) => {
                    const color = colorOptions[formattedTeamMembers.indexOf(option) % colorOptions.length].color;
                    return (_jsx(Tag, { label: option.name, color: color, truncate: true }, option.urn));
                }) }) }));
};
export const CustomColumnPersonSelectCellRenderer = withPendingDot(BaseCustomColumnPersonSelectCellRenderer);
